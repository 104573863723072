import FiltersHeader from "../../../../Helper/filtersHeader";
import * as Constants from '../../../../Helper/constants/constants';
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { Calender, ChangePatientProfile, Image_Up, Man, Patient } from "../../../../../assets/images";
import Select from 'react-select';
import PatientProfileModal from "../../../Modals/PatientProfileModal";
import { useState } from "react";
import { useEffect } from "react";
import EditPatientFunctions from "./funstions";
import UploadImageBox from "../../../../Helper/UploadUserImageBox";
import Loader from "../../../../Helper/loader";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import { dateFormat2, spaceAfter4Digit } from "../../../../Helper/General";
import CustomSelectOptions from '../../../../Helper/customSelectOption';


const EditPatient = () => {
    const {
        breadcrumData,
        breadcrumItem,
        values,
        setValues,
        isError,
        loaction,
        loader,
        handleChange,
        navigate,
        getBoodGroupListing,
        getMartialStatusListing,
        getNationalitiesListing,
        getStatesListing,
        getGendersListing,
        getDistrictsListing,
        getCitiesListing,
        bloodGroupsList,
        martialStatusList,
        nationalitiesList,
        gendersList,
        statesList,
        districtsList,
        citiesList,
        handleDistrictAndCityDropDown,
        handleBreadcrumDataNaviagtion,
        handleSubmit,
        getPatinetDetail,
        patientDetail,
        getmonthyears
    } = EditPatientFunctions();

    useEffect(() => {
        if (loaction && loaction.state && loaction.state.id) {
            let id = loaction && loaction.state && loaction.state.id ? loaction.state.id : ''
            getPatinetDetail(id);
            // getBoodGroupListing();
            // getMartialStatusListing();
            getNationalitiesListing();
            // getStatesListing();
            // getGendersListing();
        }
        else {
            navigate(-1);
        }
    }, []);

    const customStyles = {
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                color: "black",
                fontWeight: isFocused ? "bold" : "normal",
                backgroundColor: isFocused ? "rgba(242, 166, 159, 0.8)" : "white",
            };
        }
    };

    return (
        <div className="edit_patient_screen">
            <WithoutImageHeader
                header={Constants.EDIT_PROFILE}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
                handleBack={() => navigate(-1)}
            />
            {
                loader ?
                    <Loader
                        loader={loader}
                    />
                    :
                    patientDetail ?
                        <Container>
                            <Row>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className="edit_patient">
                                        <div className="img_section">
                                            <UploadImageBox
                                                name={'Patient'}
                                                image={values && values.image}
                                                onResponse={(response) => {
                                                    handleChange("image", response.path,)
                                                }}
                                                removeImage={async () => {
                                                    //  await removeImage();
                                                }}
                                            />
                                            {isError.image.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError.image.message}
                                                </p>
                                            ) : null}
                                        </div>
                                        <Form>
                                            <Form.Group className="form-group">
                                                <Form.Label>Patient Name<span>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter patient name here"
                                                    value={values && values.patientName}
                                                    className={isError && isError.patientName.message
                                                        ? "error_border"
                                                        : ""}
                                                    onChange={(e) =>
                                                        handleChange("patientName", e.target.value)
                                                    }
                                                />
                                                {isError && isError.patientName.message ? (
                                                    <p className="text-danger mt-2">
                                                        {isError && isError.patientName.message}
                                                    </p>
                                                ) : null}
                                            </Form.Group>
                                        </Form>
                                        <Row>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <Form>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Father/Husband Name<span>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter father/husband name here"
                                                            value={values && values.fatherName}
                                                            className={isError && isError.fatherName.message
                                                                ? "error_border"
                                                                : ""}
                                                            onChange={(e) =>
                                                                handleChange("fatherName", e.target.value)
                                                            }
                                                        />
                                                        {isError && isError.fatherName.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.fatherName.message}
                                                            </p>
                                                        ) : null}
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <Form>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Mother/Spouse</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter mother/Spouse here"
                                                            value={values && values.motherName}
                                                            className={isError && isError.motherName.message
                                                                ? "error_border"
                                                                : ""}
                                                            onChange={(e) =>
                                                                handleChange("motherName", e.target.value)
                                                            }
                                                        />
                                                        {isError && isError.motherName.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.motherName.message}
                                                            </p>
                                                        ) : null}
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                                                <Form>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Date of Birth<span>*</span></Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            type="text"
                                                            placeholder="Date of birth (YYYY-MM-DD)"
                                                            value={values && dateFormat2(values.dob)}
                                                            className={isError && isError.dob.message
                                                                ? "error_border"
                                                                : ""}
                                                            onChange={(e) =>
                                                                handleChange("dob", e.target.value)
                                                            }
                                                        />
                                                        {isError && isError.dob.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.dob.message}
                                                            </p>
                                                        ) : null}
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                            <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12}>
                                                <Form>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Age<span>*</span></Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            type="text"
                                                            placeholder="Enter age here"
                                                            value={values && values.age}
                                                            className={isError && isError.age.message
                                                                ? "error_border"
                                                                : ""}
                                                            onChange={(e) =>
                                                                handleChange("age", e.target.value)
                                                            }
                                                        />
                                                        {isError && isError.age.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.age.message}
                                                            </p>
                                                        ) : null}
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                                                <div className="dropdown_box">
                                                    <h6>Gender<span>*</span></h6>
                                                    <div className="dropdown_area">
                                                        <Select
                                                            styles={customStyles}
                                                            placeholder="Select gender"
                                                            value={values.gender}
                                                            defaultValue={values.gender}
                                                            options={gendersList ? gendersList : []}
                                                            onChange={(item) => handleChange('gender', item)}
                                                            className={isError.gender.message ? "error_border_select" : ""}
                                                            components={{
                                                                Option: props => (
                                                                    <CustomSelectOptions
                                                                        items={props}
                                                                        value={values && values.gender}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                        {isError && isError.gender.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.gender.message}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <div className="dropdown_box">
                                                    <h6>Blood Group</h6>
                                                    <div className="dropdown_area">
                                                        <Select
                                                            styles={customStyles}
                                                            placeholder="Select bloodgroup"
                                                            defaultValue={values.bloodGroup}
                                                            value={values.bloodGroup}
                                                            options={bloodGroupsList ? bloodGroupsList : []}
                                                            onChange={(item) => handleChange('bloodGroup', item)}
                                                            className={isError.bloodGroup.message ? "error_border_select" : ""}
                                                            components={{
                                                                Option: props => (
                                                                    <CustomSelectOptions
                                                                        items={props}
                                                                        value={values && values.bloodGroup}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                        {isError && isError.bloodGroup.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.bloodGroup.message}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <div className="dropdown_box">
                                                    <h6>Marital Status<span>*</span></h6>
                                                    <div className="dropdown_area">
                                                        <Select
                                                            styles={customStyles}
                                                            placeholder="Select marital status"
                                                            value={values.maritalStatus}
                                                            options={martialStatusList ? martialStatusList : []}
                                                            onChange={(item) => handleChange('maritalStatus', item)}
                                                            className={isError.maritalStatus.message ? "error_border_select" : ""}
                                                            components={{
                                                                Option: props => (
                                                                    <CustomSelectOptions
                                                                        items={props}
                                                                        value={values && values.maritalStatus}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                        {isError && isError.maritalStatus.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.maritalStatus.message}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <Form>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Occupation</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter occupation here"
                                                            value={values && values.occupation}
                                                            className={isError && isError.occupation.message
                                                                ? "error_border"
                                                                : ""}
                                                            onChange={(e) =>
                                                                handleChange("occupation", e.target.value)
                                                            }
                                                        />
                                                        {isError && isError.occupation.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.occupation.message}
                                                            </p>
                                                        ) : null}
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <div className="dropdown_box">
                                                    <h6>Nationality<span>*</span></h6>
                                                    <div className="dropdown_area">
                                                        <Select
                                                            styles={customStyles}
                                                            placeholder="Select nationality"
                                                            value={values.nationlity}
                                                            defaultValue={values.nationlity}
                                                            options={nationalitiesList ? nationalitiesList : []}
                                                            onChange={(item) => (handleChange('nationlity', item), getStatesListing(item && item.id ? item.id : ''))}
                                                            className={isError.nationlity.message ? "error_border_select" : ""}
                                                            components={{
                                                                Option: props => (
                                                                    <CustomSelectOptions
                                                                        items={props}
                                                                        value={values && values.nationlity}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                        {isError && isError.nationlity.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.nationlity.message}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                                                <Form>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>House Number</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter House Number here"
                                                            value={values && values.address}
                                                            className={isError && isError.address.message
                                                                ? "error_border"
                                                                : ""}
                                                            onChange={(e) =>
                                                                handleChange("address", e.target.value)
                                                            }
                                                        />
                                                        {isError && isError.address.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.address.message}
                                                            </p>
                                                        ) : null}
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                                                <Form>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Area<span>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter area here"
                                                            value={values && values.area}
                                                            className={isError && isError.area.message
                                                                ? "error_border"
                                                                : ""}
                                                            onChange={(e) =>
                                                                handleChange("area", e.target.value)
                                                            }
                                                        />
                                                        {isError && isError.area.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.area.message}
                                                            </p>
                                                        ) : null}
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                            <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12}>
                                                <Form>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Pincode</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter pincode here"
                                                            value={values && values.pincode}
                                                            className={isError && isError.pincode.message
                                                                ? "error_border"
                                                                : ""}
                                                            onChange={(e) =>
                                                                handleChange("pincode", e.target.value)
                                                            }
                                                        />
                                                        {isError && isError.pincode.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.pincode.message}
                                                            </p>
                                                        ) : null}
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                                                <div className="dropdown_box">
                                                    <h6>State<span>*</span></h6>
                                                    <div className="dropdown_area">
                                                        <Select
                                                            styles={customStyles}
                                                            placeholder="Select state"
                                                            value={values.stateData}
                                                            options={statesList ? statesList : []}
                                                            onChange={(item) => (
                                                                handleChange('stateData', item),
                                                                getDistrictsListing(item.id ? item.id : ''),
                                                                getCitiesListing(item.id ? item.id : '')
                                                            )}
                                                            className={isError.stateData.message ? "error_border_select" : ""}
                                                            components={{
                                                                Option: props => (
                                                                    <CustomSelectOptions
                                                                        items={props}
                                                                        value={values && values.stateData}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                        {isError && isError.stateData.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.stateData.message}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                                                <div className="dropdown_box" onClick={handleDistrictAndCityDropDown}>
                                                    <h6>District<span>*</span></h6>
                                                    <div className="dropdown_area">
                                                        <Select
                                                            styles={customStyles}
                                                            isDisabled={values && values.stateData ? false : true}
                                                            placeholder="Select district"
                                                            value={values.districtData}
                                                            options={districtsList ? districtsList : []}
                                                            onChange={(item) => handleChange('districtData', item)}
                                                            className={isError.districtData.message ? "error_border_select" : ""}
                                                            components={{
                                                                Option: props => (
                                                                    <CustomSelectOptions
                                                                        items={props}
                                                                        value={values && values.districtData}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                        {isError && isError.districtData.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.districtData.message}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12}>
                                                <div className="dropdown_box" onClick={handleDistrictAndCityDropDown}>
                                                    <h6>City<span>*</span></h6>
                                                    <div className="dropdown_area">
                                                        <Select
                                                            styles={customStyles}
                                                            isDisabled={values && values.stateData ? false : true}
                                                            placeholder="Select city"
                                                            value={values.cityData}
                                                            options={citiesList ? citiesList : []}
                                                            onChange={(item) => handleChange('cityData', item)}
                                                            className={isError.cityData.message ? "error_border_select" : ""}
                                                            components={{
                                                                Option: props => (
                                                                    <CustomSelectOptions
                                                                        items={props}
                                                                        value={values && values.cityData}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                        {isError && isError.cityData.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.cityData.message}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <Form>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Contact Number<span>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter contact number here"
                                                            value={values && values.contactNumber}
                                                            className={isError && isError.contactNumber.message
                                                                ? "error_border"
                                                                : ""}
                                                            onChange={(e) =>
                                                                handleChange("contactNumber", e.target.value)
                                                            }
                                                        />
                                                        {isError && isError.contactNumber.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.contactNumber.message}
                                                            </p>
                                                        ) : null}
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <Form>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Alter Contact Number</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter alternative contact numbere here"
                                                            value={values && values.alternativeNumber}
                                                            className={isError && isError.alternativeNumber.message
                                                                ? "error_border"
                                                                : ""}
                                                            onChange={(e) =>
                                                                handleChange("alternativeNumber", e.target.value)
                                                            }
                                                        />
                                                        {isError && isError.alternativeNumber.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError && isError.alternativeNumber.message}
                                                            </p>
                                                        ) : null}
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className="form-group">
                                                <Form.Label>
                                                    Date of Admission<span>*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    placeholder="DD/MM/YYYY"
                                                    value={values && values.dateOfAdmission ? values.dateOfAdmission : null}
                                                    className={isError && isError.dateOfAdmission.message
                                                        ? "error_border"
                                                        : ""}
                                                    onChange={(e) =>
                                                        handleChange("dateOfAdmission", e.target.value)
                                                    }
                                                />
                                                {isError && isError.dateOfAdmission.message ? (
                                                    <p className="text-danger mt-2">
                                                        {isError && isError.dateOfAdmission.message}
                                                    </p>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Form>
                                            <Form.Group className="form-group">
                                                <Form.Label>Aadhar Number</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter your Aadhar Number Here"
                                                    value={values && values.adhaarCard}
                                                    className={isError && isError.adhaarCard.message
                                                        ? "error_border"
                                                        : ""}
                                                    onChange={(e) =>
                                                        handleChange("adhaarCard", spaceAfter4Digit(e.target.value))
                                                    }
                                                />
                                                {isError && isError.adhaarCard.message ? (
                                                    <p className="text-danger mt-2">
                                                        {isError && isError.adhaarCard.message}
                                                    </p>
                                                ) : null}
                                            </Form.Group>
                                        </Form>
                                        <Form>
                                            <Form.Group className="form-group">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter your email address"
                                                    value={values && values.email}
                                                    className={isError && isError.email.message
                                                        ? "error_border"
                                                        : ""}
                                                    onChange={(e) =>
                                                        handleChange("email", e.target.value)
                                                    }
                                                />
                                                {isError && isError.email.message ? (
                                                    <p className="text-danger mt-2">
                                                        {isError && isError.email.message}
                                                    </p>
                                                ) : null}
                                            </Form.Group>
                                        </Form>
                                        <div className="btn_area">
                                            <Button
                                                className="cancelBtn"
                                                type="button"
                                                // onClick={() => navigate('/patientDetails', { state: { id: loaction && loaction.state && loaction.state.id ? loaction.state.id : '' } })}
                                                onClick={() => navigate(-1)}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="submitBtn"
                                                type="button"
                                                onClick={handleSubmit}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        :
                        null
            }
        </div>
    )
};

export default EditPatient;