import ActionType from "../constants/index";

const initialState = {
    userPermissions: {},
};

const UserPermissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.SET_USER_PERMISSION:
            return Object.assign({}, state, {
                user: action.payload,
            });
        default:
            return state;
    }
};

export default UserPermissionsReducer;
