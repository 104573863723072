import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Form, Image, Table } from "react-bootstrap";
import { Delete_2, Edit_2, Eye_2, Hospital_Img, Man } from "../../../../../assets/images";
import { dateFormat, renderImage, timeFormat2 } from "../../../../Helper/General";
import NoDataComponent from "../../../CommanComponents/NoDataComponent";
import MultipleHospitalPopup from "../../../Modals/MultipleHospitalModal";
import { useState } from "react";

export const StafListComponet = (props) => {
    const [hospitalArrayAssigned, setHospitalArrayAssigned] = useState([]);
    const {
        updateStafStatus,
        navigate,
        setDeleteItem,
        setDeletePopup,
        List,
        checkPermissions,
        currentTab,
        breadcrumData1,
        User,
        showMultiHosPopup,
        setShowMultiHosPopup
    } = props

    return (
        <>
            {List && List.length > 0 ?
                <div className="staff_table_area">
                    <div className="table-responsive">
                        <Table responsive>
                            <tbody>
                                <tr>
                                    <th>Hospital Name</th>
                                    <th>Name</th>
                                    <th>Role</th>
                                    <th>Department</th>
                                    {/* {
                                    parseInt(currentTab) === 1 ? */}
                                    {/* //         :
                                //         null
                                // } */}
                                    <th>Status</th>
                                    <th>Created On</th>
                                    <th>Action</th>
                                </tr>
                                {
                                    List.map((item, index) => (
                                        <tr
                                            className="active"
                                            key={index}
                                        >
                                            <td>
                                                <div className="profile_area">
                                                    <div className="left_area">
                                                        <div className="img_area">
                                                            <Image
                                                                src={item && item.hospitals && item.hospitals[0] && item.hospitals[0].image ? renderImage(item.hospitals[0].image) : Hospital_Img}
                                                                alt="..."
                                                                onClick={() => navigate(`/hospital-detail/${item && item.hospitals && item.hospitals[0] && item.hospitals[0].id}`,
                                                                    {
                                                                        state: {
                                                                            fromNavigation: true
                                                                        }
                                                                    })}
                                                            />
                                                        </div>
                                                    </div>
                                                    {item.hospitals && item.hospitals.length > 1 ? <div className="right_area"
                                                        onClick={() => { setHospitalArrayAssigned(item && item.hospitals); setShowMultiHosPopup(true) }}
                                                    >
                                                        <a>{item && item.hospitals && item.hospitals[0] && item.hospitals[0].title
                                                            ? `${item.hospitals[0].title} + ${item.hospitals && item.hospitals.length - 1}`
                                                            : '-'}</a>
                                                    </div> : <div className="right_area"
                                                        onClick={() => navigate(`/hospital-detail/${item && item.hospitals && item.hospitals[0] && item.hospitals[0].id}`,
                                                            {
                                                                state: {
                                                                    fromNavigation: true
                                                                }
                                                            })}>
                                                        <a>{item && item.hospitals && item.hospitals[0] && item.hospitals[0].title
                                                            ? item.hospitals[0].title
                                                            : '-'}</a>
                                                    </div>}
                                                </div>
                                            </td>
                                            <td>
                                                {<div className="profile_area">
                                                    <div className="left_area">
                                                        <div className="img_area"
                                                            onClick={() => navigate(`/staff-detail/${item.id}`, { state: { name: item.title } })}
                                                        >
                                                            <Image
                                                                src={item.image ? renderImage(item.image) : Man}
                                                                alt="..."
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="right_area">
                                                        <a onClick={() => navigate(`/staff-detail/${item.id}`, { state: { name: item.title } })}><u style={{ textTransform: 'capitalize' }}>{item.name ? item.name : ''}</u></a>
                                                    </div>
                                                </div>}
                                            </td>
                                            <td>
                                                <div className="text_box">
                                                    {item.role_title ? item.role_title : '-'}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="text_box" style={{ textTransform: 'uppercase' }}>
                                                    {item.department_title ? item.department_title : '-'}
                                                </div>
                                            </td>

                                            <td>
                                                <div className="switch_area">
                                                    <Form>
                                                        <Form.Check
                                                            type="switch"
                                                            id="custom-switch"
                                                            label={parseInt(item.status) === 1 ? "Active" : "Inactive"}
                                                            defaultChecked={parseInt(item.status) === 1 ? true : false}
                                                            // checked={parseInt(item.status) === 1 ? true : false}
                                                            onClick={() => User && parseInt(User.user_type) === 1 ? updateStafStatus(item) : ''}
                                                        />
                                                    </Form>
                                                </div>
                                            </td>
                                            <td>
                                                <p>
                                                    {dateFormat(item.created)}{' '}{timeFormat2(item.created)}
                                                </p>
                                            </td>
                                            <td>
                                                <div className="icon">
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant="none"
                                                            id="dropdown-basic"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faEllipsisVertical
                                                                }
                                                                className="ellipsis_icon"
                                                            />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                               onClick={() => navigate(`/staff-detail/${item.id}`, { state: { name: item.title } })}
                                                            >
                                                                View{" "}
                                                                <Image
                                                                    src={Eye_2}
                                                                    alt="..."
                                                                    className="image_area"
                                                                />
                                                            </Dropdown.Item>
                                                            {
                                                                User && parseInt(User.user_type) === 1 && parseInt(currentTab) === 1 ?
                                                                    <Dropdown.Item
                                                                        onClick={() => navigate("/add-staff", { state: { name: "EditScreen", id: item.id, } })}
                                                                    >
                                                                        Edit
                                                                        <Image
                                                                            src={Edit_2}
                                                                            alt="..."
                                                                            className="image_area"
                                                                        />
                                                                    </Dropdown.Item>
                                                                    :
                                                                    parseInt(currentTab) === 1
                                                                        && checkPermissions
                                                                        && checkPermissions.permissions
                                                                        && checkPermissions.permissions.length > 1
                                                                        && parseInt(checkPermissions.permissions[2].value) === 1 ?
                                                                        <Dropdown.Item
                                                                            onClick={() => navigate("/add-staff", { state: { name: "EditScreen", id: item.id, } })}
                                                                        >
                                                                            Edit
                                                                            <Image
                                                                                src={Edit_2}
                                                                                alt="..."
                                                                                className="image_area"
                                                                            />
                                                                        </Dropdown.Item>
                                                                        :
                                                                        null
                                                            }
                                                            {
                                                                User && parseInt(User.user_type) === 1 ?
                                                                    <Dropdown.Item onClick={() => (setDeleteItem(item), setDeletePopup(true))} >
                                                                        Delete
                                                                        <Image
                                                                            src={
                                                                                Delete_2
                                                                            }
                                                                            alt="..."
                                                                            className="image_area delete_icon "
                                                                        />
                                                                    </Dropdown.Item>
                                                                    :
                                                                    parseInt(currentTab) === 1
                                                                        && checkPermissions
                                                                        && checkPermissions.permissions
                                                                        && checkPermissions.permissions.length > 3
                                                                        && parseInt(checkPermissions.permissions[3].value) === 1 ?
                                                                        <Dropdown.Item onClick={() => (setDeleteItem(item), setDeletePopup(true))} >
                                                                            Delete
                                                                            <Image
                                                                                src={
                                                                                    Delete_2
                                                                                }
                                                                                alt="..."
                                                                                className="image_area delete_icon "
                                                                            />
                                                                        </Dropdown.Item>
                                                                        :
                                                                        null
                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
                :
                <NoDataComponent
                    message="No Staff Member Found"
                />
            }
            {
                showMultiHosPopup ? (
                    <MultipleHospitalPopup
                        show={showMultiHosPopup}
                        navigate={navigate}
                        hospitals={hospitalArrayAssigned}
                        close={() => setShowMultiHosPopup(false)}
                    />
                ) : null
            }

        </>
    )
}