import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { Button, Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";
import NoDataComponent from "../../CommanComponents/NoDataComponent";
import ExistPatientListFilterFunctions from "./function";



const ExistPatientListFilterModal = (props) => {
    const {
        patientStatusList,
        wardsList,
        getWardsListing,
        handlePatientListFilters,
        handleWardsFilters,
        handleWardsSearch,
        setFilters,
        filters,
        applyFilters,
        handleClearAllFilters,
    } = ExistPatientListFilterFunctions(props);

    useEffect(() => {
        getWardsListing();
    }, []);


    return (
        <div>
            <Modal show={props.show} onHide={props.close} centered className="filter_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="filter_details">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="second">
                            <Row>
                                <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Ward</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Admission Date</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                                    <div className="content_area">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="second">
                                                <div className="search_details">
                                                    <Form className="ward_form">
                                                        <Form.Group className="form-group">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="No Selected"
                                                                // value={wardsSearch}
                                                                onChange={handleWardsSearch}
                                                            />
                                                            <a >
                                                                <div className="icon">
                                                                    <FontAwesomeIcon icon={faSearch} />
                                                                </div>
                                                            </a>
                                                        </Form.Group>
                                                        <div className="check_box_slider">
                                                            {
                                                                wardsList && wardsList.length > 0 ?
                                                                    wardsList.map((item, i) => (
                                                                        <Form.Check
                                                                            key={i}
                                                                            type="checkbox"
                                                                            id={item.id}
                                                                            label={item.title}
                                                                            checked={item.checked}
                                                                            onChange={() => handleWardsFilters(item)}
                                                                        />
                                                                    ))
                                                                    :
                                                                    <NoDataComponent
                                                                        message="No Ward Found!"
                                                                    />
                                                            }
                                                        </div>
                                                    </Form>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <Form className="task_status_form">
                                                    <div className="check_box_slider">
                                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                                            <Form.Group className="form-group" id="dob_box" >
                                                                <Form.Label>From date</Form.Label>
                                                                <span className="asterisk">*</span>
                                                                <Form.Control
                                                                    className="assign_date"
                                                                    type="date"
                                                                    placeholder="DD/MM/YYYY"
                                                                    max={new Date().toISOString().slice(0, 10)}
                                                                    value={filters.admissionFromDate}
                                                                    // className={isError.name.message
                                                                    //     ? "error_border"
                                                                    //     : ""}
                                                                    onChange={(e) => setFilters({
                                                                        ...filters,
                                                                        admissionFromDate: e.target.value
                                                                    })}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                                            <Form.Group className="form-group" id="dob_box" >
                                                                <Form.Label>To Date</Form.Label>
                                                                <span className="asterisk">*</span>
                                                                <Form.Control
                                                                    className="assign_date"
                                                                    type="date"
                                                                    placeholder="DD/MM/YYYY"
                                                                    max={new Date().toISOString().slice(0, 10)}
                                                                    value={filters.admissionToDate}
                                                                    // className={isError.name.message
                                                                    //     ? "error_border"
                                                                    //     : ""}
                                                                    onChange={(e) => setFilters({
                                                                        ...filters,
                                                                        admissionToDate: e.target.value
                                                                    })}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </div>
                                                </Form>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </Col>
                            </Row>
                        </Tab.Container>
                        <div className="btn_area">
                            <Button
                                className="cancelBtn"
                                type="button"
                                onClick={() => handleClearAllFilters()}
                            >Clear All</Button>
                            <Button
                                className="submitBtn"
                                type="submit"
                                onClick={() => applyFilters()}
                            >Apply</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ExistPatientListFilterModal;