import FiltersHeader from "../../../../Helper/filtersHeader";
import * as Constants from '../../../../Helper/constants/constants';
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import Select from 'react-select';
import { useEffect, useState } from "react";
import AddCaseFunctions from "./funstions";
import Loader from '../../../../Helper/loader/index'

const AddCase = () => {
    const {
        breadcrumData,
        breadcrumItem,
        setBreadcrumItem,
        values,
        isError,
        loaction,
        loader,
        handleChange,
        navigate,
        getWardsListing,
        getAdminsListing,
        getRoomsListing,
        getBedsListing,
        getDepartmentsListing,
        getPatientTypesListing,
        wardsList,
        roomsList,
        bedsList,
        patientTypesList,
        departmentList,
        consultantsList,
        handleEmergencyStatus,
        handleRoomNoDropdown,
        handleBedNoDropdown,
        handleConsultantDropdown,
        handleBreadcrumDataNaviagtion,
        handleSubmit,
        getCaseDetail,
    } = AddCaseFunctions();

    const [tags, setTags] = useState([]);

    useEffect(() => {
        if (loaction && loaction.state && loaction.state.name) {
            if (loaction.state.name === 'addCase') {
                setBreadcrumItem('Add Case');
            }
            else {
                setBreadcrumItem('Edit Case');
            }
            if (loaction.state.name !== 'addCase') {
                if (loaction && loaction.state && loaction.state.caseId) {
                    let id = loaction.state.caseId
                    getCaseDetail(id);
                }
                else {
                    navigate(-1);
                }
            } else {
                getWardsListing()
            }
            getPatientTypesListing();
            if (loaction && loaction.state && loaction.state.hospitalCaseId) {
                getAdminsListing(loaction && loaction.state && loaction.state.hospitalCaseId);
            }
        }
    }, []);


    // const handleChange = (tags) => {
    //     let arry = [...tags]
    //     console.log(arry, '---arry');
    //     console.log(tags[0], '------------------------tags');
    //     if (tags) {
    //         arry.push(tags[0])
    //     }
    //     setTags(arry)
    // }


    return (
        <div className="add_case_section">
            <FiltersHeader
                header={loaction && loaction.state && loaction.state.name === 'editCase' ? Constants.EDIT_CASE : Constants.ADD_CASE}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
                handleBack={() => navigate(-1)}
                lefticon={'back'}
            />
            {
                loader ?
                    <Loader
                        loader={loader}
                    />
                    :
                    <Container>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="case_area">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Label>MR Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter MR Title here"
                                                value={values && values.caseTitle}
                                                className={isError && isError.caseTitle.message
                                                    ? "error_border"
                                                    : ""}
                                                onChange={(e) =>
                                                    handleChange("caseTitle", e.target.value)
                                                }
                                            />
                                            {isError && isError.caseTitle.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError && isError.caseTitle.message}
                                                </p>
                                            ) : null}
                                        </Form.Group>
                                        {/* <div className="tag_area">
                                    <h6>Tags<span>*</span></h6>
                                    <TagsInput
                                        value={tags}
                                        inputValue={tags}
                                        onChange={handleChange}
                                    />
                                    {isError && isError.tags.message ? (
                                        <p className="text-danger mt-2">
                                            {isError && isError.tags.message}
                                        </p>
                                    ) : null}
                                </div> */}
                                        <Form.Group className="form-group">
                                            <Form.Label>
                                                Tags
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Ex- Fever, cold, cough etc"
                                                value={values.tags}
                                                className={isError.tags.message
                                                    ? "error_border"
                                                    : ""}
                                                onChange={(e) =>
                                                    handleChange("tags", e.target.value)
                                                }
                                            />
                                            {isError.tags.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError.tags.message}
                                                </p>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label>Summary</Form.Label>
                                            <Form.Control
                                                as={'textarea'}
                                                type="text"
                                                placeholder="Enter Summary Here"
                                                value={values && values.summary}
                                                className={isError && isError.summary.message
                                                    ? "error_border"
                                                    : ""}
                                                onChange={(e) =>
                                                    handleChange("summary", e.target.value)
                                                }
                                            />
                                            {isError && isError.summary.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError && isError.summary.message}
                                                </p>
                                            ) : null}
                                        </Form.Group>
                                        <div className="dropdown_box">
                                            <h6>Ward<span>*</span></h6>
                                            <div className="dropdown_area">
                                                <Select
                                                    placeholder="Select Ward"
                                                    value={values && values.ward}
                                                    options={wardsList}
                                                    className={isError && isError.ward.message
                                                        ? "error_border_select"
                                                        : ""}
                                                    onChange={(item) => (
                                                        handleChange('ward', item),
                                                        getRoomsListing(item && item.id, values && values.hospitalId)
                                                    )}
                                                />
                                                {isError && isError.ward.message ? (
                                                    <p className="text-danger mt-2">
                                                        {isError && isError.ward.message}
                                                    </p>
                                                ) : null}
                                            </div>
                                        </div>
                                    </Form>
                                    <Row>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <div className="dropdown_box" onClick={() => handleRoomNoDropdown()}>
                                                <h6>Room No<span>*</span></h6>
                                                <div className="dropdown_area" >
                                                    <Select
                                                        placeholder="Select Room No"
                                                        isDisabled={values && values.ward ? false : true}
                                                        value={values && values.roomNo}
                                                        options={roomsList}
                                                        onChange={(item) => (handleChange('roomNo', item), getBedsListing(item && item.id))}
                                                        className={isError && isError.roomNo.message
                                                            ? "error_border_select"
                                                            : ""}
                                                    />
                                                    {isError && isError.roomNo.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError && isError.roomNo.message}
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <div className="dropdown_box" onClick={() => handleBedNoDropdown()}>
                                                <h6>Bed No<span>*</span></h6>
                                                <div className="dropdown_area">
                                                    <Select
                                                        placeholder="Select Bed No"
                                                        isDisabled={values && values.roomNo ? false : true}
                                                        value={values && values.bedNo}
                                                        options={bedsList}
                                                        onChange={(item) => handleChange('bedNo', item)}
                                                        className={isError && isError.bedNo.message
                                                            ? "error_border_select"
                                                            : ""}
                                                    />
                                                    {isError && isError.bedNo.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError && isError.bedNo.message}
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="dropdown_box">
                                        <h6>Patient Type<span>*</span></h6>
                                        <div className="dropdown_area">
                                            <Select
                                                placeholder="Select Patient Type"
                                                value={values && values.patientType}
                                                options={patientTypesList}
                                                onChange={(item) => handleChange('patientType', item)}
                                                className={isError && isError.patientType.message
                                                    ? "error_border_select"
                                                    : ""}
                                            />
                                            {isError && isError.patientType.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError && isError.patientType.message}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="check_box">
                                        <Form.Check
                                            type="checkbox"
                                            label={'Emergency Admission'}
                                            value={values && values.emergencyAdmission}
                                            checked={values && parseInt(values.emergencyAdmission) === 1 ? true : false}
                                            onChange={(e) => handleEmergencyStatus(e)}
                                        />
                                    </div>
                                    {/* <div className="dropdown_box">
                                        <h6>Choose Department</h6>
                                        <div className="dropdown_area">
                                            <Select
                                                placeholder="Select Patient Type"
                                                value={values && values.department}
                                                options={departmentList}
                                                onChange={(item) => (handleChange('department', item), getAdminsListing(item && item.id))}
                                                className={isError && isError.department.message
                                                    ? "error_border"
                                                    : ""}
                                            />
                                            {isError && isError.department.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError && isError.department.message}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div> */}
                                    <div className="dropdown_box">
                                        <h6>Consultant<span>*</span></h6>
                                        <div className="dropdown_area">
                                            <Select
                                                placeholder="Select Consultant"
                                                // isDisabled={loaction && loaction.state && loaction.state.caseId ? true : false}
                                                value={values && values.consultant}
                                                options={consultantsList}
                                                onChange={(item) => handleChange('consultant', item)}
                                                className={isError && isError.consultant.message
                                                    ? "error_border_select"
                                                    : ""}
                                            />
                                            {isError && isError.consultant.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError && isError.consultant.message}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="btn_area">
                                        <Button
                                            className="cancelBtn"
                                            type="button"
                                            onClick={() => navigate(-1)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="submitBtn"
                                            type="button"
                                            onClick={() => handleSubmit()}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
            }
        </div>
    )
};

export default AddCase;