import React, { useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import {
    EditProfile1,
    EditProfile2
} from "../../../../assets/images";
import * as Constants from "../../../Helper/constants/constants";
import WithoutImageHeader from "../../../Helper/withoutImageHeader";



const EditProfile = () => {
    const [specialization, setSpecialization] = useState("");
    const [gender, setGender] = useState("");
    const [tenure, setTenure] = useState("");
    const [bloodgroup, setBloodgroup] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");

    
    return (
        <div className="edit_profile_screen">
            <WithoutImageHeader header={Constants.PROFILE_HEADER} />
            <div className="parent_area">
                <div className="innerr_area">
                    <Container>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="profile_area">
                                    <div className="image_area">
                                        <Image src={EditProfile1} alt="..." />
                                        <a href="javscript:;">
                                            <div className="editicon">
                                                <Image src={EditProfile2} alt="..." />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="form">
                                    <Form>
                                        <Row>
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Full Name<span>*</span></Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder="Harjinder Singh"
                                                        defaultValue=""
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Contact Number<span>*</span></Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder="+91-7987987987"
                                                        defaultValue=""
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>UH Id<span>*</span></Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder="7DH5738H"
                                                        defaultValue=""
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="harjinder12@gmail.com"
                                                        defaultValue=""
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Specialization<span>*</span></Form.Label>
                                                    <Form.Select
                                                        required
                                                        value={specialization}
                                                        onChange={(e) =>
                                                            setSpecialization(e.currentTarget.value)
                                                        }
                                                    >
                                                        <option disabled={true} value="">Select</option>
                                                        <option value="000">000</option>
                                                        <option value="111">111</option>
                                                        <option value="222">222</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Practicing tenure</Form.Label>
                                                    <Form.Select
                                                        required
                                                        value={tenure}
                                                        onChange={(e) => setTenure(e.currentTarget.value)}
                                                    >
                                                        <option disabled={true} value="">Select</option>
                                                        <option value="000">000</option>
                                                        <option value="111">111</option>
                                                        <option value="222">222</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                                                <Form.Group className="form-group" id="dob_box">
                                                    <Form.Label>Date of Birth*</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="date"
                                                        placeholder="DD/MM/YYYY"
                                                    />
                                                    {/* {<Datepicker />} 
                          <a href="javscript:;">
                            <div className="icon">
                              <Image src={Calender} alt="..." />
                            </div>
                          </a> */}
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Gender<span>*</span></Form.Label>
                                                    <Form.Select
                                                        required
                                                        value={gender}
                                                        onChange={(e) => setGender(e.currentTarget.value)}
                                                    >
                                                        <option disabled={true} value="">Select</option>
                                                        <option value="Male" >Male</option>
                                                        <option value="Female">Female</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Blood Group </Form.Label>
                                                    <Form.Select
                                                        required
                                                        value={bloodgroup}
                                                        onChange={(e) => setBloodgroup(e.currentTarget.value)}
                                                    >
                                                        <option disabled={true} value="">Select</option>
                                                        <option value="AB+">AB+</option>
                                                        <option value="A">A</option>
                                                        <option value="B">B</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Address<span>*</span></Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="type"
                                                        placeholder="Type here"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>City<span>*</span></Form.Label>
                                                    <Form.Select
                                                        required
                                                        value={city}
                                                        onChange={(e) => setCity(e.currentTarget.value)}
                                                    >
                                                        <option disabled={true} value="">Select</option>
                                                        <option value="000">000</option>
                                                        <option value="111">111</option>
                                                        <option value="222">222</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <Form.Group
                                                    className="form-group"
                                                    controlId="SelectState"
                                                >
                                                    <Form.Label>State<span>*</span></Form.Label>
                                                    <Form.Select
                                                        required
                                                        value={state}
                                                        onChange={(e) => setState(e.currentTarget.value)}
                                                    >
                                                        <option disabled={true} value="">Select</option>
                                                        <option value="000">000</option>
                                                        <option value="111">111</option>
                                                        <option value="222">222</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <div className="btn_area">
                                                <Button className="cancelBtn" type="button">
                                                    Cancel
                                                </Button>
                                                <Button className="submitBtn" type="submit">
                                                    Submit
                                                </Button>
                                            </div>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default EditProfile;
