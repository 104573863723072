import { faCalendarWeek, faEllipsisVertical, faVenus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Card1 } from '../../../../assets/images';

const History = () => {
    return (
        <div className='history_tab_screen'>
            <Card>
                <Card.Body>
                    <div className="card_left">
                        <div className="img_area">
                            <Card.Img variant="left" src={Card1} alt="..." />
                        </div>
                    </div>
                    <div className="card_right">
                        <div className="personal_data">
                            <div className="name_number_area">
                                <div className="data">
                                    <h3>Robert William</h3>
                                    <span>MR Number: 198293 <FontAwesomeIcon icon={faEllipsisVertical} className="ellipsis_icon" /></span>
                                </div>

                            </div>
                            <h4>Ebola haemorrhagic fever.</h4>
                            <p>Female <FontAwesomeIcon icon={faVenus} className="gender_icon" /></p>
                            <div className="date_information">
                                <div className="date">
                                    <FontAwesomeIcon icon={faCalendarWeek} className="date_icon" />
                                    <p>5 July, 2023</p>
                                </div>
                                <Button type="button">Discharge</Button>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>

            <Card>
                <Card.Body>
                    <div className="card_left">
                        <div className="img_area">
                            <Card.Img variant="left" src={Card1} alt="..." />
                        </div>
                    </div>
                    <div className="card_right">
                        <div className="personal_data">
                            <div className="name_number_area">
                                <div className="data">
                                    <h3>Robert William</h3>
                                    <span>MR Number: 198293 <FontAwesomeIcon icon={faEllipsisVertical} className="ellipsis_icon" /></span>
                                </div>

                            </div>
                            <h4>Ebola haemorrhagic fever.</h4>
                            <p>Female <FontAwesomeIcon icon={faVenus} className="gender_icon" /></p>
                            <div className="date_information">
                                <div className="date">
                                    <FontAwesomeIcon icon={faCalendarWeek} className="date_icon" />
                                    <p>5 July, 2023</p>
                                </div>
                                <Button type="button">Discharge</Button>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default History;