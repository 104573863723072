import React, { useState } from 'react';




const AutocompleteInput = (props) => {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);


    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setValue(inputValue);
        props.onChange(inputValue);
        // Here, you can make an API call or perform any other logic to fetch autocomplete suggestions
        // For simplicity, let's assume the suggestions are fetched synchronously from a local array

        // Example array of suggestions
        const suggestionsArray = props.data && inputValue.length > 0 ? props.data : []

        // Filter the suggestions based on the input value
        const filteredSuggestions = suggestionsArray.filter(suggestion => suggestion.label.toLowerCase().startsWith(inputValue.toLowerCase())
        );
        let data = []
        for (let i in filteredSuggestions) {
            data.push(filteredSuggestions[i].label)
        }
        setSuggestions(data);
    };


    const handleSuggestionClick = (suggestion) => {
        setValue(suggestion);
        props.onChange(suggestion);
        setSuggestions([]);
    };


    return (
        <div className='input_auto_area'>
            <input
                type="text"
                value={props && props.value ? props.value : value}
                // className={props && props.isError ? 'error_border' : ""}
                onChange={handleInputChange}
                placeholder={props && props.placeholder ? props.placeholder : "Type to search..."}
                className='form-control'
            />

            {suggestions.length > 0 && (
                <ul className='menu_listing_area'>
                    {suggestions.map((suggestion, index) => (
                        <li key={index} onClick={() => handleSuggestionClick(suggestion)} style={{ cursor: 'pointer' }}>
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AutocompleteInput;
