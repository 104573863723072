import { Toaster } from "../../../components/Helper/toaster/Toaster";
import { patientServices } from "../../Services/PatientServices/patient.services";


const patientListing = async (params) => {
    let response = await patientServices.patientList(params);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const doctorPatientList = async (params) => {
    let response = await patientServices.doctorPatientList(params);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const existPatientListing = async (params, id) => {
    let response = await patientServices.existPatientList(params, id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
}


const patientDetail = async (id) => {
    let response = await patientServices.patientDetails(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const patientCreateStepOne = async (data) => {
    let arry = []
    let hospitalData = data.hospitalId
    if (hospitalData) {
        arry.push(hospitalData.id)
    }
    else {
        arry = []
    }
    let post = {
        hospital_id: arry,
        image: data && data.image ? data.image : null,
        uh_id: data && data.uhId ? data.uhId : null,
        patient_name: data && data.patientName ? data.patientName : null,
        ward_id: data && data.ward ? data.ward.id : null,
        room_id: data && data.roomNo ? data.roomNo.id : null,
        bed_id: data && data.bedNo ? data.bedNo.id : null,
        emergency_admission: data && data.emergencyAdmission ? data.emergencyAdmission : 0,
        patient_type: data && data.patientType ? data.patientType.id : null,
        patient_status: data && data.patientStatus ? data.patientStatus.value : null,
        date_of_admission: data && data.dateOfAdmission ? data.dateOfAdmission : null,
        //<-----------------------common data-------------------------------------------
        father_name: data && data.fatherOrHusbandName ? data.fatherOrHusbandName : null,
        mother_name: data && data.motherName ? data.motherName : null,
        dob: data && data.dob ? data.dob : null,
        gender: data && data.gender ? data.gender.id : null,
        blood_group: data && data.bloodGroup ? data.bloodGroup.id : null,
        age: data && data.age ? data.age : null,
        marital_status: data && data.martialStatus ? data.martialStatus.id : null,
        occupation: data && data.occupation ? data.occupation : null,
        nationlity: data && data.nationality ? data.nationality.id : null,
        adhaar_card: data && data.adharNumber ? data.adharNumber : null,
        steps: data && data.step ? data.step : 1
    }
    let response = await patientServices.patientCreateStepOne(post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const patientUpdateStepOne = async (patientId, data) => {
    let arry = []
    let hospitalData = data.hospitalId
    if (hospitalData) {
        arry.push(hospitalData.id)
    }
    else {
        arry = []
    }
    let post = {
        hospital_id: arry,
        image: data && data.image ? data.image : null,
        patient_name: data && data.patientName ? data.patientName : null,
        ward_id: data && data.ward ? data.ward.id : null,
        room_id: data && data.roomNo ? data.roomNo.id : null,
        bed_id: data && data.bedNo ? data.bedNo.id : null,
        emergency_admission: data && data.emergencyAdmission ? data.emergencyAdmission : 0,
        patient_type: data && data.patientType ? data.patientType.id : null,
        patient_status: data && data.patientStatus ? data.patientStatus.value : null,
        date_of_admission: data && data.dateOfAdmission ? data.dateOfAdmission : null,
        //<-----------------------common data-------------------------------------------
        father_name: data && data.fatherOrHusbandName ? data.fatherOrHusbandName : null,
        mother_name: data && data.motherName ? data.motherName : null,
        dob: data && data.dob ? data.dob : null,
        gender: data && data.gender ? data.gender.id : null,
        blood_group: data && data.bloodGroup ? data.bloodGroup.id : null,
        age: data && data.age ? data.age : null,
        marital_status: data && data.martialStatus ? data.martialStatus.id : null,
        occupation: data && data.occupation ? data.occupation : null,
        nationlity: data && data.nationality ? data.nationality.id : null,
        adhaar_card: data && data.adharNumber ? data.adharNumber : null,
    }
    let response = await patientServices.patientUpdateStepOne(patientId, post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};



const patientCreateStepTwo = async (patientId, stepOne, two) => {
    let arry = []
    let hospitalData = stepOne.hospitalId
    if (hospitalData) {
        arry.push(hospitalData.id)
    }
    else {
        arry = []
    }
    let post = {
        hospital_id: arry,
        image: stepOne && stepOne.image ? stepOne.image : null,
        uh_id: stepOne && stepOne.uhId ? stepOne.uhId : null,
        patient_name: stepOne && stepOne.patientName ? stepOne.patientName : null,
        ward_id: stepOne && stepOne.ward ? stepOne.ward.id : null,
        room_id: stepOne && stepOne.roomNo ? stepOne.roomNo.id : null,
        bed_id: stepOne && stepOne.bedNo ? stepOne.bedNo.id : null,
        emergency_admission: stepOne && stepOne.emergencyAdmission ? stepOne.emergencyAdmission : 0,
        patient_type: stepOne && stepOne.patientType ? stepOne.patientType.id : null,
        patient_status: stepOne && stepOne.patientStatus ? stepOne.patientStatus.value : null,
        date_of_admission: stepOne && stepOne.dateOfAdmission ? stepOne.dateOfAdmission : null,
        //<---------------Second step data----------------------------------------------
        address: two && two.address ? two.address : null,
        area: two && two.area ? two.area : null,
        pincode: two && two.pincode ? two.pincode : null,
        district_id: two && two.districtData ? two.districtData.id : null,
        state_id: two && two.stateData ? two.stateData.id : null,
        city_id: two && two.cityData ? two.cityData.id : null,
        contact_number: two && two.contactNumber ? two.contactNumber : null,
        alternative_number: two && two.alternativeNumber ? two.alternativeNumber : null,
        email: two && two.email ? two.email : null,
        steps: two && two.steps ? two.steps : 2
    }
    let response = await patientServices.patientCreateStepTwo(patientId, post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const patientCreateStepThree = async (patientId, stepOne, data) => {
    let arry = []
    let hospitalData = stepOne.hospitalId
    if (hospitalData) {
        arry.push(hospitalData.id)
    }
    else {
        arry = []
    }
    let post = {
        hospital_id: arry,
        image: stepOne && stepOne.image ? stepOne.image : null,
        uh_id: stepOne && stepOne.uhId ? stepOne.uhId : null,
        patient_name: stepOne && stepOne.patientName ? stepOne.patientName : null,
        ward_id: stepOne && stepOne.ward ? stepOne.ward.id : null,
        room_id: stepOne && stepOne.roomNo ? stepOne.roomNo.id : null,
        bed_id: stepOne && stepOne.bedNo ? stepOne.bedNo.id : null,
        emergency_admission: stepOne && stepOne.emergencyAdmission ? stepOne.emergencyAdmission : 0,
        patient_type: stepOne && stepOne.patientType ? stepOne.patientType.id : null,
        patient_status: stepOne && stepOne.patientStatus ? stepOne.patientStatus.value : null,
        date_of_admission: stepOne && stepOne.dateOfAdmission ? stepOne.dateOfAdmission : null,
        //<---------------Third step data --------------------------------
        consultant_id: data && data.consultantData ? data.consultantData.id : null,
        case_title: data && data.caseTitle ? data.caseTitle : null,
        tag: data && data.tag ? data.tag : null,
        summary: data && data.summary ? data.summary : null,
        steps: data && data.steps ? data.steps : 3
    }
    let response = await patientServices.patientCreateStepThree(patientId, post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const patientCreateStepFour = async (patientId, stepOne, data) => {
    let arry = []
    let hospitalData = stepOne.hospitalId
    if (hospitalData) {
        arry.push(hospitalData.id)
    }
    else {
        arry = []
    }
    let post = {
        hospital_id: arry,
        image: stepOne && stepOne.image ? stepOne.image : null,
        uh_id: stepOne && stepOne.uhId ? stepOne.uhId : null,
        patient_name: stepOne && stepOne.patientName ? stepOne.patientName : null,
        ward_id: stepOne && stepOne.ward ? stepOne.ward.id : null,
        room_id: stepOne && stepOne.roomNo ? stepOne.roomNo.id : null,
        bed_id: stepOne && stepOne.bedNo ? stepOne.bedNo.id : null,
        emergency_admission: stepOne && stepOne.emergencyAdmission ? stepOne.emergencyAdmission : 0,
        patient_type: stepOne && stepOne.patientType ? stepOne.patientType.id : null,
        patient_status: stepOne && stepOne.patientStatus ? stepOne.patientStatus.value : null,
        date_of_admission: stepOne && stepOne.dateOfAdmission ? stepOne.dateOfAdmission : null,
        //<---------------Third step data ----------------------------------------------------
        temperature: data && data.temperature ? data.temperature : null,
        weight: data && data.weight ? data.weight : null,
        heart_beat: data && data.heartBeat ? data.heartBeat : null,
        blood_pressure_top: data && data.bloodPressureTop ? data.bloodPressureTop : null,
        blood_pressure_bottom: data && data.bloodPressureBottom ? data.bloodPressureBottom : null,
        oxygen_saturation_top: data && data.oxygenSaturationTop ? data.oxygenSaturationTop : null,
        oxygen_saturation_bottom: data && data.oxygenSaturationBottom ? data.oxygenSaturationBottom : null,
        blood_suger: data && data.bloodSuger ? data.bloodSuger : null,
        steps: data && data.steps ? data.steps : 4
    }
    let response = await patientServices.patientCreateStepFour(patientId, post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const updatePatientDetail = async (patientId, data) => {
    let post = {
        hospital_id: data && data.hospitalId ? [data.hospitalId.id] : [],
        image: data && data.image ? data.image : null,
        patient_name: data && data.patientName ? data.patientName : null,
        father_name: data && data.fatherName ? data.fatherName : null,
        mother_name: data && data.motherName ? data.motherName : null,
        dob: data && data.dob ? data.dob : null,
        age: data && data.age ? data.age : null,
        date_of_admission: data && data.dateOfAdmission ? data.dateOfAdmission : null,
        gender: data && data.gender ? data.gender.id : null,
        blood_group: data && data.bloodGroup ? data.bloodGroup.id : null,
        marital_status: data && data.maritalStatus ? data.maritalStatus.id : null,
        occupation: data && data.occupation ? data.occupation : null,
        nationlity: data && data.nationlity ? data.nationlity.id : null,
        address: data && data.address ? data.address : null,
        area: data && data.area ? data.area : null,
        pincode: data && data.pincode ? data.pincode : null,
        district_id: data && data.districtData ? data.districtData.id : null,
        state_id: data && data.stateData ? data.stateData.id : null,
        city_id: data && data.cityData ? data.cityData.id : null,
        contact_number: data && data.contactNumber ? data.contactNumber : null,
        alternative_number: data && data.alternativeNumber ? data.alternativeNumber : null,
        email: data && data.email ? data.email : null,
        adhaar_card: data && data.adhaarCard ? data.adhaarCard : null
    }
    let response = await patientServices.updatePatientDetail(patientId, post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const patientUpdate = async (id) => {
    let response = await patientServices.patientUpdate(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const patientDelete = async (id) => {
    let response = await patientServices.patientDelete(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const updatePatientStatus = async (id, params) => {
    let response = await patientServices.updatePatientStatus(id, params);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const todayPatientList = async (id) => {
    let response = await patientServices.todayPatientList(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const addPatientNew = async (stepOne, stepTwo, stepThree, stepFour) => {
    let arry = []
    let hospitalData = stepOne.hospitalId
    if (hospitalData) {
        arry.push(hospitalData.id)
    }
    else {
        arry = []
    }
    let post = {
        hospital_id: arry,
        image: stepOne && stepOne.image ? stepOne.image : null,
        uh_id: stepOne && stepOne.uhId ? stepOne.uhId : null,
        patient_name: stepOne && stepOne.patientName ? stepOne.patientName : null,
        ward_id: stepOne && stepOne.ward ? stepOne.ward.id : null,
        room_id: stepOne && stepOne.roomNo ? stepOne.roomNo.id : null,
        bed_id: stepOne && stepOne.bedNo ? stepOne.bedNo.id : null,
        emergency_admission: stepOne && stepOne.emergencyAdmission ? stepOne.emergencyAdmission : 0,
        patient_type: stepOne && stepOne.patientType ? stepOne.patientType.id : null,
        patient_status: stepOne && stepOne.patientStatus ? stepOne.patientStatus.value : null,
        date_of_admission: stepOne && stepOne.dateOfAdmission ? stepOne.dateOfAdmission : null,
        //<---------------first step data ----------------------------------------------------
        father_name: stepOne && stepOne.fatherOrHusbandName ? stepOne.fatherOrHusbandName : null,
        mother_name: stepOne && stepOne.motherName ? stepOne.motherName : null,
        dob: stepOne && stepOne.dob ? stepOne.dob : null,
        gender: stepOne && stepOne.gender ? stepOne.gender.id : null,
        blood_group: stepOne && stepOne.bloodGroup ? stepOne.bloodGroup.id : null,
        age: stepOne && stepOne.age ? stepOne.age : null,
        marital_status: stepOne && stepOne.martialStatus ? stepOne.martialStatus.id : null,
        occupation: stepOne && stepOne.occupation ? stepOne.occupation : null,
        nationlity: stepOne && stepOne.nationality ? stepOne.nationality.id : null,
        adhaar_card: stepOne && stepOne.adharNumber ? stepOne.adharNumber : null,
        //<---------------Second step data----------------------------------------------
        address: stepTwo && stepTwo.address ? stepTwo.address : null,
        area: stepTwo && stepTwo.area ? stepTwo.area : null,
        pincode: stepTwo && stepTwo.pincode ? stepTwo.pincode : null,
        district_id: stepTwo && stepTwo.districtData ? stepTwo.districtData.id : null,
        state_id: stepTwo && stepTwo.stateData ? stepTwo.stateData.id : null,
        city_id: stepTwo && stepTwo.cityData ? stepTwo.cityData.id : null,
        contact_number: stepTwo && stepTwo.contactNumber ? stepTwo.contactNumber : null,
        alternative_number: stepTwo && stepTwo.alternativeNumber ? stepTwo.alternativeNumber : null,
        email: stepTwo && stepTwo.email ? stepTwo.email : null,
        //<---------------Third step data --------------------------------
        consultant_id: stepThree && stepThree.consultantData ? stepThree.consultantData.id : null,
        case_title: stepThree && stepThree.caseTitle ? stepThree.caseTitle : null,
        tag: stepThree && stepThree.tag ? stepThree.tag : null,
        summary: stepThree && stepThree.summary ? stepThree.summary : null,
        //<---------------fourth step data ----------------------------------------------------
        temperature: stepFour && stepFour.temperature ? stepFour.temperature : null,
        weight: stepFour && stepFour.weight ? stepFour.weight : null,
        heart_beat: stepFour && stepFour.heartBeat ? stepFour.heartBeat : null,
        blood_pressure_top: stepFour && stepFour.bloodPressureTop ? stepFour.bloodPressureTop : null,
        blood_pressure_bottom: stepFour && stepFour.bloodPressureBottom ? stepFour.bloodPressureBottom : null,
        oxygen_saturation_top: stepFour && stepFour.oxygenSaturationTop ? stepFour.oxygenSaturationTop : null,
        oxygen_saturation_bottom: stepFour && stepFour.oxygenSaturationBottom ? stepFour.oxygenSaturationBottom : null,
        blood_suger: stepFour && stepFour.bloodSuger ? stepFour.bloodSuger : null,
        steps: stepFour && stepFour.steps ? stepFour.steps : 4
    }
    let response = await patientServices.addPatientNew(post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const patientController = {
    patientListing,
    doctorPatientList,
    patientDetail,
    patientCreateStepOne,
    patientCreateStepTwo,
    patientCreateStepThree,
    patientCreateStepFour,
    updatePatientDetail,
    patientUpdate,
    patientDelete,
    updatePatientStatus,
    patientUpdateStepOne,
    todayPatientList,
    existPatientListing,
    addPatientNew
};

export default patientController;
