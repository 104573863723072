import { Toaster } from "../../../components/Helper/toaster/Toaster";
import { setHospital } from "../../../redux/actions/users";
import store from "../../../redux/store";
import { hospitalServices } from "../../Services/HospitalServices/hospital.services";


const hospitalListing = async (params) => {
    let response = await hospitalServices.hospitalList(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const hospitalListDashboard = async (params) => {
    let response = await hospitalServices.hospitalListDashboard(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const createHospital = async (data) => {
    let post = {
        image: data.image ? data.image : null,
        name: data && data.name ? data.name : null,
        phonenumber: data && data.phonenumber ? data.phonenumber : null,
        telephone_number: data && data.telephonenumber ? data.telephonenumber : null,
        email: data && data.email ? data.email : null,
        tag: null,
        gst_number: data && data.gstNumber ? data.gstNumber : null,
        address: data && data.address ? data.address : null,
        city: data && data.city ? data.city.id : null,
        state: data && data.state ? data.state.id : null,
        zipcode: data && data.zipcode ? data.zipcode : null,
        map_link: data && data.mapLink ? data.mapLink : null
    }
    let response = await hospitalServices.createHospital(post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const hospitalDetail = async (id) => {
    let response = await hospitalServices.hospitalDetail(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const updateHospital = async (data, id) => {
    let post = {
        image: data.image ? data.image : null,
        name: data && data.name ? data.name : null,
        phonenumber: data && data.phonenumber ? data.phonenumber : null,
        telephone_number: data && data.telephonenumber ? data.telephonenumber : null,
        email: data && data.email ? data.email : null,
        tag: null,
        gst_number: data && data.gstNumber ? data.gstNumber : null,
        address: data && data.address ? data.address : null,
        city: data && data.city ? data.city.id : null,
        state: data && data.state ? data.state.id : null,
        zipcode: data && data.zipcode ? data.zipcode : null,
        map_link: data && data.mapLink ? data.mapLink : null
    }
    let response = await hospitalServices.updateHospital(post, id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const deleteHospital = async (id) => {
    let response = await hospitalServices.deleteHospital(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const setUpHospital= async (item) => {
    await localStorage.setItem("SET_HOSPITAL", JSON.stringify(item));
    await store.dispatch(setHospital(item));
    return item;
};

const getHospitalState = async () => {
    let hospital = await store.getState().HospitalReducer.hospital;
    return hospital;
};


const hospitalController = {
    hospitalListing,
    hospitalListDashboard,
    createHospital,
    hospitalDetail,
    updateHospital,
    deleteHospital,
    setUpHospital,
    getHospitalState
};

export default hospitalController;
