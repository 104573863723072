import React, { useState } from 'react'
import { actionsController } from '../../../../apis/Controllers/CommonController/action.controller';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toaster } from '../../../Helper/toaster/Toaster';
import medicineController from '../../../../apis/Controllers/MedicineController/medicine.controller';
import store from '../../../../redux/store';



const AssignMedicineFunction = () => {
    const defaultErrors = {
        brand_name: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        medicine_name: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        salt: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        dosage: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        route: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        frequency: {
            rules: ["required"],
            isValid: true,
            message: "",
        },

    };
    const [isError, setError] = useState([defaultErrors]);
    const [loader, setLoader] = useState(false);
    const breadcrumData = ['Dashboard', "Patient List", 'Patient Detail'];
    const breadcrumItem = ['Assign Medicine'];
    const [numMedicine, setNumMedicine] = useState([{
        brand_name: '',
        medicine_name: '',
        frequency: '',
        route: '',
        dosage: '',
        salt: '',
    }]);
    const [medicineFrequenciesList, setMedicineFrequenciesList] = useState([]);
    const [brandSuggestionsList, setBrandSuggestionsList] = useState([]);
    const [medicineSuggestionsList, setMedicineSuggestionsList] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();


    const handleChange = (index, field, value) => {
        const updatedMedicines = [...numMedicine];
        updatedMedicines[index][field] = value;
        setNumMedicine(updatedMedicines);
        const updatedErrors = [...isError];
        const fieldErrors = { ...updatedErrors[index] };
        fieldErrors[field].isValid = true;
        fieldErrors[field].message = "";
        updatedErrors[index] = fieldErrors;
        setError(updatedErrors);
    };


    const addMedicineForm = () => {
        const newMedicine = {
            brand_name: '',
            medicine_name: '',
            frequency: '',
            route: '',
            dosage: '',
            salt: '',
        };
        const updatedMedicines = [...numMedicine, newMedicine];
        setNumMedicine(updatedMedicines);
        const updatedErrors = [...isError, defaultErrors];
        setError(updatedErrors);
    };


    const validateForm = () => {
        const updatedErrors = [...isError];
        numMedicine.forEach((item, index) => {
            Object.keys(item).forEach((field) => {
                if (defaultErrors[field].rules.includes("required") && !item[field]) {
                    updatedErrors[index][field].isValid = false;
                    updatedErrors[index][field].message = "This field is required";
                }
            });
        });
        return updatedErrors;
    };



    const handleSubmit = async () => {
        const updatedErrors = validateForm();
        setError(updatedErrors);
        const isValid = updatedErrors.every((error) =>
            Object.values(error).every((field) => field.isValid)
        );
        if (isValid) {
            let patientId = location && location.state && location.state.patientId
            let caseId = location && location.state && location.state.patientCaseId
            setLoader(true);
            for (let i in numMedicine) {
                numMedicine[i]['patient_id'] = patientId
                numMedicine[i]['case_id'] = caseId
                for (let j in numMedicine[i].frequency) {
                    let id = numMedicine[i].frequency[j].id
                    numMedicine[i].frequency[j] = id
                }
            }
            let response = await medicineController.medicineCreate(numMedicine);
            if (response && response.status) {
                new Toaster().success(response.message);
                navigate(`/patientDetails/${patientId}`, {
                    replace: true,
                    state: {
                        name: 'fromAssignMedicine'
                    }
                })
                // navigate(-1, { state: 'fromAssignMedicine' })
                setLoader(false);
            }
            else {
                setLoader(false);
            }
        }
    };


    const getMedicineFrequencyList = async () => {
        let response = await actionsController.medicineFrequencyList();
        if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]['label'] = data[i].title
                data[i]['value'] = data[i].title
            }
            setMedicineFrequenciesList(data);
        }
        else {
            setMedicineFrequenciesList([]);
        }
    };


    const getBrandSuggestionsList = async (e) => {
        let post = {
            search: e ? e : ''
        }
        let response = await medicineController.brandNameSuggestionsListing(post);
        if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]['label'] = data[i].brand_name
            }
            setBrandSuggestionsList(data);
        }
        else {
            setBrandSuggestionsList([]);
        }
    };


    const getMedicineSuggestionsList = async (e) => {
        let post = {
            search: e ? e : ''
        }
        let response = await medicineController.medicineNameSuggestionsList(post);
        if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]['label'] = data[i].medicine_name
            }
            setMedicineSuggestionsList(data);
        }
        else {
            setMedicineSuggestionsList([]);
        }
    };


    const removeMedicineForm = (item) => {
        let array = [...numMedicine];
        if (item) {
            let index = array.indexOf(item);
            if (index > -1) {
                array.splice(index, 1);
            } else return array
            setNumMedicine(array)
        } else;
    };


    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate('/dashboard');
            }
            else {
                navigate('/dashboard-superadmin');
            }
        }
        else if (i === 1) {
            navigate('/manage-patient-list');
        }
        else if (i === 2) {
            // navigate(`/patientDetails/${location.state.patientId}`, {
            //     replace: true,
            //     state: {
            //         name: 'fromAssignMedicine'
            //     }
            // })
            navigate(-1, { state: { name: 'fromAssignMedicine' } })
        }
        else return;
    };


    return {
        breadcrumData,
        breadcrumItem,
        isError,
        handleChange,
        handleSubmit,
        loader,
        addMedicineForm,
        removeMedicineForm,
        numMedicine,
        getMedicineFrequencyList,
        medicineFrequenciesList,
        location,
        navigate,
        brandSuggestionsList,
        getBrandSuggestionsList,
        getMedicineSuggestionsList,
        medicineSuggestionsList,
        handleBreadcrumDataNaviagtion,
        setBrandSuggestionsList,
        setMedicineFrequenciesList,
        setMedicineSuggestionsList
    }
}

export default AssignMedicineFunction