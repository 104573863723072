import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import adminController from "../../../../apis/Controllers/AdminControler/admin.controller";
import { actionsController } from "../../../../apis/Controllers/CommonController/action.controller";
import patientCasesController from "../../../../apis/Controllers/PatientCasesController/patientCases.controller";
import patientController from "../../../../apis/Controllers/PatientController/patient.controller";
import taskController from "../../../../apis/Controllers/TaskController/task.controller";
import { Toaster } from "../../../Helper/toaster/Toaster";
import Validation from "../../../Helper/validations/vaildation";
import store from "../../../../redux/store";


const AddTaskFunctions = () => {
    const [taskModal, setTaskModal] = useState(false);
    const [taskResponse, setTaskResponse] = useState(null);
    const defaultValues = {
        assignTo: [],
        patientData: null,
        // caseId: null,
        title: null,
        description: null,
        priority: null,
        startDate: null,
        startTime: null,
        endTime: null,
    };

    const defaultErrors = {
        assignTo: {
            rules: ["array"],
            isValid: true,
            message: "",
        },
        patientData: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        // caseId: {
        //     rules: [],
        //     isValid: true,
        //     message: "",
        // },
        title: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        description: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        priority: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        startDate: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        startTime: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        endTime: {
            rules: [],
            isValid: true,
            message: "",
        },
    };

    var breadcrumData = ["Dashboard", "Task list"];
    const [breadcrumItem, setbreadcrumItem] = useState("Add Task");

    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [loader, setLoader] = useState(false);
    const [adminList, setAdminList] = useState([]);
    const [patientsList, setPatientsList] = useState([]);
    const [taskPriorities, setTaskPriorities] = useState([]);
    const [selectedAssigntedTo, setSelectedAssigntedTo] = useState([]);
    const [assignTaskModal, setAssignTaskModal] = useState(false);
    const [adminListSearch, setAdminListSearch] = useState('');
    const [patientCases, setPatientCases] = useState([]);
    const [dropdownIcon, setDropdownIcon] = useState(false);
    const [dropdownIcon2, setDropdownIcon2] = useState(false);
    const [focus, setFocus] = useState(false);
    const inputCal = useRef(null);
    const loaction = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        if (values && values.patientData) {
            let id = values.patientData && values.patientData.patientId ? values.patientData.patientId : ''
            getPetientCaseListing(id);
        }
    }, [values.patientData]);


    const handleChange = (field, value) => {
        let validation = new Validation(isError);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };


    const onWardClick = () => {
        if (values && values.patientData === null) {
            new Toaster().error('Please select patient')
        }
        else return;
    };


    const onPatientCaseClick = () => {
        if (values && values.patientData === null) {
            new Toaster().error('Please select patient name')
        }
        else return;
    };


    const handleStartTimeDisable = () => {
        if (values.startDate === null) {
            new Toaster().error('Please select task assign date');
        }
        else return;
    };


    const handleEndTimeDisable = () => {
        if (values.startTime === null) {
            new Toaster().error('Please select task start time');
        }
        else return;
    };


    const handleStartTime = (e) => {
        if (values.startDate) {
            if (loaction && loaction.state && loaction.state.name) {
                if (values.startTime > e.target.value) {
                    new Toaster().error('Please select start time greater than or equal to present time');
                }
                else {
                    handleChange("startTime", e.target.value);
                }
            }
            else if (values.startDate === moment().format("YYYY-MM-DD")) {
                if (e.target.value < moment().format("HH:mm")) {
                    new Toaster().error('Please select future time');
                }
                else {
                    handleChange("startTime", e.target.value);
                }
            }
            else {
                handleChange("startTime", e.target.value);
            }
        }
        else {
            new Toaster().error('Please select task assign date');
        }
    };


    const handleEndTime = (e) => {
        if (values.startDate) {
            if (values.startTime) {
                // if (values.startDate === moment().format("YYYY-MM-DD")) {
                if (values && values.startTime >= e.target.value) {
                    new Toaster().error('Task end time shoud be greater than task start time');
                }
                else {
                    handleChange("endTime", e.target.value);
                }
                // }
                // else {
                //     handleChange("endTime", e.target.value);
                // }
            }
            else {
                new Toaster().error('Please select task start time');
            }
        }
        else {
            new Toaster().error('Please select task assign date');
        }

    };


    const handlePatientDataOnChange = (item) => {
        if (item) {
            let data = {
                "patientName": item && item.patient_name ? item.patient_name : '',
                "patientId": item && item.id ? item.id : '',
                "hospitalId": item && item.hospital_id ? item.hospital_id : '',
                "caseId": item && item.active_case[0] && item.active_case[0].id ? item.active_case[0].id : '',
                "caseTitle": item && item.active_case[0] && item.active_case[0].case_title ? item.active_case[0].case_title : '',
                "wardId": item && item.ward_id ? item.ward_id : '',
                "wardName": item && item.ward_name ? item.ward_name : '',
                "label": item && item.patient_name ? item.patient_name : '',
                "id": item && item.id ? item.id : ''
            }
            handleChange('patientData', data);
        }
        else return;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            setButtonLoader(true);
            if (loaction && loaction.state && loaction.state.name) {                                 // <-----------UPDATE TASK
                let id = loaction && loaction.state && loaction.state.id ? loaction.state.id : ''
                let response = await taskController.taskUpdate(values, id);
                if (response && response.status) {
                    new Toaster().success(response.message);
                    navigate(-1, { replace: true });
                    setButtonLoader(false);
                } else {
                    setButtonLoader(false);
                }
            }
            else {
                let response = await taskController.taskCreate(values);                              // <-----------ADD TASK
                if (response && response.status) {
                    // new Toaster().success(response.message);
                    // navigate("/task-list");
                    setTaskResponse(response.data)
                    setButtonLoader(false);
                    setTaskModal(true);
                } else {
                    setButtonLoader(false);
                }
            }
        } else {
            setError({ ...isValid.errors });
        }
    };


    const getTaskDetail = async (adminslist) => {
        let id = loaction && loaction.state && loaction.state.id ? loaction.state.id : ''
        let arry = [...adminslist];
        setLoader(true);
        let response = await taskController.taskDetail(id);
        if (response && response.status) {
            let data = response.data
            let cases = response.data.cases
            let aasigndMembers = response.data.assigned_members
            // let casedata = null
            let patientDetail = null
            for (let i in cases) {
                if (parseInt(cases[i].case_status) === 1) {

                    // casedata = cases[i].id ? cases[i].id : ''
                    patientDetail = {
                        "patientId": data && data.patient_id ? data.patient_id : '',
                        "patientName": data && data.patient_name ? data.patient_name : '',
                        "hospitalId": cases[i].hospital_id ? cases[i].hospital_id : '',
                        "caseId": cases[i].id ? cases[i].id : '',
                        "caseTitle": cases[i].case_title ? cases[i].case_title : '',
                        "wardId": cases[i].ward_id ? cases[i].ward_id : '',
                        "wardName": cases[i].ward_title ? cases[i].ward_title : '',
                        "label": data && data.patient_name ? data.patient_name : '',
                        "id": cases && cases[i].id ? cases[i].id : ''
                    }
                }
            }
            setValues({
                ...values,
                assignTo: data.assigned_members ? data.assigned_members : [],
                patientData: patientDetail,
                // caseId: casedata ? casedata : null,
                title: data && data.title ? data.title : null,
                description: data && data.description ? data.description : null,
                priority: data && parseInt(data.priority) === 1 ? { title: "Low", id: "1" } : parseInt(data.priority) === 2 ? { title: "Medium", id: "2" } : { title: 'High', id: "3" },      //<--priority name pending from backend
                startDate: data && data.start_date ? data.start_date : null,
                startTime: data && data.start_time ? data.start_time : null,
                endTime: data && data.end_time ? data.end_time : null,
            });

            // <-----------------------------handle assigned members Pop up --------------------------
            setSelectedAssigntedTo(aasigndMembers);
            arry.forEach((b) => {
                aasigndMembers.forEach((a) => {
                    if (parseInt(b.id) === parseInt(a.admin_id)) {
                        b.checked = true;
                    }
                })
            })
            setAdminList(arry);
            setLoader(false);
        }
        else {
            setLoader(false);
        }
    };


    const getPatientList = async () => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        let post = {
            hospital_id: item ? [item.id] : []
        }
        let response = await patientController.patientListing(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].patient_name
            }
            setPatientsList(data);
        }
        else {
            setPatientsList([]);
        }
    };


    const getTaskPeriorities = async () => {
        let response = await actionsController.taskPriorities();
        if (response && response.status) {
            setTaskPriorities(response.data);
        }
        else {
            setTaskPriorities([]);
        }
    };


    const getAdminsList = async () => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        let post = {
            hospital_id: item ? item.id : null
        }
        let response = await adminController.allAdminsTaskList(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['checked'] = false
            }
            if (loaction && loaction.state && loaction.state.name) {          //<----------------EDIT TASK CASE
                getTaskDetail(data);
            }
            setAdminList(data);
        }
        else {
            setAdminList([]);
        }
    };


    const getPetientCaseListing = async (id) => {
        let post = {
            patient_id: id ? id : ''
        }
        let response = await patientCasesController.casesList(post);
        if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]['label'] = data[i].case_title
            }
            setPatientCases(response.data);
        }
        else {
            setPatientCases([]);
        }
    };


    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate('/dashboard');
            }
            else {
                navigate('/dashboard-superadmin');
            }
        }
        else if (i === 1) {
            if (user && parseInt(user.user_type) === 1) {
                navigate('/task-list');
            }
            else {
                navigate('/myTasks');
            }
        }
        else return;
    };


    //-----------------------------------------ASSIGNED TO :- POP UP FUNCTIONALITY ------------------------------>>

    const handleSelectAdmin = (item) => {                                              // <----------ON SELECT
        let array = [...selectedAssigntedTo]
        let admins = [...adminList]
        if (item) {
            let isExistIndex = array.findIndex((data) => data.admin_id ? data.admin_id === item.id : data.id === item.id);
            let selectAdminIndex = admins.findIndex((data) => data.id === item.id);
            if (isExistIndex === -1) {
                array.push(item);
            }
            else {
                array.splice(isExistIndex, 1);
            }
            if (selectAdminIndex !== -1) {
                let check = admins[selectAdminIndex].checked
                admins[selectAdminIndex].checked = !check
            }
            setSelectedAssigntedTo(array);
            setAdminList(admins);
        }
    };


    const handleModalSearch = (e) => {                                    // <-----------modal search
        setAdminListSearch(e.target.value);
    };


    const clearAllSelectedAssignedTo = () => {                            // <----------- clear all
        let admins = [...adminList]
        for (let i in admins) {
            admins[i].checked = false
        }
        setAdminList(admins);
        setSelectedAssigntedTo([]);
        handleChange('assignTo', []);
    };


    const handleApplyAssignedTo = () => {                                // <-----------    APPLY 
        handleChange('assignTo', selectedAssigntedTo);
    };


    const checkAssignedMembersValue = () => {                       //<---------------first time render modal
        let array = [...values.assignTo]
        let array2 = [...selectedAssigntedTo]
        const notExistModule = array.filter(({ id: roleId }) => !array2.some(({ id: dataId }) => roleId === dataId));
        let newAray = array2.concat(notExistModule);
        setSelectedAssigntedTo(newAray);
    };


    const checkAdminsListCheckedValue = () => {                     //<---------------first time render modal
        let array = [...values.assignTo]
        let array2 = [...adminList]
        array2.map((item) => {
            for (let i in array) {
                if (array[i].admin_id) {                      //<-----------from deatil get admin_id
                    if (item.id === array[i].admin_id) {
                        item.checked = true
                    }
                }
                else {                                      //<----------------addded by user then asssinTo admin_id will be == id
                    if (item.id === array[i].id) {
                        item.checked = true
                    }
                }
            }
        })
        setAdminList(array2);
    };


    const handleAsignMembersModalClose = () => {                         //<-----------------on modal close
        let array2 = [...adminList]
        for (let i in array2) {
            array2[i].checked = false
        }
        setAdminList(array2);
        setAssignTaskModal(false);
        setSelectedAssigntedTo([]);
    };

    // <<-----------------------------------------------------------------------------------------------------------
    const handleClick = () => {      //---------Date picker------
        if (!focus) {
            if (inputCal.current) {
                inputCal.current.showPicker();
            }
        }
    };


    return {
        breadcrumData,
        breadcrumItem,
        setbreadcrumItem,
        loaction,
        navigate,
        loader,
        buttonLoader,
        values,
        isError,
        handleChange,
        handlePatientDataOnChange,
        handleSubmit,
        getAdminsList,
        adminList,
        assignTaskModal,
        setAssignTaskModal,
        getPatientList,
        patientsList,
        getTaskPeriorities,
        taskPriorities,
        handleSelectAdmin,
        selectedAssigntedTo,
        handleModalSearch,
        clearAllSelectedAssignedTo,
        handleApplyAssignedTo,
        adminListSearch,
        patientCases,
        onWardClick,
        onPatientCaseClick,
        dropdownIcon,
        setDropdownIcon,
        dropdownIcon2,
        setDropdownIcon2,
        handleEndTime,
        handleStartTime,
        handleStartTimeDisable,
        handleEndTimeDisable,
        setSelectedAssigntedTo,
        checkAssignedMembersValue,
        checkAdminsListCheckedValue,
        handleAsignMembersModalClose,
        handleBreadcrumDataNaviagtion,
        taskModal,
        setTaskModal,
        taskResponse,
        getPetientCaseListing,
        setValues,
        focus,
        setFocus,
        handleClick,
        inputCal
    }
};

export default AddTaskFunctions;