import { Toaster } from "../../../components/Helper/toaster/Toaster";
import { medicineServices } from "../../Services/MedicineServices/medicine.services";


const medicineListing = async (params) => {
    let response = await medicineServices.medicineListing(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const medicineCreate = async (params) => {
    let response = await medicineServices.medicineCreate(params);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const medicineDetail = async (params) => {
    let response = await medicineServices.medicineDetail(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const medicineUpdate = async (data, id) => {
    let post = {
        brand_name: data && data.brandName ? data.brandName : null,
        medicine_name: data && data.medicineName ? data.medicineName : null,
        salt: data && data.salt ? data.salt : null,
        dosage: data && data.dosage ? data.dosage : null,
        route: data && data.route ? data.route : null,
        frequency_id: data && data.frequency ? data.frequency.id : null
    }
    let response = await medicineServices.medicineUpdate(id, post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const medicineDelete = async (id, ids) => {
    let response = await medicineServices.medicineDelete(id, ids);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const brandNameSuggestionsListing = async (post) => {
    let response = await medicineServices.brandNameSuggestionsList(post);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const medicineNameSuggestionsList = async (post) => {
    let response = await medicineServices.medicineNameSuggestionsList(post);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const completeMedicine = async (id, data) => {
    let response = await medicineServices.completeMedicine(id, data);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const completeAllMedicines = async (data) => {
    let response = await medicineServices.completeAllMedicines(data);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const addComment = async (id, data) => {
    let response = await medicineServices.addComment(id, data);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const medicineController = {
    medicineListing,
    medicineCreate,
    medicineDelete,
    medicineUpdate,
    medicineDetail,
    brandNameSuggestionsListing,
    medicineNameSuggestionsList,
    completeMedicine,
    completeAllMedicines,
    addComment
};

export default medicineController;
