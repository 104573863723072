import { logsServices } from "../../Services/LogsSevices/logs.services";


const logsListing = async (params) => {
    let response = await logsServices.logsListing(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const subAdminLogsListing = async (params) => {
    let response = await logsServices.subAdminLogsList(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
}


const logsController = {
    logsListing,
    subAdminLogsListing
};

export default logsController;
