import React, { useState } from 'react';

const CustomDatepicker = (props) => {
    const { dayMonth,handleDate ,values} = props
    const [date, setDate] = useState(null)
    
    return (
        <div className='custom_datepicker_section'>
            {/* <div className="inner_datepicker_area">
                <div className="content_area">
                    <h6>01</h6>
                    <p>Mon</p>
                    <div className="dot"></div>
                </div>
            </div> */}
              {dayMonth && dayMonth.map((item) => {
                  return(
                      <div className={values && values.day === item.date ? 'inner_datepicker_area active' : "inner_datepicker_area"}
                          onClick={() => {
                              handleDate("day",item.date)
                          }} >
                <div className="content_area">
                    <h6>{item.date}</h6>
                    <p>{item.day}</p>
                    <div className="dot"></div>
                </div>
            </div>
                  )})}
            
             {/* <div className="inner_datepicker_area active">  //active class------------>
                <div className="content_area">
                    <h6>04</h6>
                    <p>Thu</p>
                    <div className="dot"></div>
                </div>
            </div> */}
            
        </div>
    );
}

export default CustomDatepicker;