import FiltersHeader from "../../../../Helper/filtersHeader";
import * as Constants from "../../../../Helper/constants/constants";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import {
    Calender,
    ChangePatientProfile,
    Image_Up,
    Man,
    Patient,
} from "../../../../../assets/images";
import Select from "react-select";
import { useState } from "react";
import TagsInput from "react-tagsinput";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import { useNavigate } from "react-router-dom";
import AddDepartmentFunctions from "./function";
import { useEffect } from "react";
import CustomSelectOptions from "../../../../Helper/customSelectOption";

const AddDepertment = () => {
    const navigate = useNavigate();
    const {
        getHospitalListing,
        breadcrumData,
        breadcrumItem,
        values,
        isError,
        hospitalsListing,
        loader,
        handleChange,
        submitDepartement,
        loaction,
        setbreadcrumItem,
        handleBreadcrumDataNaviagtion,
        getDepartmentDetail,
    } = AddDepartmentFunctions();

    useEffect(() => {
        if (!loader) {
            if (loaction && loaction.state && loaction.state.name) {
                let id =
                    loaction && loaction.state && loaction.state.id
                        ? loaction.state.id
                        : null;

                getDepartmentDetail(id);
                setbreadcrumItem("Edit Department");
            }
            getHospitalListing();
        }
    }, []);

    return (
        <div className="add_case_section">
            <WithoutImageHeader
                header={
                    loaction && loaction.state && loaction.state.name
                        ? Constants.EDITDEPARTMENT
                        : Constants.ADDDEPARTMentT
                }
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBack={() => navigate(-1)}
                handleBreadcrumDataNaviagtion={(i) =>
                    handleBreadcrumDataNaviagtion(i)
                }
            />
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="case_area">
                            <div className="dropdown_box">
                                <h6>
                                    Hospital Name<span>*</span>
                                </h6>
                                <div className="dropdown_area">
                                    <Select
                                        isDisabled={loaction && loaction.state && loaction.state.id ? true : false}
                                        placeholder="Select hospital"
                                        options={hospitalsListing}
                                        className={isError.hospital_arr.message ? "error_border_select" : ""}
                                        value={values && values.hospital_arr}
                                        onChange={(e) => {
                                            handleChange("hospital_arr", e);
                                        }}
                                        components={{
                                            Option: props => (
                                                <CustomSelectOptions
                                                    items={props}
                                                    value={values && values.hospital_arr}
                                                />
                                            )
                                        }}
                                    />
                                    {isError.hospital_arr.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.hospital_arr.message}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    Department Title<span>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter department title here"
                                    className={isError.department_title.message ? "error_border" : ""}
                                    value={values.department_title}
                                    onChange={(e) =>
                                        handleChange("department_title", e.target.value)
                                    }
                                />
                                {isError.department_title.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.department_title.message}
                                    </p>
                                ) : null}
                            </Form.Group>
                            <div className="btn_area">
                                <Button
                                    className="cancelBtn"
                                    type="button"
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="submitBtn"
                                    type="button"
                                    onClick={() => submitDepartement()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddDepertment;
