import { useEffect, useState } from "react";
import permissionsController from "../../../../../apis/Controllers/PermissionsController/permissions.contoller";
import Validation from "../../../../Helper/validations/vaildation";
import rolesController from "../../../../../apis/Controllers/RolesController/roles.controller";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../../redux/store";
import { all } from "axios";
import hospitalController from "../../../../../apis/Controllers/HospitalController/hospital.controller";
import wardController from "../../../../../apis/Controllers/WardController/ward.controller";
import roomsController from "../../../../../apis/Controllers/RoomsController/rooms.controller";
import { filtersController } from "../../../../../apis/Controllers/CommonController/filter.controller";

const RoomListFunction = () => {
    var breadcrumData = ["Dashboard"];
    var breadcrumData1 = ["Dashboard", "Room List"];
    let storedFilters = store.getState().RoomListFilters.filters;
    const [loader, setLoader] = useState(false);
    const [breadcrumItem, setbreadcrumItem] = useState("Room list");
    const [searchInput, setSearchInput] = useState(null);
    const [checkPermissions, setCheckPermissions] = useState(null);
    const [roomList, setRoomList] = useState([]);
    const [deletePopup, setDeletePopup] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);
    const [filtersAppliedSign, setFiltersAppliedSign] = useState(false);
    const [keyword, setSearch] = useState(null);
    const [SearchLoader, setSearchLoader] = useState(false);
    const [sorting, setSorting] = useState(null)
    // -------------------PAZINATION ----------------------
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    //----------------------Filters-------------------------------
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [checkStoredvalueFromRedux, SetCheckStoredValueFromRedux] = useState(null);
    const [allFiltersValues, setAllFiltersValues] = useState(false);

    const loaction = useLocation();
    const navigate = useNavigate();
    let userdata = localStorage.getItem('SET_USER_DATA');
    let User = JSON.parse(userdata);


    useEffect(() => {
        if (storedFilters && checkStoredvalueFromRedux, allFiltersValues) {
            if (
                (storedFilters &&
                    storedFilters.wards &&
                    storedFilters.wards.length > 0) ||
                (storedFilters &&
                    storedFilters.hospitals &&
                    storedFilters.hospitals.length > 0) ||
                (storedFilters &&
                    storedFilters.sortByAlpha)
            ) {
                setFiltersAppliedSign(true);
                getRoomlist("", page, itemsPerPage);
            } else {
                setFiltersAppliedSign(false);
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                getRoomlist("", page, itemsPerPage, '', item);
            }
        }
    }, [storedFilters, checkStoredvalueFromRedux, allFiltersValues]);

    useEffect(() => {
        if (storedFilters && checkStoredvalueFromRedux) {
            if ((storedFilters &&
                storedFilters.hospitals &&
                storedFilters.hospitals.length > 0)
            ) {
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                handleDashboardHospitalwithFiltersAppliedCase(item, storedFilters.hospitals);
            } else {
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                if (item) {
                    handleDashboardHospitalwithFilters(item);
                }
            }
        }
    }, [checkStoredvalueFromRedux]);


    const handleDashboardHospitalwithFiltersAppliedCase = async (seletedHospitalData, AppliedFilterHospitals) => {
        let applied = AppliedFilterHospitals
        if (applied.length > 1) {
            let index = applied.findIndex((id) => parseInt(id) === parseInt(seletedHospitalData.id))
            if (index === -1) {
                applied.push(seletedHospitalData.id);
            }
            // else {
            //     return applied
            // }
        }
        else {
            applied = []
            applied.push(seletedHospitalData.id);
        }
        let filterNew = {
            wards: storedFilters && storedFilters.wards && storedFilters.wards.length > 0 ? storedFilters.wards : [],
            hospitals: applied,
            sortByAlpha: storedFilters && storedFilters.sortByAlpha && storedFilters.sortByAlpha.value ? storedFilters.sortByAlpha.value : null,
        };
        await filtersController.setRoomListFilters(filterNew);
        await setAllFiltersValues(true);
    };


    const handleDashboardHospitalwithFilters = async (seletedHospitalData) => {
        let filterNew = {
            wards: storedFilters && storedFilters.wards && storedFilters.wards.length > 0 ? storedFilters.wards : [],
            hospitals: seletedHospitalData && seletedHospitalData.id ? [seletedHospitalData.id] : [],
            sortByAlpha: storedFilters && storedFilters.sortByAlpha && storedFilters.sortByAlpha.value ? storedFilters.sortByAlpha.value : null,
        };
        await filtersController.setRoomListFilters(filterNew);
        await setAllFiltersValues(true);
    };


    useEffect(() => {
        if (count) {
            handlePages(count);
        }
    }, [count, itemsPerPage]);


    const getModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('room');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckPermissions(data);
        }
        else {
            setCheckPermissions(null);
        }
    };

    const getRoomlist = async (e, page, itemsPerPage, filters, seletedHospitalData) => {
        if (!loader) {
            let post = {
                search: e ? e : '',
                page: page ? page : 1,
                limit: itemsPerPage ? itemsPerPage : 10,
                hospital_id: filters && filters.hospitals && filters.hospitals.length > 0 ? filters.hospitals : storedFilters && storedFilters.hospitals && storedFilters.hospitals.length > 0 ? storedFilters.hospitals : seletedHospitalData && seletedHospitalData.id ? [seletedHospitalData.id] : [],
                ward_id: filters && filters.wards && filters.wards.length > 0 ? filters.wards : storedFilters && storedFilters.wards ? storedFilters.wards : [],
                sort: filters && filters.sortByAlpha ? filters.sortByAlpha.value : storedFilters && storedFilters.sortByAlpha ? storedFilters.sortByAlpha.value : null,
            };
            setLoader(true);
            let response = await roomsController.superAdminRoomsListing(post);
            if (response && response.status) {
                setRoomList(response.data);
                setCount(response.count);
                setTotalCount(response.totalCount)
                setLoader(false);
            } else {
                setRoomList([]);
                setLoader(false);
            }
            setLoader(false);
        }
    };

    const handleFilters = (sort) => {
        if (sort) {
            setSorting(sort)
            getRoomlist("", page, itemsPerPage, sort)
        }
    }

    const handlePages = (count) => {
        let array = [];
        const data = parseInt(count);
        const totalPage = Math.ceil(data / itemsPerPage);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i);
        }
        setTotalPages(array);
    };


    const handleDelete = (item) => {
        setDeleteItem(item)
        setDeletePopup(true)
    };


    const handleDeleteRoom = async (data) => {
        let array = [...roomList]
        let id = data && data.id ? data.id : '';
        setLoader(true);
        let response = await roomsController.roomDelete(id);
        if (response && response.status) {
            setLoader(false);
            new Toaster().success(response.message);
            setDeletePopup(false)
            let filterData = array.filter((data) => data.id !== data.id);
            if (filterData.length > 0) {
                getRoomlist('', page, itemsPerPage);
            }
            else {
                if (page > 1) {
                    let newpage = page - 1
                    setPage(page - 1);
                    getRoomlist('', newpage, itemsPerPage);
                }
                else {
                    setPage(page);
                    getRoomlist('', page, itemsPerPage);
                }
            }
            setTotalPages([]);
        } else {
            setLoader(false);
        }
        setLoader(false);
    };

    const updateRoomStatus = async (item) => {
        let post = {
            status: item.status == 1 ? 0 : 1,
        };
        let response = await roomsController.updateRoomStatus(item.id, post);
        if (response && response.status) {
            let array = [...roomList];
            item.status = parseInt(item.status) == 0 ? 1 : 0;
            setRoomList(array);
            new Toaster().success(response.message);
        }
        else return;
    };


    const handleBackClick = () => {                             //<------------PAZINATION
        if (page > 1) {
            let newpage = page - 1;
            setPage(page - 1);
            getRoomlist("", newpage, itemsPerPage);
        } else;
    };


    const handleNextClick = () => {                                 //<------------PAZINATION
        if (page >= 1 && page < totalPages.length) {
            let newpage = page + 1;
            setPage(page + 1);
            getRoomlist("", newpage, itemsPerPage);
        } else;
    };


    const handlePage = (e) => {
        setPage(e);
        getRoomlist("", e, itemsPerPage);
    };


    const handleItemPerPage = (e) => {
        let value = e.target.value;
        setItemsPerPage(value);
        getRoomlist("", 1, value);
    };

    const handleSearch = (value) => {
        // setSearchInput(value);         
        // getRoomlist(value , page);
    };


    const applyFilters = async (filter) => {
        let filters = {
            wards: filter && filter.wards ? filter.wards : [],
            hospitals: filter && filter.hospitals ? filter.hospitals : [],
            sortByAlpha: filter && filter.sortByAlpha ? filter.sortByAlpha : null,
        };
        if (filter && filter.hospitals && filter.hospitals.length > 0) {
            await filtersController.setRoomListFilters(filters);
            setShowFilterModal(false);
            setFiltersAppliedSign(true);
        } else {
            new Toaster().error("Please select at least one hospital");
        }
    };


    const handleClearAllFilters = async () => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        await filtersController.clearRoomListFilters(item);
    };

    const setReduxValue = (e) => {          //<------------For confirmation that we have checked the localstorage value's and RESET the REDUX- FILTERS's Values and after it the listing api will hit
        SetCheckStoredValueFromRedux(e);
    };

    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/dashboard-superadmin");
            }
        } else if (i === 1) {
            // navigate("/role-list");
        } else return;
    };


    return {
        breadcrumData,
        breadcrumItem,
        getModulePermissions,
        checkPermissions,
        roomList,
        getRoomlist,
        loader,
        navigate,
        page,
        handleBreadcrumDataNaviagtion,
        handlePage,
        handleBackClick,
        handleNextClick,
        totalPages,
        deletePopup,
        setDeletePopup,
        handleDelete,
        handleDeleteRoom,
        deleteItem,
        updateRoomStatus,
        handleSearch,
        searchInput,
        setSearchInput,
        keyword,
        setSearch,
        filtersAppliedSign,
        totalCount,
        handleFilters,
        sorting,
        breadcrumData1,
        User,
        showFilterModal,
        setShowFilterModal,
        applyFilters,
        handleClearAllFilters,
        setReduxValue,
        handleItemPerPage,
        itemsPerPage,
    };
};

export default RoomListFunction;
