import React from 'react';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from 'react-select';
import Loader from '../../../Helper/loader';
import CustomSelectOptions from '../../../Helper/customSelectOption';

const AddressTab = (props) => {
    const {
        values,
        isError,
        handleChange,
        statesList,
        districtsList,
        citiesList,
        loader,
        getDistrictsListing,
        getCitiesListing
    } = props


    const handleDistrictAndCityDropDown = () => {
        props.handleDistrictAndCityDropDown();
    };

    const handleCityDropDown = () => {
        if (props && props.handleCityDropDown) {
            props.handleCityDropDown();
        }
    };


    const handleBackClick = () => {
        props.setCurrentTab('1');
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        props.handleSubmitStepTwo();
    };


    return (
        <div className="address_tab_screen">
            {loader ? <Loader loader={loader} /> : <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Form>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    House No.
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter House No. here"
                                    value={values.address}
                                    className={isError.address.message
                                        ? "error_border"
                                        : ""}
                                    onChange={(e) =>
                                        handleChange("address", e.target.value)
                                    }
                                />
                                {isError.address.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.address.message}
                                    </p>
                                ) : null}
                            </Form.Group>
                            <Row>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Form.Group className="form-group">
                                        <Form.Label>
                                            Area<span>*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter area here"
                                            value={values.area}
                                            className={isError.area.message
                                                ? "error_border"
                                                : ""}
                                            onChange={(e) =>
                                                handleChange("area", e.target.value)
                                            }
                                        />
                                        {isError.area.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.area.message}
                                            </p>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Form.Group className="form-group">
                                        <Form.Label>
                                            Pin Code
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter pincode here"
                                            value={values.pincode}
                                            className={isError.pincode.message
                                                ? "error_border"
                                                : ""}
                                            onChange={(e) =>
                                                handleChange("pincode", e.target.value)
                                            }
                                        />
                                        {isError.pincode.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.pincode.message}
                                            </p>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="dropdown_box">
                                    <h6>State<span>*</span></h6>
                                    <div className="dropdown_area" >
                                        <Select
                                            placeholder="Select State"
                                            value={values && values.stateData}
                                            options={statesList}
                                            onChange={(item) => (handleChange('stateData', item), getDistrictsListing(item.id))}
                                            className={isError.stateData.message ? "error_border_select" : ""}
                                            components={{
                                                Option: props => (
                                                    <CustomSelectOptions
                                                        items={props}
                                                        value={values && values.stateData}
                                                    />
                                                )
                                            }}
                                        />
                                        {isError.stateData.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.stateData.message}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>
                            <Row>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                                    <div className="dropdown_box" onClick={() => handleDistrictAndCityDropDown()}>
                                        <h6>District<span>*</span></h6>
                                        <div className="dropdown_area" >
                                            <Select
                                                placeholder="Select district"
                                                isDisabled={values && values.stateData ? false : true}
                                                value={values && values.districtData}
                                                options={districtsList}
                                                onChange={(item) => (handleChange('districtData', item), getCitiesListing(values.stateData ? values.stateData.id : ''))}
                                                className={isError.nationality.message ? "error_border_select" : ""}
                                                components={{
                                                    Option: props => (
                                                        <CustomSelectOptions
                                                            items={props}
                                                            value={values && values.districtData}
                                                        />
                                                    )
                                                }}
                                            />
                                            {isError.districtData.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError.districtData.message}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className="dropdown_box" onClick={() => handleCityDropDown()}>
                                        <h6>City<span>*</span></h6>
                                        <div className="dropdown_area">
                                            <Select
                                                placeholder="Select city"
                                                isDisabled={values && values.districtData ? false : true}
                                                value={values && values.cityData}
                                                options={citiesList}
                                                onChange={(item) => handleChange('cityData', item)}
                                                className={isError.cityData.message ? "error_border_select" : ""}
                                                components={{
                                                    Option: props => (
                                                        <CustomSelectOptions
                                                            items={props}
                                                            value={values && values.cityData}
                                                        />
                                                    )
                                                }}
                                            />
                                            {isError.cityData.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError.cityData.message}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Form.Group className="form-group">
                                        <Form.Label>
                                            Contact Number<span>*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter contact number here"
                                            value={values.contactNumber}
                                            className={isError.contactNumber.message
                                                ? "error_border"
                                                : ""}
                                            onChange={(e) =>
                                                handleChange("contactNumber", e.target.value)
                                            }
                                        />
                                        {isError.contactNumber.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.contactNumber.message}
                                            </p>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Form.Group className="form-group">
                                        <Form.Label>
                                            Alternate Number
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter alternative number here"
                                            value={values.alternativeNumber}
                                            className={isError.alternativeNumber.message
                                                ? "error_border"
                                                : ""}
                                            onChange={(e) =>
                                                handleChange("alternativeNumber", e.target.value)
                                            }
                                        />
                                        {isError.alternativeNumber.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.alternativeNumber.message}
                                            </p>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    Email
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="test@example.domain"
                                    value={values.email}
                                    className={isError.email.message
                                        ? "error_border"
                                        : ""}
                                    onChange={(e) =>
                                        handleChange("email", e.target.value)
                                    }
                                />
                                {isError.email.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.email.message}
                                    </p>
                                ) : null}
                            </Form.Group>
                            <div className="btn_area">
                                <Button className="cancelBtn" type="button" onClick={() => handleBackClick()}>Back</Button>
                                <Button className="submitBtn" type="submit" onClick={handleSubmit}>Next</Button>
                            </div>

                        </Form>
                    </Col>
                </Row>
            </Container>}
        </div>
    )
};

export default AddressTab;