import { Toaster } from "../../../components/Helper/toaster/Toaster";
import { labServices } from "../../Services/LabServices/lab.services";


const testTypeListing = async (params) => {
    let response = await labServices.testTypeList(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const testNameListing = async (id) => {
    const post = {
        test_type_id: id ? id : "",
    }
    let response = await labServices.testNameList(post);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
}

const testCategoryListing = async (id) => {
    const post = {
        lab_test_id: id ? id : "",
    }
    let response = await labServices.testCategoryList(post);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
}

const subCategoryListing = async (data) => {
    let Id = "";
    for (let i in data) {
        if (data[i].is_test_sub_category == 1) {
            Id = data[i].id;
        }
    };
    const post = {
        lab_tests_category_id: Id ? Id : "",
    }
    let response = await labServices.subCategoryList(post);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
}

const patientLabTesting = async (data, testCategoryList, data2) => {
    let post = {
        patient_id: data && data.patient_id ? data.patient_id : '',
        case_id: data && data.case_id ? data.case_id : '',
        test_type_id: data && data.testType && data.testType.id ? data.testType.id : '',
        test_id: data && data.testName && data.testName.id ? data.testName.id : '',
        image: data && data.image ? data.image : [],
        test_details: data2 ? data2.range : testCategoryList && testCategoryList.length > 0 ? testCategoryList : [],

    }
    let response = await labServices.patientLabTest(post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
}

const patientLabTestList = async (params) => {
    let response = await labServices.patientLabTestList(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const patientLabTestDetails = async (id) => {
    let response = await labServices.patientLabTestDetails(id);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const deletePatientLabTest = async (id) => {
    let response = await labServices.deletePatientLabTest(id);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const editpatientLabTest = async (id, data, testCategoryList, data2) => {
    let post = {
        patient_id: data && data.patient_id ? data.patient_id : '',
        case_id: data && data.case_id ? data.case_id : '',
        test_type_id: data && data.testType && data.testType.id ? data.testType.id : '',
        test_id: data && data.testName && data.testName.id ? data.testName.id : '',
        image: data && data.image ? data.image : [],
        test_details: data2 ? data2.range : testCategoryList && testCategoryList.length > 0 ? testCategoryList : [],

    }
    let response = await labServices.editpatientLabTest(id, post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
}

const labController = {
    testTypeListing,
    testNameListing,
    testCategoryListing,
    subCategoryListing,
    patientLabTesting,
    patientLabTestList,
    patientLabTestDetails,
    deletePatientLabTest,
    editpatientLabTest
};

export default labController;
