import React from 'react';
import { Slide, toast, ToastContainer } from 'react-toastify';

export class Toaster extends React.Component {

    success(message) {
        toast.dismiss()
        if (message && message !== "" && message !== null) {
            toast.success(message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true
            });
        }
    }

    error(message) {
        toast.dismiss();
        if (message && message !== "" && message !== null) {
            toast.error(message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true
            });
        }
    }

    render() {

        const undoFeature = false;
        const CloseButton = ({ closeToast }) => (
            undoFeature ??
            <div className="btn_grey ml-4">
                <button type="button">Undo</button>
            </div>
        );

        return (
            <ToastContainer
                limit={1}
                className="success_toast close_watchlist_toast"
                position={"bottom-center"}
                closeOnClick={true}
                autoClose={3000}
                transition={Slide}
                newestOnTop={true}
                closeButton={CloseButton}
                hideProgressBar
                pauseOnHover={false}
                enableMultiContainer={false}
            />
        );
    }

};