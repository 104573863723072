export const LOGIN_HEADER = "Log in";
export const LOGIN_DESCRIPTION = '"Your health is just a tap away." - log in now.';
export const FOGET_PASSWORD_HEADER = "Forgot Password?";
export const EMAIL_VERIFICATION = "Email Verification";
export const FOGET_PASSWORD_DESCRIPTION = "Please enter your email address to reset your password.";
export const RESET_PASSWORD_HEADER = "Reset Password!";
export const RESET_PASSWORD_DESCRIPTION = "Change your password now to keep your account secure.";
export const CHANGE_PASSWORD_HEADER = "Change Password";
export const MY_PROFILE_HEADER = "My Profile";
export const PATIENT_LIST_HEADER = "Patient List";
export const EXIST_PATIENT_LIST_HEADER = "Existing Patient List";
export const PROFILE_HEADER = "Profile";
export const PATIENT_DETAILS_HEADER = "Patient Details";
export const PATIENT_PROFILE_HEADER = "Patient Profile";
export const MY_TASKS_DETAIL = "My Tasks";
export const ASSIGN_TASKS = "Assign Tasks";
export const EDIT_TASK = "Edit Task";
export const ADD_HOSPITAL = "Add Hospital";
export const EDIT_HOSPITAL = "Edit Hospital";
export const HOSPITAL_DETAILS = "Hospital Details";
export const HOSPITAL_LIST = "Hospital list";
export const ROLE_LIST = "Role List";
export const ADD_ROLE = "Add Role";
export const VIEW_ROLE = "View Role";
export const EDIT_ROLE = "Edit Role";
export const ADD_PATIENT = "Add Patient";
export const TASK_LIST = "Task List";
export const STAFF_DETAIL = "Staff Detail";
export const ADD_PROFILE = "Add Profile";
export const ADD_STAFF = "Add Staff";
export const EDIT_STAFF = "Edit Staff";
export const DASHBOARD = "Dashboard";
export const TODAYADMITPATIENT = "Today Admit Patient";
export const STAFF_LIST = "Staff List";
export const Search = "Search";
export const EDIT_PROFILE = "Edit Profile";
export const ADD_CASE = "Add Case";
export const EDIT_CASE = "Edit Case";
export const WARDLIST = "Ward List";
export const ADDWARD = "Add Ward";
export const EDITWARD = "Edit Ward";
export const DEPARTMENT_LIST = "Department List";
export const ADDDEPARTMentT = "Add Department";
export const EDITDEPARTMENT = "Edit Department";
export const VIEWFILE = "View File";
export const EXISTING_PATIENT = "Existing Patient";
export const ASSIGN_MEDICINE = "Assign Medicine";
export const EDIT_MEDICINE = "Edit Medicine";
export const ROOM_WiITH_BED = "Create room with bed";
export const ADDROOM = "Add Room";
export const ROOMLIST = "Room List";
export const EDITROOM = "Edit Room";
export const BEDLIST = "Bed List";
export const ADDBED = "Add Bed";
export const EDITBED = "Edit Bed";
export const ADD_REPORT = "Add Report";
export const EDIt_REPORT = "Edit Report";
export const CASE_DETAIL = "Case Details";
export const PROFILE_LAB = "Profile";
export const MYPROFILE_LAB = "My Profile";
export const PATIENT_LIST_LAB = "Patient List";
export const REPORT_DETAIL_LAB = "Report Detail";



