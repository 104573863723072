import React, { useEffect, useState } from "react";
import { Card, Col, Container, Dropdown, Form, Image, Row } from "react-bootstrap";
import * as Constants from '../../../Helper/constants/constants';
import WithoutImageHeader from "../../../Helper/withoutImageHeader";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useLocation, useNavigate } from "react-router-dom";
import { after } from "lodash";
import WebConstant from "../../../../apis/webConstants";
import { renderDocument, renderImage } from "../../../Helper/General";

const Viewfile = () => {

    const [breadcrumData, setbreadcrumData] = useState(['Screen']);
    const [selectedDocs, setSelectedDocs] = useState([]);
    const loaction = useLocation();
    let navigate = useNavigate();
    var breadcrumItem = 'View File';
    const file = loaction && loaction.state && loaction.state;
    let str = file;
    var ext = str.substr(str.indexOf('.') + 1);


    const docs = [
        {
            uri: file && file ? renderDocument(file) : '' ,
            fileType: ext ? ext :'',
            fileName: file && file.title ?  file.title + "." + ext : '',
        }];


    return (
        <>
            <div className="role_list_screen ward_list_area">
                <WithoutImageHeader
                    header={Constants.VIEWFILE}
                    handleBack={() => navigate(-1)}
                    // breadcrumData={breadcrumData}
                    // breadcrumItem={breadcrumItem}
                />
                <Container>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div className="role_list_details">
                                <div className="title_btn_area">
                                    {/* <div className="left_area">
                                        <p>
                                            {selectedDocs && selectedDocs[0]
                                                && selectedDocs[0].name ? selectedDocs[0].name
                                                : null}
                                        </p>
                                    </div> */}
                                    {/* <div className="right_area">
                                        <a
                                            className="btn btn-primary"
                                            href="/add-Ward"
                                        >
                                            Download
                                        </a>
                                    </div> */}
                                </div>
                                <div>
                                    <div>
                                        {/* <input
                                            type="file"
                                            accept=".pdf , .doc, .docx , .jpg , jpeg , .xlsx ,xls"
                                            onChange={(e) =>{
                                                setSelectedDocs(Array.from(e.target.files))
                                            }}
                                        /> */}
                                        <DocViewer
                                            documents={docs}
                                            pluginRenderers={DocViewerRenderers} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Viewfile;