import { Toaster } from "../../../components/Helper/toaster/Toaster";
import { commentServices } from "../../Services/CommentServices/coments.services";


const commentsListing = async (params) => {
    let response = await commentServices.commentListing(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const commentsCreate = async (data, value) => {
    let post = {
        hospital_id: data && data.hospital_id ? data.hospital_id : null,
        patient_id: data && data.patient_id ? data.patient_id : null,
        task_id: data && data.id ? data.id : null,
        case_id: data && data.case_id ? data.case_id : null,
        comment: value ? value : null
    }
    let response = await commentServices.commentCreate(post);
    if (response) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const commentsController = {
    commentsListing,
    commentsCreate,
};

export default commentsController;
