import {
    faEllipsisVertical,
    faSearch
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect } from "react";
import { Col, Container, Dropdown, Form, Image, Row } from "react-bootstrap";
import { Build_Img, Delete_2, Edit_2, Eye_2, Hospital_Img } from "../../../../../assets/images";
import * as Constants from "../../../../Helper/constants/constants";
import Loader from "../../../../Helper/loader";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import NoDataComponent from "../../../CommanComponents/NoDataComponent";
import PaginationComponent from "../../../CommanComponents/Pagination";
import HospitalListingFunctions from "./functions";
import DeleteModal from "../../../Modals/DeleteModal";
import { useState } from "react";
import { dateFormat, openPhoneDialer, renderImage, timeFormat2 } from "../../../../Helper/General";
import store from "../../../../../redux/store";
import { setDrawer } from "../../../../../redux/actions/users";
import { debounce } from "lodash";


const HospitalListingData = () => {
    const [deleteItem, setDeleteItem] = useState(null);
    const {
        getModulePermissions,
        checkPermissions,
        getHospitalListing,
        hospitalsListing,
        handleBackClick,
        handleNextClick,
        handleDeleteHospital,
        page,
        setPage,
        totalPages,
        breadcrumData,
        breadcrumItem,
        navigate,
        loader,
        handlePage,
        handleBreadcrumDataNaviagtion,
        deletePopup,
        setDeletePopup,
        count,
        keyword,
        setSearch,
        User,
        itemsPerPage,
        handleItemPerPage
    } = HospitalListingFunctions();


    useEffect(() => {
        let userdata = localStorage.getItem('SET_USER_DATA');
        let User = JSON.parse(userdata);
        if (User && parseInt(User.user_type) === 2) {
            getModulePermissions();
        }
        else;
        getHospitalListing('', 1, itemsPerPage);
    }, []);


    const handleDelete = (item) => {
        setDeleteItem(item)
        setDeletePopup(true)
    };


    const handleDrawer = async () => {
        await store.dispatch(setDrawer(false));
    };

    const search = useCallback(debounce(getHospitalListing, 1000), []);
    return (
        <div className="hospital_list_data" >
            <WithoutImageHeader
                header={Constants.HOSPITAL_LIST}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                lefticon='menu'
                icon={'notification'}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
            />
            <Container onClick={() => handleDrawer()}>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="hospital_data">
                            <div className="content_area">
                                <div className="left_area">
                                    <p>Total Hospitals: {count ? count : 0}</p>
                                </div>
                                {
                                    User && parseInt(User.user_type) === 1 ?
                                        <div className="right_area">
                                            <a className="btn btn-primary"
                                                onClick={() => navigate('/hospital')}>Add Hospital</a>
                                        </div>
                                        :
                                        checkPermissions
                                            && checkPermissions.permissions
                                            && checkPermissions.permissions.length > 1
                                            && parseInt(checkPermissions.permissions[1].value) === 1 ?
                                            <div className="right_area">
                                                <a className="btn btn-primary"
                                                    onClick={() => navigate('/hospital')}>Add Hospital</a>
                                            </div>
                                            :
                                            null
                                }
                            </div>
                            <div className="search_icon_area">
                                <div className="search_area">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Control
                                                type="text"
                                                placeholder="Search by hospital name here"
                                                value={keyword}
                                                onChange={(e) =>
                                                (
                                                    search(e.target.value, 1, itemsPerPage),
                                                    setSearch(e.target.value))
                                                }
                                            />
                                            <a >
                                                <div className="icon_search">
                                                    <FontAwesomeIcon
                                                        icon={faSearch}
                                                    />
                                                </div>
                                            </a>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div className="pagination_list">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Select
                                                value={itemsPerPage}
                                                onChange={(e) => handleItemPerPage(e)}
                                            >
                                                <option value={5}>5</option>
                                                <option value={10}>10</option>
                                                <option value={15}>15</option>
                                                <option value={20}>20</option>
                                                <option value={25}>25</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Form>
                                </div>
                                {/* <div className="icon_filter_area">
                                    <Button
                                        className="btn-primary"
                                        onClick={() => setShowFilterModal(true)}>
                                        <div className="img_area">
                                            <Image
                                                src={Filters}
                                                alt="..."
                                                className="image_area"
                                            />
                                        </div>Filters</Button>
                                </div> */}
                            </div>
                            {
                                loader ?
                                    <Loader
                                        loader={loader}
                                    />
                                    : <>
                                        {
                                            hospitalsListing && hospitalsListing.length > 0 ?
                                                <div className="table_content_area" >
                                                    <div className="table-responsive">
                                                        <table responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th width="30%" className="hosp_name">Hospital Name</th>
                                                                    <th width="30%" className="hosp_adrs">Hospital Address</th>
                                                                    <th width="20%" className="act_pat">Active Patients</th>
                                                                    <th width="10%" className="cont_no">Contact Number</th>
                                                                    <th width="10%" className="cont_no">Created On</th>
                                                                    <th width="10%" className="action">Actions</th>
                                                                </tr>
                                                            </thead>
                                                            {
                                                                hospitalsListing.map((item, i) => {
                                                                    return (
                                                                        parseInt(item.status) === 1 ?
                                                                            <tbody key={i} >
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className="text_img_area" onClick={() => navigate(`/hospital-detail/${item.id}`)}>
                                                                                            <div className="left_area">
                                                                                                <div className="img_area">
                                                                                                <Image
                                                                                                    src={item.image && item.image ? renderImage(item.image) : Hospital_Img}
                                                                                                    alt='...'
                                                                                                    
                                                                                                />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="right_area">
                                                                                                <p style={{ textTransform: "capitalize" }} className="under_line">{item.title ? item.title : ''}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="center_space" style={{ textTransform: "capitalize", textDecoration: item.map_link ? 'underline' : null }} onClick={() => item.map_link ? window.open(item.map_link) : null}>{item.address ? item.address : ''}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        {/* {
                                                                            item.active_patients ? */}
                                                                                        <div className="center_box">
                                                                                            <div className="num_box" onClick={() => navigate('/manage-patient-list')} >{item.active_patients ? item.active_patients : '0'}</div>
                                                                                        </div>
                                                                                        {/* :
                                                                                null
                                                                        } */}
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="center_space" style={{ textDecoration: item.phonenumber ? 'underline' : null }} onClick={() => item.phonenumber ? openPhoneDialer(item.phonenumber) : null}>{item.phonenumber}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        {dateFormat(item.created)}{' '}{timeFormat2(item.created)}
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="icon">
                                                                                            <Dropdown>
                                                                                                <Dropdown.Toggle variant="none" id="dropdown-basic">
                                                                                                    <FontAwesomeIcon icon={faEllipsisVertical} className="ellipsis_icon" />
                                                                                                </Dropdown.Toggle>

                                                                                                <Dropdown.Menu>
                                                                                                    <Dropdown.Item onClick={() => navigate(`/hospital-detail/${item.id}`)}
                                                                                                    >View <Image src={Eye_2} alt="..." className="image_area" />
                                                                                                    </Dropdown.Item>
                                                                                                    {
                                                                                                        User && parseInt(User.user_type) === 1 ?
                                                                                                            <Dropdown.Item onClick={() => navigate('/hospital', { state: { id: item.id, name: 'EditHospital' } })}>
                                                                                                                Edit
                                                                                                                <Image src={Edit_2}
                                                                                                                    alt="..."
                                                                                                                    className="image_area"
                                                                                                                />
                                                                                                            </Dropdown.Item>
                                                                                                            :
                                                                                                            checkPermissions
                                                                                                                && checkPermissions.permissions
                                                                                                                && checkPermissions.permissions.length > 0
                                                                                                                && parseInt(checkPermissions.permissions[2].value) === 1 ?
                                                                                                                <Dropdown.Item onClick={() => navigate('/hospital', { state: { id: item.id, name: 'EditHospital' } })}>
                                                                                                                    Edit
                                                                                                                    <Image src={Edit_2}
                                                                                                                        alt="..."
                                                                                                                        className="image_area"
                                                                                                                    />
                                                                                                                </Dropdown.Item>
                                                                                                                :
                                                                                                                null
                                                                                                    }
                                                                                                    {
                                                                                                        User && parseInt(User.user_type) === 1 ?
                                                                                                            <Dropdown.Item onClick={() => handleDelete(item)}>
                                                                                                                Delete
                                                                                                                <Image src={Delete_2}
                                                                                                                    alt="..."
                                                                                                                    className="image_area delete_icon "
                                                                                                                />
                                                                                                            </Dropdown.Item>
                                                                                                            :
                                                                                                            checkPermissions
                                                                                                                && checkPermissions.permissions
                                                                                                                && checkPermissions.permissions.length > 3
                                                                                                                && parseInt(checkPermissions.permissions[3].value) === 1 ?
                                                                                                                <Dropdown.Item onClick={() => handleDelete(item)}>
                                                                                                                    Delete
                                                                                                                    <Image src={Delete_2}
                                                                                                                        alt="..."
                                                                                                                        className="image_area delete_icon "
                                                                                                                    />
                                                                                                                </Dropdown.Item>
                                                                                                                :
                                                                                                                null
                                                                                                    }
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                            :
                                                                            null
                                                                    )
                                                                })
                                                            }
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    {!loader ?
                                                        <NoDataComponent
                                                            message="No Hospital Found!"
                                                        /> : null}
                                                </>
                                        }
                                    </>}
                            {/* <div className="value_list">5<FontAwesomeIcon icon={faChevronDown} className="icon" /></div> */}
                            {
                                !loader && hospitalsListing && hospitalsListing.length > 0 ?
                                    <PaginationComponent
                                        page={page}
                                        setPage={handlePage}
                                        totalPages={totalPages}
                                        handleBackClick={handleBackClick}
                                        handleNextClick={handleNextClick}
                                    />
                                    :
                                    null
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            {
                deletePopup ?
                    <DeleteModal
                        show={deletePopup}
                        handleSubmit={() => handleDeleteHospital(deleteItem)}
                        close={() => setDeletePopup(false)}
                        message={'Do you want to delete this hospital?'}
                        loader={loader}
                        rightButtontext={'Delete'}
                    />
                    :
                    null
            }
        </div >
    );
};

export default HospitalListingData;
