import {
    faBed,
    faCircleCheck,
    faEllipsisVertical,
    faHouse
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Col, Container, Dropdown, Image, Row } from "react-bootstrap";
import { Edit_2, Eye_2, GirlImg, Man } from "../../../../../assets/images";
import { setDrawer } from "../../../../../redux/actions/users";
import store from "../../../../../redux/store";
import { renderImage, timeFormat } from "../../../../Helper/General";
import * as Constants from "../../../../Helper/constants/constants";
import Loader from "../../../../Helper/loader";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import NoDataComponent from "../../../CommanComponents/NoDataComponent";
import TodayAdmitPatientFunction from "./function";
import { useNavigate } from "react-router-dom";


const TodayPatientList = () => {

    const {
        getTodayAdmitListing,
        loader,
        todayAdmitPatient,
        breadcrumData,
        breadcrumItem,
        totalCount,
        navigate,
        handleBreadcrumDataNaviagtion
    } = TodayAdmitPatientFunction();

    useEffect(() => {
        if (!loader) {
            getTodayAdmitListing();
        }
    }, []);


    const handleDrawer = async (e) => {
        await store.dispatch(setDrawer(false));
    }
    return (
        <div className="dashboard_superadmin">
            <WithoutImageHeader
                header={Constants.TODAYADMITPATIENT}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBack={() => navigate(-1)}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
            />
            <Container onClick={() => handleDrawer()}>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="dashboard_logo_area">
                            {loader ?
                                <Loader loader={loader} /> :
                                <div className="patient_admit_area">
                                    <div className="title">
                                        <h4>Patient's Admitted Today: {totalCount ? totalCount : 0}</h4>
                                    </div>
                                    {todayAdmitPatient && todayAdmitPatient.length > 0 ? todayAdmitPatient.map((item, index) => (
                                        <div key={index} className="patient_main_area">
                                            <div className="left_main">
                                                <div className="text">
                                                    <p>{timeFormat(item.created)} </p>
                                                </div>
                                                <div className="check_icon">
                                                    <FontAwesomeIcon icon={faCircleCheck} />
                                                </div>
                                            </div>
                                            <div className="right_area">
                                                <a href="javascript:;">
                                                    <div className="patient_info_area">
                                                        <div className="left_info_area">
                                                            <div className="img_girl">
                                                                <Image src={item && item.image ? renderImage(item.image) : Man} alt='...' onClick={() => navigate(`/patientDetails/${item.id}`, { state: { pageParams: ['Dashboard'], name: item.name } })} />
                                                            </div>
                                                        </div>
                                                        <div className="right_info_area">
                                                            <div className="patient_name_area">
                                                                <div className="left_area">
                                                                    <h6>{item.patient_name}</h6>
                                                                    <div className="text">
                                                                        <p style={{ textTransform: 'uppercase' }}>{item.ward_name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="right_area">
                                                                    <div className="disease_area">
                                                                        <div className="left_disease">
                                                                            <p>{item.case_title}</p>
                                                                            <div className="list_area">
                                                                                <ul>
                                                                                    <li>
                                                                                        <div className="text_icon">
                                                                                            <FontAwesomeIcon icon={faBed} className="icon" />Bed #{item.bed_number}
                                                                                        </div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div className="text_icon">
                                                                                            <FontAwesomeIcon icon={faHouse} className="icon" />Room #{item.room_number}
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="right_disease">
                                                                            <Dropdown>
                                                                                <Dropdown.Toggle variant="none" id="dropdown-basic">
                                                                                    <FontAwesomeIcon icon={faEllipsisVertical} className="ellipsis_icon" />
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item
                                                                                        onClick={() => navigate(`/patientDetails/${item.id}`, { state: { pageParams: ['Dashboard'], name: item.name } })}
                                                                                    >
                                                                                        View
                                                                                        <Image src={Eye_2} alt="..." className="image_area" />
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item onClick={() => navigate('/edit-patient', { state: { id: item.id ? item.id : '' } })}>
                                                                                        Edit
                                                                                        <Image src={Edit_2} alt="..." className="image_area" />
                                                                                    </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    )) :
                                        <>
                                            {!loader ?
                                                <NoDataComponent
                                                    message="No patient Found!"
                                                /> : null}
                                        </>
                                    }
                                </div>}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default TodayPatientList;
