import { faCalendarDays, faHeartPulse, faVenus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form, Image, Modal } from "react-bootstrap";
import { Circle, GirlImg, Patient } from "../../../../assets/images";
import { dateFormat, dateFormat2, renderImage } from "../../../Helper/General";

const PatientProfileModal = (props) => {
    const { patientData, navigate, checkPermissions } = props
    let userdata = localStorage.getItem('SET_USER_DATA');
    let User = JSON.parse(userdata);

    return (
        <div>
            {
                patientData ?
                    <Modal show={props.show} onHide={props.close} centered className="patient_profile_modal">
                        <Modal.Header closeButton>
                            <Modal.Title>Patient Profile</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="patient_profile_area">
                                <div className="profile_detail">
                                    <div className="left_area">
                                        <div className="img_area">
                                            <Image
                                                src={patientData && patientData.image ? renderImage(patientData.image) : Patient}
                                                alt="..."
                                            />
                                        </div>
                                    </div>
                                    <div className="right_area">
                                        <h3 style={{ textTransform: 'capitalize' }} >{patientData.patient_name ? patientData.patient_name : ''}</h3>
                                        <div className="text_list">
                                            <ul>
                                                <li>
                                                    {
                                                        patientData.gender ?
                                                            <div className="gender_area"><FontAwesomeIcon icon={faVenus} className="icon" />{patientData.gender}</div>
                                                            :
                                                            null
                                                    }
                                                </li>
                                                <li>
                                                    {
                                                        patientData && patientData.cases && patientData.cases.length > 0 ?
                                                            <div className="label_area" style={{textTransform:'uppercase'}}>{patientData.cases[0].ward_title}</div>
                                                            :
                                                            null
                                                    }
                                                </li>
                                                <li>
                                                    {
                                                        patientData && patientData.cases && patientData.cases.length > 0 ?
                                                            <div className="general"><FontAwesomeIcon icon={faHeartPulse} className="icon_heart" />{patientData.cases[0].patient_type}</div>
                                                            :
                                                            null
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="contact_area">
                                            <div className="left_con">
                                                <ul>
                                                    <li><a href={`tel:${patientData.contact_number}`}>{patientData.contact_number ? patientData.contact_number : ''}</a></li>
                                                    <li><a href={`tel:${patientData.alternative_number}`}>{patientData.alternative_number ? patientData.alternative_number : ''}</a></li>
                                                </ul>
                                            </div>
                                            <div className="right_con">
                                                <div className="email">{patientData.email ? patientData.email : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="patient_information">
                                    {
                                        patientData.father_name ?
                                            <div className="profile_info">
                                                <div className="left_info">
                                                    <div className="name" >Father Name</div>
                                                </div>
                                                <div className="right_info">
                                                    <div className="info" style={{ textTransform: 'capitalize' }} >{patientData.father_name}</div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        patientData.mother_name ?
                                            <div className="profile_info">
                                                <div className="left_info">
                                                    <div className="name">Mother Name</div>
                                                </div>
                                                <div className="right_info">
                                                    <div className="info" style={{ textTransform: 'capitalize' }} >{patientData.mother_name}</div>
                                                </div>
                                            </div>
                                            :
                                            null

                                    }
                                    {
                                        patientData.dob ?
                                            <div className="profile_info">
                                                <div className="left_info">
                                                    <div className="name">D.O.B</div>
                                                </div>
                                                <div className="right_info">
                                                    <div className="info">{dateFormat(patientData.dob)}</div>
                                                </div>
                                            </div>
                                            :
                                            null

                                    }
                                    {
                                        patientData.age ?
                                            <div className="profile_info">
                                                <div className="left_info">
                                                    <div className="name">Age</div>
                                                </div>
                                                <div className="right_info">
                                                    <div className="info">{patientData.age}</div>
                                                </div>
                                            </div>
                                            :
                                            null

                                    }
                                    {
                                        patientData.gender ?
                                            <div className="profile_info">
                                                <div className="left_info">
                                                    <div className="name">Gender</div>
                                                </div>
                                                <div className="right_info">
                                                    <div className="info" style={{ textTransform: 'capitalize' }} >{patientData.gender}</div>
                                                </div>
                                            </div>
                                            :
                                            null

                                    }
                                    {
                                        patientData.blood_group ?
                                            <div className="profile_info">
                                                <div className="left_info">
                                                    <div className="name">Blood Group</div>
                                                </div>
                                                <div className="right_info">
                                                    <div className="info">{patientData.blood_group}</div>
                                                </div>
                                            </div>
                                            :
                                            null

                                    }
                                    {
                                        patientData.marital_status ?
                                            <div className="profile_info">
                                                <div className="left_info">
                                                    <div className="name">Marital Status</div>
                                                </div>
                                                <div className="right_info">
                                                    <div className="info" style={{ textTransform: 'capitalize' }} >{patientData.marital_status}</div>
                                                </div>
                                            </div>
                                            :
                                            null

                                    }
                                    {
                                        patientData.occupation ?
                                            <div className="profile_info">
                                                <div className="left_info">
                                                    <div className="name">Occupation</div>
                                                </div>
                                                <div className="right_info">
                                                    <div className="info" style={{ textTransform: 'capitalize' }} >{patientData.occupation}</div>
                                                </div>
                                            </div>
                                            :
                                            null

                                    }
                                    {
                                        patientData.nationlity_name ?
                                            <div className="profile_info">
                                                <div className="left_info">
                                                    <div className="name">Nationality</div>
                                                </div>
                                                <div className="right_info">
                                                    <div className="info" style={{ textTransform: 'capitalize' }} >{patientData.nationlity_name}</div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        patientData.area ?
                                            <div className="profile_info">
                                                <div className="left_info">
                                                    <div className="name">House No.</div>
                                                </div>
                                                <div className="right_info">
                                                    <div className="info">{patientData.area}</div>
                                                </div>
                                            </div>
                                            :
                                            null

                                    }
                                    {
                                        patientData.district_name ?
                                            <div className="profile_info">
                                                <div className="left_info">
                                                    <div className="name">District</div>
                                                </div>
                                                <div className="right_info">
                                                    <div className="info" style={{ textTransform: 'capitalize' }} >{patientData.district_name}</div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        patientData.state_name ?
                                            <div className="profile_info">
                                                <div className="left_info">
                                                    <div className="name">State</div>
                                                </div>
                                                <div className="right_info">
                                                    <div className="info" style={{ textTransform: 'capitalize' }} >{patientData.state_name}</div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        patientData.city_name ?
                                            <div className="profile_info">
                                                <div className="left_info">
                                                    <div className="name">City</div>
                                                </div>
                                                <div className="right_info">
                                                    <div className="info" style={{ textTransform: 'capitalize' }} >{patientData.city_name}</div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        patientData.address ?
                                            <div className="profile_info">
                                                <div className="left_info">
                                                    <div className="name" >Address</div>
                                                </div>
                                                <div className="right_info">
                                                    <div className="info">{patientData.address}</div>
                                                </div>
                                            </div>
                                            :
                                            null

                                    }
                                      {
                                        patientData.adhaar_card ?
                                            <div className="profile_info">
                                                <div className="left_info">
                                                    <div className="name" >Aadhar Number</div>
                                                </div>
                                                <div className="right_info">
                                                    <div className="info">{patientData.adhaar_card}</div>
                                                </div>
                                            </div>
                                            :
                                            null

                                    }
                                    <div className="circle_area">
                                        <Image src={Circle} alt="..." />
                                    </div>
                                </div>
                                {
                                    User && parseInt(User.user_type) === 1 && patientData.patient_status !== 'discharge' ?
                                        <div className="btn_area">
                                            <Button
                                                className="cancelBtn"
                                                type="button"
                                                onClick={() => props.close()}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="submitBtn"
                                                type="button"
                                                onClick={() => navigate('/edit-patient', { state: { id: patientData.id ? patientData.id : '' } })}
                                            >
                                                Edit Detail
                                            </Button>
                                        </div>
                                        :
                                        checkPermissions
                                            && checkPermissions.permissions
                                            && checkPermissions.permissions.length > 2
                                            && parseInt(checkPermissions.permissions[2].value) === 1 && patientData.patient_status !== 'discharge' ?
                                            <div className="btn_area">
                                                <Button
                                                    className="cancelBtn"
                                                    type="button"
                                                    onClick={() => props.close()}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    className="submitBtn"
                                                    type="button"
                                                    onClick={() => navigate('/edit-patient', { state: { id: patientData.id ? patientData.id : '' } })}
                                                >
                                                    Edit Detail
                                                </Button>
                                            </div>
                                            :
                                            null
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                    :
                    null
            }
        </div>
    )
}

export default PatientProfileModal;