import { faCircle, faEllipsisVertical, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Dropdown, Form, Image, Row, Table } from "react-bootstrap";
import { AssignImg, Delete_2, Edit_2, Eye_2, Filters, Patient } from "../../../../../assets/images";
import * as Constants from '../../../../Helper/constants/constants';
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import PaginationComponent from "../../../CommanComponents/Pagination";
import FilterModal from "../../../Modals/FilterModal";
import Loader from "../../../../Helper/loader";
import NoDataComponent from "../../../CommanComponents/NoDataComponent";
import { dateFormat, renderImage, timeFormat2 } from "../../../../Helper/General";
import { dateFormat2 } from "../../../../Helper/General";
import { timeFormat } from "../../../../Helper/General";
import DeleteModal from "../../../Modals/DeleteModal";
import PatientListFilterModal from "../../../Modals/PatientListFiltersModal";
import { useCallback } from "react";
import { debounce } from "lodash";
import store from "../../../../../redux/store";
import MedicineCommentModel from "../../../Modals/AddCommentMedicine";
import ExistPatientListFunctions from "./functions";
import ExistPatientListFilterModal from "../../../Modals/ExistPatientListFilterModel";



const ExistingPatient = () => {
    const {
        getModulePermissions,
        checkPermissions,
        getPatientListing,
        patientListing,
        handleBackClick,
        handleNextClick,
        handleDeletePatient,
        handleSearch,
        page,
        totalPages,
        itemsPerPage,
        searchInput,
        setSearchInput,
        showFilterModal,
        setShowFilterModal,
        breadcrumData,
        breadcrumItem,
        navigate,
        loader,
        handlePage,
        handleBreadcrumDataNaviagtion,
        deletePopup,
        setDeletePopup,
        handleItemPerPage,
        setPatientDetail,
        applyFilters,
        handleClearAllFilters,
        setReduxValue,
        filtersAppliedSign,
        keyword,
        setSearch,
        patientStatus,
        chnagePatientStatus,
        handleDrawer,
        totalCount,
        User
    } = ExistPatientListFunctions();
    let storedFilters = store.getState().PatientListFilters.filters;

    useEffect(() => {
        let userdata = localStorage.getItem('SET_USER_DATA');
        let User = JSON.parse(userdata);
        if (User && parseInt(User.user_type) === 2) {
            getModulePermissions();
        }
        else;
    }, []);


    const search = useCallback(debounce(getPatientListing, 1000), []);

    return (
        <div className="manage_patient_list_screen">
            <WithoutImageHeader
                header={Constants.EXISTING_PATIENT}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBack={() => navigate(-1)}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
                setReduxvalue={(e) => setReduxValue(e)}
            // lefticon={"menu"}
            />
            <Container onClick={() => handleDrawer()}>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="patient_list_details">
                            <div className="title_btn_area">
                                <div className="left_area">
                                    <h3>Total Patient : {totalCount ? totalCount : 0} </h3>
                                </div>
                            </div>
                            <div className="search_section">
                                <div className="search_left_area">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Control
                                                type="text"
                                                placeholder="Search by patient name here"
                                                value={keyword}
                                                onChange={(e) =>
                                                (
                                                    search(e.target.value, 1, itemsPerPage, storedFilters),
                                                    setSearch(e.target.value))
                                                }
                                            />
                                            <a >
                                                <div className="icon">
                                                    <FontAwesomeIcon icon={faSearch} />
                                                </div>
                                            </a>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div className="search_right_area">
                                    <Form>
                                        <Form.Group className="form-group select_pagination">
                                            <Form.Select
                                                value={itemsPerPage}
                                                onChange={(e) => handleItemPerPage(e)}
                                            >
                                                <option value={5}>5</option>
                                                <option value={10}>10</option>
                                                <option value={15}>15</option>
                                                <option value={20}>20</option>
                                                <option value={25}>25</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Form>

                                    <div className="filter_button">
                                        <Button
                                            className={filtersAppliedSign ? "btn-primary" : 'btn-border'}
                                            onClick={() => setShowFilterModal(true)}
                                        >
                                            <Image
                                                src={Filters}
                                                alt="..."
                                                className="image_area"
                                            />
                                            Filters
                                        </Button>
                                        {
                                            filtersAppliedSign ?
                                                <FontAwesomeIcon
                                                    icon={faCircle}
                                                    className="filter_icon"
                                                />
                                                :
                                                null
                                        }
                                    </div>

                                </div>

                            </div>
                            {
                                loader ?
                                    <Loader
                                        loader={loader}
                                    />
                                    :
                                    patientListing && patientListing.length > 0 ?
                                        <div className="list_detail">
                                            <div className="list_data">
                                                <Card>
                                                    <Card.Body>
                                                        <Table responsive>
                                                            <tr>
                                                                <th>Hospital Name</th>
                                                                <th className="role_text">Patient Name</th>
                                                                <th>Ward</th>
                                                                <th>Room</th>
                                                                <th>Bed</th>
                                                                <th>Status</th>
                                                                <th>Date of Admission</th>
                                                                <th>Action</th>
                                                            </tr>
                                                            {
                                                                patientListing.map((item, i) => (
                                                                    parseInt(item.status) === 1 ?
                                                                        <tr key={i}>
                                                                            <td>
                                                                                <div className="data_table">
                                                                                    <div className="left_area">
                                                                                        {/* <div className="img_area"> */}
                                                                                        {/* <Image src={AssignImg} alt="..." /> */}
                                                                                        <Image
                                                                                            src={item && item.hospitals && item.hospitals[0] && item.hospitals[0].hospital_image ? renderImage(item.hospitals[0].hospital_image) : Patient}
                                                                                            alt='...'
                                                                                            className="img_area"
                                                                                            onClick={() => navigate(`/hospital-detail/${item && item.hospitals && item.hospitals[0] && item.hospitals[0].id}`,
                                                                                                {
                                                                                                    state: {
                                                                                                        name: "patientlist",
                                                                                                        fromNavigation: true
                                                                                                    }
                                                                                                })}
                                                                                        />
                                                                                        {/* </div> */}
                                                                                    </div>
                                                                                    <div className="right_areaa"

                                                                                        onClick={() => navigate(`/hospital-detail/${item && item.hospitals && item.hospitals[0] && item.hospitals[0].id}`,
                                                                                            {
                                                                                                state: {
                                                                                                    name: "patientlist",
                                                                                                    fromNavigation: true
                                                                                                }
                                                                                            })}
                                                                                    >
                                                                                        {item && item.hospitals && item.hospitals[0] && item.hospitals[0].title
                                                                                            ? item.hospitals[0].title
                                                                                            : null}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td >
                                                                                <div className="data_table">
                                                                                    <div className="left_area">
                                                                                        {/* <div className="img_area"> */}
                                                                                        {/* <Image src={AssignImg} alt="..." /> */}
                                                                                        <Image
                                                                                            src={item.image && item.image ? renderImage(item.image) : Patient}
                                                                                            alt='...'
                                                                                            className="img_area"
                                                                                            onClick={() => navigate(`/patientDetails/${item.id}`, { state: { name: item.title, name1: 'ExistingPatientList', pageParams: ['Dashboard', 'Discharged Patient List'] } })}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="right_areaa"
                                                                                        onClick={() => navigate(`/patientDetails/${item.id}`, { state: { name: item.title, name1: 'ExistingPatientList', pageParams: ['Dashboard', 'Discharged Patient List'] } })}>
                                                                                        <h4>{item.patient_name ? item.patient_name : ''}</h4>
                                                                                        {
                                                                                            item.uh_id ?
                                                                                                <p className="patient_number">{item.uh_id}</p>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    item && item.active_case[0] && item.active_case[0].ward_title ?
                                                                                        <p style={{ textTransform: 'uppercase' }}><span className="designation">{item && item.active_case[0] && item.active_case[0].ward_title}</span></p>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </td>
                                                                            <td>{item && item.active_case[0] && item.active_case[0].room_number ? item && item.active_case[0] && item.active_case[0].room_number : ''}</td>
                                                                            <td>{item && item.active_case[0] && item.active_case[0].bed_number ? item && item.active_case[0] && item.active_case[0].bed_number : ''}</td>
                                                                            <td>
                                                                                {
                                                                                    item.patient_status === 'discharge' ?
                                                                                        <div className="badge">
                                                                                            <p class="discharge">Discharge</p>
                                                                                        </div>
                                                                                        :
                                                                                        User && parseInt(User.user_type) === 2 ?
                                                                                            item.patient_status === 'under_treatment' ?
                                                                                                <div className="badge">
                                                                                                    <p class="warning">Under Treatment</p>
                                                                                                </div>
                                                                                                :
                                                                                                item.patient_status === 'critical' ?
                                                                                                    <div className="badge">
                                                                                                        <p class="danger">Critical</p>
                                                                                                    </div>
                                                                                                    :
                                                                                                    item.patient_status === 'admitted' ?
                                                                                                        <div className="badge">
                                                                                                            <p class="success">Admitted</p>
                                                                                                        </div>
                                                                                                        :
                                                                                                        item.patient_status === 'discharge' ?
                                                                                                            <div className="badge">
                                                                                                                <p class="discharge">Discharge</p>
                                                                                                            </div>
                                                                                                            :
                                                                                                            null
                                                                                            :
                                                                                            <Dropdown>
                                                                                                <Dropdown.Toggle variant="none" id="dropdown-basic">
                                                                                                    {
                                                                                                        item.patient_status === 'under_treatment' ?
                                                                                                            <div className="badge">
                                                                                                                <p class="warning">Under Treatment</p>
                                                                                                            </div>
                                                                                                            :
                                                                                                            item.patient_status === 'critical' ?
                                                                                                                <div className="badge">
                                                                                                                    <p class="danger">Critical</p>
                                                                                                                </div>
                                                                                                                :
                                                                                                                item.patient_status === 'admitted' ?
                                                                                                                    <div className="badge">
                                                                                                                        <p class="success">Admitted</p>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    item.patient_status === 'discharge' ?
                                                                                                                        <div className="badge">
                                                                                                                            <p class="discharge">Discharged</p>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        null
                                                                                                    }
                                                                                                </Dropdown.Toggle>
                                                                                                <Dropdown.Menu>
                                                                                                    {patientStatus.map((ditem, index) => (
                                                                                                        item.patient_status !== ditem.id ?
                                                                                                            <Dropdown.Item key={index}
                                                                                                                onClick={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    chnagePatientStatus(item.id, ditem.id)
                                                                                                                }
                                                                                                                }>
                                                                                                                {ditem.value}
                                                                                                            </Dropdown.Item>
                                                                                                            :
                                                                                                            null
                                                                                                    ))}
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                }
                                                                            </td>
                                                                            <td>{item.date_of_admission ? `${dateFormat2(item.date_of_admission)}` : '-'}</td>
                                                                            <td>
                                                                                <div className="icon">
                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle variant="none" id="dropdown-basic">
                                                                                            <FontAwesomeIcon icon={faEllipsisVertical} className="ellipsis_icon" />
                                                                                        </Dropdown.Toggle>
                                                                                        <Dropdown.Menu>
                                                                                            <Dropdown.Item
                                                                                                onClick={() => navigate(`/patientDetails/${item.id}`, { state: { name: item.title, name1: 'ExistingPatientList', pageParams: ['Dashboard', 'Discharged Patients List'] } })}
                                                                                            >
                                                                                                View
                                                                                                <Image src={Eye_2} alt="..." className="image_area" />
                                                                                            </Dropdown.Item>
                                                                                            {/* <Dropdown.Item onClick={() => navigate('/edit-patient', { state: { id: item.id ? item.id : '' } })}>
                                                                                                Edit
                                                                                                <Image src={Edit_2} alt="..." className="image_area" />
                                                                                            </Dropdown.Item> */}
                                                                                            {
                                                                                                User && parseInt(User.user_type) === 1 ?
                                                                                                    <Dropdown.Item onClick={() => (setDeletePopup(true), setPatientDetail(item))}>Delete
                                                                                                        <Image src={Delete_2} alt="..." className="image_area delete_icon " />
                                                                                                    </Dropdown.Item>
                                                                                                    :
                                                                                                    checkPermissions
                                                                                                        && checkPermissions.permissions
                                                                                                        && checkPermissions.permissions.length > 3
                                                                                                        && parseInt(checkPermissions.permissions[3].value) === 1 ?
                                                                                                        <Dropdown.Item onClick={() => (setDeletePopup(true), setPatientDetail(item))}>Delete
                                                                                                            <Image src={Delete_2} alt="..." className="image_area delete_icon " />
                                                                                                        </Dropdown.Item>
                                                                                                        :
                                                                                                        null
                                                                                            }
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        :
                                                                        null
                                                                ))
                                                            }
                                                        </Table>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            {!loader ?
                                                <NoDataComponent
                                                    message="No Patient Found!"
                                                /> : null}
                                        </>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            {
                !loader && patientListing && patientListing.length > 0 ?
                    <PaginationComponent
                        page={page}
                        setPage={handlePage}
                        totalPages={totalPages}
                        handleBackClick={handleBackClick}
                        handleNextClick={handleNextClick}
                    />
                    :
                    null
            }
            {
                showFilterModal ? (
                    <ExistPatientListFilterModal
                        show={showFilterModal}
                        applyFilters={applyFilters}
                        handleClearAllFilters={handleClearAllFilters}
                        close={() => setShowFilterModal(false)}
                    />
                ) : null
            }
            {
                deletePopup ?
                    <DeleteModal
                        show={deletePopup}
                        handleSubmit={() => handleDeletePatient()}
                        close={() => setDeletePopup(false)}
                        message={'Do you want to delete this patient?'}
                    />
                    :
                    null
            }
        </div>
    )
}

export default ExistingPatient;