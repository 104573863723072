import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { actionsController } from "../../../../../apis/Controllers/CommonController/action.controller";
import hospitalController from "../../../../../apis/Controllers/HospitalController/hospital.controller";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import Validation from "../../../../Helper/validations/vaildation";
import store from "../../../../../redux/store";

const AddHospitalFunctions = () => {
    var breadcrumData = ["Dashboard", "Hospital List"];
    const [breadcrumItem, setBreadcrumItem] = useState("Add Hospital");

    useEffect(() => {
        getStatesList();
        // getCitiesList();
    }, []);

    const defaultValues = {
        image: null,
        name: null,
        gstNumber: null,
        phonenumber: null,
        telephonenumber: null,
        email: null,
        address: null,
        city: null,
        state: null,
        zipcode: null,
        mapLink: null,
    };

    const defaultErrors = {
        image: {
            rules: [""],
            isValid: true,
            message: "",
        },
        name: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        gstNumber: {
            rules: ["required", "gst"],
            isValid: true,
            message: "",
        },
        phonenumber: {
            rules: ["required", "min:8", "max:13"],
            isValid: true,
            message: "",
        },
        telephonenumber: {
            rules: ["required", "min:8", "max:13"],
            isValid: true,
            message: "",
        },
        email: {
            rules: [""],
            isValid: true,
            message: "",
        },
        address: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        city: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        state: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        zipcode: {
            rules: ["required", "numeric", "max:8", "min:6"],
            isValid: true,
            message: "",
        },
        mapLink: {
            rules: ["maplink"],
            isValid: true,
            message: "",
        },
    };

    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);
    const [citieslist, setCitiesList] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [loader, setLoader] = useState(false);
    const loaction = useLocation();
    const navigate = useNavigate();

    const handleChange = (field, value) => {
        let err = { ...isError };
        if (field === "email") {
            if (value.length > 0) {
                err = {
                    ...isError,
                    email: {
                        rules: ["email"],
                        isValid: true,
                        message: "",
                    },
                };
            } else {
                err = {
                    ...isError,
                    email: {
                        rules: [""],
                        isValid: true,
                        message: "",
                    },
                };
            }
        }
        let validation = new Validation(err);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };

    const handleSubmit = async () => {

        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            if (loaction && loaction.state && loaction.state.name) {
                // <-----------UPDATE HOSPITAL
                let id =
                    loaction && loaction.state && loaction.state.id
                        ? loaction.state.id
                        : "";
                setLoader(true);
                let response = await hospitalController.updateHospital(
                    values,
                    id
                );
                if (response && response.status) {
                    new Toaster().success(response.message);
                    navigate("/hospital-listing" ,{ replace: true });
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            } else {
                setLoader(true);
                let response = await hospitalController.createHospital(values); // <-----------ADD HOSPITAL
                if (response && response.status) {
                    new Toaster().success(response.message);
                    navigate("/hospital-listing", { replace: true });
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            }
        } else {
            setError({ ...isValid.errors });
        }
        setLoader(false);
    };

    const getHospitalDetail = async (id) => {
        let response = await hospitalController.hospitalDetail(id);
        if (response && response.status) {
            let data = response.data;
            setLoader(false);
            setValues({
                ...values,
                image: data.image ? data.image : null,
                name: data && data.title ? data.title : "",
                phonenumber: data && data.phonenumber ? data.phonenumber : "",
                telephonenumber:
                    data && data.telephone_number ? data.telephone_number : "",
                email: data && data.email ? data.email : "",
                gstNumber: data && data.gst_number ? data.gst_number : "",
                address: data && data.address ? data.address : "",
                city:
                    data && data.city_id
                        ? {
                            id: data.city_id,
                            label: data.city_name,
                        }
                        : "",
                state:
                    data && data.state_id
                        ? {
                            id: data.state_id,
                            label: data.state_name,
                        }
                        : // data.state
                        "",
                zipcode: data && data.zipcode ? data.zipcode : "",
                mapLink: data && data.map_link ? data.map_link : "",
            });
            getCitiesList(data.state_id);
        } else {
            setLoader(false);
        }
    };

    const getCitiesList = async (id) => {
        let response = await actionsController.citiesList({ state_id: id });
        if (response && response.status) {
            const transformedArray = response.data.map((obj) => {
                return {
                    id: obj.id,
                    label: obj.title,
                };
            });
            setCitiesList(transformedArray);
        } else {
            setCitiesList([]);
        }
    };

    const getStatesList = async () => {
        let response = await actionsController.statesList({ country_id: 100 });
        if (response && response.status) {
            const transformedArray = response.data.map((obj) => {
                return {
                    id: obj.id,
                    label: obj.title,
                };
            });
            setStatesList(transformedArray);
        } else {
            setStatesList([]);
        }
    };

    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/dashboard-superadmin");
            }
        } else if (i === 1) {
            navigate("/hospital-listing");
        } else return;
    };

    return {
        handleChange,
        handleSubmit,
        loader,
        values,
        isError,
        getHospitalDetail,
        breadcrumData,
        breadcrumItem,
        setBreadcrumItem,
        getCitiesList,
        getStatesList,
        citieslist,
        statesList,
        loaction,
        navigate,
        handleBreadcrumDataNaviagtion,
    };
};

export default AddHospitalFunctions;
