import { faCalendarWeek, faEnvelope, faLocationDot, faPhone, faVenus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { HeightImg, PatientProfileImg, WeightImg } from "../../../../assets/images";
import * as Constants from '../../../Helper/constants/constants';
import CustomTab from "../../../Helper/customTabs";
import WithoutImageHeader from "../../../Helper/withoutImageHeader";
import Case from "../../Tabs/Case";
import Documents from "../../Tabs/Documents";
import History from "../../Tabs/History";


const PatientProfile = () => {
    var breadcrumData = ['Dashboard', 'Ward'];
    var breadcrumItem = 'Patient List';
    const [currentTab, setCurrentTab] = useState('1');
    const tabs = [
        {
            id: 1,
            tabTitle: 'Case',
        },
        {
            id: 2,
            tabTitle: 'Documents',
        },
        {
            id: 3,
            tabTitle: 'History',
        }
    ];

    return (
        <div className="patient_profile_screen">
            <WithoutImageHeader header={Constants.PATIENT_PROFILE_HEADER} breadcrumData={breadcrumData} breadcrumItem={breadcrumItem} />
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="profile_data">
                            <div className="left_area">
                                <div className="profile_details">
                                    <Image src={PatientProfileImg} alt="..." />
                                </div>
                            </div>
                            <div className="right_area">
                                <div className="inner_area">
                                    <h3>Robert William</h3>
                                    <h6>Ebola haemorrhagic fever</h6>
                                    <div className="gender_area">
                                        <span>Female <FontAwesomeIcon icon={faVenus} /></span>
                                        <p>Room No. 4</p>
                                    </div>
                                    <div className="data">
                                        <p><FontAwesomeIcon icon={faPhone} /> +91 90249 74391</p>
                                    </div>
                                    <div className="data">
                                        <p><FontAwesomeIcon icon={faEnvelope} /> robertwillia@gmail.com</p>
                                    </div>
                                    <div className="data">
                                        <p><FontAwesomeIcon icon={faCalendarWeek} /> Jan 20, 2023</p>
                                    </div>
                                    <div className="data">
                                        <p><FontAwesomeIcon icon={faLocationDot} /> Plot no. 16, Punjab</p>
                                    </div>
                                    <Button type="button" className="btn_warning">Under Treatment</Button>
                                </div>
                            </div>
                        </div>
                        <div className="height_weight_area">
                            <Row>
                                <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <div className="record height_details">
                                        <Image src={HeightImg} alt="..." />
                                        <p>Height <span>170 cm</span></p>
                                    </div>
                                </Col>
                                <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <div className="record weight_details">
                                        <Image src={WeightImg} alt="..." />
                                        <p>Weight <span>72 kg</span></p>
                                    </div>
                                </Col>

                                <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <div className="record history_details">
                                        <p>None</p>
                                        <p>Past History</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="buttons_area">
                <CustomTab
                    tabs={tabs}
                    currentTab={currentTab}
                    setCurrentTab={(e) => setCurrentTab(e)}
                />
            </div>
            <Container>
                {parseInt(currentTab) === 1 ? <Case /> : null}
                {parseInt(currentTab) === 2 ? <Documents /> : null}
                {parseInt(currentTab) === 3 ? <History /> : null}
            </Container>

        </div>
    )
}

export default PatientProfile;