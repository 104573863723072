import moment from "moment";
import WebConstant from "../../apis/webConstants";
import React from "react";

export function baseUrl(path) {
    if (path && path !== null && path !== "") return WebConstant.image + path;
    else return WebConstant.image;
}

export function baseUrlImage(path) {
    if (path && path !== null && path !== "") return WebConstant.image + path;
    else return WebConstant.image;
}
export function sharableUrl(path) {
    return encodeURI(WebConstant.host + path);
}

export function getUrlParam(key) {
    let urlParams = new URLSearchParams();
    return urlParams.has(key) ? urlParams.get(key) : null;
}

export function stringLimit(string, limit) {
    return string !== null
        ? string.length >= limit
            ? string.substr(0, limit) + "..."
            : string
        : "";
}


export function stripTags(html) {
    return html.replace(/(<([^>]+)>)/gi, "");
}



export function _date(timestamp) {
    let date = new Date(timestamp);
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;

    let m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;

    let y = date.getFullYear();

    return d + "/" + m + "/" + y;
}

export function _time(date) {
    return moment(date).format("LT");
}

export function _msdate(timestamp) {
    let date = new Date(timestamp);
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;

    let m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;

    let y = date.getFullYear();
    return y + "-" + m + "-" + d;
}

// export function _msdate(timestamp) {
//     let date = new Date(timestamp);
//     let d = date.getDate();
//     d = d < 10 ? '0' + d : d;

//     let m = date.getMonth() + 1;
//     m = m < 10 ? '0' + m : m;

//     let y = date.getFullYear();
//     console.log(y,'-',m);
//     return y + '-' + m;
// }

export function dateText(timestamp) {
    var d = new Date(timestamp);
    var days = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
    var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];

    return (
        days[d.getDay()] +
        ", " +
        d.getDate() +
        " " +
        months[d.getMonth()] +
        (new Date().getFullYear() !== d.getFullYear()
            ? ` ` + d.getFullYear()
            : ``)
    );
}

export function timeAgo(time) {
    let today = moment().format("L");
    let expected = moment(time).format("L");
    return moment(time).fromNow(true) + ' ago';
}

export function renderImage(image, size = "small") {
    if (typeof image === "string") {
        return image ? baseUrlImage(image) : '';
    } else if (size == "large") {
        return image && image.large && image.large !== ``
            ? baseUrlImage(image.large)
            : image && image.original && image.original !== ``
                ? baseUrlImage(image.original)
                : null;
    } else if (size === "medium") {
        return image && image.medium && image.medium !== ``
            ? baseUrlImage(image.medium)
            : image && image.original && image.original !== ``
                ? baseUrlImage(image.original)
                : null;
    } else {
        return image && image.small && image.small !== ``
            ? baseUrlImage(image.small)
            : image && image.original && image.original !== ``
                ? baseUrlImage(image.original)
                : '';
    }
}

export function renderDocument(image) {
    if (typeof image === "string") {
        return image ? baseUrlImage(image) : '';
    }
}

export function respondMessage(mins) {
    if (mins * 1 >= 1440) {
        return `Typically responds in days`;
    } else if (mins * 1 >= 60) {
        return `Typically reponds in hours`;
    } else if (mins * 1 >= 0) {
        return `Typically reponds in minutes`;
    } else {
        return ``;
    }
}

export function getMonths(name = false) {
    // let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return name ? months[name] : months
}

export function getYear() {
    let date = new Date();
    return date.getFullYear();
}

export function renderVideoThumb(video, path) {
    video = video.replace(/\\/g, '/').split('/');
    video = video[video.length - 1];
    return baseUrl((path ? path : '/uploads/posts/thumbs/') + video.replace('.mp4', '.png'));
}

export function range(start, end, mZero = false) {
    var ans = [];
    for (let i = start; i <= end; i++) {
        if (mZero) {
            ans.push(i <= 9 ? '0' + i : i);
        }
        else {
            ans.push(i);
        }
    }
    return ans;
}

export function leapYear(year) {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
}

export function copyToClipboard(text) {
    let copyText = document.querySelector("#clipboard-input");
    if (!copyText || copyText.length < 1) {
        let elemDiv = document.createElement('input');
        elemDiv.setAttribute("id", 'clipboard-input');
        elemDiv.style.cssText = 'position:absolute;top:-1000px; left: -1000px;';
        elemDiv.value = text;
        document.body.appendChild(elemDiv);
        copyText = document.querySelector("#clipboard-input");
    }
    else {
        copyText.value = text;
    }
    copyText.select();
    navigator.clipboard
        .writeText(copyText.value)
        .then(() => {
        })
        .catch(() => {
        });
}

export function linkify(inputText) {
    var replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
    return replacedText;
}

export function getFlagIcon(code) {
    return `https://flagpedia.net/data/flags/h60/` + code.toLowerCase() + '.webp'
}

export function nFormatter(num, digits) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "";
}


export function ucfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


//Check Variables
export const isEmpty = (item) => {

    if (item === null) {
        return true
    }
    else if (item === '') {
        return true;
    }
    else if (item === undefined) {
        return true;
    }
    else {
        return false;
    }
}

//Check array
export const isEmptyArray = (item) => {
    // console.log(item);
    if (item.length <= 0)
        return true
    else
        return false;
}

export const isEmptyObj = (item) => {
    if (Object.keys(item).length === 0)
        return true
    else
        return false;
}

export function parseYoutubeUrl(url) {
    let id = url.replace('https://www.youtube.com/watch?v=', '').replace('https://youtu.be/', '').replace('https://www.youtube.com/embed/', '');
    return 'https://www.youtube.com/embed/' + id;
}

export function intToString(value) {
    value = value * 1;
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(2));
    if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
}

export function renderNoAvatar(name) {
    return `https://ui-avatars.com/api/?background=ebd0d9&font-size=0.3&name=` + name.substr(0, 1);
}

export function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null;
}

export const dateFormat = (date) => {                     //------------------------------>>>>> Created date Format
    if (date) {
        return moment(date).format('DD MMM, YYYY');
    }
}

export const dateFormat3 = (date) => {                     //------------------------------>>>>> Created date Format
    if (date) {
        return moment(date).format('DD MMM, YYYY , h:mm A');
    }
}


export const dateFormat2 = (date) => {                     //------------------------------>>>>> Created date Format2
    if (date) {
        return moment(date).format('DD/MM/YYYY');
    }
}

export const dateFormat222 = (date) => {                     //------------------------------>>>>> Created date Format2
    if (date) {
        return moment(date).format('DD-MM-YYYY');
    }
}


export const timeInAgoFormat = (time) => {
    if (time) {
        return moment().startOf('mm').fromNow();
    }
}


export const timeFormat = (time) => {
    if (time) {
        let momentTime = moment(time).format('hh:mm A');
        // let final = moment(momentTime)
        // let selectedTime = moment(final).format('hh mm a')
        return momentTime
    }
}

export const timeFormat2 = (time) => {
    if (time) {
        let selectedTime = moment(time).format('LT')
        return selectedTime
    }
}


export const timeFormat3 = (time) => {
    if (time) {
        let momentTime = moment(time, 'HH:mm a');
        let newTime = moment(momentTime._d).format('LT')
        return newTime
    }
}

export const phoneNumberFormatter = (item) => {
    if (item) {
        var p = item
        p = p.replace(/\D+/g, '')
        p = p.substr(0, 3) + '-' + p.substr(3, 3) + '-' + p.substr(6, 4);
        return p;
    }
}

export const getExtension = path => {
    path = path.split('/');
    let ext = path[path.length - 1].split('.');
    return ext.length > 1 ? ext[ext.length - 1].toLowerCase() : null;
};


export const spaceAfter4Digit = (data) => {
    if (data !== null && data !== '') {
        const number = data.replace(/\D/g, ''); // Remove non-digits from the input
        const formattedNumber = number.replace(/(.{4})/g, '$1 '); // Add a space after every 4 digits
        return formattedNumber.trim(); // Trim any leading/trailing spaces
    }
    return '';
}

export const openEmail = (data) => {
    if (data) {
        return window.open(`mailto:${data}`);
    }
}

export const openPhoneDialer = (data) => {
    if (data) {
        return window.open(`tel:${data}`);
    }
}

export const isPastDate = async (dateString) => {

    var today = new Date();
    var inputDate = new Date(dateString);

    // Set hours, minutes, seconds, and milliseconds to 0 for both dates
    today.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);


    return inputDate < today;
}

export const getName = (path) => {
    path = path.split('/');
    return path.length > 1 ? path[path.length - 1] : path;
}
export const getName3 = (path) => {
    path = path.split(`\\`);
    return path.length > 1 ? path[path.length - 1] : path;
}

export const getName2 = (path) => {
    path = path.split('-');
    return path.length > 1 ? path[path.length - 1] : path;
}

export const checkTypeParam = (param) => {
    if (typeof param === 'string') {
        return true;
    } else if (Array.isArray(param)) {
        return false
    }
}

export const getShortName = (fileName) => {
    const parts = fileName.split('\\');
    const lastPart = parts[parts.length - 1];
    const shortName = lastPart.substring(0, 10); // Adjust the length as needed
    const fileExtension = lastPart.substring(lastPart.lastIndexOf('.') + 1);
    const dottedShortName = `...${shortName}.${fileExtension}`;
    return dottedShortName;
}

export function parseHtmlString (htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const rootElement = doc.body.firstChild;
  
    if (!rootElement) {
      return null;
    }
  
    const children = Array.from(rootElement.childNodes).map((node, index) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const tagName = node.tagName ? node.tagName.toLowerCase() : "div";
        const attributes = Array.from(node.attributes).reduce(
          (attrs, attr) => ({ ...attrs, [attr.name]: attr.value }),
          {}
        );
        const children = parseHtmlString(node.innerHTML);
        return React.createElement(tagName, { key: index, ...attributes }, children);
      }
      return null;
    });
  
    return React.createElement(
      rootElement.tagName ? rootElement.tagName.toLowerCase() : "div",
      {},
      children
    );
}