import { Toaster } from "../../../components/Helper/toaster/Toaster";
import { casesServices } from "../../Services/PatientCasesServices/patientCases.services";


const casesList = async (data) => {
    let response = await casesServices.casesList(data);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const createCase = async (data) => {
    let post = {
        hospital_id: data && data.hospitalId ? [data.hospitalId] : null,
        //<-----------------patient detail------------------------------
        patient_id: data && data.patientData ? data.patientData.patientId : null,
        patient_name: data && data.patientData ? data.patientData.patientName : null,
        patient_status: data && data.patientData ? data.patientData.patientStatus : null,
        //<-------------------------------------------------------------
        case_title: data && data.caseTitle ? data.caseTitle : null,
        ward_id: data && data.ward ? data.ward.id : null,
        room_id: data && data.roomNo ? data.roomNo.id : null,
        bed_id: data && data.bedNo ? data.bedNo.id : null,
        patient_type: data && data.patientType ? data.patientType.id : null,
        emergency_admission: data && data.emergencyAdmission ? data.emergencyAdmission : 0,
        consultant_id: data && data.consultant ? data.consultant.id : null,
        tag: data && data.tags ? data.tags : null,
        summary: data && data.summary ? data.summary : null
    }
    let response = await casesServices.createCase(post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const caseUpdate = async (data, id) => {
    let post = {
        hospital_id: data && data.hospitalId ? [data.hospitalId] : null,
        //<-----------------patient detail----------------------------------------------
        patient_id: data && data.patientData ? data.patientData.patientId : null,
        patient_name: data && data.patientData ? data.patientData.patientName : null,
        patient_status: data && data.patientData ? data.patientData.patientStatus : null,
        //<-------------------------------------------------------------------------------
        case_title: data && data.caseTitle ? data.caseTitle : null,
        ward_id: data && data.ward ? data.ward.id : null,
        room_id: data && data.roomNo ? data.roomNo.id : null,
        bed_id: data && data.bedNo ? data.bedNo.id : null,
        patient_type: data && data.patientType ? data.patientType.id : null,
        emergency_admission: data && data.emergencyAdmission ? data.emergencyAdmission : 0,
        consultant_id: data && data.consultant ? data.consultant.id : null,
        tag: data && data.tags ? data.tags : null,
        summary: data && data.summary ? data.summary : null
    }
    let response = await casesServices.caseUpdate(id, post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const caseDetail = async (id) => {
    let response = await casesServices.caseDetail(id);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const caseDelete = async (id) => {
    let response = await casesServices.caseDelete(id);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const casesController = {
    casesList,
    createCase,
    caseDetail,
    caseUpdate,
    caseDelete
};

export default casesController;
