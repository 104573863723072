import React, { useEffect } from 'react'
import Validation from '../../../../../Helper/validations/vaildation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import labController from '../../../../../../apis/Controllers/LabController/lab.controller';
import { Toaster } from '../../../../../Helper/toaster/Toaster';
import { checkTypeParam, isEmpty, isEmptyArray } from '../../../../../Helper/General';
import store from '../../../../../../redux/store';
const AddReportFunction = () => {
    const breadcrumData = ['Dashboard', 'Patient Detail'];
    const [breadcrumItem, setbreadcrumItem] = useState('Add Report');
    const defaultValues = {
        testType: null,
        testName: null,
        image: []
    }
    const defaultErrors = {
        testType: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        testName: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        image: {
            rules: [],
            isValid: true,
            message: "",
        }
    };

    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);
    const [values2, setValues2] = useState({ range: null });
    const [isError2, setError2] = useState({
        range: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
    });
    const [loader, setLoader] = useState(false);
    const [testTyleList, setTestTypeList] = useState([]);
    const [testNameList, setTestNameList] = useState([]);
    const [testCategoryList, setTestCategoryList] = useState([])
    const location = useLocation();
    const navigate = useNavigate();



    useEffect(() => {
        getSubCategoryListing();
    }, [values && values.test])

    const handleChange = (field, value) => {
        let validation = new Validation(isError);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };

    const handleChange2 = (field, value) => {
        let validation = new Validation(isError2);
        let node = validation.validateField(field, value);
        setError2({ ...isError2, [field]: node });
        setValues2({ ...values2, [field]: value });
    };

    const handleChange1 = (item, value) => {
        const array = [...testCategoryList];
        item.test_value = value;
        setTestCategoryList(array);


        // updatedtest[index][field] = value;
        //  if (field == "testCategory") {
        //     if (updatedtest[index][field] == "" || null || undefined) {
        //     let err = true;
        //     values.test[index].error_test = err;
        //  } else {
        //     let err = false;
        //     values.test[index].error_test = err;
        //     }   
        //  } else {
        //     if (updatedtest[index][field] == "" || null || undefined) {
        //     let err = true;
        //     values.test[index].error_range = err;
        //  } else {
        //     let err = false;
        //     values.test[index].error_range = err;
        // }   
        // }

        // setValues({...values , test : updatedtest})
    };

    const addCategories = () => {
        const test = {
            testCategory: "",
            range: "",
            error_test: false,
            error_range: false
        }
        const arr = [...values.test, test];
        setValues({ ...values, test: arr })
    };

    const deleteCategories = (item) => {
        let arr = [...values.test];
        if (item) {
            let index = arr.indexOf(item);
            if (index > -1) {
                arr.splice(index, 1)
            };
            setValues({ ...values, test: arr })
        }
    };


    const getTestTypeListing = async () => {
        let response = await labController.testTypeListing()
        if (response && response.status) {
            let array = response.data;
            for (let i in array) {
                array[i]["label"] = array[i].title;
            }
            setTestTypeList(array)
        } else {
            setTestTypeList([])
        }
    };

    const getTestNameListing = async (e) => {
        let response = await labController.testNameListing(e)
        if (response && response.status) {
            let array = response.data;
            for (let i in array) {
                array[i]["label"] = array[i].title;
            }
            setTestNameList(array)
        } else {
            setTestNameList([])
        }
    };

    const getTestCategoryListing = async (e) => {
        let response = await labController.testCategoryListing(e)
        if (response && response.status) {
            let array = response.data;
            for (let i in array) {
                array[i]["label"] = array[i].title;
                array[i]["test_value"] = "";
                if (array[i].is_test_sub_category == 1) {
                    let subcat = array[i].is_test_sub_category_arr;
                    for (let i in subcat) {
                        subcat[i]["test_value"] = "";
                    }
                }
            }
            setTestCategoryList(array)
        } else {
            setTestCategoryList([])
        }
    };

    const getSubCategoryListing = async () => {
        let response = await labController.subCategoryListing(values && values.test)
        if (response && response.status) {
            let array = response.data;
            for (let i in array) {
                array[i]["label"] = array[i].title;
            }
            setTestCategoryList(array)
        } else {
            setTestCategoryList([])
        }
    };

    const submitReport = async () => {
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            if (!isEmptyArray(testCategoryList)) {
                setLoader(true);
                const filterData = testCategoryList.filter((item) => (item.test_value !== ""));
                if (filterData && filterData.length > 0) {
                    let value = {
                        ...values,
                        patient_id: location && location.state && location.state.patient_id,
                        case_id: location && location.state && location.state.case_id,
                    }
                    let response = await labController.patientLabTesting(value, testCategoryList);
                    if (response && response.status) {
                        new Toaster().success(response.message);
                        setLoader(false);
                        setValues({
                            testType: null,
                            testName: null,
                            image: []
                        })
                        navigate(`/patientDetails/${location && location.state && location.state.patient_id}`, { replace: true } )
                        // navigate(-1)

                    } else {
                        setLoader(false);
                    }
                } else {
                    setLoader(false);
                    new Toaster().error("Please fill atleast one Test Category");
                }
            } else {
                let validation = new Validation(isError2);
                let isValid = validation.isFormValid(values2);
                if (isValid && !isValid.haveError) {
                    let value = {
                        ...values,
                        patient_id: location && location.state && location.state.patient_id,
                        case_id: location && location.state && location.state.case_id,
                    }
                    let response = await labController.patientLabTesting(value, testCategoryList, values2);
                    if (response && response.status) {
                        new Toaster().success(response.message);
                        setLoader(false);
                        setValues({
                            testType: null,
                            testName: null,
                            image: []
                        })
                        navigate(`/patientDetails/${location && location.state && location.state.patient_id}`, { replace: true } )
                        // navigate('/patientDetails', { replace: true, state: { id: location && location.state && location.state.patient_id }})

                    } else {
                        setLoader(false);
                    }
                } else {
                    setError2({ ...isValid.errors });
                    // new Toaster().error("Please fill Test range");
                }
            }

        } else {
            setError({ ...isValid.errors });
        }
        setLoader(false);
    };

    const getPatientLabreprtdetails = async (id) => {
        setLoader(true)
        let response = await labController.patientLabTestDetails(id)
        if (response && response.status) {
            let details = response.data;
            const testDetails = JSON.parse(details.test_details);
            details.test_details = testDetails;


            setValues({
                ...values,
                testType: details && details.test_type_id ? { id: details.test_type_id, label: details.test_type_title } : null,
                testName: details && details.test_id ? { id: details.test_id, label: details.test_title, is_sub_category: details.is_sub_category, test_range: details.lab_test_range, test_units: details.lab_test_units } : null,
                image: details && details.image ? details.image : [],
            })
            if (checkTypeParam(details && details.test_details)) {
                setValues2({
                    range: details && details.test_details
                })
            } else {
                setTestCategoryList(testDetails)
            }
            getTestNameListing(details && details.test_type_id)
            // getTestCategoryListing(details && details.test_id)
            setLoader(false)
        } else {
            // setLabReportDetails(null)
            setLoader(false)
        }
        setLoader(false)
    };

    const editTestReport = async (e) => {
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            if (!isEmptyArray(testCategoryList)) {
                setLoader(true);
                const filterData = testCategoryList.filter((item) => (item.test_value !== ""));
                if (filterData && filterData.length > 0) {
                    let value = {
                        ...values,
                        patient_id: location && location.state && location.state.patient_id,
                        case_id: location && location.state && location.state.case_id,
                    }
                    let response = await labController.editpatientLabTest(e, value, testCategoryList);
                    if (response && response.status) {
                        new Toaster().success(response.message);
                        setLoader(false);
                        setValues({
                            testType: null,
                            testName: null,
                            image: []
                        })
                        // navigate('/patientDetails', { replace: true, state: { id: location && location.state && location.state.patient_id } })
                        navigate(-1)
                    } else {
                        setLoader(false);
                    }
                } else {
                    setLoader(false);
                    new Toaster().error("Please fill atleast one Test Category");
                }
            } else {
                let validation = new Validation(isError2);
                let isValid = validation.isFormValid(values2);
                if (isValid && !isValid.haveError) {
                    let value = {
                        ...values,
                        patient_id: location && location.state && location.state.patient_id,
                        case_id: location && location.state && location.state.case_id,
                    }
                    let response = await labController.editpatientLabTest(e, value, testCategoryList, values2);
                    if (response && response.status) {
                        new Toaster().success(response.message);
                        setLoader(false);
                        setValues({
                            testType: null,
                            testName: null,
                            image: []
                        })
                        // navigate('/patientDetails', { replace: true, state: { id: location && location.state && location.state.patient_id }})
                        navigate(-1)
                    } else {
                        setLoader(false);
                    }
                } else {
                    setError2({ ...isValid.errors });
                    // new Toaster().error("Please fill Test range");
                }
            }

        } else {
            setError({ ...isValid.errors });
        }
        setLoader(false);
    };

    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/dashboard-superadmin");
            }
        } else if (i === 1) {
            navigate("/patientDetails");
        } else return;
    };

    return {
        breadcrumData,
        breadcrumItem,
        setbreadcrumItem,
        values,
        setValues,
        loader,
        isError,
        navigate,
        handleChange,
        submitReport,
        addCategories,
        deleteCategories,
        handleChange1,
        getTestTypeListing,
        testTyleList,
        testNameList,
        testCategoryList,
        loader,
        location,
        getTestNameListing,
        getTestCategoryListing,
        setTestCategoryList,
        handleChange2,
        values2,
        setValues2,
        isError2,
        getPatientLabreprtdetails,
        editTestReport,
        handleBreadcrumDataNaviagtion
    }
}

export default AddReportFunction