import {
    faAngleDown,
    faArrowUp,
    faBed,
    faCaretDown,
    faCheck,
    faCircleCheck,
    faEllipsisVertical,
    faHouse,
    faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import React, { useEffect } from "react";
import { Col, Container, Dropdown, Image, Row } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { Circle, Edit_2, Eye_2, GirlImg, Hospital_Img, Icon_Four, Icon_One, Icon_Three, Icon_Two, ImgHos, Loc_Icon, Mail_Icon, Patient, SplashLogo } from "../../../../../assets/images";
import * as Constants from "../../../../Helper/constants/constants";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import store from "../../../../../redux/store";
import { setDrawer } from "../../../../../redux/actions/users";
import SuperAdminDashboardFunctions from "./function";
import { indexOf } from "lodash";
import { dateFormat, isEmptyObj, openEmail, renderImage, timeFormat } from "../../../../Helper/General";
import { useState } from "react";
import Loader from "../../../../Helper/loader";
import NoDataComponent from "../../../CommanComponents/NoDataComponent";
import hospitalController from "../../../../../apis/Controllers/HospitalController/hospital.controller";
import { useNavigate } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardSuperAdmin = () => {
    const [arrow, setArrow] = useState(false);
    let navigate = useNavigate();

    const {
        getHospitalListing,
        getSuperAdminDAshboardDetails,
        hospitalDetails,
        patientStatus,
        taskStatus,
        patientAdmittedCount,
        todayAdmittedPatient,
        loader,
        data,
        hospitalsListing,
        breadcrumData1,
        handleTaskGraph,
        timeData,
        values,
        breadcrumItem1
    } = SuperAdminDashboardFunctions();

    useEffect(() => {
        if (!loader && isEmptyObj(hospitalDetails)) {
            getHospitalListing();
        }

    }, []);

    const handleDrawer = async (e) => {
        await store.dispatch(setDrawer(false));
    };


    return (
        <div className="dashboard_superadmin">
            <WithoutImageHeader
                header={Constants.DASHBOARD}
                lefticon='menu'
                icon='search_bell'
            />
            <Container onClick={() => handleDrawer()}>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="dashboard_logo_area">
                            {!loader ? <>
                                <div className="logo_main_area">
                                    <div className="left_area">
                                        <div className="img_logo">
                                            <Image src={SplashLogo} alt="..." />
                                        </div>
                                    </div>

                                    <div className="right_area">
                                        {hospitalsListing && hospitalsListing.length > 0 ?
                                            <div className="dropdown_area">
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        {hospitalDetails && hospitalDetails.title ? hospitalDetails.title : "Oxford Hospital"}
                                                        <div className="icon down_icon ms-4">
                                                            <FontAwesomeIcon
                                                                icon={faAngleDown}
                                                            />
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {hospitalsListing && hospitalsListing.length > 0 ?
                                                            hospitalsListing.map((item, index) => (
                                                                <Dropdown.Item style={hospitalDetails && hospitalDetails.title === item.title ? { backgroundColor: '#D3D3D3' } : null} key={index} id={index} onClick={(e) => { e.preventDefault(); hospitalController.setUpHospital(item); getSuperAdminDAshboardDetails(item) }}>
                                                                    {item.title}
                                                                    {
                                                                        hospitalDetails && hospitalDetails.title === item.title ?
                                                                            <FontAwesomeIcon
                                                                                style={{ float: 'right' }}
                                                                                icon={faCheck}
                                                                            />
                                                                            :
                                                                            null
                                                                    }
                                                                </Dropdown.Item>)) : null}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            :
                                            <div className="btn_patient_area">
                                                <a href="" className="btn btn-primary" onClick={() => navigate('/hospital')}>Add Hospital</a>
                                            </div>}
                                    </div>
                                    {/* // <div className="right_area">
                                        //     <div className="dropdown_area">
                                        //         <a href="/" className="btn btn-primary">Existing Patient</a>
                                        //     </div>
                                        // </div> */}

                                </div>

                                {hospitalDetails ? <div className="information_area"
                                >
                                    <div className="left_info">
                                        {hospitalDetails && hospitalDetails.title ?
                                            <h6 style={{ cursor: 'pointer' }} onClick={() => navigate(`/hospital-detail/${hospitalDetails.id}`, { state: { breadcrumData1: breadcrumData1, fromNavigation: true } })}>
                                                {hospitalDetails && hospitalDetails.title ? hospitalDetails.title : '-'}</h6> : null}
                                        {hospitalDetails && hospitalDetails.email ? <div className="inner_info_area space_mb">
                                            <div className="left_area">
                                                <div className="img_icon">
                                                    <Image src={Mail_Icon} alt="..." />
                                                </div>
                                            </div>
                                            <div className="right_area" onClick={() => hospitalDetails.email ? openEmail(hospitalDetails.email) : null}>
                                                <p>{hospitalDetails.email}</p>
                                            </div>
                                        </div> : null}

                                        {hospitalDetails.address ? <div className="inner_info_area">
                                            <div className="left_area">
                                                <div className="img_icon">
                                                    <Image src={Loc_Icon} alt="..." />
                                                </div>
                                            </div>
                                            <div className="right_area" onClick={() => hospitalDetails.map_link ? window.open(hospitalDetails.map_link) : null}>
                                                <p>{hospitalDetails.address}, {hospitalDetails.city_name}, {hospitalDetails.state_name}, {hospitalDetails.zipcode}</p>
                                            </div>
                                        </div> : null}

                                    </div>
                                    <div className="right_info">
                                        <div className="img_area">
                                            <Image src={hospitalDetails.image ? renderImage(hospitalDetails.image) : Hospital_Img} alt="..." />
                                        </div>
                                    </div>
                                </div> : null}

                                <div className="text_btn_area">
                                    <div className="left_text_area">
                                        <p>Needs Attention</p>
                                    </div>
                                    <div className="right_btn_area"
                                        onClick={() => navigate('/existing-patient')}>
                                        <a className="btn btn-primary">Discharged Patients</a>
                                    </div>
                                </div>

                                {patientStatus ? <div className="box_contain">
                                    <Row>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <div className="box_area">
                                                <div className="top_area">
                                                    <div className="top_left">
                                                        <a>
                                                            <div className="user_area">
                                                                <div className="img_area">
                                                                    <img src={Icon_One} alt="..." />
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    {/* <div className="top_right">
                                                        <div className="arrow_area">
                                                            <FontAwesomeIcon icon={faArrowUp} className="icon" />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="middle">
                                                    <h4>{patientStatus && patientStatus.active_patients ? patientStatus.active_patients : 0}</h4>
                                                </div>
                                                <div className="bottom_area">
                                                    <p>Total Active Patients</p>
                                                </div>
                                                <div className="circle_area">
                                                    <Image src={Circle} alt="..." />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <div className="box_area">
                                                <div className="top_area">
                                                    <div className="top_left">
                                                        <a>
                                                            <div className="user_area">
                                                                <div className="img_area">
                                                                    <img src={Icon_Two} alt="..." />
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    {/* <div className="top_right">
                                                        <div className="arrow_area">
                                                            <FontAwesomeIcon icon={faArrowUp} className="icon" />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="middle">
                                                    <h4>{patientStatus && patientStatus.critical_patients ? patientStatus.critical_patients : 0}</h4>
                                                </div>
                                                <div className="bottom_area">
                                                    <p>Critical Patients</p>
                                                </div>
                                                <div className="circle_area">
                                                    <Image src={Circle} alt="..." />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <div className="box_area">
                                                <div className="top_area">
                                                    <div className="top_left">
                                                        <a>
                                                            <div className="user_area">
                                                                <div className="img_area">
                                                                    <img src={Icon_Three} alt="..." />
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    {/* <div className="top_right">
                                                        <div className="arrow_area">
                                                            <FontAwesomeIcon icon={faArrowUp} className="icon" />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="middle">
                                                    <h4>{patientStatus && patientStatus.admitted_patients ? patientStatus.admitted_patients : 0}</h4>
                                                </div>
                                                <div className="bottom_area">
                                                    <p>Patients Admitted</p>
                                                </div>
                                                <div className="circle_area">
                                                    <Image src={Circle} alt="..." />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <div className="box_area">
                                                <div className="top_area">
                                                    <div className="top_left">
                                                        <a>
                                                            <div className="user_area">
                                                                <div className="img_area">
                                                                    <img src={Icon_Four} alt="..." />
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    {/* <div className="top_right">
                                                        <div className="arrow_area">
                                                            <FontAwesomeIcon icon={faArrowUp} className="icon" />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="middle">
                                                    <h4>{patientStatus && patientStatus.discharged_patients ? patientStatus.discharged_patients : 0}</h4>
                                                </div>
                                                <div className="bottom_area">
                                                    <p>Patients Discharged </p>
                                                </div>
                                                <div className="circle_area">
                                                    <Image src={Circle} alt="..." />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div> : null}
                                {taskStatus ? <div className="average_main_area">
                                    <div className="left_average_area">
                                        <h5>Task Overview</h5>
                                        <div className="text_area">
                                            <p>{values && values.lebal ? values.lebal : 'Today'}‘s Tasks - {taskStatus && taskStatus.total_tasks ? taskStatus.total_tasks : 0}</p>
                                        </div>
                                        <div className="content_text space_text">
                                            <div className="left_box">
                                                <div className="box_contain"></div>
                                            </div>
                                            <div className="right_text">
                                                <p>Completed Task: {taskStatus && taskStatus.completed_tasks ? taskStatus.completed_tasks : 0}</p>
                                            </div>
                                        </div>
                                        <div className="content_text">
                                            <div className="left_box">
                                                <div className="box_contain task"></div>
                                            </div>
                                            <div className="right_text">
                                                <p>Pending Task: {taskStatus && taskStatus.pending_tasks ? taskStatus.pending_tasks : 0} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right_average_area">
                                        <div className="dropdown_area">
                                            <Dropdown>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    {values && values.lebal ? values.lebal : "Today"}
                                                    <div className="icon down_icon ms-4">
                                                        <FontAwesomeIcon icon={faCaretDown} />
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu >
                                                    {timeData && timeData.map((item, index) => {
                                                        return (
                                                            <Dropdown.Item key={index} onClick={() => handleTaskGraph(item)}>
                                                                {item.lebal}
                                                            </Dropdown.Item>
                                                        )
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="circle_percentage">
                                            <Doughnut data={data} className="circle_part" />
                                        </div>
                                    </div>
                                </div> : null}
                                <div className="patient_admit_area">
                                    <div className="title">
                                        <h4>Patient's Admitted Today: {patientAdmittedCount ? patientAdmittedCount : 0}</h4>
                                    </div>
                                    <div className="date_area">
                                        <div className="left_date">
                                            <p>{dateFormat(new Date())}</p>
                                        </div>
                                        {todayAdmittedPatient && todayAdmittedPatient.length > 0 ? <div className="right_date">
                                            <a href="/today-patient-List" className="btn btn-primary">View All</a>
                                        </div> : null}
                                    </div>
                                    {todayAdmittedPatient && todayAdmittedPatient.length > 0 ? todayAdmittedPatient.map((item, index) => (
                                        <div key={index} className="patient_main_area">
                                            <div className="left_main">
                                                <div className="text">
                                                    <p>{timeFormat(item.created)} </p>
                                                </div>
                                                <div className="check_icon">
                                                    <FontAwesomeIcon icon={faCircleCheck} />
                                                </div>
                                            </div>
                                            <div className="right_area">
                                                <a>
                                                    <div className="patient_info_area">
                                                        <div className="left_info_area">
                                                            <div className="img_girl">
                                                                <Image src={item.image ? renderImage(item.image) : Patient} alt='...' onClick={() => navigate(`/patientDetails/${item.id}`, { state: { pageParams: ['Dashboard'], name: item.name } })} />
                                                            </div>
                                                        </div>
                                                        <div className="right_info_area">
                                                            <div className="patient_name_area">
                                                                <div className="left_area">
                                                                    <h6
                                                                        onClick={() => navigate(`/patientDetails/${item.id}`, { state: { pageParams: ['Dashboard'], name: item.name } })}
                                                                    >{item.patient_name}</h6>
                                                                    <div className="text">
                                                                        <p style={{ textTransform: 'uppercase' }}>{item.ward_name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="right_area">
                                                                    <div className="disease_area">
                                                                        <div className="left_disease">
                                                                            <p>{item.case_title}</p>
                                                                            <div className="list_area">
                                                                                <ul>
                                                                                    <li>
                                                                                        <div className="text_icon">
                                                                                            <FontAwesomeIcon icon={faBed} className="icon" />Bed #{item.bed_number}
                                                                                        </div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div className="text_icon">
                                                                                            <FontAwesomeIcon icon={faHouse} className="icon" />Room #{item.room_number}
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="right_disease">
                                                                            {/* <div className="icon_dots">
                                                                        <FontAwesomeIcon icon={faEllipsisVertical} />
                                                                    </div> */}
                                                                            <Dropdown>
                                                                                <Dropdown.Toggle variant="none" id="dropdown-basic">
                                                                                    <FontAwesomeIcon icon={faEllipsisVertical} className="ellipsis_icon" />
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item onClick={() => navigate(`/patientDetails`, { state: { id: item.id, pageParams: ['Dashboard'], name: item.name } })}>
                                                                                        View
                                                                                        <Image src={Eye_2} alt="..." className="image_area" />
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item onClick={() => navigate('/edit-patient', { state: { id: item.id ? item.id : '' } })}>
                                                                                        Edit
                                                                                        <Image src={Edit_2} alt="..." className="image_area" />
                                                                                    </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    )) :
                                        <>
                                            {!loader ?
                                                <NoDataComponent
                                                    message="No Patient Found"
                                                /> : null}
                                        </>
                                    }
                                </div>
                            </> : <Loader
                                loader={loader}
                            />}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default DashboardSuperAdmin;
