import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function uploadFile(params, progressCallback) {
    return mainWrapper.upload(WebConstant.host + "/upload-media/file",
        params,
        progressCallback);
};

export const fileServices = {
    uploadFile,
};
