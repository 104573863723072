import {
    faCircle,
    faEllipsisVertical,
    faSearch
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import {
    Button,
    Col,
    Container,
    Dropdown,
    Form,
    Image,
    Row,
    Table,
} from "react-bootstrap";
import { Build_Img, Delete_2, Edit_2, Filter, Filters, Hospital_Img } from "../../../../../assets/images";
import { setDrawer } from "../../../../../redux/actions/users";
import store from "../../../../../redux/store";
import { dateFormat, renderImage, timeFormat2 } from "../../../../Helper/General";
import * as Constants from "../../../../Helper/constants/constants";
import Loader from "../../../../Helper/loader";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import NoDataComponent from "../../../CommanComponents/NoDataComponent";
import RoomListFunction from "./function";
import PaginationComponent from "../../../CommanComponents/Pagination";
import DeleteModal from "../../../Modals/DeleteModal";
import BedListFunction from "./function";
import { debounce } from "lodash";
import { useCallback } from "react";
import BedListFilterModal from "../../../Modals/BedListFilterModal";

const BedList = () => {

    const {
        checkPermissions,
        getModulePermissions,
        getBedListing,
        breadcrumData,
        loader,
        breadcrumItem,
        loaction,
        bedList,
        navigate,
        handleDelete,
        handleDeleteBed,
        deletePopup,
        deleteItem,
        setDeletePopup,
        totalCount,
        page,
        totalPages,
        handleBreadcrumDataNaviagtion,
        handlePage,
        handleBackClick,
        handleNextClick,
        handleFilters,
        sorting,
        handlePages,
        updateBedStatuss,
        keyword,
        setSearch,
        User,
        showFilterModal,
        setShowFilterModal,
        setReduxValue,
        applyFilters,
        handleClearAllFilters,
        filtersAppliedSign,
        dashboard_hospital,
        itemsPerPage,
        handleItemPerPage,
    } = BedListFunction()
    let storedFilters = store.getState().BedListFilters.filters;

    useEffect(() => {
        let userdata = localStorage.getItem('SET_USER_DATA');
        let User = JSON.parse(userdata);
        if (User && parseInt(User.user_type) === 2) {
            getModulePermissions();
        }
        else;
    }, []);


    const handleDrawer = async (e) => {
        await store.dispatch(setDrawer(false));
    };


    const search = useCallback(debounce(getBedListing, 1000), []);
    return (
        <div className="role_list_screen ward_list_area bed_list_spacing">
            <WithoutImageHeader
                header={Constants.BEDLIST}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                lefticon={"menu"}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
                setReduxvalue={(e) => setReduxValue(e)}
            />
            <Container onClick={() => handleDrawer()}>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="role_list_details">
                            <div className="title_btn_area">
                                <div className="left_area">
                                    <p>
                                        Total Beds: {totalCount ? totalCount : 0}
                                    </p>
                                </div>
                                {
                                    User && parseInt(User.user_type) === 1 ?
                                        <div className="right_area">
                                            <a
                                                className="btn btn-primary"
                                                href="/Add-Bed"
                                            >
                                                Add Bed
                                            </a>
                                        </div>
                                        :
                                        checkPermissions
                                            && checkPermissions.permissions
                                            && checkPermissions.permissions.length > 1
                                            && parseInt(checkPermissions.permissions[1].value) === 1 ?
                                            <div className="right_area">
                                                <a
                                                    className="btn btn-primary"
                                                    href="/Add-Bed"
                                                >
                                                    Add Bed
                                                </a>
                                            </div>
                                            :
                                            null
                                }
                            </div>
                            <div className="search_section">

                                <div className="search_area">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Control
                                                type="text"
                                                placeholder="Search by ward name, room no.  & bed no. here"
                                                value={keyword}
                                                onChange={(e) =>
                                                (
                                                    search(e.target.value, 1, itemsPerPage, storedFilters),
                                                    setSearch(e.target.value))
                                                }
                                            />
                                            <a>
                                                <div className="icon">
                                                    <FontAwesomeIcon
                                                        icon={faSearch}
                                                    />
                                                </div>
                                            </a>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div className="pagination_list">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Select
                                                value={itemsPerPage}
                                                onChange={(e) => handleItemPerPage(e)}
                                            >
                                                <option value={5}>5</option>
                                                <option value={10}>10</option>
                                                <option value={15}>15</option>
                                                <option value={20}>20</option>
                                                <option value={25}>25</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Form>
                                </div>
                                {/* <Dropdown>
                                    <Dropdown.Toggle variant="none" id="dropdown-basic"  >
                                        <div className={sorting ? "sort_button1" : "sort_button"}>
                                            <Button
                                                className="btn-primary"
                                                type="submit"
                                                onClick={()=>setShowFilterModal(true)}
                                            >
                                                {sorting ? sorting : 'Sort'}
                                                <Image
                                                    src={Filter}
                                                    alt="..."
                                                    className="image_area"
                                                />
                                            </Button>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleFilters('ASC')}>
                                            Ascending
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleFilters('DESC')}>
                                            Descending
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> */}

                                <div className="filter_button">
                                    <Button
                                        className={filtersAppliedSign ? "btn-primary" : 'btn-border'}
                                        onClick={() => setShowFilterModal(true)}
                                    >
                                        <Image
                                            src={Filters}
                                            alt="..."
                                            className="image_area"
                                        />
                                        Filters
                                    </Button>
                                    {
                                        filtersAppliedSign ?
                                            <FontAwesomeIcon
                                                icon={faCircle}
                                                className="filter_icon"
                                            />
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            {loader ? (
                                <Loader loader={loader} />
                            ) : (
                                <>
                                    {!loader && bedList && bedList.length > 0 ? (
                                        <div className="list_detail">
                                            <div className="list_data">
                                                <Table responsive>
                                                    <tr>
                                                        <th className="role_text hospital">
                                                            Hospital Name
                                                        </th>
                                                        <th className="role_text">
                                                            Ward Name
                                                        </th>
                                                        <th>
                                                            Room Number
                                                        </th>
                                                        <th>Bed Number</th>
                                                        <th>Status</th>
                                                        <th>Created On</th>
                                                        {
                                                            User && parseInt(User.user_type) === 1 ?
                                                                <th>Action</th>
                                                                :
                                                                (checkPermissions
                                                                    && checkPermissions.permissions
                                                                    && checkPermissions.permissions.length > 2
                                                                    && parseInt(checkPermissions.permissions[2].value) === 1) ||
                                                                    (checkPermissions
                                                                        && checkPermissions.permissions
                                                                        && checkPermissions.permissions.length > 3
                                                                        && parseInt(checkPermissions.permissions[3].value) === 1)
                                                                    ?
                                                                    <th>Action</th>
                                                                    :
                                                                    null
                                                        }
                                                    </tr>
                                                    {bedList &&
                                                        bedList.length > 0
                                                        ? bedList.map(
                                                            (item, index) => {

                                                                return (
                                                                    <tr key={index}>
                                                                        <td className="test">
                                                                            <div className="hospital_head">
                                                                                <div className="test_bed" onClick={() => navigate(`/hospital-detail/${item.hospital_id}`, { state: { fromNavigation: true } })}>
                                                                                    <Image
                                                                                        src={
                                                                                            item &&
                                                                                                item.hospital_image
                                                                                                ? renderImage(
                                                                                                    item.hospital_image
                                                                                                )
                                                                                                : Hospital_Img
                                                                                        }
                                                                                        alt="..."
                                                                                    />
                                                                                </div>
                                                                                <p className="h_name"  style={{ cursor: 'pointer' }} onClick={() => navigate(`/hospital-detail/${item.hospital_id}`, { state: { fromNavigation: true } })}>
                                                                                    {item.hospital_name}
                                                                                </p>

                                                                            </div>

                                                                        </td>
                                                                        <td>
                                                                            <div className="label">
                                                                                <p style={{ textTransform: 'uppercase' }}>
                                                                                    {item.ward_name}
                                                                                </p>
                                                                            </div>

                                                                        </td>
                                                                        <td>
                                                                            <div className="label">
                                                                                <p>
                                                                                    {item.room_number}
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="label">
                                                                                <p>
                                                                                    {
                                                                                        item && item.bed_number
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="switch_area">
                                                                                <Form>
                                                                                    <Form.Check
                                                                                        type="switch"
                                                                                        id="custom-switch"
                                                                                        label={parseInt(item.status) === 1 ? "Active" : "Deactivate"}
                                                                                        checked={parseInt(item.status) === 1 ? true : false}
                                                                                        onClick={() => User && parseInt(User.user_type) === 1 ? updateBedStatuss(item) : ''}
                                                                                    />
                                                                                </Form>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="profile_info no_space">
                                                                                <div className="right_area no_space">
                                                                                    <p>
                                                                                        {dateFormat(item.created)}{' '}{timeFormat2(item.created)}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                User && parseInt(User.user_type) === 1 ?
                                                                                    <div className="icon">
                                                                                        <Dropdown>
                                                                                            <Dropdown.Toggle variant="none" id="dropdown-basic"  >
                                                                                                <FontAwesomeIcon icon={faEllipsisVertical} className="ellipsis_icon" />
                                                                                            </Dropdown.Toggle>
                                                                                            <Dropdown.Menu>
                                                                                                <Dropdown.Item
                                                                                                    onClick={() => navigate("/Add-Bed", { state: { name: "EditBed", id: item.id, } })}
                                                                                                >
                                                                                                    Edit
                                                                                                    <Image src={Edit_2} alt="..." className="image_area" />
                                                                                                </Dropdown.Item>

                                                                                                <Dropdown.Item
                                                                                                    onClick={() => handleDelete(item)}
                                                                                                >
                                                                                                    Delete
                                                                                                    <Image src={Delete_2} alt="..." className="image_area delete_icon " />
                                                                                                </Dropdown.Item>
                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown>
                                                                                    </div>
                                                                                    :
                                                                                    (checkPermissions
                                                                                        && checkPermissions.permissions
                                                                                        && checkPermissions.permissions.length > 2
                                                                                        && parseInt(checkPermissions.permissions[2].value) === 1) ||
                                                                                        (checkPermissions
                                                                                            && checkPermissions.permissions
                                                                                            && checkPermissions.permissions.length > 3
                                                                                            && parseInt(checkPermissions.permissions[3].value) === 1)
                                                                                        ?
                                                                                        <div className="icon">
                                                                                            <Dropdown>
                                                                                                <Dropdown.Toggle variant="none" id="dropdown-basic"  >
                                                                                                    <FontAwesomeIcon icon={faEllipsisVertical} className="ellipsis_icon" />
                                                                                                </Dropdown.Toggle>
                                                                                                <Dropdown.Menu>
                                                                                                    {
                                                                                                        checkPermissions
                                                                                                            && checkPermissions.permissions
                                                                                                            && checkPermissions.permissions.length > 2
                                                                                                            && parseInt(checkPermissions.permissions[2].value) === 1 ?
                                                                                                            <Dropdown.Item
                                                                                                                onClick={() => navigate("/Add-Bed", { state: { name: "EditBed", id: item.id, } })}
                                                                                                            >
                                                                                                                Edit
                                                                                                                <Image src={Edit_2} alt="..." className="image_area" />
                                                                                                            </Dropdown.Item>
                                                                                                            :
                                                                                                            null
                                                                                                    }
                                                                                                    {
                                                                                                        checkPermissions
                                                                                                            && checkPermissions.permissions
                                                                                                            && checkPermissions.permissions.length > 2
                                                                                                            && parseInt(checkPermissions.permissions[3].value) === 1 ?
                                                                                                            <Dropdown.Item
                                                                                                                onClick={() => handleDelete(item)}
                                                                                                            >
                                                                                                                Delete
                                                                                                                <Image src={Delete_2} alt="..." className="image_area delete_icon " />
                                                                                                            </Dropdown.Item>
                                                                                                            :
                                                                                                            null
                                                                                                    }

                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        )
                                                        : null}
                                                </Table>
                                            </div>
                                        </div>
                                    ) : (
                                        <NoDataComponent
                                            message="No Bed Found"
                                        />
                                    )}
                                </>
                            )}
                            {!loader &&
                                bedList &&
                                bedList.length > 0 ? (
                                <PaginationComponent
                                    page={page}
                                    setPage={handlePage}
                                    totalPages={totalPages}
                                    handleBackClick={handleBackClick}
                                    handleNextClick={handleNextClick}
                                />
                            ) : null}
                        </div>
                    </Col>
                </Row>
            </Container>
            {
                deletePopup ?
                    <DeleteModal
                        show={deletePopup}
                        handleSubmit={() => handleDeleteBed(deleteItem)}
                        close={() => setDeletePopup(false)}
                        message={'Do you want to delete this bed?'}
                        loader={loader}
                    />
                    :
                    null
            }

            {showFilterModal ?
                <BedListFilterModal
                    show={showFilterModal}
                    close={() => setShowFilterModal(false)}
                    applyFilters={applyFilters}
                    handleClearAllFilters={handleClearAllFilters}
                />
                : null
            }
        </div>
    );
};

export default BedList;
