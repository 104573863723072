import { useEffect, useState } from "react";
import patientController from "../../../../../apis/Controllers/PatientController/patient.controller";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import Validation from "../../../../Helper/validations/vaildation";
import { useLocation, useNavigate } from "react-router-dom";
import wardController from "../../../../../apis/Controllers/WardController/ward.controller";
import bedsController from "../../../../../apis/Controllers/BedsControoler/beds.controller";
import { actionsController } from "../../../../../apis/Controllers/CommonController/action.controller";
import roomsController from "../../../../../apis/Controllers/RoomsController/rooms.controller";
import departmentController from "../../../../../apis/Controllers/DepartmentController/department.controller";
import adminController from "../../../../../apis/Controllers/AdminControler/admin.controller";
import store from "../../../../../redux/store";
import casesController from "../../../../../apis/Controllers/PatientCasesController/patientCases.controller";



const AddCaseFunctions = () => {
    const breadcrumData = ['Dashboard'];
    const [breadcrumItem, setBreadcrumItem] = useState('Add Case');

    const defaultValues = {
        hospitalId: null,
        caseTitle: null,
        tags: null,
        summary: null,
        ward: null,
        roomNo: null,
        bedNo: null,
        patientType: null,
        emergencyAdmission: 0,
        // department: null,
        consultant: null,
        patientData: null
    };

    const defaultErrors = {
        hospitalId: {
            rules: [""],
            isValid: true,
            message: "",
        },
        caseTitle: {
            rules: [""],
            isValid: true,
            message: "",
        },
        tags: {
            rules: [],
            isValid: true,
            message: "",
        },
        summary: {
            rules: [""],
            isValid: true,
            message: "",
        },
        ward: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        roomNo: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        bedNo: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        patientType: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        // department: {
        //     rules: ["required"],
        //     isValid: true,
        //     message: "",
        // },
        consultant: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        emergencyAdmission: {
            rules: [""],
            isValid: true,
            message: "",
        },
    };

    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);
    const [loader, setLoader] = useState(false);
    const [wardsList, setWardsList] = useState([]);
    const [roomsList, setRoomsList] = useState([]);
    const [bedsList, setbedsList] = useState([]);
    const [patientTypesList, setPatientTypesList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [consultantsList, setConsultantsList] = useState([]);
    const loaction = useLocation();
    const navigate = useNavigate();



    const handleChange = (field, value) => {
        let Validations = new Validation(isError);
        let node = Validations.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
        if (field === 'ward') {
            setValues({
                ...values,
                ward: value,
                roomNo: null,
                bedNo: null
            });
        }
        else if (field === 'roomNo') {
            setValues({
                ...values,
                roomNo: value,
                bedNo: null
            });
        }
        // else if (field === 'department') {
        //     setValues({
        //         ...values,
        //         department: value,
        //         consultant: null
        //     });
        // }
    };


    const handleEmergencyStatus = (e) => {
        let status = { ...values.emergencyAdmission }
        if (e.target.checked === true) {
            status = 1
        }
        else {
            status = 0
        }
        handleChange('emergencyAdmission', status)
    };

    const handleSubmit = async () => {
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            if (loaction && loaction.state && loaction.state.name === 'addCase') {
                let patientDetail = {
                    patientId: loaction && loaction.state && loaction.state.patientDetails && loaction.state.patientDetails.id ? loaction.state.patientDetails.id : null,
                    patientName: loaction && loaction.state && loaction.state.patientDetails && loaction.state.patientDetails.patient_name ? loaction.state.patientDetails.patient_name : null,
                    patientStatus: loaction && loaction.state && loaction.state.patientDetails && loaction.state.patientDetails.patient_status ? loaction.state.patientDetails.patient_status : null,

                }
                let value = {
                    ...values,
                    hospitalId: loaction && loaction.state && loaction.state.hospitalCaseId ? loaction && loaction.state && loaction.state.hospitalCaseId : null,
                    patientData: patientDetail
                }
                setLoader(true);
                let response = await casesController.createCase(value);
                if (response && response.status) {
                    new Toaster().success(response.message);
                    // navigate(`/patientDetails/${response.data && response.data.case_patient_id ? response.data.case_patient_id : ''}`, { replace: true })
                    navigate(-1)
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            }
            else {
                setLoader(true);
                let id = values && values.patientData && values.patientData.caseId ? values.patientData.caseId : ''
                let response = await casesController.caseUpdate(values, id);
                if (response && response.status) {
                    new Toaster().success(response.message);
                    // navigate('/patientDetails', { replace: true, state: { id: response.data && response.data.case_patient_id ? response.data.case_patient_id : '' } });
                    // navigate(`/patientDetails/${response.data && response.data.case_patient_id ? response.data.case_patient_id : ''}`, { replace: true })
                    navigate(-1)
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            }
        } else {
            setError({ ...isValid.errors });
        }
    };


    const getCaseDetail = async (caseId) => {
        let response = await casesController.caseDetail(caseId);
        if (response && response.status) {
            let data = response.data
            let patientDetail = {
                "patientId": data && data.patient_id ? data.patient_id : null,
                "patientName": data && data.patient_name ? data.patient_name : null,
                "patientStatus": data && data.patient_status ? data.patient_status : null,
                "caseId": data.id ? data.id : null,
            }
            setValues({
                ...values,
                patientData: patientDetail,
                hospitalId: data && data.hospital_id ? data.hospital_id : null,
                caseTitle: data && data.case_title ? data.case_title : null,
                tags: data && data.tag ? data.tag : null,
                summary: data && data.summary ? data.summary : null,
                ward: data && data.ward_title && data.ward_id ? { label: data.ward_title, id: data.ward_id } : null,
                roomNo: data && data.room_number && data.room_id ? { label: data.room_number, id: data.room_id } : null,
                bedNo: data && data.bed_number && data.bed_id ? { label: data.bed_number, id: data.bed_id } : null,
                patientType: data && data.patient_type_name && data.patient_type ? { label: data.patient_type_name, id: data.patient_type } : null,
                emergencyAdmission: data && data.emergency_admission ? data.emergency_admission : null,
                // department: data && data.department_id ? { label: data.department_name, id: data.department_id } : null,
                consultant: data && data.consultant_name ? { label: data.consultant_name, id: data.consultant_id } : null,
            });
            getWardsListing(data && data.hospital_id ? [data.hospital_id] : []);
            getAdminsListing(data && data.hospital_id ? data.hospital_id : null);
            getRoomsListing(data && data.ward_id, data && data.hospital_id ? [data.hospital_id] : '');
            getBedsListing(data && data.room_id);
        } else return;
    };



    const getWardsListing = async (idArray) => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        let post = {
            hospital_id: idArray ? idArray : [item.id]
        }
        let response = await wardController.wardListing(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setWardsList(data);
        }
        else {
            setWardsList([]);
        }
    };


    const getRoomsListing = async (id, hospitalId) => {
        let post = {
            ward_id: id ? id : '',
            hospital_id: hospitalId ? [hospitalId] : []
        }
        let response = await roomsController.roomListing(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].room_number
            }
            setRoomsList(data);
        }
        else {
            setRoomsList([]);
        }
    };


    const getBedsListing = async (id) => {
        let post = {
            room_id: id ? id : ''
        }
        let response = await bedsController.bedsListing(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].bed_number
            }
            setbedsList(data);
        }
        else {
            setbedsList([]);
        }
    };


    const getPatientTypesListing = async () => {
        let response = await actionsController.patientTypes();
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setPatientTypesList(data);
        }
        else {
            setPatientTypesList([]);
        }
    };

    const getDepartmentsListing = async () => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        let post = {
            hospital_id: item ? [item.id] : [],
        };
        let response = await departmentController.departmentListing(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setDepartmentList(response.data);
        }
        else {
            setDepartmentList([]);
        }
    };


    const getAdminsListing = async (id) => {
        let post = {
            hospital_id: id ? [id] : []
        }
        let response = await adminController.consultantListing(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                let department = data[i] && data[i].department_title ? `(${data[i].department_title})` : null;
                data[i]['label'] = `${data[i].name} ` + department;
            }
            setConsultantsList(response.data);
        }
        else {
            setConsultantsList([]);
        }
    };


    const handleRoomNoDropdown = () => {
        if (values && (values.ward === null)) {
            new Toaster().error('Please select ward');
        }
        else return;
    };


    const handleBedNoDropdown = () => {
        if (values && (values.roomNo === null)) {
            new Toaster().error('Please select Room Number');
        }
        else return;
    };


    const handleConsultantDropdown = () => {
        if (values && (values.department === null)) {
            new Toaster().error('Please select Department');
        }
        else return;
    };


    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate('/dashboard');
            }
            else {
                navigate('/dashboard-superadmin');
            }
        }
        else if (i === 1) {
            navigate('/manage-patient-list');
        }
        else return;
    };


    return {
        breadcrumData,
        breadcrumItem,
        setBreadcrumItem,
        values,
        setValues,
        isError,
        loaction,
        loader,
        handleChange,
        navigate,
        getWardsListing,
        getRoomsListing,
        getBedsListing,
        getAdminsListing,
        getDepartmentsListing,
        getPatientTypesListing,
        wardsList,
        roomsList,
        bedsList,
        patientTypesList,
        departmentList,
        consultantsList,
        handleEmergencyStatus,
        handleRoomNoDropdown,
        handleBedNoDropdown,
        handleConsultantDropdown,
        handleBreadcrumDataNaviagtion,
        handleSubmit,
        getCaseDetail,
    }
};

export default AddCaseFunctions;