import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function bedsListing (params) {
    return mainWrapper.get(WebConstant.host + `/bed`, params);
};

function superAdminBedsListing(params) {
    return mainWrapper.get(WebConstant.host + `/bed_super_admin`, params);
}

function bedsCreate (params) {
    return mainWrapper.post(WebConstant.host + `/bed`, params);
};


function bedsDetail (id) {
    return mainWrapper.get(WebConstant.host + `/bed/${id}`);
};


function bedsUpdate (id,params) {
    return mainWrapper.put(WebConstant.host + `/bed/${id}`,params);
};


function bedsDelete (id) {
    return mainWrapper._delete(WebConstant.host + `/bed/${id}`);
};


function updateBedStatus(id, params) {
     return mainWrapper.put(WebConstant.host + `/update_bed_status/${id}`,params);
};

export const bedsServices = {
    bedsListing,
    bedsCreate,
    bedsDetail,
    bedsUpdate,
    bedsDelete,
    superAdminBedsListing,
    updateBedStatus
};
