import { Toaster } from "../../../components/Helper/toaster/Toaster";
import { departmentServices } from "../../Services/DepartmentServices/department.services";

const departmentList = async (params) => {
    let response = await departmentServices.departmentList(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const departmentListing = async (params) => {
    let response = await departmentServices.departmentListing(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const departmentCreate = async (data) => {
    let post = {
        title: data.department_title ? data.department_title : null,
        hospital: data.hospital_arr ? [data.hospital_arr.id] : [],
    };
    let response = await departmentServices.departmentCreate(post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const departmentDetail = async (id) => {
    let response = await departmentServices.departmentDetail(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const departmentUpdate = async (id, data) => {
    let post = {
        title: data.department_title ? data.department_title : null,
        hospital: data.hospital_arr ? [data.hospital_arr.id] : [],
    };
    let response = await departmentServices.departmentUpdate(id, post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const updateDepartStatus = async (id, params) => {
    let response = await departmentServices.updateDepartStatus(id, params);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const departmentDelete = async (id) => {
    let response = await departmentServices.departmentDelete(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const departmentController = {
    departmentList,
    departmentListing,
    departmentCreate,
    departmentDetail,
    departmentUpdate,
    departmentDelete,
    updateDepartStatus,
};

export default departmentController;
