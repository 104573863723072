//------------ REACT IMPORT --------------//
import React, { useRef, useState } from "react";
//--------- BOOTSTRAP ITEMS IMPORT ----------//
import { Card, Form, Image } from "react-bootstrap";
//------------ IMAGES IMPORT ---------------//
import { CalendarBlack, Delete, Eye, Eye_2, PdfImg, editing } from "../../../../assets/images";
import PaginationComponent from "../../CommanComponents/Pagination";
import { faPaperclip, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Modals/DeleteModal";
import NoDataComponent from "../../CommanComponents/NoDataComponent";
import { dateFormat3 } from "../../../Helper/General";

const LabUpdate = (props) => {
    let navigate = useNavigate();
    const [deleteItem, setdeleteItem] = useState(null);
    const {
        LabListing,
        loader,
        totalPages,
        page,
        handlePage,
        handleLogsNextClick,
        handleLogsBackClick,
        patientData,
        deleteLabreport,
        setDeleteLabreport,
        deleteLabReportListing,
        checkLabPermissions,
        labDateParam,
        setLabDateParam,
        totalLabListLength
    } = props;

    let userdata = localStorage.getItem('SET_USER_DATA');
    const User = JSON.parse(userdata);

    const inputCal = useRef(null);

    const handleClick = () => {
        if (inputCal.current) {          //---------------date picker----
            inputCal.current.showPicker();
        }
    };


    return (
        <>
            <div className="update_lab_tab">
                <div className="top_content_area">
                    <div className="left_area">
                        <p>Total Reports : {totalLabListLength ? totalLabListLength : 0}</p>
                    </div>
                    <div className="right_area">
                        <div className="img_text_area">
                            <p>Today</p>
                            <div className="img_area">
                                <Image
                                    src={CalendarBlack} alt="..."
                                    onClick={() => handleClick()}
                                    style={{ cursor: 'pointer' }}
                                />
                                <Form.Control
                                    style={{
                                        position: 'absolute',
                                        zIndex: 1,
                                        height: '1px',
                                        width: '295px',
                                        right: '0',
                                        left: 'none',
                                        top: '15px',
                                        visibility: 'hidden'
                                    }}
                                    type="date"
                                    ref={inputCal}
                                    placeholder="date"
                                    value={labDateParam}
                                    onChange={(e) => {
                                        setLabDateParam(e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {LabListing && LabListing.length > 0 ?
                    <div className="container_area">
                        {LabListing && LabListing.map((item, index) => (
                            <div key={index} className="inner_container_area">
                                <div className="top_area">
                                    <div className="left_top_area">
                                        <div className="name" onClick={() => navigate('/report-detail-lab', { state: { id: item.id, fromNavigation: true } })}>
                                            {item.test_type_title}{item.attachemnts && item.attachemnts.length > 0 ? <FontAwesomeIcon icon={faPaperclip} className="icon_attach" /> : null}
                                        </div>
                                        <div className="txt">
                                            Test Name : {item.test_title}
                                        </div>
                                    </div>
                                    {checkLabPermissions
                                        && checkLabPermissions.permissions
                                        && checkLabPermissions.permissions.length > 1
                                        && parseInt(checkLabPermissions.permissions[2].value) === 1
                                        && patientData && patientData.patient_status !== 'discharge' && User && parseInt(User.user_type) === 2 ?
                                        <div className="right_top_area">
                                            <div className="img_eye">
                                                <Image src={editing} alt="..." onClick={() => navigate('/add-lab-report', { state: { name: "EditTestReport", id: item.id, patient_id: patientData && patientData.id, case_id: patientData && patientData.cases && patientData.cases[0].id } })} />
                                            </div>
                                        </div> : null}
                                    <div className="right_top_area">
                                        <div className="img_eye">
                                            <Image src={Eye} alt="..." onClick={() => navigate('/report-detail-lab', { state: { id: item.id, fromNavigation: true } })} />
                                        </div>
                                    </div>
                                    {checkLabPermissions
                                        && checkLabPermissions.permissions
                                        && checkLabPermissions.permissions.length > 1
                                        && parseInt(checkLabPermissions.permissions[3].value) === 1
                                        && patientData && patientData.patient_status !== 'discharge' && User && parseInt(User.user_type) === 2 ? <div className="right_top_area">
                                        <div className="img_eye">
                                            <Image src={Delete} alt="..." onClick={() => { setdeleteItem(item.id); setDeleteLabreport(true) }} />
                                        </div>
                                    </div> : null}
                                </div>
                                <div className="bottom_area">
                                    <div className="left_area">
                                        <p  onClick={() => navigate(`/staff-detail/${item.created_by}`, { state: { name: item.admin_name, fromNavigation: true } })}>Updated by: {item.admin_name}</p>
                                    </div>
                                    <div className="right_area">
                                        <p>{dateFormat3(item.created)}</p>
                                    </div>
                                </div>
                            </div>))}
                    </div> : <NoDataComponent
                        message="No lab Report Found!"
                    />}

                {checkLabPermissions
                    && checkLabPermissions.permissions
                    && checkLabPermissions.permissions.length > 1
                    && parseInt(checkLabPermissions.permissions[1].value) === 1
                    && patientData && patientData.patient_status !== 'discharge' && User && parseInt(User.user_type) === 2 ?
                    <div className="assign_task">
                        <a >
                            <div className="circle_area" onClick={() => navigate('/add-lab-report', { replace: true, state: { patient_id: patientData && patientData.id, case_id: patientData && patientData.cases && patientData.cases[0].id } })}>
                                <div className="add_icon_area" >
                                    <FontAwesomeIcon icon={faPlus} />
                                    <p style={{ cursor: 'pointer' }}>Add Report</p>
                                </div>
                            </div>
                        </a>
                    </div> : null}
            </div>
            {
                LabListing && LabListing.length > 0 ?
                    <PaginationComponent
                        page={page}
                        setPage={handlePage}
                        totalPages={totalPages}
                        handleBackClick={handleLogsBackClick}
                        handleNextClick={handleLogsNextClick}
                    />
                    :
                    null
            }
            {
                deleteLabreport ?
                    <DeleteModal
                        show={deleteLabreport}
                        handleSubmit={() => deleteLabReportListing(deleteItem)}
                        close={() => setDeleteLabreport(false)}
                        message={'Do you want to delete this lab report ?'}
                        icon={null}
                        rightButtontext={'Delete'}
                    />
                    :
                    null
            }
        </>
    )
}

export default LabUpdate;