import ActionTypes from "../../constants";

const initialState = {
    filters: {
        wards: [],
        hospitals: [],
        sortByAlpha: null
    }
};

const RoomListFilters = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_ROOM_LIST_FILTERS:
            return Object.assign({}, state, {
                filters : action.payload,
            });
        default:
            return state;
    }
};

export default RoomListFilters;
