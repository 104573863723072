//------------ REACT IMPORT --------------//
import React from "react";
//--------- BOOTSTRAP ITEMS IMPORT ----------//
import { Card, Image } from "react-bootstrap";
import {
    BloodPressure,
    BloodSugar,
    HealthPerson,
    HeartRate,
    Man,
    OxygenSaturation,
    Temperature1,
    bodyweight,
} from "../../../../assets/images";
import PaginationComponent from "../../CommanComponents/Pagination";
import { dateFormat, dateFormat2, renderImage, timeFormat } from "../../../Helper/General";
import NoDataComponent from "../../CommanComponents/NoDataComponent";
import { useNavigate } from "react-router-dom";

const Health = (props) => {
    let navigate = useNavigate();
    const {
        patientHealthData,
        page,
        handlePage,
        totalPages,
        handleHealthBackClick,
        handleHealthNextClick,
    } = props;

    return (
        <>
            <div className="health_tab_screen">
                {patientHealthData && patientHealthData.length > 0
                    ? patientHealthData.map((item, i) => (
                        <Card key={i}>
                            <Card.Body>
                                <div className="card_details">
                                    <div className="left_area">
                                        <div className="inner_left_area">
                                            <ul>
                                                {item.heart_beat ? (
                                                    <li>
                                                        <div className="person_health_details">
                                                            <div className="details">
                                                                <div className="img_heart">
                                                                    <Image
                                                                        src={
                                                                            HeartRate
                                                                        }
                                                                        alt="..."
                                                                    />
                                                                </div>
                                                                <h6>
                                                                    Heart Rate
                                                                </h6>
                                                            </div>
                                                            <span>:</span>
                                                        </div>
                                                    </li>
                                                ) : null}
                                                {item.blood_pressure_top &&
                                                    item.blood_pressure_bottom ? (
                                                    <li>
                                                        <div className="person_health_details">
                                                            <div className="details">
                                                                <div className="img_heart">
                                                                    <Image
                                                                        src={
                                                                            BloodPressure
                                                                        }
                                                                        alt="..."
                                                                    />
                                                                </div>
                                                                <h6>
                                                                    Blood
                                                                    Pressure
                                                                </h6>
                                                            </div>
                                                            <span>:</span>
                                                        </div>
                                                    </li>
                                                ) : null}
                                                {item.temperature ? (
                                                    <li>
                                                        <div className="person_health_details">
                                                            <div className="details">
                                                                <div className="img_heart">
                                                                    <Image
                                                                        src={
                                                                            Temperature1
                                                                        }
                                                                        alt="..."
                                                                    />
                                                                </div>
                                                                <h6>
                                                                    Temperature
                                                                </h6>
                                                            </div>
                                                            <span>:</span>
                                                        </div>
                                                    </li>
                                                ) : null}
                                                {item.oxygen_saturation_top &&
                                                    item.oxygen_saturation_bottom ? (
                                                    <li>
                                                        <div className="person_health_details">
                                                            <div className="details">
                                                                <div className="img_heart">
                                                                    <Image
                                                                        src={
                                                                            OxygenSaturation
                                                                        }
                                                                        alt="..."
                                                                    />
                                                                </div>
                                                                <h6>
                                                                    Oxygen
                                                                    Saturation
                                                                </h6>
                                                            </div>
                                                            <span>:</span>
                                                        </div>
                                                    </li>
                                                ) : null}
                                                {item.blood_suger ? (
                                                    <li>
                                                        <div className="person_health_details">
                                                            <div className="details">
                                                                <div className="img_heart">
                                                                    <Image
                                                                        src={
                                                                            BloodSugar
                                                                        }
                                                                        alt="..."
                                                                    />
                                                                </div>
                                                                <h6>
                                                                    Blood
                                                                    Sugar
                                                                </h6>
                                                            </div>
                                                            <span>:</span>
                                                        </div>
                                                    </li>
                                                ) : null}
                                                {item.weight ? (
                                                    <li>
                                                        <div className="person_health_details">
                                                            <div className="details">
                                                                <div className="img_heart">
                                                                    <Image
                                                                        src={
                                                                            bodyweight
                                                                        }
                                                                        alt="..."
                                                                    />
                                                                </div>
                                                                <h6>
                                                                    Weight
                                                                </h6>
                                                            </div>
                                                            <span>:</span>
                                                        </div>
                                                    </li>
                                                ) : null}
                                            </ul>
                                        </div>
                                        <div className="inner_right_area">
                                            <ul>
                                                {item.heart_beat ? (
                                                    <li>
                                                        <div className="details_list">
                                                            <p>
                                                                {
                                                                    item.heart_beat
                                                                }{" "}
                                                                bpm
                                                            </p>
                                                        </div>
                                                    </li>
                                                ) : null}
                                                {item.blood_pressure_top &&
                                                    item.blood_pressure_bottom ? (
                                                    <li>
                                                        <div className="details_list">
                                                            <p>
                                                                {
                                                                    item.blood_pressure_top
                                                                }{" "}
                                                                /{" "}
                                                                {
                                                                    item.blood_pressure_bottom
                                                                }{" "}
                                                                mmhg
                                                            </p>
                                                        </div>
                                                    </li>
                                                ) : null}
                                                {item.temperature ? (
                                                    <li>
                                                        <div className="details_list">
                                                            <p>
                                                                {
                                                                    item.temperature
                                                                }{" "}
                                                                Celsius{" "}
                                                            </p>
                                                        </div>
                                                    </li>
                                                ) : null}
                                                {item.oxygen_saturation_top &&
                                                    item.oxygen_saturation_bottom ? (
                                                    <li>
                                                        <div className="details_list">
                                                            <p>
                                                                {
                                                                    item.oxygen_saturation_top
                                                                }{" "}
                                                                %spo2{" "}
                                                                {
                                                                    item.oxygen_saturation_bottom
                                                                }{" "}
                                                                PRbpm
                                                            </p>
                                                        </div>
                                                    </li>
                                                ) : null}
                                                {item.blood_suger ? (
                                                    <li>
                                                        <div className="details_list">
                                                            <p>
                                                                {
                                                                    item.blood_suger
                                                                }{" "}
                                                                mg / dl
                                                            </p>
                                                        </div>
                                                    </li>
                                                ) : null}
                                                {item.weight ? (
                                                    <li>
                                                        <div className="details_list">
                                                            <p>
                                                                {
                                                                    item.weight
                                                                }{" "}
                                                                kg
                                                            </p>
                                                        </div>
                                                    </li>
                                                ) : null}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="right_area"
                                        onClick={() => navigate(`/staff-detail/${item.created_by}`, { state: { name: item.admin_name, fromNavigation: true } })}
                                    >
                                        <div className="person_details">
                                            <div className="img_person">
                                                <Image
                                                    src={item.image ? renderImage(item.image) : Man}
                                                    alt="..."
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </div>
                                            <u style={{ color: "#E31E24" }}>
                                                <h5 style={{ cursor: 'pointer' }}>
                                                    {item.admin_name
                                                        ? item.admin_name
                                                        : ""}
                                                </h5>
                                            </u>
                                            <p>
                                                {dateFormat(item.created)}{" "}
                                                {timeFormat(item.created)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    ))
                    :
                    <NoDataComponent
                        message="No Vitals Found!"
                    />
                }
            </div>
            {patientHealthData && patientHealthData.length > 0 ? (
                <PaginationComponent
                    page={page}
                    setPage={handlePage}
                    totalPages={totalPages}
                    handleBackClick={handleHealthBackClick}
                    handleNextClick={handleHealthNextClick}
                />
            ) : null}
        </>
    );
};

export default Health;
