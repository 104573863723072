//------------ REACT IMPORT --------------//
import React, { useEffect, useState } from "react";
//---------- FONTAWESOME IMPORT ----------//
import { faEllipsisVertical, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//--------- BOOTSTRAP ITEMS IMPORT ----------//
import { Col, Dropdown, Image, Row } from "react-bootstrap";
//------------ IMAGES IMPORT ---------------//
import { useNavigate } from "react-router-dom";
import {
    Man, MytaskImg
} from "../../../../assets/images";
import { dateFormat, renderImage, timeFormat2, timeFormat3 } from "../../../Helper/General";
import NoDataComponent from "../../CommanComponents/NoDataComponent";
import PaginationComponent from "../../CommanComponents/Pagination";
import AssignedMemberModal from "../../Modals/AssignedMember";

const MyTaskList = (props) => {
    const {
        page,
        handlePage,
        handleBackClick,
        handleNextClick,
        totalPages,
        taskListing,
        checkPermissions,
        currentTab
    } = props;
    const [assignedMembers, setAssignMembersData] = useState(null);
    const [showAssignedModal, setShowAssignedModal] = useState(false);
    let navigate = useNavigate();


    const taskCompletedByAdmin = (item) => {
        if (props.taskCompletedByAdmin) {
            props.taskCompletedByAdmin(item);
        } else return;
    };

    return (
        <>
            <div className="card_section">
                <Row>
                    {taskListing && taskListing.length > 0
                        ? taskListing.map((item, i) => (
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} key={i} >
                                <div className="card_area"
                                >
                                    <div className="mytask_area">
                                        <div className="mytask_left_area">
                                            <div className="img_area">
                                                <Image
                                                    src={item && item.image ? renderImage(item.image) : Man}
                                                    alt="..."
                                                />
                                            </div>
                                            <div className="right_name_area"

                                                onClick={() => navigate(`/patientDetails/${item.patient_id}`, {
                                                    state: {
                                                        name: item.patient_name,
                                                        name1: "tasklist", breadcrumData1: ["Dashboard", "Task list"]
                                                    }
                                                })}
                                            >
                                                <p>{item && item.patient_name}</p>
                                                {item && item.case_id ? <p><span>MR - {item.case_id}</span></p> : null}
                                            </div>
                                        </div>
                                        <div className="mytask_right_area">
                                            <p><span>{item && item.ward_name}</span></p>
                                        </div>
                                    </div>
                                    {/* <div className="sub_title">
                                        <div className="left_area">
                                            <p>
                                                {dateFormat(item.created)}{" "}
                                                {timeFormat2(item.created)}
                                            </p>
                                        </div>
                                        {parseInt(item.task_status) === 1 ? (
                                            <div className="right_area">
                                                <p>Complete</p>
                                            </div>
                                        ) : parseInt(item.task_status) ===
                                            0 ? (
                                            <div className="right_area test">
                                                <p>Pending</p>
                                            </div>
                                        ) : null}
                                    </div> */}
                                    <div
                                        className={
                                            parseInt(item.priority) === 1
                                                ? "card_inner pending"
                                                : "card_inner"
                                        }
                                    >
                                        <div className="card_inner_detail">
                                            <div className="sub_title">
                                                {parseInt(item.priority) ===
                                                    1 ? (
                                                    <div className="left_area testt">
                                                        <p>Low</p>
                                                    </div>
                                                ) : parseInt(
                                                    item.priority
                                                ) === 2 ? (
                                                    <div className="left_area medium">
                                                        <p>Medium</p>
                                                    </div>
                                                ) : parseInt(
                                                    item.priority
                                                ) === 3 ? (
                                                    <div className="left_area">
                                                        <p>High</p>
                                                    </div>
                                                ) : null}

                                                {parseInt(item.task_status) == 1 ?
                                                    <div className="task_right_area">
                                                        <p>Completed</p>
                                                    </div>
                                                    : parseInt(item.task_status) == 0 ?
                                                        <div className="task_right_area">
                                                            <p>Pending</p>
                                                        </div>
                                                        : null}

                                            </div>
                                            <div className="title_area" style={{ cursor: 'pointer' }}>
                                                <h5 onClick={() => navigate('/myTaskDetails', { state: { id: item.id, name: 'subAdminTaskList' } })}>
                                                    {item.title
                                                        ? item.title
                                                        : ""}
                                                </h5>
                                            </div>
                                            <div className="description">
                                                <p>
                                                    {item.description
                                                        ? item.description
                                                        : ""}
                                                </p>
                                                {item.start_time &&
                                                    item.end_time ? (
                                                    <p>
                                                        {" "}
                                                        <span>
                                                            {timeFormat3(item.start_time)}
                                                            {" "}to{" "}
                                                            {timeFormat3(item.end_time)}
                                                        </span>{" "}
                                                    </p>
                                                ) : null}
                                            </div>
                                            {item.assigned_members &&
                                                item.assigned_members.length > 0 ?
                                                <div className="group_image" onClick={() => (setAssignMembersData(item), setShowAssignedModal(true))}>
                                                    <div className="image_area">
                                                        <Image
                                                            src={item && item.assigned_members && item.assigned_members[0].admin_image ? renderImage(item.assigned_members[0].admin_image) : Man}
                                                            alt="..."
                                                        />
                                                    </div>
                                                    {item.assigned_members.length > 1 ? (
                                                        <div className="image_area img_2">
                                                            <Image
                                                                src={item.assigned_members[1].admin_image ? renderImage(item.assigned_members[1].admin_image) : Man}
                                                                alt="..."
                                                            />
                                                        </div>
                                                    ) : null}
                                                    {item.assigned_members.length === 3 ? (
                                                        <div className="image_area img_2">
                                                            <Image
                                                                src={item.assigned_members[2].admin_image ? renderImage(item.assigned_members[2].admin_image) : Man}
                                                                alt="..."
                                                            />
                                                        </div>
                                                    ) : item.assigned_members.length > 3 ? (
                                                        <a>
                                                            <div className="image_area img_3">
                                                                <p>3+</p>
                                                            </div>
                                                        </a>
                                                    ) : null}
                                                </div>
                                                : null}
                                        </div>
                                        <div className="bottom_area">
                                            <div className="inner_area">
                                                <div className="left_area">
                                                    <div className="img_area">
                                                        <Image
                                                            variant="left"
                                                            src={item.assign_by_image ? renderImage(item.assign_by_image) : Man}
                                                            alt="..."
                                                        />
                                                    </div>
                                                </div>
                                                <div className="right_area">
                                                    <div className="name_area">
                                                        <h3>
                                                            {item.assign_by
                                                                ? item.assign_by
                                                                : ""}
                                                        </h3>
                                                        <p>
                                                            {item.specialization
                                                                ? item.specialization
                                                                : ""}
                                                        </p>
                                                        <Dropdown>
                                                            <Dropdown.Toggle
                                                                variant="none"
                                                                id="dropdown-basic"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEllipsisVertical
                                                                    }
                                                                    className="ellipsis_icon"
                                                                />
                                                            </Dropdown.Toggle>
                                                            {parseInt(
                                                                item.task_status
                                                            ) === 1 ? (
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                        onClick={() => navigate("/myTaskDetails", { state: { id: item.id, }, })}
                                                                    >
                                                                        View
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            ) : (
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                        onClick={() => taskCompletedByAdmin(item)}
                                                                    >
                                                                        Completed
                                                                    </Dropdown.Item>

                                                                    {parseInt(currentTab) === 3 ? <Dropdown.Item
                                                                        onClick={() => navigate('/assignTask', { state: { name: 'EditTask', id: item.id } })}
                                                                    >
                                                                        Edit
                                                                    </Dropdown.Item> : null}
                                                                </Dropdown.Menu>

                                                            )}
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))
                        :
                        <NoDataComponent
                            message="No Task Found!"
                        />
                    }
                </Row>
                {
                    checkPermissions
                        && checkPermissions.permissions
                        && checkPermissions.permissions.length > 1
                        && parseInt(checkPermissions.permissions[1].value) === 1 ?
                        <div className="assign_task">
                            <a >
                                <div className="circle_area"
                                    onClick={() => navigate('/assignTask')} style={{ cursor: 'pointer' }}
                                >
                                    <div className="add_icon_area" >
                                        <FontAwesomeIcon icon={faPlus} />
                                        <p style={{ cursor: 'pointer' }}>Assign Task</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        :
                        null
                }

            </div>
            {taskListing && taskListing.length > 0 ? (
                <PaginationComponent
                    page={page}
                    setPage={handlePage}
                    totalPages={totalPages}
                    handleBackClick={handleBackClick}
                    handleNextClick={handleNextClick}
                />
            ) : null}
            {
                showAssignedModal && assignedMembers && assignedMembers.assigned_members.length > 0 ? (
                    <AssignedMemberModal
                        show={showAssignedModal}
                        assignedMembers={assignedMembers && assignedMembers.assigned_members}
                        close={() => setShowAssignedModal(false)}
                    />
                ) : null
            }
        </>
    );
};

export default MyTaskList;
