import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import taskController from "../../../../../apis/Controllers/TaskController/task.controller";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import store from "../../../../../redux/store";
import { filtersController } from "../../../../../apis/Controllers/CommonController/filter.controller";
import permissionsController from "../../../../../apis/Controllers/PermissionsController/permissions.contoller";

const TaskListFunctions = () => {
    var breadcrumData = ["Dashboard"];
    var breadcrumItem = "Task list";
    var breadcrumData1 = ["Dashboard", "Task list"];

    let storedFilters = store.getState().TaskListFilters.filters;
    const [checkPermissions, setCheckPermissions] = useState(null);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [taskListing, setTaskListing] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [deletConfirmationModal, setDeletConfirmationModal] = useState(false);
    const [deletItemDetail, setDeletItemDetail] = useState(null);
    const [filtersAppliedSign, setFiltersAppliedSign] = useState(false);
    const [checkStoredvalueFromRedux, SetCheckStoredValueFromRedux] = useState(null);
    const [loader, setLoader] = useState(false);
    const [keyword, setSearch] = useState(null);
    const [SearchLoader, setSearchLoader] = useState(false);
    //-----------------PAZINATION-----------------------------
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    //--------------------------------------------------------
    const [allFiltersValues, setAllFiltersValues] = useState(false);
    let navigate = useNavigate();


    const getModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('task');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckPermissions(data);
        }
        else {
            setCheckPermissions(null);
        }
    };


    useEffect(() => {
        if (storedFilters && checkStoredvalueFromRedux, allFiltersValues) {
            if (
                (storedFilters && storedFilters.taskPriorities) ||
                (storedFilters && storedFilters.taskStatus) ||
                (storedFilters &&
                    storedFilters.assignBy &&
                    storedFilters.assignBy.length > 0) ||
                (storedFilters &&
                    storedFilters.wards &&
                    storedFilters.wards.length > 0) ||
                (storedFilters &&
                    storedFilters.hospitals &&
                    storedFilters.hospitals.length > 0)
            ) {
                setFiltersAppliedSign(true);
                getTaskListing("", page, itemsPerPage);
            } else {
                setFiltersAppliedSign(false);
                getTaskListing("", page, itemsPerPage);
            }
        }
    }, [storedFilters, checkStoredvalueFromRedux, allFiltersValues]);


    useEffect(() => {
        if (storedFilters && checkStoredvalueFromRedux) {
            if ((storedFilters &&
                storedFilters.hospitals &&
                storedFilters.hospitals.length > 0)
            ) {
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                handleDashboardHospitalwithFiltersAppliedCase(item, storedFilters.hospitals);
            } else {
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                if (item) {
                    handleDashboardHospitalwithFilters(item);
                }
            }
        }
    }, [checkStoredvalueFromRedux]);


    const handleDashboardHospitalwithFiltersAppliedCase = async (seletedHospitalData, AppliedFilterHospitals) => {
        let applied = AppliedFilterHospitals
        if (applied.length > 1) {
            let index = applied.findIndex((id) => parseInt(id) === parseInt(seletedHospitalData.id))
            if (index === -1) {
                applied.push(seletedHospitalData.id);
            }
            // else {
            //     return applied
            // }
        }
        else {
            applied = []
            applied.push(seletedHospitalData.id);
        }
        let filterNew = {
            assignBy: storedFilters && storedFilters.assignBy ? storedFilters.assignBy : [],
            wards: storedFilters && storedFilters.wards && storedFilters.wards.length > 0 ? storedFilters.wards : [],
            hospitals: applied,
            taskPriorities:
                storedFilters && storedFilters.taskPriorities ? storedFilters.taskPriorities : "",
            taskStatus: storedFilters && storedFilters.taskStatus ? storedFilters.taskStatus : "",
        };
        await filtersController.setTaskListFilters(filterNew);
        await setAllFiltersValues(true);
    };


    const handleDashboardHospitalwithFilters = async (seletedHospitalData) => {
        let filterNew = {
            assignBy: storedFilters && storedFilters.assignBy ? storedFilters.assignBy : [],
            wards: storedFilters && storedFilters.wards && storedFilters.wards.length > 0 ? storedFilters.wards : [],
            hospitals: seletedHospitalData && seletedHospitalData.id ? [seletedHospitalData.id] : [],
            taskPriorities:
                storedFilters && storedFilters.taskPriorities ? storedFilters.taskPriorities : "",
            taskStatus: storedFilters && storedFilters.taskStatus ? storedFilters.taskStatus : "",
        };
        await filtersController.setTaskListFilters(filterNew);
        await setAllFiltersValues(true);
    };


    useEffect(() => {
        if (count) {
            handlePages(count);
        }
    }, [count, itemsPerPage]);

    const handlePages = (count) => {
        let array = [];
        const data = parseInt(count);
        const items = parseInt(itemsPerPage);
        const totalPage = Math.ceil(data / items);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i);
        }
        setTotalPages(array);
    };

    const handleChange = (e) => {
        setSearchInput(e.target.value);
        getTaskListing(e.target.value, page, itemsPerPage);
    };

    const getTaskListing = async (e, page, itemPerPage, filters) => {
        let post = {
            search: e ? e : keyword ? keyword : "",
            page: page ? page : 1,
            limit: itemPerPage ? itemPerPage : 10,
            hospital_id: filters && filters.hospitals && filters.hospitals.length > 0 ? filters.hospitals : storedFilters && storedFilters.hospitals && storedFilters.hospitals.length > 0 ? storedFilters.hospitals : [],
            task_status: filters && filters.taskStatus ? filters.taskStatus : storedFilters && storedFilters.taskStatus ? storedFilters.taskStatus : "",
            task_priority: filters && filters.taskPriorities ? filters.taskPriorities : storedFilters && storedFilters.taskPriorities ? storedFilters.taskPriorities : "",
            assigned_by: filters && filters.assignBy ? filters.assignBy : storedFilters && storedFilters.assignBy ? storedFilters.assignBy : [],
            ward_id: filters && filters.wards ? filters.wards : storedFilters && storedFilters.wards ? storedFilters.wards : [],
        };
        setLoader(true);
        let response = await taskController.taskListing(post);
        if (response && response.status) {
            setCount(response.count);
            setTotalCount(response.totalCount);
            setTaskListing(response.data);
            setLoader(false);
        } else {
            setLoader(false);
            setTaskListing([]);
        }
    };

    const handleDeleteTask = async () => {
        let item = deletItemDetail;
        let array = [...taskListing];
        let id = item && item.id ? item.id : "";
        setLoader(true);
        let response = await taskController.taskDelete(id);
        if (response && response.status) {
            setLoader(false);
            new Toaster().success(response.message);
            let filterData = array.filter((data) => data.id !== item.id);
            if (filterData.length > 0) {
                getTaskListing("", page, itemsPerPage);
            } else {
                if (page > 1) {
                    let newPage = page - 1;
                    setPage(page - 1);
                    getTaskListing("", newPage, itemsPerPage);
                } else {
                    setPage(page);
                    getTaskListing("", page, itemsPerPage);
                }
            }
            setTotalPages([]);
            setDeletConfirmationModal(false);
        } else {
            setDeletConfirmationModal(false);
            setLoader(false);
        }
    };

    const taskCompletedByAdmin = async (item) => {
        setLoader(true);
        let id = item && item.id ? item.id : "";
        let response = await taskController.taskComplete(id);
         if (response && response.status) {
             let array = [...taskListing];
             item.task_status = parseInt(item.task_status) == 0 ? 1 : 0;
             setTaskListing(array);
            new Toaster().success(response.message);
            setLoader(false);
        } else {
            setLoader(false);
        }
    };

    const handleBackClick = () => {
        // <---------PAZINATION
        if (page > 1) {
            let newPage = page - 1;
            setPage(page - 1);
            getTaskListing("", newPage, itemsPerPage);
        } else return;
    };

    const handleNextClick = () => {
        // <---------PAZINATION
        if (page >= 1 && page < totalPages.length) {
            let newPage = page + 1;
            getTaskListing("", newPage, itemsPerPage);
            setPage(page + 1);
        } else return;
    };

    const handlePage = (page) => {
        setPage(page);
        getTaskListing("", page, itemsPerPage);
    };

    const handleItemPerPage = (e) => {
        let value = e.target.value;
        setItemsPerPage(value);
        getTaskListing("", 1, value);
    };

    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/dashboard-superadmin");
            }
        } else if (i === 1) {
            navigate("/task-list");
        } else return;
    };

    const applyFilters = async (filter) => {
        let filters = {
            assignBy: filter && filter.assignBy ? filter.assignBy : [],
            wards: filter && filter.wards ? filter.wards : [],
            hospitals: filter && filter.hospitals ? filter.hospitals : [],
            taskPriorities:
                filter && filter.taskPriorities ? filter.taskPriorities : "",
            taskStatus: filter && filter.taskStatus ? filter.taskStatus : "",
        };

        if (filter && filter.hospitals && filter.hospitals.length > 0) {
            await filtersController.setTaskListFilters(filters);
            setShowFilterModal(false);
            setFiltersAppliedSign(true);
        } else {
            new Toaster().error("Please select at least one hospital");
        }
    };


    const handleClearAllFilters = async () => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        await filtersController.clearTaskListFilters(item);
    };

    const setReduxValue = (e) => {          //<------------For confirmation that we have checked the localstorage value's and RESET the REDUX- FILTERS's Values and after it the listing api will hit
        SetCheckStoredValueFromRedux(e);
    };

    return {
        getModulePermissions,
        checkPermissions,
        setSearchInput,
        searchInput,
        handleChange,
        getTaskListing,
        taskListing,
        navigate,
        handleBackClick,
        handleNextClick,
        handleDeleteTask,
        page,
        setPage,
        count,
        totalPages,
        itemsPerPage,
        setItemsPerPage,
        showFilterModal,
        setShowFilterModal,
        breadcrumData,
        breadcrumItem,
        handlePage,
        handleItemPerPage,
        loader,
        setLoader,
        handleBreadcrumDataNaviagtion,
        deletConfirmationModal,
        setDeletConfirmationModal,
        deletItemDetail,
        setDeletItemDetail,
        applyFilters,
        filtersAppliedSign,
        handleClearAllFilters,
        setReduxValue,
        totalCount,
        setTotalCount,
        keyword,
        setSearch,
        breadcrumData1,
        taskCompletedByAdmin,
    };
};

export default TaskListFunctions;
