import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { ABC, Building, Check, Email, Email2, Hospital_Banner, Hospital_Img, Loc, Loc_Round, Profile_hos, Route, phonecall } from "../../../../../assets/images";
import * as Constants from "../../../../Helper/constants/constants";
import Loader from "../../../../Helper/loader";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import HospitalDetailFunctions from "./funstions";
import { openPhoneDialer, renderImage } from "../../../../Helper/General";
import { useParams } from "react-router-dom";


const HospitalDetails = () => {
    const {
        User,
        getModulePermissions,
        checkPermissions,
        getHospitalDetail,
        hospitalDetail,
        loaction,
        navigate,
        taskAssign,
        setTaskAssign,
        breadcrumData,
        breadcrumItem,
        loader,
        setLoader,
        handleBreadcrumDataNaviagtion,
        setBreadcrumData
    } = HospitalDetailFunctions();

    let param = useParams();
    useEffect(() => {
        if (param.slug) {
            getHospitalDetail(param.slug);
            let userdata = localStorage.getItem('SET_USER_DATA');
            let User = JSON.parse(userdata);
            if (User && parseInt(User.user_type) === 2) {
                getModulePermissions();
            }
            if (loaction && loaction.state && loaction.state.name == 'wardlist') {
                setBreadcrumData(loaction && loaction.state && loaction.state.breadcrumData1)
            }
            else if (loaction && loaction.state && loaction.state.name == 'departmentlist') {
                setBreadcrumData(loaction && loaction.state && loaction.state.breadcrumData1)
            }
            else if (loaction && loaction.state && loaction.state.name == "roomlist") {
                setBreadcrumData(loaction && loaction.state && loaction.state.breadcrumData1)
            }
            else if (loaction && loaction.state && loaction.state.name == "stafflist") {
                setBreadcrumData(loaction && loaction.state && loaction.state.breadcrumData1)
            }
            else if (loaction && loaction.state && loaction.state.name == "dashboard") {
                setBreadcrumData(loaction && loaction.state && loaction.state.breadcrumData1)
            }
            else {
                setBreadcrumData(breadcrumData)
            }
        }
        else {
            navigate(-1);
        }
    }, []);

    return (
        <div className="hospital_detail_area">
            <WithoutImageHeader
                header={Constants.HOSPITAL_DETAILS}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
                icon={User && parseInt(User.user_type) === 1 ? "edit" : checkPermissions
                    && checkPermissions.permissions
                    && checkPermissions.permissions.length > 2
                    && parseInt(checkPermissions.permissions[2].value) === 1 ? "edit" : null}
                handleNavigate={() => navigate('/hospital', { state: { id: hospitalDetail.id, name: 'EditHospital' } })}
                handleBack={() => navigate(-1)}
            />
            {
                !loader && hospitalDetail ?
                    <div className="hospital_area">
                        <div className="img_banner">
                            <Image src={hospitalDetail.image ? renderImage(hospitalDetail.image) : Hospital_Img} alt="..." />
                        </div>
                        <div className="content_section">
                            {
                                hospitalDetail.title ?
                                    <div className="content_area">
                                        <div className="left_area">
                                            <div className="icon_user">
                                                <Image src={Profile_hos} alt="..." />
                                            </div>
                                        </div>
                                        <div className="right_area">
                                            <p style={{ textTransform: "capitalize" }}>Name - {hospitalDetail.title}</p>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {
                                hospitalDetail.gst_number ?
                                    <div className="content_area">
                                        <div className="left_area">
                                            <div className="icon_user">
                                                <Image src={Check} alt="..." />
                                            </div>
                                        </div>
                                        <div className="right_area">
                                            <p>GSTIN - {hospitalDetail.gst_number}</p>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {
                                hospitalDetail.address ?
                                    <div className="content_area cursor">
                                        <div className="left_area">
                                            <div className="icon_user">
                                                <Image src={Loc} alt="..." />
                                            </div>
                                        </div>
                                        <div className="right_area">
                                            <p style={{ textTransform: "capitalize", }}
                                                onClick={() => hospitalDetail.map_link ? window.open(hospitalDetail.map_link) : null}>Address - {hospitalDetail.map_link ? <u>{hospitalDetail.address}</u> : hospitalDetail.address}</p>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {
                                hospitalDetail.email ?
                                    <div className="content_area">
                                        <div className="left_area">
                                            <div className="icon_user">
                                                <Image src={Email2} alt="..." />
                                            </div>
                                        </div>
                                        <div className="right_area">
                                            <p style={{  cursor: 'pointer' }}>Email - <u onClick={() => window.location.href = `mailto:${hospitalDetail.email}`}>{hospitalDetail.email}</u></p>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {
                                hospitalDetail.phonenumber ?
                                    <div className="content_area">
                                        <div className="left_area">
                                            <div className="icon_user">
                                                <Image src={phonecall} alt="..." />
                                            </div>
                                        </div>
                                        <div className="right_area">
                                            <p style={{ textTransform: "capitalize", cursor: 'pointer' }} onClick={() => hospitalDetail.phonenumber ? openPhoneDialer(hospitalDetail.phonenumber) : null}>Contact Number - <u>{hospitalDetail.phonenumber}</u></p>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {
                                hospitalDetail.telephone_number ?
                                    <div className="content_area">
                                        <div className="left_area">
                                            <div className="icon_user">
                                                <Image src={phonecall} alt="..." />
                                            </div>
                                        </div>
                                        <div className="right_area">
                                            <p style={{ textTransform: "capitalize", cursor: 'pointer' }}
                                                onClick={() => hospitalDetail.telephone_number ? openPhoneDialer(hospitalDetail.telephone_number) : null}>
                                                Telephone Number - <u>{hospitalDetail.telephone_number}</u></p>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {
                                hospitalDetail.city_name ?
                                    <div className="content_area">
                                        <div className="left_area">
                                            <div className="icon_user">
                                                <Image src={Building} alt="..." />
                                            </div>
                                        </div>
                                        <div className="right_area">
                                            <p style={{ textTransform: "capitalize" }}>City - {hospitalDetail.city_name}</p>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {
                                hospitalDetail.state_name ?
                                    <div className="content_area">
                                        <div className="left_area">
                                            <div className="icon_user">
                                                <Image src={Loc_Round} alt="..." />
                                            </div>
                                        </div>
                                        <div className="right_area">
                                            <p style={{ textTransform: "capitalize" }}>State - {hospitalDetail.state_name}</p>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {
                                hospitalDetail.zipcode ?
                                    <div className="content_area">
                                        <div className="left_area">
                                            <div className="icon_user">
                                                <Image src={Route} alt="..." />
                                            </div>
                                        </div>
                                        <div className="right_area">
                                            <p>ZIP Code - {hospitalDetail.zipcode}</p>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    :
                    <Loader
                        loader={loader}
                    />
            }
            {/* <TaskAssignModal show={taskAssign} close={() => setTaskAssign(false)} /> */}
        </div >
    );
};

export default HospitalDetails;
