import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import labController from '../../../../../apis/Controllers/LabController/lab.controller';
const LabReportDetailFunction = () => {

    const [labReportDetails, setLabReportDetails] = useState(null);
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();



    const getPatientLabreprtdetails = async (id) => {
        setLoader(true)
        let response = await labController.patientLabTestDetails(id)
        if (response && response.status) {
            let details = response.data;
            const testDetails = JSON.parse(details.test_details);
            details.test_details = testDetails;
            setLabReportDetails(details)
            setLoader(false)
        } else {
            setLabReportDetails(null)
            setLoader(false)
        }
        setLoader(false)
    };











    return {
        labReportDetails,
        loader,
        location,
        getPatientLabreprtdetails,
        navigate
    }
}

export default LabReportDetailFunction