import { Toaster } from "../../../components/Helper/toaster/Toaster";
import { wardServices } from "../../Services/WardServices/ward.services";

const wardListing = async (params) => {
    let response = await wardServices.wardListing(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const wardCreate = async (data) => {
    let post = {
        title: data.ward_title ? data.ward_title : null,
        // ward_number: data.ward_number ? data.ward_number : null,
        ward_number:  null,
        hospital_id: data.hospital_arr ? [data.hospital_arr.id] : [],
    };
    let response = await wardServices.wardCreate(post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const wardDetail = async (id) => {
    let response = await wardServices.wardDetail(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const wardUpdate = async (id, data) => {
    let post = {
        title: data.ward_title ? data.ward_title : null,
        // ward_number: data.ward_number ? data.ward_number : null,
        ward_number:  null,
        hospital_id: data.hospital_arr ? [data.hospital_arr.id] : [],
    };
    let response = await wardServices.wardUpdate(id, post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const updateWardStatus = async (id, params) => {
    let response = await wardServices.updateWardStatus(id, params);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const wardDelete = async (id) => {
    let response = await wardServices.wardDelete(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const superAdminwardListing = async (params) => {
    let response = await wardServices.superAdminwardListing(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const wardController = {
    wardListing,
    wardCreate,
    wardDetail,
    wardUpdate,
    wardDelete,
    superAdminwardListing,
    updateWardStatus,
};

export default wardController;
