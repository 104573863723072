import React from "react";
import { Image } from "react-bootstrap";
import { AuthHeaderImage } from "../../assets/images";

const AuthHeader = (props) => {
    return (
        <div className="auth_header">
            <div className="header_area header_responsive">
                <div className="left_area">
                    <h3>{props.header}</h3>
                    <p>{props.description}</p>
                </div>
                <div className="right_area">
                    <div className="img_one">
                        <Image src={AuthHeaderImage} alt="..." />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthHeader;