import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Validation from "../../../../Helper/validations/vaildation";
import hospitalController from "../../../../../apis/Controllers/HospitalController/hospital.controller";
import { actionsController } from "../../../../../apis/Controllers/CommonController/action.controller";
import departmentController from "../../../../../apis/Controllers/DepartmentController/department.controller";
import rolesController from "../../../../../apis/Controllers/RolesController/roles.controller";
import adminController from "../../../../../apis/Controllers/AdminControler/admin.controller";
import permissionsController from "../../../../../apis/Controllers/PermissionsController/permissions.contoller";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../../redux/store";

const AddStafFunction = () => {
    const loaction = useLocation();
    var breadcrumData = ["Dashboard", "Staff List"];
    const [breadcrumItem, setbreadcrumItem] = useState("Add Staff");
    const defaultValues = {
        image: "",
        hospital: "",
        name: "",
        email: "",
        phoneNo: "",
        employeeId: "",
        role: "",
        gender: "",
        bloodGroup: "",
        dob: "",
        joiningDate: "",
        department: "",
        specialization: "",
        state: "",
        city: "",
        address: "",
        password: ''
    };
    const defaultErrors = {
        image: {
            rules: [""],
            isValid: true,
            message: "",
        },
        hospital: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        name: {
            rules: ["required",],
            isValid: true,
            message: "",
        },
        email: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        phoneNo: {
            rules: ["required", "numeric", "min:9", "max:13"],
            isValid: true,
            message: "",
        },
        role: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        employeeId: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        gender: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        bloodGroup: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        dob: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        joiningDate: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        department: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        specialization: {
            rules: [""],
            isValid: true,
            message: "",
        },
        state: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        city: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        address: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        password: {
            rules: loaction && loaction.state && loaction.state.name ? [] : ["required", "password"],
            isValid: true,
            message: "",
        },
    };

    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);
    const [loader, setLoader] = useState(false);
    const [hospitalsListing, setHospitalsListing] = useState([]);
    const [citiesList, setCitiesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [genderList, setGenderList] = useState([]);
    const [bloodgrpList, setBloodgrpList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [superAdmin, setSuperAdmin] = useState(false);
    const [permissonsArrayList, setPermissionArray] = useState([]);
    const [checkRoleChangeOnEditCase, setCheckRoleChangeOnEditCase] = useState(false);
    const [checkAllFieldsFilled, setCheckAllFieldsFilled] = useState(false);
    const [validationFromDrawerEditProfile, setValidationFromdrawerEditProfile] = useState(false);
    const navigate = useNavigate();
    const [focus, setFocus] = useState(false);
    const inputCal = useRef(null);
    const [focusDob, setFocusDob] = useState(false);
    const inputCalDob = useRef(null);

    useEffect(() => {
        if (values && values.role) {
            if (loaction && loaction.state && loaction.state.name) {
                if (checkRoleChangeOnEditCase) {                              //<----------on role change in edit Staf Case
                    let id = values.role.id ? values.role.id : ''
                    getRoleDetail(id);
                }
                else return;
            }
            else {
                let id = values.role.id ? values.role.id : ''
                getRoleDetail(id);
            }
        }
    }, [values.role]);


    const handleChange = (field, value) => {
        let validation = new Validation(isError);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
        if (field === 'state') {
            setValues({
                ...values,
                state: value,
                city: null,
            });
        }
    };


    const getRoleDetail = async (id) => {
        let array = [...permissions];
        let response = await rolesController.roleDetail(id);
        if (response && response.status) {
            let data1 = response.data && response.data.permissions ? response.data.permissions : []
            const notExistModule = array.filter(({ id: roleId }) => !data1.some(({ id: dataId }) => roleId === dataId));
            if (notExistModule) {
                for (let i in notExistModule) {
                    notExistModule[i].permissions.map((item) => {
                        item.value = 0;
                    });
                }
                let newAray = data1.concat(notExistModule);
                setPermissions(newAray);
            }
            else {
                setPermissions(data1);
            }
        } else return;
    };


    const getHospitalListing = async (e, page) => {
        if (!loader) {
            let post = {
                search: e ? e : "",
                page: page ? page : 1,
            };
            setLoader(true);
            let response = await hospitalController.hospitalListing(post);
            if (response && response.status) {
                let array1 = response.data;
                for (let i in array1) {
                    array1[i]["label"] = array1[i].title
                    array1[i]['value'] = array1[i].title
                }
                setHospitalsListing(array1);
                setLoader(false);
            } else {
                setHospitalsListing([]);
                setLoader(false);
            }
            setLoader(false);
        }
    };

    const getbloodGroup = async () => {
        let response = await actionsController.bloodGroups(null);
        if (response && response.status) {
            let array = response.data;
            for (let i in array) {
                array[i]["label"] = array[i].title;
            }
            setBloodgrpList(array);
        } else {
            setBloodgrpList([]);
        }
    };

    const getcities = async (e) => {
        let stateId = {
            state_id: e.id,
        };
        let response = await actionsController.citiesList(stateId);
        if (response && response.status) {
            let array = response.data;
            for (let i in array) {
                array[i]["label"] = array[i].title;
            }
            setCitiesList(array);
        } else {
            setCitiesList([]);
        }
    };

    const getstate = async () => {
        let response = await actionsController.statesList({ country_id: 100 });
        if (response && response.status) {
            let array = response.data;
            for (let i in array) {
                array[i]["label"] = array[i].title;
            }
            setStateList(array);
        } else {
            setStateList([]);
        }
    };

    const getGender = async () => {
        let response = await actionsController.gendersList(null);
        if (response && response.status) {
            let array = response.data;
            for (let i in array) {
                array[i]["label"] = array[i].title;
            }
            setGenderList(array);
        } else {
            setGenderList([]);
        }
    };

    const getdepartment = async (item) => {
        let post = {
            hospital_id: item ? [item.id] : [],
        };
        let response = await departmentController.departmentList(post);
        if (response && response.status) {
            let array = response.data;
            for (let i in array) {
                array[i]["label"] = array[i].title;
            }
            setDepartmentList(array);
        } else {
            setDepartmentList([]);
        }
    };

    const getRoleList = async () => {
        let response = await rolesController.rolesListingDropdown();
        if (response && response.status) {
            let array = response.data;
            for (let i in array) {
                array[i]["label"] = array[i].title;
            }
            setRoleList(array);
        } else {
            setRoleList([]);
        }
    };


    const createAdmin = async () => {
        let value = {
            ...values,
            is_super_admin: superAdmin ? 1 : 0,
        };
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(value);
        if (isValid && !isValid.haveError) {
            setLoader(true);
            setCheckAllFieldsFilled(false);
            if (loaction && loaction.state && loaction.state.name) {
                let id = loaction && loaction.state && loaction.state.id ? loaction.state.id : "";
                let response = await adminController.adminUpdate(permissions, value, id, validationFromDrawerEditProfile);
                if (response && response.status) {
                    new Toaster().success(response.message);
                    if (validationFromDrawerEditProfile) {
                        navigate("/dashboard", { replace: true });
                    }
                    else {
                        navigate("/staff-list");
                    }
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            } else {
                let response = await adminController.adminCreate(
                    value,
                    permissions
                );
                if (response && response.status) {
                    new Toaster().success(response.message);
                    navigate("/staff-list");
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            }
        } else {
            setError({ ...isValid.errors });
            setCheckAllFieldsFilled(true);
        }
    };

    const getPermissionsListing = async () => {
        let response = await permissionsController.permissionsListing();
        if (response && response.status) {
            let list = response.data;
            for (let i in list) {
                for (let j in list[i].permissions) {
                    list[i].permissions[j].value = 0;
                }
            }
            if (loaction && loaction.state && loaction.state.id) {
                let id =
                    loaction && loaction.state && loaction.state.id
                        ? loaction.state.id
                        : "";
                getStaffDetails(id, list);
            } else {
                setPermissionArray(list);
            }
            setPermissions(list);
        } else {
            setPermissions([]);
        }
    };

    const handleCheck = (item, parent) => {                                 // < ----------------- On click on module's checkbox's
        if (values && values.role) {
            let array = [...permissions];
            if (item && parent) {
                for (let i in array) {
                    if (array[i].type == parent.type) {
                        let checkedParent = array[i]
                        let checkedParentPermission = checkedParent.permissions
                        if (item.title === checkedParentPermission[0].title) {                                  //<----------on Listing check click
                            let index = array.findIndex((item) => parseInt(item.id) === parseInt(parent.id));
                            if (index !== -1) {
                                let clickedItemPermisions = array[index].permissions;
                                for (let i in clickedItemPermisions) {
                                    if (clickedItemPermisions[i].title === item.title) {
                                        if (parseInt(clickedItemPermisions[i].value) === 1) {
                                            clickedItemPermisions[i].value = 0;
                                        } else {
                                            clickedItemPermisions[i].value = 1;
                                        }
                                    }
                                    else {
                                        clickedItemPermisions[i].value = 0;
                                    }
                                }
                                array[index].permissions = clickedItemPermisions;
                                let data = isAllCheckedSelected(array);
                                if (data === false) {
                                    setSelectAll(false);
                                    setSuperAdmin(false);
                                } else {
                                    setSelectAll(true);
                                    // setSuperAdmin(true);
                                }
                                setPermissions(array);
                            } else {
                                setPermissions(array);
                            }
                        }
                        else if (item.title !== checkedParentPermission[0].title) {                      //<---------- create update delet check click
                            if (parseInt(checkedParentPermission[0].value) === 1) {
                                let index = array.findIndex((item) => parseInt(item.id) === parseInt(parent.id));
                                if (index !== -1) {
                                    let clickedItemPermisions = array[index].permissions;
                                    for (let i in clickedItemPermisions) {
                                        if (clickedItemPermisions[i].title === item.title) {
                                            if (parseInt(clickedItemPermisions[i].value) === 1) {
                                                clickedItemPermisions[i].value = 0;
                                            } else {
                                                clickedItemPermisions[i].value = 1;
                                            }
                                        }
                                    }
                                    array[index].permissions = clickedItemPermisions;
                                    let data = isAllCheckedSelected(array);
                                    if (data === false) {
                                        setSelectAll(false);
                                        setSuperAdmin(false);
                                    } else {
                                        setSelectAll(true);
                                        // setSuperAdmin(true);
                                    }
                                    setPermissions(array);
                                } else {
                                    setPermissions(array);
                                }
                            }
                            else {
                                new Toaster().error('Please assign "Listing" permission first.');
                            }
                        }
                        else return;
                    }
                    else {
                        // console.log('--------------------------lop else call');
                    }
                }
            }
        }
        else {
            new Toaster().error('Please select Role first');
        }
    };

    const isAllCheckedSelected = (list) => {           // <---------------------For handle "all-Select" and "SuperAdmin" checkox
        let allChecked = false;
        for (let i in list) {
            for (let j in list[i].permissions) {
                if (parseInt(list[i].permissions[j].value) === 0) {
                    allChecked = false;
                    return false;
                } else {
                    allChecked = true;
                }
            }
        }
        return allChecked;
    };


    const handleAllSelect = (item) => {             // <----------------onClick allSelect Checkbox -----------> handle [all select ] and [super admin] checkbox
        let array = [...permissions];
        if (item) {
            for (let i in array) {
                array[i].permissions.map((item) => {
                    return (item.value = 1);
                });
            }
            setSelectAll(true);
            setSuperAdmin(false);
        } else {
            for (let i in array) {
                array[i].permissions.map((item) => {
                    return (item.value = 0);
                });
            }
            setSelectAll(false);
        }
        setPermissions(array);
    };

    const handleSuperAdminSelect = (item) => {                      // <----------------onClick superadmin Checkbox -----------> handle [super admin] checkbox
        let array = [...permissions];
        if (item) {
            for (let i in array) {
                array[i].permissions.map((item) => {
                    return (item.value = 1);
                });
            }
            setSuperAdmin(true);
            setSelectAll(false);
        } else {
            for (let i in array) {
                array[i].permissions.map((item) => {
                    return (item.value = 0);
                });
            }
            setValues({
                ...values,
                role: null
            })
            setSuperAdmin(false);
        }
        setPermissions(array);
    };


    const checkAllpermissionsCheckedValue = (list) => {
        //<---------------first Time render check status of all permissions and will set `Select all` and 'supder_admin' [true or false] according to the status
        let state = false;
        let array = [...list];
        for (let i in array) {
            array[i].permissions.map((item) => {
                if (parseInt(item.value) === 1) {
                    state = true;
                    return true;
                } else {
                    state = false;
                }
            });
        }
        // setSelectAll(state);
        // setSuperAdmin(state);
    };

    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/dashboard-superadmin");
            }
        } else if (i === 1) {
            navigate("/staff-list");
        } else return;
    };

    const getStaffDetails = async (id, list) => {
        let array = [...list];
        let response = await adminController.adminDetail(id);
        setLoader(true);
        if (response && response.status) {
            let data = response.data;
            let data1 = response.data.permissions;
            const notExistModule = array.filter(({ id: roleId }) => !data1.some(({ id: dataId }) => roleId === dataId));
            if (notExistModule) {
                for (let i in notExistModule) {
                    notExistModule[i].permissions.map((item) => {
                        item.value = 0;
                    });
                }
                let newAray = data1.concat(notExistModule);
                setPermissions(newAray);
                let status = isAllCheckedSelected(newAray);
                setSelectAll(
                    status &&
                        parseInt(
                            response && response.data && response.data.user_type
                        ) === 2
                        ? true
                        : false
                );
                setSuperAdmin(
                    status &&
                        parseInt(
                            response && response.data && response.data.user_type
                        ) === 1
                        ? true
                        : false
                );
            } else {
                setPermissions(data);
            }



            var newArray = [];
            data && data.hospitals.forEach(function (obj) {
                var newObj = {
                    id: obj.id,
                    label: obj.title,
                    value: obj.title,
                };
                newArray.push(newObj);
            });
            setLoader(false);
            setValues({
                ...values,
                image: data && data.image ? data.image : null,
                hospital: newArray ? newArray : [],
                name: data && data.name ? data.name : "",
                email: data && data.email ? data.email : "",
                phoneNo: data && data.phonenumber ? data.phonenumber : "",
                employeeId: data && data.emp_id ? data.emp_id : "",
                role: data && data.role ? { id: data.role, label: data.role_title } : "",
                gender: data && data.gender ? { id: data.gender, label: data.gender_title } : "",
                bloodGroup: data && data.blood_group ? { id: data.blood_group, label: data.blood_group_title, } : "",
                dob: data && data.dob ? data.dob : "",
                joiningDate: data && data.joining_date ? data.joining_date : "",
                department: data && data.department ? { id: data.department, label: data.department_title, } : "",
                specialization: data && data.specialization ? data.specialization : "",
                state: data && data.state ? { id: data.state, label: data.state_title } : "",
                city: data && data.city ? { id: data.city, label: data.city_title } : "",
                address: data && data.address ? data.address : "",
            });
            getcities({ id: data && data.state })
        } else {
            setLoader(false);
        }
    };

    // <<-----------------------------------------Date picker------------------------------------------------------------------
    const handleClick = () => {
        if (!focus) {
            if (inputCal.current) {
                inputCal.current.showPicker();
            }
        };
    };

    const handleClickDob = () => {
        if (!focusDob) {
            if (inputCalDob.current) {
                inputCalDob.current.showPicker();
            }
        }
    };

    return {
        values,
        setValues,
        isError,
        loader,
        handleChange,
        getHospitalListing,
        hospitalsListing,
        getcities,
        citiesList,
        getstate,
        stateList,
        getGender,
        genderList,
        getbloodGroup,
        bloodgrpList,
        departmentList,
        getdepartment,
        getRoleList,
        roleList,
        createAdmin,
        getPermissionsListing,
        permissions,
        handleCheck,
        selectAll,
        superAdmin,
        isAllCheckedSelected,
        handleAllSelect,
        handleSuperAdminSelect,
        checkAllpermissionsCheckedValue,
        handleBreadcrumDataNaviagtion,
        getStaffDetails,
        loaction,
        breadcrumData,
        breadcrumItem,
        setbreadcrumItem,
        setCheckRoleChangeOnEditCase,
        setCheckAllFieldsFilled,
        checkAllFieldsFilled,
        validationFromDrawerEditProfile,
        setValidationFromdrawerEditProfile,
        setValues,
        handleClick,
        focus,
        setFocus,
        inputCal,
        handleClickDob,
        focusDob,
        setFocusDob,
        inputCalDob,
        setSuperAdmin
    };
};

export default AddStafFunction;
