import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import commentsController from "../../../../apis/Controllers/CommentsController/comments.controler";
import taskController from "../../../../apis/Controllers/TaskController/task.controller";
import { Toaster } from "../../../Helper/toaster/Toaster";
import Validation from "../../../Helper/validations/vaildation";
import store from "../../../../redux/store";
import permissionsController from "../../../../apis/Controllers/PermissionsController/permissions.contoller";


const TaskDetailFunctions = () => {
    const [breadcrumData, setBreadcrumData] = useState(["Dashboard", "Task list"]);
    const [breadcrumItem, setbreadcrumItem] = useState("View Task");
    const [checkPermissions, setCheckPermissions] = useState(null);
    const [taskData, setTaskData] = useState(null);
    const [loader, setLoader] = useState(false);
    const defaultValues = {
        comment: "",
    };

    const defaultErrors = {
        comment: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
    };

    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);
    const [showAssignedModal, setShowAssignedModal] = useState(false);
    const [completeConfirmationModal, setcompleteConfirmationModal] = useState(false);
    const loaction = useLocation();
    let navigate = useNavigate();


    const getTaskDetail = async (id) => {
        setLoader(true);
        let response = await taskController.taskDetail(id);
        if (response && response.status) {
            setTaskData(response.data);
            setLoader(false);
        }
        else {
            setTaskData(null);
            setLoader(false);
        }
    };


    const handleChange = (field, value) => {
        let validation = new Validation(isError);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };


    const handleSubmitComment = async () => {
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            setLoader(true);
            let response = await commentsController.commentsCreate(taskData, values.comment);
            if (response && response.status) {
                let id = loaction && loaction.state && loaction.state.id
                getTaskDetail(id);
                setValues(defaultValues);
                new Toaster().success(response.message);
                setLoader(false);
            } else {
                setLoader(false);
            }
        } else {
            setError({ ...isValid.errors });
        }
    };


    const handleTaskCompleted = async () => {
        setLoader(true);
        let id = taskData && taskData.id ? taskData.id : ''
        let response = await taskController.taskComplete(id);
        if (response && response.status) {
            new Toaster().success(response.message);
            setLoader(false);
            getTaskDetail(id);
            setcompleteConfirmationModal(false);
        }
        else {
            setcompleteConfirmationModal(false);
            setLoader(false);
        }
    };


    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate('/dashboard');
            }
            else {
                navigate('/dashboard-superadmin');
            }
        }
        else if (i === 1) {
            if (loaction && loaction.state && loaction.state.name === 'subAdminTaskList') {
                navigate('/myTasks');
            }
            else {
                navigate('/task-list');
            }
        }
        else return;
    };


    const handleAssignByNavigation = () => {
        // if (taskData && parseInt(taskData.user_type) === 2) {
        if (taskData.created_by) {
            navigate(`/staff-detail/${taskData.created_by}`, { state: {  fromNavigation: true } })
        } else return;
        // }
        // else return;
    };

    const getModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('task');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckPermissions(data);
        }
        else {
            setCheckPermissions(null);
        }
    };


    return {
        getModulePermissions,
        checkPermissions,
        getTaskDetail,
        taskData,
        loader,
        loaction,
        navigate,
        values,
        setValues,
        isError,
        setError,
        handleSubmitComment,
        handleChange,
        defaultValues,
        handleTaskCompleted,
        showAssignedModal,
        setShowAssignedModal,
        completeConfirmationModal,
        setcompleteConfirmationModal,
        handleBreadcrumDataNaviagtion,
        breadcrumData,
        breadcrumItem,
        setbreadcrumItem,
        handleAssignByNavigation,
        setBreadcrumData
    }
};


export default TaskDetailFunctions;