import { Toaster } from "../../../components/Helper/toaster/Toaster";
import { setUserData } from "../../../redux/actions/users";
import store from "../../../redux/store";
import { adminServices } from "../../Services/AdminServices/admin.services";

const adminListing = async (params) => {
    let response = await adminServices.adminListing(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const adminCreate = async (data, permissions) => {
    const newArray = permissions.map((obj) => ({
        slug: obj.type,
        listing: parseInt(obj.permissions[0].value),
        create: parseInt(obj.permissions[1].value),
        update: parseInt(obj.permissions[2].value),
        delete: parseInt(obj.permissions[3].value),
    }));

    let post = {
        image: data && data.image ? data.image : null,
        hospital:
            data && data.hospital && data.hospital.id ? [data.hospital.id] : [],
        name: data && data.name ? data.name : null,
        password: data && data.password ? data.password : null,
        phonenumber: data && data.phoneNo ? data.phoneNo : null,
        email: data && data.email ? data.email : null,
        emp_id: data && data.employeeId ? data.employeeId : null,
        role: data && data.role && data.role.id ? data.role.id : null,
        gender: data && data.gender && data.gender.id ? data.gender.id : null,
        blood_group:
            data && data.bloodGroup && data.bloodGroup.id
                ? data.bloodGroup.id
                : null,
        dob: data && data.dob ? data.dob : null,
        joining_date: data && data.joiningDate ? data.joiningDate : null,
        department:
            data && data.department && data.department.id
                ? data.department.id
                : null,
        specialization:
            data && data.specialization ? data.specialization : null,
        state: data && data.state && data.state.id ? data.state.id : null,
        city: data && data.city && data.city.id ? data.city.id : null,
        address: data && data.address ? data.address : null,
        is_super_admin: data && data.is_super_admin ? data.is_super_admin : 0,
        permissions: newArray,
    };
    let response = await adminServices.adminCreate(post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const adminDetail = async (id) => {
    let response = await adminServices.adminDetail(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const adminUpdate = async (value, data, id, validationFromDrawerEditProfile) => {
    const newArray = value.map((obj) => ({
        slug: obj.type,
        listing: parseInt(obj.permissions[0].value),
        create: parseInt(obj.permissions[1].value),
        update: parseInt(obj.permissions[2].value),
        delete: parseInt(obj.permissions[3].value),
    }));
   
    var hospitalArray = data && data.hospital.map(function(obj) {
        return obj.id;
    });

    let post = {
        image: data && data.image ? data.image : null,
        hospital: hospitalArray ? hospitalArray : [],
        name: data && data.name ? data.name : null,
        phonenumber: data && data.phoneNo ? data.phoneNo : null,
        email: data && data.email ? data.email : null,
        emp_id: data && data.employeeId ? data.employeeId : null,
        role: data && data.role && data.role.id ? data.role.id : null,
        gender: data && data.gender && data.gender.id ? data.gender.id : null,
        blood_group: data && data.bloodGroup && data.bloodGroup.id ? data.bloodGroup.id : null,
        dob: data && data.dob ? data.dob : null,
        joining_date: data && data.joiningDate ? data.joiningDate : null,
        department: data && data.department && data.department.id ? data.department.id : null,
        specialization: data && data.specialization ? data.specialization : null,
        state: data && data.state && data.state.id ? data.state.id : null,
        city: data && data.city && data.city.id ? data.city.id : null,
        address: data && data.address ? data.address : null,
        is_super_admin: data && data.is_super_admin ? data.is_super_admin : 0,
        permissions: newArray,
    };
    let response = await adminServices.adminUpdate(post, id);
    if (response && response.status) {
        if (validationFromDrawerEditProfile) {
            setUpLogin(response.data);
            return response;
        }
        else {
            return response;
        }
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const adminDelete = async (id) => {
    let response = await adminServices.adminDelete(id);
    if (response) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const updateAdminStatus = async (id, params) => {
    let response = await adminServices.updateAdminStatus(id, params);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const adminListingWithDepartment = async (params) => {
    let response = await adminServices.adminListingWithDepartment(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const allAdminsList = async (params) => {
    let response = await adminServices.allAdminsList(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const getSuperAdminsListing = async (params) => {
    let response = await adminServices.superAdminsList(params);
    if (response && response.status) {
        return response;
    } else {
        // new Toaster().error(response.error);
        return null;
    }
};


const updateSuperAdmin = async (id, data) => {
    let post = {
        image: data && data.image ? data.image : null,
        name: data && data.name ? data.name : null,
        email: data && data.email ? data.email : null,
        phonenumber: data && data.phonenumber ? data.phonenumber : null,
        address: data && data.address ? data.address : null,
    }
    let response = await adminServices.updateSuperAdmin(id, post);
    if (response && response.status) {
        setUpLogin(response.data);
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const setUpLogin = async (user) => {
    await localStorage.setItem('SET_USER_DATA', JSON.stringify(user));
    await store.dispatch(setUserData(user));
    return user;
};

const allAdminsTaskList = async (params) => {
    let response = await adminServices.allAdminsTaskList(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const consultantListing = async (params) => {
    let response = await adminServices.consultantListing(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const adminController = {
    adminListing,
    adminCreate,
    adminDetail,
    adminUpdate,
    adminDelete,
    updateAdminStatus,
    adminListingWithDepartment,
    getSuperAdminsListing,
    updateSuperAdmin,
    allAdminsList,
    allAdminsTaskList,
    consultantListing
};

export default adminController;
