import keyMirror from "fbjs/lib/keyMirror";

const ActionTypes = keyMirror({
    SET_USER_DATA: "SET_USER_DATA",
    SET_API_TOKEN: "SET_API_TOKEN",
    SET_SCROLLER: "SET_SCROLLER",
    SET_TASK_LIST_FILTERS: "SET_TASK_LIST_FILTERS",
    SET_DRAWER: "SET_DRAWER",
    SET_HOSPITAL: "SET_HOSPITAL",
    SET_STAFF_LIST_FILTERS: "SET_STAFF_LIST_FILTERS",
    SET_PATIENT_LIST_FILTERS : 'SET_PATIENT_LIST_FILTERS',
    SET_USER_PERMISSION : 'SET_USER_PERMISSION',
    SET_WARD_LIST_FILETRS : 'SET_WARD_LIST_FILETRS',
    SET_DEPARTMENT_LIST_FILETRS: 'SET_DEPARTMENT_LIST_FILETRS',
    SET_ROOM_LIST_FILTERS: 'SET_ROOM_LIST_FILTERS',
    SET_BED_LIST_FILTERS:'SET_BED_LIST_FILTERS'
});

export default ActionTypes;
