import { useState } from "react";
import wardController from "../../../../apis/Controllers/WardController/ward.controller";
import { Toaster } from "../../../Helper/toaster/Toaster";
import store from "../../../../redux/store";
import { useEffect } from "react";



const ExistPatientListFilterFunctions = (props) => {
    let storedFilters = store.getState().PatientListFilters.filters;

    const defaultFilters = {
        patientStatus: storedFilters && storedFilters.patientStatus ? storedFilters.patientStatus : [],
        wards: storedFilters && storedFilters.wards ? storedFilters.wards : [],
        admissionFromDate: storedFilters && storedFilters.admissionFromDate ? storedFilters.admissionFromDate : null,
        admissionToDate: storedFilters && storedFilters.admissionToDate ? storedFilters.admissionToDate : null
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [wardsList, setWardsList] = useState([]);
    const [patientStatusList, setPatientStatusList] = useState([
        {
            title: "Admitted",
            value: "admitted",
            checked: false,
            id: 1,
        },
        {
            title: "Under Treatment",
            value: "under_treatment",
            checked: false,
            id: 2,
        },
        {
            title: "Critical",
            value: "critical",
            checked: false,
            id: 3,
        },
        {
            title: "Discharge",
            value: "discharge",
            checked: false,
            id: 4,
        },
    ]);


    useEffect(() => {
        if (storedFilters && storedFilters.patientStatus && storedFilters.patientStatus.length > 0) {
            reSetValuesOfPatientStatusList(storedFilters.patientStatus);
        }
    }, [storedFilters]);


    const reSetValuesOfPatientStatusList = (appliedStatus) => {
        let array = [...patientStatusList]
        if (appliedStatus) {
            const appliedPatientStatus = array.filter(({ value: listValue }) => appliedStatus.some((item) => listValue === item));
            array.forEach(a => {
                appliedPatientStatus.forEach(b => {
                    if (parseInt(a.id) === parseInt(b.id)) {
                        a.checked = true
                    }
                })
            });
            setPatientStatusList(array)
        }
    };


    const getWardsListing = async () => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        let post = {
            hospital_id: item ? [item.id] : [],
        };
        let response = await wardController.wardListing(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            if (storedFilters && storedFilters.wards && storedFilters.wards.length > 0) {
                let list = storedFilters.wards
                const appliedWards = data.filter(({ id: wardId }) => list.some((item) => wardId === item));
                data.forEach(a => {
                    appliedWards.forEach(b => {
                        if (parseInt(a.id) === parseInt(b.id)) {
                            a.checked = true
                        }
                    })
                });
                setWardsList(data);
            }
            else {
                setWardsList(data);
            }
        }
        else {
            setWardsList([]);
        }
    };


    const handlePatientListFilters = (item) => {
        let array = [...patientStatusList]
        let patientStatus = [...filters.patientStatus]
        if (item) {
            //<----------------------------------patient list checked value---------------------
            let index = array.findIndex((data) => parseInt(data.id) === parseInt(item.id));
            if (index !== -1) {
                let check = array[index].checked
                array[index].checked = !check
            }
            setPatientStatusList(array);
            //<---------------------------------Filter value------------------------------------
            let checkIfExist = patientStatus.findIndex((value) => value === item.value);
            if (checkIfExist === -1) {
                patientStatus.push(item.value);
            }
            else {
                patientStatus.splice(checkIfExist, 1);
            }
            setFilters({
                ...filters,
                patientStatus: patientStatus
            })
        }
    };


    const handleWardsSearch = (e) => {
        let array = [...wardsList]
        let value = e.target.value
        if (value.length > 0) {
            let data = array.filter((item) => item.title.toLowerCase().indexOf(value.toLowerCase()) > -1);
            setWardsList(data);
        }
        else {
            getWardsListing();
        }
    };


    const handleWardsFilters = (item) => {
        let array = [...wardsList]
        let filterValue = [...filters.wards]
        if (item) {
            //<----------------------------------ward list checked value---------------------
            let index = array.findIndex((data) => parseInt(data.id) === parseInt(item.id))
            if (index !== -1) {
                let check = array[index].checked
                array[index].checked = !check
            }
            setWardsList(array);

            //<---------------------------------Filter value------------------------------------
            let checkIfExist = filterValue.findIndex((id) => parseInt(id) === parseInt(item.id));
            if (checkIfExist === -1) {
                filterValue.push(item.id);
            }
            else {
                filterValue.splice(checkIfExist, 1);
            }
            setFilters({
                ...filters,
                wards: filterValue
            })
        }
    };


    const applyFilters = () => {
        if (filters && filters.admissionFromDate && filters.admissionToDate === null) {
            new Toaster().error('Please select admission to date');
        }
        else if (filters && filters.admissionToDate && filters.admissionFromDate === null) {
            new Toaster().error('Please select admission start date');
        }
        else if (filters && filters.admissionFromDate && filters.admissionFromDate > filters.admissionToDate) {
            new Toaster().error('To date is greater than from date');
        }
        else {
            props.applyFilters(filters);
        }
    };


    const handleClearAllFilters = () => {
        props.handleClearAllFilters();
        props.close();
    };


    return {
        wardsList,
        getWardsListing,
        patientStatusList,
        handlePatientListFilters,
        handleWardsFilters,
        handleWardsSearch,
        setFilters,
        filters,
        applyFilters,
        handleClearAllFilters
    }
};

export default ExistPatientListFilterFunctions;