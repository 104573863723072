import { setBedListFiltes, setDepartmentListFilters, setPatientListFilters, setRoomListFiltes, setStaffListFiltes, setTaskListFiltes, setWardListFilters } from "../../../redux/actions/users";
import store from "../../../redux/store";


const setTaskListFilters = async (data) => {
    await localStorage.setItem("SET_TASK_LIST_FILTERS", JSON.stringify(data));
    await store.dispatch(setTaskListFiltes(data));
};


const clearTaskListFilters = async (item) => {
    await localStorage.setItem("SET_TASK_LIST_FILTERS", JSON.stringify(""));
    await store.dispatch(
        setTaskListFiltes({
            assignBy: [],
            wards: [],
            hospitals: item && item.id ? [item.id] : [],
            taskPriorities: null,
            taskStatus: null,
        })
    );
};

const setRoomListFilters = async (data) => {
    await localStorage.setItem("SET_ROOM_LIST_FILTERS", JSON.stringify(data));
    await store.dispatch(setRoomListFiltes(data));
};


const clearRoomListFilters = async (item) => {
    await localStorage.setItem("SET_ROOM_LIST_FILTERS", JSON.stringify(""));
    await store.dispatch(
        setRoomListFiltes({
            wards: [],
            hospitals: item && item.id ? [item.id] : [],
            sortByAlpha:null
        })
    );
};

const setBedListFilters = async (data) => {
    await localStorage.setItem("SET_BED_LIST_FILTERS", JSON.stringify(data));
    await store.dispatch(setBedListFiltes(data));
};


const clearBedListFilters = async (item) => {
    await localStorage.setItem("SET_BED_LIST_FILTERS", JSON.stringify(""));
    await store.dispatch(
        setBedListFiltes({
            wards: [],
            hospitals: item && item.id ? [item.id] : [],
            rooms: [],
            sortByAlpha: null
        })
    );
};


const setStaffListFilters = async (data) => {
    await localStorage.setItem("SET_STAFF_LIST_FILTERS", JSON.stringify(data));
    await store.dispatch(setStaffListFiltes(data));
};


const clearStaffListFilters = async (item) => {
    await localStorage.setItem("SET_STAFF_LIST_FILTERS", JSON.stringify(""));
    await store.dispatch(
        setStaffListFiltes({
            department: [],
            role: [],
            hospitals: item && item.id ? [item.id] : [],
            adminStatus: null,
        })
    );
};


const setPatientListFilter = async (data) => {
    await localStorage.setItem("SET_PATIENT_LIST_FILTERS", JSON.stringify(data));
    await store.dispatch(setPatientListFilters(data));
};


const clearPatientListFilters = async (item) => {
    await localStorage.setItem("SET_PATIENT_LIST_FILTERS", JSON.stringify(""));
    await store.dispatch(
        setPatientListFilters({
            patientStatus: [],
            wards: [],
            hospitals: item && item.id ? [item.id] : [],
            admissionFromDate: null,
            admissionToDate: null
        })
    );
};


const setWardListFilter = async (data) => {
    await localStorage.setItem("SET_WARD_LIST_FILETRS", JSON.stringify(data));
    await store.dispatch(setWardListFilters(data));
};


const clearWardListFilters = async (item) => {
    await localStorage.setItem("SET_WARD_LIST_FILETRS", JSON.stringify(""));
    await store.dispatch(
        setWardListFilters({
            wardStatus: null,
            sortByAlpha: null,
            hospitals: item && item.id ? [item.id] : [],
        })
    );
};


const setDepartmentListFilter = async (data) => {
    await localStorage.setItem("SET_DEPARTMENT_LIST_FILETRS", JSON.stringify(data));
    await store.dispatch(setDepartmentListFilters(data));
};


const clearDepartmentListFilters = async (item) => {
    await localStorage.setItem("SET_DEPARTMENT_LIST_FILETRS", JSON.stringify(""));
    await store.dispatch(
        setDepartmentListFilters({
            depatmentStatus: null,
            sortByAlpha: null,
            hospitals: item && item.id ? [item.id] : [],
        })
    ); 
};


export const filtersController = {
    setTaskListFilters,
    clearTaskListFilters,
    setStaffListFilters,
    clearStaffListFilters,
    setPatientListFilter,
    clearPatientListFilters,
    setWardListFilter,
    clearWardListFilters,
    setDepartmentListFilter,
    clearDepartmentListFilters,
    setRoomListFilters,
    clearRoomListFilters,
    setBedListFilters,
    clearBedListFilters
};
