//----------- REACT IMPORTS ------------//
import React, { useEffect } from "react";
//------ BOOTSTAP ITEMS IMPORTS -------//
import { Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
//----------- IMAGE IMPORTS -----------//
import { SplashLogo } from "../../../../assets/images";
import AuthController from "../../../../apis/Controllers/AuthController/AuthController";

function SplashOne() {

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            initSplash()
        }, 3000);
        return () => {
            clearTimeout(timer);
        };
        // setTimeout(() => {
        //     // 👇 Redirects to about page, note the `replace: true`
        //     // navigate('/welcome', { replace: true });
        // }, 3000);
    }, []);

    const initSplash = async () => {
        let user = await AuthController.getLoginUser();
        if (user) {
            if (parseInt(user && user.user_type) === 1) {
                navigate("/dashboard-superadmin", { replace: true });
            }
            else if (parseInt(user && user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/login");
            }
        }
        else {
            navigate('/welcome', { replace: true });
        }
    };

    return (
        <div className="splash_screen_one">
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="splash_area_one">
                            <div className="splash_img">
                                <Image src={SplashLogo} alt="..." />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SplashOne;
