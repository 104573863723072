import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function testTypeList(params) {
    return mainWrapper.get(WebConstant.host + `/test_types`, params);
};

function testNameList(params) {
    return mainWrapper.get(WebConstant.host + `/lab_test_list`, params);
}

function testCategoryList(params) {
    return mainWrapper.get(WebConstant.host + `/lab_test_category_list`, params);
}

function subCategoryList(params) {
    return mainWrapper.get(WebConstant.host + `/lab_test_category_sub_list`, params);
}

function patientLabTestList(params) {
    return mainWrapper.get(WebConstant.host + `/patient_lab_test_list`, params);
}

function patientLabTest(params) {
    return mainWrapper.post(WebConstant.host + `/patient_lab_tests`, params);
}

function patientLabTestDetails(id) {
    return mainWrapper.get(WebConstant.host + `/patient_lab_test_details/${id}`);
}

function deletePatientLabTest(id) {
    return mainWrapper._delete(WebConstant.host + `/delete_patient_lab_tests/${id}`);
}

function editpatientLabTest(id, params) {
    return mainWrapper.put(WebConstant.host + `/update_patient_lab_tests/${id}`, params);
}

export const labServices = {
    testTypeList,
    testNameList,
    testCategoryList,
    subCategoryList,
    patientLabTestList,
    patientLabTest,
    deletePatientLabTest,
    patientLabTestDetails,
    editpatientLabTest
};
