import NoDataComponent from "../../CommanComponents/NoDataComponent";

const TaskList = (props) => {
    const {
        List,
        keyword,
        navigate
    } = props


    const handleAssinedMembers = (item) => {
        if (item && item.assigned_members && item.assigned_members.length > 0) {
            let members = item.assigned_members.map((data) => data.name).join(',')
            return members
        }
        else return;
    };


    return (
        <>
            {
                List && List.length > 0 ?
                    <div className="task_list_screen" >
                        <div className="list_area" >
                            {
                                List.map((item, i) => (
                                    <div className="item active" key={i}>
                                        <h4 onClick={() => navigate('/myTaskDetails', { state: { id: item.id,fromNavigation: true } })} style={{ cursor: 'pointer' }}>{item.title ? item.title : ''}</h4>
                                        <div className="left_area">
                                            <p>Assigned to :</p>
                                            <div className="name_tag_area">
                                                <p>{handleAssinedMembers(item)}</p>
                                                {/* <p className="tag">{item}</p> */}
                                            </div>
                                        </div>
                                        {
                                            item.patient_name ?
                                                <div className="left_area">
                                                    <p>Patinet Name :</p>
                                                    <div className="name_tag_area" >
                                                        <p>{item.patient_name}</p>
                                                    </div>
                                                    {
                                                        item.uh_id ?
                                                            <div className="name_tag_area">
                                                                <p>{item.uh_id}</p>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                :
                                                null
                                        }
                                        <div className="right_area">
                                            <p>Assigned by :</p>
                                            <div className="name_tag_area">
                                                <p>{item.assign_by}</p>
                                                {
                                                    item.assign_by_role ?
                                                        <p className="tag">{item.assign_by_role}</p>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>

                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    :
                    keyword ?
                        <NoDataComponent
                            message="No Task Found!"
                        />
                        :
                        null
            }
        </>
    )
}

export default TaskList;