import { Image } from "react-bootstrap";
import { No_Data } from "../../../../assets/images";

function NoDataComponent(props) {
    return (
        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
            <div>
                <p style={{ fontSize: 20, color: "#111111", fontWeight: 600, textAlign: 'center', marginBottom: 20, }}>{props.message ? props.message : 'No Data Found!'}</p>
                <div style={{ width: '75%', margin: '0 auto' }}>
                    <Image src={props.image ? props.image : No_Data} alt="..." style={{ height: '100%', width: '100%', }} />
                </div>
            </div>
        </div>
    );
}

export default NoDataComponent;