import React, { useEffect } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import * as Constants from "../../../Helper/constants/constants";
import Loader from "../../../Helper/loader";
import WithoutImageHeader from "../../../Helper/withoutImageHeader";
import NoDataComponent from "../../CommanComponents/NoDataComponent";
import AdminDashbordFunctons from "./functions";
import { faAngleDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import hospitalController from "../../../../apis/Controllers/HospitalController/hospital.controller";

function Dashboard() {
    const {
        getModulePermissions,
        getExistPatinetsPermissions,
        checkPatientModulePermissions,
        checExistkPatientsPermissions,
        setWardItemData,
        wardList,
        getWardsList,
        getHospitalList,
        hospitalList,
        dropdownValue,
        setDropdownValue,
        navigate,
        hospitalId,
        setHospitalId,
        loader,
        setLoader,
        breadcrumData,
        breadcrumItem,
        handleNavigate,
        hospitalTitle,
        setHospitalTitle,
        handleDrawer
    } = AdminDashbordFunctons();

    useEffect(() => {
        if (!loader) {
            getHospitalList();
            getModulePermissions();
            getExistPatinetsPermissions();
        }
    }, [hospitalTitle]);

    // useEffect(() => {
    //     if (hospitalId) {
    //         getWardsList();
    //     }
    // }, [hospitalId]);

    let data = localStorage.getItem('SET_HOSPITAL');
    const hospitalData = JSON.parse(data)
    return (
        <div className="main_dashboard_screen">
            {
                <WithoutImageHeader
                    header={Constants.DASHBOARD}
                    breadcrumData={breadcrumData}
                    breadcrumItem={breadcrumItem}
                    hospitalList={hospitalList}
                    setDropdownValue={setDropdownValue}
                    dropdownValue={dropdownValue}
                    setHospitalId={setHospitalId}
                    lefticon='menu'
                    icon='search_bell'
                />
            }
            {/* <DrawerComponent /> */}
            <Container onClick={() => handleDrawer()}>
                {/* {<DashboardHeader/>} */}
                <div className="dashboard_area">
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div className="logo_main_area">
                                {/* {
                                    checExistkPatientsPermissions
                                        && checExistkPatientsPermissions.permissions
                                        && checExistkPatientsPermissions.permissions.length > 0
                                        && parseInt(checExistkPatientsPermissions.permissions[0].value) === 1 ?
                                        <div className="text_btn_area" onClick={() => navigate('/existing-patient')}>
                                            <div className="right_btn_area">
                                                <p className="btn btn-primary" onClick={() => navigate('/existing-patient')}>Existing Patient</p>
                                            </div>
                                        </div>
                                        :
                                        null
                                } */}
                                <div className="right_area">
                                    {hospitalList && hospitalList.length > 0
                                        ?
                                        <div className="dropdown_area">
                                            <Dropdown>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    {hospitalData && hospitalData.title ? hospitalData.title : "Oxford Hospital"}
                                                    <div className="icon down_icon ms-4">
                                                        <FontAwesomeIcon
                                                            icon={faAngleDown}
                                                        />
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {hospitalList && hospitalList.length > 0 ?
                                                        hospitalList.map((item, index) => (
                                                            <Dropdown.Item key={index} id={index}
                                                                onClick={(e) => { e.preventDefault(); hospitalController.setUpHospital(item); setHospitalTitle(item) }}>
                                                                {item.title}
                                                                {
                                                                    hospitalData && hospitalData.title === item.title ?
                                                                        <FontAwesomeIcon
                                                                            style={{ float: 'right' }}
                                                                            icon={faCheck}
                                                                        />
                                                                        :
                                                                        null
                                                                }
                                                            </Dropdown.Item>)) : null}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div> :
                                        null}
                                </div>
                            </div>
                            <div className="inner_dashboard_area">
                                <div className="ward_view_page">
                                    <div className="inner_ward">
                                        <div className="top_head">
                                            <h4>Ward</h4>
                                        </div>
                                        <div className="ward_listing">
                                            {loader ? <Loader
                                                loader={loader}
                                            /> : <Row>
                                                {
                                                    !loader && wardList.length > 0 ?
                                                        wardList.map((item, i) => {
                                                            return (
                                                                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} key={i}>
                                                                    <div className="list_view"
                                                                        onClick={() => navigate('/patient-list', { state: { item: item } })}
                                                                    >
                                                                        <p style={{ textTransform: 'capitalize' }}>{item.title}</p>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })
                                                        :
                                                        <NoDataComponent
                                                            message={'No wards found'}
                                                        />
                                                }
                                            </Row>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                checExistkPatientsPermissions
                                    && checExistkPatientsPermissions.permissions
                                    && checExistkPatientsPermissions.permissions.length > 0
                                    && parseInt(checExistkPatientsPermissions.permissions[0].value) === 1 ?
                                    <div className="text_btn_area" onClick={() => navigate('/existing-patient')}>
                                        <div className="right_btn_area">
                                            <p className="btn btn-primary" onClick={() => navigate('/existing-patient')}>Discharged Patients</p>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default Dashboard;