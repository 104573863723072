import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AddCommentModalFunctions } from "./functions";
import { isEmpty } from "../../../Helper/General";

const MedicineCommentModel = (props) => {
    const {
        values,
        setValues,
        isError,
        loader,
        handleChange,
        handleSubmit
    } = AddCommentModalFunctions(props);

    useEffect(() => {

        if (!isEmpty(props && props.medicineDataForCommentApi && props && props.medicineDataForCommentApi.comment)) {
            setValues({
                comment: props && props.medicineDataForCommentApi && props && props.medicineDataForCommentApi.comment ? props && props.medicineDataForCommentApi.comment : ''
            });
        }
    }, [props && props.medicineDataForCommentApi]);

    return (
        <div>
            <Modal
                show={props.show} onHide={props.close}
                centered className="add_report_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add your comment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="report_details_form">
                        <Form>
                            <Form.Group className="form_group" controlId="description">
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Enter your Comment here"
                                    rows={4}
                                    value={values.comment}
                                    className={isError.comment.message
                                        ? "error_border"
                                        : ""}
                                    onChange={(e) =>
                                        handleChange("comment", e.target.value)
                                    }
                                />
                                {isError.comment.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.comment.message}
                                    </p>
                                ) : null}
                            </Form.Group>
                            <div className="btn_area">
                                <Button
                                    className="cancelBtn"
                                    type="button"
                                    onClick={props.close}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="submitBtn"
                                    type="submit"
                                    onClick={(e) => (e.preventDefault(), handleSubmit())}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default MedicineCommentModel