import React, { useEffect } from "react";
import { Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import * as Constants from "../../../../Helper/constants/constants";
import Loader from "../../../../Helper/loader";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import RoleDetailFunctions from "./functions";


const RoleDetail = () => {

    const {
        values,
        breadcrumItem,
        setbreadcrumItem,
        getRoleDetail,
        loader,
        breadcrumData,
        loaction,
        navigate,
        roleDetailPermissions,
        selectAll,
        superAdmin,
        permissonsArrayList,
        getPermissionsListing,
        handleBreadcrumDataNaviagtion
    } = RoleDetailFunctions();


    useEffect(() => {
        if (loaction && loaction.state && loaction.state.name) {
            let id = loaction && loaction.state && loaction.state.id ? loaction.state.id : ''
            setbreadcrumItem(loaction.state.name);
            getPermissionsListing(id);
        }
        else {
            navigate(-1);
        }
    }, []);


    useEffect(() => {                                                               // <-------------------For added not exist roles module
        if (loaction && loaction.state && loaction.state.name) {
            if (permissonsArrayList && permissonsArrayList.length > 0) {
                let id = loaction && loaction.state && loaction.state.id ? loaction.state.id : ''
                getRoleDetail(id);
            }
        }
    }, [permissonsArrayList]);


    return (
        <div className="add_role_screen">
            <WithoutImageHeader
                header={Constants.VIEW_ROLE}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBack={() => navigate(-1)}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
            />
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        {
                            loader ?
                                <Loader
                                    loader={loader}
                                />
                                : <div className="add_role_details">
                                    <>
                                        <div className="role_area_title">
                                            <div className="role_area">
                                                <p>Role<span>*</span></p>
                                            </div>
                                        </div>
                                        <div className="search_section">
                                            <div className="search_area">
                                                <Form>
                                                    <Form.Group className="form-group">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="ex: Doctor"
                                                            value={values.role}
                                                            disabled={true}
                                                        />
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                        </div>
                                        <div className="title_btn_area">
                                            <div className="left_area">
                                                <Form.Check
                                                    reverse
                                                    type="checkbox"
                                                    id="Critical"
                                                    label="User is Super Admin"
                                                    checked={superAdmin}
                                                />
                                            </div>
                                            {/* <div className="right_area">
                                                <Form.Check
                                                    reverse
                                                    type="checkbox"
                                                    id="Critical"
                                                    label="Select all"
                                                    checked={selectAll}
                                                />
                                            </div> */}
                                        </div>
                                        {
                                            roleDetailPermissions && roleDetailPermissions.length > 0 ?
                                                <div className="list_detail">
                                                    <div className="list_data">
                                                        <Card>
                                                            <Card.Body>
                                                                <h4>User Permission </h4>
                                                                <Table responsive>
                                                                    <tr>
                                                                        <th>
                                                                            <ul className="list-inline">
                                                                                <li >Module</li>
                                                                                <li className="list-gap">Listing</li>
                                                                                <li className="list-gap">Create</li>
                                                                                <li className="list-gap">Update</li>
                                                                                <li className="list-gap">Delete</li>
                                                                            </ul>
                                                                        </th>
                                                                    </tr>
                                                                    {
                                                                        roleDetailPermissions.map((item, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>
                                                                                        <ul className="list-inline">
                                                                                            <li>{item.title ? item.title : ''}</li>
                                                                                            {
                                                                                                item.permissions.map((data, i) => {
                                                                                                    return (
                                                                                                        <li className="list-gap" key={i}>
                                                                                                            <Form.Check
                                                                                                                
                                                                                                                type="checkbox"
                                                                                                                id="Critical"
                                                                                                                checked={parseInt(data.value) === 1 ? true : false}
                                                                                                                className="check_icon"
                                                                                                            />
                                                                                                        </li>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </ul>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </Table>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </>
                                </div>}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default RoleDetail;