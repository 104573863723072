import React, { useRef, useState } from "react";
import { Form, Image, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import WebConstant from "../../apis/webConstants";
import { renderImage, renderNoAvatar } from "./General";
import ImageUploadController from "../../apis/Controllers/ImageController";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { EditProfile1, Hospital_Img, Man, Patient } from "../../assets/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function UploadImageBox(props) {
    const inputFile = useRef(null);
    const [isLoading, setLoader] = useState(false);
    const [progress, setProgress] = React.useState(0);
    const [isError, setIsError] = useState(null);

    const handleImage = async (value) => {
        setProgress(0);
        if (props && props.fromSuperAdminUpdateProfile ? ["image/jpeg", "image/png",].includes(value.type) : ["image/jpeg", "image/png", "image/gif", "image/webp", "image/jfif", "application/pdf",].includes(value.type)) {
            if (value.size < WebConstant.maxFileSize) {
                setIsError(null);
                if (isLoading === false) {
                    setLoader(true);
                    setProgress(0.5);
                    let callback = (p) => {
                        // setProgress(p > 100 ? p * 1 - 5 : p);
                        setProgress(p > 100 ? 100 : p);
                    };
                    let response = await ImageUploadController.uploadImage(value, callback);
                    setLoader(false);
                    if (response && response.status) {
                        setProgress(Math.round(progress * 100));
                        setProgress(0);
                        props.onResponse(response);
                    } else {
                        setProgress(0);
                        setIsError(response.message);
                    }
                }
            } else {
                setIsError("File size exceeds the maximum limit of 5MB.");
            }
        } else {
            props && props.fromSuperAdminUpdateProfile ?
                setIsError(
                    "Please upload file having extension jpg, jpeg or png"
                )
                :
                setIsError(
                    "Please upload file having extension jpg, jpeg, png, gif, webp or pdf."
                );
        }
    };

    return (
        <>
            <div className="img_area_profile">
                {/* <img src={props.image && props.image.small ? renderImage(props.image.small) : renderNoAvatar(props.name)} /> */}
                <div className="img_icon_area">
                    <div className="img_area">
                        <Image
                            src={
                                props && props.image
                                    ? renderImage(props.image)
                                    : props.name == "admin"
                                        ? Man
                                        : props.name == 'Patient'
                                            ? Patient
                                            : props.name == 'superAdmin'
                                                ? Man
                                                : Hospital_Img
                            }
                            alt="..."
                        />
                    </div>
                    <div
                        className="icon_area"
                        onClick={() => inputFile.current.click()}
                    >
                        <FontAwesomeIcon icon={faImage} />
                    </div>
                </div>
                {/* {
                    !isLoading
                    &&
                    <div className="edits_btn">
                        <ul>
                            <li>
                                <a href="" onClick={(e) => {
                                    e.preventDefault();
                                    fileInput.current.click()
                                }}><i class="fal fa-pen"></i></a>
                            </li>
                            {
                                props.image
                                &&
                                <li>
                                    <a href="" onClick={async (e) => {
                                            e.preventDefault();
                                                setLoader(true);
                                                await props.removeImage()
                                                setLoader(false);
                                        }}><i class="fal fa-times"></i></a>
                                </li>
                            }
                        </ul>
                    </div>
                } */}
                <input
                    style={{ display: "none" }}
                    ref={inputFile}
                    id="photo2"
                    type="file"
                    onChange={(event) => {
                        handleImage(event.target.files[0]);
                        event.target.value = "";
                    }}
                    accept="image/*"
                />
                {/* {progress !== 0 ? (
                    <ProgressBar now={progress} variant="danger" label={`${progress}%`} max={100} animated/>
                ) : null} */}
            </div>
            {isLoading && progress !== 0 ? (
                <ProgressBar
                    now={progress}
                    variant="danger"
                    label={`${progress}%`}
                    max={100}
                    animated
                />
            ) : null}
            <small className="text-danger">{isError ? isError : ``}</small>
        </>
    );
}
export default UploadImageBox;
