import { faCalendarDays, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";
import { FiltersFunctions } from "./functions";
import NoDataComponent from "../../CommanComponents/NoDataComponent";


const FilterModal = (props) => {
    const {
        getAdminsList,
        adminsList,
        wardsListing,
        wardslist,
        adminsSearch,
        wardsSearch,
        handleWardsSearch,
        handleAssignedBySearch,
        getTaskPeriorities,
        taskPriorities,
        handleAssignedByFilters,
        handleWardsFilters,
        handleTaskPrioritiesFilters,
        handleTaskStatus,
        taskStatusArray,
        applyFilters,
        handleClearAllFilters,
        hospitalsSearch,
        hospitalsList,
        handleHospitalsFilters,
        handleHospitalsSearch,
        getHospitalListing
    } = FiltersFunctions(props);


    useEffect(() => {
        wardsListing('');
        getAdminsList('');
        getTaskPeriorities();
        getHospitalListing('')
    }, []);


    return (
        <div>
            <Modal show={props.show} onHide={props.close} centered className="filter_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="filter_details">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Hospital</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Ward</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Assigned by</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="fourth">Task Priority</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="fifth">Task status</Nav.Link>
                                        </Nav.Item>

                                    </Nav>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                                    <div className="content_area">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <Form className="ward_form">
                                                    <Form.Group className="form-group">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter hospital name here"
                                                            value={hospitalsSearch}
                                                            onChange={handleHospitalsSearch}
                                                        />
                                                        <a >
                                                            <div className="icon">
                                                                <FontAwesomeIcon icon={faSearch} />
                                                            </div>
                                                        </a>
                                                    </Form.Group>
                                                    <div className="check_box_slider">
                                                        {
                                                            hospitalsList && hospitalsList.length > 0 ?
                                                                hospitalsList.map((item, i) => (
                                                                    <Form.Check
                                                                        key={i}
                                                                        type="checkbox"
                                                                        id={item.id}
                                                                        label={item.title}
                                                                        checked={item.checked}
                                                                        onChange={() => handleHospitalsFilters(item)}
                                                                    />
                                                                ))
                                                                :
                                                                <NoDataComponent
                                                                    message="No data Found!"
                                                                />
                                                        }
                                                    </div>
                                                </Form>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <div className="search_details">
                                                    <Form className="ward_form">
                                                        <Form.Group className="form-group">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Enter ward name here"
                                                                value={wardsSearch}
                                                                onChange={handleWardsSearch}
                                                            />
                                                            <a >
                                                                <div className="icon">
                                                                    <FontAwesomeIcon icon={faSearch} />
                                                                </div>
                                                            </a>
                                                        </Form.Group>
                                                        <div className="check_box_slider">
                                                            {
                                                                wardslist && wardslist.length > 0 ?
                                                                    wardslist.map((item, i) => (
                                                                        <Form.Check
                                                                            key={i}
                                                                            type="checkbox"
                                                                            id={item.id}
                                                                            label={(<div style={{ textTransform: 'capitalize' }}>{item.title}  <b>{item.hospital_name ? ` (${item.hospital_name})` : ''}</b></div>)}
                                                                            checked={item.checked}
                                                                            onChange={() => handleWardsFilters(item)}
                                                                        />
                                                                    ))
                                                                    :
                                                                    <NoDataComponent
                                                                        message="No Ward Found!"
                                                                    />
                                                            }
                                                        </div>
                                                    </Form>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <Form className="ward_form">
                                                    <Form.Group className="form-group">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter staff name here"
                                                            value={adminsSearch}
                                                            onChange={handleAssignedBySearch}
                                                        />
                                                        <a >
                                                            <div className="icon">
                                                                <FontAwesomeIcon icon={faSearch} />
                                                            </div>
                                                        </a>
                                                    </Form.Group>
                                                    <div className="check_box_slider">
                                                        {
                                                            adminsList && adminsList.length > 0 ?
                                                                adminsList.map((item, i) => (
                                                                    <Form.Check
                                                                        key={i}
                                                                        type="checkbox"
                                                                        id={item.id}
                                                                        label={(<div style={{ textTransform: 'capitalize' }}>{item.name}</div>)}
                                                                        checked={item.checked}
                                                                        onChange={() => handleAssignedByFilters(item)}
                                                                    />
                                                                ))
                                                                :
                                                                <NoDataComponent
                                                                    message="No data Found!"
                                                                />
                                                        }
                                                    </div>
                                                </Form>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="fourth">
                                                <Form className="task_status_form">
                                                    <div className="check_box_slider">
                                                        {
                                                            taskPriorities && taskPriorities.length ?
                                                                taskPriorities.map((item, i) => (
                                                                    <Form.Check
                                                                        key={i}
                                                                        type="checkbox"
                                                                        id={item.id}
                                                                        label={(<div style={{ textTransform: 'capitalize' }}>{item.title}</div>)}
                                                                        checked={item.checked}
                                                                        onChange={() => handleTaskPrioritiesFilters(item)}
                                                                    />
                                                                ))
                                                                :
                                                                null
                                                        }
                                                    </div>

                                                </Form>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="fifth">
                                                <Form className="task_status_form">
                                                    <div className="check_box_slider">
                                                        {
                                                            taskStatusArray.map((item, i) => (
                                                                <Form.Check
                                                                    key={i}
                                                                    type="checkbox"
                                                                    id={item.id}
                                                                    label={item.label}
                                                                    checked={item.checked}
                                                                    onChange={() => handleTaskStatus(item)}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                </Form>
                                            </Tab.Pane>

                                        </Tab.Content>
                                    </div>
                                </Col>
                            </Row>
                        </Tab.Container>
                        <div className="btn_area">
                            <Button className="cancelBtn" type="button" onClick={() => handleClearAllFilters()}>Clear All</Button>
                            <Button className="submitBtn" type="submit" onClick={() => applyFilters()}>Apply</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default FilterModal;