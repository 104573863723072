import { faAngleDown, faCalendarDays, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    Form,
    Image,
    Row,
    Table,
} from "react-bootstrap";
import { Image_Up, Man } from "../../../../../assets/images";
import * as Constants from "../../../../Helper/constants/constants";
import FiltersHeader from "../../../../Helper/filtersHeader";
import Select from "react-select";
import AddStafFunction from "./function";
import { useEffect } from "react";
import Loader from "../../../../Helper/loader";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import { useNavigate } from "react-router";
import UploadImageBox from "../../../../Helper/UploadUserImageBox";
import moment from "moment";
import CustomSelectOptions from '../../../../Helper/customSelectOption'

const AddStaff = () => {
    const navigate = useNavigate();
    const [isVisiblePassword, setVisiblePassword] = useState(false);
    const {
        values,
        loader,
        isError,
        handleChange,
        getHospitalListing,
        hospitalsListing,
        getcities,
        citiesList,
        getstate,
        stateList,
        getGender,
        genderList,
        getbloodGroup,
        bloodgrpList,
        departmentList,
        getdepartment,
        getRoleList,
        roleList,
        createAdmin,
        getPermissionsListing,
        permissions,
        handleCheck,
        selectAll,
        superAdmin,
        handleAllSelect,
        handleSuperAdminSelect,
        handleBreadcrumDataNaviagtion,
        getStaffDetails,
        loaction,
        breadcrumData,
        breadcrumItem,
        setbreadcrumItem,
        setCheckRoleChangeOnEditCase,
        setCheckAllFieldsFilled,
        checkAllFieldsFilled,
        validationFromDrawerEditProfile,
        setValidationFromdrawerEditProfile,
        setValues,
        handleClick,
        focus,
        setFocus,
        inputCal,
        handleClickDob,
        focusDob,
        setFocusDob,
        inputCalDob,
        setSuperAdmin
    } = AddStafFunction();


    useEffect(() => {
        if (!loader) {
            if (loaction && loaction.state && loaction.state.name) {
                setbreadcrumItem('Edit Staff');
            }
            else {
                setbreadcrumItem('Add Staff');
                let hospitalData = localStorage.getItem('SET_HOSPITAL');
                let hospital = JSON.parse(hospitalData);
                getdepartment(hospital)
                setValues({ ...values, hospital: hospital && hospital.title ? { id: hospital.id, label: hospital.title } : "", });
            }
            if (loaction && loaction.state && loaction.state.name === 'fromDrawer') {   //<--------For handle the validations to disable the fields which sub-admin can't edit 
                setValidationFromdrawerEditProfile(true);
            }
            else {
                setValidationFromdrawerEditProfile(false);
            }
            getstate();
            getGender();
            getbloodGroup();
            getRoleList();
            getPermissionsListing();
            getHospitalListing("", 1);
            setCheckAllFieldsFilled(false);
        }
    }, []);

    useEffect(() => {
        if (focus) {
            setFocus(false)
        }
        if (focusDob) {
            setFocusDob(false)
        }
    }, [handleClick, handleClickDob]);

    let name = loaction && loaction.state && loaction.state.name;

    let userdata = localStorage.getItem('SET_USER_DATA');
    const User = JSON.parse(userdata);

    return (
        <div className="add_staff_section">
            <WithoutImageHeader
                header={loaction && loaction.state && loaction.state.name ? Constants.EDIT_STAFF : Constants.ADD_STAFF}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBack={() => navigate(-1)}
                handleBreadcrumDataNaviagtion={(i) =>
                    handleBreadcrumDataNaviagtion(i)
                }
            />
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="add_staff_area">
                            <div className="image_section">
                                {/* <div className="img_area">
                                    <Image src={Man} alt="..." />
                                </div>
                                <div className="gallery_img">
                                    <Image src={Image_Up} alt="..." />
                                </div> */}
                                <UploadImageBox
                                    name={`admin`}
                                    image={values && values.image}
                                    onResponse={(response) => { handleChange("image", response.path); }}
                                    removeImage={async () => {
                                        //  await removeImage();
                                    }}
                                />
                            </div>

                            <div className="form_area">
                                <div className="dropdown_box">
                                    <h6>
                                        Select Hospital<span>*</span>
                                    </h6>
                                    <div className="dropdown_area">
                                        {loaction && loaction.state && loaction.state.name ? <Select
                                            isMulti
                                            isSearchable
                                            placeholder={"Select hospital"}
                                            value={values.hospital}
                                            options={hospitalsListing}
                                            onChange={(e) => {
                                                handleChange("hospital", e);
                                            }}
                                            className={isError.hospital.message ? "error_border_select" : ""}
                                        /> :
                                            <Select
                                                autoFocus={false}
                                                placeholder={"Select hospital"}
                                                value={values && values.hospital}
                                                options={hospitalsListing}
                                                onChange={(e) => {
                                                    getdepartment(e);
                                                    handleChange("hospital", e);
                                                }}
                                                className={
                                                    isError.hospital.message
                                                        ? "error_border_select"
                                                        : ""
                                                }
                                            />}
                                        {isError.hospital.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.hospital.message}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <Form>
                                    <Form.Group className="form-group">
                                        <Form.Label>
                                            Full Name<span>*</span>
                                        </Form.Label>
                                        <Form.Control
                                            disabled={loaction && loaction.state && loaction.state.name ? true : false}
                                            type="text"
                                            placeholder="Enter your full name"
                                            className={
                                                isError.name.message
                                                    ? "error_border"
                                                    : ""
                                            }
                                            value={values.name}
                                            onChange={(e) => handleChange("name", e.target.value)}
                                        />
                                        {isError.name.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.name.message}
                                            </p>
                                        ) : null}
                                    </Form.Group>
                                    <Row>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                                            <Form>
                                                <Form.Group className="form-group">
                                                    <Form.Label>
                                                        Email<span>*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter your email"
                                                        className={isError.email.message ? "error_border" : ""}
                                                        value={values.email}
                                                        onChange={(e) => handleChange("email", e.target.value)}
                                                    />
                                                    {isError.email.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.email.message}
                                                        </p>
                                                    ) : null}
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                                            <Form.Group className="form-group">
                                                <Form.Label>
                                                    Contact Number<span>*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter your contact number"
                                                    className={isError.phoneNo.message ? "error_border" : ""}
                                                    value={values.phoneNo}
                                                    onChange={(e) => handleChange("phoneNo", e.target.value)}
                                                />
                                                {isError.phoneNo.message ? (
                                                    <p className="text-danger mt-2">
                                                        {isError.phoneNo.message}
                                                    </p>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                                            <Form>
                                                <Form.Group className="form-group">
                                                    <Form.Label>
                                                        Employee ID
                                                        <span>*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        disabled={loaction && loaction.state && loaction.state.name ? true : false}
                                                        type="text"
                                                        placeholder="Enter your Employee Id"
                                                        className={isError.employeeId.message ? "error_border" : ""}
                                                        value={values.employeeId}
                                                        onChange={(e) => handleChange("employeeId", e.target.value)}
                                                    />
                                                    {isError.employeeId.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.employeeId.message}
                                                        </p>
                                                    ) : null}
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                                            <div className="dropdown_box">
                                                <h6>
                                                    Role<span>*</span>
                                                </h6>
                                                <div className="dropdown_area">
                                                    <Select
                                                        // isDisabled={loaction && loaction.state && loaction.state.name ? true : false}
                                                        autoFocus={false}
                                                        placeholder={"Select role"}
                                                        className={isError.role.message ? "error_border_select" : ""}
                                                        options={roleList ? roleList : []}
                                                        value={values && values.role}
                                                        onChange={(e) => (handleChange("role", e), setCheckRoleChangeOnEditCase(true),  setSuperAdmin(false))}
                                                        components={{
                                                            Option: props => (
                                                                <CustomSelectOptions
                                                                    items={props}
                                                                    value={values && values.role}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    {isError.role.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.role.message}
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                                            <div className="dropdown_box">
                                                <h6>
                                                    Gender<span>*</span>
                                                </h6>
                                                <div className="dropdown_area">
                                                    <Select
                                                        autoFocus={false}
                                                        placeholder={"Select gender"}
                                                        value={values && values.gender}
                                                        options={genderList ? genderList : []}
                                                        onChange={(e) => handleChange("gender", e)}
                                                        className={isError.gender.message ? "error_border_select" : ""}
                                                        components={{
                                                            Option: props => (
                                                                <CustomSelectOptions
                                                                    items={props}
                                                                    value={values && values.gender}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    {isError.gender.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.gender.message}
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                                            <div className="dropdown_box">
                                                <h6>
                                                    Blood Group<span>*</span>
                                                </h6>
                                                <div className="dropdown_area">
                                                    <Select
                                                        autoFocus={false}
                                                        placeholder={"Select blood group"}
                                                        options={bloodgrpList}
                                                        value={values && values.bloodGroup}
                                                        onChange={(e) => handleChange("bloodGroup", e)}
                                                        className={isError.bloodGroup.message ? "error_border_select" : ""}
                                                        components={{
                                                            Option: props => (
                                                                <CustomSelectOptions
                                                                    items={props}
                                                                    value={values && values.bloodGroup}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    {isError.bloodGroup.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.bloodGroup.message}
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                                            <Form>
                                                <Form.Group className="form-group">
                                                    <Form.Label>
                                                        Date of Birth
                                                        <span>*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        onFocus={() => setFocusDob(true)}
                                                        disabled={focusDob}
                                                        ref={inputCalDob}
                                                        max={moment().format("YYYY-MM-DD")}
                                                        placeholder="DD/MM/YYYY"
                                                        className={isError.dob.message ? "error_border" : ""}
                                                        value={values.dob}
                                                        onChange={(e) => handleChange("dob", e.target.value)}
                                                    />
                                                    {isError.dob.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.dob.message}
                                                        </p>
                                                    ) : null}
                                                    <a>
                                                        <div className="icon">
                                                            <FontAwesomeIcon icon={faCalendarDays}
                                                                onClick={() => handleClickDob()}
                                                            />
                                                        </div>
                                                    </a>
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                                            <Form>
                                                <Form.Group className="form-group">
                                                    <Form.Label>
                                                        Joining Date
                                                        <span>*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        onFocus={() => setFocus(true)}
                                                        disabled={loaction && loaction.state && loaction.state.name ? true : focus}
                                                        ref={inputCal}
                                                        placeholder="DD/MM/YYYY"
                                                        // min={moment().format("YYYY-MM-DD")}
                                                        className={
                                                            isError.joiningDate
                                                                .message
                                                                ? "error_border"
                                                                : ""
                                                        }
                                                        value={
                                                            values.joiningDate
                                                        }
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "joiningDate",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    {isError.joiningDate
                                                        .message ? (
                                                        <p className="text-danger mt-2">
                                                            {
                                                                isError
                                                                    .joiningDate
                                                                    .message
                                                            }
                                                        </p>
                                                    ) : null}
                                                    {
                                                        loaction && loaction.state && loaction.state.name ? null :
                                                            (

                                                                <div className="icon">
                                                                    <FontAwesomeIcon icon={faCalendarDays}
                                                                        onClick={() => handleClick()}
                                                                    />
                                                                </div>

                                                            )
                                                    }

                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            xxl={6}
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={12}
                                            xs={12}
                                        >
                                            <div className="dropdown_box">
                                                <h6>
                                                    Department<span>*</span>
                                                </h6>
                                                <div className="dropdown_area">
                                                    <Select
                                                        isDisabled={loaction && loaction.state && loaction.state.name ? true : false}
                                                        autoFocus={false}
                                                        placeholder={"Select department"}
                                                        options={departmentList ? departmentList : []}
                                                        value={values.department}
                                                        onChange={(e) => handleChange("department", e)}
                                                        className={isError.department.message ? "error_border_select" : ""}
                                                        components={{
                                                            Option: props => (
                                                                <CustomSelectOptions
                                                                    items={props}
                                                                    value={values && values.department}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    {isError.department
                                                        .message ? (
                                                        <p className="text-danger mt-2">
                                                            {
                                                                isError
                                                                    .department
                                                                    .message
                                                            }
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                                            <Form.Group className="form-group">
                                                <Form.Label>
                                                    Specialization (if any)
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter specialization here"
                                                    className={
                                                        isError.phoneNo.message
                                                            ? "error_border"
                                                            : ""
                                                    }
                                                    value={
                                                        values.specialization
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "specialization",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                {isError.specialization
                                                    .message ? (
                                                    <p className="text-danger mt-2">
                                                        {
                                                            isError
                                                                .specialization
                                                                .message
                                                        }
                                                    </p>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                                            <div className="dropdown_box">
                                                <h6>
                                                    State<span>*</span>
                                                </h6>
                                                <div className="dropdown_area">
                                                    <Select
                                                        autoFocus={false}
                                                        placeholder={
                                                            "Select State"
                                                        }
                                                        value={
                                                            values &&
                                                            values.state
                                                        }
                                                        options={
                                                            stateList
                                                                ? stateList
                                                                : []
                                                        }
                                                        onChange={(e) => { getcities(e); handleChange("state", e) }}
                                                        className={
                                                            isError.state.message
                                                                ? "error_border_select"
                                                                : ""
                                                        }
                                                        components={{
                                                            Option: props => (
                                                                <CustomSelectOptions
                                                                    items={props}
                                                                    value={values && values.state}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    {isError.state.message ? (
                                                        <p className="text-danger mt-2">
                                                            {
                                                                isError.state
                                                                    .message
                                                            }
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                                            <div className="dropdown_box">
                                                <h6>
                                                    City<span>*</span>
                                                </h6>
                                                <div className="dropdown_area">
                                                    <Select
                                                        isDisabled={
                                                            values &&
                                                                values.state
                                                                ? false
                                                                : true
                                                        }
                                                        autoFocus={false}
                                                        placeholder={
                                                            "Select City"
                                                        }
                                                        value={
                                                            values &&
                                                            values.city
                                                        }
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "city",
                                                                e
                                                            )
                                                        }
                                                        options={
                                                            citiesList
                                                                ? citiesList
                                                                : []
                                                        }
                                                        className={
                                                            isError.city.message
                                                                ? "error_border_select"
                                                                : ""
                                                        }
                                                        components={{
                                                            Option: props => (
                                                                <CustomSelectOptions
                                                                    items={props}
                                                                    value={values && values.city}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    {isError.city.message ? (
                                                        <p className="text-danger mt-2">
                                                            {
                                                                isError.city
                                                                    .message
                                                            }
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Label>
                                                Address<span>*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter your address"
                                                className={
                                                    isError.address.message
                                                        ? "error_border"
                                                        : ""
                                                }
                                                value={values.address}
                                                onChange={(e) =>
                                                    handleChange(
                                                        "address",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            {isError.address.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError.address.message}
                                                </p>
                                            ) : null}
                                        </Form.Group>
                                    </Form>
                                    {name == null ?
                                        <Form>
                                            <Form.Group className="form-group">
                                                <Form.Label>
                                                    Password<span>*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type={!isVisiblePassword ? "password" : "text"}
                                                    placeholder="Enter your password"
                                                    className={
                                                        isError.password.message
                                                            ? "error_border"
                                                            : ""
                                                    }
                                                    value={values.password}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "password",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <span onClick={() => setVisiblePassword(!isVisiblePassword)}>
                                                    {isVisiblePassword ? <FontAwesomeIcon
                                                        icon={faEye}
                                                        className="eye_icon"
                                                    /> : <FontAwesomeIcon
                                                        icon={faEyeSlash}
                                                        className="eye_icon"
                                                    />}
                                                </span>
                                                {isError.password.message ? (
                                                    <p className="text-danger mt-2">
                                                        {isError.password.message}
                                                    </p>
                                                ) : null}
                                            </Form.Group>
                                        </Form>
                                        : null
                                    }

                                </Form>

                                <div className="add_role_screen">
                                    <div className="add_role_details">
                                        <div className="title_btn_area">
                                            {User && parseInt(User.user_type) === 1 ?
                                                <div className="left_area">
                                                    <Form.Check
                                                        reverse
                                                        type="checkbox"
                                                        id="Critical"
                                                        label="User  is Super Admin"
                                                        checked={superAdmin}
                                                        onChange={(e) =>
                                                            handleSuperAdminSelect(
                                                                e.target.checked
                                                            )
                                                        }
                                                        disabled={values && values.role && (values.role.label === 'Lab' || values.role.label === 'lab') ? true : false}
                                                    />

                                                </div> : null}
                                            <div className="right_area">
                                                <Form.Check
                                                    reverse
                                                    type="checkbox"
                                                    id="Critical"
                                                    label="Select all"
                                                    checked={selectAll}
                                                    onChange={(e) =>
                                                        handleAllSelect(
                                                            e.target.checked
                                                        )
                                                    }
                                                    disabled={values && values.role && (values.role.label === 'Lab' || values.role.label === 'lab') ? true : false}
                                                />
                                            </div>

                                        </div>
                                        {
                                            superAdmin ?
                                                <h6>Disclaimer: If you select "Super Admin", Permissions which are assigned with the selected role for this user will override with Super Admin Permissions. Kindly verify before making this change.</h6>
                                                :
                                                null
                                        }
                                        {permissions &&
                                            permissions.length > 0 ? (
                                            <>
                                                <div className="list_detail">
                                                    <div className="list_data">
                                                        <Card>
                                                            <Card.Body>
                                                                <h4>
                                                                    User
                                                                    Permission{" "}
                                                                </h4>
                                                                <Table
                                                                    responsive
                                                                >
                                                                    <tr>
                                                                        <th>
                                                                            <ul className="list-inline">
                                                                                <li className="fix-area">
                                                                                    Module
                                                                                </li>
                                                                                <li className="list-gap">
                                                                                    Listing
                                                                                </li>
                                                                                <li className="list-gap">
                                                                                    Create
                                                                                </li>
                                                                                <li className="list-gap">
                                                                                    Update
                                                                                </li>
                                                                                <li className="list-gap">
                                                                                    Delete
                                                                                </li>
                                                                            </ul>
                                                                        </th>
                                                                    </tr>
                                                                    {permissions &&
                                                                        permissions.map((item, i) => {
                                                                            return (
                                                                                <tr
                                                                                    key={i}
                                                                                >
                                                                                    <td>
                                                                                        <ul className="list-inline">
                                                                                            <li className="fix-area">
                                                                                                {item.title}
                                                                                            </li>
                                                                                            {item.permissions.map((data, i) => {
                                                                                                return (
                                                                                                    <li
                                                                                                        className="list-gap"
                                                                                                        key={i}
                                                                                                    >
                                                                                                        <Form.Check
                                                                                                            type="checkbox"
                                                                                                            id="Critical"
                                                                                                            checked={parseInt(data.value) === 1 ? true : false}
                                                                                                            onChange={() => handleCheck(data, item)}
                                                                                                            // disabled={values && values.role && (values.role.label === 'Lab' || values.role.label === 'lab') ? true : false}
                                                                                                            disabled={true}
                                                                                                        />
                                                                                                    </li>
                                                                                                );
                                                                                            }
                                                                                            )}
                                                                                        </ul>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                        )}
                                                                </Table>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                                <div>
                                    <div className="btn_area">
                                        <Button className="cancelBtn" type="button" onClick={() => navigate(-1)}>
                                            Cancel
                                        </Button>
                                        <Button
                                            className="submitBtn"
                                            type="submit"
                                            onClick={() => createAdmin()}
                                        >
                                            Submit
                                            {checkAllFieldsFilled ? (<p className="text-danger manage_text mt-2" >
                                                Please fill all fields
                                            </p>) : null}
                                        </Button>
                                    </div>

                                    {/* 
                                        <p className="text-danger mt-2">
                                            Please fill all fields
                                        </p>
                                    ) : null} */}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                {loader ? <Loader loader={loader} /> : null}
            </Container>
            {/* <DeleteModal /> */}
        </div>
    );
};

export default AddStaff;
