import { useEffect, useState } from "react";
import adminController from "../../../../apis/Controllers/AdminControler/admin.controller";
import wardController from "../../../../apis/Controllers/WardController/ward.controller";
import { actionsController } from "../../../../apis/Controllers/CommonController/action.controller";
import store from "../../../../redux/store";
import hospitalController from "../../../../apis/Controllers/HospitalController/hospital.controller";
import { filtersController } from "../../../../apis/Controllers/CommonController/filter.controller";

export const RoomListFiltersFunctions = (props) => {
    let storedFilters = store.getState().RoomListFilters.filters;
    const [wardslist, setWardsList] = useState([]);
    const [wardsSearch, setWardsSearch] = useState("");
    const [hospitalsSearch, setHospitalsSearch] = useState("")
    const [hospitalsList, setHospitalsList] = useState([]);

    const defaultFilters = {
        wards: storedFilters && storedFilters.wards ? storedFilters.wards : [],
        hospitals: storedFilters && storedFilters.hospitals ? storedFilters.hospitals : [],
        sortByAlpha: storedFilters && storedFilters.sortByAlpha ? storedFilters.sortByAlpha : null,
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [SortByList, setSortByListStatusList] = useState([
        {
            title: "Ascending",
            value: "ASC",
            checked: false,
            id: 1,
        },
        {
            title: "Descending",
            value: "DESC",
            checked: false,
            id: 2,
        },
    ]);

    useEffect(() => {
        if (storedFilters && storedFilters.sortByAlpha) {
            reSetValuesOfSortByAlphaStatusList(storedFilters.sortByAlpha);
        }
    }, [storedFilters]);

    const handleWardsSearch = (e) => {
        setWardsSearch(e.target.value);
        getWardsListing(e.target.value);
    };

    const handleHospitalsSearch = (e) => {
        setHospitalsSearch(e.target.value);
        getHospitalListing(e.target.value);
    };

    const getWardsListing = async (e) => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        let post = {
            hospital_id: item ? [item.id] : [],
            search: e ? e : "",
        };
        let response = await wardController.wardListing(post);
        if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]["checked"] = false;
            }
            if (
                storedFilters &&
                storedFilters.wards &&
                storedFilters.wards.length > 0
            ) {
                reSetWardsAppliedFiltersValues(storedFilters, data);
            } else {
                setWardsList(data);
            }
        } else {
            setWardsList([]);
        }
    };

    const reSetWardsAppliedFiltersValues = (storedFilters, wardslist) => {
        let filter = { ...storedFilters };
        let wards = [...wardslist];
        if (wards && wards.length > 0) {
            wards.forEach((b) => {
                filter.wards.forEach((a) => {
                    if (parseInt(b.id) === parseInt(a)) {
                        b.checked = true;
                    }
                });
            });
            setWardsList(wards);
            setFilters({
                ...filters,
                wards: filter.wards,
            });
        }
    };



    const handleWardsFilters = (item) => {
        let array = [...wardslist];
        let value = [...filters.wards];
        if (item) {
            for (let i in array) {
                if (parseInt(array[i].id) === parseInt(item.id)) {
                    let check = array[i].checked;
                    array[i].checked = !check;
                }
            }
            setWardsList(array);
            let status = value.findIndex((id) => parseInt(id) === parseInt(item.id));
            if (status === -1) {
                value.push(item.id);
            } else {
                value.splice(status, 1);
            }
            setFilters({
                ...filters,
                wards: value,
            });
        }
    };


    const getHospitalListing = async (e) => {
        let post = {
            search: e ? e : "",
        };
        let response = await hospitalController.hospitalListing(post);
        if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]["checked"] = false;
            }
            setHospitalsList(data)
            if (
                storedFilters &&
                storedFilters.hospitals &&
                storedFilters.hospitals.length > 0
            ) {
                reSethospitalsAppliedFiltersValues(storedFilters, data);
            } else {
                setHospitalsList(data);
            }
        } else {
            setHospitalsList([])
        }
    };

    const handleHospitalsFilters = (item) => {
        let array = [...hospitalsList];
        let value = [...filters.hospitals];
        if (item) {
            for (let i in array) {
                if (parseInt(array[i].id) === parseInt(item.id)) {
                    let check = array[i].checked;
                    array[i].checked = !check;
                }
            }
            setHospitalsList(array);
            let status = value.findIndex((id) => parseInt(id) === parseInt(item.id));
            if (status === -1) {
                value.push(item.id);
            } else {
                value.splice(status, 1);
            }
            setFilters({
                ...filters,
                hospitals: value,
            });
        }
    };

    const reSethospitalsAppliedFiltersValues = (storedFilters, hospitalslist) => {
        let filter = { ...storedFilters };
        let hospitals = [...hospitalslist];
        if (hospitals && hospitals.length > 0) {
            hospitals.forEach((b) => {
                filter.hospitals.forEach((a) => {
                    if (parseInt(b.id) === parseInt(a)) {
                        b.checked = true;
                    }
                });
            });
            setHospitalsList(hospitals)
            setFilters({
                ...filters,
                hospitals: filter.hospitals,
            });
        }
    };

    const handleSortByFilters = (item) => {
        let array = [...SortByList]
        if (item) {
            for (let i in array) {
                array[i].checked = false
            }
            let index = array.findIndex((data) => parseInt(data.id) === parseInt(item.id));
            if (index !== -1) {
                if (array[index].checked === true) {
                    array[index].checked = false
                }
                else {
                    array[index].checked = true
                }
            }
            setSortByListStatusList(array);
            setFilters({
                ...filters,
                sortByAlpha: item
            })
        }
    };

    const reSetValuesOfSortByAlphaStatusList = (appliedStatus) => {
        let array = [...SortByList]
        if (appliedStatus) {
            const appliedSortByStatusIndex = array.findIndex((data) => parseInt(data.id) === parseInt(appliedStatus.id));
            if (appliedSortByStatusIndex !== -1) {
                array[appliedSortByStatusIndex].checked = true
            }
            setSortByListStatusList(array)
        }
    };

    const applyFilters = () => {
        props.applyFilters(filters);
    };

    const handleClearAllFilters = async () => {
        props.handleClearAllFilters();
        props.close();
    };

    return {
        getWardsListing,
        handleWardsSearch,
        handleWardsFilters,
        applyFilters,
        setFilters,
        handleClearAllFilters,
        wardslist,
        getHospitalListing,
        hospitalsList,
        handleHospitalsFilters,
        applyFilters,
        handleClearAllFilters,
        handleWardsSearch,
        wardsSearch,
        handleHospitalsSearch,
        hospitalsSearch,
        SortByList,
        handleSortByFilters,
    };
};
