import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import store from "../../../../../redux/store";
import adminController from "../../../../../apis/Controllers/AdminControler/admin.controller";
import permissionsController from "../../../../../apis/Controllers/PermissionsController/permissions.contoller";
import moment from "moment";
import logsController from "../../../../../apis/Controllers/LogsController/logs.controller";

const StaffDetailFunctions = () => {
    const [breadcrumData, setBreadcrumData] = useState(["Dashboard", "Staff List"]);
    const [breadcrumItem, setbreadcrumItem] = useState("");
    const [loader, setLoader] = useState(false);
    const [staffDetail, setStaffDetail] = useState(null);
    const [taskListing, setTaskListing] = useState([])
    const [sortTitle, setSortTitle] = useState(null);
    //<------------------------------------permissions -------------------------------
    const [checkPermissions, setCheckPermissions] = useState(null);
    const [checkTaskPermissions, setCheckTaskPermissions] = useState(null);
    const [checkLogsPermissions, setLogsCheckPermissions] = useState(null);
    //<-------------------------------------------------------------------------------
    const [staffId, setStaffId] = useState(null);
    const [tabs, setTabs] = useState([]);
    const loaction = useLocation();
    const navigate = useNavigate();
    //<-----------------------Logs Tab----------------------------------------
    const [LogsListing, setLogsListing] = useState([]);
    const [logsPage, setLogsPage] = useState(1)
    const [logsCount, setLogsCount] = useState(0)
    const [logsTotalPages, setLogsTotalPages] = useState([]);
    const [logsDateParam, setLogsDateParam] = useState(moment().format('YYYY-MM-DD'));
    let userdata = localStorage.getItem('SET_USER_DATA');
    let User = JSON.parse(userdata);
    const defaultTabs = [
        {
            id: 1,
            tabTitle: "Task",
        },
        {
            id: 2,
            tabTitle: "Logs",
        },
    ];

    //<-------------onChange Tab---------
    const urlparam = useParams();

    const handleTabData = (tabId) => {
        if (parseInt(tabId) === 1) {
            let id = urlparam.slug ? urlparam.slug : "";
            getStaffDetails(id);
        } else if (parseInt(tabId) === 2) {
            getSubAdminLogsListing(1);
        }
        else return;
    };


    useEffect(() => {
        if (checkTaskPermissions) {
            let tabBar = [...tabs]
            if (checkTaskPermissions
                && checkTaskPermissions.permissions
                && checkTaskPermissions.permissions.length > 0
                && parseInt(checkTaskPermissions.permissions[0].value) === 1) {
                let checkId = tabBar.findIndex((item) => item.tabTitle === 'Task')
                if (checkId === -1) {
                    tabBar.push({
                        id: 1,
                        tabTitle: "Task",
                    })
                }
                else;
            }
            else {
                return tabBar
            }
            setTabs(tabBar);
        }
    }, [checkTaskPermissions]);


    useEffect(() => {
        if (checkLogsPermissions) {
            let tabBar = [...tabs]
            if (checkLogsPermissions
                && checkLogsPermissions.permissions
                && checkLogsPermissions.permissions.length > 0
                && parseInt(checkLogsPermissions.permissions[0].value) === 1) {
                let checkId = tabBar.findIndex((item) => item.tabTitle === 'Logs')
                if (checkId === -1) {
                    tabBar.push({
                        id: 2,
                        tabTitle: "Logs",
                    })
                }
                else;
            }
            else {
                return tabBar
            }
            setTabs(tabBar);
        }
    }, [checkLogsPermissions]);


    const checkTaskPermissionInSubAdmin = () => {
        let tabBar = [...tabs]
        if (User && User === '') {
            tabBar.push(
                {
                    id: 1,
                    tabTitle: "Task",
                },
                {
                    id: 2,
                    tabTitle: "Logs",
                },
            )
        }
        else {
            tabBar.push(
                {
                    id: 1,
                    tabTitle: "Task",
                },
                {
                    id: 2,
                    tabTitle: "Logs",
                },
            )
        }
        setTabs(tabBar);
    };


    const getModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('staff');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckPermissions(data);
        }
        else {
            setCheckPermissions(null);
        }
    };


    const getTaskModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('task');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckTaskPermissions(data);
        }
        else {
            setCheckTaskPermissions(null);
        }
    };


    const getLogsModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('log');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setLogsCheckPermissions(data);
        }
        else {
            setLogsCheckPermissions(null);
        }
    };


    const getStaffDetails = async (id) => {
        setLoader(true);
        let response = await adminController.adminDetail(id);
        if (response && response.status) {
            let data = response.data && response.data.tasks ? response.data.tasks : [];
            setStaffId(response && response.data && response.data.id)
            setTaskListing(data)
            setStaffDetail(response.data);
            setLoader(false);
        } else {
            setStaffDetail(null);
            setLoader(false);
        }
    };

    //---------task sorting-------------------
    const handleTaskFilters = (sort) => {
        if (taskListing && taskListing.length > 0) {
            let data = [...taskListing]
            setSortTitle(sort)
            if (sort == "Asce") {
                let filter = data.sort((a, b) => { return a.id - b.id });
                setTaskListing(filter)
            }
            else if (sort == "Desc") {
                let filter = data.sort((a, b) => { return b.id - a.id });
                setTaskListing(filter)
            } else {
                setTaskListing(data)
            }
        }
    }


    const handleBreadcrumDataNaviagtion = (i, item) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/dashboard-superadmin");
            }
        } else if (i === 1) {
            if (item === 'My Tasks') {
                navigate("/myTasks");
            }
            else if(item === 'Task list'){ 
                navigate("/task-list");
            }
            else {
                navigate("/staff-list");
            }
        } else return;
    };

    //------------Logs--sub-admin------------------------

    useEffect(() => {
        if (logsDateParam) {
            getSubAdminLogsListing(1);
        }
    }, [logsDateParam]);

    const getSubAdminLogsListing = async (page) => {
        let post = {
            created_by: staffId,
            page: page ? page : 1,
            limit: 10,
            date: logsDateParam ? logsDateParam : ''
        };
        setLoader(true);
        let response = await logsController.subAdminLogsListing(post);
        if (response && response.status) {
            setLogsListing(response.data);
            setLogsCount(response.count);
            setLoader(false);
        } else {
            setLogsListing([]);
            setLogsCount(null)
            setLoader(false);
        }
    };

    useEffect(() => {
        if (logsCount) {
            handleLogsPages(logsCount);
        }
    }, [logsCount]);

    const onChangePage = (e) => {
        setLogsPage(e);
        getSubAdminLogsListing(e);
    };

    const handleLogsPages = (count) => {
        let array = []
        const data = parseInt(count);
        const totalPage = Math.ceil(data / 10);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i);
        }
        setLogsTotalPages(array);
    };

    const handleLogsBackClick = () => {
        if (logsPage > 1) {
            let newpage = logsPage - 1;
            setLogsPage(logsPage - 1);
            getSubAdminLogsListing(newpage);
        } else return;
    };

    const handleLogsNextClick = () => {
        if (logsPage >= 1 && logsPage < logsTotalPages.length) {
            let newpage = logsPage + 1;
            setLogsPage(logsPage + 1);
            getSubAdminLogsListing(newpage);
        } else return;
    };


    const handleNavigationOnEditIcon = () => {
        if (User && parseInt(User.user_type) === 1) {
            navigate("/add-staff", { state: { name: "EditScreen", id: staffDetail && staffDetail.id, } })
        }
    };

    return {
        User,
        checkTaskPermissions,
        getModulePermissions,
        getTaskModulePermissions,
        getLogsModulePermissions,
        checkLogsPermissions,
        checkPermissions,
        tabs,
        setTabs,
        defaultTabs,
        loader,
        breadcrumItem,
        setbreadcrumItem,
        breadcrumData,
        loaction,
        navigate,
        getStaffDetails,
        staffDetail,
        handleBreadcrumDataNaviagtion,
        handleTabData,
        LogsListing,
        checkLogsPermissions,
        logsCount,
        logsPage,
        logsTotalPages,
        onChangePage,
        handleLogsBackClick,
        handleLogsNextClick,
        handleLogsPages,
        logsDateParam,
        setLogsDateParam,
        navigate,
        handleTaskFilters,
        taskListing,
        sortTitle,
        handleNavigationOnEditIcon,
        checkTaskPermissionInSubAdmin,
        setBreadcrumData
    };
};

export default StaffDetailFunctions;
