import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function patientList(params) {
    return mainWrapper.get(WebConstant.host + "/patient", params);
};

function doctorPatientList(params) {
    return mainWrapper.get(WebConstant.host + "/doctor_patient_list", params);
};

function existPatientList(params,id) {
     return mainWrapper.get(WebConstant.host + `/discharge_patient_list/${id}`, params);
}

function patientDetails(id) {
    return mainWrapper.get(WebConstant.host + `/patient/${id}`);
};


function patientCreateStepOne(params) {
    return mainWrapper.post(WebConstant.host + `/patient_step_1`, params);
};


function patientUpdateStepOne(firstStepId, params) {
    return mainWrapper.put(WebConstant.host + `/update_patient_step_1/${firstStepId}`, params);
};


function patientCreateStepTwo(firstStepId, params) {
    return mainWrapper.put(WebConstant.host + `/patient_step_2/${firstStepId}`, params);
};


function patientCreateStepThree(firstStepId, params) {
    return mainWrapper.put(WebConstant.host + `/patient_step_3/${firstStepId}`, params);
};


function patientCreateStepFour(firstStepId, params) {
    return mainWrapper.put(WebConstant.host + `/patient_step_4/${firstStepId}`, params);
};


function patientUpdate(id) {
    return mainWrapper.put(WebConstant.host + `/patient/${id}`);
};


function patientDelete(id) {
    return mainWrapper._delete(WebConstant.host + `/delete_patient/${id}`);
};

function updatePatientDetail(id,params) {
    return mainWrapper.put(WebConstant.host + `/update_patient_deatils/${id}`,params);
};


function updatePatientStatus(id,params) {
    return mainWrapper.put(WebConstant.host + `/update_patient_status/${id}`,params);
};

function todayPatientList(id) {
    return mainWrapper.get(WebConstant.host + `/today_admit_patient_list/${id}`);
};


function addPatientNew(params) {
    return mainWrapper.post(WebConstant.host + `/add_patient`, params);
};


export const patientServices = {
    patientList,
    doctorPatientList,
    patientDetails,
    patientCreateStepOne,
    patientUpdateStepOne,
    patientCreateStepTwo,
    patientCreateStepThree,
    patientCreateStepFour,
    updatePatientDetail,
    patientUpdate,
    patientDelete,
    updatePatientStatus,
    todayPatientList,
    existPatientList,
    addPatientNew
};
