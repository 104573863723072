import { useEffect, useState } from "react";
import hospitalController from "../../../../../apis/Controllers/HospitalController/hospital.controller";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import { useNavigate } from "react-router-dom";
import store from "../../../../../redux/store";
import permissionsController from "../../../../../apis/Controllers/PermissionsController/permissions.contoller";


const HospitalListingFunctions = () => {
    var breadcrumData = ["Dashboard"];
    var breadcrumItem = "Hospital List";
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [checkPermissions, setCheckPermissions] = useState(null);
    const [hospitalsListing, setHospitalsListing] = useState([]);
    const [loader, setLoader] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    const [keyword, setSearch] = useState('');
    // -------------------PAZINATION ----------------------
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [totalPages, setTotalPages] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    //-----------------------------------------------------
    let navigate = useNavigate();
    let userdata = localStorage.getItem('SET_USER_DATA');
    let User = JSON.parse(userdata);


    useEffect(() => {
        if (count) {
            handlePages(count);
        }
    }, [count, itemsPerPage]);



    const handlePages = (count) => {
        let array = []
        const data = parseInt(count);
        const totalPage = Math.ceil(data / itemsPerPage);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i);
        }
        setTotalPages(array);
    };


    const getHospitalListing = async (e, page, itemsPerPage) => {
        if (!loader) {
            let post = {
                search: e ? e : keyword ? keyword : '',
                page: page ? page : 1,
                limit: itemsPerPage ? itemsPerPage : 10,
            }
            setLoader(true);
            let response = await hospitalController.hospitalListing(post);
            if (response && response.status) {
                setHospitalsListing(response.data);
                setCount(response.count);
                setLoader(false);
            }
            else {
                setHospitalsListing([]);
                setLoader(false);
            }
            setLoader(false);
        }
    };


    const getModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('hospital');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckPermissions(data);
        }
        else {
            setCheckPermissions(null);
        }
    };


    const handleDeleteHospital = async (item) => {
        let array = [...hospitalsListing]
        let id = item && item.id ? item.id : '';
        setLoader(true);
        let response = await hospitalController.deleteHospital(id);
        if (response && response.status) {
            setLoader(false);
            new Toaster().success(response.message);
            setDeletePopup(false)
            let filterData = array.filter((data) => data.id !== item.id);
            if (filterData.length > 0) {
                getHospitalListing('', page, itemsPerPage);
            }
            else {
                if (page > 1) {
                    let newpage = page - 1
                    setPage(page - 1);
                    getHospitalListing('', newpage, itemsPerPage);
                }
                else {
                    setPage(page);
                    getHospitalListing('', page, itemsPerPage);
                }
            }
            setTotalPages([]);
        } else {
            setLoader(false);
        }
    };


    const handleBackClick = () => {                   //<------------PAZINATION
        if (page > 1) {
            let newPage = page - 1
            getHospitalListing('', newPage, itemsPerPage);
            setPage(page - 1);
        }
        else return;
    };


    const handleNextClick = () => {                    //<------------PAZINATION
        if (page >= 1 && page < totalPages.length) {
            let newPage = page + 1
            getHospitalListing('', newPage, itemsPerPage);
            setPage(page + 1);
        }
        else return;
    };


    const handleItemPerPage = (e) => {
        let value = e.target.value;
        setItemsPerPage(value);
        getHospitalListing("", 1, value);
    };

    const handlePage = (e) => {
        setPage(e);
        getHospitalListing('', e, itemsPerPage);
    };


    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate('/dashboard');
            }
            else {
                navigate('/dashboard-superadmin');
            }
        }
        else return;
    };


    return {
        getModulePermissions,
        checkPermissions,
        getHospitalListing,
        hospitalsListing,
        handleBackClick,
        handleNextClick,
        handleDeleteHospital,
        page,
        setPage,
        totalPages,
        setTotalPages,
        itemsPerPage,
        setItemsPerPage,
        showFilterModal,
        setShowFilterModal,
        breadcrumData,
        breadcrumItem,
        navigate,
        loader,
        setLoader,
        handlePage,
        handleBreadcrumDataNaviagtion,
        deletePopup,
        setDeletePopup,
        count,
        keyword,
        setSearch,
        User,
        handleItemPerPage
    }
};

export default HospitalListingFunctions;