import React, { useState } from 'react';

const CustomTab = (props) => {
    const { currentTab } = props;

    // To store the index
   

    // To retrieve the index
    



    const handleTabClick = (e) => {
        localStorage.setItem('index', e.target.id);
        props.setCurrentTab(e.target.id);
    }

    return (
        <div className='custom_tabs_area'>
            <div className='custom_tabs'>
                {props.tabs.map((tab, i) =>
                    <button
                        key={i} id={tab.id}
                        disabled={parseInt(currentTab) === parseInt(tab.id)}
                        className={parseInt(currentTab) === parseInt(tab.id) ? 'active_btn' : 'inactive_btn'}
                        onClick={handleTabClick}>
                        {tab.tabTitle}
                    </button>
                )}
            </div>
        </div>
    );
}
export default CustomTab;