import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const UpdateVitalsModal = (props) => {
    const { values, handleChange, updateHealth, isError } = props;

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.close} 
                centered
                className="add_report_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Vitals</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="report_details_form">
                        <Form>
                            <Row>
                                <Col
                                    xxl={6}
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12} 
                                >
                                    <Form.Group
                                        className="form_group"
                                        controlId="heartBeat"
                                    >
                                        <Form.Label>
                                            Heartbeat (BPM)
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Ex. 89bpm"
                                            maxLength={3}
                                            value={values && values.heartbeat}
                                            onChange={(e) =>
                                                handleChange(
                                                    "heartbeat",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        {isError.heartbeat.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.heartbeat.message}
                                            </p>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                                <Col
                                    xxl={6}
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                >
                                    <Form.Group
                                        className="form_group"
                                        controlId="bloodPressure"
                                    >
                                        <Form.Label>
                                            Blood Pressure (mm/hg)
                                        </Form.Label>
                                        <Row>
                                            <Col
                                                xxl={6}
                                                xl={6}
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Ex. 102"
                                                    maxLength={3}
                                                    value={
                                                        values &&
                                                        values.bloodPressureTop
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "bloodPressureTop",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                {isError.bloodPressureTop
                                                    .message ? (
                                                    <p className="text-danger mt-2">
                                                        {
                                                            isError
                                                                .bloodPressureTop
                                                                .message
                                                        }
                                                    </p>
                                                ) : null}
                                            </Col>
                                            <Col
                                                xxl={6}
                                                xl={6}
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Ex. 72"
                                                    maxLength={2}
                                                    value={
                                                        values &&
                                                        values.bloodPressureBottom
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "bloodPressureBottom",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                {isError.bloodPressureBottom
                                                    .message ? (
                                                    <p className="text-danger mt-2">
                                                        {
                                                            isError
                                                                .bloodPressureBottom
                                                                .message
                                                        }
                                                    </p>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col
                                    xxl={12}
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                >
                                    <Form.Group
                                        className="form_group"
                                        controlId="temperature"
                                    >
                                        <Form.Label>
                                            Temperature (Celsius)
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Ex. 80"
                                            maxLength={3}
                                            value={values && values.temperature}
                                            onChange={(e) =>
                                                handleChange(
                                                    "temperature",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        {isError.temperature.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.temperature.message}
                                            </p>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                                <Col
                                    xxl={12}
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                >
                                    <Form.Group
                                        className="form_group"
                                        controlId="oxygenSaturation"
                                    >
                                        <Form.Label>
                                            Oxygen Saturation (Spo2/Prbpm)
                                            
                                        </Form.Label>
                                        <Row>
                                            <Col
                                                xxl={6}
                                                xl={6}
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Ex. 97 SPo2"
                                                    maxLength={3}
                                                    value={
                                                        values &&
                                                        values.oxygenSaturationTop
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "oxygenSaturationTop",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                {isError.oxygenSaturationTop
                                                    .message ? (
                                                    <p className="text-danger mt-2">
                                                        {
                                                            isError
                                                                .oxygenSaturationTop
                                                                .message
                                                        }
                                                    </p>
                                                ) : null}
                                            </Col>
                                            <Col
                                                xxl={6}
                                                xl={6}
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Ex. 60PRbpm"
                                                    maxLength={2}
                                                    value={
                                                        values &&
                                                        values.oxygenSaturationBottom
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "oxygenSaturationBottom",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                {isError.oxygenSaturationBottom
                                                    .message ? (
                                                    <p className="text-danger mt-2">
                                                        {
                                                            isError
                                                                .oxygenSaturationBottom
                                                                .message
                                                        }
                                                    </p>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col
                                    xxl={12}
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                >
                                    <Form.Group
                                        className="form_group"
                                        controlId="bloodSugar"
                                    >
                                        <Form.Label>
                                            Blood Sugar (mg/dl)
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Ex. 104mg/dl"
                                            maxLength={3}
                                            value={values && values.bloodSuger}
                                            onChange={(e) =>
                                                handleChange(
                                                    "bloodSuger",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        {isError.bloodSuger.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.bloodSuger.message}
                                            </p>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                                <Col
                                    xxl={12}
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                >
                                    <Form.Group
                                        className="form_group"
                                        controlId="bloodSugar"
                                    >
                                        <Form.Label>
                                            Weight (kg)
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Ex. 50Kg"
                                            maxLength={3}
                                            value={values && values.weight}
                                            onChange={(e) =>
                                                handleChange(
                                                    "weight",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        {isError.weight.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.weight.message}
                                            </p>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="btn_area">
                                <Button
                                    className="cancelBtn"
                                    type="button"
                                    onClick={props.close}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="submitBtn"
                                    onClick={() => updateHealth()}
                                >
                                    Update
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UpdateVitalsModal;
