import {
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Dropdown, Image, Row } from "react-bootstrap";
import { Filter, LogCalendar } from "../../../../assets/images";
import * as Constants from "../../../Helper/constants/constants";
import CustomDatepicker from "../../../Helper/customDatePicker";
import WithoutImageHeader from "../../../Helper/withoutImageHeader";
import PaginationComponent from "../../CommanComponents/Pagination";
import CalendarModal from "../../Modals/CalendarModal";
import FilterModal from "../../Modals/FilterModal";
import Task from "../../Tabs/Task";
import CustomTab from '../../../Helper/customTabs'
import MyTaskListFunctions from "./functions";
import MyTaskList from "../MyTaskList";
import moment from "moment";


const MyTasks = () => {
    const {
        tabs,
        currentTab,
        setCurrentTab,
        showCalendarModal,
        setShowCalendarModal,
        setSearchInput,
        searchInput,
        handleChange,
        taskListing,
        navigate,
        handleBackClick,
        handleNextClick,
        handleDeleteTask,
        page,
        setPage,
        count,
        totalPages,
        itemsPerPage,
        setItemsPerPage,
        showFilterModal,
        setShowFilterModal,
        handlePage,
        handleItemPerPage,
        loader,
        setLoader,
        deletConfirmationModal,
        setDeletConfirmationModal,
        deletItemDetail,
        setDeletItemDetail,
        applyFilters,
        filtersAppliedSign,
        handleClearAllFilters,
        setReduxValue,
        totalCount,
        setTotalCount,
        generateArrayOfYears,
        years,
        getMonths,
        months,
        values,
        setValues,
        handleDate,
        generateDates,
        dayMonth,
        getTaskListingSubAdmin,
        currentDate,
        handleBreadcrumDataNaviagtion,
        handleTabData,
        handleFilters,
        checkPermissions,
        getModulePermissions,
        taskCompletedByAdmin,
        breadcrumData,
        breadcrumItem,
    } = MyTaskListFunctions();

    useEffect(() => {
        // getTaskListingSubAdmin("", 1);
        generateDates();
        getModulePermissions();
    }, [])

    return (
        <div className="task_section">
            <WithoutImageHeader
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                header={Constants.MY_TASKS_DETAIL}
                lefticon={"menu"}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
            />
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="task_area">
                            <div className="task_btn_area">
                                <CustomTab
                                    tabs={tabs}
                                    currentTab={currentTab}
                                    setCurrentTab={(e) => (setCurrentTab(e), handleTabData(e))}
                                />
                            </div>
                            <div className="tab_details">
                                {/* <div className={parseInt(currentTab) === 1 ? 'sorting_data' : 'd-none'}>
                                    <div className="left_area">
                                        <p>Assigned Task</p>
                                    </div>
                                    <div className="right_area">
                                        <div className="sort_button">
                                            <Button className="btn-primary" type="submit"> Sort <Image src={Filter} alt="..." className="image_area" /> </Button>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="date_num_area">
                                    <div className="left_date_area">
                                        <Button className="btnm primary"
                                            onClick={() => setShowCalendarModal(true)}
                                        >
                                            <div className="icon">
                                                <Image src={LogCalendar}
                                                    alt="..."
                                                    className="image_area"
                                                />
                                            </div>
                                        </Button>

                                        {values && values.day ? <p>{values && values.day}{" "}
                                            {values && values.month ? values.month.slice(0, 3) : null}{" "}
                                            {values && values.year ? values.year : null}</p>
                                            : <p>{moment().format("DD MMM YYYY")}</p>}

                                        {/* <div className="button_area">
                                            <Button className="btnm primary" onClick={() => setShowCalendarModal(true)}>13 Aug 2023</Button>
                                            {
                                                showCalendarModal ? (
                                                    <CalendarModal show={showCalendarModal} close={() => setShowCalendarModal(false)} />
                                                ) : null
                                            }
                                        </div> */}
                                        {/* <p><FontAwesomeIcon icon={faCalendarMinus} className="date_icon" />13 Aug 2023</p> */}
                                    </div>
                                    <a>
                                        <div className="right_num_area">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="none" id="dropdown-basic"  >

                                                    <Button className="btnm primary">
                                                        <Image
                                                            src={Filter}
                                                            alt="..."
                                                            className="image_area"
                                                        />
                                                    </Button>

                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleFilters('ASC')}>
                                                        Ascending
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleFilters('DESC')}>
                                                        Descending
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </a>
                                </div>
                                {/* Custom_DatePicker */}

                                {dayMonth && dayMonth.length > 0 ?
                                    <CustomDatepicker
                                        dayMonth={dayMonth}
                                        handleDate={handleDate}
                                        values={values} />
                                    : null}

                                <div className="custom_tab_data">
                                    {parseInt(currentTab) === 1 ?
                                        <MyTaskList
                                            taskListing={taskListing}
                                            page={page}
                                            totalPages={totalPages}
                                            handlePage={handlePage}
                                            handleBackClick={handleBackClick}
                                            handleNextClick={handleNextClick}
                                            checkPermissions={checkPermissions}
                                            taskCompletedByAdmin={taskCompletedByAdmin}
                                            currentTab={currentTab}
                                        />
                                        :
                                        null
                                    }

                                    {parseInt(currentTab) === 2 ?
                                        <MyTaskList
                                            taskListing={taskListing}
                                            page={page}
                                            totalPages={totalPages}
                                            handlePage={handlePage}
                                            handleBackClick={handleBackClick}
                                            handleNextClick={handleNextClick}
                                            checkPermissions={checkPermissions}
                                            taskCompletedByAdmin={taskCompletedByAdmin}
                                            currentTab={currentTab}
                                        /> : null}

                                    {parseInt(currentTab) === 3 ?
                                        <MyTaskList
                                            taskListing={taskListing}
                                            page={page}
                                            totalPages={totalPages}
                                            handlePage={handlePage}
                                            handleBackClick={handleBackClick}
                                            handleNextClick={handleNextClick}
                                            checkPermissions={checkPermissions}
                                            taskCompletedByAdmin={taskCompletedByAdmin}
                                            currentTab={currentTab}
                                        /> : null}

                                </div>

                                {/* <div className="pagination_space">
                                    <PaginationComponent />
                                </div> */}
                                {/* <div className="assign_task">
                                    <a >
                                        <div className="circle_area" onClick={() => navigate('/AssignTask')}>
                                            <div className="add_icon_area">
                                                <FontAwesomeIcon icon={faPlus} />
                                                <p>Assign Task</p>
                                            </div>
                                        </div>
                                    </a>
                                </div> */}

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            {
                showCalendarModal ? (
                    <CalendarModal
                        generateArrayOfYears={generateArrayOfYears}
                        years={years}
                        getMonths={getMonths}
                        months={months}
                        values={values}
                        setValues={setValues}
                        handleDate={handleDate}
                        show={showCalendarModal}
                        generateDates={generateDates}
                        close={() => setShowCalendarModal(false)}
                    />
                ) : null
            }
        </div>
    );
};

export default MyTasks;
