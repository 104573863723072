//------------- REACT IMPORT ---------------//
import React, { useEffect } from "react";
//-------- BOOTSTRAP ITEMS IMPORT ---------//
import { Button, Card, Image, Modal } from "react-bootstrap";
//----------- IMAGES IMPORT --------------//
import { CheckedImg } from "../../../../assets/images";
import { useNavigate } from "react-router";
import TaskDetailFunctions from "../../CommanScreens/MyTaskDetails/functions";
import { dateFormat, timeFormat, timeFormat3 } from "../../../Helper/General";

const TaskModal = (props) => {
  
    const {
        getTaskDetail,
        taskData,
        loader,
        loaction,
        navigate,
        values,
        setValues,
        isError,
        handleSubmitComment,
        handleChange,
        defaultValues,
        handleTaskCompleted,
        showAssignedModal,
        setShowAssignedModal,
        completeConfirmationModal,
        setcompleteConfirmationModal,
        breadcrumData,
        breadcrumItem,
        handleBreadcrumDataNaviagtion
    } = TaskDetailFunctions();


    useEffect(() => {
        getTaskDetail(props.taskResponse && props.taskResponse.id);
    }, []);

    return (
        <div>
            <Modal show={props.show} onHide={props.close} centered>
                <div className="modal_area">
                    <div className="modal_data">
                        <div className="modal_img">
                            <div className="img_one">
                                <Image src={CheckedImg} alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal.Body>
                    <div className="message">
                        <h3>Task assigned successfully!</h3>
                        {taskData ? <Card>
                            <Card.Body>
                                <div className="card_data">
                                    <div className="left_area">
                                        <div className="details">
                                            <ul>
                                                <li><h5>Assign to <span>:</span></h5></li>
                                                <li><h5>Patient Name <span>:</span></h5></li>
                                                <li><h5>Task Date <span>:</span></h5></li>
                                                <li><h5>Task Time <span>:</span></h5></li>
                                                <li><h5>Task Priority <span>:</span></h5></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="right_area">
                                        <ul>
                                            <li><p>{taskData && taskData.assigned_members && taskData.assigned_members.length > 0  ? `${taskData.assigned_members[0].name} ${taskData.assigned_members.length > 1 ? `+ ${taskData.assigned_members.length -1} More` : ''}` : null}</p></li>
                                            {taskData && taskData.patient_name ? <li><p>{taskData.patient_name}</p></li> : null}
                                            {taskData && taskData.start_date ? <li><p>{dateFormat(taskData.start_date)}</p></li> : null}
                                            {taskData && taskData.start_time && taskData.end_time? <li><p>{timeFormat3(taskData.start_time)} to {timeFormat3(taskData.end_time)} </p></li> : <li><p>{timeFormat3(taskData.start_time)} </p></li>}
                                            {taskData && taskData.priority ? <li><p>{taskData.priority && parseInt(taskData.priority) === 1 ? "Low" : parseInt(taskData.priority) === 2 ? "Medium" : parseInt(taskData.priority) === 3 ? "High" : null}</p></li> : null}
                                        </ul>

                                    </div>
                                </div>
                            </Card.Body>
                        </Card> : null}
                    </div>
                    <div className="modal_btn">
                        <div className="btn_area">
                            <Button className="cancelBtn" type="button" onClick={() => navigate(-1, { replace: true })}>
                                Close
                            </Button>
                            <Button className="submitBtn" type="submit" onClick={() => navigate(-1, { replace: true })}>
                                Go to listing
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default TaskModal;