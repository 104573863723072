import { permissionsServices } from "../../Services/PermissionsServices/permissions.services";


const permissionsListing = async () => {
    let response = await permissionsServices.permissionsListing();
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const userpermissionsListing = async () => {
    let response = await permissionsServices.userpermissionsListing();
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const permissionsDetails = async (params) => {
    let response = await permissionsServices.permissionsDetail(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const permissionsController = {
    permissionsListing,
    userpermissionsListing,
    permissionsDetails,
};

export default permissionsController;
