import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from 'react-select';
import Loader from "../../../Helper/loader";
import { spaceAfter4Digit } from "../../../Helper/General";
import moment from "moment";
import CustomSelectOptions from "../../../Helper/customSelectOption";


const DemographicDetailTab = (props) => {
    const {
        values,
        isError,
        navigate,
        gendersList,
        martialStatusList,
        bloodGroupsList,
        nationalitiesList,
        loader,
        getStatesListing,
        patientCreateOrUpdateValue
    } = props


    const handleChange = (field, value) => {
        props.handleChange(field, value);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        props.handleSubmit();
    };


    const customStyles = {
        option: provided => ({
            ...provided,
            color: 'black',
            backgroundColor: 'white'
        }),
    };


    return (
        <div className="demographic_tab_screen ">
            {loader ? <Loader loader={loader} /> : <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Form>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    Father/Husband Name<span>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter father/husband name here"
                                    value={values.fatherOrHusbandName}
                                    className={isError && isError.fatherOrHusbandName.message
                                        ? "error_border"
                                        : ""}
                                    onChange={(e) =>
                                        handleChange("fatherOrHusbandName", e.target.value)
                                    }
                                />
                                {isError && isError.fatherOrHusbandName.message ? (
                                    <p className="text-danger mt-2">
                                        {isError && isError.fatherOrHusbandName.message}
                                    </p>
                                ) : null}
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    Mother Name/Spouse
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter mother/Spouse name here"
                                    value={values.motherName}
                                    className={isError && isError.motherName.message
                                        ? "error_border"
                                        : ""}
                                    onChange={(e) =>
                                        handleChange("motherName", e.target.value)
                                    }
                                />
                                {isError && isError.motherName.message ? (
                                    <p className="text-danger mt-2">
                                        {isError && isError.motherName.message}
                                    </p>
                                ) : null}
                            </Form.Group>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                                    <Form.Group className="form-group">
                                        <Form.Label>
                                            Date of Birth<span>*</span>
                                        </Form.Label>
                                        {/* {<Datepicker />} */}
                                        <Form.Control
                                            type="date"
                                            placeholder="DD/MM/YYYY"
                                            value={values.dob}
                                            max={moment().format('YYYY-MM-DD')}
                                            className={isError && isError.dob.message
                                                ? "error_border"
                                                : ""}
                                            onChange={(e) =>
                                                handleChange("dob", e.target.value)
                                            }
                                        />
                                        {isError && isError.dob.message ? (
                                            <p className="text-danger mt-2">
                                                {isError && isError.dob.message}
                                            </p>
                                        ) : null}
                                        {/* <div className="date_img">
                                            <Image src={Calender} alt="..." />
                                        </div> */}

                                    </Form.Group>
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12}>
                                    <div className="dropdown_box">
                                        <h6>Gender<span>*</span></h6>
                                        <div className="dropdown_area">
                                            <Select
                                                placeholder="Select"
                                                value={values.gender}
                                                options={gendersList}
                                                onChange={(item) => handleChange('gender', item)}
                                                className={isError.gender.message ? "error_border_select" : ""}
                                                components={{
                                                    Option: props => (
                                                        <CustomSelectOptions
                                                            items={props}
                                                            value={values && values.gender}
                                                        />
                                                    )
                                                }}
                                            />
                                            {isError && isError.gender.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError && isError.gender.message}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                                    <div className="dropdown_box">
                                        <h6>Blood Group</h6>
                                        <div className="dropdown_area">
                                            <Select
                                                placeholder="Select"
                                                value={values.bloodGroup}
                                                options={bloodGroupsList ? bloodGroupsList : []}
                                                onChange={(item) => handleChange('bloodGroup', item)}
                                                className={isError.bloodGroup.message ? "error_border_select" : ""}
                                                components={{
                                                    Option: props => (
                                                        <CustomSelectOptions
                                                            items={props}
                                                            value={values && values.bloodGroup}
                                                        />
                                                    )
                                                }}
                                            />
                                            {isError && isError.bloodGroup.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError && isError.bloodGroup.message}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Form.Group className="form-group">
                                        <Form.Label>
                                            Age<span>*</span>
                                        </Form.Label>
                                        <Form.Control
                                            disabled={true}
                                            type="text"
                                            placeholder="Enter age here"
                                            value={values && values.age ? values.age : null}
                                            // className={isError && isError.age.message
                                            //     ? "error_border"
                                            //     : ""}
                                            onChange={(e) =>
                                                handleChange("age", e.target.value)
                                            }
                                        />
                                        {/* {isError && isError.age.message ? (
                                            <p className="text-danger mt-2">
                                                {isError && isError.age.message}
                                            </p>
                                        ) : null} */}
                                    </Form.Group>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className="dropdown_box">
                                        <h6>Marital Status<span>*</span></h6>
                                        <div className="dropdown_area">
                                            <Select
                                                placeholder="Select"
                                                value={values.martialStatus}
                                                options={martialStatusList ? martialStatusList : []}
                                                onChange={(item) => handleChange('martialStatus', item)}
                                                className={isError.martialStatus.message ? "error_border_select" : ""}
                                                components={{
                                                    Option: props => (
                                                        <CustomSelectOptions
                                                            items={props}
                                                            value={values && values.martialStatus}
                                                        />
                                                    )
                                                }}
                                            />
                                            {isError && isError.martialStatus.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError && isError.martialStatus.message}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    Occupation
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter occupation here"
                                    value={values.occupation}
                                    className={isError && isError.occupation.message
                                        ? "error_border"
                                        : ""}
                                    onChange={(e) =>
                                        handleChange("occupation", e.target.value)
                                    }
                                />
                                {isError && isError.occupation.message ? (
                                    <p className="text-danger mt-2">
                                        {isError && isError.occupation.message}
                                    </p>
                                ) : null}
                            </Form.Group>

                            <div className="dropdown_box">
                                <h6>Nationality<span>*</span></h6>
                                <div className="dropdown_area">
                                    <Select
                                        // styles={customStyles}
                                        placeholder="Select nationality"
                                        value={values.nationality}
                                        options={nationalitiesList}
                                        onChange={(item) => (handleChange('nationality', item), getStatesListing(item && item.id ? item.id : ''))}
                                        className={isError.nationality.message ? "error_border_select" : ""}
                                        components={{
                                            Option: props => (
                                                <CustomSelectOptions
                                                    items={props}
                                                    value={values && values.nationality}
                                                />
                                            )
                                        }}
                                    // menuIsOpen={true}
                                    />
                                    {isError && isError.nationality.message ? (
                                        <p className="text-danger mt-2">
                                            {isError && isError.nationality.message}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    Aadhar Number
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter aadhar number here"
                                    value={values.adharNumber}
                                    className={isError && isError.adharNumber.message
                                        ? "error_border"
                                        : ""}
                                    onChange={(e) =>
                                        handleChange("adharNumber", spaceAfter4Digit(e.target.value))
                                    }
                                />
                                {isError && isError.adharNumber.message ? (
                                    <p className="text-danger mt-2">
                                        {isError && isError.adharNumber.message}
                                    </p>
                                ) : null}
                            </Form.Group>
                            <div className="btn_area">
                                <Button className="cancelBtn" type="button" onClick={() => navigate(-1, { replace: true })}>Cancel</Button>
                                <Button className="submitBtn" type="submit" onClick={handleSubmit}>Next</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>}
        </div>
    )
};

export default DemographicDetailTab;