//------------ REACT IMPORT --------------//
import React, { useState } from "react";
//---------- FONTAWESOME IMPORT ----------//
import { faEllipsisVertical, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//--------- BOOTSTRAP ITEMS IMPORT ----------//
import { Col, Dropdown, Image, Row } from "react-bootstrap";
//------------ IMAGES IMPORT ---------------//
import { useNavigate } from "react-router-dom";
import {
    Man
} from "../../../../assets/images";
import { dateFormat, renderImage, timeFormat2, timeFormat3 } from "../../../Helper/General";
import NoDataComponent from "../../CommanComponents/NoDataComponent";
import PaginationComponent from "../../CommanComponents/Pagination";
import AssignedMemberModal from "../../Modals/AssignedMember";

const Task = (props) => {
    const {
        List,
        page,
        handlePage,
        handleBackClick,
        handleNextClick,
        totalPages,
        patientCaseDetail,
        patientData,
        checkTaskPermissions,
        User,
        isUserCanCompleteTheTask
    } = props;

    const [assignedMembers, setAssignMembersData] = useState(null);
    const [showAssignedModal, setShowAssignedModal] = useState(false);
    let navigate = useNavigate();


    const taskCompletedByAdmin = (item) => {
        if (props.taskCompletedByAdmin) {
            props.taskCompletedByAdmin(item);
        } else return;
    };


    return (
        <>
            <div className="card_section">
                <Row>
                    {List && List.length > 0
                        ? List.map((item, i) => (
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} key={i} >
                                <div className="card_area">
                                    <div className="sub_title">
                                        <div className="left_area">
                                            <p>
                                                {dateFormat(item.created)}{" "}
                                                {timeFormat2(item.created)}
                                            </p>
                                        </div>
                                        {parseInt(item.task_status) === 1 ? (
                                            <div className="right_area">
                                                <p>Complete</p>
                                            </div>
                                        ) : parseInt(item.task_status) ===
                                            0 ? (
                                            <div className="right_area test">
                                                <p>Pending</p>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div
                                        className={
                                            parseInt(item.priority) === 1
                                                ? "card_inner pending"
                                                : "card_inner"
                                        }
                                    >
                                        <div className="card_inner_detail">
                                            <div className="sub_title">
                                                {parseInt(item.priority) ===
                                                    1 ? (
                                                    <div className="left_area testt">
                                                        <p>Low</p>
                                                    </div>
                                                ) : parseInt(
                                                    item.priority
                                                ) === 2 ? (
                                                    <div className="left_area medium">
                                                        <p>Medium</p>
                                                    </div>
                                                ) : parseInt(
                                                    item.priority
                                                ) === 3 ? (
                                                    <div className="left_area">
                                                        <p>High</p>
                                                    </div>
                                                ) : null}
                                                {/* <div className="task_right_area">
                                                    <p>Completed</p>
                                                </div> */}
                                            </div>
                                            <div className="title_area">
                                                <h5 style={{ cursor: 'pointer' }} onClick={() => navigate("/myTaskDetails", { state: { id: item.id, fromNavigation: true } })}>
                                                    {item.title
                                                        ? item.title
                                                        : ""}
                                                </h5>
                                            </div>
                                            <div className="description">
                                                <p>
                                                    {item.description
                                                        ? item.description
                                                        : ""}
                                                </p>
                                                <p style={{ paddingBottom: '3px', paddingTop: '3px' }}>
                                                    <h6>Task Date:{" "}
                                                        <span>
                                                            {item.start_date
                                                                ? dateFormat(item.start_date)
                                                                : ""}
                                                        </span>
                                                    </h6>
                                                </p>
                                                {item.start_time &&
                                                    item.end_time ? (
                                                    <p>
                                                        <h6>Timing:{" "}
                                                            <span>
                                                                {timeFormat3(item.start_time)}{" "}
                                                                to{" "}
                                                                {timeFormat3(item.end_time)}
                                                            </span>
                                                        </h6>
                                                    </p>
                                                ) : <p>
                                                    <h6>Timing:{" "}
                                                        <span>
                                                            {timeFormat3(item.start_time)}
                                                        </span>
                                                    </h6>
                                                </p>}
                                                {item.completed_on ? (
                                                    <p style={{ paddingTop: '3px' }}>
                                                        <h6>Completed On:{" "}
                                                            <span>
                                                                {dateFormat(item.completed_on)}{" "}
                                                                {timeFormat2(item.completed_on)}
                                                            </span>
                                                        </h6>
                                                    </p>
                                                ) : null}
                                            </div>
                                            {item.assigned_members &&
                                                item.assigned_members.length > 0 ?
                                                <div className="group_image" onClick={() => (setAssignMembersData(item), setShowAssignedModal(true))}>
                                                    <div className="image_area">
                                                        <Image
                                                            src={item && item.assigned_members && item.assigned_members[0].admin_image ? renderImage(item.assigned_members[0].admin_image) : Man}
                                                            alt="..."
                                                        />
                                                    </div>
                                                    {item.assigned_members.length > 1 ? (
                                                        <div className="image_area img_2">
                                                            <Image
                                                                src={item.assigned_members[1].admin_image ? renderImage(item.assigned_members[1].admin_image) : Man}
                                                                alt="..."
                                                            />
                                                        </div>
                                                    ) : null}
                                                    {item.assigned_members.length === 3 ? (
                                                        <div className="image_area img_2">
                                                            <Image
                                                                src={item.assigned_members[2].admin_image ? renderImage(item.assigned_members[2].admin_image) : Man}
                                                                alt="..."
                                                            />
                                                        </div>
                                                    ) : item.assigned_members.length > 3 ? (
                                                        <a>
                                                            <div className="image_area img_3">
                                                                <p>3+</p>
                                                            </div>
                                                        </a>
                                                    ) : null}
                                                </div>
                                                : null}
                                        </div>
                                        <div className="bottom_area">
                                            <div className="inner_area">
                                                <div className="left_area">
                                                    <div className="img_area">
                                                        <Image
                                                            variant="left"
                                                            src={item.assign_by_image ? renderImage(item.assign_by_image) : Man}
                                                            alt="..."
                                                            onClick={() => navigate(`/staff-detail/${item.created_by}`, { state: { name: item.assign_by, fromNavigation: true } })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="right_area">
                                                    <div className="name_area">
                                                        <u style={{ color: "#E31E24" }}>
                                                            <h3 style={{ cursor: 'pointer' }} onClick={() => navigate(`/staff-detail/${item.created_by}`, { state: { name: item.assign_by, fromNavigation: true } })}>
                                                                {item.assign_by
                                                                    ? item.assign_by
                                                                    : ""}

                                                            </h3>
                                                        </u>
                                                        <p>
                                                            {item.role_title
                                                                ? item.role_title
                                                                : ""}
                                                        </p>
                                                        <Dropdown>
                                                            <Dropdown.Toggle
                                                                variant="none"
                                                                id="dropdown-basic"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEllipsisVertical
                                                                    }
                                                                    className="ellipsis_icon"
                                                                />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => navigate("/myTaskDetails", { state: { id: item.id, fromNavigation: true } })}  >
                                                                    View
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                            {parseInt(item.task_status) === 0 ? <>
                                                                {User && parseInt(User.user_type) === 1 || isUserCanCompleteTheTask ?
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item onClick={() => taskCompletedByAdmin(item)}>
                                                                            Complete
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu> : null}
                                                            </> : null}
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))
                        :
                        <NoDataComponent
                            message="No Task Found!"
                        />
                    }
                </Row>
                {
                    User && parseInt(User.user_type) === 1
                        && patientData && patientData.patient_status !== 'discharge' ?
                        <div className="assign_task">
                            <a >
                                <div className="circle_area" onClick={() => navigate('/assignTask', { state: { patientCaseDetail: patientCaseDetail, patientData: patientData } })} style={{ cursor: 'pointer' }}>
                                    <div className="add_icon_area" >
                                        <FontAwesomeIcon icon={faPlus} />
                                        <p style={{ cursor: 'pointer' }}>Assign Task</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        :
                        checkTaskPermissions
                            && checkTaskPermissions.permissions
                            && checkTaskPermissions.permissions.length > 1
                            && parseInt(checkTaskPermissions.permissions[1].value) === 1 &&
                            patientData && patientData.patient_status !== 'discharge' ?

                            <div className="assign_task">
                                <a >
                                    <div className="circle_area" onClick={() => navigate('/assignTask', { state: { patientCaseDetail: patientCaseDetail, patientData: patientData } })} style={{ cursor: 'pointer' }}>
                                        <div className="add_icon_area" >
                                            <FontAwesomeIcon icon={faPlus} />
                                            <p style={{ cursor: 'pointer' }}>Assign Task</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            :
                            null
                }
            </div>
            {List && List.length > 0 ? (
                <PaginationComponent
                    page={page}
                    setPage={handlePage}
                    totalPages={totalPages}
                    handleBackClick={handleBackClick}
                    handleNextClick={handleNextClick}
                />
            ) : null}
            {
                showAssignedModal && assignedMembers && assignedMembers.assigned_members.length > 0 ? (
                    <AssignedMemberModal
                        show={showAssignedModal}
                        assignedMembers={assignedMembers && assignedMembers.assigned_members}
                        close={() => setShowAssignedModal(false)}
                    />
                ) : null
            }
        </>
    );
};

export default Task;
