import { faSearch } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { Button, Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";
import DepartmentListFilterFunctions from "./functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoDataComponent from "../../CommanComponents/NoDataComponent";

const DepartmentListFilterModal = (props) => {
    const {
        departmentStatus,
        SortByList,
        handleDepartmentStatusFilters,
        handleSortByFilters,
        applyFilters,
        handleClearAllFilters,
        handleHospitalsSearch,
        hospitalsSearch,
        hospitalsList,
        getHospitalListing,
        handleHospitalsFilters
    } = DepartmentListFilterFunctions(props);

    useEffect(() => {
        getHospitalListing('');
    }, [])

    return (
        <div>
            <Modal show={props.show} onHide={props.close} centered className="filter_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="filter_details">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Hospital</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Department Status</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Sort By</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                                    <div className="content_area">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <Form className="ward_form">
                                                    <Form.Group className="form-group">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter hospital name here"
                                                            value={hospitalsSearch}
                                                            onChange={handleHospitalsSearch}
                                                        />
                                                        <a >
                                                            <div className="icon">
                                                                <FontAwesomeIcon icon={faSearch} />
                                                            </div>
                                                        </a>
                                                    </Form.Group>
                                                    <div className="check_box_slider">
                                                        {
                                                            hospitalsList && hospitalsList.length > 0 ?
                                                                hospitalsList.map((item, i) => (
                                                                    <Form.Check
                                                                        key={i}
                                                                        type="checkbox"
                                                                        id={item.id}
                                                                        label={item.title}
                                                                        checked={item.checked}
                                                                        onChange={() => handleHospitalsFilters(item)}
                                                                    />
                                                                ))
                                                                :
                                                                <NoDataComponent
                                                                    message="No data Found!"
                                                                />
                                                        }
                                                    </div>
                                                </Form>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <Form className="ward_form">
                                                    <div className="check_box_slider">
                                                        {
                                                            departmentStatus.map((item, i) => (
                                                                <Form.Check
                                                                    key={i}
                                                                    type="checkbox"
                                                                    id={item.id}
                                                                    label={item.title}
                                                                    checked={item.checked}
                                                                    onChange={() => handleDepartmentStatusFilters(item)}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                </Form>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="third">
                                                <Form className="ward_form">
                                                    <div className="check_box_slider">
                                                        {
                                                            SortByList.map((item, i) => (
                                                                <Form.Check
                                                                    key={i}
                                                                    type="checkbox"
                                                                    id={item.id}
                                                                    label={item.title}
                                                                    checked={item.checked}
                                                                    onChange={() => handleSortByFilters(item)}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                </Form>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </Col>
                            </Row>
                        </Tab.Container>
                        <div className="btn_area">
                            <Button
                                className="cancelBtn"
                                type="button"
                                onClick={() => handleClearAllFilters()}
                            >Clear All</Button>
                            <Button
                                className="submitBtn"
                                type="submit"
                                onClick={() => applyFilters()}
                            >Apply</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default DepartmentListFilterModal;