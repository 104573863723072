import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function casesList(params) {
    return mainWrapper.get(WebConstant.host + "/case_list", params);
};


function createCase(params) {
    return mainWrapper.post(WebConstant.host + `/create_case`, params);
};


function caseDetail(id) {
    return mainWrapper.get(WebConstant.host + `/case_detail/${id}`);
};


function caseUpdate(id, params) {
    return mainWrapper.put(WebConstant.host + `/update_case/${id}`, params);
};


function caseDelete(id) {
    return mainWrapper._delete(WebConstant.host + `/patient/${id}`);
};


export const casesServices = {
    casesList,
    createCase,
    caseDetail,
    caseUpdate,
    caseDelete,
};
