import { Toaster } from "../../../components/Helper/toaster/Toaster";
import { rolesServices } from "../../Services/RolesServices/roles.services";


const rolesListing = async (search) => {
    let response = await rolesServices.rolesListing(search);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const rolesListingDropdown = async (search) => {
    let response = await rolesServices.rolesListingDropdown(search);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const rolesCreate = async (data, value) => {
    const newArray = data.map(obj => ({
        slug: obj.type,
        listing: parseInt(obj.permissions[0].value),
        create: parseInt(obj.permissions[1].value),
        update: parseInt(obj.permissions[2].value),
        delete: parseInt(obj.permissions[3].value)
    }));

    let post = {
        title: value.role,
        permissions: newArray,
        is_super_admin: value.is_super_admin
    }
    let response = await rolesServices.rolesCreate(post);
    if (response) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const roleDetail = async (id) => {
    let response = await rolesServices.rolesDetail(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const roleUpdate = async (data, value, id) => {
    const newArray = data.map(obj => ({
        slug: obj.type,
        listing: parseInt(obj.permissions[0].value),
        create: parseInt(obj.permissions[1].value),
        update: parseInt(obj.permissions[2].value),
        delete: parseInt(obj.permissions[3].value)
    }));

    let post = {
        title: value.role,
        permissions: newArray,
        is_super_admin: value.is_super_admin
    }
    let response = await rolesServices.rolesUpdate(post, id);
    if (response) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const roleDelete = async (id) => {
    let response = await rolesServices.roleDelete(id);
    if (response) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const rolesController = {
    rolesListing,
    rolesListingDropdown,
    rolesCreate,
    roleDetail,
    roleUpdate,
    roleDelete
};

export default rolesController;
