import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function commentListing (params) {
    return mainWrapper.get(WebConstant.host + "/task_comment_list", params);
};


function commentCreate (params) {
    return mainWrapper.post(WebConstant.host + `/create_task_comment`, params);
};


export const commentServices = {
    commentListing,
    commentCreate,
};
