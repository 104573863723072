import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import AuthHeader from "../../../Helper/authHeader";
import * as Constants from "../../../Helper/constants/constants";
import EmailVerificationFunctions from "./function";
import OTPInput from "react-otp-input";
import ButtonLoader from "../../../Helper/buttonLoader";
import { useNavigate } from "react-router-dom";

const EmailVerification = () => {
    const {
        values,
        error,
        loader,
        handleChange,
        handleSubmit,
    } = EmailVerificationFunctions();
    const navigation = useNavigate();

    return (
        <div className="forget_password_screen">
            <AuthHeader
                header={Constants.EMAIL_VERIFICATION}
                description={Constants.FOGET_PASSWORD_DESCRIPTION}
            />
            <Container>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="form">
                        <Form>
                            <div className="input_otp" style={{ height: '100%', width: '100%' }}>
                                <OTPInput
                                    value={values && values.otp}
                                    onChange={(e) => handleChange('otp', e)}
                                    numInputs={6}
                                    // renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} />}
                                    // inputStyle={{
                                    //     height: '100px', width: '100px', margin: '0px 5px', fontSize: '30px', color: '#7A7A7A', fontWeight: 500,
                                    //     border: '1px solid #7A7A7A', borderRadius: '3px', ':focus': { outlineStyle: 'solid', outlineColor: 'red', }
                                    // }}
                                    placeholder={''}
                                    containerStyle={{ display: "flex", justifyContent: 'center' }}
                                    // inputType={inputType}
                                    shouldAutoFocus
                                />
                                {error.otp.message ? (
                                    <p className="text-danger mt-2">
                                        {error.otp.message}
                                    </p>
                                ) : null}
                            </div>
                            {/* <Button
                                type="submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                                disabled={loader ? true : false}
                                style={{ marginTop: '70px' }}
                            >
                                {loader ? (
                                    <ButtonLoader
                                        loader={loader}
                                        variant={"light"}
                                        disabled={loader ? true : false}
                                    />
                                ) : null}
                                {loader ? "" : "Submit"}
                            </Button> */}
                            <div className="btn_area more_space">
                                <Button
                                    className="cancelBtn"
                                    type="button"
                                    onClick={() => navigation(-1)}
                                >
                                    Back
                                </Button>
                                <Button
                                    className="submitBtn"
                                    type="submit"
                                    disabled={loader ? true : false}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                >
                                    {loader ? (
                                        <ButtonLoader
                                            loader={loader}
                                            variant={"light"}
                                            disabled={loader ? true : false}
                                        />
                                    ) : null}
                                    {loader ? "" : "Submit"}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Container>
        </div>
    );
};

export default EmailVerification;
