import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function roomsListing (params) {
    return mainWrapper.get(WebConstant.host + `/room`, params);
};

function superAdminRoomsListing (params) {
    return mainWrapper.get(WebConstant.host + `/super_admin_room_list`, params);
};

function super_room_list_dropdown (params) {
    return mainWrapper.get(WebConstant.host + `/room_list_dropdown`, params);
};

function super_room_list_filters (params) {
    return mainWrapper.get(WebConstant.host + `/room_list_filters`, params);
};

function roomsCreate (params) {
    return mainWrapper.post(WebConstant.host + `/room`, params);
};


function roomsDetail (id) {
    return mainWrapper.get(WebConstant.host + `/room_details/${id}`);
};


function roomsUpdate (id,params) {
    return mainWrapper.put(WebConstant.host + `/room/${id}`,params);
};


function roomsDelete (id) {
    return mainWrapper._delete(WebConstant.host + `/room/${id}`);
};

function updateRoomStatus(id, params) {
    return mainWrapper.put(WebConstant.host + `/update_room_status/${id}`,params);
 }

export const roomsServices = {
    roomsListing,
    superAdminRoomsListing,
    super_room_list_dropdown,
    super_room_list_filters,
    roomsCreate,
    roomsDetail,
    roomsUpdate,
    roomsDelete,
    updateRoomStatus
};
