import React, { useEffect } from "react";
import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../../../../Helper/buttonLoader";
import * as Constants from "../../../../Helper/constants/constants";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import AddRolesFunctions from "./functions";
import Loader from "../../../../Helper/loader";


const AddRole = () => {
    const navigate = useNavigate();
    const {
        showFilterModal,
        setShowFilterModal,
        getPermissionsListing,
        permissions,
        values,
        isError,
        handleChange,
        handleSubmit,
        handleCheck,
        handleAllSelect,
        selectAll,
        superAdmin,
        breadcrumItem,
        setbreadcrumItem,
        getRoleDetail,
        loader,
        permissonsArrayList,
        breadcrumData,
        loaction,
        handleBreadcrumDataNaviagtion,
        handleSuperAdminSelect
    } = AddRolesFunctions();


    useEffect(() => {
        if (loaction && loaction.state && loaction.state.name) {
            setbreadcrumItem('Edit Role');
        }
        else {
            setbreadcrumItem('Add Role');
        }
        getPermissionsListing();
    }, []);


    useEffect(() => {                                                               // <-------------------EDIT ROLE CASE
        if (loaction && loaction.state && loaction.state.name) {
            if (permissonsArrayList && permissonsArrayList.length > 0) {
                let id = loaction && loaction.state && loaction.state.id ? loaction.state.id : ''
                getRoleDetail(id);
            }
        }
    }, [permissonsArrayList]);


    return (
        <div className="add_role_screen">
            <WithoutImageHeader
                header={loaction && loaction.state && loaction.state.name ? Constants.EDIT_ROLE : Constants.ADD_ROLE}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBack={() => navigate(-1)}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
            />
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        {
                            loader ?
                                <Loader
                                    loader={loader}
                                />
                                : null}
                        <div className="add_role_details">
                            <div className="role_area_title">
                                <div className="role_area">
                                    <p>Role<span>*</span></p>
                                </div>
                            </div>
                            <div className="search_section input_width">
                                <div className="search_area">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter your role"
                                                value={values.role}
                                                className={isError.role.message
                                                    ? "error_border"
                                                    : ""}
                                                onChange={(e) =>
                                                    handleChange(
                                                        "role",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            {isError.role.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError.role.message}
                                                </p>
                                            ) : null}
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>
                            <div className="title_btn_area">
                                <div className="left_area">
                                    <Form.Check
                                        reverse
                                        type="checkbox"
                                        id="Critical"
                                        label="User is Super Admin"
                                        checked={superAdmin}
                                        onChange={(e) => handleSuperAdminSelect(e.target.checked)}
                                    />
                                </div>
                                <div className="right_area">
                                    <Form.Check
                                        reverse
                                        type="checkbox"
                                        id="Critical"
                                        label="Select all"
                                        checked={selectAll}
                                        onChange={(e) => handleAllSelect(e.target.checked)}
                                    />
                                </div>
                            </div>
                            {
                                permissions && permissions.length > 0 ?
                                    <>
                                        <div className="list_detail">
                                            <div className="list_data">
                                                <Card>
                                                    <Card.Body>
                                                        <h4>User Permission </h4>
                                                        <Table responsive>
                                                            <tr>
                                                                <th>
                                                                    <ul className="list-inline">
                                                                        <li>Module</li>
                                                                        <li className="list-gap">Listing</li>
                                                                        <li className="list-gap">Create</li>
                                                                        <li className="list-gap">Update</li>
                                                                        <li className="list-gap">Delete</li>
                                                                    </ul>
                                                                </th>
                                                            </tr>
                                                            {
                                                                permissions.map((item, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>
                                                                                <ul className="list-inline">
                                                                                    <li>{item.title}</li>
                                                                                    {
                                                                                        item.permissions.map((data, i) => {
                                                                                            return (
                                                                                                <li className="list-gap" key={i}>
                                                                                                    <Form.Check
                                                                                                        // reverse
                                                                                                        type="checkbox"
                                                                                                        id="Critical"
                                                                                                        checked={parseInt(data.value) === 1 ? true : false}
                                                                                                        onChange={() => handleCheck(data, item)}
                                                                                                        className="check_icon"
                                                                                                    />
                                                                                                </li>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </Table>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                        <Button
                                            className="btn"
                                            type="submit"
                                            disabled={loader ? true : false}
                                            onClick={() => handleSubmit()}
                                        >
                                            Submit
                                        </Button>
                                    </>
                                    :
                                    null
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddRole;