import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";

const CalendarModal = (props) => {
    
    const { generateArrayOfYears,years,getMonths,months,values,handleDate, generateDates } = props;
    
    useEffect(() => {
        generateArrayOfYears();
        getMonths();
    }, [])
    
    return (
        <div>
            <Modal show={props.show} onHide={props.close} centered className="calendar_modal">
                <Modal.Header className="header_area" closeButton>
                    <Modal.Title className="title">Calendar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="report_details_form">

                        <div className="year_btns">

                            <Dropdown className="btn" >
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="show">
                                    {values && values.month ? values.month : "Select"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="list" defaultActiveKey="first">
                                    {months && months.map((item, index) => {
                                        return (
                                            <Dropdown.Item href="javascript:;" key={index}  onClick={()=>handleDate("month",item)}>{item}</Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown className="btn" >
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="show" >
                                    {values && values.year ? values.year : "Select"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="list" >
                                    {years && years.map((item, index) => {
                                        return (
                                           <Dropdown.Item href="javascript:;" key={index} onClick={()=>handleDate("year",item)} >{item}</Dropdown.Item>
                                       )
                                   })}    
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>

                        <div className="btn_area">
                            <Button className="cancelBtn" type="button" onClick={props.close}>Cancel</Button>
                            <Button className="submitBtn" type="submit" onClick={()=> generateDates()}>Submit</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CalendarModal;