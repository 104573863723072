import React from "react";
import { Col, Container, Image, Modal, Row } from "react-bootstrap";
import { Assigned_Member, Man } from "../../../../assets/images";
import { renderImage } from "../../../Helper/General";
import { useNavigate } from "react-router-dom";

const AssignedMemberModal = (props) => {
    let navigate = useNavigate();

    return (
        <div >
            <Modal show={props.show} onHide={props.close} centered className="assigned_member_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Assigned Member</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="assigned_member_area">
                                    <Row>
                                        {
                                            props && props.assignedMembers ?
                                                props.assignedMembers.map((item, i) => (
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6}>
                                                        <div className="item" key={i}
                                                            onClick={() => navigate(`/staff-detail/${item.admin_id}`, { state: { name: item.name, fromNavigation: true } })}
                                                        >
                                                            <div className="member_img">
                                                                <Image
                                                                    src={item.admin_image ? renderImage(item.admin_image) : Man}
                                                                    alt="..."
                                                                />
                                                            </div>
                                                            <h5
                                                                style={{ cursor: 'pointer', textTransform: 'capitalize' }}
                                                            >{item.name ? item.name : ''}</h5>
                                                            {
                                                                item.role_title || item.role ?
                                                                    <div className="tag_area" >
                                                                        <p >{item.role_title ? item.role_title : item.role}</p>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </Col>

                                                ))
                                                :
                                                null
                                        }
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AssignedMemberModal;