import { useEffect, useState } from "react";
import hospitalController from "../../../../../apis/Controllers/HospitalController/hospital.controller";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import { useNavigate } from "react-router-dom";
import store from "../../../../../redux/store";
import wardController from "../../../../../apis/Controllers/WardController/ward.controller";
import permissionsController from "../../../../../apis/Controllers/PermissionsController/permissions.contoller";
import { filtersController } from '../../../../../apis/Controllers/CommonController/filter.controller';


const WardListingFunctions = () => {
    var breadcrumData = ["Dashboard"];
    var breadcrumItem = "Ward List";
    var breadcrumData1 = ["Dashboard", "Ward List"];

    let storedFilters = store.getState().WardListFilters.filters;
    const [checkPermissions, setCheckPermissions] = useState(null);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [wardsListing, setWardsListing] = useState([]);
    const [loader, setLoader] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);
    const [checkStoredvalueFromRedux, SetCheckStoredValueFromRedux] = useState(null);
    const [filtersAppliedSign, setFiltersAppliedSign] = useState(false);
    const [keyword, setSearch] = useState(null);
    const [SearchLoader, setSearchLoader] = useState(false);
    // -------------------PAZINATION ----------------------
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    //-----------------------------------------------------
    const [allFiltersValues, setAllFiltersValues] = useState(false);

    let navigate = useNavigate();
    let userdata = localStorage.getItem('SET_USER_DATA');
    let User = JSON.parse(userdata);

    const getModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('ward');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckPermissions(data);
        }
        else {
            setCheckPermissions(null);
        }
    };


    useEffect(() => {
        if (storedFilters && checkStoredvalueFromRedux, allFiltersValues) {
            if ((storedFilters && storedFilters.wardStatus) ||
                (storedFilters && storedFilters.sortByAlpha) ||
                (storedFilters && storedFilters.hospitals
                    && storedFilters.hospitals.length > 0)
            ) {
                setFiltersAppliedSign(true);
                getWardListing("", page, itemsPerPage);
            } else {
                setFiltersAppliedSign(false);
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                getWardListing("", page, itemsPerPage, '', item);
            }
        }
    }, [storedFilters, checkStoredvalueFromRedux, allFiltersValues]);



    useEffect(() => {
        if (storedFilters && checkStoredvalueFromRedux) {
            if ((storedFilters &&
                storedFilters.hospitals &&
                storedFilters.hospitals.length > 0)
            ) {
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                handleDashboardHospitalwithFiltersAppliedCase(item, storedFilters.hospitals);
            } else {
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                if (item) {
                    handleDashboardHospitalwithFilters(item);
                }
            }
        }
    }, [checkStoredvalueFromRedux]);


    const handleDashboardHospitalwithFiltersAppliedCase = async (seletedHospitalData, AppliedFilterHospitals) => {
        let applied = AppliedFilterHospitals
        if (applied.length > 1) {
            let index = applied.findIndex((id) => parseInt(id) === parseInt(seletedHospitalData.id))
            if (index === -1) {
                applied.push(seletedHospitalData.id);
            }
            // else {
            //     return applied
            // }
        }
        else {
            applied = []
            applied.push(seletedHospitalData.id);
        }
        let filterNew = {
            wardStatus: storedFilters && storedFilters.wardStatus && storedFilters.wardStatus.length > 0 ? storedFilters.wardStatus : null,
            hospitals: applied,
            sortByAlpha: storedFilters && storedFilters.sortByAlpha && storedFilters.sortByAlpha.value ? storedFilters.sortByAlpha.value : null,
        };
        await filtersController.setWardListFilter(filterNew);
        await setAllFiltersValues(true);
    };


    const handleDashboardHospitalwithFilters = async (seletedHospitalData) => {
        let filterNew = {
            wardStatus: storedFilters && storedFilters.wardStatus && storedFilters.wardStatus.length > 0 ? storedFilters.wardStatus : null,
            hospitals: seletedHospitalData && seletedHospitalData.id ? [seletedHospitalData.id] : [],
            sortByAlpha: storedFilters && storedFilters.sortByAlpha && storedFilters.sortByAlpha.value ? storedFilters.sortByAlpha.value : null,
        };
        await filtersController.setWardListFilter(filterNew);
        await setAllFiltersValues(true);
    };


    useEffect(() => {
        if (count) {
            handlePages(count);
        }
    }, [count, itemsPerPage]);


    const handlePages = (count) => {
        let array = []
        const data = parseInt(count);
        const totalPage = Math.ceil(data / itemsPerPage);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i);
        }
        setTotalPages(array);
    };

    const getWardListing = async (e, page, itemsPerPage, filters, seletedHospitalData) => {
        if (!loader) {
            let post = {
                search: e ? e : keyword ? keyword : "",
                page: page ? page : 1,
                limit: itemsPerPage ? itemsPerPage : 10,
                hospital_id: filters && filters.hospitals && filters.hospitals.length > 0 ? filters.hospitals : storedFilters && storedFilters.hospitals && storedFilters.hospitals.length ? storedFilters.hospitals : seletedHospitalData && seletedHospitalData.id ? [seletedHospitalData.id] : [],
                sort: filters && filters.sortByAlpha ? filters.sortByAlpha.value : storedFilters && storedFilters.sortByAlpha ? storedFilters.sortByAlpha.value : null,
                status: filters && filters.wardStatus ? filters.wardStatus.value : storedFilters && storedFilters.wardStatus ? storedFilters.wardStatus.value : null,
            };
            setLoader(true);
            let response = await wardController.superAdminwardListing(post);
            if (response && response.status) {
                setWardsListing(response.data);
                setCount(response.count);
                setTotalCount(response.total_count);
                setLoader(false);
            } else {
                setWardsListing([]);
                setLoader(false);
            }
            setLoader(false);
        }
    };


    const handleDeleteWard = async (item) => {
        let array = [...wardsListing];
        let id = deleteItem && deleteItem.id ? deleteItem.id : "";
        setLoader(true);
        let response = await wardController.wardDelete(id);
        if (response && response.status) {
            setLoader(false);
            new Toaster().success(response.message);
            setDeletePopup(false);
            let filterData = array.filter((data) => data.id !== deleteItem.id);
            if (filterData.length > 0) {
                getWardListing("", page, itemsPerPage);
            } else {
                if (page > 1) {
                    let newpage = page - 1;
                    setPage(page - 1);
                    getWardListing("", newpage, itemsPerPage);
                } else {
                    setPage(page);
                    getWardListing("", page, itemsPerPage);
                }
            }
            setTotalPages([]);
        } else {
            setLoader(false);
        }
        setLoader(false);
    };


    const searchWard = (value) => {
        if (value.length > 0) {
            setSearchInput(value);
            getWardListing(value, page, itemsPerPage);
        }
        else {
            setSearchInput(value);
            getWardListing('', page, itemsPerPage);
        }
    };


    const handleBackClick = () => {                          //<------------PAZINATION
        if (page > 1) {
            let newPage = page - 1;
            getWardListing("", newPage, itemsPerPage);
            setPage(page - 1);
        } else return;
    };


    const handleNextClick = () => {                           //<------------PAZINATION
        if (page >= 1 && page < totalPages.length) {
            let newPage = page + 1;
            getWardListing("", newPage, itemsPerPage);
            setPage(page + 1);
        } else return;
    };

    const handlePage = (e) => {
        setPage(e);
        getWardListing("", e, itemsPerPage);
    };


    const handleItemPerPage = (e) => {
        let value = e.target.value;
        setItemsPerPage(value);
        getWardListing("", 1, value);
    };


    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/dashboard-superadmin");
            }
        } else return;
    };

    const updateWardStatus = async (item) => {
        let post = {
            status: item.status == 1 ? 0 : 1,
        };
        let response = await wardController.updateWardStatus(item.id, post);
        if (response && response.status) {
            let array = [...wardsListing];
            item.status = parseInt(item.status) == 0 ? 1 : 0;
            setWardsListing(array)
            new Toaster().success(response.message);
        } else {
            new Toaster().error(response.message);
        }
    };


    const applyFilters = async (filter) => {
        let filters = {
            wardStatus: filter && filter.wardStatus ? filter.wardStatus : null,
            sortByAlpha: filter && filter.sortByAlpha ? filter.sortByAlpha : null,
            hospitals: filter && filter.hospitals ? filter.hospitals : [],
        };
        if (filter && filter.hospitals && filter.hospitals.length > 0) {
            await filtersController.setWardListFilter(filters);
            setShowFilterModal(false);
            setFiltersAppliedSign(true);
        } else {
            new Toaster().error("Please select at least one hospital");
        }
    };


    const handleClearAllFilters = async () => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        await filtersController.clearWardListFilters(item);
    };


    const setReduxValue = (e) => {          //<------------For confirmation that we have checked the localstorage value's and RESET the REDUX- FILTERS's Values and after it the listing api will hit
        SetCheckStoredValueFromRedux(e);
    };


    return {
        getModulePermissions,
        checkPermissions,
        getWardListing,
        handleItemPerPage,
        wardsListing,
        handleBackClick,
        handleNextClick,
        page,
        setPage,
        totalPages,
        setTotalPages,
        itemsPerPage,
        setItemsPerPage,
        searchInput,
        setSearchInput,
        breadcrumData,
        breadcrumItem,
        navigate,
        loader,
        setLoader,
        handlePage,
        handleBreadcrumDataNaviagtion,
        deletePopup,
        setDeletePopup,
        count,
        totalCount,
        searchWard,
        updateWardStatus,
        handleDeleteWard,
        deleteItem,
        setDeleteItem,
        showFilterModal,
        setShowFilterModal,
        applyFilters,
        handleClearAllFilters,
        setReduxValue,
        filtersAppliedSign,
        keyword,
        setSearch,
        breadcrumData1,
        User,
    };
};

export default WardListingFunctions;
