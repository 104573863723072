import ActionType from '../constants/index';

let stored = localStorage.getItem( 'SET_API_TOKEN');
stored = stored ? JSON.parse(stored) : {};

const initialState = {
  apitoken: stored,
};

const APITokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_API_TOKEN:

      return Object.assign({}, state, {
        apitoken: action.payload,
      });

    default:
      return state;
  }
}
export default APITokenReducer;
