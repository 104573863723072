import { setDrawer } from "../../../redux/actions/users";
import store from "../../../redux/store";
import { actionsServices } from "../../Services/CommonServices/action.services";

const citiesList = async (params) => {
    let response = await actionsServices.citiesList(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const districtsList = async (params) => {
    let response = await actionsServices.districtsList(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const statesList = async (params) => {
    let response = await actionsServices.statesList({ country_id: '101' });
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const nationalities = async (params) => {
    let param = params ? params : null;
    let response = await actionsServices.nationalities(param);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const countrieslist = async (params) => {
    let param = params ? params : null;
    let response = await actionsServices.countrieslist(param);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const taskPriorities = async (params) => {
    let param = params ? params : null;
    let response = await actionsServices.taskPriority(param);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const bloodGroups = async (params) => {
    let param = params ? params : null;
    let response = await actionsServices.bloodGroups(param);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const patientTypes = async (params) => {
    let param = params ? params : "";
    let response = await actionsServices.patientTypes(param);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const gendersList = async (params) => {
    let param = params ? params : "";
    let response = await actionsServices.gendersList(param);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const martialStatusList = async (params) => {
    let param = params ? params : "";
    let response = await actionsServices.martialStatusList(param);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const medicineFrequencyList = async (params) => {
    let response = await actionsServices.medicineFrequencyList(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const globalSearchApi = async (params) => {
    let response = await actionsServices.globalSearchApi(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const setUpDrawer = async (item) => {
    await store.dispatch(setDrawer(item));
    return item;
};

const getDrawerState = async () => {
    let drawerState = store.getState().DrawerReducer.drawerState;
    return drawerState;
};

export const actionsController = {
    citiesList,
    districtsList,
    statesList,
    nationalities,
    countrieslist,
    taskPriorities,
    bloodGroups,
    patientTypes,
    gendersList,
    martialStatusList,
    medicineFrequencyList,
    setUpDrawer,
    getDrawerState,
    globalSearchApi,
};
