import { useEffect, useState } from "react";
import rolesController from "../../../../../apis/Controllers/RolesController/roles.controller";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import { useNavigate } from "react-router-dom";
import store from "../../../../../redux/store";
import permissionsController from "../../../../../apis/Controllers/PermissionsController/permissions.contoller";

const RolesFunctions = () => {
    var breadcrumData = ["Dashboard"];
    var breadcrumItem = "Role list";

    const [searchInput, setSearchInput] = useState("");
    const [checkPermissions, setCheckPermissions] = useState(null);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [rolesList, setRolesList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    const [keyword, setSearch] = useState(null);
    let navigate = useNavigate();
    let userdata = localStorage.getItem('SET_USER_DATA');
    let User = JSON.parse(userdata);

    // -------------------PAZINATION ----------------------
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [totalPages, setTotalPages] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    //-----------------------------------------------------


    const getModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('role');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckPermissions(data);
        }
        else {
            setCheckPermissions(null);
        }
    };


    useEffect(() => {
        if (count) {
            handlePages(count);
        }
    }, [count, itemsPerPage]);



    const getRolesList = async (e, page, itemsPerPage) => {
        if (!loader) {
            let post = {
                search: e ? e : keyword ? keyword : "",
                page: page ? page : 1,
                limit: itemsPerPage ? itemsPerPage : 10
            };
            setLoader(true);
            let response = await rolesController.rolesListing(post);
            if (response && response.status) {
                setCount(response.count);
                setRolesList(response.data);
                setLoader(false);
            } else {
                setRolesList([]);
                setLoader(false);
            }
            setLoader(false);
        }
    };

    const handlePages = (count) => {
        let array = [];
        const data = parseInt(count);
        const totalPage = Math.ceil(data / itemsPerPage);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i);
        }
        setTotalPages(array);
    };

    const handleDeleteAdmine = async (item) => {
        let array = [...rolesList];
        let id = item && item.id ? item.id : "";
        setLoader(true);
        let response = await rolesController.roleDelete(id);
        if (response && response.status) {
            setLoader(false);
            new Toaster().success(response.message);
            setDeletePopup(false);
            let filterData = array.filter((data) => data.id !== item.id);
            if (filterData.length > 0) {
                getRolesList("", page, itemsPerPage);
            } else {
                if (page > 1) {
                    let newapge = page - 1;
                    setPage(page - 1);
                    getRolesList("", newapge, itemsPerPage);
                } else {
                    setPage(page);
                    getRolesList("", page, itemsPerPage);
                }
            }
            setTotalPages([]);
        } else {
            setLoader(false);
        }
    };

    const handleBackClick = () => {                             //<------------PAZINATION
        if (page > 1) {
            let newpage = page - 1;
            setPage(page - 1);
            getRolesList("", newpage, itemsPerPage);
        } else {
            setPage(1);
            getRolesList("", 1, itemsPerPage);
        }
    };

    const handleNextClick = () => {                                 //<------------PAZINATION
        if (page >= 1 && page < totalPages.length) {
            let newpage = page + 1;
            setPage(page + 1);
            getRolesList("", newpage, itemsPerPage);
        } else {
            setPage(page);
            getRolesList("", page, itemsPerPage);
        }
    };


    const handleItemPerPage = (e) => {
        let value = e.target.value;
        setItemsPerPage(value);
        getRolesList("", 1, value);
    };


    const handlePage = (e) => {
        setPage(e);
        getRolesList("", e, itemsPerPage);
    };

    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/dashboard-superadmin");
            }
        } else return;
    };

    return {
        checkPermissions,
        getModulePermissions,
        showFilterModal,
        setShowFilterModal,
        searchInput,
        setSearchInput,
        getRolesList,
        rolesList,
        handleDeleteAdmine,
        page,
        setPage,
        count,
        totalPages,
        itemsPerPage,
        setItemsPerPage,
        handleBackClick,
        handleNextClick,
        navigate,
        breadcrumData,
        breadcrumItem,
        loader,
        handlePage,
        handleBreadcrumDataNaviagtion,
        deletePopup,
        setDeletePopup,
        keyword,
        setSearch,
        User,
        handleItemPerPage
    };
};

export default RolesFunctions;
