import { faAngleDown, faChevronRight, faHospital } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import Bell from "../../assets/images/bell.png";
import Menu from "../../assets/images/menu.png";
import Search from "../../assets/images/search.png";
import { setUserData } from "../../redux/actions/users";
import store from "../../redux/store";


function DashboardHeader(props) {
    const navigate = useNavigate();

    useEffect(() => {
        getUserData();
    }, []);


    const getUserData = async () => {
        window.scrollTo(0, 0);
        let item = localStorage.getItem('SET_USER_DATA');
        item = JSON.parse(item);
        let user = item;
        if (user) {
            if (user && user.access && user.access.token) {
                await store.dispatch(setUserData(user));
            }
        }
        else {
            logout();
        }
    };

    const logout = async () => {
        store.dispatch(setUserData({}));
        localStorage.clear();
        navigate("/login");
    };


    const handleChange = (item) => {
        props.setDropdownValue(item.title);
        props.setHospitalId(item.id);
    };
    const [openDrawer, setOpenDrawer] = useState(false);

    return (
        <>
            <div className="dashboard_screen">
                <div className="dashboardheader_area">
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div className="inner_area">
                                <div className="left_area">
                                    {/* <a href="javascript:;"> */}
                                    <div className="icon" onClick={() => setOpenDrawer(true)}>
                                        <Image src={Menu} alt="..." />
                                    </div>
                                    {/* </a> */}
                                </div>
                                <div className="right_area">
                                    <ul>
                                        <li>
                                            <div className="icon">
                                                <a href="javscript:;">
                                                    <Image src={Search} alt="..." />
                                                </a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon alert_circle">
                                                <a href="javscript:;">
                                                    <Image
                                                        src={Bell}
                                                        alt="..."
                                                    // onClick={() => logout()}
                                                    />
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="header_title">
                                    <h5>Dashboard </h5>
                                </div>
                            </div>
                            <div className="linking_area">
                                <div className="inner_area">
                                    <a href="javscript:;" className="me-2">
                                        Dashboard
                                    </a>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                    <span>Ward</span>
                                </div>
                                {
                                    props && props.hospitalList && props.hospitalList.length > 0 ?
                                        <div className="dropdown_area">
                                            <Dropdown>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    <div className="icon">
                                                        <FontAwesomeIcon
                                                            icon={faHospital}
                                                        />
                                                    </div>
                                                    {props && props.dropdownValue ? props.dropdownValue : props.hospitalList[0].title ? props.hospitalList[0].title : ''}
                                                    <div className="icon down_icon ms-4">
                                                        <FontAwesomeIcon
                                                            icon={faAngleDown}
                                                        />
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {
                                                        props.hospitalList.map((item, i) => {
                                                            return (
                                                                <Dropdown.Item onClick={() => handleChange(item)} key={i}>
                                                                    {item.title}
                                                                </Dropdown.Item>
                                                            )
                                                        })
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default DashboardHeader;
