import { faUserPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { BloodGroup, Calendar, Circle, Email, Gender, Location, Phone, Profile, Registration, Specialization } from "../../../../assets/images";
import * as Constants from '../../../Helper/constants/constants';
import WithoutImageHeader from "../../../Helper/withoutImageHeader";

function MyProfile() {
  return (
    <div className="my_profile_screen">
      <WithoutImageHeader header={Constants.MY_PROFILE_HEADER} />
      <div className="parent_area">
        <Container>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="profile_area">
                <div className="image_area">
                  <Image src={Profile} alt="..." />
                </div>
                <div className="name_area">
                  <h5>Kiran Kumari</h5>
                  <p><span>Nurse</span></p>
                </div>
                {/* <div className="card_area">
                  <Row>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={12}>
                      <div className="card_inner_area">
                        <div className="top_area">
                          <div className="img_area">
                            <Image src={Specialist} alt="..." />
                          </div>
                        </div>
                        <h5>Sr.</h5>
                        <p>Specialist</p>
                      </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={12}>
                      <div className="card_inner_area">
                        <div className="top_area experience">
                          <div className="img_area">
                            <Image src={Experience} alt="..." />
                          </div>
                        </div>
                        <h5>10 Yrs</h5>
                        <p>Experience</p>
                      </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={12}>
                      <div className="card_inner_area">
                        <div className="top_area task">
                          <div className="img_area">
                            <Image src={Tasks} alt="..." />
                          </div>
                        </div>
                        <h5>1000+</h5>
                        <p>Tasks</p>
                      </div>
                    </Col>
                  </Row>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="my_profile_bottom">
        <div className="parent_area details ">
          <Container>
            <Row>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="info_area">
                  <div className="inner_area">
                    <div class="left_area">
                      <div className="details_area">
                        <div className="icon_area">
                          <Image src={Phone} alt="..." />
                        </div>
                        <p>Contact Number</p><span>:</span>
                      </div>
                    </div>
                    <div class="right_area">
                      <p>+91-798-798-7987</p>
                    </div>
                  </div>

                </div>
              </Col>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="info_area">
                  <div className="inner_area">
                    <div class="left_area">
                      <div className="details_area">
                        <div className="icon_area">
                          <Image src={Email} alt="..." />
                        </div>
                        <p>Email</p><span>:</span>
                      </div>
                    </div>
                    <div class="right_area">
                      <p>harjinder12@gmail.com</p>
                    </div>
                  </div>

                </div>
              </Col>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="info_area">
                  <div className="inner_area">
                    <div class="left_area">
                      <div className="details_area">
                        <div className="icon_area">
                          <Image src={Gender} alt="..." />
                        </div>
                        <p>Gender</p><span>:</span>
                      </div>
                    </div>
                    <div class="right_area">
                      <p>Male</p>
                    </div>
                  </div>

                </div>
              </Col>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="info_area">
                  <div className="inner_area">
                    <div class="left_area">
                      <div className="details_area">
                        <div className="icon_area">
                          <Image src={BloodGroup} alt="..." />
                        </div>
                        <p>Blood Group</p><span>:</span>
                      </div>
                    </div>
                    <div class="right_area">
                      <p>O</p>
                    </div>
                  </div>

                </div>
              </Col>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="info_area">
                  <div className="inner_area">
                    <div class="left_area">
                      <div className="details_area">
                        <div className="icon_area">
                          <Image src={Calendar} alt="..." />
                        </div>
                        <p>Date of Birth</p><span>:</span>
                      </div>
                    </div>
                    <div class="right_area">
                      <p>26-05-1980</p>
                    </div>
                  </div>

                </div>
              </Col>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="info_area">
                  <div className="inner_area">
                    <div class="left_area">
                      <div className="details_area">
                        <div className="icon_area">
                          <Image src={Specialization} alt="..." />
                        </div>
                        <p>Specialization</p><span>:</span>
                      </div>
                    </div>
                    <div class="right_area">
                      <p>Viralogist</p>
                    </div>
                  </div>

                </div>
              </Col>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="info_area">
                  <div className="inner_area">
                    <div class="left_area">
                      <div className="details_area">
                        <div className="icon_area">
                          <Image src={Registration} alt="..." />
                        </div>
                        <p>Registration #</p><span>:</span>
                      </div>
                    </div>
                    <div class="right_area">
                      <p>123456789</p>
                    </div>
                  </div>

                </div>
              </Col>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="info_area">
                  <div className="inner_area">
                    <div class="left_area">
                      <div className="details_area">
                        <div className="icon_area">
                          <Image src={Location} alt="..." />
                        </div>
                        <p>Address</p><span>:</span>
                      </div>
                    </div>
                    <div class="right_area">
                      <p>107, Guru Nanak Pura, Civil Lines Ludhiana, Punjab</p>
                    </div>
                  </div>
                </div>
              </Col>
              <div className="edit_btn_area">
                <Button type="submit" class="btn btn-primary">
                  Edit Profile
                  <span className="icon">
                    <FontAwesomeIcon icon={faUserPen} />
                  </span>
                </Button>
              </div>

              <div className="circle_area">
                <Image src={Circle} alt="..." />
              </div>
            </Row>
          </Container>
        </div>
      </div >
    </div >
  );
}

export default MyProfile;