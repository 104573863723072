import { useState } from "react";
import wardController from "../../../../apis/Controllers/WardController/ward.controller";
import { Toaster } from "../../../Helper/toaster/Toaster";
import store from "../../../../redux/store";
import { useEffect } from "react";
import hospitalController from "../../../../apis/Controllers/HospitalController/hospital.controller";


const DepartmentListFilterFunctions = (props) => {
    let storedFilters = store.getState().DepartmentListFilters.filters;

    const defaultFilters = {
        depatmentStatus: storedFilters && storedFilters.depatmentStatus ? storedFilters.depatmentStatus : null,
        sortByAlpha: storedFilters && storedFilters.sortByAlpha ? storedFilters.sortByAlpha : null,
        hospitals: storedFilters && storedFilters.hospitals ? storedFilters.hospitals : [],
        
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [hospitalsSearch, setHospitalsSearch] = useState("");
    const [hospitalsList, setHospitalsList] = useState([]);
    const [departmentStatus, setDepartmentListStatusList] = useState([
        {
            title: "Active",
            value: 1,
            checked: false,
            id: 1,
        },
        {
            title: "Inactive",
            value: 0,
            checked: false,
            id: 2,
        },
    ]);


    const [SortByList, setSortByListStatusList] = useState([
        {
            title: "Ascending",
            value: "ASC",
            checked: false,
            id: 1,
        },
        {
            title: "Descending",
            value: "DESC",
            checked: false,
            id: 2,
        },
    ]);


    useEffect(() => {
        if (storedFilters && storedFilters.sortByAlpha) {
            reSetValuesOfSortByAlphaStatusList(storedFilters.sortByAlpha);
        }
    }, [storedFilters]);


    useEffect(() => {
        if (storedFilters && storedFilters.depatmentStatus) {
            reSetValuesOfWardStatusList(storedFilters.depatmentStatus);
        }
    }, [storedFilters]);


    const reSetValuesOfSortByAlphaStatusList = (appliedStatus) => {
        let array = [...SortByList]
        if (appliedStatus) {
            const appliedSortByStatusIndex = array.findIndex((data) => parseInt(data.id) === parseInt(appliedStatus.id));
            if (appliedSortByStatusIndex !== -1) {
                array[appliedSortByStatusIndex].checked = true
            }
            setSortByListStatusList(array)
        }
    };


    const reSetValuesOfWardStatusList = (appliedStatus) => {
        let array = [...departmentStatus]
        if (appliedStatus) {
            const appliedWardStatusIndex = array.findIndex((data) => parseInt(data.id) === parseInt(appliedStatus.id));
            if (appliedWardStatusIndex !== -1) {
                array[appliedWardStatusIndex].checked = true
            }
            setDepartmentListStatusList(array)
        }
    };


    const handleDepartmentStatusFilters = (item) => {
        let array = [...departmentStatus]
        if (item) {
            for (let i in array) {
                array[i].checked = false
            }
            let index = array.findIndex((data) => parseInt(data.id) === parseInt(item.id));
            if (index !== -1) {
                if (array[index].checked === true) {
                    array[index].checked = false
                }
                else {
                    array[index].checked = true
                }
            }
            setDepartmentListStatusList(array);
            setFilters({
                ...filters,
                depatmentStatus: item
            })
        }
    };



    const handleSortByFilters = (item) => {
        let array = [...SortByList]
        if (item) {
            for (let i in array) {
                array[i].checked = false
            }
            let index = array.findIndex((data) => parseInt(data.id) === parseInt(item.id))
            if (index !== -1) {
                if (array[index].checked === true) {
                    array[index].checked = false
                }
                else {
                    array[index].checked = true
                }
            }
            setSortByListStatusList(array);
            setFilters({
                ...filters,
                sortByAlpha: item
            })
        }
    };


      const handleHospitalsSearch = (e) => {
        setHospitalsSearch(e.target.value);
        getHospitalListing(e.target.value);
    };


    const getHospitalListing = async (e) => {
          let post = {
            search: e ? e : "",
        };
        let response = await hospitalController.hospitalListing(post);
         if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]["checked"] = false;
             }
             setHospitalsList(data)
            if (
                storedFilters &&
                storedFilters.hospitals &&
                storedFilters.hospitals.length > 0
            ) {
                reSethospitalsAppliedFiltersValues(storedFilters, data);
            } else {
                setHospitalsList(data);
            }
            } else {
              setHospitalsList([])
            }
    };

        const handleHospitalsFilters = (item) => {
        let array = [...hospitalsList];
        let value = [...filters.hospitals];
        if (item) {
            for (let i in array) {
                if (parseInt(array[i].id) === parseInt(item.id)) {
                    let check = array[i].checked;
                    array[i].checked = !check;
                }
            }
            setHospitalsList(array);
            let status = value.findIndex((id) => parseInt(id) === parseInt(item.id));
            if (status === -1) {
                value.push(item.id);
            } else {
                value.splice(status, 1);
            }
            setFilters({
                ...filters,
                hospitals: value,
            });
        }
    };

       const reSethospitalsAppliedFiltersValues = (storedFilters, hospitalslist) => {
        let filter = { ...storedFilters};
        let hospitals = [...hospitalslist];
        if (hospitals && hospitals.length > 0) {
            hospitals.forEach((b) => {
                filter.hospitals.forEach((a) => {
                    if (parseInt(b.id) === parseInt(a)) {
                        b.checked = true;
                    }
                });
            });
            setHospitalsList(hospitals)
            setFilters({
                ...filters,
                hospitals: filter.hospitals,
            });
        }
    };

    const applyFilters = () => {
        props.applyFilters(filters);
    };


    const handleClearAllFilters = () => {
        props.handleClearAllFilters();
        props.close();
    };
    
    return {
        departmentStatus,
        setSortByListStatusList,
        SortByList,
        handleDepartmentStatusFilters,
        handleSortByFilters,
        setFilters,
        filters,
        applyFilters,
        handleClearAllFilters,
        handleHospitalsSearch,
        hospitalsSearch,
        hospitalsList,
        getHospitalListing,
        handleHospitalsFilters
    }
};

export default DepartmentListFilterFunctions;