import React from "react";

import { Col, Container, Image, Row } from "react-bootstrap";
import { Filter } from "../../../../assets/images";
import DashboardHeader from "../../../Helper/dashboardHeader";
// import Filter from '../../../../assets/images/filter.png';
function Rooms() {
  return (
    <div className="main_dashboard_screen">
      {<DashboardHeader />}
      <Container>
        {/* {<DashboardHeader/>} */}
        <div className="dashboard_area">
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <div class="inner_dashboard_area">
                <div className="ward_view_page">
                  <div className="inner_ward">
                    <div className="top_head">
                      <h4>Rooms</h4>
                      <a href="javscript:;" >
                        <Image src={Filter} alt="..." />
                      </a>
                    </div>
                    <div className="ward_listing">
                      <Row>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={12}>
                          <div className="list_view">
                            <div className="left_text">
                              <p>Room Number</p>
                            </div>
                            <div className="right_text">
                              <p>01</p>
                            </div>
                          </div>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={12}>
                          <div className="list_view">
                            <div className="left_text">
                              <p>Room Number</p>
                            </div>
                            <div className="right_text">
                              <p>02</p>
                            </div>
                          </div>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={12}>
                          <div className="list_view">
                            <div className="left_text">
                              <p>Room Number</p>
                            </div>
                            <div className="right_text">
                              <p>03</p>
                            </div>
                          </div>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={12}>
                          <div className="list_view">
                            <div className="left_text">
                              <p>Room Number</p>
                            </div>
                            <div className="right_text">
                              <p>04</p>
                            </div>
                          </div>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={12}>
                          <div className="list_view">
                            <div className="left_text">
                              <p>Room Number</p>
                            </div>
                            <div className="right_text">
                              <p>05</p>
                            </div>
                          </div>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={12}>
                          <div className="list_view">
                            <div className="left_text">
                              <p>Room Number</p>
                            </div>
                            <div className="right_text">
                              <p>06</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>




  );
}

export default Rooms;