import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import * as Constants from "../../../Helper/constants/constants";
import WithoutImageHeader from "../../../Helper/withoutImageHeader";
import ChangePasswordFunction from "./function";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Helper/loader";

const ChangePassword = () => {
    const navigate = useNavigate();
    const [isVisibleOldPassword, setVisibleOldPassword] = useState(false);
    const [isVisibleNewPassword, setVisibleNewPassword] = useState(false);
    const [isVisibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
    const { values, isError, handleChange, handleChangePassword, loader } =
        ChangePasswordFunction();

    const toggleOldPassword = () => {
        setVisibleOldPassword(!isVisibleOldPassword);
    };

    const toggleNewPassword = () => {
        setVisibleNewPassword(!isVisibleNewPassword);
    };

    const toggleConfirmPassword = () => {
        setVisibleConfirmPassword(!isVisibleConfirmPassword);
    };

    return (
        <div className="change_password_screen">
            <WithoutImageHeader
                header={Constants.CHANGE_PASSWORD_HEADER}
                handleBack={() => navigate(-1)}
            />
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="form">
                            <Form>
                                <Form.Group className="form-group">
                                    <Form.Label>Old Password</Form.Label>
                                    <Form.Control
                                        type={!isVisibleOldPassword ? "password" : "text"}
                                        placeholder="************"
                                        className={isError.password.message
                                            ? "error_border"
                                            : ""}
                                        value={values.password}
                                        onChange={(e) =>
                                            handleChange(
                                                "password",
                                                e.target.value
                                            )
                                        }
                                    />
                                    <span onClick={toggleOldPassword}>
                                        {isVisibleOldPassword ? <FontAwesomeIcon
                                            icon={faEye}
                                            className="eye_icon"
                                        /> : <FontAwesomeIcon
                                            icon={faEyeSlash}
                                            className="eye_icon"
                                        />}
                                    </span>
                                    {isError.password.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.password.message}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        type={!isVisibleNewPassword ? "password" : "text"}
                                        placeholder="************"
                                        value={values.newPassword}
                                        className={isError.newPassword.message
                                            ? "error_border"
                                            : ""}
                                        onChange={(e) =>
                                            handleChange(
                                                "newPassword",
                                                e.target.value
                                            )
                                        }
                                    />
                                    <span onClick={toggleNewPassword}>
                                        {isVisibleNewPassword ? <FontAwesomeIcon
                                            icon={faEye}
                                            className="eye_icon"
                                        /> : <FontAwesomeIcon
                                            icon={faEyeSlash}
                                            className="eye_icon"
                                        />}
                                    </span>
                                    {isError.newPassword.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.newPassword.message}
                                        </p>
                                    ) : null}

                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>Confirm Password</Form.Label>
                                    {/* <div className="password_icon"> */}
                                    <Form.Control
                                        type={
                                            !isVisibleConfirmPassword ? "password" : "text"
                                        }
                                        placeholder="************"
                                        className={isError.confirmPassword.message
                                            ? "error_border"
                                            : ""}
                                        value={values.confirmPassword}
                                        onChange={(e) =>
                                            handleChange(
                                                "confirmPassword",
                                                e.target.value
                                            )
                                        }
                                    />
                                    <span onClick={toggleConfirmPassword}>
                                        {isVisibleConfirmPassword ? <FontAwesomeIcon
                                            icon={faEye}
                                            className="eye_icon"
                                        /> : <FontAwesomeIcon
                                            icon={faEyeSlash}
                                            className="eye_icon"
                                        />}
                                    </span>

                                    {isError.confirmPassword.message ? (
                                        <p className="text-danger mt-2">
                                            {
                                                isError.confirmPassword
                                                    .message
                                            }
                                        </p>
                                    ) : null}


                                    {/* <FontAwesomeIcon
                                            icon={faEye}
                                            className="eye_icon"
                                            
                                        />
                                        <FontAwesomeIcon
                                            icon={faEyeSlash}
                                            className="eye_icon"
                                            
                                        /> */}
                                    {/* </div> */}
                                </Form.Group>
                                <div className="btn_area">
                                    <Button
                                        className="submitBtn"
                                        type="submit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleChangePassword();
                                        }}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
            {loader ?
                <Loader loader={loader} />
                : null}
        </div>
    );
};

export default ChangePassword;
