import FiltersHeader from "../../../../Helper/filtersHeader";
import * as Constants from "../../../../Helper/constants/constants";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import {
    Calender,
    ChangePatientProfile,
    Image_Up,
    Man,
    Patient,
} from "../../../../../assets/images";
import Select from "react-select";
import { useState } from "react";
import TagsInput from "react-tagsinput";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import { useNavigate } from "react-router-dom";
import AddWardFunctions from "./function";
import { useEffect } from "react";
import CustomSelectOptions from '../../../../Helper/customSelectOption';


const AddWard = () => {
    const navigate = useNavigate();
    const {
        getHospitalListing,
        breadcrumData,
        breadcrumItem,
        values,
        isError,
        hospitalsListing,
        loader,
        handleChange,
        submitWard,
        loaction,
        setbreadcrumItem,
        getWardDetail,
        handleBreadcrumDataNaviagtion,
    } = AddWardFunctions();

    useEffect(() => {
        if (!loader) {
            if (loaction && loaction.state && loaction.state.name) {
                let id =
                    loaction && loaction.state && loaction.state.id
                        ? loaction.state.id
                        : null;

                getWardDetail(id);
                // getCitiesList(values && values.state && values.state.id);
                setbreadcrumItem("Edit Ward");
            }
            getHospitalListing();
        }
    }, []);


    return (
        <div className="add_case_section">
            <WithoutImageHeader
                header={
                    loaction && loaction.state && loaction.state.name
                        ? Constants.EDITWARD
                        : Constants.ADDWARD
                }
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBack={() => navigate(-1)}
                handleBreadcrumDataNaviagtion={(i) =>
                    handleBreadcrumDataNaviagtion(i)
                }
            />
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="case_area">
                            <div className="dropdown_box">
                                <h6>
                                    Hospital<span>*</span>
                                </h6>
                                <div className="dropdown_area">
                                    <Select
                                        isDisabled={loaction && loaction.state && loaction.state.id ? true : false}
                                        placeholder="Select Hospital"
                                        options={hospitalsListing}
                                        value={values && values.hospital_arr}
                                        onChange={(e) => {
                                            handleChange("hospital_arr", e);
                                        }}
                                        className={
                                            isError.hospital_arr.message
                                                ? "error_border_select"
                                                : ""
                                        }
                                        components={{
                                            Option: props => (
                                                <CustomSelectOptions
                                                    items={props}
                                                    value={values && values.hospital_arr}
                                                />
                                            )
                                        }}
                                    />
                                    {isError.hospital_arr.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.hospital_arr.message}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    Ward Title<span>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter ward title here"
                                    className={
                                        isError.ward_title.message
                                            ? "error_border"
                                            : ""
                                    }
                                    value={values.ward_title}
                                    onChange={(e) =>
                                        handleChange(
                                            "ward_title",
                                            e.target.value
                                        )
                                    }
                                />
                                {isError.ward_title.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.ward_title.message}
                                    </p>
                                ) : null}
                            </Form.Group>
                            {/* <Form.Group className="form-group">
                                <Form.Label>
                                    Ward Number<span>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter ward number here"
                                    className={
                                        isError.ward_number.message
                                            ? "error_border"
                                            : ""
                                    }
                                    value={values.ward_number}
                                    onChange={(e) =>
                                        handleChange(
                                            "ward_number",
                                            e.target.value
                                        )
                                    }
                                />
                                {isError.ward_number.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.ward_number.message}
                                    </p>
                                ) : null}
                            </Form.Group> */}
                            <div className="btn_area">
                                <Button
                                    className="cancelBtn"
                                    type="button"
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="submitBtn"
                                    type="button"
                                    onClick={() => submitWard()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddWard;
