import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function reportsListing (params) {
    return mainWrapper.get(WebConstant.host + `/reports_list`, params);
};


function reportsCreate (params) {
    return mainWrapper.post(WebConstant.host + `/create_reports`, params);
};


function reportsDetail (id) {
    return mainWrapper.get(WebConstant.host + `/reports_detail/${id}`);
};


function reportsUpdate (params,id) {
    return mainWrapper.put(WebConstant.host + `/update_reports/${id}`, params);
};


function reportDelete (id) {
    return mainWrapper._delete(WebConstant.host + `/delete_reports/${id}`);
};


export const reportsServices = {
    reportsListing,
    reportsCreate,
    reportsDetail,
    reportsUpdate,
    reportDelete
};
