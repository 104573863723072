import { useCallback, useState } from "react";
import { actionsController } from "../../../../apis/Controllers/CommonController/action.controller";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";


export const SearchFunctions = () => {
    const [keyword, setSearch] = useState(null);
    const [currentTab, setCurrentTab] = useState('1');
    const [taskTotalCount, setTaskhTotalCount] = useState(0);
    const [patinetTotalCount, setPatinetTotalCount] = useState(0);
    const [stafTotalCount, setStafTotalCount] = useState(0);
    const [stafResult, setStafResult] = useState([]);
    const [taskResult, setTaskhResult] = useState([]);
    const [patinetResult, setPatinetResult] = useState([]);
    const [loader, setLoader] = useState(false);
    let navigate = useNavigate();
    const tabs = [
        {
            id: 1,
            tabTitle: 'Staff',
        },
        {
            id: 2,
            tabTitle: 'Task',
        },
        {
            id: 3,
            tabTitle: 'Patient',
        }
    ];


    const searchData = async (e) => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        let post = {
            search: e ? e : '',
            hospital_id: item ? item.id : ''
        }
        setLoader(true);
        let response = await actionsController.globalSearchApi(post);
        if (response && response.status) {
            let data = response && response.data
            setStafResult(data.all_staff);
            setTaskhResult(data.tasks);
            setPatinetResult(data.patients);
            setStafTotalCount(data.all_staff_count);
            setTaskhTotalCount(data.tasks_count);
            setPatinetTotalCount(data.patients_count);
            setLoader(false);
        }
        else {
            setStafResult([]);
            setTaskhResult([]);
            setPatinetResult([]);
            setStafTotalCount(0);
            setTaskhTotalCount(0);
            setPatinetTotalCount(0);
            setLoader(false);
        }
    };


    const search = useCallback(debounce(searchData, 1000), []);
    return {
        loader,
        keyword,
        setSearch,
        currentTab,
        setCurrentTab,
        tabs,
        search,
        stafResult,
        taskResult,
        patinetResult,
        taskTotalCount,
        patinetTotalCount,
        stafTotalCount,
        navigate
    }
}