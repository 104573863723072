import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";

function departmentList(params) {
    return mainWrapper.get(WebConstant.host + "/department", params);
}

function departmentListing(params) {
    return mainWrapper.get(WebConstant.host + "/department_listing", params);
}

function departmentCreate(params) {
    return mainWrapper.post(WebConstant.host + `/department`, params);
}

function departmentDetail(id) {
    return mainWrapper.get(WebConstant.host + `/department/${id}`);
}

function departmentUpdate(id, params) {
    return mainWrapper.put(WebConstant.host + `/department/${id}`, params);
}

function updateDepartStatus(id, params) {
    return mainWrapper.put(
        WebConstant.host + `/update_department_status/${id}`,
        params
    );
}

function departmentDelete(id) {
    return mainWrapper._delete(WebConstant.host + `/department/${id}`);
}

export const departmentServices = {
    departmentListing,
    departmentCreate,
    departmentDetail,
    departmentUpdate,
    departmentDelete,
    updateDepartStatus,
    departmentList
};
