import React from 'react';
import { Card, Image } from 'react-bootstrap';
import { Delete, Eye, PdfImg } from '../../../../assets/images';


const Documents = () => {
    return (
        <div className="document_area">
            <Card>
                <Card.Body>
                    <Card>
                        <Card.Body>
                            <div className='card_details'>
                                <div className='left_area'>
                                    <div className='inner_area'>
                                        <div className='inner_left_area'>
                                            <div className='inner_img'>
                                                <Image src={PdfImg} alt='...' />
                                            </div>
                                        </div>
                                        <div className='inner_right_area'>
                                            <h3>Aadhar Card.pdf</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='right_area'>
                                    <div className='icons_area'>
                                        <div className='img_one'>
                                            <Image src={Eye} alt='...' />
                                        </div>
                                        <div className='img_two'>
                                            <Image src={Delete} alt='...' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <div className='card_details'>
                                <div className='left_area'>
                                    <div className='inner_area'>
                                        <div className='inner_left_area'>
                                            <div className='inner_img'>
                                                <Image src={PdfImg} alt='...' />
                                            </div>
                                        </div>
                                        <div className='inner_right_area'>
                                            <h3>Pan Card.pdf</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='right_area'>
                                    <div className='icons_area'>
                                        <div className='img_one'>
                                            <Image src={Eye} alt='...' />
                                        </div>
                                        <div className='img_two'>
                                            <Image src={Delete} alt='...' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Documents;