
import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function citiesList(params) {
    return mainWrapper.get(WebConstant.host + "/cities", params);
};


function districtsList (params) {
    return mainWrapper.get(WebConstant.host + "/districts", params);
};


function statesList (params) {
    return mainWrapper.get(WebConstant.host + "/states", params);
};


function nationalities (params) {
    return mainWrapper.get(WebConstant.host + "/nationalities", params);
};

function countrieslist (params) {
    return mainWrapper.get(WebConstant.host + "/countries", params);
};

function taskPriority (params) {
    return mainWrapper.get(WebConstant.host + "/task_priority", params);
};


function bloodGroups (params) {
    return mainWrapper.get(WebConstant.host + "/bloodgroup", params);
};


function patientTypes (params) {
    return mainWrapper.get(WebConstant.host + "/patient_types", params);
};


function gendersList (params) {
    return mainWrapper.get(WebConstant.host + "/genders", params);
};


function martialStatusList (params) {
    return mainWrapper.get(WebConstant.host + "/marital_status", params);
};


function medicineFrequencyList (params) {
    return mainWrapper.get(WebConstant.host + "/medicine_frequency", params);
};



function globalSearchApi (params) {
    return mainWrapper.get(WebConstant.host + "/search_list", params);
};


export const actionsServices = {
    citiesList,
    districtsList,
    statesList,
    nationalities,
    countrieslist,
    taskPriority,
    bloodGroups,
    patientTypes,
    gendersList,
    martialStatusList,
    medicineFrequencyList,
    globalSearchApi,
};
