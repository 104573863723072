import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthController from "../../../../apis/Controllers/AuthController/AuthController";
import { Toaster } from "../../../Helper/toaster/Toaster";
import Validation from "../../../Helper/validations/vaildation";

const ForgotFunctions = () => {
    const defaultValues = {
        email: "",
    };
    const defaultErrors = {
        email: {
            rules: ["required", "email"],
            isValid: true,
            message: "",
        },
    };
    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    //handleChange and Validation on each field ------
    const handleChange = (field, value) => {
        let validation = new Validation(isError);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };


    //check full form validation---------------------
    const handleForgot = async () => {
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            setLoader(true);
            let response = await AuthController.forgotDetails(values);
            if (response && response.status) {
                new Toaster().success(`${response.message}`);
                navigate("/email-verification", { state: response });
                setLoader(false);
            } else {
                setLoader(false);
            }
        } else {
            setError({ ...isValid.errors });
        }
    };

    return {
        values,
        isError,
        loader,
        setLoader,
        handleChange,
        handleForgot,
        navigate,
    };
};

export default ForgotFunctions;
