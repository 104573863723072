import ActionType from "../constants/index";

let stored = localStorage.getItem("SET_SCROLLER");
try {
    stored = stored ? stored : null;
} catch (err) {
    console.log("Error==>", err);
}

const initialState = {
    scroller: stored,
};

const ScrollerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.SET_SCROLLER:
            return Object.assign({}, state, {
                scroller: action.payload,
            });
        default:
            return state;
    }
};

export default ScrollerReducer;
