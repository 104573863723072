import { Toaster } from "../../../components/Helper/toaster/Toaster";
import { roomsServices } from "../../Services/RoomServices/rooms.services";


const roomListing = async (params) => {
    let response = await roomsServices.roomsListing(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const superAdminRoomsListing = async (params) => {
    let response = await roomsServices.superAdminRoomsListing(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const super_room_list_dropdown = async (params) => {
    let response = await roomsServices.super_room_list_dropdown(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const super_room_list_filters = async (params) => {
    let response = await roomsServices.super_room_list_filters(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const roomCreate = async (data) => {
    let post = {
        room_number: data.roomNumber,
        available_beds: data.availableBeds,
        hospital_id: data.hospitalId ? data.hospitalId.id : null,
        ward_id: data.wardId ? data.wardId.id : null,
    }
    let response = await roomsServices.roomsCreate(post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const roomDetail = async (id) => {
    let response = await roomsServices.roomsDetail(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const roomUpdate = async (id, data) => {
     let post = {
        room_number: data.roomNumber,
        available_beds: data.availableBeds,
        hospital_id: data.hospitalId ? data.hospitalId.id : null,
        ward_id: data.wardId ? data.wardId.id : null,
    }
    let response = await roomsServices.roomsUpdate(id,post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const roomDelete = async (id) => {
    let response = await roomsServices.roomsDelete(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const updateRoomStatus = async(id,params) => {
     let response = await roomsServices.updateRoomStatus(id,params);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
}


const roomsController = {
    roomListing,
    superAdminRoomsListing,
    super_room_list_dropdown,
    super_room_list_filters,
    roomCreate,
    roomDetail,
    roomUpdate,
    roomDelete,
    updateRoomStatus
};

export default roomsController;
