import ActionTypes from "../../constants";

const initialState = {
    filters: {
        patientStatus: [],
        wards: [],
        hospitals: [],
        admissionFromDate: null,
        admissionToDate: null
    },
};


const PatientListFilters = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_PATIENT_LIST_FILTERS:
            return Object.assign({}, state, {
                filters: action.payload,
            });
        default:
            return state;
    }
};

export default PatientListFilters;
