import { useState } from "react";
import Validation from "../../../Helper/validations/vaildation";
import reportsController from "../../../../apis/Controllers/ReportsController/reports.controller";



export const EditReportsModalFunctions = (props) => {
    const defaultValues = {
        reportName: null,
        discription: null,
        upload: null,
    };

    const defaultErrors = {
        reportName: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        discription: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        upload: {
            rules: [],
            isValid: true,
            message: "",
        },
    };

    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);
    const [loader, setLoader] = useState(false);


    const handleChange = (field, value) => {
        let validation = new Validation(isError);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };


    const handleSubmit = async () => {
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            props.handleAddReport(values);                             //<------------callback funtion from Reports Tab 
        } else {
            setError({ ...isValid.errors });
        }
    };

    const Reportdetails = async (id) => {
        let response = await reportsController.reportDetail(id);
        if (response && response.status) {
            let item = response && response.data;
            setValues({
                ...values,
                reportName: item && item.title ? item.title : null,
                discription: item && item.description ? item.description : null,
                upload: item && item.attachment ? item.attachment : null,
            })
        } else {
            return null;
        }
    };


    return {
        values,
        isError,
        loader,
        handleChange,
        handleSubmit,
        Reportdetails
    }
}