import { combineReducers } from "redux";
import APITokenReducer from "./apiTokens";
import ScrollerReducer from "./scroller";
import UserReducer from "./user";
import DrawerReducer from "./drawer"
import HospitalReducer from "./hospital"
import UserPermissionsReducer from './userPermissions'
import WardListFilters from './filters/wardList';
import TaskListFilters from './filters/taskListFilters';
import StaffListFilters from './filters/staffListFilters';
import PatientListFilters from './filters/patientListFilters'
import DepartmentListFilters from './filters/departmentListFilters';
import RoomListFilters from "./filters/roomListFilters";
import BedListFilters from "./filters/bedListFilters"

const appReducer = combineReducers({
    UserReducer,
    APITokenReducer,
    ScrollerReducer,
    TaskListFilters,
    DrawerReducer,
    HospitalReducer,
    StaffListFilters,
    PatientListFilters,
    UserPermissionsReducer,
    WardListFilters,
    DepartmentListFilters,
    RoomListFilters,
    BedListFilters
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};
export default rootReducer;
