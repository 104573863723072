import { faEye, faEyeSlash, faFileLines, faPaperclip, faPlus, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import * as Constants from "../../../../../Helper/constants/constants";
import WithoutImageHeader from "../../../../../Helper/withoutImageHeader";
import Select from 'react-select';
import AddReportFunction from "./function";
import { useEffect, useRef, useState } from "react";
import Loader from "../../../../../Helper/loader";
import UploadFileComponent from "../../../../../Helper/UploadFile";
import WebConstant from "../../../../../../apis/webConstants";
import { Toaster } from "../../../../../Helper/toaster/Toaster";
import fileUploadController from "../../../../../../apis/Controllers/ImageController/file.upload";
import { getName, getName3, getShortName } from "../../../../../Helper/General";

const AddReportBioOne = () => {

    const [progress, setProgress] = useState(0);
    const inputFile = useRef();
    const {
        breadcrumData,
        breadcrumItem,
        setbreadcrumItem,
        values,
        setValues,
        loader,
        navigate,
        isError,
        handleChange,
        handleChange1,
        submitReport,
        addCategories,
        deleteCategories,
        getTestTypeListing,
        testTyleList,
        testNameList,
        testCategoryList,
        location,
        getTestNameListing,
        getTestCategoryListing,
        setTestCategoryList,
        handleChange2,
        values2,
        setValues2,
        isError2,
        getPatientLabreprtdetails,
        editTestReport,
        handleBreadcrumDataNaviagtion
    } = AddReportFunction()

    useEffect(() => {
        if (location && location.state && location.state.name) {
            let id =
                location && location.state && location.state.id
                    ? location.state.id
                    : null;

            getPatientLabreprtdetails(id);
            setbreadcrumItem("Edit Report");
        }
        getTestTypeListing();
    }, []);

    const handleImage = async (value) => {
        if (
            [
                "image/jpeg",
                "image/png",
                "image/gif",
                "image/webp",
                "application/pdf",
                "application/msword",
                "application/vnd.ms-excel",
                "application/vnd.ms-powerpoint",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ].includes(value.type)
        ) {
            if (value.size < WebConstant.maxFileSize) {
                setProgress(0.5);
                let callback = (p) => {
                    setProgress(p > 100 ? p * 1 - 5 : p);
                };

                let response = await fileUploadController.uploadFile(value, callback);
                if (response && response.status) {
                    setProgress(0);
                    let arr = values && values.image ? values.image : [];
                    arr.push(response.path)
                    handleChange('image', arr)
                } else {
                    new Toaster().success(response.message);
                }
            }
        } else {
            new Toaster().error("File size exceeds the maximum limit of 5MB.");
        }
    };

    const clear = index => {
        let array = [...values.image];
        array.splice(index, 1);
        handleChange('image', array)
    };

    const handleSetValues = () => {
        if (values.testName !== null) {
            setValues({ ...values, testName: null, image: [] })
            setValues2({ ...values2, range: null })
            setTestCategoryList([])
        }

    }

    return (
        <div className="add_Report_hemotology manage_select_react">
            <WithoutImageHeader header={location && location.state && location.state.name ? Constants.EDIt_REPORT : Constants.ADD_REPORT}
                // breadcrumData={breadcrumData}
                // breadcrumItem={breadcrumItem}
                // handleBack={() => navigate('/patientDetails', { replace: true, state: { id: location && location.state && location.state.patient_id } })}
                handleBack={() => navigate(-1)}
            // handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
            />
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="report_main_area">
                            <div className="inner_area">
                                <div className="dropdown_box">
                                    <h6>Test Type<span>*</span></h6>
                                    <div className="dropdown_area">
                                        <Select
                                            isDisabled={location && location.state && location.state.name ? true : false}
                                            placeholder="Select"
                                            value={values && values.testType}
                                            options={testTyleList ? testTyleList : []}
                                            onChange={(e) => {
                                                getTestNameListing(e.id); handleChange('testType', e); handleSetValues();
                                            }}
                                            className={isError.testType.message ? "error_border_select" : ""}
                                        />
                                        {isError && isError.testType.message ? (
                                            <p className="text-danger mt-2">
                                                {isError && isError.testType.message}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>

                                {values && values.testType ?
                                    <div className="dropdown_box">
                                        <h6>Test Name<span>*</span></h6>
                                        <div className="dropdown_area">
                                            <Select
                                                isDisabled={location && location.state && location.state.name ? true : false}
                                                placeholder="Select"
                                                value={values && values.testName}
                                                options={testNameList ? testNameList : []}
                                                onChange={(e) => { getTestCategoryListing(e.id); handleChange('testName', e) }}
                                                className={isError.testName.message ? "error_border_select" : ""}
                                            />
                                            {isError && isError.testName.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError && isError.testName.message}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div> : null}

                                {values && values.testName && parseInt(values.testName.is_sub_category) === 1 && testCategoryList && testCategoryList.length > 0 ?
                                    testCategoryList.map((item, index) => {
                                        return (
                                            <>
                                                <Row key={index} style={{ display: 'flex', alignItems: 'end' }}>
                                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                        <div className="dropdown_box">
                                                            <h6>Test Category<span>*</span></h6>
                                                            <div className="dropdown_area">
                                                                <Select
                                                                    placeholder="Select"
                                                                    isDisabled={true}
                                                                    // options={testCategoryList}
                                                                    value={{ label: item.title }}
                                                                //className={item && item.error_test  ? "error_border_select" : ""}
                                                                />
                                                                {/* {item && item.error_test ? (
                                                    <p className="text-danger mt-2">
                                                        {"This field is required"}
                                                    </p>
                                                    ) : null} */}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {parseInt(item.is_test_sub_category) === 0 ?
                                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                            <div className="form_div_area">
                                                                <Form>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>{item.test_units ? 'Range' : ''} {item.test_units ? `(${item.test_units})` : null}</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder={item.test_range ? item.test_range : "Enter Value"}
                                                                            value={item.test_value}
                                                                            onChange={(e) => handleChange1(item, e.target.value)}
                                                                        />
                                                                    </Form.Group>
                                                                </Form>
                                                            </div>
                                                        </Col> : null}
                                                </Row>

                                                {parseInt(item.is_test_sub_category) == 1 ?
                                                    item.is_test_sub_category_arr.map((sitem, i) =>
                                                        <Row>
                                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                                <div className="dropdown_box">
                                                                    <h6>Sub-Test Category ({item.title})</h6>
                                                                    <div className="dropdown_area">
                                                                        <Select
                                                                            placeholder="Select"
                                                                            isDisabled={true}
                                                                            value={{ label: sitem.title }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                                <div className="form_div_area">
                                                                    <Form>
                                                                        <Form.Group className="form-group">
                                                                            <Form.Label>Range ({sitem.test_units} )</Form.Label>
                                                                            <Form.Control
                                                                                placeholder="Enter Value"
                                                                                value={sitem && sitem.test_value}
                                                                                onChange={(e) => handleChange1(sitem, e.target.value)}
                                                                            />
                                                                        </Form.Group>
                                                                    </Form>

                                                                    {/* <div className="right_area">
                                                <a href="javscript:;">
                                                    <div className="plus_box">
                                                        <FontAwesomeIcon icon={faTrashCan} className="icon_plus" />
                                                    </div>
                                                </a>
                                            </div> */}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    ) : null}
                                            </>
                                        )
                                    })
                                    :
                                    <>
                                        {values && values.testName && parseInt(values.testName.is_sub_category) === 0 ? <div className="dropdown_box">
                                            <Form>
                                                <Form.Group className="form-group">
                                                    <Form.Label>{values.testName.test_units ? 'Range' : values.testName.title} {values.testName && values.testName.test_units ? `(${values.testName.test_units})` : null}<span>*</span></Form.Label>
                                                    <Form.Control
                                                        placeholder={values.testName ? `Range - ${values.testName.test_range}` : "Enter Value"}
                                                        value={values2 && values2.range}
                                                        onChange={(e) => handleChange2('range', e.target.value)}
                                                        className={isError2.range.message ? "error_border" : ""}
                                                    />
                                                </Form.Group>
                                            </Form>
                                            {isError2.range.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError2.range.message}
                                                </p>
                                            ) : null}
                                        </div> : null}
                                    </>
                                }
                            </div>
                            <div className="upload_img_area">

                                {/* Before-Upload_file */}
                                {values && values.image.length <= 0 ? <div className="before_upload_area" onClick={() => inputFile.current && inputFile.current.click()} >
                                    <div className="text_area">
                                        <FontAwesomeIcon icon={faPaperclip} className="icon_area" />
                                        Upload File
                                    </div>
                                </div> : null}
                                <Form.Control
                                    style={{ display: "none" }}
                                    ref={inputFile}
                                    type="file"
                                    placeholder="Upload"
                                    accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                    // className={props && props.isError && props.isError.message
                                    //     ? "error_border"
                                    //     : ""}
                                    onChange={(event) => {
                                        handleImage(event.target.files[0])
                                        event.target.value = "";
                                    }}
                                />
                                {/* After- Upload_file */}
                                {values && values.image.length > 0 ? <div className="after_upload_area">
                                    <div className="inner_img_area">
                                        <div className="icon_text_area" onClick={() => inputFile.current && inputFile.current.click()} >
                                            <FontAwesomeIcon icon={faPaperclip} className="icon" />
                                            <p>Upload File</p>
                                        </div>
                                    </div>
                                    {values && values.image.map((item, index) => (<div key={index} className="inner_img_area upload_file">
                                        <div className="icon_text_area">
                                            <FontAwesomeIcon icon={faFileLines} className="icon" />
                                            <p>{getShortName(item)}</p>
                                        </div>
                                        <div className="cross_icon">
                                            <FontAwesomeIcon icon={faXmark} onClick={() => clear(index)} />
                                        </div>
                                    </div>))}
                                </div> : null}
                            </div>

                            <div className="btn_area">
                                <Button
                                    className="cancelBtn"
                                    type="button"
                                    // onClick={() => navigate('/patientDetails', { replace: true, state: { id: location && location.state && location.state.patient_id } })}
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </Button>
                                {location && location.state && location.state.name ?
                                    <Button
                                        className="submitBtn"
                                        type="button"
                                        onClick={() => editTestReport(location && location.state && location.state.id ? location.state.id : null)}
                                    >
                                        Edit
                                    </Button> :
                                    <Button
                                        className="submitBtn"
                                        type="button"
                                        onClick={() => submitReport()}
                                    >
                                        Submit
                                    </Button>}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            {loader ?
                <Loader loader={loader} />
                : null}
        </div >
    );
};

export default AddReportBioOne;