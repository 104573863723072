import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import * as Constants from '../../../Helper/constants/constants';
import CustomTab from "../../../Helper/customTabs";
import WithoutImageHeader from "../../../Helper/withoutImageHeader";
import Nurse from "../../Tabs/StafList";
import TaskList from "../../Tabs/TaskList";
import { SearchFunctions } from "./functions";
import Loader from "../../../Helper/loader";
import PatientList from "../../Tabs/PatientList";
import StafList from "../../Tabs/StafList";


const SearchScreen = () => {
    const {
        keyword,
        setSearch,
        currentTab,
        setCurrentTab,
        loader,
        tabs,
        search,
        stafResult,
        taskResult,
        patinetResult,
        taskTotalCount,
        patinetTotalCount,
        stafTotalCount,
        navigate
    } = SearchFunctions();


    return (
        <div className="search_screen">
            <WithoutImageHeader
                header={Constants.Search}
                handleBack={() => navigate(-1)}
            />
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Form>
                            <Form.Group className="form-group">
                                <Form.Control
                                    type="text"
                                    placeholder="Search Here..."
                                    value={keyword}
                                    onChange={(e) =>
                                    (
                                        search(e.target.value),
                                        setSearch(e.target.value))
                                    }
                                />
                                <a>
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </div>
                                </a>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <div className="task_btn_area">
                <CustomTab
                    tabs={tabs}
                    currentTab={currentTab}
                    setCurrentTab={(e) => setCurrentTab(e)}
                />
            </div>
            {
                loader ?
                    <Loader
                        loader={loader}
                    />
                    :
                    <>
                        <div className="search_data_details">
                            <h4>Search Result :
                                {
                                    parseInt(currentTab) === 1 ? stafTotalCount :
                                        parseInt(currentTab) === 2 ? taskTotalCount :
                                            parseInt(currentTab) === 3 ? patinetTotalCount :
                                                0
                                }
                            </h4>
                        </div>
                        <div className="custom_tab_data">
                            {parseInt(currentTab) === 1 ?
                                <StafList
                                    List={stafResult && stafResult.length > 0 ? stafResult : []}
                                    keyword={keyword}
                                    navigate={navigate}
                                />
                                :
                                null
                            }
                            {parseInt(currentTab) === 2 ?
                                <TaskList
                                    List={taskResult && taskResult.length > 0 ? taskResult : []}
                                    keyword={keyword}
                                    navigate={navigate}
                                />
                                :
                                null
                            }
                            {parseInt(currentTab) === 3 ?
                                <PatientList
                                    List={patinetResult && patinetResult.length > 0 ? patinetResult : []}
                                    keyword={keyword}
                                    navigate={navigate}
                                />
                                :
                                null
                            }
                        </div>
                    </>
            }
        </div >
    )
}

export default SearchScreen;