import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";

function wardListing(params) {
    return mainWrapper.get(WebConstant.host + `/ward`, params);
}

function wardCreate(params) {
    return mainWrapper.post(WebConstant.host + `/ward`, params);
}

function wardDetail(id) {
    return mainWrapper.get(WebConstant.host + `/ward/${id}`);
}

function wardUpdate(id, params) {
    return mainWrapper.put(WebConstant.host + `/ward/${id}`, params);
}
function updateWardStatus(id, params) {
    return mainWrapper.put(
        WebConstant.host + `/update_ward_status/${id}`,
        params
    );
}

function wardDelete(id) {
    return mainWrapper._delete(WebConstant.host + `/ward/${id}`);
}

function superAdminwardListing(params) {
    return mainWrapper.get(WebConstant.host + `/ward_listing`, params);
}

export const wardServices = {
    wardListing,
    wardCreate,
    wardDetail,
    wardUpdate,
    wardDelete,
    superAdminwardListing,
    updateWardStatus,
};
