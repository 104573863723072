import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function logsListing(params) {
    return mainWrapper.get(WebConstant.host + `/log_list`, params);
};

function subAdminLogsList(params) {
    return mainWrapper.get(WebConstant.host + `/admin_log_list`, params);
}

export const logsServices = {
    logsListing,
    subAdminLogsList
};
