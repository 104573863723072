import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import AuthHeader from "../../../Helper/authHeader";
import ButtonLoader from "../../../Helper/buttonLoader";
import * as Constants from "../../../Helper/constants/constants";
import ForgotFunctions from "./function";
import Loader from '../../../Helper/loader';

const ForgetPassword = () => {
    const {
        values,
        isError,
        handleChange,
        handleForgot,
        loader,
        navigate
    } = ForgotFunctions();

    return (
        <div className="forget_password_screen">
            <AuthHeader
                header={Constants.FOGET_PASSWORD_HEADER}
                description={Constants.FOGET_PASSWORD_DESCRIPTION}
            />
            <Container>
                {
                    loader ?
                        <Loader
                            loader={loader}
                        />
                        :
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="form">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Label>
                                                Enter your email address
                                            </Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="test@gmail.com"
                                                value={values.email}
                                                className={isError.email.message
                                                    ? "error_border"
                                                    : ""}
                                                onChange={(e) =>
                                                    handleChange(
                                                        "email",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            {isError.email.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError.email.message}
                                                </p>
                                            ) : null}
                                        </Form.Group>
                                        {/* <Button
                                    type="submit"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleForgot();
                                    }}
                                    disabled={loader ? true : false}
                                >
                                    {loader ? (
                                        <ButtonLoader
                                            loader={loader}
                                            variant={"light"}
                                            disabled={loader ? true : false}
                                        />
                                    ) : null}
                                    {loader ? "Sending..." : "Send"}
                                </Button> */}
                                        <div className="btn_area">
                                            <Button
                                                className="cancelBtn"
                                                type="button"
                                                onClick={() => navigate('/login')}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                className="submitBtn"
                                                type="submit"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleForgot();
                                                }}

                                            >
                                                Send
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        </Row>

                }
            </Container>
        </div>
    );
};

export default ForgetPassword;
