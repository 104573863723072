import React, { useEffect } from "react";
//---------- FONTAWESOME IMPORT ------------//
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//-------- BOOTSTRAP ITEMS IMPORT ----------//
import { faCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import { useCallback } from "react";
import { Button, Col, Container, Dropdown, Form, Image, Row } from "react-bootstrap";
import { Filter, Filters } from "../../../../../assets/images";
import store from "../../../../../redux/store";
import * as Constants from "../../../../Helper/constants/constants";
import Loader from "../../../../Helper/loader";
import StafListTab from "../../../../Helper/stafListTabs";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import PaginationComponent from "../../../CommanComponents/Pagination";
import DeleteModal from "../../../Modals/DeleteModal";
import StaffFilterModal from "../../../Modals/StaffFilterModal";
import StafListSuperAdminListFilterModal from "../../../Modals/stafListSuperAdminListFilters";
import StaffListFunctions from "./function";
import { StafListComponet } from "./listComponet";
import MultipleHospitalPopup from "../../../Modals/MultipleHospitalModal";

const StaffList = () => {
    const {
        getModulePermissions,
        checkPermissions,
        breadcrumData,
        breadcrumItem,
        handleBreadcrumDataNaviagtion,
        stafList,
        handleDeleteStaf,
        deletePopup,
        setDeletePopup,
        applyFilters,
        handleClearAllFilters,
        showFilterModal,
        setShowFilterModal,
        navigate,
        itemsPerPage,
        handleItemPerPage,
        stafListLength,
        loader,
        handleBackClick,
        handleNextClick,
        page,
        totalPages,
        handlePage,
        setDeleteItem,
        setReduxValue,
        filtersAppliedSign,
        updateStafStatus,
        getStafListing,
        keyword,
        setSearch,
        handleDrawer,
        currentTab,
        setCurrentTab,
        tabs,
        setTabs,
        handleTabData,
        superAdminsListLength,
        superAdminsList,
        getSuperAdminsListing,
        setSearch2,
        keyword2,
        superAdminListFilters,
        setSuperAdminListFilters,
        sortingValue,
        setSortingValue,
        //<-----------
        page2,
        setPage2,
        totalPages2,
        setTotalPages2,
        itemsPerPage2,
        handleBackClick2,
        handleNextClick2,
        //<-----------
        breadcrumData1,
        showMultiHosPopup,
        setShowMultiHosPopup
    } = StaffListFunctions();
    let storedFilters = store.getState().StaffListFilters.filters;
    let userdata = localStorage.getItem('SET_USER_DATA');
    let User = JSON.parse(userdata);

    useEffect(() => {
        let userdata = localStorage.getItem('SET_USER_DATA');
        let User = JSON.parse(userdata);
        if (User && parseInt(User.user_type) === 2) {
            getModulePermissions();
        }
        else;
    }, []);


    const search = useCallback(debounce(getStafListing, 1000), []);
    const search2 = useCallback(debounce(getSuperAdminsListing, 1000), []);
    return (
        <div className="task_list_screen staff_list_section">
            <WithoutImageHeader
                header={Constants.STAFF_LIST}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBreadcrumDataNaviagtion={(i) =>
                    handleBreadcrumDataNaviagtion(i)
                }
                setReduxvalue={(e) => setReduxValue(e)}
                lefticon="menu"
            />
            <Container onClick={() => handleDrawer()}>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="task_list_details">
                            <div className="title_btn_area">
                                <div className="left_area">
                                    <p>
                                        Total {parseInt(currentTab) === 1 ? 'Staff' : 'Super Admin'}:{" "}{parseInt(currentTab) === 1 ? stafListLength : superAdminsListLength}
                                    </p>
                                </div>
                                {
                                    User && parseInt(User.user_type) === 1 ?
                                        <div className="right_area">
                                            <Button
                                                className="btn"
                                                type="submit"
                                                onClick={() => navigate("/add-staff")}
                                            >
                                                Add Staff
                                            </Button>
                                        </div>
                                        :
                                        checkPermissions
                                            && checkPermissions.permissions
                                            && checkPermissions.permissions.length > 1
                                            && parseInt(checkPermissions.permissions[1].value) === 1 ?
                                            <div className="right_area">
                                                <Button
                                                    className="btn"
                                                    type="submit"
                                                    onClick={() => navigate("/add-staff")}
                                                >
                                                    Add Staff
                                                </Button>
                                            </div>
                                            :
                                            null
                                }
                            </div>
                            <StafListTab
                                tabs={tabs}
                                currentTab={currentTab}
                                setCurrentTab={(e) => (
                                    setCurrentTab(e), handleTabData(e)
                                )}
                            />
                            <div className="search_section">
                                <div className="search_area">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Control
                                                type="text"
                                                placeholder={parseInt(currentTab) === 1 ? "Search by staff name here" : "Search by super admin name here"}
                                                value={parseInt(currentTab) === 2 ? keyword2 : keyword}
                                                onChange={(e) =>
                                                    parseInt(currentTab) === 1 ?
                                                        (search(e.target.value, 1, itemsPerPage, storedFilters),
                                                            setSearch(e.target.value))
                                                        :
                                                        (
                                                            search2(e.target.value, 1, itemsPerPage),
                                                            setSearch2(e.target.value)
                                                        )
                                                }
                                            />
                                            <a>
                                                <div className="icon">
                                                    <FontAwesomeIcon
                                                        icon={faSearch}
                                                    />
                                                </div>
                                            </a>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div className="pagination_list">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Select
                                                value={parseInt(currentTab) === 1 ? itemsPerPage : itemsPerPage2}
                                                onChange={(e) => handleItemPerPage(e)}
                                            >
                                                <option value={5}>5</option>
                                                <option value={10}>10</option>
                                                <option value={15}>15</option>
                                                <option value={20}>20</option>
                                                <option value={25}>25</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Form>
                                </div>

                                {
                                    parseInt(currentTab) === 1 ?
                                        <div className="filter_button">
                                            <Button
                                                className={filtersAppliedSign ? "btn-primary" : 'btn-border'}
                                                onClick={() => setShowFilterModal(true)}
                                            >
                                                <Image
                                                    src={Filters}
                                                    alt="..."
                                                    className="image_area"
                                                />
                                                Filters
                                            </Button>
                                            {
                                                filtersAppliedSign ?
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        className="filter_icon"
                                                    />
                                                    :
                                                    null
                                            }
                                        </div>
                                        :
                                        <Dropdown>
                                            <Dropdown.Toggle variant="none" id="dropdown-basic"  >
                                                <div className={"sort_button"}>
                                                    <Button
                                                        className={sortingValue ? "btn_bg" : "btn-primary"}
                                                        type="submit"

                                                    >
                                                        {sortingValue ? sortingValue : 'Sort'}
                                                        <Image
                                                            src={Filter}
                                                            alt="..."
                                                            className="image_area"
                                                        />
                                                    </Button>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => (setSortingValue('ASC'))}>
                                                    Ascending
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => (setSortingValue('DESC'))}>
                                                    Descending
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                }
                            </div>
                            {loader ?
                                <Loader
                                    loader={loader}
                                />
                                :
                                parseInt(currentTab) === 1 ?
                                    <StafListComponet
                                        updateStafStatus={updateStafStatus}
                                        navigate={navigate}
                                        setDeleteItem={setDeleteItem}
                                        setDeletePopup={setDeletePopup}
                                        List={stafList}
                                        checkPermissions={checkPermissions}
                                        currentTab={currentTab}
                                        loader={loader}
                                        breadcrumData1={breadcrumData1}
                                        User={User}
                                        showMultiHosPopup={showMultiHosPopup}
                                        setShowMultiHosPopup={setShowMultiHosPopup}
                                    />
                                    :
                                    <StafListComponet
                                        updateStafStatus={updateStafStatus}
                                        navigate={navigate}
                                        setDeleteItem={setDeleteItem}
                                        setDeletePopup={setDeletePopup}
                                        List={superAdminsList}
                                        checkPermissions={checkPermissions}
                                        currentTab={currentTab}
                                        loader={loader}
                                        User={User}
                                        showMultiHosPopup={showMultiHosPopup}
                                        setShowMultiHosPopup={setShowMultiHosPopup}
                                    />
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            {
                !loader && parseInt(currentTab) === 1 && stafList && stafList.length > 0 ?
                    <PaginationComponent
                        page={page}
                        setPage={handlePage}
                        totalPages={totalPages}
                        handleBackClick={handleBackClick}
                        handleNextClick={handleNextClick}
                    />
                    :
                    null
            }
            {
                !loader && parseInt(currentTab) === 2 && superAdminsList && superAdminsList.length > 0 ?
                    <PaginationComponent
                        page={page2}
                        setPage={handlePage}
                        totalPages={totalPages2}
                        handleBackClick={handleBackClick2}
                        handleNextClick={handleNextClick2}
                    />
                    :
                    null
            }
            {showFilterModal ? (
                <StaffFilterModal
                    show={showFilterModal}
                    close={() => setShowFilterModal(false)}
                    applyFilters={applyFilters}
                    handleClearAllFilters={handleClearAllFilters}
                />
            ) : null}

            {deletePopup ? (
                <DeleteModal
                    show={deletePopup}
                    handleSubmit={() => handleDeleteStaf()}
                    close={() => setDeletePopup(false)}
                    message={"Do you want to delete this staff member ?"}
                    loader={loader}
                />
            ) : null}
            {
                superAdminListFilters ?
                    <StafListSuperAdminListFilterModal
                        show={superAdminListFilters}
                        applyFilters={applyFilters}
                        handleClearAllFilters={handleClearAllFilters}
                        close={() => setSuperAdminListFilters(false)}
                    />
                    :
                    null
            }
              
        </div>
    );
};

export default StaffList;
