import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Select, { components } from 'react-select';


const CustomSelectOptions = (props) => {
    const { Option } = components

    return (
        <Option {...props.items}>
            {props && props.items && props.items.data && props.items.data.label ? props.items.data.label : ''}
            {
                props && props.value && props.value.id === props.items.data.id ?
                    <FontAwesomeIcon
                        style={{ float: 'right' }}
                        icon={faCheck}
                    />
                    :
                    null
            }
        </Option>
    )
};

export default CustomSelectOptions