import { faBriefcaseMedical, faFileInvoice, faHospital, faListCheck, faLock, faPen, faPersonShelter, faPowerOff, faProcedures, faUser, faUserGroup, faWarehouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AuthController from "../../apis/Controllers/AuthController/AuthController";
import permissionsController from "../../apis/Controllers/PermissionsController/permissions.contoller";
import { LeftIcon, Man } from "../../assets/images";
import { setDrawer } from "../../redux/actions/users";
import store from "../../redux/store";
import { renderImage } from "./General";


const DrawerComponent = (props) => {

    const [drawerState, setDrawerState] = useState()
    const [user, setUser] = useState(null)
    // const [hospiatlName, setHospiatlName] = useState(null);
    const [hospitalPermissions, setHospiatlPermissions] = useState(null);
    const [patientPermissions, setPatientPermissions] = useState(null);
    const [taskPermissions, setTaskPermissions] = useState(null);
    const [rolesPermissions, setRolesPermissions] = useState(null);
    const [wardsPermissions, setWardsPermissions] = useState(null);
    const [departmentsPermissions, setDepartmentsPermissions] = useState(null);
    const [stafPermissions, setStafPermissions] = useState(null);
    const [roomsPermissions, setRoomsPermissions] = useState(null);
    const [bedsPermissions, setBedsPermissions] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    let superAdminDrawerMenu = [
        {
            name: 'Dashboard',
            path: '/dashboard-superadmin',
            icon: faHospital,
            active: false
        },
        {
            name: 'Manage Hospitals',
            path: '/hospital-listing',
            icon: faBriefcaseMedical,
            active: false
        },
        {
            name: 'Manage Staff',
            path: '/staff-list',
            icon: faUserGroup,
            active: false
        },
        {
            name: 'Manage Patients',
            path: '/manage-patient-list',
            icon: faUser,
            active: false
        },
        {
            name: 'Manage Tasks',
            path: '/task-list',
            icon: faListCheck,
            active: false
        },
        {
            name: 'Manage Roles',
            path: '/role-list',
            icon: faFileInvoice,
            active: false
        },
        {
            name: 'Manage Wards',
            path: '/wards',
            icon: faWarehouse,
            active: false
        },
        {
            name: 'Manage Departments',
            path: '/department',
            icon: faFileInvoice,
            active: false
        },
        {
            name: 'Manage Rooms',
            path: '/room-list',
            icon: faPersonShelter,
            active: false
        },
        {
            name: 'Manage Beds',
            path: '/bed-list',
            icon: faProcedures,
            active: false
        },
        {
            name: 'Change Password',
            path: '/change-password',
            icon: faLock,
            active: false
        },
    ]

    const [drawerList, setDrawerList] = useState(superAdminDrawerMenu);
    let permission = localStorage.getItem('SET_USER_PERMISSION');
    let data = localStorage.getItem('SET_HOSPITAL');
    let hospiatlName = JSON.parse(data);
    let userdata = localStorage.getItem('SET_USER_DATA');

    useEffect(() => {
        getUser();
    }, []);


    useEffect(() => {
        getUser();
    }, [userdata, location]);

    useEffect(() => {
        let item = JSON.parse(userdata);
        if (item && parseInt(item.user_type) === 2) {
            getUserPermissions();
        }
    }, [location]);


    useEffect(() => {
        var x = window.matchMedia("(max-width: 700px)")
        setDrawerState(x);
    }, []);


    // useEffect(() => {
    //     if (permission) {
    //         let permission = localStorage.getItem('SET_USER_PERMISSION');
    //         permission = JSON.parse(permission);
    //         let userPermissions = permission;
    //         handleDrawerMenu(userPermissions);
    //     }
    // }, [permission]);


    useEffect(() => {
        if (props.drawerState) {
            openNav()
        } else {
            closeNav()
        }
    }, [props.drawerState]);



    const getUser = async () => {
        let response = await AuthController.getLoginUser();
        // let data = localStorage.getItem('SET_HOSPITAL');
        if (response && response.id) {
            // let item = JSON.parse(data);
            // setHospiatlName(item)
            setUser(response);
            // if (response && parseInt(response.user_type) === 2) {
            //     getUserPermissions(response && response.id)
            // }
        } else {
            setUser(null);
            // setHospiatlName(null);
        }
    };

    const getUserPermissions = async () => {
        let response = await permissionsController.userpermissionsListing();
        if (response && response.status) {
            let data = response.data
            await localStorage.setItem('SET_USER_PERMISSION', JSON.stringify(data));
            handleDrawerMenu(data);
        } else return;
    };


    const handleDrawerMenu = (data) => {
        if (data) {
            for (let i in data) {
                if (data[i].type === 'hospital') {
                    if (data[i].permissions && data[i].permissions.length > 0) {
                        if (parseInt(data[i].permissions[0].value) === 1) {
                            setHospiatlPermissions(true);
                        }
                        else {
                            setHospiatlPermissions(null);
                        }
                    }
                    else {
                        setHospiatlPermissions(null);
                    }
                }
                if (data[i].type === 'staff') {
                    if (data[i].permissions && data[i].permissions.length > 0) {
                        if (parseInt(data[i].permissions[0].value) === 1) {
                            setStafPermissions(true);
                        }
                        else {
                            setStafPermissions(null);
                        }
                    }
                    else {
                        setStafPermissions(null);
                    }
                }
                if (data[i].type === 'user') {
                    if (data[i].permissions && data[i].permissions.length > 0) {
                        if (parseInt(data[i].permissions[0].value) === 1) {
                            setPatientPermissions(true);
                        }
                        else {
                            setPatientPermissions(null);
                        }
                    }
                    else {
                        setPatientPermissions(null);
                    }
                }
                if (data[i].type === 'task') {
                    if (data[i].permissions && data[i].permissions.length > 0) {
                        if (parseInt(data[i].permissions[0].value) === 1) {
                            setTaskPermissions(true);
                        }
                        else {
                            setTaskPermissions(null);
                        }
                    }
                    else {
                        setTaskPermissions(null);
                    }
                }
                if (data[i].type === 'role') {
                    if (data[i].permissions && data[i].permissions.length > 0) {
                        if (parseInt(data[i].permissions[0].value) === 1) {
                            setRolesPermissions(true);
                        }
                        else {
                            setRolesPermissions(null);
                        }
                    }
                    else {
                        setRolesPermissions(null);
                    }
                }
                // if (data[i].type === 'report') {
                //     if (data[i].permissions && data[i].permissions.length > 0) {
                //         if (parseInt(data[i].permissions[0].value) === 1) {
                //             setReportsPermissions(true);
                //         }
                //         else {
                //             setReportsPermissions(null);
                //         }
                //     }
                //     else {
                //         setReportsPermissions(null);
                //     }
                // }
                if (data[i].type === 'ward') {
                    if (data[i].permissions && data[i].permissions.length > 0) {
                        if (parseInt(data[i].permissions[0].value) === 1) {
                            setWardsPermissions(true);
                        }
                        else {
                            setWardsPermissions(null);
                        }
                    }
                    else {
                        setWardsPermissions(null);
                    }
                }
                if (data[i].type === 'department') {
                    if (data[i].permissions && data[i].permissions.length > 0) {
                        if (parseInt(data[i].permissions[0].value) === 1) {
                            setDepartmentsPermissions(true);
                        }
                        else {
                            setDepartmentsPermissions(null);
                        }
                    }
                    else {
                        setDepartmentsPermissions(null);
                    }
                }
                if (data[i].type === 'room') {
                    if (data[i].permissions && data[i].permissions.length > 0) {
                        if (parseInt(data[i].permissions[0].value) === 1) {
                            setRoomsPermissions(true);
                        }
                        else {
                            setRoomsPermissions(null);
                        }
                    }
                    else {
                        setRoomsPermissions(null);
                    }
                }
                if (data[i].type === 'bed') {
                    if (data[i].permissions && data[i].permissions.length > 0) {
                        if (parseInt(data[i].permissions[0].value) === 1) {
                            setBedsPermissions(true);
                        }
                        else {
                            setBedsPermissions(null);
                        }
                    }
                    else {
                        setBedsPermissions(null);
                    }
                }
            }
        }
    };


    const openNav = () => {
        document.getElementById("mySidenav").style.width = drawerState.matches ? "100%" : "450px";
    };


    const closeNav = () => {
        document.getElementById("mySidenav").style.width = "0";
    };


    const handleDrawer = async (e) => {
        await store.dispatch(setDrawer(false));
    };


    const handleNavigation = async () => {
        if (user && parseInt(user.user_type) === 1) {
            navigate('/edit-Profile');
            await store.dispatch(setDrawer(false));
        }
        // else {
        //     navigate("/add-staff", { state: { name: "fromDrawer", id: user.id, } })
        //     await store.dispatch(setDrawer(false));
        // }
    };


    return (
        <>
            <div id="mySidenav" className="sidenav">
                <div className="header_area">
                    <div className="icon_top" onClick={handleDrawer} >
                        <a >  <Image src={LeftIcon} alt="..." /></a>
                    </div>
                    <div className="profile_info">
                        <div className="img_main">
                            <div className="img_area">
                                <Image
                                    src={user && user.image ? renderImage(user.image) : Man}
                                    alt="..."
                                    onClick={() => { closeNav(); navigate(`/staff-detail/${user.id}`, { state: { name: user.name } }) }}
                                />
                            </div>
                            {user && parseInt(user.user_type) === 1 ? <div className="edit_img" onClick={() => handleNavigation()} >
                                <FontAwesomeIcon icon={faPen} />
                            </div> : null}
                        </div>
                        <h5 style={{ cursor: 'pointer' }} onClick={() => { closeNav(); navigate(`/staff-detail/${user.id}`, { state: { name: user.name } }) }}>{user && user.name ? user.name : ""}</h5>
                        <p >{user && user.role_title ? user.role_title : "Super Admin"}</p>
                    </div>
                    <div className="logout_top">
                        <a onClick={() => AuthController.logout()} className="btn-primary" style={{ cursor: 'pointer' }}>Logout</a>
                    </div>
                </div>
                <div className="body_area">
                    <div className="content_area">
                        <div className="left_area">
                            <p>{hospiatlName && hospiatlName.title ? hospiatlName.title : ""}</p>
                        </div>
                        {/* <div className="right_area">
                            <a href="javascript:;" className="btn btn-primary">Change</a>
                        </div> */}
                    </div>
                    <div className="list_area">
                        <ul>
                            {
                                user && parseInt(user.user_type) === 1 ?
                                    drawerList.map((item, index) => (
                                        <li key={index} className={location.pathname === item.path ? "active" : ""}>
                                            <a href={item.path}>
                                                <div className="text_icon_area">
                                                    <div className="left_icon">
                                                        <div className="icon_box">
                                                            <FontAwesomeIcon icon={item.icon} />
                                                        </div>
                                                    </div>
                                                    <div className="right_text">
                                                        <p>{item.name}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    ))
                                    :
                                    <>
                                        <li className={location.pathname === '/dashboard' ? "active" : ""}>
                                            <a>
                                                <div className="text_icon_area" onClick={() => { closeNav(); navigate('/dashboard') }}>
                                                    <div className="left_icon">
                                                        <div className="icon_box">
                                                            <FontAwesomeIcon icon={faHospital} />
                                                        </div>
                                                    </div>
                                                    <div className="right_text" style={{ cursor: 'pointer' }}>
                                                        <p>Dashboard</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        {
                                            hospitalPermissions ?
                                                <li className={location.pathname === '/hospital-listing' ? "active" : ""} >
                                                    <a>
                                                        <div className="text_icon_area" onClick={() => { closeNav(); navigate('/hospital-listing') }}>
                                                            <div className="left_icon">
                                                                <div className="icon_box">
                                                                    <FontAwesomeIcon icon={faBriefcaseMedical} />
                                                                </div>
                                                            </div>
                                                            <div className="right_text" style={{ cursor: 'pointer' }}>
                                                                <p>Manage Hospitals</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                :
                                                null
                                        }
                                        {
                                            stafPermissions ?
                                                <li className={location.pathname === '/staff-list' ? "active" : ""}>
                                                    <a>
                                                        <div className="text_icon_area" onClick={() => { closeNav(); navigate('/staff-list') }}>
                                                            <div className="left_icon">
                                                                <div className="icon_box">
                                                                    <FontAwesomeIcon icon={faUserGroup} />
                                                                </div>
                                                            </div>
                                                            <div className="right_text" style={{ cursor: 'pointer' }}>
                                                                <p>Manage Staff</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                :
                                                null
                                        }
                                        {
                                            patientPermissions ?
                                                <li className={location.pathname === '/manage-patient-list' ? "active" : ""}>
                                                    <a>
                                                        <div className="text_icon_area" onClick={() => { closeNav(); navigate('/manage-patient-list') }}>
                                                            <div className="left_icon">
                                                                <div className="icon_box">
                                                                    <FontAwesomeIcon icon={faUser} />
                                                                </div>
                                                            </div>
                                                            <div className="right_text" style={{ cursor: 'pointer' }}>
                                                                <p>Manage Patient</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                :
                                                null
                                        }
                                        {
                                            taskPermissions ?
                                                <li className={location.pathname === '/myTasks' ? "active" : ""}>
                                                    <a>
                                                        <div className="text_icon_area" onClick={() => { closeNav(); navigate('/myTasks') }}>
                                                            <div className="left_icon">
                                                                <div className="icon_box">
                                                                    <FontAwesomeIcon icon={faListCheck} />
                                                                </div>
                                                            </div>
                                                            <div className="right_text" style={{ cursor: 'pointer' }}>
                                                                <p>Manage Tasks</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                :
                                                null
                                        }
                                        {
                                            rolesPermissions ?
                                                <li className={location.pathname === '/role-list' ? "active" : ""}>
                                                    <a>
                                                        <div className="text_icon_area" onClick={() => { closeNav(); navigate('/role-list') }}>
                                                            <div className="left_icon">
                                                                <div className="icon_box">
                                                                    <FontAwesomeIcon icon={faFileInvoice} />
                                                                </div>
                                                            </div>
                                                            <div className="right_text" style={{ cursor: 'pointer' }}>
                                                                <p>Manage Roles</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                :
                                                null
                                        }
                                        {
                                            wardsPermissions ?
                                                <li className={location.pathname === '/wards' ? "active" : ""}>
                                                    <a>
                                                        <div className="text_icon_area" onClick={() => { closeNav(); navigate('/wards') }}>
                                                            <div className="left_icon">
                                                                <div className="icon_box">
                                                                    <FontAwesomeIcon icon={faWarehouse} />
                                                                </div>
                                                            </div>
                                                            <div className="right_text" style={{ cursor: 'pointer' }}>
                                                                <p>Manage Wards</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                :
                                                null

                                        }
                                        {
                                            departmentsPermissions ?
                                                <li className={location.pathname === '/department' ? "active" : ""}>
                                                    <a>
                                                        <div className="text_icon_area" onClick={() => { closeNav(); navigate('/department') }}>
                                                            <div className="left_icon">
                                                                <div className="icon_box">
                                                                    <FontAwesomeIcon icon={faFileInvoice} />
                                                                </div>
                                                            </div>
                                                            <div className="right_text" style={{ cursor: 'pointer' }}>
                                                                <p>Manage Departments</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                :
                                                null
                                        }
                                        {
                                            roomsPermissions ?
                                                <li className={location.pathname === '/room-list' ? "active" : ""}>
                                                    <a>
                                                        <div className="text_icon_area" onClick={() => { closeNav(); navigate('/room-list') }}>
                                                            <div className="left_icon">
                                                                <div className="icon_box">
                                                                    <FontAwesomeIcon icon={faPersonShelter} />
                                                                </div>
                                                            </div>
                                                            <div className="right_text" style={{ cursor: 'pointer' }}>
                                                                <p>Manage Rooms</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                :
                                                null
                                        }
                                        {
                                            bedsPermissions ?
                                                <li className={location.pathname === '/bed-list' ? "active" : ""}>
                                                    <a>
                                                        <div className="text_icon_area" onClick={() => { closeNav(); navigate('/bed-list') }} >
                                                            <div className="left_icon">
                                                                <div className="icon_box">
                                                                    <FontAwesomeIcon icon={faProcedures} />
                                                                </div>
                                                            </div>
                                                            <div className="right_text" style={{ cursor: 'pointer' }}>
                                                                <p>Manage Beds</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                :
                                                null
                                        }
                                        {

                                            <li className={location.pathname === '/change-password' ? "active" : ""}>
                                                <a>
                                                    <div className="text_icon_area" onClick={() => { closeNav(); navigate('/change-password') }} >
                                                        <div className="left_icon">
                                                            <div className="icon_box">
                                                                <FontAwesomeIcon icon={faLock} />
                                                            </div>
                                                        </div>
                                                        <div className="right_text" style={{ cursor: 'pointer' }}>
                                                            <p>Change Password</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>

                                        }
                                    </>
                            }
                            <li><a onClick={() => AuthController.logout()}>
                                <div className="text_icon_area">
                                    <div className="left_icon">
                                        <div className="icon_box">
                                            <FontAwesomeIcon icon={faPowerOff} />
                                        </div>
                                    </div>
                                    <div className="right_text" style={{ cursor: 'pointer' }}>
                                        <p onClick={() => AuthController.logout()}>Log Out</p>
                                    </div>
                                </div>
                            </a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    drawerState: state.DrawerReducer.drawerState,
});
export default connect(mapStateToProps)(DrawerComponent);