import { useState } from "react";
import store from "../../../../redux/store";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import taskController from "../../../../apis/Controllers/TaskController/task.controller";
import { filtersController } from '../../../../apis/Controllers/CommonController/filter.controller'
import { Toaster } from "../../../Helper/toaster/Toaster";
import moment from "moment";
import permissionsController from "../../../../apis/Controllers/PermissionsController/permissions.contoller";


const MyTaskListFunctions = () => {
    var breadcrumData = ["Dashboard"];
    var breadcrumItem = "My Tasks";

    const [currentTab, setCurrentTab] = useState('1');
    const [showCalendarModal, setShowCalendarModal] = useState(false);
    const tabs = [
        {
            id: 1,
            tabTitle: 'All',
        },
        {
            id: 2,
            tabTitle: 'Assigned to me',
        },
        {
            id: 3,
            tabTitle: 'Assigned by me',
        }
    ];

    let year = moment().format('YYYY')
    let month = moment().format('MMM')
    let day = moment().format('DD')
    const defaultValues = {
        day: day ? day : '',
        month: month ? month : '',
        year: year ? year : ''
    }
    const [values, setValues] = useState(defaultValues)
    let storedFilters = store.getState().TaskListFilters.filters;
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [taskListing, setTaskListing] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [deletConfirmationModal, setDeletConfirmationModal] = useState(false);
    const [deletItemDetail, setDeletItemDetail] = useState(null);
    const [filtersAppliedSign, setFiltersAppliedSign] = useState(false);
    const [checkStoredvalueFromRedux, SetCheckStoredValueFromRedux] = useState(null);
    const [loader, setLoader] = useState(false);
    const [years, setYears] = useState([])
    const [months, setMonths] = useState([])
    const [dayMonth, setDayMonth] = useState([])
    const [currentDate, setCurrentDate] = useState(null)
    const [sorting, setSorting] = useState(null)
    const [checkPermissions, setCheckPermissions] = useState(null);

    //-----------------PAZINATION-----------------------------
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    //--------------------------------------------------------
    let navigate = useNavigate();

    // useEffect(() => {
    //     if (storedFilters && checkStoredvalueFromRedux) {
    //         if (
    //             (storedFilters && storedFilters.taskPriorities) ||
    //             (storedFilters && storedFilters.taskStatus) ||
    //             (storedFilters &&
    //                 storedFilters.assignBy &&
    //                 storedFilters.assignBy.length > 0) ||
    //             (storedFilters &&
    //                 storedFilters.wards &&
    //                 storedFilters.wards.length > 0)
    //         ) {
    //             setFiltersAppliedSign(true);
    //             getTaskListing("", page, itemsPerPage);
    //         } else {
    //             setFiltersAppliedSign(false);
    //             getTaskListing("", page, itemsPerPage);
    //         }
    //     }
    // }, [storedFilters, checkStoredvalueFromRedux]);


    useEffect(() => {
        if (count) {
            handlePages(count);
        }
    }, [count, itemsPerPage]);

    useEffect(() => {
        if (values && values.day) {
            if (parseInt(currentTab) === 1) {
                getTaskListingSubAdmin("", 1);
            } else if (parseInt(currentTab) === 2) {
                getTaskListingSubAdmin("", 1, itemsPerPage, 'assign_to_me');
            } else if (parseInt(currentTab) === 3) {
                getTaskListingSubAdmin("", 1, itemsPerPage, 'assign_by_me');
            }
        }
    }, [values && values.day]);

    //<-------------onChange Tab--------

    const handleTabData = (tabId) => {
        if (parseInt(tabId) === 1) {
            getTaskListingSubAdmin("", 1);
        } else if (parseInt(tabId) === 2) {
            getTaskListingSubAdmin("", 1, itemsPerPage, 'assign_to_me');
        } else if (parseInt(tabId) === 3) {
            getTaskListingSubAdmin("", 1, itemsPerPage, 'assign_by_me');
        }
        else return;
    };


    const handlePages = (count) => {
        let array = [];
        const data = parseInt(count);
        const items = parseInt(itemsPerPage);
        const totalPage = Math.ceil(data / items);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i);
        }
        setTotalPages(array);
    };


    const handleChange = (e) => {
        setSearchInput(e.target.value);
        getTaskListingSubAdmin(e.target.value, page, itemsPerPage);
    };

    const getTaskListingSubAdmin = async (e, page, itemPerPage, type, sort) => {
        let month = moment().month(values && values.month).format("MM");
        let defaultDate = moment().format("YYYY-MM-DD");
        let date = `${values.year}` + "-" + `${month}` + "-" + `${values.day}`;
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        let post = {
            date: values && values.day ? date : defaultDate,
            page: page ? page : 1,
            search: e ? e : "",
            limit: itemPerPage ? itemPerPage : 10,
            hospital_id: item && item.id ? item.id : null,   //-------------Static hospital id -------------------->>
            type: type ? type : "",
            sort: sort ? sort : ""

        }
        setLoader(true);
        let response = await taskController.taskListingSubAdmin(post);
        if (response && response.status) {
            setCount(response.count);
            setTotalCount(response.totalCount);
            setTaskListing(response.data);
            setLoader(false);
        } else {
            setLoader(false);
            setTaskListing([]);
        }
    };


    //--Task--filters------------------->

    const handleFilters = (sort) => {
        if (sort) {
            setSorting(sort)
            if (values && values.day) {
                if (parseInt(currentTab) === 1) {
                    getTaskListingSubAdmin("", 1, itemsPerPage, '', sort);
                } else if (parseInt(currentTab) === 2) {
                    getTaskListingSubAdmin("", 1, itemsPerPage, 'assign_to_me', sort);
                } else if (parseInt(currentTab) === 3) {
                    getTaskListingSubAdmin("", 1, itemsPerPage, 'assign_by_me', sort);
                }
            }
        }
    }

    const getModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('task');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckPermissions(data);
        }
        else {
            setCheckPermissions(null);
        }
    };

    // const handleDeleteTask = async () => {
    //     let item = deletItemDetail;
    //     let array = [...taskListing];
    //     let id = item && item.id ? item.id : "";
    //     let response = await taskController.taskDelete(id);
    //     if (response && response.status) {
    //         new Toaster().success(response.message);
    //         let filterData = array.filter((data) => data.id !== item.id);
    //         if (filterData.length > 0) {
    //             getTaskListing("", page, itemsPerPage);
    //         } else {
    //             if (page > 1) {
    //                 let newPage = page - 1;
    //                 setPage(page - 1);
    //                 getTaskListing("", newPage, itemsPerPage);
    //             } else {
    //                 setPage(page);
    //                 getTaskListing("", page, itemsPerPage);
    //             }
    //         }
    //         setTotalPages([]);
    //         setDeletConfirmationModal(false);
    //     } else {
    //         setDeletConfirmationModal(false);
    //     }
    // };


    const handleBackClick = () => {                                      // <---------PAZINATION
        if (page > 1) {
            let newPage = page - 1;
            setPage(page - 1);
            getTaskListingSubAdmin("", newPage, itemsPerPage)
        } else return;
    };

    const handleNextClick = () => {                                      // <---------PAZINATION
        if (page >= 1 && page < totalPages.length) {
            let newPage = page + 1;
            getTaskListingSubAdmin("", newPage, itemsPerPage)
            setPage(page + 1);
        } else return;
    };

    const handlePage = (page) => {                                   //<---------------ON patricular page change
        setPage(page);
        getTaskListingSubAdmin("", page, itemsPerPage)
    };

    const handleItemPerPage = (e) => {
        let value = e.target.value;
        setItemsPerPage(value);
        // getTaskListing("", page, value);
    };

    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/dashboard-superadmin");
            }
        } else if (i === 1) {
            navigate("/bed-list");
        } else return;
    };


    const applyFilters = async (filter) => {
        let filters = {
            assignBy: filter && filter.assignBy ? filter.assignBy : [],
            wards: filter && filter.wards ? filter.wards : [],
            taskPriorities:
                filter && filter.taskPriorities ? filter.taskPriorities : "",
            taskStatus: filter && filter.taskStatus ? filter.taskStatus : "",
        };
        await filtersController.setTaskListFilters(filters);
        setShowFilterModal(false);
        setFiltersAppliedSign(true);
    };


    const handleClearAllFilters = async () => {
        await filtersController.clearTaskListFilters();
    };


    const setReduxValue = (e) => {          //<------------For confirmation that we have checked the localstorage value's and RESET the REDUX- FILTERS's Values and after it the listing api will hit
        SetCheckStoredValueFromRedux(e);
    };


    //---------calender Dropdown Functions------------>

    //-------------generate years------------>
    const generateArrayOfYears = () => {
        var add = 50 / 2;
        var max = new Date().getFullYear()
        var next = max + add;
        var prev = max - add;
        var years = [];
        for (var i = max - 1; i >= prev; i--) {
            years.unshift(i)
        }
        for (var i = max; i <= next; i++) {
            years.push(i)
        }
        setYears(years)
    }

    //------Generate months-------->
    const getMonths = (name = false) => {
        let months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        let month = name ? months[name] : months;
        setMonths(month)
    }

    const handleDate = (field, value) => {
        setValues({ ...values, [field]: value });
    };

    function generateDates() {
        let name = moment().month(values && values.month).format("M");
        let year = values && values.year;
        let month = name;
        var dates = [];
        var currentDate = new Date(year, month - 1);
        var numberOfDays = new Date(year, month, 0).getDate();
        for (var i = 1; i <= numberOfDays; i++) {
            currentDate.setDate(i);
            dates.push(new Date(currentDate));
        }
        let arr = [...dates];
        let newArr = []
        for (let i in arr) {
            let a = arr[i].toString().slice(0, 3);
            let b = arr[i].toString().slice(8, 10);
            let c = {
                day: a,
                date: b
            }
            newArr.push(c);
        }
        setDayMonth(newArr)
        setShowCalendarModal(false)
    }

    const taskCompletedByAdmin = async (item) => {
        setLoader(true);
        let id = item && item.id ? item.id : "";
        let response = await taskController.taskComplete(id);
        if (response && response.status) {
            if (parseInt(currentTab) === 1) {
                getTaskListingSubAdmin("", 1);
            } else if (parseInt(currentTab) === 2) {
                getTaskListingSubAdmin("", 1, itemsPerPage, 'assign_to_me');
            } else if (parseInt(currentTab) === 3) {
                getTaskListingSubAdmin("", 1, itemsPerPage, 'assign_by_me');
            }
            new Toaster().success(response.message);
            setLoader(false);
        } else {
            setLoader(false);
        }
    };

    return {
        breadcrumData,
        breadcrumItem,
        tabs,
        currentTab,
        setCurrentTab,
        showCalendarModal,
        setShowCalendarModal,
        setSearchInput,
        searchInput,
        handleChange,
        taskListing,
        navigate,
        handleBackClick,
        handleNextClick,
        page,
        setPage,
        count,
        totalPages,
        itemsPerPage,
        setItemsPerPage,
        showFilterModal,
        setShowFilterModal,
        handlePage,
        handleItemPerPage,
        loader,
        setLoader,
        deletConfirmationModal,
        setDeletConfirmationModal,
        deletItemDetail,
        setDeletItemDetail,
        applyFilters,
        filtersAppliedSign,
        handleClearAllFilters,
        setReduxValue,
        totalCount,
        setTotalCount,
        generateArrayOfYears,
        years,
        getMonths,
        months,
        values,
        setValues,
        handleDate,
        generateDates,
        dayMonth,
        getTaskListingSubAdmin,
        currentDate,
        handleBreadcrumDataNaviagtion,
        handleTabData,
        handleFilters,
        sorting,
        checkPermissions,
        getModulePermissions,
        taskCompletedByAdmin
    };
};

export default MyTaskListFunctions;
