import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
//-------- HEADER COMPONENT IMPORT ---------//
import WithoutImageHeader from "../../../Helper/withoutImageHeader";
//------------ CONSTANTS IMPORT ------------//
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select';
import 'react-tagsinput/react-tagsinput.css';
import AutocompleteInput from "../../../Helper/autocompleteInput";
import * as Constants from "../../../Helper/constants/constants";
import Loader from "../../../Helper/loader";
import AssignMedicineFunction from "./function";


const AssignMedicine = () => {
    const {
        breadcrumData,
        breadcrumItem,
        isError,
        handleChange,
        handleSubmit,
        loader,
        addMedicineForm,
        removeMedicineForm,
        numMedicine,
        getMedicineFrequencyList,
        medicineFrequenciesList,
        location,
        navigate,
        brandSuggestionsList,
        getBrandSuggestionsList,
        getMedicineSuggestionsList,
        medicineSuggestionsList,
        handleBreadcrumDataNaviagtion,
        setBrandSuggestionsList,
        setMedicineSuggestionsList
    } = AssignMedicineFunction()


    useEffect(() => {
        if (location && location.state && location.state.patientCaseId &&
            location && location.state && location.state.patientId
        ) {
            getMedicineFrequencyList();
            // getBrandSuggestionsList();
            // getMedicineSuggestionsList();
        }
        else {
            navigate(-1);
        }
    }, []);


    return (
        <div className="assign_medicine_section manage_tags manage_select_react">
            <WithoutImageHeader
                header={Constants.ASSIGN_MEDICINE}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
                handleBack={() => navigate(-1)}
            />
            <div className="assign_medicine_area">
                <Container>
                    {
                        loader ?
                            <Loader
                                loader={loader}
                            />
                            :
                            <Row>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className="medicine_main_area">
                                        {numMedicine &&
                                            numMedicine.length > 0 ?
                                            numMedicine.map((item, index) => {
                                                return (
                                                    <div className="form_area" key={index}>{
                                                        index !== 0 ?
                                                            <div className="icon_cross" onClick={() => removeMedicineForm(item)}>
                                                                <a ><FontAwesomeIcon icon={faXmark} /></a>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                        <Form>
                                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                <Form.Group className="form-group">
                                                                    <Form.Label>Brand Name<span>*</span></Form.Label>
                                                                    <AutocompleteInput
                                                                        placeholder={'Select Brand Name Here'}
                                                                        data={brandSuggestionsList}
                                                                        isError={!isError[index].brand_name.isValid
                                                                            ? true
                                                                            : false}
                                                                        value={item.brand_name || ''}
                                                                        onChange={(e) => (
                                                                            (handleChange(index, "brand_name", e),
                                                                                getBrandSuggestionsList(e))

                                                                        )}
                                                                    />
                                                                    {!isError[index].brand_name.isValid ? (
                                                                        <p className="text-danger mt-2">
                                                                            {isError[index].brand_name.message}
                                                                        </p>
                                                                    ) : null}
                                                                </Form.Group>
                                                                <Form.Group className="form-group">
                                                                    <Form.Label>Medicine Name<span>*</span></Form.Label>
                                                                    <AutocompleteInput
                                                                        placeholder={'Select Medicine Name Here'}
                                                                        data={medicineSuggestionsList}
                                                                        isError={!isError[index].medicine_name.isValid
                                                                            ? true
                                                                            : false}
                                                                        value={item.medicine_name || ''}
                                                                        onChange={(e) => (
                                                                            (handleChange(index, "medicine_name", e),
                                                                                getMedicineSuggestionsList(e))
                                                                        )}
                                                                    />
                                                                    {!isError[index].medicine_name.isValid ? (
                                                                        <p className="text-danger mt-2">
                                                                            {isError[index].medicine_name.message}
                                                                        </p>
                                                                    ) : null}
                                                                </Form.Group>
                                                                <Form.Group className="form-group">
                                                                    <Form.Label>Salt<span>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder={'Enter salt here'}
                                                                        value={item.salt || ''}
                                                                        className={!isError[index].salt.isValid
                                                                            ? "error_border"
                                                                            : ""}
                                                                        onChange={(e) => handleChange(index, "salt", e.target.value)}
                                                                    />
                                                                    {!isError[index].salt.isValid ? (
                                                                        <p className="text-danger mt-2">
                                                                            {isError[index].salt.message}
                                                                        </p>
                                                                    ) : null}
                                                                </Form.Group>
                                                            </Col>
                                                            <Row>
                                                                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>Dosage<span>*</span></Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder={'Enter Dosage here'}
                                                                            value={item.dosage || ''}
                                                                            className={!isError[index].dosage.isValid
                                                                                ? "error_border"
                                                                                : ""}
                                                                            onChange={(e) => handleChange(index, "dosage", e.target.value)}
                                                                        />
                                                                        {!isError[index].dosage.isValid ? (
                                                                            <p className="text-danger mt-2">
                                                                                {isError[index].dosage.message}
                                                                            </p>
                                                                        ) : null}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>Route<span>*</span></Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder={'Enter route here'}
                                                                            value={item.route || ''}
                                                                            className={!isError[index].route.isValid
                                                                                ? "error_border"
                                                                                : ""}
                                                                            onChange={(e) => handleChange(index, "route", e.target.value)}
                                                                        />
                                                                        {!isError[index].route.isValid ? (
                                                                            <p className="text-danger mt-2">
                                                                                {isError[index].route.message}
                                                                            </p>
                                                                        ) : null}
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <div className="dropdown_box">
                                                                <h6>Frequency<span>*</span></h6>
                                                                <div className="dropdown_area">
                                                                    <Select
                                                                        isMulti
                                                                        isSearchable
                                                                        options={medicineFrequenciesList}
                                                                        value={item.frequency}
                                                                        className={!isError[index].frequency.isValid
                                                                            ? "error_border"
                                                                            : ""}
                                                                        onChange={(item) => handleChange(index, "frequency", item)}
                                                                    />
                                                                    {!isError[index].frequency.isValid ? (
                                                                        <p className="text-danger mt-2">
                                                                            {isError[index].frequency.message}
                                                                        </p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                )
                                            })
                                            : null}
                                        <div className="more_btn_area" onClick={() => addMedicineForm()}>
                                            <a className="btn btn-primary" >
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                    className="plus_icon" />Add More</a>
                                        </div>
                                        <div className="btn_area">
                                            <Button
                                                className="cancelBtn"
                                                type="button"
                                                onClick={() => navigate(-1)}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="submitBtn"
                                                type="submit"
                                                onClick={() => handleSubmit()}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                    }
                </Container>
            </div>
        </div>
    );
};

export default AssignMedicine;
