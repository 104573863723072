import { useEffect, useRef, useState } from "react";
import patientController from "../../../../../apis/Controllers/PatientController/patient.controller";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import Validation from "../../../../Helper/validations/vaildation";
import { useLocation, useNavigate } from "react-router-dom";
import { actionsController } from "../../../../../apis/Controllers/CommonController/action.controller";
import store from "../../../../../redux/store";
import moment from "moment";



const EditPatientFunctions = () => {
    const breadcrumData = ['Dashboard', 'Patient List'];
    const breadcrumItem = ['Edit Profile'];
    const [profileModal, setProfileModal] = useState(false);

    const defaultValues = {
        hospitalId: null,
        image: null,
        patientName: null,
        fatherName: null,
        motherName: null,
        dob: null,
        age: null,
        dateOfAdmission: null,
        gender: null,
        bloodGroup: null,
        maritalStatus: null,
        occupation: null,
        nationlity: null,
        address: null,
        area: null,
        pincode: null,
        districtData: null,
        stateData: null,
        cityData: null,
        contactNumber: null,
        alternativeNumber: null,
        email: null,
        adhaarCard: null
    };

    const defaultErrors = {
        image: {
            rules: [""],
            isValid: true,
            message: "",
        },
        hospitalId: {
            rules: [""],
            isValid: true,
            message: "",
        },
        patientName: {
            rules: ["required", "alphabetic"],
            isValid: true,
            message: "",
        },
        fatherName: {
            rules: ["required", "alphabetic"],
            isValid: true,
            message: "",
        },
        motherName: {
            rules: [],
            isValid: true,
            message: "",
        },
        dob: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        gender: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        bloodGroup: {
            rules: [],
            isValid: true,
            message: "",
        },
        maritalStatus: {
            rules: ["required",],
            isValid: true,
            message: "",
        },
        occupation: {
            rules: [],
            isValid: true,
            message: "",
        },
        // dob: {
        //     rules: ["required"],
        //     isValid: true,
        //     message: "",
        // },
        gender: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        bloodGroup: {
            rules: [],
            isValid: true,
            message: "",
        },
        age: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        dateOfAdmission: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        occupation: {
            rules: [""],
            isValid: true,
            message: "",
        },
        nationlity: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        address: {
            rules: [""],
            isValid: true,
            message: "",
        },
        area: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        pincode: {
            rules: [],
            isValid: true,
            message: "",
        },
        districtData: {
            rules: [],
            isValid: true,
            message: "",
        },
        stateData: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        cityData: {
            rules: [],
            isValid: true,
            message: "",
        },
        contactNumber: {
            rules: ["required", "min:8", "max:13"],
            isValid: true,
            message: "",
        },
        alternativeNumber: {
            rules: [],
            isValid: true,
            message: "",
        },
        email: {
            rules: [""],
            isValid: true,
            message: "",
        },
        adhaarCard: {
            rules: [],
            isValid: true,
            message: "",
        },
    };

    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);
    const [loader, setLoader] = useState(false);
    const [patientDetail, setPatientDetail] = useState(null);
    const [bloodGroupsList, setBloodGroupsList] = useState([]);
    const [martialStatusList, setMartialStatusList] = useState([]);
    const [gendersList, setGendersList] = useState([]);
    const [nationalitiesList, setNationalitiesList] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [districtsList, setDistrictsList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);
    const [age, setAge] = useState(null)
    const loaction = useLocation();
    const navigate = useNavigate();


    const handleChange = (field, value) => {
        let err = { ...isError }
        if (field === 'email') {
            if (value.length > 0) {
                err = {
                    ...isError,
                    email: {
                        rules: ['email'],
                        isValid: true,
                        message: '',
                    },
                };
            }
            else {
                err = {
                    ...isError,
                    email: {
                        rules: [''],
                        isValid: true,
                        message: '',
                    },
                };
            }
        }
        else if (field === 'alternativeNumber') {
            if (value.length > 0) {
                err = {
                    ...isError,
                    alternativeNumber: {
                        rules: ["max:13", "min:8"],
                        isValid: true,
                        message: '',
                    },
                };
            }
            else {
                err = {
                    ...isError,
                    alternativeNumber: {
                        rules: [''],
                        isValid: true,
                        message: '',
                    },
                };
            }
        }
        else if (field === 'motherName') {
            if (value.length > 0) {
                err = {
                    ...isError,
                    motherName: {
                        rules: ["alphabetic"],
                        isValid: true,
                        message: '',
                    },
                };
            }
            else {
                err = {
                    ...isError,
                    motherName: {
                        rules: [''],
                        isValid: true,
                        message: '',
                    },
                };
            }
        }
        else if (field === 'occupation') {
            if (value.length > 0) {
                err = {
                    ...isError,
                    occupation: {
                        rules: ["alphabetic"],
                        isValid: true,
                        message: '',
                    },
                };
            }
            else {
                err = {
                    ...isError,
                    occupation: {
                        rules: [''],
                        isValid: true,
                        message: '',
                    },
                };
            }
        }
        else if (field === 'adhaarCard') {
            if (value.length > 0) {
                err = {
                    ...isError,
                    adhaarCard: {
                        rules: ["adharWith4DigitsMessage"],
                        isValid: true,
                        message: '',
                    },
                };
            }
            else {
                err = {
                    ...isError,
                    adhaarCard: {
                        rules: [''],
                        isValid: true,
                        message: '',
                    },
                };
            }
        }
        else {
            err = { ...isError }
        }
        let validation = new Validation(err);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
        if (field === 'stateData') {
            setValues({
                ...values,
                stateData: value,
                cityData: null,
                districtData: null,
            });
        }
        // else if (field === 'nationlity') {
        //     setValues({
        //         ...values,
        //         nationlity: value,
        //         stateData: null,
        //         cityData: null,
        //         districtData: null,
        //     });
        // }
    };



    const handleSubmit = async () => {
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            setLoader(true);
            let patientId = patientDetail && patientDetail.id ? patientDetail.id : ''
            let response = await patientController.updatePatientDetail(patientId, values);
            if (response && response.status) {
                new Toaster().success(response.message);
                navigate(-1, { replace: true });
                setLoader(false);
            } else {
                setLoader(false);
            }
        } else {
            setError({ ...isValid.errors });
        }
    };



    const getPatinetDetail = async (id) => {
        setLoader(true);
        let response = await patientController.patientDetail(id);
        if (response && response.status) {
            let data = response.data
            setPatientDetail(data);
            // getmonthyears(data && data.dob ? data.dob : null);
            setValues({
                ...values,
                hospitalId: data && data.hospitals && data.hospitals.length > 0 ? { label: data.hospitals[0].title, id: data.hospitals[0].id } : null,
                image: data && data.image ? data.image : null,
                patientName: data && data.patient_name ? data.patient_name : null,
                fatherName: data && data.father_name ? data.father_name : null,
                motherName: data && data.mother_name ? data.mother_name : null,
                dob: data && data.dob ? data.dob : null,
                age: getmonthyears(data && data.dob ? data.dob : null),
                gender: data && data.gender && data.gender_id ? { label: data.gender, id: data.gender_id } : null,
                bloodGroup: data && data.blood_group && data.blood_group_id ? { label: data.blood_group, id: data.blood_group_id } : null,
                maritalStatus: data && data.marital_status && data.marital_status_id ? { label: data.marital_status, id: data.marital_status_id } : null,
                occupation: data && data.occupation ? data.occupation : null,
                nationlity: data && data.nationlity_name && data.nationlity_id ? { label: data.nationlity_name, id: data.nationlity_id } : null,
                address: data && data.address ? data.address : null,
                area: data && data.area ? data.area : null,
                pincode: data && data.pincode ? data.pincode : null,
                districtData: data && data.district_name && data.district_id ? { label: data.district_name, id: data.district_id } : null,
                stateData: data && data.state_name && data.state_id ? { label: data.state_name, id: data.state_id } : null,
                cityData: data && data.city_name && data.city_id ? { label: data.city_name, id: data.city_id } : null,
                contactNumber: data && data.contact_number ? data.contact_number : null,
                alternativeNumber: data && data.alternative_number ? data.alternative_number : null,
                email: data && data.email ? data.email : null,
                adhaarCard: data && data.adhaar_card ? data.adhaar_card : null,
                dateOfAdmission: data && data.date_of_admission ? data.date_of_admission : null
            })
            getStatesListing(data && data.nationlity_id ? data.nationlity_id : '');
            getDistrictsListing(data && data.state_id ? data.state_id : '');
            getCitiesListing(data && data.state_id ? data.state_id : '');
            getGendersListing();
            getBoodGroupListing();
            getMartialStatusListing();
            setLoader(false);
        }
        else {
            setValues(defaultValues);
            setLoader(false);
        }
    };

    //------------Convert full age from date of birth------------>
    const getmonthyears = (value) => {
        let dt = value;
        let age = '';
        let bY = Number(moment(dt).format('YYYY'));
        let bM = Number(moment(dt).format('MM'));
        let bD = Number(moment(dt).format('DD'));
        let tY = Number(moment().format('YYYY'));
        let tM = Number(moment().format('MM'));
        let tD = Number(moment().format('DD'));
        age += (tY - bY) + "  ";
        if (tM < bM) {
            age += (tM - bM + 12) + "  ";
            tY = tY - 1;
        } else {
            age += (tM - bM) + "  ";
        } if (tD < bD) {
            age += (tD - bD + 30);
            tM = tM - 1;
        } else {
            age += (tD - bD);
        }
        let years = age.toString().slice(0, 2)
        let months = age.toString().slice(2, 6)
        let days = age.toString().slice(6, 10)
        let data = {
            years: years && parseInt(years) > 0 ? years : null,
            months: months && parseInt(months) > 0 ? months : null,
            days: days && parseInt(days) > 0 ? days : null
        }
        let newAge = null;
        if (data.days) {
            newAge = `${data.days} Days`
        }
        if (data.years) {
            newAge = `${data.years} Years `
        }
        if (data.years && data.months) {
            newAge = `${data.years} Years, ${data.months} Months`
        }
        if (data.years && data.days) {
            newAge = `${data.years} Years, ${data.days} Days`
        }
        if (data.months && data.days) {
            newAge = `${data.months}Months, ${data.days} Days`
        }
        if (data.years && data.months && data.days) {
            newAge = `${data.years} Years, ${data.months} Months, ${data.days} Days`
        } if (values.dob === moment().format('YYYY-MM-DD')) {
            newAge = `1 Day`
        }
        return newAge;
    }


    const getBoodGroupListing = async () => {
        let response = await actionsController.bloodGroups();
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setBloodGroupsList(data);
        }
        else {
            setBloodGroupsList([]);
        }
    };


    const getMartialStatusListing = async () => {
        let response = await actionsController.martialStatusList();
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setMartialStatusList(data);
        }
        else {
            setMartialStatusList([]);
        }
    };


    const getGendersListing = async () => {
        let response = await actionsController.gendersList();
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setGendersList(data);
        }
        else {
            setGendersList([]);
        }
    };


    const getNationalitiesListing = async () => {
        let response = await actionsController.nationalities();
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setNationalitiesList(data);
        }
        else {
            setNationalitiesList([]);
        }
    };


    const getStatesListing = async (id) => {
        let post = {
            country_id: id ? id : ''
        }
        let response = await actionsController.statesList(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setStatesList(data);
        }
        else {
            setStatesList([]);
        }
    };


    const getDistrictsListing = async (id) => {
        let post = {
            state_id: id ? id : ''
        }
        let response = await actionsController.districtsList(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setDistrictsList(response.data);
        }
        else {
            setDistrictsList([]);
        }
    };


    const getCitiesListing = async (id) => {
        let post = {
            state_id: id ? id : ''
        }
        let response = await actionsController.citiesList(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setCitiesList(response.data);
        }
        else {
            setCitiesList([]);
        }
    };


    const handleDistrictAndCityDropDown = () => {
        if ((values && values.stepTwo) && (values.stepTwo.stateData === null)) {
            new Toaster().error('Please select State');
        }
        else return;
    };



    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate('/dashboard');
            }
            else {
                navigate('/dashboard-superadmin');
            }
        }
        else if (i === 1) {
            navigate('/manage-patient-list');
        }
        else return;
    };

    return {
        breadcrumData,
        breadcrumItem,
        values,
        setValues,
        isError,
        loaction,
        loader,
        handleChange,
        navigate,
        getBoodGroupListing,
        getMartialStatusListing,
        getNationalitiesListing,
        getStatesListing,
        getGendersListing,
        getDistrictsListing,
        getCitiesListing,
        bloodGroupsList,
        martialStatusList,
        nationalitiesList,
        gendersList,
        statesList,
        districtsList,
        citiesList,
        handleDistrictAndCityDropDown,
        handleBreadcrumDataNaviagtion,
        handleSubmit,
        profileModal,
        setProfileModal,
        getPatinetDetail,
        patientDetail,
        getmonthyears
    }
};

export default EditPatientFunctions;