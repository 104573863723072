import React, { useState } from "react";
import wardController from "../../../../apis/Controllers/WardController/ward.controller";
import hospitalController from "../../../../apis/Controllers/HospitalController/hospital.controller";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "../../../Helper/General";
import permissionsController from "../../../../apis/Controllers/PermissionsController/permissions.contoller";
import { Toaster } from "../../../Helper/toaster/Toaster";
import store from "../../../../redux/store";
import { setDrawer } from "../../../../redux/actions/users";


const AdminDashbordFunctons = () => {
    var breadcrumData = ["Dashboard"];
    var breadcrumItem = "Ward";
    const [wardList, setWardList] = useState([]);
    const [hospitalList, setHospitalList] = useState([]);
    const [dropdownValue, setDropdownValue] = useState(null);
    const [hospitalId, setHospitalId] = useState(null);
    const [loader, setLoader] = useState(false);
    const [hospitalTitle, setHospitalTitle] = useState(null)
    const [checkPatientModulePermissions, setCheckPatientModulePermissions] = useState(null);
    const [checExistkPatientsPermissions, setCheckExistkPatientsPermissions] = useState(null);
    let navigate = useNavigate();


    const getModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('user');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckPatientModulePermissions(data);
        }
        else {
            setCheckPatientModulePermissions(null);
        }
    };


    const getExistPatinetsPermissions = async () => {
        let response = await permissionsController.permissionsDetails('existing_patient');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckExistkPatientsPermissions(data);
        }
        else {
            setCheckExistkPatientsPermissions(null);
        }
    };


    const getWardsList = async (item) => {
        if (!loader) {
            let body = {
                hospital_id: item ? [item.id] : []
            }
            setLoader(true);
            let response = await wardController.wardListing(body);
            if (response && response.status) {
                setWardList(response.data)
                setLoader(false);
            }
            else {
                setWardList([]);
                setLoader(false);
            }
            setLoader(false);
        }
    };


    const getHospitalList = async () => {
        let response = await hospitalController.hospitalListDashboard();
        if (response && response.status) {
            let data = localStorage.getItem('SET_HOSPITAL');
            setHospitalTitle(JSON.parse(data))
            setHospitalList(response.data)
            if (isEmpty(data)) {
                getWardsList(response.data && response.data[0]);
                await hospitalController.setUpHospital(response.data && response.data[0]);
            } else {
                let item = JSON.parse(data);
                getWardsList(item);
            }
        }
        else {
            setHospitalList([]);
        }
    };

    const handleNavigate = (data) => {
        if (checkPatientModulePermissions
            && checkPatientModulePermissions.permissions
            && checkPatientModulePermissions.permissions.length > 0
            && parseInt(checkPatientModulePermissions.permissions[0].value) === 1) {
            navigate('/patient-list', { state: { item: data } })
        }
        else {
            new Toaster().error('Sorry! You do not have access to view Patients.');
        }
    };

    const handleDrawer = async (e) => {
        await store.dispatch(setDrawer(false));
    };

    return {
        getModulePermissions,
        checkPatientModulePermissions,
        getExistPatinetsPermissions,
        checExistkPatientsPermissions,
        wardList,
        getWardsList,
        getHospitalList,
        hospitalList,
        setDropdownValue,
        dropdownValue,
        navigate,
        hospitalId,
        setHospitalId,
        loader,
        setLoader,
        breadcrumData,
        breadcrumItem,
        handleNavigate,
        hospitalTitle,
        setHospitalTitle,
        handleDrawer
    }
};

export default AdminDashbordFunctons;