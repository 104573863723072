import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function taskListing (params) {
    return mainWrapper.get(WebConstant.host + "/task_list", params);
};


function taskCreate (params) {
    return mainWrapper.post(WebConstant.host + `/create_task`, params);
};


function taskDetail (id) {
    return mainWrapper.get(WebConstant.host + `/task_detail/${id}`);
};


function taskUpdate (params, id) {
    return mainWrapper.put(WebConstant.host + `/update_task/${id}`, params);
};


function taskDelete (id) {
    return mainWrapper._delete(WebConstant.host + `/delete_task/${id}`);
};


function taskComplete (id) {
    return mainWrapper.put(WebConstant.host + `/complete_task/${id}`);
};

function taskListingPatientDetails (params) {
    return mainWrapper.get(WebConstant.host + "/task_list_patient_details", params);
};

function taskListingSubAdmin(params) {
    return mainWrapper.get(WebConstant.host + "/task_list_doctor", params);
}

export const taskServices = {
    taskListing,
    taskCreate,
    taskDetail,
    taskUpdate,
    taskDelete,
    taskComplete,
    taskListingPatientDetails,
    taskListingSubAdmin
};
