import { Toaster } from "../../../components/Helper/toaster/Toaster";
import { setHospital, setUserData, setUserPermissions } from "../../../redux/actions/users";
import store from "../../../redux/store";
import AuthServices from "../../Services/AuthServices/AuthServices";


const loginDetails = async (data) => {
    let post = {
        email: data.email,
        password: data.password,
        device_id: null,
        device_type: null,
        device_name: null,
        fcm_token: null,
    };
    let response = await AuthServices.postLoginDetail(post);
    if (response && response.status) {
        setUpLogin(response.user);
        return response;
    } else {
        new Toaster().error(response.error);
    }
};

const setUpLogin = async (user) => {
    await localStorage.setItem('SET_USER_DATA', JSON.stringify(user));
    await store.dispatch(setUserData(user));
    return user;
};


const setUserPermission = async (user) => {
    await localStorage.setItem('SET_USER_PERMISSION', JSON.stringify(user));
    await store.dispatch(setUserPermissions(user));
};


const getLoginUser = async () => {
    let item = localStorage.getItem('SET_USER_DATA');
    item = JSON.parse(item);
    let user = item;
    if (user) {
        store.dispatch(setUserData(user));
        return user && user !== null && user.id ? user : null;
    } else {
        return null;
    }
};


const logout = async () => {
    store.dispatch(setUserData({}));
    store.dispatch(setHospital({
        hospital: {},
    }));
    localStorage.clear();
    window.location.href = '/login';
};


const forgotDetails = async (data) => {
    let post = {
        email: data.email,
    };
    let response = await AuthServices.postForgotDetail(post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
    }
};

const changePasswordDetails = async (data) => {
    let post = {
        old_password: data.password,
        new_password: data.newPassword,
        confirm_password: data.newPassword,
    };
    let response = await AuthServices.changePassword(post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.message);
        return null;
    }
};


const resetPasswordDetails = async (data, state) => {
    let post = {
        otp: state.otp,
        token: state.token,
        new_password: data.newPassword,
        confirm_password: data.confirmPassword,
    };
    let response = await AuthServices.postResetPassword(post);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const otpVerifiication = async (otp, token) => {
    let post = {
        "otp": otp,
        "token": token ? token : ''
    };
    let response = await AuthServices.otpVerifiication(post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const AuthController = {
    loginDetails,
    forgotDetails,
    changePasswordDetails,
    resetPasswordDetails,
    setUpLogin,
    getLoginUser,
    logout,
    otpVerifiication,
    setUserPermission
};

export default AuthController;
