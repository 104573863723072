import { faCalendarDays, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";
import NoDataComponent from "../../CommanComponents/NoDataComponent";
import { BedListFiltersFunctions } from "./function";


const BedListFilterModal = (props) => {

    const {
        wardslist,
        getWardsListing,
        getHospitalListing,
        hospitalsList,
        handleWardsFilters,
        handleHospitalsFilters,
        applyFilters,
        handleClearAllFilters,
        handleWardsSearch,
        wardsSearch,
        handleHospitalsSearch,
        hospitalsSearch,
        getRoomsListing,
        roomList,
        roomsSearch,
        handleRoomsSearch,
        handleRoomsFilters,
        SortByList,
        handleSortByFilters
    } = BedListFiltersFunctions(props);

    useEffect(() => {
        getWardsListing('');
        getHospitalListing('')
        getRoomsListing('')
    }, []);


    return (
        <div>
            <Modal show={props.show} onHide={props.close} centered className="filter_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="filter_details">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Hospital</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Ward</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Room</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="fourth">Sort by</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                                    <div className="content_area">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <Form className="ward_form">
                                                    <Form.Group className="form-group">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter hospital name here"
                                                            value={hospitalsSearch}
                                                            onChange={handleHospitalsSearch}
                                                        />
                                                        <a >
                                                            <div className="icon">
                                                                <FontAwesomeIcon icon={faSearch} />
                                                            </div>
                                                        </a>
                                                    </Form.Group>
                                                    <div className="check_box_slider">
                                                        {
                                                            hospitalsList && hospitalsList.length > 0 ?
                                                                hospitalsList.map((item, i) => (
                                                                    <Form.Check
                                                                        key={i}
                                                                        type="checkbox"
                                                                        id={item.id}
                                                                        label={item.title}
                                                                        checked={item.checked}
                                                                        onChange={() => handleHospitalsFilters(item)}
                                                                    />
                                                                ))
                                                                :
                                                                <NoDataComponent
                                                                    message="No data Found!"
                                                                />
                                                        }
                                                    </div>
                                                </Form>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <div className="search_details">
                                                    <Form className="ward_form">
                                                        <Form.Group className="form-group">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Enter ward name here"
                                                                value={wardsSearch}
                                                                onChange={handleWardsSearch}
                                                            />
                                                            <a >
                                                                <div className="icon">
                                                                    <FontAwesomeIcon icon={faSearch} />
                                                                </div>
                                                            </a>
                                                        </Form.Group>
                                                        <div className="check_box_slider">
                                                            {
                                                                wardslist && wardslist.length > 0 ?
                                                                    wardslist.map((item, i) => (
                                                                        <Form.Check
                                                                            key={i}
                                                                            type="checkbox"
                                                                            id={item.id}
                                                                            label={(<>{item.title} <b>{item.hospital_name ? ` (${item.hospital_name})` : ''}</b></>)}
                                                                            checked={item.checked}
                                                                            onChange={() => handleWardsFilters(item)}
                                                                        />
                                                                    ))
                                                                    :
                                                                    <NoDataComponent
                                                                        message="No Ward Found!"
                                                                    />
                                                            }
                                                        </div>
                                                    </Form>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <div className="search_details">
                                                    <Form className="ward_form">
                                                        <Form.Group className="form-group">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Enter room number here"
                                                                value={roomsSearch}
                                                                onChange={handleRoomsSearch}
                                                            />
                                                            <a >
                                                                <div className="icon">
                                                                    <FontAwesomeIcon icon={faSearch} />
                                                                </div>
                                                            </a>
                                                        </Form.Group>
                                                        <div className="check_box_slider">
                                                            {
                                                                roomList && roomList.length > 0 ?
                                                                    roomList.map((item, i) => (
                                                                        <Form.Check
                                                                            key={i}
                                                                            type="checkbox"
                                                                            id={item.id}
                                                                            label={(<div style={{ textTransform: 'capitalize' }}>{item.room_number} <b>{item.ward_name ? `(${item.ward_name})` : ''}</b> <b>{item.hospital_name ? ` (${item.hospital_name})` : ''}</b></div>)}
                                                                            checked={item.checked}
                                                                            onChange={() => handleRoomsFilters(item)}
                                                                        />
                                                                    ))
                                                                    :
                                                                    <NoDataComponent
                                                                        message="No Room Found!"
                                                                    />
                                                            }
                                                        </div>
                                                    </Form>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="fourth">
                                                <Form className="ward_form">
                                                    <div className="check_box_slider">
                                                        {
                                                            SortByList.map((item, i) => (
                                                                <Form.Check
                                                                    key={i}
                                                                    type="checkbox"
                                                                    id={item.id}
                                                                    label={item.title}
                                                                    checked={item.checked}
                                                                    onChange={() => handleSortByFilters(item)}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                </Form>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </Col>
                            </Row>
                        </Tab.Container>
                        <div className="btn_area">
                            <Button className="cancelBtn" type="button"
                                onClick={() => handleClearAllFilters()}
                            >Clear All</Button>
                            <Button className="submitBtn" type="submit"
                                onClick={() => applyFilters()}
                            >
                                Apply</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default BedListFilterModal;