import WebConstant from "../../webConstants";
import { mainWrapper } from "./../../main";


const AuthServices = {
    postLoginDetail,
    postForgotDetail,
    postResetPassword,
    otpVerifiication,
    changePassword
};


function postLoginDetail(params) {
    return mainWrapper.post(WebConstant.host + "/admin/login", params);
}


function postForgotDetail(params) {
    return mainWrapper.post(
        WebConstant.host + "/admin/forgot-password",
        params
    );
}


function postResetPassword(params) {
    return mainWrapper.post(WebConstant.host + "/admin/reset-password", params);
}


function otpVerifiication(params) {
    return mainWrapper.post(WebConstant.host + "/admin/validate-otp", params);
}

function changePassword(params) {
    return mainWrapper.put(WebConstant.host + "/change_password", params);
}

export default AuthServices;
