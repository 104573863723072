import React, { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import UploadFileComponent from "../../../Helper/UploadFile";
import { EditReportsModalFunctions } from "./functions";

const EditReportModal = (props) => {
    const {
        values,
        isError,
        loader,
        handleChange,
        handleSubmit,
        Reportdetails
    } = EditReportsModalFunctions(props);

    useEffect(() => {
        Reportdetails(props && props.reportsdata && props.reportsdata.id ? props.reportsdata.id : null);
    }, []);

    return (
        <div>
            <Modal show={props.show} onHide={props.close} centered className="add_report_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="report_details_form">
                        <Form>
                            <Form.Group className="form_group" controlId="reportName">
                                <Form.Label>Report Name<span>*</span></Form.Label>
                                <Form.Control
                                    type="reportName"
                                    placeholder="Enter report name here"
                                    value={values.reportName}
                                    className={isError.reportName.message
                                        ? "error_border"
                                        : ""}
                                    onChange={(e) =>
                                        handleChange("reportName", e.target.value)
                                    }
                                />
                                {isError.reportName.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.reportName.message}
                                    </p>
                                ) : null}
                            </Form.Group>
                            <Form.Group className="form_group" controlId="description">
                                <Form.Label>Description<span>*</span></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Enter report description here"
                                    rows={4}
                                    value={values.discription}
                                    className={isError.discription.message
                                        ? "error_border"
                                        : ""}
                                    onChange={(e) =>
                                        handleChange("discription", e.target.value)
                                    }
                                />
                                {isError.discription.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.discription.message}
                                    </p>
                                ) : null}
                            </Form.Group>

                            <UploadFileComponent
                                name={``}
                                image={values.upload}
                                onResponse={(response) => {
                                    handleChange("upload", response.path)
                                }}
                                removeImage={async () => {
                                    //  await removeImage();
                                }}
                                isError={isError && isError.upload}
                            />
                            {isError.upload.message ? (
                                <p className="text-danger mt-2">
                                    {isError.upload.message}
                                </p>
                            ) : null}

                            <div className="btn_area">
                                <Button
                                    className="cancelBtn"
                                    type="button"
                                    onClick={props.close} >
                                    Cancel
                                </Button>
                                <Button
                                    className="submitBtn"
                                    type="submit"
                                    onClick={(e) => (e.preventDefault(), handleSubmit())}
                                >
                                    Apply
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditReportModal;