import React from 'react';
import { Image } from 'react-bootstrap';
import { AssignImg, Man } from '../../../../assets/images';
import { renderImage } from '../../../Helper/General';
import NoDataComponent from '../../CommanComponents/NoDataComponent';

const PatientList = (props) => {
    const {
        List,
        keyword,
        navigate
    } = props


    return (
        <>
            {
                List && List.length > 0 ?
                    <div className="nurse_list_screen">
                        {
                            List.map((item, i) => (
                                <div className="nurse_details active" key={i}>
                                    <div className="left_area">
                                        <div className="img_one">
                                            <Image
                                                src={item && item.image ? renderImage(item.image) : Man}
                                                alt="..."
                                                onClick={() => navigate(`/patientDetails/${item.id}`, { state: { name: item.patient_name, fromNavigation: true } })}
                                            />
                                        </div>
                                    </div>
                                    <div className="right_area" style={{ cursor: 'pointer' }}>
                                        {
                                            item.patient_name ?
                                                <h3
                                                    onClick={() => navigate(`/patientDetails/${item.id}`, { state: { name: item.patient_name, fromNavigation: true } })}
                                                >{item.patient_name}</h3>
                                                :
                                                null
                                        }
                                        {
                                            item.uh_id ?
                                                <div className="tag">
                                                    <p>{item.uh_id}</p>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>

                            ))
                        }
                    </div>
                    :
                    keyword ?
                        <NoDataComponent
                            message="No Patient Found!"
                        />
                        :
                        null
            }
        </>
    )
}
export default PatientList;