import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Form, ProgressBar } from "react-bootstrap";
import fileUploadController from "../../apis/Controllers/ImageController/file.upload";
import WebConstant from "../../apis/webConstants";

function UploadFileComponent(props) {
    const inputFile = useRef(null);
    const [isLoading, setLoader] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isError, setIsError] = useState(null);
    const [fileName, setFileName] = useState(null);

    useEffect(() => {
        setFileName(null);
    }, []);

    const handleImage = async (value) => {
        if (
            [
                "image/jpeg",
                "image/png",
                "image/gif",
                "image/webp",
                "application/pdf",
                "application/msword",
                "application/vnd.ms-excel",
                "application/vnd.ms-powerpoint",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ].includes(value.type)
        ) {
            if (value.size < WebConstant.maxFileSize) {
                setIsError(null);
                if (isLoading === false) {
                    setLoader(true);
                    setProgress(0.5);
                    let callback = (p) => {
                        // setProgress(p > 100 ? p * 1 - 5 : p);
                        setProgress(p > 100 ? 100 : p);
                    };

                    let response = await fileUploadController.uploadFile(
                        value,
                        callback
                    );
                    setProgress(100);
                    setFileName(value.name);
                    setLoader(false);
                    if (response && response.status) {
                        setProgress(0);
                        props.onResponse(response);
                    } else {
                        setIsError(response.message);
                    }
                }
            } else {
                setIsError("File size exceeds the maximum limit of 5MB.");
            }
        } else {
            setIsError(
                "Please upload file having extension jpg, jpeg, png, gif, webp xlsx, xls , doc, docx, ppt, pptx, txt or pdf"
            );
        }
    };


    return (
        <>
            <Form>
                <Form.Group className="form_group" controlId="upload">
                    <Form.Label>Upload</Form.Label>
                    <div className="input_icon" onClick={() => inputFile.current.click()}>
                        <Form.Control
                            disabled
                            ref={inputFile}
                            type="text"
                            placeholder="Upload File"
                            value={props.image ? props.image : fileName}
                            className={props && props.isError && props.isError.message
                                ? "error_border upload_file"
                                : "upload_file"}
                        />
                        <FontAwesomeIcon
                            icon={faPencil}
                            className="date_icon"
                            onClick={() => inputFile.current.click()}
                        />
                        {/* {progress !== 0 ? (
                            <div class="progress">
                                <div
                                    class="progress-bar"
                                    style={{ width: progress ? progress + "%" : "0" }}
                                ></div>
                            </div>
                        ) : null} */}
                    </div>
                </Form.Group>
                <Form.Group className="form_group" controlId="upload">
                    <div className="input_icon">
                        <Form.Control
                            style={{ display: "none" }}
                            ref={inputFile}
                            type="file"
                            placeholder="Upload"
                            accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                            className={props && props.isError && props.isError.message
                                ? "error_border right_icon_upload"
                                : "right_icon_upload"}
                            onChange={(event) => {
                                handleImage(event.target.files[0]);
                                event.target.value = "";
                            }}
                        />
                    </div>
                </Form.Group>
            </Form>
            {isLoading && progress !== 0 ? (
                <ProgressBar
                    now={progress}
                    variant="danger"
                    label={`${progress}%`}
                    max={100}
                    animated
                />
            ) : null}
            <small className="text-danger">{isError ? isError : ``}</small>
        </>
    );
}
export default UploadFileComponent;
