import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AuthController from "../../apis/Controllers/AuthController/AuthController";


function Header() {

    // useEffect(() => {
    //     getUserData();
    // }, []);


    // const getUserData = async () => {
    //     window.scrollTo(0, 0);
    //     let item = localStorage.getItem('SET_USER_DATA');
    //     item = JSON.parse(item);
    //     let user = item;
    //     if (user) {
    //         if (user && user.access && user.access.token) {
    //             await store.dispatch(setUserData(user));
    //         }
    //     }
    // };


    return (
        <div>
            <div className="header_section">
                <Container>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div className="left_area">
                                Header
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Header;
