import { useState } from "react";
import permissionsController from "../../../../../apis/Controllers/PermissionsController/permissions.contoller";
import Validation from "../../../../Helper/validations/vaildation";
import rolesController from "../../../../../apis/Controllers/RolesController/roles.controller";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../../redux/store";
import { all } from "axios";
import roomsController from "../../../../../apis/Controllers/RoomsController/rooms.controller";
import bedsController from "../../../../../apis/Controllers/BedsControoler/beds.controller";
import { useEffect } from "react";
import { filtersController } from "../../../../../apis/Controllers/CommonController/filter.controller";

const BedListFunction = () => {
    let storedFilters = store.getState().BedListFilters.filters;
    var breadcrumData = ["Dashboard"];
    const [bedList, setBedList] = useState([])
    const [loader, setLoader] = useState(false);
    const [breadcrumItem, setbreadcrumItem] = useState("Bed List");
    const [deletePopup, setDeletePopup] = useState(false);
    const [checkPermissions, setCheckPermissions] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const loaction = useLocation();
    const navigate = useNavigate();
    const [sorting, setSorting] = useState(null);
    // -------------------PAZINATION ----------------------
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState([]);
    const [keyword, setSearch] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    //------------------filters-----------------------------------
    const [checkStoredvalueFromRedux, SetCheckStoredValueFromRedux] = useState(null);
    const [filtersAppliedSign, setFiltersAppliedSign] = useState(false);
    const [allFiltersValues, setAllFiltersValues] = useState(false);

    let userdata = localStorage.getItem('SET_USER_DATA');
    let User = JSON.parse(userdata);


    useEffect(() => {
        if (storedFilters && checkStoredvalueFromRedux && allFiltersValues) {
            if (
                (storedFilters &&
                    storedFilters.wards &&
                    storedFilters.wards.length > 0) ||
                (storedFilters &&
                    storedFilters.hospitals &&
                    storedFilters.hospitals.length > 0) ||
                (storedFilters &&
                    storedFilters.rooms &&
                    storedFilters.rooms.length > 0) ||
                (storedFilters &&
                    storedFilters.sortByAlpha)
            ) {
                setFiltersAppliedSign(true);
                getBedListing("", page, itemsPerPage);
            } else {
                setFiltersAppliedSign(false);
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                getBedListing("", page, itemsPerPage, '', item);
            }
        }
    }, [storedFilters, checkStoredvalueFromRedux, allFiltersValues]);


    useEffect(() => {
        if (storedFilters && checkStoredvalueFromRedux) {
            if ((storedFilters &&
                storedFilters.hospitals &&
                storedFilters.hospitals.length > 0)
            ) {
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                handleDashboardHospitalwithFiltersAppliedCase(item, storedFilters.hospitals);
            } else {
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                if (item) {
                    handleDashboardHospitalwithFilters(item);
                }
            }
        }
    }, [checkStoredvalueFromRedux]);


    const handleDashboardHospitalwithFiltersAppliedCase = async (seletedHospitalData, AppliedFilterHospitals) => {
        let applied = AppliedFilterHospitals
        if (applied.length > 1) {
            let index = applied.findIndex((id) => parseInt(id) === parseInt(seletedHospitalData.id))
            if (index === -1) {
                applied.push(seletedHospitalData.id);
            }
            // else {
            //     return applied
            // }
        }
        else {
            applied = []
            applied.push(seletedHospitalData.id);
        }
        let filterNew = {
            wards: storedFilters && storedFilters.wards && storedFilters.wards.length > 0 ? storedFilters.wards : [],
            hospitals: applied,
            rooms: storedFilters && storedFilters.rooms ? storedFilters.rooms : [],
            sortByAlpha: storedFilters && storedFilters.sortByAlpha && storedFilters.sortByAlpha.value ? storedFilters.sortByAlpha.value : null,
        }
        await filtersController.setBedListFilters(filterNew);
        await setAllFiltersValues(true);
    };


    const handleDashboardHospitalwithFilters = async (seletedHospitalData) => {
        let filterNew = {
            wards: storedFilters && storedFilters.wards && storedFilters.wards.length > 0 ? storedFilters.wards : [],
            hospitals: seletedHospitalData && seletedHospitalData.id ? [seletedHospitalData.id] : [],
            rooms: storedFilters && storedFilters.rooms ? storedFilters.rooms : [],
            sortByAlpha: storedFilters && storedFilters.sortByAlpha && storedFilters.sortByAlpha.value ? storedFilters.sortByAlpha.value : null,
        };
        await filtersController.setBedListFilters(filterNew);
        await setAllFiltersValues(true);
    };


    useEffect(() => {
        if (count) {
            handlePages(count);
        }
    }, [count, itemsPerPage]);


    const getModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('bed');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckPermissions(data);
        }
        else {
            setCheckPermissions(null);
        }
    };


    const getBedListing = async (e, page, itemsPerPage, filters, seletedHospitalData) => {
        if (!loader) {
            setLoader(true)
            let post = {
                search: e ? e : '',
                page: page ? page : 1,
                limit: itemsPerPage ? itemsPerPage : 10,
                sort: filters && filters.sortByAlpha ? filters.sortByAlpha : storedFilters && storedFilters.sortByAlpha ? storedFilters.sortByAlpha.value : "DESC",
                ward_id: filters && filters.wards && filters.wards.length > 0 ? filters.wards : storedFilters && storedFilters.wards && storedFilters.wards.length > 0 ? storedFilters.wards : [],
                room_id: filters && filters.rooms && filters.rooms.length > 0 ? filters.rooms : storedFilters && storedFilters.rooms && storedFilters.rooms.length > 0 ? storedFilters.rooms : [],
                hospital_id: filters && filters.hospitals && filters.hospitals.length > 0 ? filters.hospitals : storedFilters && storedFilters.hospitals && storedFilters.hospitals.length > 0 ? storedFilters.hospitals : seletedHospitalData && seletedHospitalData.id ? [seletedHospitalData.id] : [],
            };
            setLoader(true);
            let response = await bedsController.superAdminBedsListing(post);
            if (response && response.status) {
                setBedList(response.data);
                setCount(response.count);
                setTotalCount(response.count);
                setLoader(false);
            } else {
                setLoader(false);
                setCount(null);
                setTotalCount(null)
            }
        }
    }

    //--------bed--filters------------------->

    const handleFilters = (sort) => {
        if (sort) {
            setSorting(sort)
            getBedListing("", page, itemsPerPage, sort)
        }
    };

    const updateBedStatuss = async (item) => {
        let post = {
            status: item.status == 1 ? 0 : 1,
        };
        let response = await bedsController.updateBedStatus(item.id, post);
        if (response && response.status) {
            let array = [...bedList];
            item.status = parseInt(item.status) == 0 ? 1 : 0;
            setBedList(array)
            new Toaster().success(response.message);
        } else return;
    };


    const handleDelete = (item) => {
        setDeleteItem(item)
        setDeletePopup(true)
    };


    const handleDeleteBed = async (data) => {
        let array = [...bedList]
        let id = data && data.id ? data.id : '';
        setLoader(true);
        let response = await bedsController.bedsDelete(id);
        if (response && response.status) {
            setLoader(false);
            new Toaster().success(response.message);
            setDeletePopup(false);
            let filterData = array.filter((data) => data.id !== data.id);
            if (filterData.length > 0) {
                getBedListing('', page, itemsPerPage);
            }
            else {
                if (page > 1) {
                    let newpage = page - 1
                    setPage(page - 1);
                    getBedListing('', newpage, itemsPerPage);
                }
                else {
                    setPage(page);
                    getBedListing('', page, itemsPerPage);
                }
            }
            setTotalPages([]);
        }
        else {
            setDeletePopup(false)
            setLoader(false);
        }
        setLoader(false);
    };

    const handleBackClick = () => {                             //<------------PAZINATION
        if (page > 1) {
            let newpage = page - 1;
            setPage(page - 1);
            getBedListing("", newpage, itemsPerPage);
        } else return;
    };


    const handleNextClick = () => {                                 //<------------PAZINATION
        if (page >= 1 && page < totalPages.length) {
            let newpage = page + 1;
            setPage(page + 1);
            getBedListing("", newpage, itemsPerPage);
        } else return;
    };

    const handleItemPerPage = (e) => {
        let value = e.target.value;
        setItemsPerPage(value);
        getBedListing("", 1, value);
    };


    const handlePage = (e) => {
        setPage(e);
        getBedListing("", e, itemsPerPage);
    };


    const handlePages = (count) => {
        let array = [];
        const data = parseInt(count);
        const totalPage = Math.ceil(data / itemsPerPage);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i);
        }
        setTotalPages(array);
    };


    //---------Bed filters----------->

    const applyFilters = async (filter) => {
        let filters = {
            wards: filter && filter.wards ? filter.wards : [],
            hospitals: filter && filter.hospitals ? filter.hospitals : [],
            rooms: filter && filter.rooms ? filter.rooms : [],
            sortByAlpha: filter && filter.sortByAlpha ? filter.sortByAlpha : null,
        };
        if (filter && filter.hospitals && filter.hospitals.length > 0) {
            await filtersController.setBedListFilters(filters);
            setShowFilterModal(false);
            setFiltersAppliedSign(true);
        } else {
            new Toaster().error("Please select at least one hospital");
        }
    };


    const handleClearAllFilters = async () => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        await filtersController.clearBedListFilters(item);
    };

    const setReduxValue = (e) => {          //<------------For confirmation that we have checked the localstorage value's and RESET the REDUX- FILTERS's Values and after it the listing api will hit
        SetCheckStoredValueFromRedux(e);
    };

    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/dashboard-superadmin");
            }
        } else if (i === 1) {
            navigate("/bed-list");
        } else return;
    };


    return {
        checkPermissions,
        getModulePermissions,
        getBedListing,
        breadcrumData,
        loader,
        breadcrumItem,
        loaction,
        bedList,
        navigate,
        handleDelete,
        deletePopup,
        deleteItem,
        handleDeleteBed,
        setDeletePopup,
        handleBackClick,
        handleNextClick,
        handlePage,
        handleBreadcrumDataNaviagtion,
        totalCount,
        page,
        totalPages,
        handleFilters,
        sorting,
        handlePages,
        updateBedStatuss,
        keyword,
        setSearch,
        User,
        showFilterModal,
        setShowFilterModal,
        handleClearAllFilters,
        setReduxValue,
        applyFilters,
        filtersAppliedSign,
        handleClearAllFilters,
        handleItemPerPage,
        itemsPerPage

    };
};

export default BedListFunction;
