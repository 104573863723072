import React, { useEffect } from "react";
import { useState } from "react";
import departmentController from "../../../../apis/Controllers/DepartmentController/department.controller";
import rolesController from "../../../../apis/Controllers/RolesController/roles.controller";
import hospitalController from "../../../../apis/Controllers/HospitalController/hospital.controller";
import store from "../../../../redux/store";


const StaffFilterFunctions = (props) => {
    let storedFilters = store.getState().StaffListFilters.filters;

    const [departmentValues, setDepartmentValues] = useState(null);
    const [hospitalValues, setHospitalValues] = useState(null);
    const [departmentList, setDepartmentList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [hospitalsSearch, setHospitalsSearch] = useState("");
    const [hospitalsList, setHospitalsList] = useState([]);
    const defaultFilters = {
        department:
            storedFilters && storedFilters.department
                ? storedFilters.department
                : [],
        role: storedFilters && storedFilters.role ? storedFilters.role : [],
        hospitals:
            storedFilters && storedFilters.hospitals
                ? storedFilters.hospitals
                : [],
        adminStatus:
            storedFilters && storedFilters.adminStatus
                ? storedFilters.adminStatus
                : null,
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [adminStatus, setAdminStatus] = useState([
        {
            label: "Active",
            value: "1",
            checked: false,
            id: 1,
        },
        {
            label: "Inactive",
            value: "0",
            checked: false,
            id: 2,
        },
    ]);


    useEffect(() => {
        if (storedFilters && storedFilters.adminStatus) {
            handleAdminsStatusFilter(storedFilters.adminStatus);
        }
    }, [storedFilters]);


    const getdepartment = async () => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        let post = {
            // hospital_id: item ? [item.id] : [],
            hospital_id: item ? item.id :null,
        };
        let response = await departmentController.departmentList(post);
        if (response && response.status) {
            let array = response.data;
            for (let i in array) {
                array[i]["label"] = array[i].title;
            }
            if (storedFilters && storedFilters.department && storedFilters.department.length > 0) {
                let findItem = array.filter((item) => parseInt(item.id) === storedFilters.department[0])
                setDepartmentValues(findItem);
            }
            setDepartmentList(array);
        } else {
            setDepartmentList([]);
        }
    };


    const getRoleList = async () => {
        let response = await rolesController.rolesListingDropdown();
        if (response && response.status) {
            let array = response.data;
            for (let i in array) {
                array[i]["label"] = array[i].title;
                array[i]["checked"] = false;
            }
            if (storedFilters && storedFilters.role && storedFilters.role.length > 0) {
                let list = storedFilters.role
                const appliedRoles = array.filter(({ id: adminId }) => list.some((item) => adminId === item))
                array.forEach(a => {
                    appliedRoles.forEach(b => {
                        if (parseInt(a.id) === parseInt(b.id)) {
                            a.checked = true
                        }
                    })
                });
                setRoleList(array);
            }
            else {
                setRoleList(array);
            }
        } else {
            setRoleList([]);
        }
    };


    const handleAdminsStatusFilter = (appliedStatus) => {
        let adminSatusList = [...adminStatus]
        let findAppliedStatusItemIndex = adminSatusList.findIndex((item) => parseInt(item.value) === parseInt(appliedStatus))
        // if (findAppliedStatusItemIndex) {
        adminSatusList[findAppliedStatusItemIndex].checked = true
        // }
        setAdminStatus(adminSatusList);
    };


    const handleDepartmentFilters = (value) => {
        setDepartmentValues(value);
        setFilters({
            ...filters,
            department: [value.id],
        });
    };


    const handlehospitalFilters = (value) => {
        setHospitalValues(value);
        setFilters({
            ...filters,
            hospital: [value.id],
        });
    };


    const handleRoleFilters = (item) => {
        let array = [...roleList];
        let value = [...filters.role];
        if (item) {
            for (let i in array) {
                if (parseInt(array[i].id) === parseInt(item.id)) {
                    let check = array[i].checked;
                    array[i].checked = !check;
                }
            }
            let status = value.findIndex((id) => parseInt(id) === parseInt(item.id));
            if (status === -1) {
                value.push(item.id);
            } else {
                value.splice(status, 1);
            }
            setFilters({
                ...filters,
                role: value,
            });
            setRoleList(array);
        }
    };



    const handleAdminStatus = (item) => {
        let array = [...adminStatus];
        if (item) {
            for (let i in array) {
                array[i].checked = false;
                if (parseInt(array[i].id) === parseInt(item.id)) {
                    let check = array[i].checked;
                    array[i].checked = !check;
                }
            }
            setFilters({
                ...filters, 
                adminStatus: item.value,
            });
            setAdminStatus(array);
        }
    };


    
    const getHospitalListing = async (e) => {
          let post = {
            search: e ? e : "",
        };
        let response = await hospitalController.hospitalListDashboard(post);
         if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]["checked"] = false;
             }
             setHospitalsList(data)
            if (
                storedFilters &&
                storedFilters.hospitals &&
                storedFilters.hospitals.length > 0
            ) {
                reSethospitalsAppliedFiltersValues(storedFilters, data);
            } else {
                setHospitalsList(data);
            }
            } else {
              setHospitalsList([])
            }
    };

        const handleHospitalsFilters = (item) => {
        let array = [...hospitalsList];
        let value = [...filters.hospitals];
        if (item) {
            for (let i in array) {
                if (parseInt(array[i].id) === parseInt(item.id)) {
                    let check = array[i].checked;
                    array[i].checked = !check;
                }
            }
            setHospitalsList(array);
            let status = value.findIndex((id) => parseInt(id) === parseInt(item.id));
            if (status === -1) {
                value.push(item.id);
            } else {
                value.splice(status, 1);
            }
            setFilters({
                ...filters,
                hospitals: value,
            });
        }
    };

       const reSethospitalsAppliedFiltersValues = (storedFilters, hospitalslist) => {
        let filter = { ...storedFilters};
        let hospitals = [...hospitalslist];
        if (hospitals && hospitals.length > 0) {
            hospitals.forEach((b) => {
                filter.hospitals.forEach((a) => {
                    if (parseInt(b.id) === parseInt(a)) {
                        b.checked = true;
                    }
                });
            });
            setHospitalsList(hospitals)
            setFilters({
                ...filters,
                hospitals: filter.hospitals,
            });
        }
    };

    const handleHospitalsSearch = (e) => {
        setHospitalsSearch(e.target.value);
        getHospitalListing(e.target.value);
    };

     
    const applyFilters = () => {
        props.applyFilters(filters);
    };


    const handleClearAllFilters = async () => {
        props.handleClearAllFilters();
        props.close();
    };


    return {
        getdepartment,
        departmentList,
        getRoleList,
        roleList,
        adminStatus,
        handleRoleFilters,
        handleAdminStatus,
        departmentValues,
        hospitalValues,
        handleDepartmentFilters,
        handlehospitalFilters,
        applyFilters,
        handleClearAllFilters,
        handleAdminsStatusFilter,
        handleHospitalsFilters,
        getHospitalListing,
        hospitalsList,
        hospitalsSearch,
        handleHospitalsSearch
    };
};

export default StaffFilterFunctions;
