import { useEffect, useState } from "react";
import adminController from "../../../../apis/Controllers/AdminControler/admin.controller";
import wardController from "../../../../apis/Controllers/WardController/ward.controller";
import { actionsController } from "../../../../apis/Controllers/CommonController/action.controller";
import store from "../../../../redux/store";
import hospitalController from "../../../../apis/Controllers/HospitalController/hospital.controller";

export const FiltersFunctions = (props) => {
    let storedFilters = store.getState().TaskListFilters.filters;
    const [adminsList, setAdminslist] = useState([]);
    const [wardslist, setWardsList] = useState([]);
    const [adminsSearch, setAdminsSearch] = useState("");
    const [wardsSearch, setWardsSearch] = useState("");
    const [taskPriorities, setTaskPriorities] = useState([]);
    const [hospitalsSearch, setHospitalsSearch] = useState("");
    const [hospitalsList, setHospitalsList] = useState([]);
    const defaultFilters = {
        assignBy:
            storedFilters && storedFilters.assignBy
                ? storedFilters.assignBy
                : [],
        wards: storedFilters && storedFilters.wards ? storedFilters.wards : [],
        hospitals: storedFilters && storedFilters.hospitals ? storedFilters.hospitals : [],
        taskPriorities:
            storedFilters && storedFilters.taskPriorities
                ? storedFilters.taskPriorities
                : null,
        taskStatus:
            storedFilters && storedFilters.taskStatus
                ? storedFilters.taskStatus
                : null,
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [taskStatusArray, setTaskStatusArray] = useState([
        {
            label: "Completed",
            value: "1",
            checked: false,
            id: 1,
        },
        {
            label: "Pending",
            value: "0",
            checked: false,
            id: 2,
        },
    ]);

    useEffect(() => {
        if (storedFilters && storedFilters.taskStatus) {
            reSetTaskStatusArrayAppliedFilters(storedFilters);
        }
    }, [storedFilters]);

    const handleAssignedBySearch = (e) => {
        setAdminsSearch(e.target.value);
        getAdminsList(e.target.value);
    };

    const handleWardsSearch = (e) => {
        setWardsSearch(e.target.value);
        wardsListing(e.target.value);
    };

    const getAdminsList = async (e) => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        let post = {
            search: e ? e : "",
            hospital_id: item && item.id ? [item.id] : []
        };
        let response = await adminController.allAdminsList();
        if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]["checked"] = false;
            }
            if (storedFilters && storedFilters.assignBy.length > 0) {
                reSetAdminsAppliedFiltersValues(storedFilters, data);
            } else {
                setAdminslist(data);
            }
        } else {
            setAdminslist([]);
        }
    };

    const reSetAdminsAppliedFiltersValues = (storedFilters, adminlist) => {
        let filter = { ...storedFilters };
        let admins = [...adminlist];
        if (admins && admins.length > 0) {
            admins.forEach((b) => {
                filter.assignBy.forEach((a) => {
                    if (parseInt(b.id) === parseInt(a)) {
                        b.checked = true;
                    }
                });
            });
            setAdminslist(admins);
            setFilters({
                ...filters,
                assignBy: filter.assignBy,
            });
        }
    };

    const wardsListing = async (e) => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        let post = {
            hospital_id: item ? [item.id] : [],
            search: e ? e : "",
        };
        let response = await wardController.wardListing(post);
        if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]["checked"] = false;
            }
            if (
                storedFilters &&
                storedFilters.wards &&
                storedFilters.wards.length > 0
            ) {
                reSetWardsAppliedFiltersValues(storedFilters, data);
            } else {
                setWardsList(data);
            }
        } else {
            setWardsList([]);
        }
    };

    const reSetWardsAppliedFiltersValues = (storedFilters, wardslist) => {
        let filter = { ...storedFilters };
        let wards = [...wardslist];
        if (wards && wards.length > 0) {
            wards.forEach((b) => {
                filter.wards.forEach((a) => {
                    if (parseInt(b.id) === parseInt(a)) {
                        b.checked = true;
                    }
                });
            });
            setWardsList(wards);
            setFilters({
                ...filters,
                wards: filter.wards,
            });
        }
    };

    const getTaskPeriorities = async () => {
        let response = await actionsController.taskPriorities();
        if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]["checked"] = false;
            }
            if (storedFilters && storedFilters.taskPriorities) {
                reSetTaskPeriorityAppliedFiltersValues(storedFilters, data);
            } else {
                setTaskPriorities(data);
            }
        } else {
            setTaskPriorities([]);
        }
    };

    const reSetTaskPeriorityAppliedFiltersValues = (
        storedFilters,
        taskPeriorities
    ) => {
        let filter = { ...storedFilters };
        let perioty = [...taskPeriorities];
        if (perioty && perioty.length > 0) {
            let status = perioty.findIndex(
                (item) => parseInt(item.id) === parseInt(filter.taskPriorities)
            );
            if (status !== -1) {
                perioty[status].checked = true;
            } else {
                return perioty;
            }
            setTaskPriorities(perioty);
            setFilters({
                ...filters,
                taskPriorities: filter.taskPriorities,
            });
        }
    };

    const reSetTaskStatusArrayAppliedFilters = (appliedFiletrs) => {
        let filter = { ...appliedFiletrs };
        let taskStatus = [...taskStatusArray];
        if (filter && filter.taskStatus) {
            let status = taskStatus.findIndex(
                (item) => parseInt(item.value) === parseInt(filter.taskStatus)
            );
            if (status !== -1) {
                taskStatus[status].checked = true;
            } else {
                return taskStatus;
            }
            setTaskStatusArray(taskStatus);
            setFilters({
                ...filters,
                taskStatus: filter.taskStatus,
            });
        }
    };

    const handleAssignedByFilters = (item) => {
        let array = [...adminsList];
        let value = [...filters.assignBy];
        if (item) {
            for (let i in array) {
                if (parseInt(array[i].id) === parseInt(item.id)) {
                    let check = array[i].checked;
                    array[i].checked = !check;
                }
            }
            let status = value.findIndex(
                (id) => parseInt(id) === parseInt(item.id)
            );
            if (status === -1) {
                value.push(item.id);
            } else {
                value.splice(status, 1);
            }
            setFilters({
                ...filters,
                assignBy: value,
            });
            setAdminslist(array);
        }
    };

    const handleWardsFilters = (item) => {
        let array = [...wardslist];
        let value = [...filters.wards];
        if (item) {
            for (let i in array) {
                if (parseInt(array[i].id) === parseInt(item.id)) {
                    let check = array[i].checked;
                    array[i].checked = !check;
                }
            }
            setWardsList(array);
            let status = value.findIndex((id) => parseInt(id) === parseInt(item.id));
            if (status === -1) {
                value.push(item.id);
            } else {
                value.splice(status, 1);
            }
            setFilters({
                ...filters,
                wards: value,
            });
        }
    };

    const handleTaskPrioritiesFilters = (item) => {
        let array = [...taskPriorities];
        if (item) {
            for (let i in array) {
                if (parseInt(array[i].id) === parseInt(item.id)) {
                    if (array[i].checked === false) {
                        array[i].checked = true
                        setFilters({
                            ...filters,
                            taskPriorities: item.id,
                        });
                    }
                    else {
                        array[i].checked = false
                        setFilters({
                            ...filters,
                            taskPriorities: null,
                        });
                    }
                }
                else {
                    array[i].checked = false
                }
            }
            setTaskPriorities(array);
        }
    };


    const handleTaskStatus = (item) => {
        let array = [...taskStatusArray];
        if (item) {
            for (let i in array) {
                if (parseInt(array[i].id) === parseInt(item.id)) {
                    if (array[i].checked === false) {
                        array[i].checked = true
                        setFilters({
                            ...filters,
                            taskStatus: item.value,
                        });
                    }
                    else {
                        array[i].checked = false
                        setFilters({
                            ...filters,
                            taskStatus: null,
                        });
                    }
                }
                else {
                    array[i].checked = false
                }
            }
            setTaskStatusArray(array);

        }
    };

     const handleHospitalsSearch = (e) => {
        setHospitalsSearch(e.target.value);
        getHospitalListing(e.target.value);
    };

    
    const getHospitalListing = async (e) => {
          let post = {
            search: e ? e : "",
        };
        let response = await hospitalController.hospitalListing(post);
         if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]["checked"] = false;
             }
             setHospitalsList(data)
            if (
                storedFilters &&
                storedFilters.hospitals &&
                storedFilters.hospitals.length > 0
            ) {
                reSethospitalsAppliedFiltersValues(storedFilters, data);
            } else {
                setHospitalsList(data);
            }
            } else {
              setHospitalsList([])
            }
    };

        const handleHospitalsFilters = (item) => {
        let array = [...hospitalsList];
        let value = [...filters.hospitals];
        if (item) {
            for (let i in array) {
                if (parseInt(array[i].id) === parseInt(item.id)) {
                    let check = array[i].checked;
                    array[i].checked = !check;
                }
            }
            setHospitalsList(array);
            let status = value.findIndex((id) => parseInt(id) === parseInt(item.id));
            if (status === -1) {
                value.push(item.id);
            } else {
                value.splice(status, 1);
            }
            setFilters({
                ...filters,
                hospitals: value,
            });
        }
    };

       const reSethospitalsAppliedFiltersValues = (storedFilters, hospitalslist) => {
        let filter = { ...storedFilters};
        let hospitals = [...hospitalslist];
        if (hospitals && hospitals.length > 0) {
            hospitals.forEach((b) => {
                filter.hospitals.forEach((a) => {
                    if (parseInt(b.id) === parseInt(a)) {
                        b.checked = true;
                    }
                });
            });
            setHospitalsList(hospitals)
            setFilters({
                ...filters,
                hospitals: filter.hospitals,
            });
        }
    };
    
    const applyFilters = () => {
        props.applyFilters(filters);
    };

    const handleClearAllFilters = async () => {
        props.handleClearAllFilters();
        props.close();
    };

    return {
        getAdminsList,
        adminsList,
        wardsListing,
        wardslist,
        adminsSearch,
        wardsSearch,
        handleWardsSearch,
        handleAssignedBySearch,
        getTaskPeriorities,
        taskPriorities,
        handleAssignedByFilters,
        handleWardsFilters,
        handleTaskPrioritiesFilters,
        handleTaskStatus,
        taskStatusArray,
        applyFilters,
        setFilters,
        reSetTaskStatusArrayAppliedFilters,
        handleClearAllFilters,
        hospitalsSearch,
        hospitalsList,
        handleHospitalsFilters,
        handleHospitalsSearch,
        getHospitalListing
    };
};
