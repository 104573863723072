import ActionTypes from "../../constants";

const initialState = {
    filters: {
        department: [],
        role: [],
        hospitals: [],
        adminStatus: null,
    },
};

const StaffListFilters = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_STAFF_LIST_FILTERS:
            return Object.assign({}, state, {
                filters: action.payload,
            });
        default:
            return state;
    }
};

export default StaffListFilters;
