import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { Green_Shape, Shape } from "../../../../assets/images";
import { CheckedImg } from "../../../../assets/images";
import ButtonLoader from "../../../Helper/buttonLoader";



const DeleteModal = (props) => {


    const handleSubmit = () => {
        props.handleSubmit();
    };


    return (
        <div>
            <Modal show={props.show} onHide={props.close} centered className="delete_modal">
                {/* <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="dlt_body_area">
                        <div className="image_section">

                            {/* Task_Complete_icon */}
                            {
                                props && props.taskCompeled ?
                                    <div className="img_shape">
                                        <Image src={Green_Shape} alt="..." />
                                        <div className="green_circle">
                                            <div className="img_area">
                                                <Image src={CheckedImg} alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="img_shape">
                                        <Image src={props.image ? props.image : Shape} alt="..." />
                                        <div className={props.background ? props.background : "dlt_circle"}>
                                            <FontAwesomeIcon icon={props.icon ? props.icon : faTrashCan} />
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className="text_line">
                            <p>{props && props.message ? props.message : 'Do you wish to delete this member?'}</p>
                        </div>
                        <div className="btn_area">
                            <Button
                                className="cancelBtn"
                                type="button"
                                onClick={() => props.close()}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="submitBtn"
                                type="submit"
                                onClick={() => props && props.handleSubmit ? handleSubmit() : ''}
                                disabled={props && props.loader ? true : false}
                            >
                                {props && props.loader ? (
                                    <ButtonLoader
                                        loader={props.loader}
                                        variant={"danger"}
                                    />
                                ) : null}
                                {props && props.loader ? "Loading..." : props && props.rightButtontext ? props && props.rightButtontext : 'Submit'}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default DeleteModal;