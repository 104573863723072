import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import hospitalController from "../../../../../apis/Controllers/HospitalController/hospital.controller";
import { el } from "date-fns/locale";
import store from "../../../../../redux/store";
import permissionsController from "../../../../../apis/Controllers/PermissionsController/permissions.contoller";

const HospitalDetailFunctions = () => {
    const  [breadcrumData,setBreadcrumData] = useState(["Dashboard", "Hospital List"]);
    var breadcrumItem = "View Hospital";

    const [taskAssign, setTaskAssign] = useState(false);
    const [hospitalDetail, setHospitalDetail] = useState(null);
    const [checkPermissions, setCheckPermissions] = useState(null);
    const [loader, setLoader] = useState(false);
    const loaction = useLocation();
    let navigate = useNavigate();
    let userdata = localStorage.getItem('SET_USER_DATA');
    let User = JSON.parse(userdata);

    const getHospitalDetail = async (id) => {
        setLoader(true);
        let response = await hospitalController.hospitalDetail(id);
        if (response && response.status) {
            setLoader(false);
            setHospitalDetail(response.data);
        }
        else {
            setLoader(false);
            setHospitalDetail(null);
        }
        setLoader(false);
    };


    const getModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('hospital');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckPermissions(data);
        }
        else {
            setCheckPermissions(null);
        }
    };


    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate('/dashboard');
            }
            else {
                navigate('/dashboard-superadmin');
            }
        }
        else if (i === 1) {
            if (loaction && loaction.state && loaction.state.name == 'wardlist') {
                navigate('/wards');
            } else if (loaction && loaction.state && loaction.state.name == 'departmentlist') {
                navigate('/department');
            } else if (loaction && loaction.state && loaction.state.name == "roomlist") {
                navigate('/room-list');
            } else if (loaction && loaction.state && loaction.state.name == "stafflist") {
                navigate('/staff-list');
            } else if (loaction && loaction.state && loaction.state.name == "dashboard") {
                navigate('/dashboard-superadmin');
            } else {
                navigate('/hospital-listing');
            }
           
        }
        else return;
    };


    return {
        User,
        getModulePermissions,
        checkPermissions,
        getHospitalDetail,
        hospitalDetail,
        loader,
        loaction,
        navigate,
        taskAssign,
        setTaskAssign,
        breadcrumData,
        breadcrumItem,
        loader,
        setLoader,
        handleBreadcrumDataNaviagtion,
        setBreadcrumData
    }
};

export default HospitalDetailFunctions;