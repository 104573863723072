import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthController from "../../../../apis/Controllers/AuthController/AuthController";
import Validation from "../../../Helper/validations/vaildation";
import { Toaster } from "../../../Helper/toaster/Toaster";
import store from "../../../../redux/store";

const ChangePasswordFunction = () => {
    const defaultValues = {
        password: "",
        newPassword: "",
        confirmPassword: "",
    };
    const defaultErrors = {
        password: {
            rules: ["required", "password"],
            isValid: true,
            message: "",
        },
        newPassword: {
            rules: ["required", "password"],
            isValid: true,
            message: "",
        },
        confirmPassword: {
            rules: ["required", "password"],
            isValid: true,
            message: "",
        },
    };
    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);
     const [loader, setLoader] = useState(false);
    let navigate = useNavigate();

    //handleChange and Validation on each field ------
    const handleChange = (field, value) => {
        let validation = new Validation(isError);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };

    //check full form validation---------------------
    const handleChangePassword = async () => {
        setLoader(true)
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            setLoader(false)
        if (values.newPassword !== values.confirmPassword) {
        let err = {
          ...isError.confirmPassword,
          isValid: false,
          message: 'Confirm Password should be same as New Password',
        };
        setError({...isError, confirmPassword: err});
      } else {
         let response = await AuthController.changePasswordDetails(values);
            if (response && response.status) {
                new Toaster().success(response.message);
                let user = store.getState().UserReducer.user;
                if (user && parseInt(user.user_type) === 1) {
                    navigate("/dashboard-superadmin");
                } else {
                     navigate("/dashboard");
                }                 
                setLoader(false)
            } else {
                setLoader(false)
               }
           }
        } else {
            setLoader(false)
            setError({ ...isValid.errors });
        }
    };

    return {
        values,
        isError,
        handleChange,
        handleChangePassword,
        loader
    };
};

export default ChangePasswordFunction;
