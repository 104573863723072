import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Validation from '../../../Helper/validations/vaildation'
import adminController from '../../../../apis/Controllers/AdminControler/admin.controller'
import store from "../../../../redux/store";
import { Toaster } from "../../../Helper/toaster/Toaster";

const UpdateSuperAdminProfileFunctions = () => {
    var breadcrumData = ["Dashboard", "Staff List"];
    const [breadcrumItem, setbreadcrumItem] = useState("Add Staff");

    const defaultValues = {
        image: null,
        name: null,
        email: null,
        phonenumber: null,
        address: null,
    };

    const defaultErrors = {
        image: {
            rules: [""],
            isValid: true,
            message: "",
        },
        name: {
            rules: ["required", "alphabetic"],
            isValid: true,
            message: "",
        },
        email: {
            rules: ["required", "email"],
            isValid: true,
            message: "",
        },
        phonenumber: {
            rules: [],
            isValid: true,
            message: "",
        },
        address: {
            rules: [],
            isValid: true,
            message: "",
        },
    };

    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const loaction = useLocation();


    const handleChange = (field, value) => {
        let err = { ...isError }
        if (field === 'phonenumber') {
            if (value.length > 0) {
                err = {
                    ...isError,
                    phonenumber: {
                        rules: ["max:13", "min:9"],
                        isValid: true,
                        message: '',
                    },
                };
            }
            else {
                err = {
                    ...isError,
                    phonenumber: {
                        rules: [''],
                        isValid: true,
                        message: '',
                    },
                };
            }
        }
        let validation = new Validation(err);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };


    const getSuperAdminDetail = async () => {
        let user = store.getState().UserReducer.user;
        let response = await adminController.adminDetail(user && user.id);
        if (response && response.status) {
            let data = response.data
            setValues({
                ...values,
                image: data && data.image ? data.image : null,
                name: data && data.name ? data.name : null,
                email: data && data.email ? data.email : null,
                phonenumber: data && data.phonenumber ? data.phonenumber : null,
                address: data && data.address ? data.address : null,
            })
        } else {
            navigate(-1);
        }
    };


    const handleSubmit = async () => {
        let user = store.getState().UserReducer.user;
        let id = user && user.id ? user.id : ''
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            setLoader(true);
            let response = await adminController.updateSuperAdmin(id, values);
            if (response && response.status) {
                new Toaster().success(response.message);
                navigate("/dashboard-superadmin");
                setLoader(false);
            } else {
                setLoader(false);
            }
        } else {
            setError({ ...isValid.errors });
        }
    };


    const handleBreadcrumDataNaviagtion = (i) => {
        if (i === 0) {
            navigate("/dashboard-superadmin");
        } else return;
    };


    return {
        values,
        setValues,
        isError,
        loader,
        navigate,
        handleChange,
        handleBreadcrumDataNaviagtion,
        loaction,
        breadcrumData,
        breadcrumItem,
        setbreadcrumItem,
        getSuperAdminDetail,
        handleSubmit,
    };
};

export default UpdateSuperAdminProfileFunctions;
