import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function rolesListing (params) {
    return mainWrapper.get(WebConstant.host + `/role_list`, params);
};

function rolesListingDropdown (params) {
    return mainWrapper.get(WebConstant.host + `/role_list_dropdown`, params);
};


function rolesCreate (params) {
    return mainWrapper.post(WebConstant.host + `/create_role`, params);
};


function rolesDetail (id) {
    return mainWrapper.get(WebConstant.host + `/detail_role/${id}`);
};


function rolesUpdate (params,id) {
    return mainWrapper.put(WebConstant.host + `/edit_role/${id}`, params);
};


function roleDelete (id) {
    return mainWrapper._delete(WebConstant.host + `/delete_role/${id}`);
};


export const rolesServices = {
    rolesListing,
    rolesListingDropdown,
    rolesCreate,
    rolesDetail,
    rolesUpdate,
    roleDelete
};
