import {
    faAngleDown,
    faCheck,
    faImage
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import Select, { components } from 'react-select';
import { Hospital_Img } from "../../../../../assets/images";
import ButtonLoader from "../../../../Helper/buttonLoader";
import * as Constants from "../../../../Helper/constants/constants";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import AddHospitalFunctions from "./funstions";
import Loader from "../../../../Helper/loader";
import UploadImageBox from "../../../../Helper/UploadUserImageBox";
import CustomSelectOptions from "../../../../Helper/customSelectOption";


const AddHospital = () => {
    const {
        handleChange,
        handleSubmit,
        values,
        isError,
        getHospitalDetail,
        breadcrumData,
        breadcrumItem,
        setBreadcrumItem,
        getCitiesList,
        getStatesList,
        citieslist,
        statesList,
        loaction,
        navigate,
        loader,
        handleBreadcrumDataNaviagtion
    } = AddHospitalFunctions();


    useEffect(() => {
        if (loaction && loaction.state && loaction.state.name) {
            let id = loaction && loaction.state && loaction.state.id ? loaction.state.id : ''
            getHospitalDetail(id);
            // getCitiesList(values && values.state && values.state.id);
            setBreadcrumItem('Edit Hospital');
        }
        else {
            setBreadcrumItem('Add Hospital');
        }
    }, []);

    
    return (
        <div className="add_hospital_area">
            <WithoutImageHeader
                header={loaction && loaction.state && loaction.state.name ? Constants.EDIT_HOSPITAL : Constants.ADD_HOSPITAL}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBack={() => navigate(-1)}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
            />
            <div className="hospital_content_area">
                <UploadImageBox
                    name={``}
                    image={values && values.image}
                    onResponse={(response) => {
                        handleChange("image", response.path)
                    }}
                    removeImage={async () => {
                        //  await removeImage();
                    }}
                />
                <div className="form_section">
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                            {
                                loader ?
                                    <Loader
                                        loader={loader}
                                    />
                                    : null}
                            <Form>
                                <Form.Group className="form-group">
                                    <Form.Label>Hospital Name<span>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter hospital name"
                                        autoCapitalize={true}
                                        value={values.name}
                                        className={isError.name.message
                                            ? "error_border"
                                            : ""}
                                        onChange={(e) =>
                                            handleChange(
                                                "name",
                                                e.target.value
                                            )
                                        }
                                    />
                                    {isError.name.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.name.message}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>GSTIN<span>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your GST number"
                                        value={values.gstNumber}
                                        maxLength={15}
                                        className={isError.gstNumber.message
                                            ? "error_border"
                                            : ""}
                                        onChange={(e) =>
                                            handleChange(
                                                "gstNumber",
                                                e.target.value
                                            )
                                        }
                                    />
                                    {isError.gstNumber.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.gstNumber.message}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Row>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Form.Group className="form-group">
                                            <Form.Label>Contact Number<span>*</span></Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter your contact number"
                                                value={values.phonenumber}
                                                maxLength={10}
                                                autoComplete="none"
                                                className={isError.phonenumber.message
                                                    ? "error_border"
                                                    : ""}
                                                onChange={(e) =>
                                                    handleChange(
                                                        "phonenumber",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            {isError.phonenumber.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError.phonenumber.message}
                                                </p>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Form.Group className="form-group">
                                            <Form.Label>Telephone Number<span>*</span></Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter your telephone number"
                                                value={values.telephonenumber}
                                                maxLength={10}
                                                autoComplete="none"
                                                className={isError.telephonenumber.message
                                                    ? "error_border"
                                                    : ""}
                                                onChange={(e) =>
                                                    handleChange(
                                                        "telephonenumber",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            {isError.telephonenumber.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError.telephonenumber.message}
                                                </p>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group className="form-group">
                                    <Form.Label>Email ( Optional )</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your email address"
                                        value={values.email}
                                        className={isError.email.message
                                            ? "error_border"
                                            : ""}
                                        onChange={(e) =>
                                            handleChange(
                                                "email",
                                                e.target.value
                                            )
                                        }
                                    />
                                    {isError.email.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.email.message}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>Address<span>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your address"
                                        value={values.address}
                                        className={isError.address.message
                                            ? "error_border"
                                            : ""}
                                        onChange={(e) =>
                                            handleChange(
                                                "address",
                                                e.target.value
                                            )
                                        }
                                    />
                                    {isError.address.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.address.message}
                                        </p>
                                    ) : null}
                                </Form.Group>
                            </Form>
                            <Row>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className="dropdown_box">
                                        <h6>State<span>*</span></h6>
                                        <div className="dropdown_area">
                                            <Select
                                                placeholder="Select State"
                                                autoFocus={false}
                                                value={values.state}
                                                options={statesList}
                                                onChange={(e) => { getCitiesList(e.id); handleChange("state", e) }}
                                                className={
                                                    isError.state.message
                                                        ? "error_border_select"
                                                        : ""
                                                }
                                                components={{
                                                    Option: props => (
                                                        <CustomSelectOptions
                                                            items={props}
                                                            value={values && values.state}
                                                        />
                                                    )
                                                }}
                                            />
                                        </div>
                                        {isError.state.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.state.message}
                                            </p>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className="dropdown_box">
                                        <h6>City<span>*</span></h6>
                                        <div className="dropdown_area">
                                            <Select
                                                placeholder="Select City"
                                                value={values.city}
                                                options={citieslist}
                                                onChange={(e) => handleChange("city", e)}
                                                isDisabled={(values.state !== null && values.state !== '') ? false : true}
                                                className={
                                                    isError.city.message
                                                        ? "error_border_select"
                                                        : ""
                                                }
                                                components={{
                                                    Option: props => (
                                                        <CustomSelectOptions
                                                            items={props}
                                                            value={values && values.city}
                                                        />
                                                    )
                                                }}
                                            />
                                        </div>
                                        {isError.city.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.city.message}
                                            </p>
                                        ) : null}
                                    </div>
                                </Col>

                            </Row>
                            <Form>
                                <Form.Group className="form-group">
                                    <Form.Label>ZIP Code<span>*</span></Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter your zip code"
                                        value={values.zipcode}
                                        maxLength={8}
                                        className={isError.zipcode.message
                                            ? "error_border"
                                            : ""}
                                        onChange={(e) =>
                                            handleChange(
                                                "zipcode",
                                                e.target.value
                                            )
                                        }
                                    />
                                    {isError.zipcode.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.zipcode.message}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>Google Map Link</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Google Map Link"
                                        value={values.mapLink}
                                        className={isError.mapLink.message
                                            ? "error_border"
                                            : ""}
                                        onChange={(e) =>
                                            handleChange(
                                                "mapLink",
                                                e.target.value
                                            )
                                        }
                                    />
                                    {isError.mapLink.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.mapLink.message}
                                        </p>
                                    ) : null}
                                </Form.Group>
                            </Form>
                            <div className="btn_area">
                                <Button
                                    className="cancelBtn"
                                    type="button"
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="submitBtn"
                                    type="button"
                                    onClick={() => handleSubmit()}
                                    disabled={loader}
                                >
                                    Submit
                                </Button>
                            </div>

                        </Col>
                    </Row>
                </div>
            </div>
            {/* </Container> */}
        </div >
    );
};

export default AddHospital;
