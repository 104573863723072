import { useEffect } from "react";
import { useState } from "react";



const ListFilterFunctions = (props) => {
    const defaultFilters = {
        sortByAlpha: null,
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [SortByList, setSortByListStatusList] = useState([
        {
            title: "Ascending",
            value: "ASC",
            checked: false,
            id: 1,
        },
        {
            title: "Descending",
            value: "DESC",
            checked: false,
            id: 2,
        },
    ]);


    useEffect(() => {
        if (props && props.filterSortByValue) {
            reSetValuesOfSortByAlphaStatusList(props.filterSortByValue);
        }
    }, [props && props.filterSortByValue]);



    const reSetValuesOfSortByAlphaStatusList = (appliedStatus) => {
        let array = [...SortByList]
        if (appliedStatus) {
            const appliedSortByStatusIndex = array.findIndex((data) => parseInt(data.id) === parseInt(appliedStatus.id));
            if (appliedSortByStatusIndex !== -1) {
                array[appliedSortByStatusIndex].checked = true
            }
            setSortByListStatusList(array)
        }
    };


    const handleSortByFilters = (item) => {
        let array = [...SortByList]
        if (item) {
            for (let i in array) {
                array[i].checked = false
            }
            let index = array.findIndex((data) => parseInt(data.id) === parseInt(item.id));
            if (index !== -1) {
                if (array[index].checked === true) {
                    array[index].checked = false
                }
                else {
                    array[index].checked = true
                }
            }
            setSortByListStatusList(array);
            setFilters({
                ...filters,
                sortByAlpha: item
            })
        }
    };


    const applyFilters = () => {
        props.applyFilters(filters);
        props.close();
    };


    
    const handleClearAllFilters = () => {
        props.handleClearAllFilters();
        props.close();
    };


    return {
        setSortByListStatusList,
        SortByList,
        handleSortByFilters,
        setFilters,
        filters,
        applyFilters,
        handleClearAllFilters
    }
};

export default ListFilterFunctions;