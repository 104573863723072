import ActionType from "../constants/index";

const initialState = {
    hospital: {},
};

const HospitalReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionType.SET_HOSPITAL:
            return Object.assign({}, state, {
                hospital: action.payload,
            });
        default:
            return state;
    }
};

export default HospitalReducer;
