import {
    faBed,
    faCalendarWeek,
    faFileLines,
    faHouse,
    faVenus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, Image, Row } from "react-bootstrap";
import {
    Circle_Img,
    General,
    Icon_Circle,
    Man,
    Patient,
    siren,
} from "../../../../../assets/images";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import * as Constants from "../../../../Helper/constants/constants";
import LabReportDetailFunction from "./function";
import Loader from "../../../../Helper/loader";
import { checkTypeParam, dateFormat3, getName, getName3, getShortName, isEmptyArray, renderImage } from "../../../../Helper/General";


const ReportDetailLab = () => {
    const breadcrumData = ['Dashboard', 'Cardiology', 'Patient List'];
    const breadcrumItem = ['Report Detail'];
    const {
        labReportDetails,
        loader,
        location,
        getPatientLabreprtdetails,
        navigate
    } = LabReportDetailFunction()

    useEffect(() => {
        if (location && location.state && location.state.id) {
            getPatientLabreprtdetails(location && location.state && location.state.id);
        }
    }, []);
    return (
        <div className="report_detail_screen">
            <WithoutImageHeader
                header={Constants.REPORT_DETAIL_LAB}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                icon={'search_bell'}
                handleBack={() => navigate(-1)}
            />
            <>
                <Container>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}  >
                            {loader ? <Loader loader={loader} /> :
                                labReportDetails ?
                                    <div className="report_detail">
                                        <div className="main_detail">
                                            <div className="left_area">
                                                <div className="inner_left">
                                                    <div className="img_area" >
                                                        <Image
                                                            variant="left"
                                                            src={labReportDetails && labReportDetails.patient_details && labReportDetails.patient_details.image ? renderImage(labReportDetails.patient_details.image) : Man}
                                                            alt="..."
                                                        />
                                                    </div>
                                                </div>
                                                <div className="inner_right">
                                                    <div className="name" >
                                                        <h3 style={{ textTransform: 'capitalize' }} >
                                                            {labReportDetails && labReportDetails.patient_details && labReportDetails.patient_details.patient_name ? labReportDetails.patient_details.patient_name : null}
                                                        </h3>
                                                    </div>
                                                    <div className="gender">
                                                        <p>
                                                            <FontAwesomeIcon
                                                                icon={faVenus}
                                                                className="gender_icon"
                                                            />
                                                            {labReportDetails && labReportDetails.patient_details && labReportDetails.patient_details.gender ? labReportDetails.patient_details.gender : null}
                                                        </p>
                                                        <p>
                                                            <span>
                                                                {labReportDetails && labReportDetails.case_details && labReportDetails.case_details.ward_title ? labReportDetails.case_details.ward_title : null}
                                                            </span>
                                                        </p>

                                                        <p className="general">
                                                            <Image
                                                                src={General} alt="..." className="general_img"
                                                            />
                                                            {labReportDetails && labReportDetails.case_details && labReportDetails.case_details.patient_type ? labReportDetails.case_details.patient_type : null}
                                                        </p>
                                                    </div>
                                                    <div className="date">

                                                        <p>
                                                            <FontAwesomeIcon icon={faCalendarWeek} className="date_icon" />
                                                            {labReportDetails && labReportDetails.patient_details && labReportDetails.patient_details.created ? dateFormat3(labReportDetails.patient_details.created) : null}
                                                        </p>


                                                        <p className="bed_space">
                                                            <FontAwesomeIcon icon={faBed} className="date_icon ps-4" /> Bed #{labReportDetails && labReportDetails.case_details && labReportDetails.case_details.bed_number ? labReportDetails.case_details.bed_number : 0}
                                                        </p>

                                                        <p>
                                                            <FontAwesomeIcon icon={faHouse} className="date_icon ps-4" /> Room #{labReportDetails && labReportDetails.case_details && labReportDetails.case_details.room_number ? labReportDetails.case_details.room_number : 0}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right_area">
                                                <div className="id_area">
                                                    <p>
                                                        UHID :{labReportDetails && labReportDetails.patient_details && labReportDetails.patient_details.uh_id ? labReportDetails.patient_details.uh_id : null}
                                                    </p>
                                                </div>
                                                {/* <div className="batch">
                                            <p class="discharge">Discharge</p>
                                        </div> */}
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="none" id="dropdown-basic">
                                                        {labReportDetails && labReportDetails.patient_details && labReportDetails.patient_details.patient_status === "under_treatment" ?
                                                            <div className="batch orange_bg">
                                                                <p class="warning">Under Treatment</p>
                                                            </div> :
                                                            labReportDetails && labReportDetails.patient_details && labReportDetails.patient_details.patient_status === "critical" ?
                                                                <div className="batch danger">
                                                                    <p class="danger">Critical</p>
                                                                </div>
                                                                : labReportDetails && labReportDetails.patient_details && labReportDetails.patient_details.patient_status === "admitted" ?
                                                                    <div className="batch success">
                                                                        <p class="success">Admitted</p>
                                                                    </div>
                                                                    : labReportDetails && labReportDetails.patient_details && labReportDetails.patient_details.patient_status === "discharge" ?
                                                                        <div className="batch discharges">
                                                                            <p class="discharge">Discharged</p>
                                                                        </div> : null}
                                                    </Dropdown.Toggle>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className="info_detail_area">
                                            <div className="top_inner_area">
                                                <div className="text_type">Test Type : {labReportDetails && labReportDetails.test_type_title}</div>
                                                <div className="text_type">Test Name : {labReportDetails && labReportDetails.test_title}</div>
                                            </div>


                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="bold">Test Name</td>
                                                        <td className="bold">Results</td>
                                                        <td className="bold">Range (units)</td>
                                                    </tr>
                                                    {checkTypeParam(labReportDetails && labReportDetails.test_details) ?
                                                        <tr>
                                                            <td className="bold">{labReportDetails && labReportDetails.test_title ? labReportDetails.test_title : '-'}</td>
                                                            <td>{labReportDetails && labReportDetails.test_details} {labReportDetails && labReportDetails.lab_test_range ? `(${labReportDetails.lab_test_units})` : '-'}</td>
                                                            <td>{labReportDetails && labReportDetails.lab_test_range ? `${labReportDetails.lab_test_range} (${labReportDetails.lab_test_units})` : '-'}</td>
                                                        </tr> :
                                                        <>
                                                            {labReportDetails && labReportDetails.test_details.map((item, index) => (
                                                                <>
                                                                    {parseInt(item.is_test_sub_category) === 0 ? <tr>
                                                                        <td className="bold">{item.title}</td>
                                                                        <td>{item.test_value ? item.test_value : '-'} {item.test_value && item.test_units ? `(${item.test_units})` : ''}</td>
                                                                        <td>{item.test_range ? item.test_range : '-'} {item.test_units ? `(${item.test_units})` : ''}</td>
                                                                    </tr> : null}
                                                                    {parseInt(item.is_test_sub_category) == 1 ? <tr>
                                                                        <td colSpan="3" style={{ fontWeight: '600', fontSize: '16px', paddingLeft: '10px' }}>{item.title}
                                                                            <table className="border_remove">
                                                                                <tbody>
                                                                                    {item.is_test_sub_category_arr &&item.is_test_sub_category_arr.map((sitem, i) => (<tr key={i}>
                                                                                        <td style={{ fontWeight: '400', paddingTop: '10px' }}>{sitem.title}</td>
                                                                                        <td style={{ fontWeight: 'normal' }}>{sitem.test_value ? sitem.test_value : '-'} {sitem.test_value && sitem.test_units ? `(${sitem.test_units})` : ''}</td>
                                                                                        <td style={{ fontWeight: 'normal' }}>{sitem.test_range}  {sitem.test_units ? `(${sitem.test_units})` : ''}</td>
                                                                                    </tr>))}
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr> : null}
                                                                </>))}
                                                        </>

                                                    }
                                                </tbody>
                                            </table>



                                            {/* {checkTypeParam(labReportDetails && labReportDetails.test_details) ?
                                                <div className="bottom_area">
                                                    <div className="inner_info_area">
                                                        <div className="left_area">
                                                            <p>Range</p>
                                                        </div>
                                                        <div className="right_area">
                                                            <div className="text_area">
                                                                <span>:</span>
                                                                <p>{labReportDetails && labReportDetails.test_details}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <>
                                                    {labReportDetails && labReportDetails.test_details.map((item, index) => (<div className="bottom_area">
                                                        <div className="inner_info_area">
                                                            <div className="left_area">
                                                                <p>{item.title}</p>
                                                            </div>
                                                            {parseInt(item.is_test_sub_category) === 0 ? <div className="right_area">

                                                                <div className="text_area">
                                                                    <span>:</span>
                                                                    <p>{item.test_value}</p>
                                                                    <p>{' - '}</p>
                                                                    <p>{item.test_range} {item.test_units}</p>
                                                                </div>
                                                            </div> : null}
                                                        </div>
                                                        {parseInt(item.is_test_sub_category) == 1 ?
                                                            item.is_test_sub_category_arr.map((sitem, i) => (
                                                                <div className="inner_info_area">
                                                                    <div className="left_area">
                                                                        <p>{sitem.title} ({item.title})</p>
                                                                    </div>
                                                                    <div className="right_area">

                                                                        <div className="text_area">
                                                                            <span>:</span>
                                                                            <p>{sitem.test_value}</p>
                                                                            <p>{' - '}</p>
                                                                            <p>{sitem.test_range} {sitem.test_units}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )) : null}
                                                    </div>))}
                                                </>
                                            } */}
                                            <div className="circle_img">
                                                <Image src={Circle_Img} alt="..." />
                                            </div>
                                        </div>
                                        {labReportDetails && !isEmptyArray(labReportDetails.image) ? <div className="report_file_area">
                                            <div className="title_area">
                                                <p>Report Files</p>
                                            </div>
                                            <div className="main_files_area">
                                                {labReportDetails && labReportDetails.image.map((item, index) => (
                                                    <div key={index} className="inner_file_area" onClick={() => navigate('/Viewfile', { state: item })}>
                                                        <div className="file_upload">
                                                            <FontAwesomeIcon icon={faFileLines} className="icon_area" />
                                                            <p>{getShortName(item)}</p>
                                                        </div>
                                                    </div>))}
                                            </div>
                                        </div> : null}
                                    </div> : null}
                        </Col>
                    </Row>
                </Container>
            </>
        </div>
    );
};

export default ReportDetailLab;
