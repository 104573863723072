import React, { useEffect } from "react";
import { Button, Card, Col, Container, Form, Image, Row, Table } from "react-bootstrap";
import {
    faBed,
    faFaceSmile, faHouse, faTriangleExclamation, faVenus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Calendar, CalendarBlack, Card1, Green_Shape, Patient, Task1, User1, User2 } from "../../../../assets/images";
import { dateFormat, renderImage, timeFormat, timeFormat3, timeInAgoFormat } from "../../../Helper/General";
import * as Constants from "../../../Helper/constants/constants";
import WithoutImageHeader from "../../../Helper/withoutImageHeader";
import AssignedMemberModal from "../../Modals/AssignedMember";
import TaskDetailFunctions from "./functions";
import DeleteModal from '../../Modals/DeleteModal/index'
import moment from "moment";
import Loader from "../../../Helper/loader";


const MyTaskDetails = () => {
    const {
        getModulePermissions,
        checkPermissions,
        getTaskDetail,
        taskData,
        loader,
        loaction,
        navigate,
        values,
        setValues,
        isError,
        handleSubmitComment,
        handleChange,
        defaultValues,
        handleTaskCompleted,
        showAssignedModal,
        setShowAssignedModal,
        completeConfirmationModal,
        setcompleteConfirmationModal,
        breadcrumData,
        breadcrumItem,
        handleBreadcrumDataNaviagtion,
        handleAssignByNavigation,
        setBreadcrumData
    } = TaskDetailFunctions();


    useEffect(() => {
        let userdata = localStorage.getItem('SET_USER_DATA');
        const User = JSON.parse(userdata);
        if (User && parseInt(User.user_type) === 2) {
            getModulePermissions();
        }
        if (loaction && loaction.state && loaction.state.id) {
            if (loaction && loaction.state && loaction.state.name === 'subAdminTaskList') {
                let data = ["Dashboard", "My Tasks"]
                setBreadcrumData(data);
            }
            getTaskDetail(loaction.state.id);
            setValues(defaultValues);
        }
        else {
            navigate(-1);
        }
    }, []);

    let userdata = localStorage.getItem('SET_USER_DATA');
    const User = JSON.parse(userdata);


    return (
        <div className="task_detail_section">
            <WithoutImageHeader
                header={Constants.MY_TASKS_DETAIL}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBack={() => navigate(-1)}
                handleBreadcrumDataNaviagtion={(i, item) => handleBreadcrumDataNaviagtion(i, item)}
            />
            <Container>
                <Row>
                    {
                        loader ?
                            <Loader
                                loader={loader}
                            />
                            :
                            taskData ?
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className="task_detail_area">
                                        <div className="date_num_area">
                                            <div className="left_date_area">
                                                {/* <p><FontAwesomeIcon icon={faCalendarMinus} className="date_icon" />13 Aug 2023</p> */}
                                                <div className="date_img">
                                                    <Image
                                                        src={CalendarBlack}
                                                        alt="..."
                                                    />
                                                </div>
                                                <p>{taskData.created ? dateFormat(taskData.created) : ''}</p>
                                            </div>
                                            <div className="right_num_area">
                                                {
                                                    taskData.case_id ?
                                                        <p>MR : #{taskData.case_id}</p>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        <div className="inner_profile_area">
                                            <div className="left_profile_area">
                                                <div className="img_area">
                                                    <Image
                                                        variant="left"
                                                        src={Patient}
                                                        alt="..."
                                                    />
                                                </div>
                                            </div>
                                            {
                                                taskData ?
                                                    <div className="right_profile_area">
                                                        <div className="info_main">
                                                            <div className="left_area">
                                                                <h3 style={{ textTransform: 'capitalize' }}>{taskData.patient_name ? taskData.patient_name : ''}</h3>
                                                                <div className="gender_area">
                                                                    <p><FontAwesomeIcon icon={faVenus} className="gen_icon" />{taskData.gender ? taskData.gender : ""}</p>
                                                                    {taskData.cases && taskData.cases.length > 0 ?
                                                                        <span>
                                                                            {taskData.cases[0].ward_title ? taskData.cases[0].ward_title : ''}
                                                                        </span>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                                <div className="icons_area">
                                                                    <p><Image src={Calendar} alt="..." className="icon_calender" />{taskData.dob ? dateFormat(taskData.dob) : ''}</p>
                                                                    {
                                                                        taskData.cases && taskData.cases.length > 0 ?
                                                                            <>
                                                                                <p><FontAwesomeIcon icon={faBed} className="icon_one" />{taskData.cases[0].bed_number ? `Bed #${taskData.cases[0].bed_number}` : ''}</p>
                                                                                <p><FontAwesomeIcon icon={faHouse} className="icon_one" />{taskData.cases[0].room_number ? `Room #${taskData.cases[0].room_number}` : ''}</p>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="right_area">
                                                                <div className="text">
                                                                    <div className="p_handle">
                                                                        {
                                                                            taskData.uh_id ?
                                                                                <p>UHID : {taskData.uh_id}</p> : null
                                                                        }
                                                                    </div>
                                                                    <div className="s_handle">
                                                                        <span className={taskData && parseInt(taskData.task_status) === 1 ? "complete" : "pending"}>{taskData && parseInt(taskData.task_status) === 1 ? "Completed" : 'Pending'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="patient_information">
                                            <Card>
                                                <Card.Body>
                                                    <div className="left_area">
                                                        <Table>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        taskData && taskData.title ?
                                                                            <div className="details">
                                                                                <h5>Task Title</h5>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        taskData && taskData.title ?
                                                                            <div className="text_con">
                                                                                <span>:</span><h5 style={{ textTransform: 'capitalize' }}><b>{taskData.title}</b></h5>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        taskData.cases && taskData.cases.length > 0 ?
                                                                            <div className="details">
                                                                                <h5>Ward</h5>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        taskData.cases && taskData.cases.length > 0 ?
                                                                            <div className="text_con">
                                                                                <span>:</span><p>{taskData.cases[0].ward_title}</p>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        taskData.assign_by ?
                                                                            <div className="details">
                                                                                <h5>Assigned by</h5>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        taskData.assign_by ?
                                                                            <div className="text_con" style={{ cursor: 'pointer' }} onClick={() => handleAssignByNavigation()}>
                                                                                <span>:</span>
                                                                                {
                                                                                    taskData && parseInt(taskData.user_type) === 2 ?
                                                                                        <h5 className="space_txt"><u style={{ textTransform: 'capitalize' }}>{taskData.assign_by}</u></h5>
                                                                                        :
                                                                                        <h5><u style={{ textTransform: 'capitalize' }}>{taskData.assign_by}</u></h5>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        taskData.assigned_members && taskData.assigned_members.length > 0 ?
                                                                            <div className="details">
                                                                                <h5>Assigned To</h5>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        taskData.assigned_members && taskData.assigned_members.length > 0 ?
                                                                            <div className="text_con" onClick={() => setShowAssignedModal(true)}>
                                                                                <span>:</span>
                                                                                <h5 className="space_txt" style={{ textTransform: 'capitalize' }}>{taskData && taskData.assigned_members && taskData.assigned_members.length > 1 ? `${taskData.assigned_members[0].name} +${taskData.assigned_members.length - 1} More` : taskData.assigned_members[0].name}</h5>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        taskData.priority ?
                                                                            <div className="details">
                                                                                <h5>Priority</h5>
                                                                            </div>
                                                                            :
                                                                            null

                                                                    }
                                                                </td>
                                                                <td>
                                                                    <div className="text_con">
                                                                        <span>:</span>
                                                                        {
                                                                            taskData.priority && parseInt(taskData.priority) === 1 ?
                                                                                <div>
                                                                                    <a className="low" type="button">Low</a>
                                                                                </div>
                                                                                :
                                                                                taskData.priority && parseInt(taskData.priority) === 2 ?
                                                                                    <div>
                                                                                        <a className="medium" type="button">Medium</a>
                                                                                    </div>
                                                                                    :
                                                                                    taskData.priority && parseInt(taskData.priority) === 3 ?
                                                                                        <div>
                                                                                            <a className="high" type="button">High</a>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        taskData.start_date ?
                                                                            <div className="details">
                                                                                <h5>Task Date</h5>
                                                                            </div>
                                                                            :
                                                                            null

                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        taskData.start_date ?
                                                                            <div className="text_con">
                                                                                <span>:</span>
                                                                                <h5>{dateFormat(taskData.start_date)}</h5>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        taskData.start_time ?
                                                                            <div className="details">
                                                                                <h5>Time allotted</h5>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        taskData.start_time ?
                                                                            <div className="text_con">
                                                                                <span>:</span>
                                                                                {taskData.end_time ? <h5>{timeFormat3(taskData.start_time)} to {timeFormat3(taskData.end_time)}</h5> :
                                                                                    <h5>{timeFormat3(taskData.start_time)}</h5>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        parseInt(taskData.task_status) === 1 ?
                                                                            <div className="details">
                                                                                <h5>Completed on</h5>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        parseInt(taskData.task_status) === 1 ?
                                                                            <div className="text_con">
                                                                                <span>:</span>
                                                                                <h5>{`${dateFormat(taskData.completed_on)}  ${timeFormat(taskData.completed_on)}`}</h5>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        taskData.completed_by ?
                                                                            <div className="details without_space">
                                                                                <h5>Completed By</h5>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        taskData && taskData.completed_by ?
                                                                            <div className="text_con without_space" style={{ cursor: 'pointer' }} >
                                                                                <span>:</span>
                                                                                <h5
                                                                                    onClick={() => navigate(`/staff-detail/${taskData.completed_by_id}`, { state: { fromNavigation: true } })}
                                                                                ><u>{taskData.completed_by}</u></h5>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </Table>
                                                    </div>
                                                    {
                                                        taskData && taskData.assigned_members && taskData.assigned_members.length > 0 ?
                                                            <div className="right_area">
                                                                <div className="inner_details" onClick={() => setShowAssignedModal(true)}>
                                                                    <div className="img_one">
                                                                        <Image src={Patient} alt="..." />
                                                                    </div>
                                                                    {
                                                                        taskData.assigned_members.length > 1 ?
                                                                            <div className="img_two">
                                                                                <Image src={Patient} alt="..." />
                                                                            </div>
                                                                            :
                                                                            null

                                                                    }
                                                                    {
                                                                        taskData.assigned_members.length === 3 ?
                                                                            <div className="img_one">
                                                                                <Image src={Patient} alt="..." />
                                                                            </div>
                                                                            :
                                                                            taskData.assigned_members.length > 3 ?
                                                                                <div className="img_three_area">
                                                                                    <div className="details" >
                                                                                        <h6>{taskData.assigned_members.length > 3 ? `3 +` : ""}</h6>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                null
                                                                    }
                                                                </div>
                                                                {
                                                                    taskData && taskData.patient_status === 'discharge' ?
                                                                        null
                                                                        :
                                                                        User && parseInt(User.user_type) === 1 && parseInt(taskData.task_status) === 0 ?
                                                                            <div className="edit_area">
                                                                                <a type="button" onClick={() => navigate('/assignTask', { state: { name: 'EditTask', id: taskData.id } })}>Edit Details</a>
                                                                            </div>
                                                                            :
                                                                            checkPermissions
                                                                                && checkPermissions.permissions
                                                                                && checkPermissions.permissions.length > 2
                                                                                && parseInt(checkPermissions.permissions[2].value) === 1 ?
                                                                                parseInt(taskData.task_status) === 0 ?
                                                                                    <div className="edit_area">
                                                                                        <a type="button" onClick={() => navigate('/assignTask', { state: { name: 'EditTask', id: taskData.id } })}>Edit Details</a>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                                : null}
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </Card.Body>
                                            </Card>
                                            {
                                                taskData.description ?
                                                    <Card className="description_card">
                                                        <Card.Body>
                                                            <div className="description_area">
                                                                <h5>Description</h5>
                                                                <p>{taskData.description}</p>
                                                            </div>
                                                            {/* {
                                                                parseInt(taskData.task_status) === 0 ?
                                                                    <div className="description_edit">
                                                                        <a type="button" onClick={() => navigate('/assignTask', { state: { name: 'EditTask', id: taskData.id } })}>Edit Details</a>
                                                                    </div>
                                                                    :
                                                                    null

                                                            } */}
                                                        </Card.Body>
                                                    </Card>
                                                    :
                                                    null
                                            }
                                        </div>

                                        <div className="bottom_area">
                                            {
                                                parseInt(taskData.task_status) === 0 ?
                                                    <a type="button" onClick={() => setcompleteConfirmationModal(true)}>Task Complete</a>
                                                    :
                                                    null
                                            }
                                            <Card>
                                                <Card.Body>
                                                    <Form onClick={(e) => { e.preventDefault(); handleSubmitComment() }}>
                                                        <div className="comment_area">
                                                            <h6>Comment</h6>
                                                            <div className="count">
                                                                <span>{taskData && taskData.comments ? taskData.comments.length : ''}</span>
                                                            </div>
                                                        </div>
                                                        <div className="send_comment">
                                                            <Form.Group className="form-group">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Type your comment here"
                                                                    value={values.comment}
                                                                    className={isError.comment.message
                                                                        ? "error_border"
                                                                        : ""}
                                                                    onChange={(e) => handleChange("comment", e.target.value)}
                                                                />
                                                                {isError.comment.message ? (
                                                                    <p className="text-danger mt-2">
                                                                        {isError.comment.message}
                                                                    </p>
                                                                ) : null}
                                                                {/* <FontAwesomeIcon icon={faFaceSmile} className="emoji_icon" /> */}
                                                            </Form.Group>
                                                            <Button
                                                                type="submit"
                                                            // className="btn btn-primary"
                                                            // onClick={() => handleSubmitComment()}
                                                            >
                                                                Send</Button>
                                                        </div>
                                                    </Form>
                                                    {
                                                        taskData && taskData.comments ?
                                                            taskData.comments.map((item, i) => (
                                                                <div className="comments" key={i}>
                                                                    <div className="comment_details">
                                                                        <div className="image_one">
                                                                            <Image
                                                                                src={item && item.image ? renderImage(item.image) : Patient}
                                                                                alt="..."
                                                                            />
                                                                        </div>
                                                                        <h6 onClick={() => navigate(`/staff-detail/${item.created_by}`, { state: { fromNavigation: true } })} style={{ cursor: 'pointer' }}>{item.from_name ? item.from_name : ''}</h6>
                                                                        <p>{item.created ? timeInAgoFormat(item.created) : ''}</p>
                                                                    </div>
                                                                    <p>{item.comment ? item.comment : ''}</p>
                                                                </div>
                                                            ))
                                                            :
                                                            null
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                                :
                                null
                    }
                </Row>
            </Container>
            {
                showAssignedModal && taskData && taskData.assigned_members && taskData.assigned_members.length > 0 ? (
                    <AssignedMemberModal
                        show={showAssignedModal}
                        navigate={navigate}
                        assignedMembers={taskData && taskData.assigned_members}
                        close={() => setShowAssignedModal(false)}
                    />
                ) : null
            }
            {
                completeConfirmationModal ?
                    <DeleteModal
                        show={completeConfirmationModal}
                        handleSubmit={() => handleTaskCompleted()}
                        close={() => setcompleteConfirmationModal(false)}
                        message={'Do you want to mark this task as completed?'}
                        icon={faTriangleExclamation}
                        background={"green_circle"}
                        image={Green_Shape}
                    />
                    :
                    null
            }
        </div >
    );
};

export default MyTaskDetails;
