import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthController from "../../../../apis/Controllers/AuthController/AuthController";
import { Toaster } from '../../../Helper/toaster/Toaster';
import Validation from "../../../Helper/validations/vaildation";
import adminController from "../../../../apis/Controllers/AdminControler/admin.controller";
import { setUserPermissions } from "../../../../redux/actions/users";
import store from "../../../../redux/store";

const LoginFunctions = () => {
    const defaultValues = {
        email: "",
        password: "",
    };

    const defaultErrors = {
        email: {
            rules: ["required", "email"],
            isValid: true,
            message: "",
        },
        password: {
            rules: ["required", "password"],
            isValid: true,
            message: "",
        },
    };

    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);
    const [loader, setLoader] = useState(false);
    const [isVisiblePassword, setVisiblePassword] = useState(false);
    let navigate = useNavigate();


    //handleChange and Validation on each field ------
    const handleChange = (field, value) => {
        let validation = new Validation(isError);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };


    //check full form validation---------------------
    const handleLogin = async () => {
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            setLoader(true);
            let response = await AuthController.loginDetails(values);
            if (response && response.status) {
                setLoader(false);
                AuthController.setUpLogin(response && response.user);
                new Toaster().success(response.message);
                if (parseInt(response && response.user && response.user.user_type) === 1) {
                    navigate("/dashboard-superadmin");
                } else if (parseInt(response && response.user && response.user.user_type) === 2) {
                    getUserPermissions(response && response.user.id);
                    navigate("/dashboard");
                }
            } else {
                setLoader(false);
            }
        } else {
            setError({ ...isValid.errors });
        }
    };


    const getUserPermissions = async (id) => {
        let response = await adminController.adminDetail(id);
        if (response && response.status) {
            let data = response.data.permissions
            await localStorage.setItem('SET_USER_PERMISSION', JSON.stringify(data));
            // await store.dispatch(setUserPermissions(data));
        } else return;
    };


    return {
        values,
        isError,
        handleChange,
        handleLogin,
        loader,
        setLoader,
        isVisiblePassword,
        setVisiblePassword,
        navigate
    };
};

export default LoginFunctions;
