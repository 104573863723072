import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Helper/footer";
import Header from "../Helper/header";

const LayoutOne = ({ children }) => {
  return (
    <div>
      {<Outlet />}
    </div>
  );
}

const NoHeaderFooter = ({ children }) => {
  return (
    <div>
      {<Outlet />}
    </div>
  );
}

const LayoutTwo = ({ children }) => {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export { LayoutOne, LayoutTwo, NoHeaderFooter };
