import { faEllipsisVertical, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect } from "react";
import { Button, Col, Container, Dropdown, Form, Image, Row, Table } from "react-bootstrap";
import { Delete_2, Edit_2, Eye_2 } from "../../../../../assets/images";
import { dateFormat, dateFormat2, timeFormat2 } from "../../../../Helper/General";
import * as Constants from "../../../../Helper/constants/constants";
import Loader from "../../../../Helper/loader";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import NoDataComponent from "../../../CommanComponents/NoDataComponent";
import PaginationComponent from "../../../CommanComponents/Pagination";
import RolesFunctions from "./functions";
import DeleteModal from "../../../Modals/DeleteModal";
import { useState } from "react";
import store from "../../../../../redux/store";
import { setDrawer } from "../../../../../redux/actions/users";
import { debounce } from "lodash";

const RoleList = () => {
    const [deleteItem, setDeleteItem] = useState(null);
    const {
        checkPermissions,
        getModulePermissions,
        searchInput,
        setSearchInput,
        showFilterModal,
        setShowFilterModal,
        getRolesList,
        rolesList,
        handleDeleteAdmine,
        page,
        setPage,
        totalPages,
        handleBackClick,
        handleNextClick,
        navigate,
        breadcrumData,
        breadcrumItem,
        loader,
        handlePage,
        handleBreadcrumDataNaviagtion,
        deletePopup,
        setDeletePopup,
        count,
        keyword,
        setSearch,
        User,
        itemsPerPage,
        handleItemPerPage
    } = RolesFunctions();


    useEffect(() => {
        if (!loader) {
            let userdata = localStorage.getItem('SET_USER_DATA');
            let User = JSON.parse(userdata);
            if (User && parseInt(User.user_type) === 2) {
                getModulePermissions();
            }
            getRolesList('', 1, itemsPerPage);
        }
    }, []);


    const handleDelete = (item) => {
        setDeleteItem(item)
        setDeletePopup(true)
    };


    const handleDrawer = async (e) => {
        await store.dispatch(setDrawer(false));
    };

    const search = useCallback(debounce(getRolesList, 1000), []);
    return (
        <div className="role_list_screen">
            <WithoutImageHeader
                header={Constants.ROLE_LIST}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                lefticon='menu'
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
            />
            <Container onClick={() => handleDrawer()}>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        {/* {
                            loader ?
                                <Loader
                                    loader={loader}
                                />
                                : */}
                        <div className="role_list_details">
                            <div className="title_btn_area">
                                <div className="left_area">
                                    <p>Total Roles: {count ? count : 0}</p>
                                </div>
                                {
                                    User && parseInt(User.user_type) === 1 ?
                                        <div className="right_area">
                                            <a className="btn btn-primary" onClick={() => navigate('/role')}>Add Role</a>
                                        </div>
                                        :
                                        checkPermissions
                                            && checkPermissions.permissions
                                            && checkPermissions.permissions.length > 1
                                            && parseInt(checkPermissions.permissions[1].value) === 1 ?
                                            <div className="right_area">
                                                <a className="btn btn-primary" onClick={() => navigate('/role')}>Add Role</a>
                                            </div>
                                            :
                                            null
                                }
                            </div>
                            <div className="search_section">
                                <div className="search_area">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Control
                                                type="text"
                                                placeholder="Search by role name here"
                                                autoComplete="none"
                                                value={keyword}
                                                onChange={(e) =>
                                                (
                                                    search(e.target.value, 1, itemsPerPage),
                                                    setSearch(e.target.value))
                                                }
                                            />
                                            <a >
                                                <div className="icon">
                                                    <FontAwesomeIcon icon={faSearch} />
                                                </div>
                                            </a>
                                        </Form.Group>
                                    </Form>
                                    {/* <div className="pagination_list">
                                        <Form>
                                            <Form.Group className="form-group">
                                                <Form.Select
                                                    value={itemsPerPage}
                                                    onChange={(e) => handleItemPerPage(e)}
                                                >
                                                    <option value={5}>5</option>
                                                    <option value={10}>10</option>
                                                    <option value={15}>15</option>
                                                    <option value={20}>20</option>
                                                    <option value={25}>25</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Form>
                                    </div> */}
                                </div>
                                <div className="pagination_list">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Select
                                                value={itemsPerPage}
                                                onChange={(e) => handleItemPerPage(e)}
                                            >
                                                <option value={5}>5</option>
                                                <option value={10}>10</option>
                                                <option value={15}>15</option>
                                                <option value={20}>20</option>
                                                <option value={25}>25</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Form>
                                </div>
                                {/* <div className="filter_button">
                                    <Button className="btn-primary"
                                        onClick={() => setShowFilterModal(true)}>
                                        <Image src={Filters} alt="..." className="image_area" />Filters</Button>
                                </div> */}
                            </div>
                            {loader ?
                                <Loader
                                    loader={loader}
                                />
                                : <>
                                    {
                                        rolesList && rolesList.length > 0 ?
                                            <div className="list_detail">
                                                <div className="list_data">
                                                    <Table responsive>
                                                        <tr>
                                                            <th className="role_text">Role</th>
                                                            <th>Is Super Admin</th>
                                                            <th>Created On</th>
                                                            <th>Action</th>
                                                        </tr>
                                                        {
                                                            rolesList.map((item, i) => {
                                                                return (
                                                                    parseInt(item.status) === 1 ?
                                                                        <tr key={i}>
                                                                            <td className="data_table">
                                                                                <h4 style={{ cursor: 'pointer' }} onClick={() => navigate('/view-role', { state: { name: item.title, id: item.id } })}>{item.title}</h4>
                                                                                {/* <div className="left_area">
                                                                            <div className="img_area">
                                                                                <Image src={AssignImg} alt="..." />
                                                                            </div>
                                                                        </div>
                                                                        <div className="right_areaa">
                                                                            <h4>Robert William</h4>
                                                                            <p><span>Consultant</span></p>
                                                                        </div> */}
                                                                            </td>
                                                                            <td >
                                                                                {
                                                                                    item.user_type == 1 ?
                                                                                        <div className="badge_yes">
                                                                                            <p>Yes</p>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="badge_no">
                                                                                            <p>No</p>
                                                                                        </div>

                                                                                }
                                                                            </td>
                                                                            {
                                                                                item.created ?
                                                                                    <td className="text">
                                                                                        {dateFormat(item.created)}{' '}{timeFormat2(item.created)}
                                                                                    </td>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            <td>
                                                                                <div className="icon">
                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle variant="none" id="dropdown-basic">
                                                                                            <FontAwesomeIcon icon={faEllipsisVertical} className="ellipsis_icon" />
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>
                                                                                            <Dropdown.Item onClick={() => navigate('/view-role', { state: { name: item.title, id: item.id } })}>View <Image src={Eye_2} alt="..." className="image_area" /></Dropdown.Item>
                                                                                            {
                                                                                                User && parseInt(User.user_type) === 1 ?
                                                                                                    <Dropdown.Item onClick={() => navigate('/role', { state: { name: 'EditScreen', id: item.id } })}>
                                                                                                        Edit
                                                                                                        <Image src={Edit_2} alt="..." className="image_area" />
                                                                                                    </Dropdown.Item>
                                                                                                    :
                                                                                                    checkPermissions
                                                                                                        && checkPermissions.permissions
                                                                                                        && checkPermissions.permissions.length > 2
                                                                                                        && parseInt(checkPermissions.permissions[2].value) === 1 ?
                                                                                                        <Dropdown.Item onClick={() => navigate('/role', { state: { name: 'EditScreen', id: item.id } })}>
                                                                                                            Edit
                                                                                                            <Image src={Edit_2} alt="..." className="image_area" />
                                                                                                        </Dropdown.Item>
                                                                                                        :
                                                                                                        null
                                                                                            }
                                                                                            {
                                                                                                User && parseInt(User.user_type) === 1 ?
                                                                                                    <Dropdown.Item onClick={() => handleDelete(item)}>Delete
                                                                                                        <Image src={Delete_2} alt="..." className="image_area delete_icon " />
                                                                                                    </Dropdown.Item>
                                                                                                    :
                                                                                                    checkPermissions
                                                                                                        && checkPermissions.permissions
                                                                                                        && checkPermissions.permissions.length > 3
                                                                                                        && parseInt(checkPermissions.permissions[3].value) === 1 ?
                                                                                                        <Dropdown.Item onClick={() => handleDelete(item)}>Delete
                                                                                                            <Image src={Delete_2} alt="..." className="image_area delete_icon " />
                                                                                                        </Dropdown.Item>
                                                                                                        :
                                                                                                        null
                                                                                            }
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        :
                                                                        null
                                                                )
                                                            })
                                                        }
                                                    </Table>
                                                </div>
                                            </div>
                                            :
                                            <NoDataComponent
                                                message="No role founds!"
                                            />

                                    }</>}


                        </div>
                        {/* } */}
                    </Col>
                </Row>
            </Container>
            {
                !loader && rolesList && rolesList.length > 0 ?
                    <PaginationComponent
                        page={page}
                        setPage={handlePage}
                        totalPages={totalPages}
                        handleBackClick={handleBackClick}
                        handleNextClick={handleNextClick}
                    />
                    :
                    null
            }
            {
                deletePopup ?
                    <DeleteModal
                        show={deletePopup}
                        handleSubmit={() => handleDeleteAdmine(deleteItem)}
                        close={() => setDeletePopup(false)}
                        message={'Do you want to delete this role?'}
                        loader={loader}
                    />
                    :
                    null
            }
        </div>

    );
};

export default RoleList;

