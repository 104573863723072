import { useState } from "react";
import permissionsController from "../../../../../apis/Controllers/PermissionsController/permissions.contoller";
import Validation from "../../../../Helper/validations/vaildation";
import rolesController from "../../../../../apis/Controllers/RolesController/roles.controller";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../../redux/store";
import hospitalController from "../../../../../apis/Controllers/HospitalController/hospital.controller";
import wardController from "../../../../../apis/Controllers/WardController/ward.controller";

const AddWardFunctions = () => {
    var breadcrumData = ["Dashboard", "Ward list"];
    const [loader, setLoader] = useState(false);
    const [breadcrumItem, setbreadcrumItem] = useState("Add Ward");
    const [hospitalsListing, setHospitalsListing] = useState([]);
    const loaction = useLocation();
    const navigate = useNavigate();
    const defaultValues = {
        hospital_arr: null,
        ward_title: null,
        // ward_number: null,
    };

    const defaultErrors = {
        hospital_arr: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        ward_title: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        // ward_number: {
        //     rules: ["required", "numeric"],
        //     isValid: true,
        //     message: "",
        // },
    };

    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);

    const handleChange = (field, value) => {
        let validation = new Validation(isError);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };

    const getHospitalListing = async () => {
        if (!loader) {
            let post = {
                search: "",
                page: 1,
            };
            setLoader(true);
            let response = await hospitalController.hospitalListing(post);
            if (response && response.status) {
                let array1 = response.data;
                for (let i in array1) {
                    array1[i]["label"] = array1[i].title;
                }
                setHospitalsListing(array1);
                if (!loaction.state) {                               //<--------------auto select hospital case on the base of global seleted Hospital
                    let data = localStorage.getItem('SET_HOSPITAL');
                    let item = JSON.parse(data);
                    let hospitalData = {
                        title: item && item.title ? item.title : '',
                        id: item && item.id ? item.id : '',
                        label: item && item.title ? item.title : ''
                    }
                    setValues({
                        ...values,
                        hospital_arr: item ? hospitalData : null
                    })
                }
                setLoader(false);
            } else {
                setHospitalsListing([]);
                setLoader(false);
            }
            setLoader(false);
        }
    };

    const submitWard = async () => {
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            setLoader(true);
            if (loaction && loaction.state && loaction.state.name) {
                let id =
                    loaction && loaction.state && loaction.state.id
                        ? loaction.state.id
                        : null;
                let response = await wardController.wardUpdate(id, values);
                if (response && response.status) {
                    new Toaster().success(response.message);
                    navigate('/wards', { replace: true });
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            } else {
                let response = await wardController.wardCreate(values);
                if (response && response.status) {
                    new Toaster().success(response.message);
                    navigate('/wards', { replace: true });
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            }
        } else {
            setError({ ...isValid.errors });
        }
    };

    const getWardDetail = async (id) => {
        let response = await wardController.wardDetail(id);
        if (response && response.status) {
            let data = response.data;
            setLoader(false);
            setValues({
                ...values,
                ward_title: data.title ? data.title : null,
                // ward_number: data && data.ward_number ? data.ward_number : null,
                hospital_arr:
                    data && data.hospitals && data.hospitals[0]
                        ? {
                            id: data.hospitals[0].hospital_id,
                            label: data.hospitals[0].hospital_name,
                        }
                        : [],
            });
        } else {
            setLoader(false);
        }
    };

    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard", { replace: true });
            } else {
                navigate("/dashboard-superadmin", { replace: true });
            }
        } else if (i === 1) {
            navigate("/wards", { replace: true });
        } else return;
    };

    return {
        getHospitalListing,
        breadcrumData,
        breadcrumItem,
        values,
        isError,
        hospitalsListing,
        loader,
        handleChange,
        submitWard,
        loaction,
        setbreadcrumItem,
        getWardDetail,
        handleBreadcrumDataNaviagtion,
    };
};

export default AddWardFunctions;
