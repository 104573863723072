import { Carousel, Card, Stack, Button, Image } from "react-bootstrap";
import { AssignImg, Man } from "../../assets/images";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



export default function ControlledCarousel(props) {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4, // Adjust the number of items per slide as needed
        slidesToScroll: 1,
        // arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 567,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 0,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            }
          ]
        // draggable: true,
    };

    return (
        <div className="scroller_main">
            <div className="select_area_owl">
                <Slider {...settings}>
                    {props.list.map((item, i) => (
                        <div key={i} className="user-slide">
                            <div className="user-image-container">
                                <img className="user-image"
                                    src={Man}
                                    alt="User"
                                />
                            </div>
                            <div className="user-details">
                                <h5>{item.name}</h5>
                                <p>{item.role_title}</p>
                            </div>
                        </div>))}
                </Slider>
            </div>
        </div>
    );
}
