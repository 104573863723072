import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthController from "../../../../apis/Controllers/AuthController/AuthController";
import { Toaster } from "../../../Helper/toaster/Toaster";
import Validation from "../../../Helper/validations/vaildation";



const EmailVerificationFunctions = () => {
    let defaultValues = {
        otp: null,
    };
    let defaultErrors = {
        otp: {
            rules: ['required'],
            isValid: true,
            message: '',
        },
    }
    const [values, setValues] = useState(defaultValues)
    const [error, setError] = useState(defaultErrors);
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate();
    const { state } = useLocation();


    const handleChange = (field, value) => {
        let validation = new Validation(error);
        let node = validation.validateField(field, value);
        setError({ ...error, [field]: node });
        setValues({ ...values, [field]: value });
    };


    const handleSubmit = async () => {
        let token = state && state.token ? state.token : ''
        let validation = new Validation(error);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            setLoader(true);
            let response = await AuthController.otpVerifiication(values.otp, token);
            if (response && response.status) {
                new Toaster().success(response.message);
                navigate("/reset-password",  { state: state });
                setLoader(false);
            } else {
                setLoader(false);
            }
        } else {
            setError({ ...isValid.errors });
        }
    };


    return {
        values,
        error,
        loader,
        setLoader,
        handleChange,
        handleSubmit,
    };
};

export default EmailVerificationFunctions;
