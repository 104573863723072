import { faCircle, faEllipsisVertical, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect } from "react";
import { Button, Card, Col, Container, Dropdown, Form, Image, Row, Table } from "react-bootstrap";
import { AssignImg, Delete_2, Edit_2, Eye_2, Filters, Hospital_Img, Man } from "../../../../../assets/images";
import * as Constants from "../../../../Helper/constants/constants";
import FiltersHeader from "../../../../Helper/filtersHeader";
import NoDataComponent from "../../../CommanComponents/NoDataComponent";
import PaginationComponent from "../../../CommanComponents/Pagination";
import FilterModal from "../../../Modals/FilterModal";
import TaskListFunctions from "./functions";
import Loader from "../../../../Helper/loader";
import DeleteModal from "../../../Modals/DeleteModal";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import store from "../../../../../redux/store";
import { setDrawer } from "../../../../../redux/actions/users";
import { renderImage } from "../../../../Helper/General";
import { debounce } from "lodash";


const TaskList = () => {
    const {
        getModulePermissions,
        checkPermissions,
        searchInput,
        handleChange,
        taskListing,
        navigate,
        handleBackClick,
        handleNextClick,
        handleDeleteTask,
        page,
        totalPages,
        itemsPerPage,
        showFilterModal,
        setShowFilterModal,
        breadcrumData,
        breadcrumItem,
        handlePage,
        handleItemPerPage,
        loader,
        handleBreadcrumDataNaviagtion,
        deletConfirmationModal,
        setDeletConfirmationModal,
        setDeletItemDetail,
        applyFilters,
        filtersAppliedSign,
        handleClearAllFilters,
        setReduxValue,
        totalCount,
        setTotalCount,
        setSearchInput,
        keyword,
        setSearch,
        getTaskListing,
        breadcrumData1,
        taskCompletedByAdmin,
    } = TaskListFunctions();
    let storedFilters = store.getState().TaskListFilters.filters;
    let userdata = localStorage.getItem('SET_USER_DATA');
    let User = JSON.parse(userdata);


    useEffect(() => {
        let userdata = localStorage.getItem('SET_USER_DATA');
        let User = JSON.parse(userdata);
        if (User && parseInt(User.user_type) === 2) {
            getModulePermissions();
        }
        else;
        setSearchInput('');
    }, []);


    const handleDrawer = async (e) => {
        await store.dispatch(setDrawer(false));
    };

    const search = useCallback(debounce(getTaskListing, 1000), []);
    return (
        <div className="task_list_screen">
            <WithoutImageHeader
                header={Constants.TASK_LIST}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
                lefticon='menu'
                setReduxvalue={(e) => setReduxValue(e)}
            />
            <Container onClick={() => handleDrawer()}>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="task_list_details">
                            <div className="title_btn_area">
                                <div className="left_area">
                                    <p>Total Tasks: {totalCount ? totalCount : 0}</p>
                                </div>
                                {
                                    User && parseInt(User.user_type) === 1 ?
                                        <div className="right_area">
                                            <a className="btn" type="submit" onClick={() => navigate('/assignTask')}>
                                                Add Task
                                            </a>
                                        </div>
                                        :
                                        checkPermissions
                                            && checkPermissions.permissions
                                            && checkPermissions.permissions.length > 1
                                            && parseInt(checkPermissions.permissions[1].value) === 1 ?
                                            <div className="right_area">
                                                <a className="btn" type="submit" onClick={() => navigate('/assignTask')}>
                                                    Add Task
                                                </a>
                                            </div>
                                            :
                                            null
                                }
                            </div>
                            <div className="search_section">
                                <div className="search_area">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Control
                                                type="text"
                                                placeholder="Search by task title here"
                                                value={keyword}
                                                onChange={(e) =>
                                                (
                                                    search(e.target.value, 1, itemsPerPage, storedFilters),
                                                    setSearch(e.target.value))
                                                }
                                            />
                                            <a>
                                                <div className="icon">
                                                    <FontAwesomeIcon icon={faSearch} />
                                                </div>
                                            </a>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div className="pagination_list">
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Select
                                                value={itemsPerPage}
                                                onChange={(e) => handleItemPerPage(e)}
                                            >
                                                <option value={5}>5</option>
                                                <option value={10}>10</option>
                                                <option value={15}>15</option>
                                                <option value={20}>20</option>
                                                <option value={25}>25</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div className="filter_button">
                                    <Button className={filtersAppliedSign ? "btn-primary" : 'btn-border'} onClick={() => setShowFilterModal(true)}>
                                        <Image src={Filters} alt="..." className="image_area" />
                                        Filters</Button>
                                    {
                                        filtersAppliedSign ?
                                            <FontAwesomeIcon icon={faCircle} className="filter_icon" />
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            {
                                loader ?
                                    <Loader
                                        loader={loader}
                                    />
                                    :
                                    taskListing && taskListing.length > 0 ?
                                        <div className="list_detail">
                                            <div className="list_data">
                                                <Card>
                                                    <Card.Body>
                                                        <div className="table-responsive">
                                                            <Table responsive>
                                                                <tr>
                                                                    <th className="cursor_pointer">Hospital Name</th>
                                                                    <th className="cursor_pointer">Patient Name</th>
                                                                    <th className="width_s">Task Title</th>
                                                                    <th className="cursor_pointer">Assigned by</th>
                                                                    <th>Task Priority</th>
                                                                    <th>Status</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                                {
                                                                    taskListing.map((item, i) => {
                                                                        return (
                                                                            parseInt(item.status) === 1 ?
                                                                                <tr key={i}>
                                                                                    <td>
                                                                                        <div className="profile_info">
                                                                                            <div className="left_area">
                                                                                                <div className="img_profile">
                                                                                                    <Image
                                                                                                        onClick={() => navigate(`/hospital-detail/${item && item.hospital_id}`,
                                                                                                            {
                                                                                                                state: {
                                                                                                                    breadcrumData1: breadcrumData1,
                                                                                                                    fromNavigation: true
                                                                                                                }
                                                                                                            })}
                                                                                                        src={item && item.hospital_image ? renderImage(item.hospital_image) : Hospital_Img} alt="..." />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="right_area">
                                                                                                <p
                                                                                                    style={{ cursor: 'pointer' }}
                                                                                                    onClick={() => navigate(`/hospital-detail/${item && item.hospital_id}`,
                                                                                                        {
                                                                                                            state: {
                                                                                                                breadcrumData1: breadcrumData1,
                                                                                                                fromNavigation: true
                                                                                                            }
                                                                                                        })}
                                                                                                >{item && item.hospital_name ? item.hospital_name : null}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td >
                                                                                        <div className="data_table">
                                                                                            <div className="left_area">
                                                                                                <div className="img_area">
                                                                                                    <Image
                                                                                                        src={item && item.image ? renderImage(item.image) : Man}
                                                                                                        alt="..."
                                                                                                        onClick={() => navigate(`/patientDetails/${item.patient_id}`, { state: { name: item.patient_name, name1: "tasklist", breadcrumData1: breadcrumData1, fromNavigation: true } })}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="right_areaa">
                                                                                                <a
                                                                                                    onClick={() => navigate(`/patientDetails/${item.patient_id}`, { state: { name: item.patient_name, name1: "tasklist", breadcrumData1: breadcrumData1, fromNavigation: true } })}>{item.patient_name ? item.patient_name : ''}</a>
                                                                                                <p style={{ textTransform: 'uppercase' }}><span className="designation">{item.ward_name ? item.ward_name : ''}</span></p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="title width_s" ><a onClick={() => navigate('/myTaskDetails', { state: { id: item.id } })}>{item.title ? item.title : ''}</a></td>
                                                                                    <td >
                                                                                        <div className="data_table" onClick={() => navigate("/staff-detail", { state: { name: item.assign_by, id: item.created_by, fromNavigation: true } })}>
                                                                                            <div className="left_area">
                                                                                                <div className="img_area">
                                                                                                    <Image
                                                                                                        src={item && item.assign_by_image ? renderImage(item.assign_by_image) : Man}
                                                                                                        alt="..."
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="right_areaa" >
                                                                                                {
                                                                                                    item.assign_by ?
                                                                                                        <a>{item.assign_by}</a>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                                {
                                                                                                    item.role_title ?
                                                                                                        <p><span className="consultant">{item.role_title}</span></p>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td >
                                                                                        {
                                                                                            item.priority && parseInt(item.priority) === 1 ?
                                                                                                <div className="badge_medium">
                                                                                                    <p>Low</p>
                                                                                                </div>
                                                                                                :
                                                                                                item.priority && parseInt(item.priority) === 2 ?
                                                                                                    <div className="badge_low">
                                                                                                        <p>Medium</p>
                                                                                                    </div>
                                                                                                    :
                                                                                                    item.priority && parseInt(item.priority) === 3 ?
                                                                                                        <div className="badge_high">
                                                                                                            <p>High</p>
                                                                                                        </div>
                                                                                                        :
                                                                                                        null
                                                                                        }
                                                                                    </td>
                                                                                    <td >
                                                                                        <Dropdown>
                                                                                            <Dropdown.Toggle
                                                                                                variant="none"
                                                                                                id="dropdown-basic"
                                                                                            >
                                                                                                {
                                                                                                    parseInt(item.task_status) === 0 ?
                                                                                                        <div className="bagde_pending">
                                                                                                            <p>Pending</p>
                                                                                                        </div>
                                                                                                        :
                                                                                                        parseInt(item.task_status) === 1 ?
                                                                                                            <div className="badge_complete">
                                                                                                                <p>Completed</p>
                                                                                                            </div>
                                                                                                            :
                                                                                                            null
                                                                                                }
                                                                                            </Dropdown.Toggle>
                                                                                            {parseInt(item.task_status) === 0 ?
                                                                                                <Dropdown.Menu>
                                                                                                    <Dropdown.Item
                                                                                                        onClick={() => taskCompletedByAdmin(item)}
                                                                                                    >
                                                                                                        Completed
                                                                                                    </Dropdown.Item>
                                                                                                </Dropdown.Menu>
                                                                                                : null}
                                                                                        </Dropdown>

                                                                                    </td>
                                                                                    <td>
                                                                                        {

                                                                                            <div className="icon">
                                                                                                <Dropdown>
                                                                                                    <Dropdown.Toggle variant="none" id="dropdown-basic">
                                                                                                        <FontAwesomeIcon icon={faEllipsisVertical} className="ellipsis_icon" />
                                                                                                    </Dropdown.Toggle>
                                                                                                    <Dropdown.Menu>
                                                                                                        <Dropdown.Item onClick={() => navigate('/myTaskDetails', { state: { id: item.id } })}>
                                                                                                            View
                                                                                                            <Image src={Eye_2} alt="..." className="image_area" />
                                                                                                        </Dropdown.Item>
                                                                                                        {
                                                                                                            User && parseInt(User.user_type) === 1 && parseInt(item.task_status) === 0 && item.patient_status !== 'discharge' ?
                                                                                                                <Dropdown.Item onClick={() => navigate('/assignTask', { state: { name: 'EditTask', id: item.id } })}>
                                                                                                                    Edit
                                                                                                                    <Image src={Edit_2} alt="..." className="image_area" />
                                                                                                                </Dropdown.Item>
                                                                                                                :
                                                                                                                checkPermissions
                                                                                                                    && checkPermissions.permissions
                                                                                                                    && checkPermissions.permissions.length > 2
                                                                                                                    && parseInt(checkPermissions.permissions[2].value) === 1 && item.patient_status !== 'discharge' ?
                                                                                                                    parseInt(item.task_status) === 0 ?
                                                                                                                        <Dropdown.Item onClick={() => navigate('/assignTask', { state: { name: 'EditTask', id: item.id } })}>
                                                                                                                            Edit
                                                                                                                            <Image src={Edit_2} alt="..." className="image_area" />
                                                                                                                        </Dropdown.Item> : null
                                                                                                                    :
                                                                                                                    null

                                                                                                        }
                                                                                                        {
                                                                                                            User && parseInt(User.user_type) === 1 ?
                                                                                                                <Dropdown.Item onClick={() => (setDeletConfirmationModal(true), setDeletItemDetail(item))}>
                                                                                                                    Delete
                                                                                                                    <Image src={Delete_2} alt="..." className="image_area delete_icon " />
                                                                                                                </Dropdown.Item>
                                                                                                                :
                                                                                                                checkPermissions
                                                                                                                    && checkPermissions.permissions
                                                                                                                    && checkPermissions.permissions.length > 3
                                                                                                                    && parseInt(checkPermissions.permissions[3].value) === 1 ?
                                                                                                                    <Dropdown.Item onClick={() => (setDeletConfirmationModal(true), setDeletItemDetail(item))}>
                                                                                                                        Delete
                                                                                                                        <Image src={Delete_2} alt="..." className="image_area delete_icon " />
                                                                                                                    </Dropdown.Item>
                                                                                                                    :
                                                                                                                    null
                                                                                                        }
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown>
                                                                                            </div>
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                                :
                                                                                null
                                                                        )
                                                                    })
                                                                }
                                                            </Table>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            {!loader ? <NoDataComponent
                                                message={'No Task Found'}
                                            /> : null}
                                        </>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            {
                !loader && taskListing && taskListing.length > 0 ?
                    <PaginationComponent
                        page={page}
                        setPage={handlePage}
                        totalPages={totalPages}
                        handleBackClick={handleBackClick}
                        handleNextClick={handleNextClick}
                    />
                    :
                    null
            }
            {
                showFilterModal ? (
                    <FilterModal
                        show={showFilterModal}
                        close={() => setShowFilterModal(false)}
                        applyFilters={applyFilters}
                        handleClearAllFilters={handleClearAllFilters}
                    />
                ) : null
            }
            {
                deletConfirmationModal ?
                    <DeleteModal
                        show={deletConfirmationModal}
                        handleSubmit={() => handleDeleteTask()}
                        close={() => setDeletConfirmationModal(false)}
                        message={'Do you want to delete this task ?'}
                        icon={null}
                        loader={loader}
                    />
                    :
                    null
            }
        </div >
    );
};

export default TaskList;

