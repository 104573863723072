import { useState } from "react";
import rolesController from "../../../../../apis/Controllers/RolesController/roles.controller";
import { useLocation, useNavigate } from "react-router-dom";
import permissionsController from "../../../../../apis/Controllers/PermissionsController/permissions.contoller";
import store from "../../../../../redux/store";



const RoleDetailFunctions = () => {
    var breadcrumData = ["Dashboard", "Role list"];
    const [breadcrumItem, setbreadcrumItem] = useState("");

    const [loader, setLoader] = useState(false);
    const [roleDetailPermissions, setRoleDetailPermissions] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [superAdmin, setSuperAdmin] = useState(false);
    const [permissonsArrayList, setPermissionArray] = useState([]);
    const loaction = useLocation();
    const navigate = useNavigate();
    const defaultValues = {
        role: "",
        is_super_admin: ""
    };
    const [values, setValues] = useState(defaultValues);


    const getPermissionsListing = async () => {
        setLoader(true);
        let response = await permissionsController.permissionsListing();
        if (response && response.status) {
            let list = response.data
            setPermissionArray(list);
            setLoader(false);
        }
        else {
            setPermissionArray([]);
            setLoader(false);
        }
        setLoader(false);
    };


    const getRoleDetail = async (id) => {
        let array = [...permissonsArrayList]
        let response = await rolesController.roleDetail(id);
        if (response && response.status) {
            let data = response.data.permissions
            const notExistModule = array.filter(({ id: roleId }) => !data.some(({ id: dataId }) => roleId === dataId));
            if (notExistModule) {
                for (let i in notExistModule) {
                    notExistModule[i].permissions.map((item) => {
                        item.value = 0
                    })
                }
                let newAray = data.concat(notExistModule);
                setRoleDetailPermissions(newAray);
                let status = isAllCheckedSelected(newAray);
                setSelectAll((status && parseInt(response && response.data && response.data.user_type) === 2) ? true : false);
                setSuperAdmin((status && parseInt(response && response.data && response.data.user_type) === 1) ? true : false);
            }
            else {
                setRoleDetailPermissions(data);
                let status = isAllCheckedSelected(data);
                // setSelectAll((status && parseInt(response && response.data && response.data.user_type) === 2) ? true : false);
                // setSuperAdmin((status && parseInt(response && response.data && response.data.user_type) === 1) ? true : false);
            }
            setValues({
                ...values,
                "role": response.data.title ? response.data.title : '',
                "is_super_admin": parseInt(response && response.data && response.data.user_type) === 1 ? 1 : 0
            });
        }
        else {
            setRoleDetailPermissions(null);
        }
    };



    const isAllCheckedSelected = (list) => {                       // <---------------------For handle "all-Select" and "SuperAdmin" checkbox
        let allChecked = false;
        for (let i in list) {
            for (let j in list[i].permissions) {
                if (parseInt(list[i].permissions[j].value) === 0) {
                    allChecked = false;
                    return false;
                }
                else {
                    allChecked = true;
                }
            }
        }
        return allChecked;
    };


    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate('/dashboard');
            }
            else {
                navigate('/dashboard-superadmin');
            }
        }
        else if (i === 1) {
            navigate('/role-list');
        }
        else return;
    };


    return {
        values,
        setValues,
        loader,
        breadcrumItem,
        setbreadcrumItem,
        getRoleDetail,
        roleDetailPermissions,
        breadcrumData,
        loaction,
        navigate,
        selectAll,
        superAdmin,
        getPermissionsListing,
        permissonsArrayList,
        handleBreadcrumDataNaviagtion
    }
};

export default RoleDetailFunctions;