import { useEffect, useState } from "react";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../../redux/store";
import patientController from "../../../../../apis/Controllers/PatientController/patient.controller";
import { filtersController } from "../../../../../apis/Controllers/CommonController/filter.controller";
import permissionsController from "../../../../../apis/Controllers/PermissionsController/permissions.contoller";
import { setDrawer } from "../../../../../redux/actions/users";


const PatientListingFunctions = () => {
    let storedFilters = store.getState().PatientListFilters.filters;

    var breadcrumData = ['Dashboard'];
    const [breadcrumItem, setBreadcrumItem] = useState(['Patient List']);

    const [showFilterModal, setShowFilterModal] = useState(false);
    const [checkPermissions, setCheckPermissions] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [patientListing, setPatientListing] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [patientdetail, setPatientDetail] = useState(null);
    const [loader, setLoader] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    const [filtersAppliedSign, setFiltersAppliedSign] = useState(false);
    const [checkStoredvalueFromRedux, SetCheckStoredValueFromRedux] = useState(null);
    const [keyword, setSearch] = useState(null);
    // -------------------PAZINATION ----------------------
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [totalPages, setTotalPages] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    //-----------------------------------------------------
    let navigate = useNavigate();
    const loaction = useLocation();
    const [allFiltersValues, setAllFiltersValues] = useState(false);

    const [patientStatus, setPatientStatus] = useState([
        { id: 'admitted', value: 'Admitted' },
        { id: 'mmx', value: 'MMX' },
        { id: 'on_ventilator', value: 'On Ventilator' },
        { id: 'on_tpi', value: 'On TPI' },
        { id: 'mi', value: 'MI' },
        { id: '72hrs', value: '72hrs' },
        { id: 'discharge', value: 'Discharged' },
    ]);

    let userdata = localStorage.getItem('SET_USER_DATA');
    let User = JSON.parse(userdata);


    const getModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('user');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckPermissions(data);
        }
        else {
            setCheckPermissions(null);
        }
    };

    useEffect(() => {
        if (storedFilters && checkStoredvalueFromRedux, allFiltersValues) {
            if (
                (storedFilters &&
                    storedFilters.patientStatus &&
                    storedFilters.patientStatus.length > 0) ||
                (storedFilters &&
                    storedFilters.wards &&
                    storedFilters.wards.length > 0) ||
                (storedFilters &&
                    storedFilters.hospitals &&
                    storedFilters.hospitals.length > 0) ||
                (storedFilters && storedFilters.admissionFromDate) ||
                (storedFilters && storedFilters.admissionToDate)
            ) {
                setFiltersAppliedSign(true);
                getPatientListing("", page, itemsPerPage, '');
            } else {
                setFiltersAppliedSign(false);
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                getPatientListing("", page, itemsPerPage, '', item);
            }
        }
    }, [storedFilters, checkStoredvalueFromRedux, allFiltersValues]);


    useEffect(() => {
        if (storedFilters && checkStoredvalueFromRedux) {
            if ((storedFilters &&
                storedFilters.hospitals &&
                storedFilters.hospitals.length > 0)
            ) {
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                handleDashboardHospitalwithFiltersAppliedCase(item, storedFilters.hospitals);
            } else {
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                if (item) {
                    handleDashboardHospitalwithFilters(item);
                }
            }
        }
    }, [checkStoredvalueFromRedux]);


    const handleDashboardHospitalwithFiltersAppliedCase = async (seletedHospitalData, AppliedFilterHospitals) => {
        let applied = AppliedFilterHospitals
        if (applied.length > 1) {
            let index = applied.findIndex((id) => parseInt(id) === parseInt(seletedHospitalData.id))
            if (index === -1) {
                applied.push(seletedHospitalData.id);
            }
            // else {
            //     return applied
            // }
        }
        else {
            applied = []
            applied.push(seletedHospitalData.id);
        }
        let filterNew = {
            patientStatus: storedFilters && storedFilters.patientStatus ? storedFilters.patientStatus : [],
            wards: storedFilters && storedFilters.wards && storedFilters.wards.length > 0 ? storedFilters.wards : [],
            hospitals: applied,
            admissionFromDate: storedFilters && storedFilters.admissionFromDate ? storedFilters.admissionFromDate : '',
            admissionToDate: storedFilters && storedFilters.admissionToDate ? storedFilters.admissionToDate : ''
        };
        await filtersController.setPatientListFilter(filterNew);
        await setAllFiltersValues(true);
    };


    const handleDashboardHospitalwithFilters = async (seletedHospitalData) => {
        let filterNew = {
            patientStatus: storedFilters && storedFilters.patientStatus ? storedFilters.patientStatus : [],
            wards: storedFilters && storedFilters.wards && storedFilters.wards.length > 0 ? storedFilters.wards : [],
            hospitals: seletedHospitalData && seletedHospitalData.id ? [seletedHospitalData.id] : [],
            admissionFromDate: storedFilters && storedFilters.admissionFromDate ? storedFilters.admissionFromDate : '',
            admissionToDate: storedFilters && storedFilters.admissionToDate ? storedFilters.admissionToDate : ''
        };
        await filtersController.setPatientListFilter(filterNew);
        await setAllFiltersValues(true);
    };



    useEffect(() => {
        if (count) {
            handlePages(count);
        }
    }, [count, itemsPerPage]);


    const handlePages = (count) => {
        let array = []
        const data = parseInt(count);
        const items = parseInt(itemsPerPage);
        const totalPage = Math.ceil(data / items);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i)
        }
        setTotalPages(array);
    };


    const getPatientListing = async (e, page, itemPerPage, filters, seletedHospitalData) => {
        if (!loader) {
            let post = {
                search: e ? e : keyword ? keyword : '',
                page: page ? page : 1,
                limit: itemPerPage ? itemPerPage : 10,
                hospital_id: filters && filters.hospitals && filters.hospitals.length > 0 ? filters.hospitals : storedFilters && storedFilters.hospitals && storedFilters.hospitals.length > 0 ? storedFilters.hospitals : seletedHospitalData && seletedHospitalData.id ? [seletedHospitalData.id] : [],
                patient_status: filters && filters.patientStatus ? filters.patientStatus : storedFilters && storedFilters.patientStatus ? storedFilters.patientStatus : [],
                ward_id: filters && filters.wards ? filters.wards : storedFilters && storedFilters.wards ? storedFilters.wards : [],
                from_date: filters && filters.admissionFromDate ? filters.admissionFromDate : storedFilters && storedFilters.admissionFromDate ? storedFilters.admissionFromDate : '',
                to_date: filters && filters.admissionToDate ? filters.admissionToDate : storedFilters && storedFilters.admissionToDate ? storedFilters.admissionToDate : ''
            }
            setLoader(true);
            let response = await patientController.patientListing(post);
            if (response && response.status) {
                setPatientListing(response.data);
                setCount(response.count);
                setTotalCount(response.totalCount);
                setLoader(false);
            }
            else {
                setPatientListing([]);
                setTotalCount(null);
                setCount(null);
                setLoader(false);
            }
        }
    };

    const getExistingPatientListing = async (e, page, itemPerPage) => {
        if (!loader) {
            let data = localStorage.getItem('SET_HOSPITAL');
            let item = JSON.parse(data);
            let post = {
                search: e ? e : keyword ? keyword : '',
                page: page ? page : 1,
                limit: itemPerPage ? itemPerPage : 10,
                hospital_id: item ? [item && item.id] : [],
            }
            setLoader(true);
            let response = await patientController.existPatientListing(post);
            if (response && response.status) {
                setPatientListing(response.data);
                setCount(response.count);
                setTotalCount(response.totalCount);
                setLoader(false);
            }
            else {
                setPatientListing([]);
                setTotalCount(null);
                setCount(null);
                setLoader(false);
            }
        }
    };


    // const handleSearch = (e) => {
    //     let value = e.target.value
    //     setSearchInput(value);
    //     if (value.length === 0) {
    //         getPatientListing('', page, itemsPerPage);
    //     }
    //     else {
    //         let array = [...patientListing];
    //         let data = array.filter(
    //             (item) =>
    //                 item.patient_name.toLowerCase().indexOf(value.toLowerCase()) > -1
    //         );
    //         setPatientListing(data);
    //     }
    // };

    const handleSearch = (e) => {
        let value = e.target.value
        setSearchInput(value);
        getPatientListing(value, page, itemsPerPage);
    };


    const handleDeletePatient = async () => {
        let data = patientdetail
        let array = [...patientListing]
        let id = data && data.id ? data.id : '';
        setLoader(true);
        let response = await patientController.patientDelete(id);
        if (response && response.status) {
            setLoader(false);
            new Toaster().success(response.message);
            setDeletePopup(false)
            let filterData = array.filter((data) => data.id !== data.id);
            if (filterData.length > 0) {
                getPatientListing('', page, itemsPerPage);
            }
            else {
                if (page > 1) {
                    let newpage = page - 1
                    setPage(page - 1);
                    getPatientListing('', newpage, itemsPerPage);
                }
                else {
                    setPage(page);
                    getPatientListing('', page, itemsPerPage);
                }
            }
            setTotalPages([]);
        } else {
            setLoader(false);
        }
    };


    const handleBackClick = () => {                   //<------------PAZINATION
        if (page > 1) {
            let newPage = page - 1
            getPatientListing('', newPage, itemsPerPage);
            setPage(page - 1);
        }
        else return;
    };


    const handleNextClick = () => {                    //<------------PAZINATION
        if (page >= 1 && page < totalPages.length) {
            let newPage = page + 1
            getPatientListing('', newPage, itemsPerPage);
            setPage(page + 1);
        }
        else return;
    };


    const handlePage = (e) => {
        setPage(e);
        getPatientListing('', e, itemsPerPage);
    };


    const handleItemPerPage = (e) => {
        let value = e.target.value
        setItemsPerPage(value);
        getPatientListing('', page, value);
    };


    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate('/dashboard');
            }
            else {
                navigate('/dashboard-superadmin');
            }
        }
        else return;
    };


    const applyFilters = async (filter) => {
        let filters = {
            patientStatus: filter && filter.patientStatus ? filter.patientStatus : [],
            wards: filter && filter.wards ? filter.wards : [],
            hospitals: filter && filter.hospitals ? filter.hospitals : [],
            admissionFromDate: filter && filter.admissionFromDate ? filter.admissionFromDate : '',
            admissionToDate: filter && filter.admissionToDate ? filter.admissionToDate : ''
        };
        if (filter && filter.hospitals && filter.hospitals.length > 0) {
            await filtersController.setPatientListFilter(filters);
            setShowFilterModal(false);
            setFiltersAppliedSign(true);
        } else {
            new Toaster().error("Please select at least one hospital");
        }
    };

    const handleClearAllFilters = async () => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        await filtersController.clearPatientListFilters(item);
    };


    const setReduxValue = (e) => {          //<------------For confirmation that we have checked the localstorage value's and RESET the REDUX- FILTERS's Values and after it the listing api will hit
        SetCheckStoredValueFromRedux(e);
    };

    //---Change patient status------>
    const chnagePatientStatus = async (item, status) => {
        if (!loader) {
            let post = {
                patient_status: status ? status : '',
            }
            let ID = item && item.id ? item.id : null;
            setLoader(true);
            let response = await patientController.updatePatientStatus(ID, post);
            if (response && response.status) {
                setLoader(false);
                let array = [...patientListing];
                item.patient_status = status ? status : null;
                setPatientListing(array)
                new Toaster().success(response.message);
            }
            else {
                setLoader(false);
            };
        }
    };

    const handleDrawer = async (e) => {
        await store.dispatch(setDrawer(false));
    };

    return {
        getModulePermissions,
        checkPermissions,
        getPatientListing,
        patientListing,
        handleBackClick,
        handleNextClick,
        handleDeletePatient,
        handleSearch,
        page,
        setPage,
        totalPages,
        setTotalPages,
        itemsPerPage,
        setItemsPerPage,
        searchInput,
        setSearchInput,
        showFilterModal,
        setShowFilterModal,
        breadcrumData,
        breadcrumItem,
        navigate,
        loader,
        setLoader,
        handlePage,
        handleBreadcrumDataNaviagtion,
        deletePopup,
        setDeletePopup,
        handleItemPerPage,
        setPatientDetail,
        applyFilters,
        handleClearAllFilters,
        setReduxValue,
        filtersAppliedSign,
        keyword,
        setSearch,
        patientStatus,
        chnagePatientStatus,
        handleDrawer,
        totalCount,
        User,
        setBreadcrumItem,
        loaction,
        getExistingPatientListing,
    }
};

export default PatientListingFunctions;