import React, { useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
//-------- HEADER COMPONENT IMPORT ---------//
import { useNavigate } from "react-router-dom";
import Select, { components } from 'react-select';
import { Radiology } from "../../../../../assets/images";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
// import * as Constants from "../../../Helper/constants/constants";
import * as Constants from '../../../../Helper/constants/constants';
import AddRoomFunction from "./function";
import { useEffect } from "react";
import { isEmpty } from "../../../../Helper/General";
import CustomSelectOptions from "../../../../Helper/customSelectOption";


const AddRoom = () => {
    const navigate = useNavigate();
    const {
        breadcrumData,
        breadcrumItem,
        setbreadcrumItem,
        getHospitalListing,
        hospitalList,
        loader,
        values,
        setValues,
        isError,
        handleChange,
        wardList,
        getWardsListing,
        handleBreadcrumDataNaviagtion,
        submitRoom,
        getRoomDetails,
        loaction
    } = AddRoomFunction();


    useEffect(() => {
        if (!loader) {
            if (loaction && loaction.state && loaction.state.name) {
                let id =
                    loaction && loaction.state && loaction.state.id
                        ? loaction.state.id
                        : null;
                let hospital_Id = loaction && loaction.state && loaction.state.hospital_id
                    ? loaction.state.hospital_id
                    : null;
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                getWardsListing(item.id)
                getRoomDetails(id);
                setbreadcrumItem("Edit Room");
            }
            getHospitalListing();
        }
    }, []);


    return (
        <div className="add_case_section">
            <WithoutImageHeader
                header={loaction && loaction.state && loaction.state.name ? Constants.EDITROOM : Constants.ADDROOM}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBack={() => navigate(-1)}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
            />
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="case_area">
                            <div className="dropdown_box">
                                <h6>
                                    Hospital<span>*</span>
                                </h6>
                                <div className="dropdown_area">
                                    <Select
                                        placeholder="Select Hospital"
                                        options={hospitalList}
                                        value={values && values.hospitalId}
                                        onChange={(e) => {
                                            getWardsListing(e.id)
                                            handleChange("hospitalId", e);
                                            if (!isEmpty(values.wardId)) {
                                                setValues({
                                                    ...values,
                                                    wardId: null
                                                })
                                            }
                                        }}
                                        className={
                                            isError.hospitalId.message
                                                ? "error_border_select"
                                                : ""
                                        }
                                        components={{
                                            Option: props => (
                                                <CustomSelectOptions
                                                    items={props}
                                                    value={values && values.hospitalId}
                                                />
                                            )
                                        }}
                                    />
                                    {isError.hospitalId.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.hospitalId.message}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <div className="dropdown_box">
                                <h6>
                                    Ward<span>*</span>
                                </h6>
                                <div className="dropdown_area">
                                    <Select
                                        isDisabled={values && values.hospitalId && values.hospitalId.id ? false : true}
                                        placeholder="Select Ward"
                                        options={wardList}
                                        value={values && values.wardId}
                                        onChange={(e) => {
                                            handleChange("wardId", e);
                                        }}
                                        className={
                                            isError.wardId.message
                                                ? "error_border_select"
                                                : ""
                                        }
                                        components={{
                                            Option: props => (
                                                <CustomSelectOptions
                                                    items={props}
                                                    value={values && values.wardId}
                                                />
                                            )
                                        }}
                                    />
                                    {isError.wardId.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.wardId.message}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    Room Number<span>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter room number here"
                                    className={
                                        isError.roomNumber.message
                                            ? "error_border"
                                            : ""
                                    }
                                    value={values.roomNumber}
                                    onChange={(e) =>
                                        handleChange(
                                            "roomNumber",
                                            e.target.value
                                        )
                                    }
                                />
                                {isError.roomNumber.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.roomNumber.message}
                                    </p>
                                ) : null}
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    Available Beds<span>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter avaliable beds"
                                    className={
                                        isError.availableBeds.message
                                            ? "error_border"
                                            : ""
                                    }
                                    value={values.availableBeds}
                                    onChange={(e) =>
                                        handleChange(
                                            "availableBeds",
                                            e.target.value
                                        )
                                    }
                                />
                                {isError.availableBeds.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.availableBeds.message}
                                    </p>
                                ) : null}
                            </Form.Group>
                            <div className="btn_area">
                                <Button
                                    className="cancelBtn"
                                    type="button"
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="submitBtn"
                                    type="button"
                                    onClick={() => submitRoom()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddRoom;
