import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap";
import { AssignImg, Man } from "../../../../assets/images";

const SelectAssignTask = (props) => {


    useEffect(() => {
        props.checkAssignedMembersValue();
        props.checkAdminsListCheckedValue();
    }, []);


    const handleSelectAdmin = (item) => {
        props.handleSelectAdmin(item);
    };


    const handleModalSearch = (e) => {
        props.handleModalSearch(e);
    };


    const handleClearAllSelected = () => {
        props.clearAllSelectedAssignedTo();
        // props.close();
    };


    const handleApplyAssignedTo = () => {
        props.handleApplyAssignedTo();
        props.close();
    };


    const handleClose = () => {
        props.close();
    };


    return (
        <div>
            <Modal show={props.show} onHide={props.close} centered className="select_assign_task">
                <Modal.Header closeButton onClick={() => handleClose()}>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="select_assign_area">
                        <div className="top_area">
                            <div className="top_left">
                                <p>Assign to<span>*</span></p>
                            </div>
                            <div className="top_right">
                                <p>{props && props.selectedAssigntedTo ? props.selectedAssigntedTo.length : ''} Selected</p>
                            </div>
                        </div>
                        <Form>
                            <Row>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Form.Group className="form-group">
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Doctor or Nurse"
                                            defaultValue=""
                                            onChange={(e) => handleModalSearch(e)}
                                        />
                                        <a>
                                            <div className="search_icon">
                                                <FontAwesomeIcon icon={faSearch} />
                                            </div>
                                        </a>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                        <div className="profile_area">
                            {
                                props && props.selectedAssigntedTo && props.selectedAssigntedTo.length > 0 ?
                                    props.selectedAssigntedTo.map((item, i) => {
                                        return (
                                            <div className="inner_profile_area" key={i}>
                                                <div className="img_area">
                                                    <Image
                                                        src={Man}
                                                        alt="..."
                                                    />
                                                </div>
                                                <a href="javascript:;"><h6 style={{textTransform:'capitalize'}}>{item.name}</h6></a>
                                                <p>{item.role_title ? item.role_title : ''}</p>
                                            </div>
                                        )
                                    })
                                    :
                                    null
                            }
                            {/* <div className="inner_profile_area">
                                <div className="img_area">
                                    <Image src={AssignImg} alt="..." />
                                </div>
                                <h6>Robert William</h6>
                                <p>Medical Staff</p>
                            </div>
                            <div className="inner_profile_area">
                                <div className="img_area">
                                    <Image src={AssignImg} alt="..." />
                                </div>
                                <h6>Robert William</h6>
                                <p>Medical Staff</p>
                            </div>
                            <div className="inner_profile_area">
                                <div className="img_area">
                                    <Image src={AssignImg} alt="..." />
                                </div>
                                <h6>Robert William</h6>
                                <p>Medical Staff</p>
                            </div> */}
                        </div>
                        <div className="scroller_area">
                            {
                                props && props.adminList && props.adminList.length > 0 &&
                                props.adminList.filter((item) => (item.name.toLowerCase().indexOf(props && props.adminListSearch ? props.adminListSearch.toLowerCase() : '') > -1)).map((item, i) => {
                                    return (
                                        <div className="info_check_area" key={i}>
                                            <div className="left_area">
                                                <div className="img_main">
                                                    <Image src={Man} alt="..." />
                                                </div>
                                            </div>
                                            <div className="right_area">
                                                <div className="content_area">
                                                    <div className="left_text">
                                                        <a href="javascript:;"><h6 style={{textTransform:'capitalize'}}>{item.name}</h6></a>
                                                        <p>{item.role_title}</p>
                                                    </div>
                                                    <div className="right_check">
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="Admitted"
                                                            checked={item.checked}
                                                            onClick={() => handleSelectAdmin(item)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="btn_area">
                            <Button
                                className="cancelBtn"
                                type="button"
                                onClick={handleClearAllSelected}
                            >
                                Clear All</Button>
                            <Button
                                className="submitBtn"
                                type="submit"
                                onClick={() => handleApplyAssignedTo()}
                                disabled={parseInt(props && props.selectedAssigntedTo && props.selectedAssigntedTo.length) > 0 ? false : true}
                            >
                                Apply</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SelectAssignTask;