import { useEffect, useState } from "react";
import superAdminDashboardController from "../../../../../apis/Controllers/DashboardController/superAdminDashboardController";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import hospitalController from "../../../../../apis/Controllers/HospitalController/hospital.controller";
import { isEmptyObj } from "../../../../Helper/General";
import store from "../../../../../redux/store";
import { setHospital } from "../../../../../redux/actions/users";
import { isEmpty } from "lodash";
import patientController from "../../../../../apis/Controllers/PatientController/patient.controller";
import { useNavigate } from "react-router-dom";

const TodayAdmitPatientFunction = () => {
    const navigate = useNavigate();
    var breadcrumData = ["Dashboard"];
    const [breadcrumItem, setbreadcrumItem] = useState("Today Admit Patient");
    const [todayAdmitPatient, setTodayAdmitPatient] = useState([]);
    const [loader, setLoader] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    const getTodayAdmitListing = async () => {
        if (!loader) {
            setLoader(true);
            let data = localStorage.getItem('SET_HOSPITAL');
            let item = JSON.parse(data);
            let response = await patientController.todayPatientList(item && item.id);
            if (response && response.status) {
                setTodayAdmitPatient(response.data);
                setTotalCount(response.count);
                setLoader(false);
            } else {
                setTodayAdmitPatient([]);
                setLoader(false);
            }
            setLoader(false);
        }
    };

    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/dashboard-superadmin");
            }
        } else return;
    };


    return {
        getTodayAdmitListing,
        loader,
        todayAdmitPatient,
        breadcrumData,
        breadcrumItem,
        totalCount,
        navigate,
        handleBreadcrumDataNaviagtion
    };
};

export default TodayAdmitPatientFunction;
