

import React from 'react';
// import './style.css'; // Import your CSS styles here

const PaginationComponent = (props) => {
    const { totalPages, page } = props;
    const currentPageIndex = page - 1;
    const totalPageCount = totalPages ? totalPages.length : 0;
    const visiblePages = 3;

    let pageNumbers = [];

    if (totalPageCount <= visiblePages) {
        pageNumbers = totalPages ? totalPages : [];
    } else {
        const firstPage = Math.max(0, currentPageIndex - 1);
        const lastPage = Math.min(currentPageIndex + 1, totalPageCount - 1);

        if (firstPage === 0) {
            pageNumbers = totalPages.slice(0, visiblePages);
        } else if (lastPage === totalPageCount - 1) {
            pageNumbers = totalPages.slice(totalPageCount - visiblePages);
        } else {
            pageNumbers = totalPages.slice(firstPage, lastPage + 1);
        }
    }

    return (
        <div className='pagination_screen'>
            <ul className='pagination'>
                {currentPageIndex > 1 && (
                    <li className='pagination_buttons page-item'>
                        <a
                            className="page-link"
                            role="button"
                            tabIndex="0"
                            href="#"
                            onClick={() => props.setPage(currentPageIndex)}
                        >
                            <span aria-hidden="true">‹</span>
                            <span className="visually-hidden">Previous</span>
                        </a>
                    </li>
                )}
                {pageNumbers && pageNumbers.length > 0 ? <>
                    {pageNumbers.map((pageNumber) => (
                        <li
                            className={parseInt(props.page) === parseInt(pageNumber) ? 'page-item active' : 'page-item'}
                            onClick={() => (props.setPage ? props.setPage(pageNumber) : '')}
                            key={pageNumber}
                            style={{ cursor: 'pointer' }}
                        >
                            {
                                parseInt(props.page) === parseInt(pageNumber) ? <span class="page-link">{pageNumber}<span class="visually-hidden">(current)</span></span>
                                    :
                                    <a className={parseInt(props.page) === parseInt(pageNumber) ? 'page-link' : 'page-link'}>
                                        {pageNumber}
                                    </a>
                            }
                        </li>
                    ))}
                </> : null}
                {currentPageIndex < totalPageCount - 2 && (
                    <li className="pagination_buttons page-item">
                        <a
                            className="page-link"
                            role="button"
                            tabIndex="0"
                            href="#"
                            onClick={() => props.setPage(currentPageIndex + 2)}
                        >
                            <span aria-hidden="true">›</span>
                            <span className="visually-hidden">Next</span>
                        </a>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default PaginationComponent;
