import React from "react";
import { Spinner } from "react-bootstrap";
const Loader = (props) => {
    return (
        <>
            {props.loader ? (
                <Spinner
                    animation="border"
                    variant={props.variant ? props.variant : "danger"}
                />
            ) : null}
        </>
    );
};

export default Loader;
