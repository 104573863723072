import { useState } from "react";
import permissionsController from "../../../../../apis/Controllers/PermissionsController/permissions.contoller";
import Validation from "../../../../Helper/validations/vaildation";
import rolesController from "../../../../../apis/Controllers/RolesController/roles.controller";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../../redux/store";
import { all } from "axios";
import roomsController from "../../../../../apis/Controllers/RoomsController/rooms.controller";
import bedsController from "../../../../../apis/Controllers/BedsControoler/beds.controller";

const AddBedFunction = () => {
    var breadcrumData = ["Dashboard", "Bed list"];
    const [roomList, setRoomList] = useState([])
    const [loader, setLoader] = useState(false);
    const [breadcrumItem, setbreadcrumItem] = useState("Add Bed");
    const loaction = useLocation();
    const navigate = useNavigate();

    const defaultValues = {
        roomID: null,
        bedNumber: null,
    };

    const defaultErrors = {
        roomID: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        bedNumber: {
            rules: ["required", "numeric", "max:3"],
            isValid: true,
            message: "",
        },
    };

    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);

    const handleChange = (field, value) => {
        let validation = new Validation(isError);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };

    const getRoomsListing = async () => {
        if (!loader) {
            setLoader(true);
            let data = localStorage.getItem('SET_HOSPITAL');
            let item = JSON.parse(data);
            let post = {
                search: '',
                page: 1,
                hospital_id: item ? [item.id] : [],
            };
            let response = await roomsController.super_room_list_dropdown(post);
            if (response && response.status) {
                let data = response.data
                for (let i in data) {
                    data[i]['label'] = data[i].ward_name +" "+ "- Room " +' '+ data[i].room_number
                }
                setRoomList(data);
                setLoader(false);
            } else {
                setRoomList([]);
                setLoader(false);
            }
            setLoader(false);
        }
    };

    const getbedDetails = async (id) => {
        let response = await bedsController.bedsDetail(id);
        if (response && response.status) {
            setLoader(false);
            let data = response.data;
            setValues({
                ...values,
                roomID: data && data.room_number ? { label: data.room_number, id: data.room_id } : null,
                bedNumber: data && data.bed_number ? data.bed_number : null,
            })
        } else {
            setLoader(false);
        }
    }

    const submitBed = async () => {
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            setLoader(true);
            if (loaction && loaction.state && loaction.state.name) {
                let id =
                    loaction && loaction.state && loaction.state.id
                        ? loaction.state.id
                        : null;
                let response = await bedsController.bedsUpdate(id, values);
                if (response && response.status) {
                    new Toaster().success(response.message);
                    navigate('/bed-list');
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            } else {
                let response = await bedsController.bedsCreate(values);
                if (response && response.status) {
                    new Toaster().success(response.message);
                    navigate('/bed-list');
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            }
        } else {
            setError({ ...isValid.errors });
        }
    };


    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/dashboard-superadmin");
            }
        } else if (i === 1) {
            navigate("/bed-list");
        } else return;
    };



    return {
        values,
        isError,
        breadcrumData,
        breadcrumItem,
        setbreadcrumItem,
        loaction,
        handleChange,
        loader,
        handleBreadcrumDataNaviagtion,
        navigate,
        getRoomsListing,
        getRoomsListing,
        roomList,
        submitBed,
        getbedDetails
    };
};

export default AddBedFunction;
