import { isEmpty } from "lodash";
import { useState } from "react";
import superAdminDashboardController from "../../../../../apis/Controllers/DashboardController/superAdminDashboardController";
import hospitalController from "../../../../../apis/Controllers/HospitalController/hospital.controller";

const SuperAdminDashboardFunctions = () => {
    var breadcrumData1 = ["Dashboard"]
    var breadcrumItem1=['Discharged Patients List']
    const timeData = [
        {
            lebal: 'Today',
            value:'today'
        },
        {
            lebal: 'Yesterday',
            value:'yesterday'
        },
        {
            lebal: 'Last Week',
            value:'last_week'
        },
        {
            lebal: 'Last Month',
            value:'last_month'
        }
    ]
    const [hospitalDetails, setHospitalDetails] = useState({});
    const [patientStatus, setPatientStatus] = useState({});
    const [taskStatusCount, setTaskStatusCount] = useState(0);
    const [taskStatus, setTaskStatus] = useState({});
    const [patientAdmittedCount, setPatientAdmittedCount] = useState(0);
    const [todayAdmittedPatient, setTodayAdmittedPatient] = useState([]);
    const [hospitalsListing, setHospitalsListing] = useState([]);
    const [values , setValues]=useState(null)
    const [loader, setLoader] = useState(false);
    
    const getSuperAdminDAshboardDetails = async (item, data) => {
        let post = {
            date: data && data.value ? data.value : "today",
           }
        if (!loader) {
            setLoader(true);
            let response = await superAdminDashboardController.superAdminDashboard(item && item.id , post);
            if (response && response.status) {
                setHospitalDetails(response && response.data && response.data.hospital);
                setPatientStatus(response && response.data && response.data.patient_status);
                setTaskStatus(response && response.data && response.data.task_status);
                setPatientAdmittedCount(response && response.data && response.data.patients_admitted_today_count);
                setTodayAdmittedPatient(response && response.data && response.data.patients_admitted_today);
                setLoader(false);
            } else {
                setHospitalDetails({});
                setPatientStatus({});
                setTaskStatus({});
                setPatientAdmittedCount(0);
                setTodayAdmittedPatient([]);
                setLoader(false);
            }
        }

    };

    const getHospitalListing = async () => {
        if (!loader) {
            setLoader(true);
            let response = await hospitalController.hospitalListDashboard();
            if (response && response.status) {
                if (response.data && response.data.length > 0) {
                    let data = localStorage.getItem('SET_HOSPITAL');
                    setHospitalsListing(response.data);
                    setLoader(false);
                    if (isEmpty(data)) {
                        getSuperAdminDAshboardDetails(response.data && response.data[0]);
                        await hospitalController.setUpHospital(response.data && response.data[0]);
                    } else {
                        let item = JSON.parse(data);
                        getSuperAdminDAshboardDetails(item);
                    }
                } else {
                    setHospitalsListing([]);
                    setLoader(false);
                }
            } else {
                setHospitalsListing([]);
                setLoader(false);
            }
            setLoader(false);
        }
    };

    const data = {
        datasets: [
            {
                data: taskStatus && (taskStatus.completed_tasks > 0 || taskStatus.pending_tasks > 0) ? [
                    taskStatus && taskStatus.completed_tasks ? parseInt(taskStatus.completed_tasks) : 0,
                    taskStatus && taskStatus.pending_tasks ? parseInt(taskStatus.pending_tasks) : 0
                ] : [0, 0, 100],
                pointBackgroundColor: 'white',
                backgroundColor: (context) => {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    if (!chartArea) {
                        return null
                    }
                    if (context.dataIndex === 0) {
                        return getGradient(chart);
                    }
                    if (context.dataIndex === 1) {
                        return getGradient_two(chart);
                    }
                    else {
                        return getGradient_three(chart);
                    }
                }
            },
        ],
    };
    function getGradient(chart) {
        const { ctx, chartArea: { top, bottom, left, right } } = chart;
        const gradientSegment = ctx.createLinearGradient(left, 0, right, 0);
        gradientSegment.addColorStop(1, '#7367F0');
        gradientSegment.addColorStop(0.004, '#CE9FFC');
        gradientSegment.addColorStop(1, '#7367F0');
        return gradientSegment;
    }
    function getGradient_two(chart) {
        const { ctx, chartArea: { top, bottom, left, right } } = chart;
        const gradientSegment = ctx.createLinearGradient(left, 0, right, 0);
        gradientSegment.addColorStop(1, '#FA742B');
        gradientSegment.addColorStop(0.004, '#FFE985');
        gradientSegment.addColorStop(1, '#FA742B');
        return gradientSegment;
    }
    function getGradient_three(chart) {
        const { ctx, chartArea: { top, bottom, left, right } } = chart;
        const gradientSegment = ctx.createLinearGradient(left, 0, right, 0);
        gradientSegment.addColorStop(1, '#DFDFDE');
        gradientSegment.addColorStop(0.004, '#EBEBEB');
        gradientSegment.addColorStop(1, '#DFDFDE');
        return gradientSegment;
    }
    
    const handleTaskGraph = (item) => {
        setValues(item)
        let data = localStorage.getItem('SET_HOSPITAL');
        data =JSON.parse(data)
        getSuperAdminDAshboardDetails(data,item)
    };

    
    return {
        getHospitalListing,
        getSuperAdminDAshboardDetails,
        hospitalDetails,
        patientStatus,
        taskStatus,
        patientAdmittedCount,
        todayAdmittedPatient,
        loader,
        data,
        hospitalsListing,
        breadcrumData1,
        handleTaskGraph,
        timeData,
        values,
        breadcrumItem1
    };
};

export default SuperAdminDashboardFunctions;
