import { faArrowLeft, faBriefcaseMedical, faChevronRight, faFileInvoice, faHospital, faListCheck, faLock, faPlus, faPowerOff, faUser, faUserGroup, faUserPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import AuthController from "../../apis/Controllers/AuthController/AuthController";
import { Drawer_profile, LeftIcon, Menu, NotificationBell, Search } from "../../assets/images";
import { setBedListFiltes, setDepartmentListFilters, setDrawer, setPatientListFilters, setRoomListFiltes, setStaffListFiltes, setTaskListFiltes, setUserData, setWardListFilters } from "../../redux/actions/users";
import store from "../../redux/store";
import DrawerComponent from "./drawerComponent";


const WithoutImageHeader = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [drswerStatus, setDrawerStatus] = useState(false);

    useEffect(() => {
        getUserData();
        setTaskListFilters();
        setStafListFilters();
        setPatientListFilter();
        setWardListFilter();
        setDepartmentListFilter();
        setRoomListFilter();
        setBedListFilter();
    }, []);


    const getUserData = async () => {
        window.scrollTo(0, 0);
        let item = localStorage.getItem('SET_USER_DATA');
        item = JSON.parse(item);
        let user = item;
        if (user) {
            if (user && user.access && user.access.token) {
                await store.dispatch(setUserData(user));
            }
        }
        else {
            logout();
        }
    };


    const logout = async () => {
        store.dispatch(setUserData({}));
        localStorage.clear();
        navigate("/login");
    };


    const setStafListFilters = async () => {
        let item = localStorage.getItem('SET_STAFF_LIST_FILTERS');
        item = JSON.parse(item);
        let filters = item;
        if (item) {
            store.dispatch(setStaffListFiltes(filters));
            if (props && props.setReduxvalue) {
                props.setReduxvalue(filters);
            }
        }
        else {
            if (props && props.setReduxvalue) {
                props.setReduxvalue('Redux filters');
            }
            store.dispatch(setStaffListFiltes({
                department: [],
                role: [],
                hospital: [],
                adminStatus: null,
                hospitals: [],
            }));
        }
    };


    const setTaskListFilters = async () => {
        let item = localStorage.getItem('SET_TASK_LIST_FILTERS');
        item = JSON.parse(item);
        let filters = item;
        if (item) {
            store.dispatch(setTaskListFiltes(filters));
            if (props && props.setReduxvalue) {
                props.setReduxvalue(filters);
            }
        }
        else {
            if (props && props.setReduxvalue) {
                props.setReduxvalue('Redux filters');
            }
            store.dispatch(setTaskListFiltes({
                assignBy: [],
                wards: [],
                taskPriorities: null,
                taskStatus: null,
                hospitals: [],
            }));
        }
    };


    const setPatientListFilter = async () => {
        let item = localStorage.getItem('SET_PATIENT_LIST_FILTERS');
        item = JSON.parse(item);
        let filters = item;
        if (item) {
            store.dispatch(setPatientListFilters(filters));
            if (props && props.setReduxvalue) {
                props.setReduxvalue(filters);
            }
        }
        else {
            if (props && props.setReduxvalue) {
                props.setReduxvalue('Redux filters');
            }
            store.dispatch(setPatientListFilters({
                patientStatus: [],
                wards: [],
                admissionFromDate: null,
                admissionToDate: null,
                hospitals: [],
            }));
        }
    };


    const setWardListFilter = async () => {
        let item = localStorage.getItem('SET_WARD_LIST_FILETRS');
        item = JSON.parse(item);
        let filters = item;
        if (item) {
            store.dispatch(setWardListFilters(filters));
            if (props && props.setReduxvalue) {
                props.setReduxvalue(filters);
            }
        }
        else {
            if (props && props.setReduxvalue) {
                props.setReduxvalue('Redux filters');
            }
            store.dispatch(setWardListFilters({
                wardStatus: null,
                sortByAlpha: null,
                hospitals: [],
            }));
        }
    };

    const setRoomListFilter = async () => {
        let item = localStorage.getItem('SET_ROOM_LIST_FILTERS');
        item = JSON.parse(item);
        let filters = item;
        if (item) {
            store.dispatch(setRoomListFiltes(filters));
            if (props && props.setReduxvalue) {
                props.setReduxvalue(filters);
            }
        }
        else {
            if (props && props.setReduxvalue) {
                props.setReduxvalue('Redux filters');
            }
            store.dispatch(setRoomListFiltes({
                wards: [],
                hospitals: [],
                sortByAlpha: null
            }));
        }
    };

    const setBedListFilter = async () => {
        let item = localStorage.getItem('SET_BED_LIST_FILTERS');
        item = JSON.parse(item);
        let filters = item;
        if (item) {
            store.dispatch(setBedListFiltes(filters));
            if (props && props.setReduxvalue) {
                props.setReduxvalue(filters);
            }
        }
        else {
            if (props && props.setReduxvalue) {
                props.setReduxvalue('Redux filters');
            }
            store.dispatch(setBedListFiltes({
                wards: [],
                hospitals: [],
                rooms: [],
                sortByAlpha: null
            }));
        }
    };



    const setDepartmentListFilter = async () => {
        let item = localStorage.getItem('SET_DEPARTMENT_LIST_FILETRS');
        item = JSON.parse(item);
        let filters = item;
        if (item) {
            store.dispatch(setDepartmentListFilters(filters));
            if (props && props.setReduxvalue) {
                props.setReduxvalue(filters);
            }
        }
        else {
            if (props && props.setReduxvalue) {
                props.setReduxvalue('Redux filters');
            }
            store.dispatch(setDepartmentListFilters({
                depatmentStatus: null,
                sortByAlpha: null,
                hospitals: [],
            }));
        }
    };


    const handleDrawer = async (e) => {
        await store.dispatch(setDrawer(true));
        setDrawerStatus(true);
    };


    const closeDrawer = async () => {
        setDrawerStatus(false);
        await store.dispatch(setDrawer(false));
    };

    return (
        <>
            <div className="without_image_header" onClick={() => drswerStatus ? closeDrawer() : ''} >
                <div className="header_area">
                    <div className="details">
                        {props.lefticon === 'menu' ?
                            <div className="menu_icon">
                                <div className="img_menu">
                                    <Image src={Menu} alt="..." onClick={() => drswerStatus === false ? handleDrawer() : ''} />
                                </div>
                            </div>
                            :
                            <FontAwesomeIcon icon={faArrowLeft} className="arrow" onClick={() => props && props.handleBack() ? props.handleBack() : ''} />}
                        <h3>{props.header}</h3>
                        {props.icon === "edit" ?
                            <div className="icon_user" onClick={() => props && props.handleNavigate ? props.handleNavigate() : ''}>
                                <FontAwesomeIcon icon={faUserPen} style={{ cursor: 'pointer' }} />
                            </div>
                            :
                            // props.icon === "notification" ?
                            //     <div className="search_area">
                            //         <div className="img_search">
                            //             <Image src={NotificationBell} alt="..." />
                            //         </div>
                            //     </div>
                            //     :
                            props.icon === "add" ?
                                <div className="add_area">
                                    <a ><FontAwesomeIcon icon={faPlus} className="add_icon" />Add</a>
                                </div>
                                : props.icon === "search_bell" ?
                                    <div className="icon_area">
                                        <div className="img_one" onClick={() => navigate('/search')}>
                                            <Image src={Search} alt="..." />
                                        </div>
                                        {/* <div className="img_two">
                                            <Image src={NotificationBell} alt="..." />
                                        </div> */}
                                    </div>
                                    :
                                    null
                        }
                    </div>
                    {location && location.state && location.state.fromNavigation ? null : <div className="breadcrum_area">

                        {props.breadcrumData ? props.breadcrumData.map((item, i) =>
                            <div className="left_area" onClick={() => props && props.handleBreadcrumDataNaviagtion ? props.handleBreadcrumDataNaviagtion(i, item) : ''} key={i}>
                                <a >{item}</a><FontAwesomeIcon icon={faChevronRight} className="icon" />
                            </div>
                        ) : null}
                        <div className="left_area">
                            <span>{props.breadcrumItem}</span>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}

export default WithoutImageHeader;