import FiltersHeader from "../../../../Helper/filtersHeader";
import * as Constants from '../../../../Helper/constants/constants';
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import { Calender, ChangePatientProfile, Patient } from "../../../../../assets/images";
import CustomTab from "../../../../Helper/customTabs";
import { useRef, useState } from "react";
import DemographicDetailTab from "../../../Tabs/DemographicDetail";
import AddressTab from "../../../Tabs/Address";
import ConsultantTab from "../../../Tabs/Consultant";
import GeneralTab from "../../../Tabs/General";
import AddPatientFunctions from "./funstions";
import { useEffect } from "react";
import Select from 'react-select';
import UploadImageBox from "../../../../Helper/UploadUserImageBox";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import { useNavigate } from "react-router-dom";
import PatientDetailTabs from "../../../../Helper/patientDetailTabs";
import CustomSelectOptions from '../../../../Helper/customSelectOption';
import moment from "moment";

const AddPatient = () => {
    const {
        breadcrumData,
        breadcrumItem,
        currentTab,
        setCurrentTab,
        tabs,
        values,
        setValues,
        isError,
        handleChange,
        getPatientDetail,
        navigate,
        loaction,
        getHospitalListing,
        getWardsListing,
        getRoomsListing,
        getBedsListing,
        getBoodGroupListing,
        getMartialStatusListing,
        getPatientTypesListing,
        getNationalitiesListing,
        getStatesListing,
        getDistrictsListing,
        getCitiesListing,
        getGendersListing,
        getDepartmentsListing,
        getAdminsListing,
        patientStatusListing,
        hospitalList,
        wardsList,
        roomsList,
        bedsList,
        bloodGroupsList,
        martialStatusList,
        patientTypesList,
        nationalitiesList,
        gendersList,
        statesList,
        districtsList,
        citiesList,
        departmentList,
        consultantsList,
        handleEmergencyStatus,
        handleWardDropDown,
        handleRoomNoDropdown,
        handleBedNoDropdown,
        handleSubmitStepOne,
        handleSubmitStepTwo,
        handleSubmitStepThree,
        handleSubmitStepFour,
        handleDistrictAndCityDropDown,
        handleCityDropDown,
        handleConsultantDropdown,
        handleBreadcrumDataNaviagtion,
        patientCreateOrUpdateValue,
        loader,
    } = AddPatientFunctions();


    useEffect(() => {
        getHospitalListing();
        getBoodGroupListing();
        getMartialStatusListing();
        getPatientTypesListing();
        getNationalitiesListing();
        getGendersListing();
    }, []);



    return (
        <div className="add_patient_screen manage_select_react">
            <WithoutImageHeader
                header={Constants.ADD_PATIENT}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
                handleBack={() => navigate(-1)}
            />
            <Container>
                <Row>

                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Form>
                            <div className="patient_profile_area">
                                {/* <div className="patient_profile">
                                    <Image
                                        src={Patient}
                                        alt="..."
                                    />
                                </div>
                                <div className="change_profile">
                                    <Image
                                        src={ChangePatientProfile}
                                        alt="..."
                                    />
                                </div> */}
                                <UploadImageBox
                                    name={`admin`}
                                    image={values && values.stepOne && values.stepOne.image}
                                    onResponse={(response) => {
                                        handleChange("image", response.path, 'stepOne')
                                    }}
                                    removeImage={async () => {
                                        //  await removeImage();
                                    }}
                                />
                                {isError.image.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.image.message}
                                    </p>
                                ) : null}
                            </div>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="dropdown_box">
                                    <h6>Select Hospital<span>*</span></h6>
                                    <div className="dropdown_area" style={{ textTransform: 'capitalize' }}>
                                        <Select
                                            placeholder={"Select hospital"}
                                            value={values && values.stepOne && values.stepOne.hospitalId}
                                            options={hospitalList}
                                            onChange={(item) => (
                                                handleChange('hospitalId', item, 'stepOne'),
                                                getWardsListing(item.id ? [item.id] : []),
                                                getAdminsListing(item.id ? item.id : null)
                                            )}
                                            className={isError.hospitalId.message ? "error_border_select" : ""}
                                            components={{
                                                Option: props => (
                                                    <CustomSelectOptions
                                                        items={props}
                                                        value={values && values.stepOne && values.stepOne.hospitalId}
                                                    />
                                                )
                                            }}
                                        />
                                        {isError.hospitalId.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.hospitalId.message}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    UHID<span>*</span>
                                </Form.Label>
                                <Form.Control
                                    disabled={patientCreateOrUpdateValue && patientCreateOrUpdateValue === 1 ? true : false}
                                    type="text"
                                    placeholder="Enter UHID here"
                                    value={values.stepOne && values.stepOne.uhId}
                                    className={isError.uhId.message
                                        ? "error_border"
                                        : ""}
                                    onChange={(e) =>
                                        handleChange("uhId", e.target.value, 'stepOne')
                                    }
                                />
                                {isError.uhId.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.uhId.message}
                                    </p>
                                ) : null}
                            </Form.Group>

                            <Form.Group className="form-group">
                                <Form.Label>
                                    Patient Name<span>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter patient name"
                                    value={values && values.stepOne && values.stepOne.patientName}
                                    className={isError.patientName.message
                                        ? "error_border"
                                        : ""}
                                    onChange={(e) =>
                                        handleChange("patientName", e.target.value, 'stepOne')
                                    }
                                />
                                {isError.patientName.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.patientName.message}
                                    </p>
                                ) : null}
                            </Form.Group>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="dropdown_box" onClick={() => handleWardDropDown()}>
                                    <h6>Ward<span>*</span></h6>
                                    <div className="dropdown_area">
                                        <Select
                                            placeholder={"Select ward"}
                                            className={isError.ward.message ? "error_border_select" : ""}
                                            isDisabled={values && values.stepOne && values.stepOne.hospitalId ? false : true}
                                            value={values && values.stepOne && values.stepOne.ward}
                                            options={wardsList}
                                            onChange={(item) => (handleChange('ward', item, 'stepOne'), getRoomsListing(item.id ? item.id : ''))}
                                            components={{
                                                Option: props => (
                                                    <CustomSelectOptions
                                                        items={props}
                                                        value={values && values.stepOne && values.stepOne.ward}
                                                    />
                                                )
                                            }}
                                        />
                                        {isError.ward.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.ward.message}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>
                            <Row>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className="dropdown_box" onClick={() => handleRoomNoDropdown()}>
                                        <h6>Room No<span>*</span></h6>
                                        <div className="dropdown_area">
                                            <Select
                                                isDisabled={values && values.stepOne && values.stepOne.ward ? false : true}
                                                placeholder={'Select room no'}
                                                className={isError.roomNo.message ? "error_border_select" : ""}
                                                value={values && values.stepOne && values.stepOne.roomNo}
                                                options={roomsList}
                                                onChange={(item) => (handleChange('roomNo', item, 'stepOne'), getBedsListing(item.id))}
                                                components={{
                                                    Option: props => (
                                                        <CustomSelectOptions
                                                            items={props}
                                                            value={values && values.stepOne && values.stepOne.roomNo}
                                                        />
                                                    )
                                                }}
                                            />
                                            {isError.roomNo.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError.roomNo.message}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className="dropdown_box" onClick={() => handleBedNoDropdown()}>
                                        <h6>Bed No<span>*</span></h6>
                                        <div className="dropdown_area">
                                            <Select
                                                placeholder={"Select bed no"}
                                                className={isError.bedNo.message ? "error_border_select" : ""}
                                                isDisabled={values && values.stepOne && values.stepOne.roomNo ? false : true}
                                                value={values && values.stepOne && values.stepOne.bedNo}
                                                options={bedsList}
                                                onChange={(item) => handleChange('bedNo', item, 'stepOne')}
                                                components={{
                                                    Option: props => (
                                                        <CustomSelectOptions
                                                            items={props}
                                                            value={values && values.stepOne && values.stepOne.bedNo}
                                                        />
                                                    )
                                                }}
                                            />
                                            {isError.bedNo.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError.bedNo.message}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    Patient Status<span>*</span>
                                </Form.Label>
                                <Select
                                    options={patientStatusListing ? patientStatusListing : []}
                                    placeholder="Select patient Status"
                                    value={values && values.stepOne && values.stepOne.patientStatus}
                                    onChange={(e) => handleChange("patientStatus", e, 'stepOne')}
                                    className={isError.patientStatus.message
                                        ? "error_border_select"
                                        : ""}
                                    components={{
                                        Option: props => (
                                            <CustomSelectOptions
                                                items={props}
                                                value={values && values.stepOne && values.stepOne.patientStatus}
                                            />
                                        )
                                    }}
                                />
                                {isError.patientStatus.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.patientStatus.message}
                                    </p>
                                ) : null}
                            </Form.Group>

                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="dropdown_box">
                                    <h6>Patient Type<span>*</span></h6>
                                    <div className="dropdown_area">
                                        <Select
                                            placeholder={"Select patient type"}
                                            value={values && values.stepOne && values.stepOne.patientType}
                                            options={patientTypesList}
                                            onChange={(item) => handleChange('patientType', item, "stepOne")}
                                            className={isError.patientType.message ? "error_border_select" : ""}
                                            components={{
                                                Option: props => (
                                                    <CustomSelectOptions
                                                        items={props}
                                                        value={values && values.stepOne && values.stepOne.patientType}
                                                    />
                                                )
                                            }}
                                        />
                                        {isError.patientType.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.patientType.message}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Form.Group className="form-group">
                                    <Form.Label>
                                        Date of Admission<span>*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="DD/MM/YYYY"
                                        value={values && values.dateOfAdmission ? values.dateOfAdmission : null}
                                        className={isError && isError.dateOfAdmission.message
                                            ? "error_border"
                                            : ""}
                                        onChange={(e) =>
                                            handleChange("dateOfAdmission", e.target.value, "stepOne")
                                        }
                                    />
                                    {isError && isError.dateOfAdmission.message ? (
                                        <p className="text-danger mt-2">
                                            {isError && isError.dateOfAdmission.message}
                                        </p>
                                    ) : null}
                                </Form.Group>
                            </Col>
                            <Form.Check
                                type="checkbox"
                                className="emergency_checkbox"
                                id="Emergency Admission"
                                label="Emergency Admission"
                                value={values && values.stepOne && values.stepOne.emergencyAdmission}
                                onChange={(e) => handleEmergencyStatus(e)}
                            />

                        </Form>
                    </Col>
                </Row>
            </Container>
            <div className="task_btn_area">
                <PatientDetailTabs
                    tabs={tabs}
                    currentTab={currentTab}
                    setCurrentTab={(e) => setCurrentTab(e)}
                />
            </div>
            <div className="tab_details">
                <div className="custom_tab_data">
                    {parseInt(currentTab) === 1 ?
                        <DemographicDetailTab
                            values={values.stepOne}
                            isError={isError}
                            navigate={navigate}
                            gendersList={gendersList}
                            martialStatusList={martialStatusList}
                            bloodGroupsList={bloodGroupsList}
                            nationalitiesList={nationalitiesList}
                            handleChange={(field, value) => handleChange(field, value, 'stepOne')}
                            loader={loader}
                            handleSubmit={handleSubmitStepOne}
                            getStatesListing={getStatesListing}
                            patientCreateOrUpdateValue={patientCreateOrUpdateValue}
                        />
                        :
                        null}
                    {parseInt(currentTab) === 2 ?
                        <AddressTab
                            values={values.stepTwo}
                            stepOneValues={values.stepOne}
                            isError={isError}
                            statesList={statesList}
                            districtsList={districtsList}
                            citiesList={citiesList}
                            setCurrentTab={setCurrentTab}
                            handleChange={(field, value) => handleChange(field, value, 'stepTwo')}
                            handleDistrictAndCityDropDown={handleDistrictAndCityDropDown}
                            handleCityDropDown={handleCityDropDown}
                            loader={loader}
                            handleSubmitStepTwo={handleSubmitStepTwo}
                            getDistrictsListing={getDistrictsListing}
                            getCitiesListing={getCitiesListing}
                        />
                        : null
                    }
                    {parseInt(currentTab) === 3 ?
                        <ConsultantTab
                            values={values.stepThree}
                            isError={isError}
                            departmentList={departmentList}
                            consultantsList={consultantsList}
                            setCurrentTab={setCurrentTab}
                            handleChange={(field, value) => handleChange(field, value, 'stepThree')}
                            handleConsultantDropdown={handleConsultantDropdown}
                            loader={loader}
                            handleSubmitStepThree={handleSubmitStepThree}
                            getAdminsListing={getAdminsListing}
                        />
                        : null}
                    {parseInt(currentTab) === 4 ?
                        <GeneralTab
                            values={values.stepFour}
                            isError={isError}
                            setCurrentTab={setCurrentTab}
                            handleChange={(field, value) => handleChange(field, value, 'stepFour')}
                            loader={loader}
                            handleSubmitStepFour={handleSubmitStepFour}
                        />
                        : null}
                </div>
            </div>
        </div>
    )
};

export default AddPatient;