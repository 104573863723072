export const AuthHeaderImage = require('../images/auth.png');
export const SplashLogo = require('../images/logo.png');
export const Ellipse1 = require('../images/ellipse1.png');
export const Ellipse2 = require('../images/ellipse2.png');
export const WelcomeSplash1 = require('../images/welcomeSplash1.png');
export const WelcomeSplash2 = require('../images/welcomeSplash2.png');
export const WelcomeSplash3 = require('../images/welcomeSplash3.png');
export const Card1 = require('../images/card1.png');
export const Card2 = require('../images/card2.png');
export const Card3 = require('../images/card3.png');
export const Card4 = require('../images/card4.png');
export const CheckedImg = require('../images/checked.png');
export const NotificationBell = require('../images/notification-bell.png');
export const Search = require('../images/search.png');
export const PatientProfileImg = require('../images/patient-profile.png');
export const HeightImg = require('../images/height.png');
export const WeightImg = require('../images/weight.png');
//--------Profile image & icons------------
export const Profile = require('../images/profile.png');
export const Specialist = require('../images/specialist.png');
export const Experience = require('../images/experience.png');
export const Tasks = require('../images/tasks.png');
export const Circle = require('../images/circle.png');
export const Phone = require('../images/phone.png');
export const Email = require('../images/email.png');
export const Email2 = require('../images/email2.png');
export const phonecall = require('../images/phonecall.png');
export const Gender = require('../images/gender.png');
export const BloodGroup = require('../images/blood_group.png');
export const Calendar = require('../images/calendar.png');
export const Specialization = require('../images/specialization.png');
export const Location = require('../images/location.png');
export const EditProfile1 = require('../images/editprofile1.png');
export const EditProfile2 = require('../images/editprofile2.png');
export const EditProfile3 = require('../images/editprofile3.png');
export const Calender = require('../images/calender.png');
export const Temperature = require('../images/temperature.png');
export const Heart = require('../images/heart.png');
export const Blood = require('../images/blood.png');
export const Filter = require('../images/filter.png');
export const Group = require('../images/group.png');
export const User1 = require('../images/user1.png');
export const User2 = require('../images/user2.png');
export const Oxygen = require('../images/oxygen.png');
export const Sugar = require('../images/sugar.png');
export const bodyweight = require('../images/bodyweight.png');
export const bodyscale = require('../images/body-scale.png');
export const bloodtest = require('../images/bloodtest.png');
export const PdfImg = require('../images/pdf.png');
export const DocExt = require('../images/documentExt.png');
export const ImageExt = require('../images/imageExt.png');
export const Delete = require('../images/delete.png');
export const Eye = require('../images/eye.png');
export const editing = require('../images/editing.png');
export const BloodPressure = require('../images/bloodPressure.png');
export const BloodSugar = require('../images/bloodSugar.png');
export const HeartRate = require('../images/heartRate.png');
export const OxygenSaturation = require('../images/oxygenSaturation.png');
export const Temperature1 = require('../images/temperature1.png');
export const HealthPerson = require('../images/healthPerson.png');
export const SearchFilter = require('../images/search_filter.png');
export const CalendarBlack = require('../images/calender-black.png');
export const Task1 = require('../images/task1.png');
export const Task2 = require('../images/task2.png');
export const Task3 = require('../images/task3.png');
export const LogsImg = require('../images/logs.png');
export const LogCalendar = require('../images/log_calendar.png');
export const Checkbox = require('../images/checkbox.png');
export const MytaskImg = require('../images/mytask.png');
export const AssignImg = require('../images/assign_img.png');
export const GirlImg = require('../images/girl_img.png');
export const Vector = require('../images/vector.png');
export const Drawer_profile = require('../images/drawer_profile.png');
export const Hospital_Banner = require('../images/hospital_banner.png');
export const Hospital_Img = require('../images/hospital_img.png');
export const Building = require('../images/building.png');
export const Check = require('../images/check.png');
export const Loc_Round = require('../images/loc_round.png');
export const Loc = require('../images/loc.png');
export const Profile_hos = require('../images/profile_hos.png');
export const Route = require('../images/route.png');
export const No_Data = require('../images/no_data.png');


export const Filters = require('../images/filters.png');
export const Eye_2 = require('../images/eye_2.png');
export const Delete_2 = require('../images/delete_2.png');
export const Edit_2 = require('../images/edit2.png');
export const General = require('../images/general.png');
export const siren = require('../images/siren.png');
export const Registration = require('../images/registration.png');
export const Patient = require('../images/patient.png');
export const ChangePatientProfile = require('../images/changePatientProfile.png');

export const Build_Img = require('../images/build_img.png');
export const Circle_Img = require('../images/circle_box.png');
export const Image_Up = require('../images/image_up.png');
export const Man = require('../images/man.png');
export const Shape = require('../images/shape.png');
export const Clock = require('../images/clock.png');
export const Menu = require('../images/menu.png');
export const LeftIcon = require('../images/left_icon.png');
export const ImgHos = require('../images/img_hos.png');
export const Dash_bg = require('../images/dash_bg.png');
export const Loc_Icon = require('../images/loc_icon.png');
export const Mail_Icon = require('../images/mail_icon.png');
export const Icon_One = require('../images/icon_one.png');
export const Icon_Two = require('../images/icon_two.png');
export const Icon_Three = require('../images/icon_three.png');
export const Icon_Four = require('../images/icon_four.png');


export const Radiology = require('../images/radiology.png');
export const Assigned_Member = require('../images/assigned_member.png');
export const Green_Shape = require('../images/green_shape.png');
export const Icon_Circle = require('../images/icon_circle.png');
export const Down_Icon = require('../images/down_icon.png');
export const Abc = require('../images/abc.png');
export const Vector_1 = require('../images/vector_1.png');
export const Vector_2 = require('../images/vector_2.png');
export const Vector_3 = require('../images/vector_3.png');
export const Vector_4 = require('../images/vector_4.png');
export const Vector_5 = require('../images/vector_5.png');
export const Vector_6 = require('../images/vector_6.png');
export const Vector_7 = require('../images/vector_7.png');
export const Vector_8 = require('../images/vector_8.png');










