import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import Select from 'react-select';
import Loader from "../../../Helper/loader";
import CustomSelectOptions from "../../../Helper/customSelectOption";


const ConsultantTab = (props) => {
    const {
        values,
        isError,
        departmentList,
        consultantsList,
        loader,
        getAdminsListing
    } = props


    const handleChange = (field, value) => {
        props.handleChange(field, value);
    };


    const handleBackClick = () => {
        props.setCurrentTab('2');
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        props.handleSubmitStepThree();
    };


    const handleConsultantDropdown = () => {
        props.handleConsultantDropdown();
    };
    
    return (
        <div className="consultant_tab_screen">
            {loader ? <Loader loader={loader} /> : <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Form>
                            {/* <div className="dropdown_box">
                                <h6>Department<span>*</span></h6>
                                <div className="dropdown_area">
                                    <Select
                                        placeholder="Select department"
                                        value={values && values.department}
                                        options={departmentList}
                                        onChange={(item) => (handleChange('department', item), getAdminsListing(item.id))}
                                        className={isError.department.message ? "error_border_select" : ""}
                                    />
                                    {isError.department.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.department.message}
                                        </p>
                                    ) : null}
                                </div>
                            </div> */}
                            <div className="dropdown_box">
                                <h6>Consultant Name<span>*</span></h6>
                                <div className="dropdown_area" style={{textTransform:'capitalize'}}>
                                    <Select
                                        placeholder="Select consultant"
                                        // isDisabled={values && values.department ? false : true}
                                        value={values && values.consultantData}
                                        options={consultantsList}
                                        onChange={(item) => handleChange('consultantData', item)}
                                        components={{
                                            Option: props => (
                                                <CustomSelectOptions
                                                    items={props}
                                                    value={values && values.consultantData}
                                                />
                                            )
                                        }}
                                    />
                                    {isError.consultantData.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.consultantData.message}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    MR Title
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter MR title here"
                                    value={values.caseTitle}
                                    className={isError.caseTitle.message
                                        ? "error_border"
                                        : ""}
                                    onChange={(e) =>
                                        handleChange("caseTitle", e.target.value)
                                    }

                                />
                                {isError.caseTitle.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.caseTitle.message}
                                    </p>
                                ) : null}
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    MR Details
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter MR details here"
                                    value={values.summary}
                                    className={isError.summary.message
                                        ? "error_border"
                                        : ""}
                                    onChange={(e) =>
                                        handleChange("summary", e.target.value)
                                    }
                                />
                                {isError.summary.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.summary.message}
                                    </p>
                                ) : null}
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    Tags
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Ex- Fever, cold, cough etc"
                                    value={values.tag}
                                    className={isError.tag.message
                                        ? "error_border"
                                        : ""}
                                    onChange={(e) =>
                                        handleChange("tag", e.target.value)
                                    }
                                />
                                {isError.tag.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.tag.message}
                                    </p>
                                ) : null}
                            </Form.Group>


                            <div className="btn_area">
                                <Button className="cancelBtn" type="button" onClick={handleBackClick}>Back</Button>
                                <Button className="submitBtn" type="submit" onClick={handleSubmit}>Next</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>}
        </div>
    )
};

export default ConsultantTab;