import { useState } from "react";
import permissionsController from "../../../../../apis/Controllers/PermissionsController/permissions.contoller";
import Validation from "../../../../Helper/validations/vaildation";
import rolesController from "../../../../../apis/Controllers/RolesController/roles.controller";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../../redux/store";
import { all } from "axios";
import hospitalController from "../../../../../apis/Controllers/HospitalController/hospital.controller";
import wardController from "../../../../../apis/Controllers/WardController/ward.controller";
import roomsController from "../../../../../apis/Controllers/RoomsController/rooms.controller";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddRoomFunction = () => {
    var breadcrumData = ["Dashboard", "Room list"];
    const [loader, setLoader] = useState(false);
    const [breadcrumItem, setbreadcrumItem] = useState("Add Room");
    const [hospitalList, setHospitalList] = useState([]);
    const [wardList, setWardsList] = useState([]);
    const loaction = useLocation();
    const navigate = useNavigate();
    const defaultValues = {
        hospitalId: null,
        wardId: null,
        roomNumber: null,
        availableBeds: null,
    };

    const defaultErrors = {
        hospitalId: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        wardId: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        roomNumber: {
            rules: ["required", "numeric", "max:3"],
            isValid: true,
            message: "",
        },
        availableBeds: {
            rules: ["required", "numeric", "max:3"],
            isValid: true,
            message: "",
        },
    };

    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);

    const handleChange = (field, value) => {
        let validation = new Validation(isError);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };


    const getHospitalListing = async () => {
        if (!loader) {
            setLoader(true);
            let response = await hospitalController.hospitalListDashboard();
            if (response && response.status) {
                let array1 = response.data;
                for (let i in array1) {
                    array1[i]["label"] = array1[i].title;
                }
                setHospitalList(array1);
                if (!loaction.state) {                               //<--------------auto select hospital case on the base of global seleted Hospital
                    let data = localStorage.getItem('SET_HOSPITAL');
                    let item = JSON.parse(data);
                    let hospitalData = {
                        title: item && item.title ? item.title : '',
                        id: item && item.id ? item.id : '',
                        label: item && item.title ? item.title : ''
                    }
                    setValues({
                        ...values,
                        hospitalId: item ? hospitalData : null
                    })
                    getWardsListing(item.id);
                }
                setLoader(false);
            } else {
                setHospitalList([]);
                setLoader(false);
            }
            setLoader(false);
        }
    };


    const getWardsListing = async (id) => {
        let post = {
            hospital_id: id ? [id] : []
        }
        let response = await wardController.wardListing(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
                data[i]['icon'] = <FontAwesomeIcon
                    icon={faCheck}
                />
            }
            setWardsList(data);
        }
        else {
            setWardsList([]);
        }
    };

    const getRoomDetails = async (id) => {
        setLoader(true);
        let response = await roomsController.roomDetail(id);
        if (response && response.status) {
            setLoader(false);
            let data = response.data;
            setValues({
                ...values,
                hospitalId: data && data.hospital_name ? { label: data.hospital_name, id: data.hospital_id } : null,
                wardId: data && data ? { label: data.ward_name, id: data.ward_id } : null,
                roomNumber: data && data.room_number ? data.room_number : null,
                availableBeds: data && data.available_beds ? data.available_beds : null,
            })

        } else {
            setLoader(false);
        }
    }

    const submitRoom = async () => {
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            setLoader(true);
            if (loaction && loaction.state && loaction.state.name) {
                let id =
                    loaction && loaction.state && loaction.state.id
                        ? loaction.state.id
                        : null;
                let response = await roomsController.roomUpdate(id, values);
                if (response && response.status) {
                    new Toaster().success(response.message);
                    navigate('/room-list');
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            } else {
                let response = await roomsController.roomCreate(values);
                if (response && response.status) {
                    new Toaster().success(response.message);
                    navigate('/room-list');
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            }
        } else {
            setError({ ...isValid.errors });
        }
    };


    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/dashboard-superadmin");
            }
        } else if (i === 1) {
            navigate("/room-list");
        } else return;
    };


    return {
        breadcrumData,
        breadcrumItem,
        setbreadcrumItem,
        getHospitalListing,
        hospitalList,
        loader,
        values,
        setValues,
        isError,
        handleChange,
        wardList,
        getWardsListing,
        handleBreadcrumDataNaviagtion,
        submitRoom,
        getRoomDetails,
        loaction
    };
};

export default AddRoomFunction;
