import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function permissionsListing() {
    return mainWrapper.get(WebConstant.host + `/permission`,);
};

function userpermissionsListing() {
    return mainWrapper.get(WebConstant.host + `/user_permission`,);
};


function permissionsDetail(params) {
    return mainWrapper.get(WebConstant.host + `/get_user_permission?permission_slug=${params}`);
};


export const permissionsServices = {
    permissionsListing,
    userpermissionsListing,
    permissionsDetail
};
