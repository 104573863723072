import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function hospitalList (params) {
    return mainWrapper.get(WebConstant.host + "/hospital_listing", params);
};

function hospitalListDashboard (params) {
    return mainWrapper.get(WebConstant.host + "/hospital_list_for_dashboard", params);
};


function createHospital (params) {
    return mainWrapper.post(WebConstant.host + `/create_hospital`, params);
};


function hospitalDetail (id) {
    return mainWrapper.get(WebConstant.host + `/hospital_details/${id}`);
};


function updateHospital (params, id) {
    return mainWrapper.put(WebConstant.host + `/update_hospital/${id}`, params);
};


function deleteHospital (id) {
    return mainWrapper._delete(WebConstant.host + `/delete_hospital/${id}`);
};


export const hospitalServices = {
    hospitalList,
    hospitalListDashboard,
    createHospital,
    hospitalDetail,
    updateHospital,
    deleteHospital
};
