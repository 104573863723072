import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import {
    EditProfile1,
    EditProfile2
} from "../../../../assets/images";
import * as Constants from "../../../Helper/constants/constants";
import WithoutImageHeader from "../../../Helper/withoutImageHeader";
import UpdateSuperAdminProfileFunctions from "./function";
import { renderImage } from "../../../Helper/General";
import UploadImageBox from "../../../Helper/UploadUserImageBox";



const EditSuperAdminProfile = () => {
    const {
        values,
        setValues,
        isError,
        loader,
        handleChange,
        handleBreadcrumDataNaviagtion,
        loaction,
        breadcrumData,
        breadcrumItem,
        setbreadcrumItem,
        updateSuperAdmin,
        getSuperAdminDetail,
        handleSubmit,
        navigate,
    } = UpdateSuperAdminProfileFunctions();


    useEffect(() => {
        getSuperAdminDetail();
    }, []);




    return (
        <div className="edit_profile_screen">
            <WithoutImageHeader
                header={Constants.PROFILE_HEADER}
                handleBack={() => navigate(-1)}
            />
            <div className="parent_area">
                <div className="innerr_area">
                    <Container>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="profile_area">
                                    {/* <div className="image_area">
                                        <Image
                                            src={values && values.image ? renderImage(values.image) : EditProfile1}
                                            alt="..."
                                        />
                                        <a>
                                            <div className="editicon">
                                                <Image
                                                    src={EditProfile2}
                                                    alt="..."
                                                />
                                            </div>
                                        </a>
                                    </div> */}
                                    <UploadImageBox
                                        name={`superAdmin`}
                                        fromSuperAdminUpdateProfile={'SuperAdminUpdateProfile'}
                                        image={values && values.image}
                                        onResponse={(response) => { handleChange("image", response.path) }}
                                        removeImage={async () => {
                                            //  await removeImage();
                                        }}
                                    />
                                </div>
                                <div className="form">
                                    <Form>
                                        <Row>
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Full Name<span>*</span></Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder="Enter Name here"
                                                        className={
                                                            isError.name.message
                                                                ? "error_border"
                                                                : ""
                                                        }
                                                        value={values.name}
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "name",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    {isError.name.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.name.message}
                                                        </p>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Email<span>*</span></Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Enter email here"
                                                        className={
                                                            isError.email.message
                                                                ? "error_border"
                                                                : ""
                                                        }
                                                        value={values.email}
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "email",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    {isError.email.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.email.message}
                                                        </p>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Phone Number<span>*</span></Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter Phone Number here"
                                                        className={
                                                            isError.phonenumber.message
                                                                ? "error_border"
                                                                : ""
                                                        }
                                                        value={values.phonenumber}
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "phonenumber",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    {isError.phonenumber.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.phonenumber.message}
                                                        </p>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Address<span>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Address here"
                                                        className={
                                                            isError.address.message
                                                                ? "error_border"
                                                                : ""
                                                        }
                                                        value={values.address}
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "address",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    {isError.address.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.address.message}
                                                        </p>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <div className="btn_area">
                                                <Button
                                                    className="cancelBtn"
                                                    type="button"
                                                    onClick={() => navigate(-1)}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    className="submitBtn"
                                                    type="submit"
                                                    onClick={(e) => (e.preventDefault(), handleSubmit())}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default EditSuperAdminProfile;
