import { useEffect, useState } from "react";
import patientController from "../../../../apis/Controllers/PatientController/patient.controller";
import { useLocation, useNavigate } from "react-router";
import taskController from "../../../../apis/Controllers/TaskController/task.controller";
import { Toaster } from "../../../Helper/toaster/Toaster";
import reportsController from "../../../../apis/Controllers/ReportsController/reports.controller";
import vitalsController from "../../../../apis/Controllers/VitalsController/vitals.controller";
import Validation from "../../../Helper/validations/vaildation";
import store from "../../../../redux/store";
import permissionsController from "../../../../apis/Controllers/PermissionsController/permissions.contoller";
import { getExtension, isEmptyArray } from "../../../Helper/General";
import { Image } from "react-bootstrap";
import { DocExt, ImageExt, PdfImg } from "../../../../assets/images";
import medicineController from "../../../../apis/Controllers/MedicineController/medicine.controller";
import moment from "moment";
import logsController from "../../../../apis/Controllers/LogsController/logs.controller";
import labController from "../../../../apis/Controllers/LabController/lab.controller";
import { useParams } from "react-router-dom";


const PatientDetailFunctions = () => {
    var [breadcrumData, setBreadcrumData] = useState(["Dashboard", "Patient List"]);
    var breadcrumItem = "Patient Details";

    const defaultTab = [
        {
            id: 1,
            tabTitle: "Task",
        },
        {
            id: 2,
            tabTitle: "Reports",
        },
        {
            id: 3,
            tabTitle: "Lab",
        },
        {
            id: 4,
            tabTitle: "Health",
        },
        {
            id: 5,
            tabTitle: "Logs",
        },
        {
            id: 6,
            tabTitle: "Medicine",
        },
    ];

    const defaultLabTab = [

        {
            id: 3,
            tabTitle: "Lab",
        },
        {
            id: 5,
            tabTitle: "Logs",
        },

    ];



    const defaultValues = {
        temperature: "",
        weight: "",
        heartbeat: "",
        bloodPressureTop: "",
        bloodPressureBottom: "",
        oxygenSaturationTop: "",
        oxygenSaturationBottom: "",
        bloodSuger: "",
    };
    const defaultErrors = {
        heartbeat: {
            rules: ["numeric"],
            isValid: true,
            message: "",
        },
        weight: {
            rules: [],
            isValid: true,
            message: "",
        },
        temperature: {
            rules: [],
            isValid: true,
            message: "",
        },
        bloodPressureTop: {
            rules: ["numeric"],
            isValid: true,
            message: "",
        },
        bloodPressureBottom: {
            rules: ["numeric"],
            isValid: true,
            message: "",
        },
        oxygenSaturationTop: {
            rules: ["numeric"],
            isValid: true,
            message: "",
        },
        oxygenSaturationBottom: {
            rules: ["numeric"],
            isValid: true,
            message: "",
        },
        bloodSuger: {
            rules: ["numeric"],
            isValid: true,
            message: "",
        },
    };

    const [isError, setError] = useState(defaultErrors);
    const [values, setValues] = useState(defaultValues);
    const [showLogin, setShowLogin] = useState(false);
    const [showUpdateVitalModal, setShowUpdateVitalModal] = useState(false);
    const [currentTab, setCurrentTab] = useState(1);
    const [patientData, setPatientData] = useState(null);
    const [medicalRecordTags, setMedicalRecordTags] = useState(null);
    const [patientReportsList, setPatientReportsList] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [vitalsList, setVitalsList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    //<----------------------Permissions--------------------------------------->
    const [checkPermissions, setCheckPatientModulePermissions] = useState(null);
    const [checkTaskPermissions, setCheckTaskModulePermissions] = useState(null);
    const [checkVitalPermissions, setVitalCheckPermissions] = useState(null);
    const [checkReportPermissions, setReportCheckPermissions] = useState(null);
    const [checkMedicinePermissions, setMedicineCheckPermissions] = useState(null);
    const [checkLabPermissions, setLabCheckPermissions] = useState(null);
    const [checkLogsPermissions, setLogsCheckPermissions] = useState(null);
    const [checkPatinetCasePermissions, setPatinetCaseCheckPermissions] = useState(null);
    //<------------------------------------------------------------------------
    const [patientId, setPatientId] = useState(null);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filterSortByValue, setFilterSortByValue] = useState(null);
    const [patientActiveCaseDetail, setPatientActveCaseDetail] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    //<--------------------Task Pazination---------------------------
    const [page, setPage] = useState(1);
    const [taskListCount, setTaskListCount] = useState(0);
    const [taskListTotalPages, setTaskListTotalPages] = useState([]);
    const [isUserCanCompleteTheTask, setIsUserCanCompleteTheTask] = useState(false);

    //----------------------Reports Pazination------------------------
    const [reportsPage, setReportPage] = useState(1);
    const [reportListCount, setReportListCount] = useState(0);
    const [reportListTotalPages, setReportListTotalPages] = useState([]);
    const [deleteReportsDetail, setDeleteReportsDetail] = useState(false);

    //<----------------------Health Pazination-----------------------------
    const [vitalsPage, setVitalsPage] = useState(1);
    const [vitalsListCount, setVitalsListCount] = useState(0);
    const [vitalsListTotalPages, setVitalsListTotalPages] = useState([]);

    //<---------------------Medicine Tab------------------------
    const [medicinePage, setMedicinePage] = useState(1)
    const [medicineCount, setMedicineCount] = useState(0)
    const [medicineTotalPages, setMedicineTotalPages] = useState([]);
    const [medicineList, setMedicineList] = useState([])
    const [completeMedicine, setCompleteMedicine] = useState([])
    const [allmedicineCompletePopup, setAllmedicineCompletePopup] = useState(false);
    const [selectAllMedicine, setSelectAllMedicine] = useState();
    const [deletMedicineDetail, setDeletMedicineDetail] = useState(null);
    const [deletConfirmationModal, setDeletConfirmationModal] = useState(false);
    const [completMedicalConfirmationModal, setCompletMedicineConfimationModal] = useState(false);
    const [completeMedicineDetail, setCompleteMedicineDetail] = useState(null);
    const [medicineDataForCommentApi, setMedicineDataForCommentApi] = useState(null);
    const [medicineComment, setMedicineComment] = useState(false);
    const [dateParam, setDateParam] = useState(moment().format('YYYY-MM-DD'))
    //<-----------------------Logs Tab----------------------------------------
    const [LogsListing, setLogsListing] = useState([]);
    const [logsPage, setLogsPage] = useState(1)
    const [logsCount, setLogsCount] = useState(0)
    const [logsTotalPages, setLogsTotalPages] = useState([]);
    const [totalLogsListLength, setTotalLogsListLength] = useState(0);
    const [logsDateParam, setLogsDateParam] = useState(moment().format('YYYY-MM-DD'));
    //<-----------------------------------------------------------------------
    //<-----------------------Lab Tab----------------------------------------
    const [LabListing, setLabListing] = useState([]);
    const [labPage, setLabPage] = useState(1)
    const [labCount, setLabCount] = useState(0)
    const [labTotalPages, setLabTotalPages] = useState([]);
    const [deleteLabreport, setDeleteLabreport] = useState(false);
    const [totalLabListLength, setTotalLabListLength] = useState(0);
    const [labDateParam, setLabDateParam] = useState(moment().format('YYYY-MM-DD'));
    //<-----------------------------------------------------------------------

    const [patientStatus, setPatientStatus] = useState([
        { id: 'admitted', value: 'Admitted' },
        { id: 'mmx', value: 'MMX' },
        { id: 'on_ventilator', value: 'On Ventilator' },
        { id: 'on_tpi', value: 'On TPI' },
        { id: 'mi', value: 'MI' },
        { id: '72hrs', value: '72hrs' },
        { id: 'discharge', value: 'Discharged' },
    ]);

    let userdata = localStorage.getItem('SET_USER_DATA');
    const User = JSON.parse(userdata);

    let navigate = useNavigate();
    const loaction = useLocation();
    const urlParams = useParams();

    const [tabs, setTabs] = useState(User && (User.role_title === 'Lab' || User.role_title === 'lab') ? defaultLabTab : defaultTab);

    const storedIndex = localStorage.getItem('index');

    // useEffect(() => {
    //     if (checkTaskPermissions) {
    //         let tabBar = [...tabs]
    //         if (checkTaskPermissions
    //             && checkTaskPermissions.permissions
    //             && checkTaskPermissions.permissions.length > 0
    //             && parseInt(checkTaskPermissions.permissions[0].value) === 1) {
    //             let checkId = tabBar.findIndex((item) => item.tabTitle === 'Task')
    //             if (checkId === -1) {
    //                 tabBar.push({
    //                     id: 1,
    //                     tabTitle: "Task",
    //                 })
    //             }
    //             else;
    //         }
    //         else {
    //             return tabBar
    //         }
    //         setTabs(tabBar);
    //     }
    // }, [checkTaskPermissions]);

    // useEffect(() => {
    //     if (checkReportPermissions) {
    //         let tabBar = [...tabs]
    //         if (checkReportPermissions
    //             && checkReportPermissions.permissions
    //             && checkReportPermissions.permissions.length > 0
    //             && parseInt(checkReportPermissions.permissions[0].value) === 1) {
    //             let checkId = tabBar.findIndex((item) => item.tabTitle === 'Reports')
    //             if (checkId === -1) {
    //                 tabBar.push({
    //                     id: 2,
    //                     tabTitle: "Reports",
    //                 })
    //             }
    //             else;
    //         }
    //         else {
    //             return tabBar
    //         }
    //         setTabs(tabBar);
    //     }
    // }, [checkReportPermissions]);


    // useEffect(() => {
    //     if (checkLabPermissions) {
    //         let tabBar = [...tabs]
    //         if (checkLabPermissions
    //             && checkLabPermissions.permissions
    //             && checkLabPermissions.permissions.length > 0
    //             && parseInt(checkLabPermissions.permissions[0].value) === 1) {
    //             let checkId = tabBar.findIndex((item) => item.tabTitle === 'Lab')
    //             if (checkId === -1) {
    //                 tabBar.push({
    //                     id: 3,
    //                     tabTitle: "Lab",
    //                 })
    //             }
    //             else;
    //         }
    //         else {
    //             return tabBar
    //         }
    //         setTabs(tabBar);
    //     }
    // }, [checkLabPermissions]);


    // useEffect(() => {
    //     if (checkVitalPermissions) {
    //         let tabBar = [...tabs]
    //         if (checkVitalPermissions
    //             && checkVitalPermissions.permissions
    //             && checkVitalPermissions.permissions.length > 0
    //             && parseInt(checkVitalPermissions.permissions[0].value) === 1) {
    //             let checkId = tabBar.findIndex((item) => item.tabTitle === 'Health')
    //             if (checkId === -1) {
    //                 tabBar.push({
    //                     id: 4,
    //                     tabTitle: "Health",
    //                 })
    //             }
    //             else;
    //         }
    //         else {
    //             return tabBar
    //         }
    //         setTabs(tabBar);
    //     }
    // }, [checkVitalPermissions]);


    // useEffect(() => {
    //     if (checkLogsPermissions) {
    //         let tabBar = [...tabs]
    //         if (checkLogsPermissions
    //             && checkLogsPermissions.permissions
    //             && checkLogsPermissions.permissions.length > 0
    //             && parseInt(checkLogsPermissions.permissions[0].value) === 1) {
    //             let checkId = tabBar.findIndex((item) => item.tabTitle === 'Logs')
    //             if (checkId === -1) {
    //                 tabBar.push({
    //                     id: 5,
    //                     tabTitle: "Logs",
    //                 })
    //             }
    //             else;
    //         }
    //         else {
    //             return tabBar
    //         }
    //         setTabs(tabBar);
    //     }
    // }, [checkLogsPermissions]);


    // useEffect(() => {
    //     if (checkMedicinePermissions) {
    //         let tabBar = [...tabs]
    //         if (checkMedicinePermissions
    //             && checkMedicinePermissions.permissions
    //             && checkMedicinePermissions.permissions.length > 0
    //             && parseInt(checkMedicinePermissions.permissions[0].value) === 1) {
    //             let checkId = tabBar.findIndex((item) => item.tabTitle === 'Medicine')
    //             if (checkId === -1) {
    //                 tabBar.push({
    //                     id: 6,
    //                     tabTitle: "Medicine",
    //                 })
    //             }
    //             else;
    //         }
    //         else {
    //             return tabBar
    //         }
    //         setTabs(tabBar);
    //     }
    // }, [checkMedicinePermissions]);

    const handleTabData = (tabId) => {                    //<-------------onChange Tab
        if (parseInt(tabId) === 1) {
            getTaskListing(1, patientId);
        } else if (parseInt(tabId) === 2) {
            getReportsListng(1, patientId);
        } else if (parseInt(tabId) === 3) {
            getLabReportListing(1, patientId);
        } else if (parseInt(tabId) === 4) {
            getHealthListing(1, patientId);
        } else if (parseInt(tabId) === 6) {
            getMedicineListing(1)
        } else if (parseInt(tabId) === 5) {
            getLogsListing(1, patientId)
        }
        else return;
    };

    //----------------------------------------Permissions Module-------------------------------------------------->

    const getPatientModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('user');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckPatientModulePermissions(data);
        }
        else {
            setCheckPatientModulePermissions(null);
        }
    };


    const getTaskModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('task');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckTaskModulePermissions(data);
        }
        else {
            setCheckTaskModulePermissions(null);
        }
    };


    const getReportsModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('report');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setReportCheckPermissions(data);
        }
        else {
            setReportCheckPermissions(null);
        }
    };


    const getLabModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('lab');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setLabCheckPermissions(data);
        }
        else {
            setLabCheckPermissions(null);
        }
    };


    const getVitalModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('vital');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setVitalCheckPermissions(data);
        }
        else {
            setVitalCheckPermissions(null);
        }
    };

    const getLogsModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('log');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setLogsCheckPermissions(data);
        }
        else {
            setLogsCheckPermissions(null);
        }
    };


    const getMedicineModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('medicine');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setMedicineCheckPermissions(data);
        }
        else {
            setMedicineCheckPermissions(null);
        }
    };


    const getPatientCaseModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('case');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setPatinetCaseCheckPermissions(data);
        }
        else {
            setPatinetCaseCheckPermissions(null);
        }
    };

    //<-----------------------------------------------------------------------------------------------------Permissions Module

    const getPatientDetail = async (patinetId) => {
        setLoader(true);
        let response = await patientController.patientDetail(patinetId);
        if (response && response.status) {
            setPatientId(response && response.data && response.data.id);
            let data = response.data.vitals[0];
            let cases = response.data.cases
            setPatientData(response.data);
            setLoader(false);
            setValues({
                ...values,
                temperature: data && data.temperature ? data.temperature : null,
                weight: data && data.weight ? data.weight : null,
                heartbeat: data && data.heart_beat ? data.heart_beat : null,
                bloodPressureTop:
                    data && data.blood_pressure_top
                        ? data.blood_pressure_top
                        : null,
                bloodPressureBottom:
                    data && data.blood_pressure_bottom
                        ? data.blood_pressure_bottom
                        : null,
                oxygenSaturationTop:
                    data && data.oxygen_saturation_top
                        ? data.oxygen_saturation_top
                        : null,
                oxygenSaturationBottom:
                    data && data.oxygen_saturation_bottom
                        ? data.oxygen_saturation_bottom
                        : null,
                bloodSuger: data && data.blood_suger ? data.blood_suger : null,
            });
            getPatientActiveCaseDetail(cases);
            if (parseInt(storedIndex) == 1 || storedIndex === null) {
                setCurrentTab(1);
                getTaskListing(1, response.data.id);
            } else if (parseInt(storedIndex) === 2) {
                setCurrentTab(2);
                getReportsListng(1, response.data.id);
            } else if (parseInt(storedIndex) == 3) {
                setCurrentTab(3);
                getLabReportListing(1, response.data.id);
            } else if (parseInt(storedIndex) === 4) {
                setCurrentTab(4);
                getHealthListing(1, response.data.id);
            } else if (parseInt(storedIndex) === 5) {
                setCurrentTab(5);
                getLogsListing(1, response.data.id)
            } else if (parseInt(storedIndex) == 6) {
                setCurrentTab(6);
                getMedicineListing(1, response.data.id);
            }
        } else {
            setPatientData(null);
            setLoader(false);
        }
    };

    const getPatientActiveCaseDetail = (cases) => {
        if (cases && cases.length > 0) {
            for (let i in cases) {
                if (parseInt(cases[i].case_status) === 1) {
                    setPatientActveCaseDetail(cases[i]);
                } else {
                    setPatientActveCaseDetail(null);
                }
            }
        } else return;
    };


    const manageMedicalRecordsTags = (tags) => {
        if (tags) {
            const data = tags.replace(/,/g, "")
            let tag = data.split(" ");
            return (
                tag.map((item, i) => (
                    <li
                        className="category color"
                        key={i}
                    >
                        <p> {item}</p>
                    </li>
                ))
            )
        }
    };


    const chnagePatientStatus = async (id, status) => {
        if (!loader) {
            let post = {
                patient_status: status ? status : '',
            }
            setLoader(true)
            let response = await patientController.updatePatientStatus(id, post);
            if (response && response.status) {
                setLoader(false)
                new Toaster().success(response.message);
                getPatientDetail(patientId);
                getLogsListing(1, patientId);
            }
            else {
                setLoader(false)
            }
        }
    };


    const handleBreadcrumDataNaviagtion = (i, item) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate('/dashboard');
            }
            else {
                navigate('/dashboard-superadmin');
            }
        }
        else if (i === 1) {
            if (loaction && loaction.state && loaction.state.name1 == "ExistingPatientList") {
                navigate('/existing-patient');
            }
            else if (item === 'Patient List') {
                navigate('/manage-patient-list');
            }
            else if (item === 'Task list') {
                navigate('/task-list');
            }
            else if (user && parseInt(user.user_type) === 2) {
                return navigate("/dashboard");
            }
            else {
                return navigate("/dashboard-superadmin");
            }
        }
        else if (i === 2) {
            if (item === 'Patient List') {
                navigate('/patient-list');
            }
            else;
        }
        else return;
    };


    //<------------------------------------------------Task Module ------------------------------------------------------

    const getTaskListing = async (page, id, sortBy) => {
        let post = {
            patient_id: id ? id : patientData && patientData.id ? patientData.id : null,
            page: page ? page : 1,
            limit: 6,
            sort: sortBy ? sortBy : '',
            role: User && parseInt(User.user_type) === 2 ? 'nurse' : ''
        };
        setLoader2(true);
        let response = await taskController.taskListingPatientDetails(post);
        if (response && response.status) {
            setTaskListCount(response.count);
            setTaskList(response.data);
            let status = handleIsUserCanCompleteTheTask(response.data);
            setIsUserCanCompleteTheTask(status);
            setLoader2(false);
        } else {
            setLoader2(false);
            setTaskList([]);
        }
        setLoader2(false);
    };


    const handleIsUserCanCompleteTheTask = (list) => {               //<--------------For handle the complete task button 
        let data = localStorage.getItem('SET_USER_DATA');
        let UserData = JSON.parse(data);
        let status = false
        for (let i in list) {
            for (let j in list[i].assigned_members) {
                if (parseInt(list[i].assigned_members[j].admin_id) === parseInt(UserData.id)) {
                    status = true
                    return true
                }
                else {
                    status = false
                }
            }
        }
        return status
    };


    const taskCompletedByAdmin = async (item) => {

        let patientId = urlParams && urlParams.slug ? urlParams.slug : "";
        setLoader(true);
        let id = item && item.id ? item.id : "";
        let response = await taskController.taskComplete(id);
        if (response && response.status) {
            getTaskListing(page, patientId);
            new Toaster().success(response.message);
            getPatientDetail(patientId);
            setLoader(false);
        } else {
            setLoader(false);
        }
    };


    const applyFilters = async (filter) => {
        if (filter) {
            setFilterSortByValue(filter && filter.sortByAlpha);
            getTaskListing(1, patientId, filter && filter.sortByAlpha && filter.sortByAlpha.value ? filter.sortByAlpha.value : '');
        }
    };


    const handleClearAllFilters = () => {
        setFilterSortByValue(null);
        getTaskListing(1, patientId, '');
    };


    //<---------------------------------------Pazination Task Listing----------------------------------------------

    useEffect(() => {
        if (taskListCount) {
            handleTaskListPages(taskListCount);
        }
    }, [taskListCount]);


    const handlePage = (e) => {
        setPage(e);
        getTaskListing(e, patientId);
    };


    const handleTaskListPages = (count) => {
        let array = []
        const data = parseInt(count);
        const totalPage = Math.ceil(data / itemsPerPage);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i);
        }
        setTaskListTotalPages(array);
    };


    const handleTaskBackClick = () => {
        if (page > 1) {
            let newpage = page - 1;
            setPage(page - 1);
            getTaskListing(newpage, patientId);
        } else return;
    };


    const handleTaskNextClick = () => {
        if (page >= 1 && page < taskListTotalPages.length) {
            let newpage = page + 1;
            setPage(page + 1);
            getTaskListing(newpage, patientId);
        } else return;
    };


    //---------------------------------Report module--------------------------------------------------------------->

    const getReportsListng = async (page, patientId) => {
        let post = {
            patient_id: patientId ? [patientId] : patientData && patientData.id ? [patientData.id] : [],
            page: page ? page : 1,
            limit: 5
        };
        setLoader2(true);
        let response = await reportsController.reportsListing(post);
        if (response && response.status) {
            setReportListCount(response.count);
            setPatientReportsList(response.data);
            setLoader2(false);
        } else {
            setPatientReportsList(null);
            setLoader2(false);
        }
        setLoader2(false);
    };

    const handleDeleteReport = async (reportId) => {
        let id = reportId ? reportId : "";
        setLoader2(true);
        let response = await reportsController.reportDelete(id);
        if (response && response.status) {
            setDeleteReportsDetail(false)
            new Toaster().success(response.message);
            getReportsListng(reportsPage, patientId);
            getLogsListing(1, patientId);
            setLoader2(false);
        } else {
            setLoader2(false);
        }
    };

    const handleAddReport = async (values) => {
        let response = await reportsController.reportsCreate(
            values,
            patientData
        );
        if (response && response.status) {
            new Toaster().success(response.message);
            getReportsListng(1, patientId);
            setLoader2(false);
            getLogsListing(1, patientId);
            return response;
        } else {
            setLoader2(false);
            return null;
        }
    };

    const handleEditReport = async (values, reportsdata) => {
        let response = await reportsController.reportsUpdate(
            values,
            patientData,
            reportsdata ? reportsdata.id : null
        );
        if (response && response.status) {
            new Toaster().success(response.message);
            getReportsListng(1, patientId);
            setLoader2(false);
            getLogsListing(1, patientId);
            return response;
        } else {
            setLoader2(false);
            return null;
        }
    };


    const handleRenderImage = (item) => {                              //<----------------Report Image render
        let doc = item.attachment ? item.attachment : ''
        if (doc) {
            let ext = getExtension(item.attachment);
            if (ext) {
                if (ext === 'doc') {
                    return (
                        <Image
                            src={DocExt}
                            alt="..."
                        />
                    )
                }
                else if (ext === 'pdf') {
                    return (
                        <Image
                            src={PdfImg}
                            alt="..."
                        />
                    )
                }
                else {
                    return (
                        <Image
                            src={ImageExt}
                            alt="..."
                        />
                    )
                }
            }
        }
        else {
            return (
                <Image
                    src={ImageExt}
                    alt="..."
                />
            )
        }
    };


    //<---------------------------------------------Pazination Report Module-------------------------------------

    useEffect(() => {
        if (reportListCount) {
            handleReportsPages(reportListCount);
        }
    }, [reportListCount]);


    const handleReportsPage = (e) => {
        setReportPage(e);
        getReportsListng(e, patientId);
    };


    const handleReportsPages = (count) => {
        let array = []
        const data = parseInt(count);
        const totalPage = Math.ceil(data / itemsPerPage);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i);
        }
        setReportListTotalPages(array);
    };


    const handleReportsBackClick = () => {
        if (reportsPage > 1) {
            let newpage = reportsPage - 1;
            setReportPage(reportsPage - 1);
            getReportsListng(newpage, patientId);
        } else return;
    };


    const handleReportsNextClick = () => {
        if (reportsPage >= 1 && reportsPage < reportListTotalPages.length) {
            let newpage = reportsPage + 1;
            setReportPage(reportsPage + 1);
            getReportsListng(newpage, patientId);
        } else return;
    };



    //----------------------------------------------------Health module------------------------------------------------------------->

    const getHealthListing = async (page, patientId) => {
        let post = {
            patient_id: patientId ? [patientId] : [],
            page: page ? page : 1,
            limit: 5
        };
        setLoader2(true);
        let response = await vitalsController.vitalsListing(post);
        if (response && response.status) {
            setLoader2(false);
            setVitalsListCount(response.count);
            setVitalsList(response.data);
        } else {
            setLoader2(false);
            setVitalsList([]);
        }
    };


    const handleChange = (field, value) => {
        let validation = new Validation(isError);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };


    const updateHealth = async () => {
        let id = urlParams && urlParams.slug ? urlParams.slug : "";
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            let response = await vitalsController.updateVitals(
                patientData,
                values
            );
            if (response && response.status) {
                new Toaster().success(response.message);
                setShowUpdateVitalModal(false);
                getHealthListing(vitalsPage, patientId);
                getPatientDetail(id);
                getLogsListing(1, patientId);
            }
            else {
                setShowUpdateVitalModal(false);
            }
        } else {
            setError({ ...isValid.errors });
        }
    };


    //<--------------------------------------------------HEALTH Pazination ----------------------------------------------------------->

    useEffect(() => {
        if (vitalsListCount) {
            handleHealthListPages(vitalsListCount);
        }
    }, [vitalsListCount]);


    const handleHealthPage = (e) => {
        setVitalsPage(e);
        getHealthListing(e, patientId);
    };


    const handleHealthListPages = (count) => {
        let array = []
        const data = parseInt(count);
        const totalPage = Math.ceil(data / itemsPerPage);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i);
        }
        setVitalsListTotalPages(array);
    };


    const handleHealthBackClick = () => {
        if (vitalsPage > 1) {
            let newpage = vitalsPage - 1;
            setVitalsPage(newpage);
            getHealthListing(newpage, patientId);
        } else return;
    };


    const handleHealthNextClick = () => {
        if (vitalsPage >= 1 && vitalsPage < vitalsListTotalPages.length) {
            let newpage = vitalsPage + 1;
            setVitalsPage(newpage);
            getHealthListing(newpage, patientId);
        } else return;
    };


    const handleMRButton = () => {
        if (patientData &&
            patientData.cases &&
            patientData.cases.length > 0) {
            if (parseInt(patientData.cases[0].case_status) === 1) {
                navigate('/add-case', { state: { caseId: patientData.cases[0].id, name: 'editCase', fromNavigation: true } })
            }
            else {
                navigate('/add-case', { state: { caseId: patientData.cases[0].id, name: 'addCase', hospitalCaseId: patientData && patientData.cases[0] && patientData.cases[0].hospital_id, patientDetails: patientData, fromNavigation: true } })
            }
        }
    };


    //------------------------------------------Medicine module------------------------------------------------------->

    useEffect(() => {
        if (dateParam) {
            getMedicineListing(1)
        }
    }, [dateParam]);

    useEffect(() => {
        if (!isEmptyArray(completeMedicine)) {
            const resultArray = filterMedicineStatus(medicineList);
            if (arraysOfObjectsAreEqual(resultArray, completeMedicine)) {
                setSelectAllMedicine(true)
            } else {
                setSelectAllMedicine(false)
            }
        }
    }, [selectAllMedicine, completeMedicine]);

    const getMedicineListing = async (page, patienetId) => {
        let post = {
            patient_id: patienetId ? patienetId : patientData && patientData.id ? patientData.id : [],
            page: page ? page : 1,
            limit: 5,
            date: dateParam ? dateParam : ''
        };
        setLoader2(true);
        let response = await medicineController.medicineListing(post);
        if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]['checked'] = false;
            }
            setMedicineList(data);
            setMedicineCount(response.count)
            setLoader2(false);
        } else {
            setMedicineList([]);
            setMedicineCount(null)
            setLoader2(false);
        }
    };


    const handleDeleteMedicine = async () => {
        let data = deletMedicineDetail ? deletMedicineDetail : null
        if (data) {
            let array = [...medicineList]
            let id = data && data.medicine_id ? data.medicine_id : '';
            let ids = data && data.frequency_id ? data.frequency_id : ''
            let response = await medicineController.medicineDelete(id, ids);
            if (response && response.status) {
                new Toaster().success(response.message);
                let filterData = array.filter((data) => data.id !== data.id);
                if (filterData.length > 0) {
                    getMedicineListing(medicinePage);
                }
                else {
                    if (medicinePage > 1) {
                        let newpage = medicinePage - 1
                        setMedicinePage(medicinePage - 1);
                        getMedicineListing(newpage);
                    }
                    else {
                        setMedicinePage(medicinePage);
                        getMedicineListing(medicinePage);
                    }
                }
                getLogsListing(1, patientId);
                setDeletConfirmationModal(false);
            } else {
                setDeletConfirmationModal(false);
            }
        }
        else {
            setDeletConfirmationModal(false);
        }
    };


    const handleCompleteMedicine = async () => {
        let data = completeMedicineDetail ? completeMedicineDetail : null
        if (data) {
            let id = data && data.medicine_id ? data.medicine_id : '';
            let post = {
                medicine_status: 1,
                frequency_id: data && data.frequency_id ? data.frequency_id : ''
            }
            let response = await medicineController.completeMedicine(id, post);
            if (response && response.status) {
                new Toaster().success(response.message);
                getMedicineListing(medicinePage);
                setCompletMedicineConfimationModal(false);
                getLogsListing(1, patientId);
            }
            else {
                setCompletMedicineConfimationModal(false);
            }
        }
        else {
            setCompletMedicineConfimationModal(false);
        }
    };

    const handleCompleteAllMedicine = async () => {
        if (!isEmptyArray(completeMedicine)) {
            let response = await medicineController.completeAllMedicines(completeMedicine);
            if (response && response.status) {
                setSelectAllMedicine(false)
                setAllmedicineCompletePopup(false)
                new Toaster().success(response.message);
                setCompleteMedicine([]);
                getMedicineListing(medicinePage);
                getLogsListing(1, patientId);
            } else {
                setSelectAllMedicine(false)
                setAllmedicineCompletePopup(false)
                setCompleteMedicine([]);
            }
        } else {
            new Toaster().error('Please add atleast one medicine');
        }
    };



    const handleAllMedicine = (items) => {
        const newArray = [...completeMedicine];
        let array = [...medicineList];
        if (items) {
            array.forEach((item) => {
                if (!item.checked && parseInt(item.medicine_status) === 0) {
                    newArray.push({ id: item.medicine_id, frequency_id: item.frequency_id });
                    item.checked = true;
                }
            });
            setSelectAllMedicine(true)
            setCompleteMedicine(newArray);
        } else {
            array.forEach((item) => {
                item.checked = false;
            });
            setSelectAllMedicine(false)
            setCompleteMedicine([]);
        }
        setMedicineList(array)
    };

    // console.log(medicineList);


    const handleAddCommentInMedicineTab = async (value) => {
        let data = medicineDataForCommentApi ? medicineDataForCommentApi : null
        if (data) {
            let id = data && data.medicine_id ? data.medicine_id : '';
            let post = {
                comment: value && value.comment ? value.comment : '',
                frequency_id: data && data.frequency_id ? data.frequency_id : ''
            }
            let response = await medicineController.addComment(id, post);
            if (response && response.status) {
                new Toaster().success(response.message);
                getMedicineListing(medicinePage);
                setMedicineComment(false);
            }
            else {
                setMedicineComment(false);
            }
        }
        else {
            setMedicineComment(false);
        }
    };


    function filterMedicineStatus(array) {
        const filteredArray = array.filter(item => item.medicine_status === 0)
            .map(item => ({ id: item.medicine_id, frequency_id: item.frequency_id }));
        return filteredArray;
    }

    function arraysOfObjectsAreEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false;
        }

        // Sort the arrays based on id and frequency_id
        const sortedArr1 = arr1.slice().sort((a, b) => {
            if (a.id === b.id) {
                return a.frequency_id - b.frequency_id;
            }
            return a.id - b.id;
        });

        const sortedArr2 = arr2.slice().sort((a, b) => {
            if (a.id === b.id) {
                return a.frequency_id - b.frequency_id;
            }
            return a.id - b.id;
        });

        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i].id !== sortedArr2[i].id || sortedArr1[i].frequency_id !== sortedArr2[i].frequency_id) {
                return false;
            }
        }

        return true;
    }

    //-------------------------------------Medicine Pazination------------------------------------------------>

    useEffect(() => {
        if (medicineCount) {
            handleMedicinePages(medicineCount);
        }
    }, [medicineCount]);


    const handleMedicinePage = (e) => {
        setMedicinePage(e);
        getMedicineListing(e);
    };


    const handleMedicinePages = (count) => {
        let array = []
        const data = parseInt(count);
        const totalPage = Math.ceil(data / itemsPerPage);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i);
        }
        setMedicineTotalPages(array);
    };


    const handleMedicineBackClick = () => {
        if (medicinePage > 1) {
            let newpage = medicinePage - 1;
            setMedicinePage(medicinePage - 1);
            getMedicineListing(newpage);
        } else return;
    };


    const handleMedicineNextClick = () => {
        if (medicinePage >= 1 && medicinePage < medicineTotalPages.length) {
            let newpage = medicinePage + 1;
            setMedicinePage(medicinePage + 1);
            getMedicineListing(newpage);
        } else return;
    };


    //<---------------------------------------Logs Tab-------------------------------------------------


    useEffect(() => {
        if (logsDateParam) {
            getLogsListing(1, patientId);
        }
    }, [logsDateParam]);


    const getLogsListing = async (page, patientId) => {
        let post = {
            patient_id: patientId ? patientId : '',
            page: page ? page : 1,
            limit: 10,
            date: logsDateParam ? logsDateParam : '',
            role: User && (User.role_title === 'Nurse' || User.role_title === 'nurse') ? 'nurse' : ''
        };
        setLoader2(true);
        let response = await logsController.logsListing(post);
        if (response && response.status) {
            setLogsListing(response.data);
            setLogsCount(response.count);
            // setTotalLogsListLength(response);
            setLoader2(false);
        } else {
            setLogsListing([]);
            // setTotalLogsListLength(0);
            setLogsCount(null)
            setLoader2(false);
        }
    };


    useEffect(() => {
        if (logsCount) {
            handleLogsPages(logsCount);
        }
    }, [logsCount]);


    const onChangePage = (e) => {
        setLogsPage(e);
        getLogsListing(e, patientId);
    };


    const handleLogsPages = (count) => {
        let array = []
        const data = parseInt(count);
        const totalPage = Math.ceil(data / 10);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i);
        }
        setLogsTotalPages(array);
    };


    const handleLogsBackClick = () => {
        if (logsPage > 1) {
            let newpage = logsPage - 1;
            setLogsPage(logsPage - 1);
            getLogsListing(newpage, patientId);
        } else return;
    };


    const handleLogsNextClick = () => {
        if (logsPage >= 1 && logsPage < logsTotalPages.length) {
            let newpage = logsPage + 1;
            setLogsPage(logsPage + 1);
            getLogsListing(newpage, patientId);
        } else return;
    };

    ///////////////////////////----------------------------------lab module------------------------------///////////////////

    useEffect(() => {
        if (labDateParam) {
            getLabReportListing(1);
        }
    }, [labDateParam]);


    const getLabReportListing = async (page, id) => {
        let post = {
            patient_id: id ? id : patientData && patientData.id ? patientData.id : '',
            page: page ? page : 1,
            limit: 5,
            date: labDateParam ? labDateParam : ''
        };
        setLoader2(true);
        let response = await labController.patientLabTestList(post);
        if (response && response.status) {
            if (User && (User.role_title === 'Lab' || User.role_title === 'lab')) {
                setCurrentTab(3)
            }
            setLabListing(response.data);
            setLabCount(response.count);
            setTotalLabListLength(response.count);
            setLoader2(false);
        } else {
            setLabListing([]);
            // setTotalLogsListLength(0);
            setLabCount(0)
            setLoader2(false);
        }
        setLoader2(false);
    };

    useEffect(() => {
        if (labCount) {
            handleLabPages(labCount);
        }
    }, [labCount]);

    const onlabChangePage = (e) => {
        setLabPage(e);
        getLabReportListing(e, patientId);
    };

    const handleLabPages = (count) => {
        let array = []
        const data = parseInt(count);
        const totalPage = Math.ceil(data / 10);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i);
        }
        setLabTotalPages(array);
    };


    const handleLabBackClick = () => {
        if (labPage > 1) {
            let newpage = labPage - 1;
            setLabPage(labPage - 1);
            getLogsListing(newpage, patientId);
        } else return;
    };


    const handleLabNextClick = () => {
        if (labPage >= 1 && labPage < labTotalPages.length) {
            let newpage = labPage + 1;
            setLabPage(labPage + 1);
            getLabReportListing(newpage);
        } else return;
    };

    const deleteLabReportListing = async (id) => {

        let response = await labController.deletePatientLabTest(id);
        if (response && response.status) {
            setDeleteLabreport(false)
            if (User && (User.role_title === 'Lab' || User.role_title === 'lab')) {
                setCurrentTab(3)
            }
            new Toaster().success(response.message);
            getLabReportListing(1, patientId);
            setLoader2(false);
        } else {
            setLoader2(false);
            setDeleteLabreport(false)
        }

    };

    return {
        //<----------------------------Permissions 
        checkPermissions,
        checkTaskPermissions,
        checkVitalPermissions,
        checkReportPermissions,
        checkMedicinePermissions,
        checkLabPermissions,
        checkLogsPermissions,
        checkPatinetCasePermissions,
        getPatientModulePermissions,
        getTaskModulePermissions,
        getVitalModulePermissions,
        getReportsModulePermissions,
        getMedicineModulePermissions,
        getLabModulePermissions,
        getLogsModulePermissions,
        getPatientCaseModulePermissions,
        //<------------------------------
        setTabs,
        defaultTab,
        breadcrumData,
        breadcrumItem,
        navigate,
        loaction,
        showUpdateVitalModal,
        setShowUpdateVitalModal,
        showLogin,
        setShowLogin,
        currentTab,
        setCurrentTab,
        tabs,
        getPatientDetail,
        patientData,
        breadcrumItem,
        loader,
        loader2,
        medicalRecordTags,
        taskCompletedByAdmin,
        patientReportsList,
        handleTabData,
        handleDeleteReport,
        handleAddReport,
        handleEditReport,
        //<------------------------Task Tab
        getTaskListing,
        isUserCanCompleteTheTask,
        taskList,
        vitalsList,
        values,
        handleTaskBackClick,
        handleTaskNextClick,
        isError,
        taskListTotalPages,
        handleChange,
        getHealthListing,
        updateHealth,
        page,
        handlePage,
        setItemsPerPage,
        taskListCount,
        handleReportsPage,
        handleReportsBackClick,
        handleReportsNextClick,
        handleHealthPage,
        handleHealthBackClick,
        handleHealthNextClick,
        reportsPage,
        vitalsPage,
        reportListTotalPages,
        vitalsListTotalPages,
        profileModal,
        setProfileModal,
        handleBreadcrumDataNaviagtion,
        handleMRButton,
        manageMedicalRecordsTags,
        handleRenderImage,
        showFilterModal,
        setShowFilterModal,
        applyFilters,
        filterSortByValue,
        handleClearAllFilters,
        patientActiveCaseDetail,
        // setBreadcrumItem,
        patientStatus,
        setPatientStatus,
        chnagePatientStatus,
        setBreadcrumData,
        breadcrumData,
        //<--------------------medicine tab
        getMedicineListing,
        medicineList,
        setMedicineList,
        completeMedicine,
        setCompleteMedicine,
        handleMedicinePage,
        handleMedicineBackClick,
        handleMedicineNextClick,
        medicinePage,
        medicineTotalPages,
        handleDeleteMedicine,
        deletMedicineDetail,
        setDeletMedicineDetail,
        deletConfirmationModal,
        setDeletConfirmationModal,
        completMedicalConfirmationModal,
        setCompletMedicineConfimationModal,
        completeMedicineDetail,
        setCompleteMedicineDetail,
        handleCompleteMedicine,
        allmedicineCompletePopup,
        setAllmedicineCompletePopup,
        handleCompleteAllMedicine,
        handleAllMedicine,
        selectAllMedicine,
        setSelectAllMedicine,
        medicineComment,
        setMedicineComment,
        medicineDataForCommentApi,
        setMedicineDataForCommentApi,
        handleAddCommentInMedicineTab,
        dateParam,
        setDateParam,
        User,
        //<----------------------------Logs Tab
        handleLogsNextClick,
        handleLogsBackClick,
        onChangePage,
        LogsListing,
        logsPage,
        logsCount,
        logsTotalPages,
        logsDateParam,
        setLogsDateParam,
        totalLogsListLength,
        //---------------------LagTAb
        LabListing,
        labPage,
        labTotalPages,
        setLabPage,
        onlabChangePage,
        handleLabBackClick,
        handleLabNextClick,
        deleteLabreport,
        setDeleteLabreport,
        deleteLabReportListing,

        deleteReportsDetail,
        setDeleteReportsDetail,
        labDateParam,
        setLabDateParam,
        totalLabListLength
    };
};

export default PatientDetailFunctions;
