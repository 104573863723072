import React, { useState } from 'react'
import { actionsController } from '../../../../apis/Controllers/CommonController/action.controller';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toaster } from '../../../Helper/toaster/Toaster';
import medicineController from '../../../../apis/Controllers/MedicineController/medicine.controller';
import store from '../../../../redux/store';
import Validation from '../../../Helper/validations/vaildation'


const AssignMedicineFunction = () => {
    const defaultValues = {
        brandName: null,
        medicineName: null,
        salt: null,
        dosage: null,
        route: null,
        frequency: null,
    }
    const defaultErrors = {
        brandName: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        medicineName: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        salt: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        dosage: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        route: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        frequency: {
            rules: ["required"],
            isValid: true,
            message: "",
        },

    };
    const [isError, setError] = useState(defaultErrors);
    const [values, setValues] = useState(defaultValues);
    const [loader, setLoader] = useState(false);
    const breadcrumData = ['Dashboard', "Patient List", 'Patient Detail'];
    const breadcrumItem = ['Edit Medicine'];
    const [medicineFrequenciesList, setMedicineFrequenciesList] = useState([]);
    const [brandSuggestionsList, setBrandSuggestionsList] = useState([]);
    const [medicineSuggestionsList, setMedicineSuggestionsList] = useState([]);
    const [medicineDetail, setMedicineDetail] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();


    const handleChange = (field, value) => {
        let validation = new Validation(isError);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };

    const getMedicineDetail = async (id) => {
        setLoader(true);
        let response = await medicineController.medicineDetail(id);
        if (response && response.status) {
            let data = response.data
            setValues({
                ...values,
                brandName: data && data.brand_name ? data.brand_name : null,
                medicineName: data && data.medicine_name ? data.medicine_name : null,
                salt: data && data.salt ? data.salt : null,
                dosage: data && data.dosage ? data.dosage : null,
                route: data && data.route ? data.route : null,
                frequency: data && data.frequency_id && data.frequency_title ? { label: data.frequency_title, id: data.frequency_id } : null,
            })
            setMedicineDetail(response.data);
            setLoader(false);
        } else {
            setLoader(false);
            setMedicineDetail(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            setLoader(true)
            let id = medicineDetail && medicineDetail.id ? medicineDetail.id : ''
            let response = await medicineController.medicineUpdate(values, id);
            if (response && response.status) {
                new Toaster().success(response.message);
                navigate(`/patientDetails/${location.state.patientId}`, {
                    replace: true,
                    state: {
                        name: 'fromAssignMedicine'
                    }
                })
                setLoader(false);
            } else {
                setLoader(false);
            }
        } else {
            setError({ ...isValid.errors });
        }
    };


    const getMedicineFrequencyList = async () => {
        let response = await actionsController.medicineFrequencyList();
        if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]['label'] = data[i].title
                data[i]['value'] = data[i].title
            }
            setMedicineFrequenciesList(data);
        }
        else {
            setMedicineFrequenciesList([]);
        }
    };


    const getBrandSuggestionsList = async () => {
        let response = await medicineController.brandNameSuggestionsListing();
        if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]['label'] = data[i].brand_name
            }
            setBrandSuggestionsList(data);
        }
        else {
            setBrandSuggestionsList([]);
        }
    };


    const getMedicineSuggestionsList = async () => {
        let response = await medicineController.medicineNameSuggestionsList();
        if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]['label'] = data[i].medicine_name
            }
            setMedicineSuggestionsList(data);
        }
        else {
            setMedicineSuggestionsList([]);
        }
    };

    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate('/dashboard');
            }
            else {
                navigate('/dashboard-superadmin');
            }
        }
        else if (i === 1) {
            navigate('/manage-patient-list');
        }
        else if (i === 2) {
            navigate('/patientDetails', { state: { id: location.state.patientId } });
        }
        else return;
    };


    return {
        breadcrumData,
        breadcrumItem,
        isError,
        handleChange,
        handleSubmit,
        values,
        loader,
        getMedicineFrequencyList,
        medicineFrequenciesList,
        location,
        navigate,
        brandSuggestionsList,
        getBrandSuggestionsList,
        getMedicineSuggestionsList,
        medicineSuggestionsList,
        handleBreadcrumDataNaviagtion,
        getMedicineDetail
    }
}

export default AssignMedicineFunction