import React from "react";
import { Col, Container, Image, Modal, Row } from "react-bootstrap";
import { Assigned_Member, Hospital_Img, Man } from "../../../../assets/images";
import { renderImage } from "../../../Helper/General";
import { useNavigate } from "react-router-dom";

const MultipleHospitalPopup = (props) => {
    let navigate = useNavigate();
 
    return (
        <div >
            <Modal show={props.show} onHide={props.close} centered className="assigned_member_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Assigned Hospitals</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="assigned_member_area">
                                    <Row>
                                        {
                                            props && props.hospitals ?
                                                props.hospitals.map((item, i) => (
                                                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6}>
                                                        <div className="item" key={i}
                                                            onClick={() => navigate(`/hospital-detail/${item && item.id}`,
                                                            {
                                                                state: {
                                                                    fromNavigation: true
                                                                }
                                                            })}

                                                        >
                                                            <div className="member_img">
                                                                <Image
                                                                    src={item.image ? renderImage(item.image) : Hospital_Img}
                                                                    alt="..."
                                                                />
                                                            </div>
                                                            <h5
                                                                style={{ cursor: 'pointer', textTransform: 'capitalize' }}
                                                            >{item.title ? item.title : ''}</h5>
                                                        </div>
                                                    </Col>

                                                ))
                                                :
                                                null
                                        }
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default MultipleHospitalPopup;