import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Loader from "../../../Helper/loader";



const GeneralTab = (props) => {
    const {
        values,
        isError,
        loader
    } = props


    const handleChange = (field, value) => {
        props.handleChange(field, value);
    };


    const handleBackClick = () => {
        props.setCurrentTab('3');
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        props.handleSubmitStepFour();
    };

    return (
        <div className="general_tab_screen">
             {loader ? <Loader loader={loader} /> :  <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Form>
                            <Row>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Form.Group className="form-group">
                                        <Form.Label>Temperature (F)</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter temperature here"
                                            value={values.temperature}
                                            className={isError.temperature.message
                                                ? "error_border"
                                                : ""}
                                            onChange={(e) =>
                                                handleChange("temperature", e.target.value)
                                            }
                                        />
                                        {isError.temperature.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.temperature.message}
                                            </p>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Form.Group className="form-group">
                                        <Form.Label>Weight (Kg)</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter weight here"
                                            value={values.weight}
                                            className={isError.weight.message
                                                ? "error_border"
                                                : ""}
                                            onChange={(e) =>
                                                handleChange("weight", e.target.value)
                                            }
                                        />
                                        {isError.weight.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.weight.message}
                                            </p>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Form.Group className="form-group">
                                        <Form.Label>Heartbeat (BPM)</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Ex. 98"
                                            value={values.heartBeat}
                                            className={isError.heartBeat.message
                                                ? "error_border"
                                                : ""}
                                            onChange={(e) =>
                                                handleChange("heartBeat", e.target.value)
                                            }
                                        />
                                        {isError.heartBeat.message ? (
                                            <p className="text-danger mt-2">
                                                {isError.heartBeat.message}
                                            </p>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Form.Group className="form-group">
                                        <Form.Label>Blood Pressure (mm/hg)</Form.Label>
                                        <Row>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Ex. 102mm"
                                                    value={values.bloodPressureTop}
                                                    className={isError.bloodPressureTop.message
                                                        ? "error_border"
                                                        : ""}
                                                    onChange={(e) =>
                                                        handleChange("bloodPressureTop", e.target.value)
                                                    }
                                                />
                                                {isError.bloodPressureTop.message ? (
                                                    <p className="text-danger mt-2">
                                                        {isError.bloodPressureTop.message}
                                                    </p>
                                                ) : null}
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Ex. 72"
                                                    value={values.bloodPressureBottom}
                                                    className={isError.bloodPressureBottom.message
                                                        ? "error_border"
                                                        : ""}
                                                    onChange={(e) =>
                                                        handleChange("bloodPressureBottom", e.target.value)
                                                    }
                                                />
                                                {isError.bloodPressureBottom.message ? (
                                                    <p className="text-danger mt-2">
                                                        {isError.bloodPressureBottom.message}
                                                    </p>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Form.Group className="form-group">
                                    <Form.Label>Oxygen Saturation (Spo2/Prbpm)</Form.Label>
                                    <Row>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                            <Form.Control
                                                type="number"
                                                placeholder="Ex. 97 SPo2"
                                                value={values.oxygenSaturationTop}
                                                className={isError.oxygenSaturationTop.message
                                                    ? "error_border"
                                                    : ""}
                                                onChange={(e) =>
                                                    handleChange("oxygenSaturationTop", e.target.value)
                                                }
                                            />
                                            {isError.oxygenSaturationTop.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError.oxygenSaturationTop.message}
                                                </p>
                                            ) : null}
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                            <Form.Control
                                                type="number"
                                                placeholder="Ex. 60PRbpm"
                                                value={values.oxygenSaturationBottom}
                                                className={isError.oxygenSaturationBottom.message
                                                    ? "error_border"
                                                    : ""}
                                                onChange={(e) =>
                                                    handleChange("oxygenSaturationBottom", e.target.value)
                                                }
                                            />
                                            {isError.oxygenSaturationBottom.message ? (
                                                <p className="text-danger mt-2">
                                                    {isError.oxygenSaturationBottom.message}
                                                </p>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Form.Group className="form-group">
                                    <Form.Label>Blood Sugar (mg/dl)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Ex. 104mg/dl"
                                        value={values.bloodSuger}
                                        className={isError.bloodSuger.message
                                            ? "error_border"
                                            : ""}
                                        onChange={(e) =>
                                            handleChange("bloodSuger", e.target.value)
                                        }
                                    />
                                    {isError.bloodSuger.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.bloodSuger.message}
                                        </p>
                                    ) : null}
                                </Form.Group>
                            </Col>
                            <div className="btn_area">
                                <Button className="cancelBtn" type="button" onClick={handleBackClick}>Back</Button>
                                <Button className="submitBtn" type="submit" onClick={handleSubmit}>Submit</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>}
        </div >
    )
};

export default GeneralTab;