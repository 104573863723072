import React from "react";
import { faEye, faEyeSlash, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import AuthHeader from "../../../Helper/authHeader";
import * as Constants from "../../../Helper/constants/constants";
import LoginFunctions from "./function";
import ButtonLoader from "../../../Helper/buttonLoader";


const Login = () => {
    const {
        values,
        isError,
        handleChange,
        handleLogin,
        loader,
        isVisiblePassword,
        setVisiblePassword,
        navigate
    } = LoginFunctions();



    return (
        <div className="login_screen">
            <AuthHeader
                header={Constants.LOGIN_HEADER}
                description={Constants.LOGIN_DESCRIPTION}
            />
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="form">
                            <Form>
                                <Form.Group className="form-group">
                                    <Form.Label>
                                        Enter your email address
                                    </Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="test@gmail.com"
                                        value={values.email}
                                        className={isError.email.message
                                            ? "error_border"
                                            : ""}
                                        onChange={(e) =>
                                            handleChange(
                                                "email",
                                                e.target.value
                                            )
                                        }
                                    />
                                    {isError.email.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.email.message}
                                        </p>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="form-group">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type={!isVisiblePassword ? "password" : "text"}
                                        placeholder="************"
                                        className={isError.password.message
                                            ? "error_border"
                                            : ""}
                                        value={values.password}
                                        autoComplete="none"
                                        onChange={(e) =>
                                            handleChange(
                                                "password",
                                                e.target.value
                                            )
                                        }
                                    />
                                    <span onClick={() => setVisiblePassword(!isVisiblePassword)}>
                                        {isVisiblePassword ? <FontAwesomeIcon
                                            icon={faEye}
                                            className="eye_icon"
                                        /> : <FontAwesomeIcon
                                            icon={faEyeSlash}
                                            className="eye_icon"
                                        />}
                                    </span>
                                    {isError.password.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.password.message}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <a
                                    className="forget_password"
                                    onClick={() => navigate('/forgot-Password')}
                                >
                                    Forgot Password{" "}
                                    <FontAwesomeIcon icon={faQuestion} />
                                </a>
                                <Button
                                    type="submit"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleLogin();
                                    }}
                                    disabled={loader ? true : false}
                                >
                                    {loader ? (
                                        <ButtonLoader
                                            loader={loader}
                                            variant={"light"}
                                        />
                                    ) : null}
                                    {loader ? "Loading..." : "Log in"}
                                </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Login;
