import React, { useEffect, useState } from "react";
import { Button, Col, Container, Dropdown, Form, Image, Row } from "react-bootstrap";
//-------- HEADER COMPONENT IMPORT ---------//
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import ButtonLoader from "../../../../Helper/buttonLoader"; 
import Select from 'react-select';
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import { Radiology } from "../../../../../assets/images";
import { useNavigate } from "react-router-dom";
import store from "../../../../../redux/store";
// import * as Constants from "../../../Helper/constants/constants";
import * as Constants from '../../../../Helper/constants/constants'
const RoomWithBed = () => {
      const navigate = useNavigate();
   const [breadcrumItem,setBreadcrumItem]=useState('Create room')
   var breadcrumData = ["Dashboard"];
    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 0) {
                navigate('/dashboard');
            }
            else {
                navigate('/dashboard-superadmin');
            }
        }
        else if (i === 1) {
            navigate('/dashboard');
        }
        else return;
    };

    return (
        <div className="assign_task_screen">
            <WithoutImageHeader
                header={ Constants.ROOM_WiITH_BED}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                 handleBack={() => navigate(-1)}
                 handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
            />
            <div className="parent_area">
                <div className="innerr_area">
                    <Container>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="form">
                                    <Form>
                                        <Row>
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <div className="dropdown_box">
                                                    <h6>Hospital Name<span>*</span></h6>
                                                    <div className="dropdown_area">
                                                        <Select
                                                            placeholder="Select Hospital"
                                                            // className={"error_border_select"}
                                                        />
                                                        {/* {isError.patientData.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError.patientData.message}
                                                            </p>
                                                        ) : null} */}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <div className="dropdown_box">
                                                    <h6>Ward Name<span>*</span></h6>
                                                    <div className="dropdown_area" >
                                                        <Select
                                                            placeholder="Select Ward"
                                                            // className={"error_border_select"}
                                                        />
                                                        {/* {isError.caseData.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError.caseData.message}
                                                            </p>
                                                        ) : null} */}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Room Number</Form.Label>
                                                    <span>*</span>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter room number"
                                                    />
                                                    {/* {isError.patientData.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.patientData.message}
                                                        </p>
                                                    ) : null} */}
                                                </Form.Group>
                                            </Col>

                                             <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Available Bed</Form.Label>
                                                    <span>*</span>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter available bed"
                                                    />
                                                    <a>
                                                        <div className="icon" >
                                                            {/* <FontAwesomeIcon icon={faRadio} /> */}
                                                            <Image src={Radiology} alt="..." />
                                                        </div>
                                                    </a>
                                                    {/* {isError.patientData.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.patientData.message}
                                                        </p>
                                                    ) : null} */}
                                                </Form.Group>
                                            </Col>
       
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                               <div className="bed_info_title"><h5 >Bed Information</h5></div>
                                            </Col>

                                            {[1, 2, 3].map((item, index) => {
                                                return (
                                                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Bed Number</Form.Label>
                                                    <span>*</span>
                                                    <Form.Control
                                                        type="text"
                                                                placeholder="Enter Bed Number"
                                                                value={index+1}
                                                    />
                                                    <a>
                                                        <div className="icon" >
                                                            {/* <FontAwesomeIcon icon={faRadio} /> */}
                                                            <Image src={Radiology} alt="..." />
                                                        </div>
                                                    </a>
                                                    {/* {isError.patientData.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.patientData.message}
                                                        </p>
                                                    ) : null} */}
                                                </Form.Group>
                                            </Col>
                                               )
                                           })}
                                          
                                           
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default RoomWithBed;
