import { useEffect, useRef, useState } from "react";
import patientController from "../../../../../apis/Controllers/PatientController/patient.controller";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import Validation from "../../../../Helper/validations/vaildation";
import { useLocation, useNavigate } from "react-router-dom";
import wardController from "../../../../../apis/Controllers/WardController/ward.controller";
import rolesController from "../../../../../apis/Controllers/RolesController/roles.controller";
import bedsController from "../../../../../apis/Controllers/BedsControoler/beds.controller";
import { actionsController } from "../../../../../apis/Controllers/CommonController/action.controller";
import roomsController from "../../../../../apis/Controllers/RoomsController/rooms.controller";
import departmentController from "../../../../../apis/Controllers/DepartmentController/department.controller";
import adminController from "../../../../../apis/Controllers/AdminControler/admin.controller";
import store from "../../../../../redux/store";
import hospitalController from "../../../../../apis/Controllers/HospitalController/hospital.controller";
import moment from "moment/moment";

const AddPatientFunctions = () => {
    const breadcrumData = ['Dashboard', "Patient List"];
    const breadcrumItem = ['Add Patient'];
    const [currentTab, setCurrentTab] = useState('1');
    const tabs = [
        {
            id: 1,
            tabTitle: 'Demographic Detail',
        },
        {
            id: 2,
            tabTitle: 'Address',
        },
        {
            id: 3,
            tabTitle: 'Consultant',
        },
        {
            id: 4,
            tabTitle: 'General',
        }
    ];

    const defaultValues = {
        stepOne: {
            image: null,
            hospitalId: null,
            uhId: null,
            patientName: null,
            ward: null,
            roomNo: null,
            bedNo: null,
            patientStatus: null,
            patientType: null,
            emergencyAdmission: 0,
            fatherOrHusbandName: null,
            motherName: null,
            dob: null,
            dateOfAdmission: null,
            gender: null,
            bloodGroup: null,
            age: null,
            martialStatus: null,
            occupation: null,
            nationality: null,
            adharNumber: null,
            step: 1,
        },
        stepTwo: {
            address: null,
            area: null,
            pincode: null,
            districtData: null,
            stateData: null,
            cityData: null,
            contactNumber: null,
            alternativeNumber: null,
            email: null,
            steps: 2
        },
        stepThree: {
            // department: null,
            consultantData: null,
            caseTitle: null,
            tag: null,
            summary: null,
            steps: 3
        },
        stepFour: {
            temperature: null,
            weight: null,
            heartBeat: null,
            bloodPressureTop: null,
            bloodPressureBottom: null,
            oxygenSaturationTop: null,
            oxygenSaturationBottom: null,
            bloodSuger: null,
            steps: 4
        },

    };

    const defaultErrors = {
        image: {
            rules: [],
            isValid: true,
            message: "",
        },
        hospitalId: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        uhId: {
            rules: ["required", "alphanumeric"],
            isValid: true,
            message: "",
        },
        patientName: {
            rules: ["required", "alphabetic"],
            isValid: true,
            message: "",
        },
        ward: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        roomNo: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        bedNo: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        patientStatus: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        patientType: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        fatherOrHusbandName: {
            rules: ["required", "alphabetic"],
            isValid: true,
            message: "",
        },
        motherName: {
            rules: [],
            isValid: true,
            message: "",
        },
        dob: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        dateOfAdmission: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        gender: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        bloodGroup: {
            rules: [],
            isValid: true,
            message: "",
        },
        age: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        martialStatus: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        occupation: {
            rules: [""],
            isValid: true,
            message: "",
        },
        nationality: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        adharNumber: {
            rules: [],
            isValid: true,
            message: "",
        },
        emergencyAdmission: {
            rules: [""],
            isValid: true,
            message: "",
        },
        //---------second step----------
        address: {
            rules: [""],
            isValid: true,
            message: "",
        },
        area: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        pincode: {
            rules: ["numeric", "max:6"],
            isValid: true,
            message: "",
        },
        districtData: {
            rules: [],
            isValid: true,
            message: "",
        },
        stateData: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        cityData: {
            rules: [],
            isValid: true,
            message: "",
        },
        contactNumber: {
            rules: ["required", "numeric", "max:10", "min:10"],
            isValid: true,
            message: "",
        },
        alternativeNumber: {
            rules: ["numeric", "max:10", "min:10"],
            isValid: true,
            message: "",
        },
        email: {
            rules: [],
            isValid: true,
            message: "",
        },
        //<------------------three step---------
        department: {
            rules: [],
            isValid: true,
            message: "",
        },
        consultantData: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        caseTitle: {
            rules: [],
            isValid: true,
            message: "",
        },
        tag: {
            rules: [],
            isValid: true,
            message: "",
        },
        summary: {
            rules: [],
            isValid: true,
            message: "",
        },
        //<---------Fourt step -------
        temperature: {
            rules: ["numeric", "max:3"],
            isValid: true,
            message: "",
        },
        weight: {
            rules: ["numeric", "max:3"],
            isValid: true,
            message: "",
        },
        heartBeat: {
            rules: ["numeric", "max:3"],
            isValid: true,
            message: "",
        },
        bloodPressureTop: {
            rules: ["numeric", "max:3"],
            isValid: true,
            message: "",
        },
        bloodPressureBottom: {
            rules: ["numeric", "max:3"],
            isValid: true,
            message: "",
        },
        oxygenSaturationTop: {
            rules: ["numeric", "max:3"],
            isValid: true,
            message: "",
        },
        oxygenSaturationBottom: {
            rules: ["numeric", "max:3"],
            isValid: true,
            message: "",
        },
        bloodSuger: {
            rules: ["numeric", "max:3"],
            isValid: true,
            message: "",
        },
    };


    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);
    const [loader, setLoader] = useState(false);
    const [hospitalList, setHospitalList] = useState([]);
    const [wardsList, setWardsList] = useState([]);
    const [roomsList, setRoomsList] = useState([]);
    const [bedsList, setbedsList] = useState([]);
    const [bloodGroupsList, setBloodGroupsList] = useState([]);
    const [martialStatusList, setMartialStatusList] = useState([]);
    const [patientTypesList, setPatientTypesList] = useState([]);
    const [gendersList, setGendersList] = useState([]);
    const [nationalitiesList, setNationalitiesList] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [districtsList, setDistrictsList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [consultantsList, setConsultantsList] = useState([]);
    const [patientId, setPatientId] = useState(null);
    const [patientCreateOrUpdateValue, setPatinetCreateOrUpdateValue] = useState(0); //<-----------------For manage Api on first step -----> value = 0 ? create Api : value = 1 ? update Api
    const loaction = useLocation();
    const navigate = useNavigate();


    const handleChange = (field, value, step) => {
        let err = { ...isError }
        if (field === 'email') {
            if (value.length > 0) {
                err = {
                    ...isError,
                    email: {
                        rules: ['email'],
                        isValid: true,
                        message: '',
                    },
                };
            }
            else {
                err = {
                    ...isError,
                    email: {
                        rules: [''],
                        isValid: true,
                        message: '',
                    },
                };
            }
        }
        if (field === 'adharNumber') {
            if (value.length > 0) {
                err = {
                    ...isError,
                    adharNumber: {
                        rules: ["adharWith4DigitsMessage"],
                        isValid: true,
                        message: '',
                    },
                };
            }
            else {
                err = {
                    ...isError,
                    adharNumber: {
                        rules: [''],
                        isValid: true,
                        message: '',
                    },
                };
            }
        }
        else if (field === 'alternativeNumber') {
            if (value.length > 0) {
                err = {
                    ...isError,
                    alternativeNumber: {
                        rules: ["max:13", "min:8"],
                        isValid: true,
                        message: '',
                    },
                };
            }
            else {
                err = {
                    ...isError,
                    alternativeNumber: {
                        rules: [''],
                        isValid: true,
                        message: '',
                    },
                };
            }
        }
        else if (field === 'motherName') {
            if (value.length > 0) {
                err = {
                    ...isError,
                    motherName: {
                        rules: ["alphabetic"],
                        isValid: true,
                        message: '',
                    },
                };
            }
            else {
                err = {
                    ...isError,
                    motherName: {
                        rules: [''],
                        isValid: true,
                        message: '',
                    },
                };
            }
        }
        else if (field === 'occupation') {
            if (value.length > 0) {
                err = {
                    ...isError,
                    occupation: {
                        rules: ["alphabetic"],
                        isValid: true,
                        message: '',
                    },
                };
            }
            else {
                err = {
                    ...isError,
                    occupation: {
                        rules: [''],
                        isValid: true,
                        message: '',
                    },
                };
            }
        }
        else {
            err = { ...isError }
        }
        let Validations = new Validation(err);
        let node = Validations.validateField(field, value);
        setError({ ...isError, [field]: node });
        if (
            value instanceof Date ||
            value instanceof Object ||
            value instanceof Array ||
            typeof value == 'boolean'
        ) {
            setValues({
                ...values,
                [step]: {
                    ...values[step],
                    [field]: value ? value : null,
                },
            });
        } else {
            setValues({
                ...values,
                [step]: {
                    ...values[step],
                    [field]: value,
                },
            });
        }
        if (field === 'hospitalId') {
            setValues({
                ...values,
                stepOne: {
                    ...values.stepOne,
                    hospitalId: value,
                    ward: null,
                    roomNo: null,
                    bedNo: null
                },
            });
        }
        else if (field === 'ward' && values.stepOne.roomNo || field === 'ward' && values.stepOne.bedNo) {
            setValues({
                ...values,
                stepOne: {
                    ...values.stepOne,
                    ward: value,
                    roomNo: null,
                    bedNo: null
                },
            });
        }
        else if (field === 'stateData' && values.stepTwo.districtData || field === 'stateData' && values.stepTwo.cityData) {
            setValues({
                ...values,
                stepTwo: {
                    ...values.stepTwo,
                    stateData: value,
                    cityData: null,
                    districtData: null
                },
            });
        }
    };


    const handleEmergencyStatus = (e) => {
        let status = { ...values.stepOne.emergencyAdmission }
        if (e.target.checked === true) {
            status = 1
        }
        else {
            status = 0
        }
        handleChange('emergencyAdmission', status, 'stepOne')
    };


    const handleSubmitStepOne = async () => {
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values.stepOne);
        if (isValid && !isValid.haveError) {
            // if (patientCreateOrUpdateValue === 0) {                                                 //<---------------------Create Patient
            //     setLoader(true);
            //     let response = await patientController.patientCreateStepOne(values.stepOne);
            //     if (response && response.status) {
            //         // new Toaster().success(response.message);
            //         setCurrentTab('2');
            //         setLoader(false);
            //         setPatientId(response.data.id);
            //         setPatinetCreateOrUpdateValue(1);
            //     } else {
            //         setLoader(false);
            //     }
            // }
            // else {                                                                                   //<-------------------Update Patient first step
            //     setLoader(true);
            //     let response = await patientController.patientUpdateStepOne(patientId, values.stepOne);
            //     if (response && response.status) {
            //         // new Toaster().success(response.message);
            //         setLoader(false);
            //     } else {
            //         setLoader(false);
            //     }
            // }
            setCurrentTab('2');
        } else {
            setError({ ...isValid.errors });
        }
    };


    const handleSubmitStepTwo = async () => {
        let valuesOne = { ...values.stepOne }
        let valuesTwo = { ...values.stepTwo }
        let multiValues = { ...valuesOne, ...valuesTwo }
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(multiValues);
        if (isValid && !isValid.haveError) {
            // setLoader(true);
            // let response = await patientController.patientCreateStepTwo(patientId, values.stepOne, values.stepTwo);
            // if (response && response.status) {
            //     // new Toaster().success(response.message);
            //     setLoader(false);
            // } else {
            //     setLoader(false);
            // }
            setCurrentTab('3');
        } else {
            setError({ ...isValid.errors });
        }
    };


    const handleSubmitStepThree = async () => {
        let valuesOne = { ...values.stepOne }
        let valuesTwo = { ...values.stepTwo }
        let valuesThree = { ...values.stepThree }
        let multiValues = { ...valuesOne, ...valuesTwo, ...valuesThree }
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(multiValues);
        if (isValid && !isValid.haveError) {
            // setLoader(true);
            // let response = await patientController.patientCreateStepThree(patientId, values.stepOne, values.stepThree);
            // if (response && response.status) {
            //     // new Toaster().success(response.message);
            //     setLoader(false);
            // } else {
            //     setLoader(false);
            // }
            setCurrentTab('4');
        } else {
            setError({ ...isValid.errors });
        }
    };


    // const handleSubmitStepFour = async () => {
    //     let valuesOne = { ...values.stepOne }
    //     let valuesTwo = { ...values.stepTwo }
    //     let valuesThree = { ...values.stepThree }
    //     let valuesFour = { ...values.stepFour }
    //     let multiValues = { ...valuesOne, ...valuesTwo, ...valuesThree, ...valuesFour }
    //     let validation = new Validation(isError);
    //     let isValid = validation.isFormValid(multiValues);
    //     if (isValid && !isValid.haveError) {
    //         setLoader(true);
    //         let response = await patientController.patientCreateStepFour(patientId, values.stepOne, values.stepFour);
    //         if (response && response.status) {
    //             new Toaster().success(response.message);
    //             navigate('/manage-patient-list');
    //             setValues(defaultValues);
    //             setPatinetCreateOrUpdateValue(0);
    //             setLoader(false);
    //         } else {
    //             setLoader(false);
    //         }
    //     } else {
    //         setError({ ...isValid.errors });
    //     }
    // };

    const handleSubmitStepFour = async () => {
        let valuesOne = { ...values.stepOne }
        let valuesTwo = { ...values.stepTwo }
        let valuesThree = { ...values.stepThree }
        let valuesFour = { ...values.stepFour }
        let multiValues = { ...valuesOne, ...valuesTwo, ...valuesThree, ...valuesFour }
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(multiValues);
        if (isValid && !isValid.haveError) {
            setLoader(true);
            let response = await patientController.addPatientNew(values.stepOne, values.stepTwo, values.stepThree, values.stepFour);
            if (response && response.status) {
                new Toaster().success(response.message);
                navigate('/manage-patient-list');
                setValues(defaultValues);
                setPatinetCreateOrUpdateValue(0);
                setLoader(false);
            } else {
                setLoader(false);
            }
        } else {
            setError({ ...isValid.errors });
        }
    };

    const patientStatusListing = [
        {
            id: 1,
            title: 'Admitted',
            label: 'Admitted',
            value: 'admitted',
        },
        {
            id: 2,
            title: 'MMX',
            label: 'MMX',
            value: 'mmx',
        },
        {
            id: 3,
            title: 'On Ventilator',
            label: 'On Ventilator',
            value: 'on_ventilator',
        },
        {
            id: 4,
            title: 'On TPI',
            label: 'On TPI',
            value: 'on_tpi',
        },
        {
            id: 4,
            title: 'MI',
            label: 'MI',
            value: 'mi',
        },
        {
            id: 4,
            title: '72hrs',
            label: '72hrs',
            value: '72hrs',
        },
    ];


    const getHospitalListing = async () => {
        let response = await hospitalController.hospitalListDashboard();
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setHospitalList(data);
            if (!loaction.state) {                               //<--------------auto select hospital case on the base of global seleted Hospital
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                let hospitalData = {
                    title: item && item.title ? item.title : '',
                    id: item && item.id ? item.id : '',
                    label: item && item.title ? item.title : ''
                }
                setValues({
                    ...values,
                    stepOne: {
                        ...values.stepOne,
                        hospitalId: item ? hospitalData : null
                    },
                })
                getWardsListing([item.id]);
                getAdminsListing(item.id);
            }
        }
        else {
            setHospitalList([]);
        }
    };


    const getWardsListing = async (idArray) => {
        let post = {
            hospital_id: idArray ? idArray : []
        }
        let response = await wardController.wardListing(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setWardsList(data);
        }
        else {
            setWardsList([]);
        }
    };


    const getRoomsListing = async (id) => {
        let post = {
            ward_id: id ? id : '',
            hospital_id: values && values.stepOne && values.stepOne.hospitalId && values.stepOne.hospitalId.id ? [values.stepOne.hospitalId.id] : []
        }
        let response = await roomsController.roomListing(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].room_number
            }
            setRoomsList(data);
        }
        else {
            setRoomsList([]);
        }
    };


    const getBedsListing = async (id) => {
        let post = {
            room_id: id ? id : ''
        }
        let response = await bedsController.bedsListing(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].bed_number
            }
            setbedsList(data);
        }
        else {
            setbedsList([]);
        }
    };


    const getBoodGroupListing = async () => {
        let response = await actionsController.bloodGroups();
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setBloodGroupsList(data);
        }
        else {
            setBloodGroupsList([]);
        }
    };


    const getMartialStatusListing = async () => {
        let response = await actionsController.martialStatusList();
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setMartialStatusList(data);
        }
        else {
            setMartialStatusList([]);
        }
    };


    const getPatientTypesListing = async () => {
        let response = await actionsController.patientTypes();
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setPatientTypesList(data);
        }
        else {
            setPatientTypesList([]);
        }
    };


    const getGendersListing = async () => {
        let response = await actionsController.gendersList();
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setGendersList(data);
        }
        else {
            setGendersList([]);
        }
    };


    const getNationalitiesListing = async () => {
        let response = await actionsController.nationalities();
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setNationalitiesList(data);
        }
        else {
            setNationalitiesList([]);
        }
    };


    const getStatesListing = async (id) => {
        let post = {
            country_id: id ? id : ''
        }
        let response = await actionsController.statesList(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setStatesList(data);
        }
        else {
            setStatesList([]);
        }
    };


    const getDistrictsListing = async (id) => {
        let post = {
            state_id: id ? id : ''
        }
        let response = await actionsController.districtsList(post);
        if (response && response.status) {
            let data = response.data;
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setDistrictsList(response.data);
        }
        else {
            setDistrictsList([]);
        }
    };


    const getCitiesListing = async (id) => {
        let post = {
            state_id: id ? id : ''
        }
        let response = await actionsController.citiesList(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setCitiesList(response.data);
        }
        else {
            setCitiesList([]);
        }
    };


    const getDepartmentsListing = async (hospitalId) => {
        let post = {
            hospital_id: hospitalId ? hospitalId : []
        }
        let response = await departmentController.departmentList(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                data[i]['label'] = data[i].title
            }
            setDepartmentList(response.data);
        }
        else {
            setDepartmentList([]);
        }
    };


    const getAdminsListing = async (id) => {
        let post = {
            hospital_id: id ? [id] : [],
        }
        let response = await adminController.consultantListing(post);
        if (response && response.status) {
            let data = response.data
            for (let i in data) {
                let department = data[i] && data[i].department_title ? `(${data[i].department_title})` : null;
                data[i]['label'] = `${data[i].name} ` + department;
            }
            setConsultantsList(response.data);
        }
        else {
            setConsultantsList([]);
        }
    };


    const handleWardDropDown = () => {
        if ((values && values.stepOne) && (values.stepOne.hospitalId === null)) {
            new Toaster().error('Please select Hospital');
        }
        else return;
    };


    const handleRoomNoDropdown = () => {
        if ((values && values.stepOne) && (values.stepOne.ward === null)) {
            new Toaster().error('Please select ward');
        }
        else return;
    };


    const handleBedNoDropdown = () => {
        if ((values && values.stepOne) && (values.stepOne.roomNo === null)) {
            new Toaster().error('Please select Room Number');
        }
        else return;
    };


    const handleDistrictAndCityDropDown = () => {
        if ((values && values.stepTwo) && (values.stepTwo.stateData === null)) {
            new Toaster().error('Please select State');
        }
        else return;
    };


    const handleCityDropDown = () => {
        if ((values && values.stepTwo) && (values.stepTwo.districtData === null)) {
            new Toaster().error('Please select District');
        }
        else return;
    };

    const handleConsultantDropdown = () => {
        if ((values && values.stepThree) && (values.stepThree.department === null)) {
            new Toaster().error('Please select Department');
        }
        else return;
    };


    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate('/dashboard');
            }
            else {
                navigate('/dashboard-superadmin');
            }
        }
        else if (i === 1) {
            navigate('/manage-patient-list');
        }
        else return;
    };

    //------------set Age from date of birth---->
    const getmonthyears = () => {
        let dt = values && values.stepOne && values.stepOne.dob;
        let age = '';
        let bY = Number(moment(dt).format('YYYY'));
        let bM = Number(moment(dt).format('MM'));
        let bD = Number(moment(dt).format('DD'));
        let tY = Number(moment().format('YYYY'));
        let tM = Number(moment().format('MM'));
        let tD = Number(moment().format('DD'));
        age += (tY - bY) + "  ";
        if (tM < bM) {
            age += (tM - bM + 12) + "  ";
            tY = tY - 1;
        } else {
            age += (tM - bM) + "  ";
        } if (tD < bD) {
            age += (tD - bD + 30);
            tM = tM - 1;
        } else {
            age += (tD - bD);
        }
        let years = age.toString().slice(0, 2)
        let months = age.toString().slice(2, 6)
        let days = age.toString().slice(6, 10)
        let data = {
            years: years && parseInt(years) > 0 ? years : null,
            months: months && parseInt(months) > 0 ? months : null,
            days: days && parseInt(days) > 0 ? days : null
        }
        let newAge = null;
        if (data.days) {
            newAge = `${data.days} Days`
        }
        if (data.years) {
            newAge = `${data.years} Years `
        }
        if (data.years && data.months) {
            newAge = `${data.years} Years, ${data.months} Months`
        }
        if (data.years && data.days) {
            newAge = `${data.years} Years, ${data.days} Days`
        }
        if (data.months && data.days) {
            newAge = `${data.months}Months, ${data.days} Days`
        }
        if (data.years && data.months && data.days) {
            newAge = `${data.years} Years, ${data.months} Months, ${data.days} Days`
        } if (values.stepOne.dob === moment().format('YYYY-MM-DD')) {
            newAge = `1 Day`
        }

        setValues({
            ...values,
            stepOne: {
                ...values.stepOne,
                age: newAge ? newAge : null,
            },
        });
    }

    useEffect(() => {
        getmonthyears();
    }, [values && values.stepOne && values.stepOne.dob])

    return {
        breadcrumData,
        breadcrumItem,
        currentTab,
        setCurrentTab,
        tabs,
        values,
        setValues,
        isError,
        loaction,
        loader,
        handleChange,
        navigate,
        getHospitalListing,
        getWardsListing,
        getRoomsListing,
        getBedsListing,
        getBoodGroupListing,
        getMartialStatusListing,
        getPatientTypesListing,
        getNationalitiesListing,
        getStatesListing,
        getDistrictsListing,
        getCitiesListing,
        getGendersListing,
        getDepartmentsListing,
        getAdminsListing,
        patientStatusListing,
        hospitalList,
        wardsList,
        roomsList,
        bedsList,
        bloodGroupsList,
        martialStatusList,
        patientTypesList,
        nationalitiesList,
        gendersList,
        statesList,
        districtsList,
        citiesList,
        departmentList,
        consultantsList,
        handleEmergencyStatus,
        handleWardDropDown,
        handleRoomNoDropdown,
        handleBedNoDropdown,
        handleSubmitStepOne,
        handleSubmitStepTwo,
        handleSubmitStepThree,
        handleSubmitStepFour,
        handleDistrictAndCityDropDown,
        handleCityDropDown,
        handleConsultantDropdown,
        handleBreadcrumDataNaviagtion,
        patientCreateOrUpdateValue,
    }
};

export default AddPatientFunctions;