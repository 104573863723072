import { useState } from "react";
import Validation from "../../../Helper/validations/vaildation";



export const AddCommentModalFunctions = (props) => {
    const defaultValues = {
        comment: null,
    };

    const defaultErrors = {
        comment: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
    };

    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);
    const [loader, setLoader] = useState(false);


    const handleChange = (field, value) => {
        let validation = new Validation(isError);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };


    const handleSubmit = async () => {
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            props.handleAddComment(values);                             //<------------callback funtion from Reports Tab 
        } else {
            setError({ ...isValid.errors });
        }
    };


    return {
        values,
        setValues,
        isError,
        loader,
        handleChange,
        handleSubmit
    }
}