import ActionType from "../constants/index";

export const setUserData = (param) => {
    return {
        type: ActionType.SET_USER_DATA,
        payload: param,
    };
};

export const setAPIToken = (param) => {
    let stored = localStorage.setItem("SET_API_TOKEN", JSON.stringify(param));
    return {
        type: ActionType.SET_API_TOKEN,
        payload: param,
    };
};

export const setScroller = (param) => {
    let stored = localStorage.setItem("SET_SCROLLER", param);
    return {
        type: ActionType.SET_SCROLLER,
        payload: param,
    };
};

export const setTaskListFiltes = (param) => {
    return {
        type: ActionType.SET_TASK_LIST_FILTERS,
        payload: param,
    };
};

export const setRoomListFiltes = (param) => {
    return {
        type: ActionType.SET_ROOM_LIST_FILTERS,
        payload: param,
    };
};

export const setBedListFiltes = (param) => {
    return {
        type: ActionType.SET_BED_LIST_FILTERS,
        payload: param,
    };
};

export const setStaffListFiltes = (param) => {
    return {
        type: ActionType.SET_STAFF_LIST_FILTERS,
        payload: param,
    };
};


export const setPatientListFilters = (param) => {
    return {
        type: ActionType.SET_PATIENT_LIST_FILTERS,
        payload: param,
    };
};


export const setHospital = param => {
    return {
        type: ActionType.SET_HOSPITAL,
        payload: param,
    };
};

export const setDrawer = (param) => {
    return {
        type: ActionType.SET_DRAWER,
        payload: param,
    };
};


export const setUserPermissions = (param) => {
    return {
        type: ActionType.SET_USER_PERMISSION,
        payload: param,
    };
};


export const setWardListFilters = (param) => {
    return {
        type: ActionType.SET_WARD_LIST_FILETRS,
        payload: param,
    };
};


export const setDepartmentListFilters = (param) => {
    return {
        type: ActionType.SET_DEPARTMENT_LIST_FILETRS,
        payload: param,
    };
};



