import { superAdminDashboardServices } from "../../Services/DashboardServices/superAdminDashboardServices";
import { departmentServices } from "../../Services/DepartmentServices/department.services";


const superAdminDashboard = async (id,params) => {
    let response = await superAdminDashboardServices.superAdminDashboard(id,params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const superAdminDashboardController = {
    superAdminDashboard,
};

export default superAdminDashboardController;
