import { Toaster } from "../../../components/Helper/toaster/Toaster";
import { reportsServices } from "../../Services/ReportsServices/reports.services";


const reportsListing = async (params) => {
    let response = await reportsServices.reportsListing(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const reportsCreate = async (values, patinetDetail) => {
    let caseId = null
    let cases = patinetDetail && patinetDetail.cases ? patinetDetail.cases : ''
    if (cases) {
        for (let i in cases) {
            if (parseInt(cases[i].status) === 1) {
                caseId = cases[i].id
            }
        }
    }
    let post = {
        title: values && values.reportName ? values.reportName : null,
        description: values && values.discription ? values.discription : null,
        case_id: caseId,
        patient_id: patinetDetail && patinetDetail.id ? patinetDetail.id : null,
        attachment: values && values.upload ? values.upload : null
    }
    let response = await reportsServices.reportsCreate(post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const reportDetail = async (id) => {
    let response = await reportsServices.reportsDetail(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const reportsUpdate = async (values, patinetDetail,id) => {
    let caseId = null
    let cases = patinetDetail && patinetDetail.cases ? patinetDetail.cases : ''
    if (cases) {
        for (let i in cases) {
            if (parseInt(cases[i].status) === 1) {
                caseId = cases[i].id
            }
        }
    }
    let post = {
        title: values && values.reportName ? values.reportName : null,
        description: values && values.discription ? values.discription : null,
        case_id: caseId,
        patient_id: patinetDetail && patinetDetail.id ? patinetDetail.id : null,
        attachment: values && values.upload ? values.upload : null
    }
    let response = await reportsServices.reportsUpdate(post, id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const reportDelete = async (id) => {
    let response = await reportsServices.reportDelete(id);
    if (response) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const reportsController = {
    reportsListing,
    reportsCreate,
    reportDetail,
    reportsUpdate,
    reportDelete
};

export default reportsController;
