import { fileServices } from "../../Services/ImageServices/file.upload";
import WebConstant from "../../webConstants";



const uploadFile = async (data, progressCallback) => {
    let form = new FormData();
    if (data.size < WebConstant.maxFileSize) {
        form.append("file", data);
        let response = await fileServices.uploadFile(form, progressCallback);
        return response;
    }
    else {
        return {
            status: false,
            error: 'File size exceeds maximum limit 25MB.'
        }
    }
};

const fileUploadController = {
    uploadFile,
};

export default fileUploadController;
