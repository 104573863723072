import { Toaster } from "../../../components/Helper/toaster/Toaster";
import { taskServices } from "../../Services/TaskServices/task.services";


const taskListing = async (param) => {
    let response = await taskServices.taskListing(param);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const taskCreate = async (data) => {
    let assignMembers = []
    if(data && data.assignTo){
        data.assignTo.map((item) =>{
            assignMembers.push((item.id).toString())                         //<------------into string added by backend team it gives error as any id as number
        })
    }
    let post = {
        assign_to: assignMembers,
        title: data && data.title ? data.title : null,
        description: data && data.description ? data.description : null,
        priority: data && data.priority ? data.priority : null,
        case_id: data && data.patientData && data.patientData.caseId ? data.patientData.caseId : null,
        hospital_id: data && data.patientData && data.patientData.hospitalId ? data.patientData.hospitalId : null,
        patient_id: data && data.patientData && data.patientData.patientId ? data.patientData.patientId : null,
        patient_name: data && data.patientData && data.patientData.patientName ? data.patientData.patientName : null,
        ward_id : data && data.patientData && data.patientData.wardId ? data.patientData.wardId : null,
        start_date: data && data.startDate ? data.startDate : null,
        end_date: data && data.startDate ? data.startDate : null,                 //<----------end date as start date Added by backend team
        start_time: data && data.startTime ? data.startTime : null,
        end_time: data && data.endTime ? data.endTime : null,
    }
    let response = await taskServices.taskCreate(post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const taskDetail = async (id) => {
    let response = await taskServices.taskDetail(id);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const taskUpdate = async (data, id) => {
    let assignMembers = []
    if(data && data.assignTo){
        data.assignTo.map((item) =>{
            if(item.admin_id){                                     //<--------it will get from task detail -----> Assigned Members'__admin_id 
                assignMembers.push((item.admin_id).toString());
            }
            else{
                assignMembers.push((item.id).toString());          //<--------It will from admins list 
            }
        })
    }
    let post = {
        assign_to: assignMembers,
        title: data && data.title ? data.title : null,
        description: data && data.description ? data.description : null,
        priority: data && data.priority && data.priority.id ? data.priority.id : data.priority ? data.priority : null,
        case_id: data && data.patientData && data.patientData.caseId ? data.patientData.caseId : null,
        hospital_id: data && data.patientData && data.patientData.hospitalId ? data.patientData.hospitalId : null,
        patient_id: data && data.patientData && data.patientData.patientId ? data.patientData.patientId : null,
        patient_name: data && data.patientData && data.patientData.patientName ? data.patientData.patientName : null,
        ward_id : data && data.patientData && data.patientData.wardId ? data.patientData.wardId : null,
        start_date: data && data.startDate ? data.startDate : null,
        end_date: data && data.startDate ? data.startDate : null,
        start_time: data && data.startTime ? data.startTime : null,
        end_time: data && data.endTime ? data.endTime : null,
    }
    let response = await taskServices.taskUpdate(post, id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const taskDelete = async (id) => {
    let response = await taskServices.taskDelete(id);
    if (response) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const taskComplete = async (id) => {
    let response = await taskServices.taskComplete(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};

const taskListingPatientDetails = async (param) => {
    let response = await taskServices.taskListingPatientDetails(param);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const taskListingSubAdmin = async (param) => {
     let response = await taskServices.taskListingSubAdmin(param);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
}


const taskController = {
    taskListing,
    taskCreate,
    taskDetail,
    taskUpdate,
    taskDelete,
    taskComplete,
    taskListingPatientDetails,
    taskListingSubAdmin
};

export default taskController;
