import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import AuthHeader from "../../../Helper/authHeader";
import ButtonLoader from "../../../Helper/buttonLoader";
import * as Constants from "../../../Helper/constants/constants";
import ResetPasswordFunctions from "./function";

const ResetPassword = (props) => {
    const [isVisiblePassword, setVisiblePassword] = useState(false);
    const [isVisibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
    const {
        values,
        isError,
        handleChange,
        handleResetPassword,
        loader,
        navigate,
        show,
        setShow,
    } = ResetPasswordFunctions();

    const togglePassword = () => {
        setVisiblePassword(!isVisiblePassword);
    };

    const toggleConfirmPassword = () => {
        setVisibleConfirmPassword(!isVisibleConfirmPassword);
    };

    return (
        <div className="reset_password_screen">
            <AuthHeader
                header={Constants.RESET_PASSWORD_HEADER}
                description={Constants.RESET_PASSWORD_DESCRIPTION}
            />
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="form">
                            <Form>
                                <Form.Group className="form-group">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        type={!isVisiblePassword ? "password" : "text"}
                                        placeholder="************"
                                        className={isError.newPassword.message
                                            ? "error_border"
                                            : ""}
                                        value={values.newPassword}
                                        onChange={(e) =>
                                            handleChange(
                                                "newPassword",
                                                e.target.value
                                            )
                                        }
                                    />
                                    <span onClick={togglePassword}>
                                        {isVisiblePassword ? <FontAwesomeIcon
                                            icon={faEye}
                                            className="eye_icon"
                                        /> : <FontAwesomeIcon
                                            icon={faEyeSlash}
                                            className="eye_icon"
                                        />}
                                    </span>


                                    {isError.newPassword.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.newPassword.message}
                                        </p>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="form-group">
                                    <Form.Label>Confirm Password</Form.Label>
                                    {/* <div
                                        className={
                                            isError.confirmPassword.message
                                                ? "reset_password"
                                                : null
                                        }
                                    > */}
                                    <Form.Control
                                        type={
                                            !isVisibleConfirmPassword ? "password" : "text"
                                        }
                                        placeholder="************"
                                        className={isError.confirmPassword.message
                                            ? "confirm_password error_border"
                                            : "confirm_password"}
                                        onChange={(e) =>
                                            handleChange(
                                                "confirmPassword",
                                                e.target.value
                                            )
                                        }
                                    />
                                    <span onClick={toggleConfirmPassword}>
                                        {isVisibleConfirmPassword ? <FontAwesomeIcon
                                            icon={faEye}
                                            className="eye_icon"
                                        /> : <FontAwesomeIcon
                                            icon={faEyeSlash}
                                            className="eye_icon"
                                        />}
                                    </span>
                                    {isError.confirmPassword.message ? (
                                        <p className="text-danger mt-2">
                                            {
                                                isError.confirmPassword
                                                    .message
                                            }
                                        </p>
                                    ) : null}
                                    {/* </div> */}
                                </Form.Group>
                                <div className="btn_area">
                                    <Button
                                        className="cancelBtn"
                                        type="button"
                                        onClick={() => navigate('/login')}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="submitBtn"
                                        type="submit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleResetPassword();
                                        }}
                                        disabled={loader ? true : false}
                                    >
                                        {loader ? (
                                            <ButtonLoader
                                                loader={loader}
                                                variant={"light"}
                                                disabled={loader ? true : false}
                                            />
                                        ) : null}
                                        Continue
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ResetPassword;
