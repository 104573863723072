import { faBed, faCalendarAlt, faCalendarWeek, faCircle, faEllipsisVertical, faHospital, faHouse, faSearch, faVenus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Container, Dropdown, Form, Image, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Card1, Filters, Man, SearchFilter } from "../../../../assets/images";
import { dateFormat, renderImage } from "../../../Helper/General";
import * as Constants from '../../../Helper/constants/constants';
import WithoutImageHeader from "../../../Helper/withoutImageHeader";
import PaginationComponent from "../../CommanComponents/Pagination";
import FilterModal from "../../Modals/FilterModal";
import TaskModal from "../../Modals/TaskModal/index";
import PatientListFunctions from "./functions";
import NoDataComponent from "../../CommanComponents/NoDataComponent";
import PatientListFilterModal from "../../Modals/PatientListFiltersModal";
import { filtersController } from "../../../../apis/Controllers/CommonController/filter.controller";
import store from "../../../../redux/store";
import { debounce } from "lodash";

const PatientList = () => {
    const {
        setShowFilterModal,
        showFilterModal,
        getPatientListing,
        patientListing,
        navigate,
        loader,
        page,
        handlePages,
        handlePage,
        totalPages,
        handleNextClick,
        handleBackClick,
        wardActivePatient,
        patientStatus,
        setPatientStatus,
        chnagePatientStatus,
        handleBreadcrumDataNaviagtion,
        wardId,
        setWardID,
        applyFilters,
        handleClearAllFilters,
        setReduxValue,
        checkPermissions,
        getModulePermissions,
        filtersAppliedSign,
        dashboard_hospital,
        keyword,
        setSearch,
        breadcrumData,
        breadcrumItem,
        setbreadcrumData,
        loaction
    } = PatientListFunctions();

    let storedFilters = store.getState().PatientListFilters.filters;
    let wardName = loaction && loaction.state && loaction.state.item && loaction.state.item.title ?
        loaction && loaction.state && loaction.state.item && loaction.state.item.title : null
    let userdata = localStorage.getItem('SET_USER_DATA');
    const user = JSON.parse(userdata);

    useEffect(() => {
        setWardID(loaction && loaction.state && loaction.state.item && loaction.state.item.id)
        if (loaction && loaction.state && loaction.state.item) {
            let array = [...breadcrumData]
            let name = loaction && loaction.state && loaction.state.item && loaction.state.item.title
            if (array) {
                array.push(name)
            }
            else {
                array.push('Ward')
            }
            setbreadcrumData(array);
            let data = localStorage.getItem('SET_HOSPITAL');
            let item = JSON.parse(data);
            let storedFilters = store.getState().PatientListFilters.filters;
            let item1 = loaction && loaction.state && loaction.state.item && loaction.state.item;
            if (item) {
                dashboard_hospital(item, storedFilters, item1);
            }
        }
        let userdata = localStorage.getItem('SET_USER_DATA');
        let User = JSON.parse(userdata);
        if (User && parseInt(User.user_type) === 2) {
            getModulePermissions();
        }
        else;
    }, []);

    const search = useCallback(debounce(getPatientListing, 1000), []);

    return (
        <>
            <div className="patient_list_lab">
                <WithoutImageHeader
                    header={Constants.PATIENT_LIST_HEADER}
                    breadcrumData={breadcrumData}
                    breadcrumItem={breadcrumItem}
                    handleBack={() => navigate(-1)}
                    handleBreadcrumDataNaviagtion={(i) =>
                        handleBreadcrumDataNaviagtion(i)
                    }
                    setReduxvalue={(e) => setReduxValue(e)}
                />
                <Container>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div className="patient_list_area">
                                <div className="search_filter_main">
                                    <div className="left_area">
                                        <Form>
                                            <Form.Group className="form-group">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Search"
                                                    value={keyword}
                                                    onChange={(e) => (
                                                        search(e.target.value, 1, storedFilters),
                                                        setSearch(e.target.value))}
                                                />
                                                <a>
                                                    <div className="icon">
                                                        <FontAwesomeIcon icon={faSearch} />
                                                    </div>
                                                </a>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                    <div className="right_area">
                                        <div className="inner_area">
                                            {/* <div className="inner_left">
                                                <Form>
                                                    <Form.Group className="form-group">
                                                        <Form.Select>
                                                            <option value={5}>5</option>
                                                            <option value={10}>10</option>
                                                            <option value={15}>15</option>
                                                            <option value={20}>20</option>
                                                            <option value={25}>25</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Form>
                                            </div> */}
                                            <div className="inner_right">
                                                <div className="filter_button">
                                                    <Button
                                                        className={filtersAppliedSign ? "btn-border" : "btn-primary"}
                                                        onClick={() => setShowFilterModal(true)}>
                                                        <Image
                                                            src={Filters}
                                                            alt="..."
                                                            className="image_area"
                                                        />
                                                        Filters
                                                    </Button>
                                                    {filtersAppliedSign ? <FontAwesomeIcon
                                                        icon={faCircle}
                                                        className="filter_icon"
                                                    /> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text">
                                    {patientListing && patientListing.length > 0 ? <p>Total Patients : {wardActivePatient ? wardActivePatient : 0}</p> : null}
                                </div>
                                {
                                    patientListing && patientListing.length > 0 ?
                                        patientListing.map((item, i) => {
                                            return (
                                                <div className="listing_main_area">
                                                    <div className="list_inner_area">
                                                        <div className="left_area">
                                                            <div className="img_con_area">
                                                                <div className="left">
                                                                    <div className="img_area">
                                                                        <Image src={item && item.image ? renderImage(item.image) : Man} alt="..."
                                                                            onClick={() => navigate(`/patientDetails/${item.id}`, { state: { pageParams: ['Dashboard', wardName, 'Patient List'], name: item.name } })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="right">
                                                                    <h5
                                                                        onClick={() => navigate(`/patientDetails/${item.id}`, { state: { pageParams: ['Dashboard', wardName, 'Patient List'], name: item.name } })}
                                                                    >{item.patient_name}</h5>
                                                                    <div className="top_list">
                                                                        <ul>
                                                                            {
                                                                                item.gender ?
                                                                                    <li><FontAwesomeIcon icon={faVenus} className="icon_area" />{item.gender ? item.gender : ''}{item.age ? ` (${item.age})` : ''}</li>
                                                                                    : null}
                                                                            <li><div className="label">{item && item.active_case[0] && item.active_case[0] ? item && item.active_case[0] && item.active_case[0].ward_title : '-'}</div></li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="mid_list">
                                                                        <ul>
                                                                            <li><FontAwesomeIcon icon={faBed} className="icon_area" />Bed #{item && item.active_case[0] && item.active_case[0] ? item && item.active_case[0] && item.active_case[0].bed_number : 0}</li>
                                                                            <li><FontAwesomeIcon icon={faHouse} className="icon_area" />Room #{item && item.active_case[0] && item.active_case[0] ? item && item.active_case[0] && item.active_case[0].room_number : 0}</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="mid_list">
                                                                        <ul>
                                                                            <li><FontAwesomeIcon icon={faCalendarWeek} className="icon_area" />{dateFormat(item.date_of_admission)}</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="bottom_list">
                                                                        <ul>
                                                                            <li><FontAwesomeIcon icon={faHospital} className="icon_area" /> {item && item.hospitals && item.hospitals[0] && item.hospitals[0].title
                                                                                ? item.hospitals[0].title
                                                                                : null}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="right_area">
                                                            {item && item.active_case[0] && item.active_case[0] ? <div className="number_mr">MR: {item && item.active_case[0] && item.active_case[0] ? item && item.active_case[0] && item.active_case[0].id : ''}</div> : null}
                                                            <div className="last_btn_area">
                                                                <div className="number_txt">UHID : {item && item.uh_id}</div>
                                                                {/* <div className="batch orange_bg">
                                                                    <p class="warning">Under Treatment</p>
                                                                </div> */}
                                                                <Dropdown>
                                                                    <Dropdown.Toggle>
                                                                        {
                                                                            item.patient_status === 'mmx' ?
                                                                                <div className="batch orange_bg">
                                                                                    <p class="warning">MMX</p>
                                                                                </div>
                                                                                :
                                                                                item.patient_status === '72hrs' ?
                                                                                    <div className="batch orange_bg">
                                                                                        <p class="warning">72 hrs</p>
                                                                                    </div>
                                                                                    :
                                                                                    item.patient_status === 'on_tpi' ?
                                                                                        <div className="batch orange_bg">
                                                                                            <p class="warning">On TPI</p>
                                                                                        </div>
                                                                                        :
                                                                                        item.patient_status === 'on_ventilator' ?
                                                                                            <div className="batch danger">
                                                                                                <p class="danger">On Ventilator</p>
                                                                                            </div>
                                                                                            : item.patient_status === 'mi' ?
                                                                                                <div className="batch danger">
                                                                                                    <p class="danger">MI</p>
                                                                                                </div>
                                                                                                :
                                                                                                item.patient_status === 'admitted' ?
                                                                                                    <div className="batch success">
                                                                                                        <p class="success">Admitted</p>
                                                                                                    </div>
                                                                                                    :
                                                                                                    item.patient_status === 'discharge' ?
                                                                                                        <div className="batch discharges">
                                                                                                            <p class="discharge">Discharged</p>
                                                                                                        </div>
                                                                                                        :
                                                                                                        null}
                                                                    </Dropdown.Toggle>
                                                                    {item.patient_status !== "discharge" ?
                                                                        <Dropdown.Menu>
                                                                            {patientStatus.map((ditem, index) => (
                                                                                <Dropdown.Item key={index}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        chnagePatientStatus(item, ditem.id)
                                                                                    }
                                                                                    }>
                                                                                    {ditem.value}
                                                                                </Dropdown.Item>
                                                                            ))}
                                                                        </Dropdown.Menu> : null}
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <>
                                            {!loader ?
                                                <NoDataComponent
                                                    message="No Patient Found!"
                                                /> : null}
                                        </>
                                }

                                {
                                    !loader && patientListing && patientListing.length > 0 ? <PaginationComponent
                                        page={page}
                                        setPage={handlePage}
                                        totalPages={totalPages}
                                        handleBackClick={handleBackClick}
                                        handleNextClick={handleNextClick}
                                    /> : null}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {
                showFilterModal ? (
                    <PatientListFilterModal
                        show={showFilterModal}
                        applyFilters={applyFilters}
                        handleClearAllFilters={handleClearAllFilters}
                        close={() => setShowFilterModal(false)}
                    />
                ) : null
            }





            {/* <Viewfile/> */}
        </>
    )
}

export default PatientList;