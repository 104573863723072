import React, { useState } from 'react';

const StafListTab = (props) => {
    const { currentTab } = props;


    const handleTabClick = (e) => {
        props.setCurrentTab(e.target.id);
    }

    return (
        <div className='custom_tabs_area'>
            <div className='custom_tabs staff_tab'>
                {props.tabs.map((tab, i) =>
                    <button
                        key={i} id={tab.id}
                        disabled={currentTab === `${tab.id}`}
                        className={currentTab === `${tab.id}` ? 'active_btn' : 'inactive_btn'}
                        onClick={handleTabClick}>
                        {tab.tabTitle}
                    </button>
                )}
            </div>
        </div>
    );
}
export default StafListTab;