import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";

function adminListing(params) {
    return mainWrapper.get(WebConstant.host + `/admin`, params);
};

function adminCreate(params) {
    return mainWrapper.post(WebConstant.host + `/admin`, params);
};

function adminDetail(id) {
    return mainWrapper.get(WebConstant.host + `/admin/${id}`);
};

function adminUpdate(params, id) {
    return mainWrapper.put(WebConstant.host + `/admin/${id}`, params);
};

function adminDelete(id) {
    return mainWrapper._delete(WebConstant.host + `/admin/${id}`);
};

function updateAdminStatus(id, params) {
    return mainWrapper.put(WebConstant.host + `/update_admin_status/${id}`, params);
};

function adminListingWithDepartment(params) {
    return mainWrapper.get(WebConstant.host + `/admin_list_department_id`, params);
};


// function superAdminDetail(id) {
//     return mainWrapper.get(WebConstant.host + `/superadmin_dashboard/${id}`);
// };

function superAdminsList(params) {
    return mainWrapper.get(WebConstant.host + `/super_admin_list`, params);
};

function updateSuperAdmin(id, params) {
    return mainWrapper.put(WebConstant.host + `/update_super_admin_details/${id}`, params);
};


function allAdminsList(params) {
    return mainWrapper.get(WebConstant.host + `/all_admins`, params);
};

function allAdminsTaskList(params) {
    return mainWrapper.get(WebConstant.host + `/all_admins_task`, params);
};

function consultantListing(params) {
    return mainWrapper.get(WebConstant.host + `/consultant_list`, params);
};

export const adminServices = {
    adminListing,
    adminCreate,
    adminDetail,
    adminUpdate,
    adminDelete,
    updateAdminStatus,
    adminListingWithDepartment,
    superAdminsList,
    updateSuperAdmin,
    allAdminsList,
    allAdminsTaskList,
    consultantListing
};
