import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function uploadImage(params, progressCallback) {
    return mainWrapper.upload(WebConstant.host + "/upload-media/photo", params, progressCallback);
};

export const ImageServices = {
    uploadImage,
};
