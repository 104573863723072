const WebConstant = {
    debug: false,
    // image: "http://localhost:2603",
    // host: "http://localhost:2603",
    image: "https://oxfordapi.globizoxford.xyz",
    host: "https://oxfordapi.globizoxford.xyz",
    // image: "http://167.71.229.35:2603",
    // host: "http://167.71.229.35:2603",
    // image: "http://139.59.2.144:8080",
    // host: "http://139.59.2.144:8080",
    maxFileSize: 5 * 1000 * 1000,
};

export default WebConstant;

