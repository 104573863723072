import React, { useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
//-------- HEADER COMPONENT IMPORT ---------//
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { Radiology } from "../../../../../assets/images";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
// import * as Constants from "../../../Helper/constants/constants";
import * as Constants from '../../../../Helper/constants/constants';
import { useEffect } from "react";
import { isEmpty } from "../../../../Helper/General";
import AddBedFunction from "./function";
const AddBed = () => {
   
    const {
        breadcrumData,
        values,
        isError,
        breadcrumItem,
        setbreadcrumItem,
        loaction,
        handleChange,
        loader,
        navigate,
        handleBreadcrumDataNaviagtion,
        getRoomsListing,
        roomList,
        submitBed,
        getbedDetails,
        deletePopup,
        deleteItem
    } = AddBedFunction();

    useEffect(() => {
        if (!loader) {
             if (loaction && loaction.state && loaction.state.name) {
                let id =
                    loaction && loaction.state && loaction.state.id
                        ? loaction.state.id
                         : null;
                  getbedDetails(id);
                  setbreadcrumItem("Edit Bed");
            }
            getRoomsListing();
        }
    }, []);


    return (
        <div className="add_case_section">
            <WithoutImageHeader
                 header={loaction && loaction.state && loaction.state.name ? Constants.EDITBED  : Constants.ADDBED}
                 breadcrumData={breadcrumData}
                 breadcrumItem={breadcrumItem}
                 handleBack={() => navigate(-1)}
                  handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
            />
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="case_area">
                            <div className="dropdown_box">
                                <h6>
                                    Room<span>*</span>
                                </h6>
                                <div className="dropdown_area">
                                    <Select
                                        isDisabled={loaction && loaction.state && loaction.state.name ? true : false}
                                        placeholder="Select Room"
                                        options={roomList}
                                        value={values && values.roomID}
                                        onChange={(e) => {
                                            handleChange("roomID", e)}}
                                        className={
                                            isError.roomID.message
                                                ? "error_border_select"
                                                : ""
                                        }
                                    />
                                    {isError.roomID.message ? (
                                        <p className="text-danger mt-2">
                                            {isError.roomID.message}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                           
                            <Form.Group className="form-group">
                                <Form.Label>
                                    Bed Number<span>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter bed number here"
                                    className={
                                        isError.bedNumber.message
                                            ? "error_border"
                                            : ""
                                    }
                                    value={values.bedNumber}
                                    onChange={(e) =>
                                        handleChange(
                                            "bedNumber",
                                            e.target.value
                                        )
                                    }
                                />
                                {isError.bedNumber.message ? (
                                    <p className="text-danger mt-2">
                                        {isError.bedNumber.message}
                                    </p>
                                ) : null}
                            </Form.Group>
                           
                            <div className="btn_area">
                                <Button
                                    className="cancelBtn"
                                    type="button"
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="submitBtn"
                                    type="button"
                                    onClick={() => submitBed()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddBed;
