import { Toaster } from "../../../components/Helper/toaster/Toaster";
import { bedsServices } from "../../Services/BedsServices/beds.services";


const bedsListing = async (params) => {
    let response = await bedsServices.bedsListing(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const superAdminBedsListing = async (params) => {
    let response = await bedsServices.superAdminBedsListing(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
}


const bedsCreate = async (data) => {
    let post = {
        bed_number: data && data.bedNumber ? data.bedNumber : null,
        room_id: data && data.roomID && data.roomID.id ? data.roomID.id : null
    }
    let response = await bedsServices.bedsCreate(post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const bedsDetail = async (id) => {
    let response = await bedsServices.bedsDetail(id);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};


const bedsUpdate = async (id, data) => {
    let post = {
        bed_number: data && data.bedNumber ? data.bedNumber : null,
        room_id: data && data.roomID && data.roomID.id ? data.roomID.id : null
    }
    let response = await bedsServices.bedsUpdate(id, post);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const bedsDelete = async (id) => {
    let response = await bedsServices.bedsDelete(id);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.error);
        return null;
    }
};


const updateBedStatus = async (id, params) => {
    let response = await bedsServices.updateBedStatus(id, params);
    if (response && response.status) {
        return response;
    } else {
        new Toaster().error(response.message);
        return null;
    }
}


const bedsController = {
    bedsListing,
    bedsCreate,
    bedsDetail,
    bedsUpdate,
    bedsDelete,
    superAdminBedsListing,
    updateBedStatus
};

export default bedsController;
