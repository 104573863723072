import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthController from "../../../../apis/Controllers/AuthController/AuthController";
import { Toaster } from "../../../Helper/toaster/Toaster";
import Validation from "../../../Helper/validations/vaildation";

const ResetPasswordFunctions = () => {
    const defaultValues = {
        newPassword: "",
        confirmPassword: "",
    };
    const defaultErrors = {
        newPassword: {
            rules: ["required", "password"],
            isValid: true,
            message: "",
        },
        confirmPassword: {
            rules: ["required", "password"],
            isValid: true,
            message: "",
        },
    };
    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);
    const [loader, setLoader] = useState(false);
    const [show, setShow] = useState(false);

    const navigate = useNavigate();
    const { state } = useLocation();

    //handleChange and Validation on each field ------
    const handleChange = (field, value) => {
        let validation = new Validation(isError);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };

    //check full form validation---------------------
    const handleResetPassword = async () => {
        let validation = new Validation(isError);
        let isValid = validation.isFormValid(values);
        if (isValid && !isValid.haveError) {
            if (values.newPassword !== values.confirmPassword) {
                let err = {
                    ...isError.confirmPassword,
                    isValid: false,
                    message: "Confirm password does not matched",
                };
                setError({ ...isError, confirmPassword: err });
            } else {
                setLoader(true);
                let response = await AuthController.resetPasswordDetails(
                    values,
                    state
                );
                if (response && response.status) {
                    new Toaster().success(response.message);
                    navigate("/login");
                    setLoader(false);
                } else {
                    new Toaster().error(response.message);
                    setLoader(false);
                }
            }
        } else {
            setError({ ...isValid.errors });
        }
    };

    return {
        values,
        isError,
        handleChange,
        handleResetPassword,
        loader,
        setLoader,
        navigate,
        setShow,
        show
    };
};

export default ResetPasswordFunctions;
