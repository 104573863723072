import ActionTypes from "../../constants";

const initialState = {
    filters: {
        assignBy: [],
        wards: [],
        hospitals:[],
        taskPriorities: null,
        taskStatus: null
    }
};

const TaskListFilters = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_TASK_LIST_FILTERS:
            return Object.assign({}, state, {
                filters : action.payload,
            });
        default:
            return state;
    }
};

export default TaskListFilters;
