import { Toaster } from "../../../components/Helper/toaster/Toaster";
import { vitalsServices } from "../../Services/VitalsServices/vitals.services";

const vitalsListing = async (params) => {
    let response = await vitalsServices.vitalsListing(params);
    if (response && response.status) {
        return response;
    } else {
        return null;
    }
};

const updateVitals = async (patientData, data) => {
    let post = {
        patient_id: patientData && patientData.id ? patientData.id : null,
        temperature: data && data.temperature ? data.temperature : null,
        weight: data && data.weight ? data.weight : null,
        heart_beat: data && data.heartbeat ? data.heartbeat : null,
        blood_pressure_top:
            data && data.bloodPressureTop ? data.bloodPressureTop : null,
        blood_pressure_bottom:
            data && data.bloodPressureBottom ? data.bloodPressureBottom : null,
        oxygen_saturation_top:
            data && data.oxygenSaturationTop ? data.oxygenSaturationTop : null,
        oxygen_saturation_bottom:
            data && data.oxygenSaturationBottom
                ? data.oxygenSaturationBottom
                : null,
        blood_suger: data && data.bloodSuger ? data.bloodSuger : null,
    };
    let response = await vitalsServices.updateVitals(post);
    if (response) {
        return response;
    } else {
         new Toaster().error(response.message);
        return null;
    }
};

const vitalsController = {
    vitalsListing,
    updateVitals,
};

export default vitalsController;
