import { faAngleDown, faArrowLeft, faChevronRight, faFileArrowDown, faHospital } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Menu from "../../assets/images/menu.png";
import Bell from "../../assets/images/bell.png";
import Search from "../../assets/images/search.png";
import store from "../../redux/store";
import { setDrawer, setTaskListFiltes, setUserData } from "../../redux/actions/users";
import { useNavigate } from "react-router-dom";
import { useState } from "react";



const FiltersHeader = (props) => {
    const navigate = useNavigate();
    const [drswerStatus, setDrawerStatus] = useState(false);

    useEffect(() => {
        getUserData();
        setTaskListFilters();
    }, []);


    const getUserData = async () => {
        window.scrollTo(0, 0);
        let item = localStorage.getItem('SET_USER_DATA');
        item = JSON.parse(item);
        let user = item;
        if (user) {
            if (user && user.access && user.access.token) {
                await store.dispatch(setUserData(user));
            }
        }
        else {
            logout();
        }
    };


    const logout = async () => {
        store.dispatch(setUserData({}));
        localStorage.clear();
        navigate("/login");
    };



    const setTaskListFilters = async () => {
        let item = localStorage.getItem('SET_TASK_LIST_FILTERS');
        item = JSON.parse(item);
        let filters = item;
        if (item) {
            store.dispatch(setTaskListFiltes(filters));
            if (props && props.setReduxvalue) {
                props.setReduxvalue(filters);
            }
        }
        else {
            if (props && props.setReduxvalue) {
                props.setReduxvalue('Redux filters');
            }
            store.dispatch(setTaskListFiltes({
                assignBy: [],
                wards: [],
                taskPriorities: null,
                taskStatus: null
            }));
        }
    };


    const handleDrawer = async (e) => {
        await store.dispatch(setDrawer(true));
        setDrawerStatus(true);
    };


    const closeDrawer = async () => {
        setDrawerStatus(false);
        await store.dispatch(setDrawer(false));
    };

    return (
        <div className="dashboard_screen"  onClick={() => drswerStatus ? closeDrawer() : ''}>
            <div className="dashboardheader_area">
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="inner_area">
                            <div className="left_area">
                                {
                                    props.lefticon === 'back' ?
                                        // <div className="menu_icon">
                                        //     <div className="img_menu">
                                        <FontAwesomeIcon
                                            icon={faArrowLeft}
                                            className="arrow"
                                            onClick={() => props && props.handleBack ? props.handleBack() : ''}
                                        />
                                        //     </div>
                                        // </div>
                                        :
                                        <a>
                                            <div className="icon" onClick={() => drswerStatus === false ? handleDrawer() : ''}>
                                                <Image src={Menu} alt="..." />
                                            </div>
                                        </a>
                                }
                            </div>

                            <div className="header_title">
                                <h5>{props.header} </h5>
                            </div>
                        </div>
                        <div className="breadcrum_area">

                            {props.breadcrumData ? props.breadcrumData.map((item, i) =>
                                <div key={i}>
                                    <a onClick={() => props && props.handleBreadcrumDataNaviagtion ? props.handleBreadcrumDataNaviagtion(i) : ''}  >{item}</a><FontAwesomeIcon icon={faChevronRight} className="icon" />
                                </div>
                            ) : null}

                            <span>{props.breadcrumItem}</span>
                        </div>
                        {/* <div className="linking_area">
                            <div className="inner_area">
                                <a href="javscript:;" className="me-2">
                                    Dashboard
                                </a>
                                <FontAwesomeIcon icon={faChevronRight} />
                                <a href="javscript:;" className="task_area">
                                    Manage Task
                                </a>
                                
                                <FontAwesomeIcon icon={faChevronRight} />
                                <span>Role List</span>
                            </div>
                            
                        </div> */}
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default FiltersHeader;
