import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";

function vitalsListing(params) {
    return mainWrapper.get(WebConstant.host + "/vitals_list", params);
}

function updateVitals(params) {
    return mainWrapper.post(WebConstant.host + "/update_vitals", params);
}

export const vitalsServices = {
    vitalsListing,
    updateVitals,
};
