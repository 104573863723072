import { useState } from "react";
import permissionsController from "../../../../../apis/Controllers/PermissionsController/permissions.contoller";
import Validation from "../../../../Helper/validations/vaildation";
import rolesController from "../../../../../apis/Controllers/RolesController/roles.controller";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../../redux/store";
import { all } from "axios";

const AddRolesFunctions = () => {
    var breadcrumData = ["Dashboard", "Role list"];
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [superAdmin, setSuperAdmin] = useState(false);
    const [loader, setLoader] = useState(false);
    const [breadcrumItem, setbreadcrumItem] = useState("Add Role");
    const [roleDetail, setRoleDetail] = useState(null);
    const [permissonsArrayList, setPermissionArray] = useState([]);
    const loaction = useLocation();
    const navigate = useNavigate();
    const defaultValues = {
        role: "",
        is_super_admin: "",
    };

    const defaultErrors = {
        role: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
        is_super_admin: {
            rules: ["required"],
            isValid: true,
            message: "",
        },
    };

    const [values, setValues] = useState(defaultValues);
    const [isError, setError] = useState(defaultErrors);

    const handleChange = (field, value) => {
        let validation = new Validation(isError);
        let node = validation.validateField(field, value);
        setError({ ...isError, [field]: node });
        setValues({ ...values, [field]: value });
    };


    const getPermissionsListing = async () => {
        let response = await permissionsController.permissionsListing();
        if (response && response.status) {
            let list = response.data;
            for (let i in list) {
                for (let j in list[i].permissions) {
                    list[i].permissions[j].value = 0;
                }
            }
            setPermissions(list);
            setPermissionArray(list);
            checkAllpermissionsCheckedValue(list);
        } else {
            setPermissions([]);
        }
    };



    const handleSubmit = async () => {
        let array = [...permissions];
        let validation = new Validation(isError);
        let value = {
            ...values,
            is_super_admin: superAdmin ? 1 : 0,
        };
        let isValid = validation.isFormValid(value);
        if (isValid && !isValid.haveError) {
            let status = checkPermissionsONSubmit(array);          
            if (status) {
                setLoader(true);
                if (loaction && loaction.state && loaction.state.name) {                          // <-----------UPDATE ROLE
                    let id = loaction && loaction.state && loaction.state.id ? loaction.state.id : "";
                    let response = await rolesController.roleUpdate(permissions, value, id);
                    if (response && response.status) {
                        new Toaster().success(response.message);
                        navigate("/role-list");
                        setLoader(false);
                    } else {
                        setLoader(false);
                    }
                } else {
                    let response = await rolesController.rolesCreate(permissions, value);          // <-----------ADD ROLE
                    if (response && response.status) {
                        new Toaster().success(response.message);
                        navigate("/role-list");
                        setLoader(false);
                    } else {
                        setLoader(false);
                    }
                }
            }
            else {
                new Toaster().error('Please assign atleast one "Listing" Permission.');
            }
        } else {
            setError({ ...isValid.errors });
        }
    };


    const checkPermissionsONSubmit = (list) => {
        let checked = false;
        for (let i in list) {
            for (let j in list[i].permissions) {
                if (parseInt(list[i].permissions[j].value) === 1) {
                    checked = true
                    return true
                }
                else {
                    checked = false
                }
            }
        }
        return checked;
    };


    const handleCheck = (item, parent) => {                                              // < ----------------- On click on module's checkbox's
        let array = [...permissions];
        if (item && parent) {
            for (let i in array) {
                if (array[i].type == parent.type) {
                    let checkedParent = array[i]
                    let checkedParentPermission = checkedParent.permissions
                    if (item.title === checkedParentPermission[0].title) {                                  //<----------on Listing check click
                        let index = array.findIndex((item) => parseInt(item.id) === parseInt(parent.id));
                        if (index !== -1) {
                            let clickedItemPermisions = array[index].permissions;
                            for (let i in clickedItemPermisions) {
                                if (clickedItemPermisions[i].title === item.title) {
                                    if (parseInt(clickedItemPermisions[i].value) === 1) {
                                        clickedItemPermisions[i].value = 0;
                                    } else {
                                        clickedItemPermisions[i].value = 1;
                                    }
                                }
                                else{
                                    clickedItemPermisions[i].value = 0;
                                }
                            }
                            array[index].permissions = clickedItemPermisions;
                            let data = isAllCheckedSelected(array);
                            if (data === false) {
                                setSelectAll(false);
                                setSuperAdmin(false);
                            } else {
                                setSelectAll(true);
                                // setSuperAdmin(true);
                            }
                            setPermissions(array);
                        } else {
                            setPermissions(array);
                        }
                    }
                    else if (item.title !== checkedParentPermission[0].title) {                      //<---------- create update delet check click
                        if (parseInt(checkedParentPermission[0].value) === 1) {
                            let index = array.findIndex((item) => parseInt(item.id) === parseInt(parent.id));
                            if (index !== -1) {
                                let clickedItemPermisions = array[index].permissions;
                                for (let i in clickedItemPermisions) {
                                    if (clickedItemPermisions[i].title === item.title) {
                                        if (parseInt(clickedItemPermisions[i].value) === 1) {
                                            clickedItemPermisions[i].value = 0;
                                        } else {
                                            clickedItemPermisions[i].value = 1;
                                        }
                                    }
                                }
                                array[index].permissions = clickedItemPermisions;
                                let data = isAllCheckedSelected(array);
                                if (data === false) {
                                    setSelectAll(false);
                                    setSuperAdmin(false);
                                } else {
                                    setSelectAll(true);
                                    // setSuperAdmin(true);
                                }
                                setPermissions(array);
                            } else {
                                setPermissions(array);
                            }
                        }
                        else {
                            new Toaster().error('Please assign "Listing" permission first.');
                        }
                    }
                    else return;
                }
                else {
                    // console.log('--------------------------lop else call');
                }
            }
        }
    };


    const isAllCheckedSelected = (list) => {                       // <---------------------For handle "all-Select" and "SuperAdmin" checkox
        let allChecked = false;
        for (let i in list) {
            for (let j in list[i].permissions) {
                if (parseInt(list[i].permissions[j].value) === 0) {
                    allChecked = false;
                    return false;
                } else {
                    allChecked = true;
                }
            }
        }
        return allChecked;
    };


    const handleAllSelect = (item) => {                      // <----------------onClick allSelect Checkbox -----------> handle [all select ] and [super admin] checkbox
        let array = [...permissions];
        if (item) {
            for (let i in array) {
                array[i].permissions.map((item) => {
                    return (item.value = 1);
                });
            }
            setSelectAll(true);
            setSuperAdmin(false);
        } else {
            for (let i in array) {
                array[i].permissions.map((item) => {
                    return (item.value = 0);
                });
            }
            setSelectAll(false);
            // setSuperAdmin(false);
        }
        setPermissions(array);
    };


    const handleSuperAdminSelect = (item) => {               // <----------------onClick allSelect Checkbox -----------> handle [all select ] and [super admin] checkbox
        let array = [...permissions];
        if (item) {
            for (let i in array) {
                array[i].permissions.map((item) => {
                    return (item.value = 1);
                });
            }
            setSuperAdmin(true);
            setSelectAll(false);
        } else {
            for (let i in array) {
                array[i].permissions.map((item) => {
                    return (item.value = 0);
                });
            }
            // setSelectAll(false);
            setSuperAdmin(false);
        }
        setPermissions(array);
    };


    const checkAllpermissionsCheckedValue = (list) => {                //<---------------first Time render check status of all permissions and will set `Select all` and 'supder_admin' [true or false] according to the status
        let state = false;
        let array = [...list];
        for (let i in array) {
            array[i].permissions.map((item) => {
                if (parseInt(item.value) === 1) {
                    state = true;
                    return true;
                } else {
                    state = false;
                }
            });
        }
        // setSelectAll(state);
        // setSuperAdmin(state);
    };


    const getRoleDetail = async (id) => {                                  // <-----------------------for edit role
        let array = [...permissonsArrayList];
        let response = await rolesController.roleDetail(id);
        if (response && response.status) {
            let data = response.data.permissions;
            const notExistModule = array.filter(
                ({ id: roleId }) =>
                    !data.some(({ id: dataId }) => roleId === dataId)
            );
            if (notExistModule) {
                for (let i in notExistModule) {
                    notExistModule[i].permissions.map((item) => {
                        item.value = 0;
                    });
                }
                let newAray = data.concat(notExistModule);
                setPermissions(newAray);
                let status = isAllCheckedSelected(newAray);
                setSelectAll(
                    status &&
                        parseInt(
                            response && response.data && response.data.user_type
                        ) === 2
                        ? true
                        : false
                );
                setSuperAdmin(
                    status &&
                        parseInt(
                            response && response.data && response.data.user_type
                        ) === 1
                        ? true
                        : false
                );
            } else {
                setPermissions(data);
            }
            // setSelectAll(parseInt(response && response.data && response.data.user_type) === 2 ? true : false);
            // setSuperAdmin(parseInt(response && response.data && response.data.user_type) === 1 ? true : false);
            setValues({
                ...values,
                role: response && response.data && response.data.title,
                is_super_admin:
                    parseInt(
                        response && response.data && response.data.user_type
                    ) === 1
                        ? 1
                        : 0,
            });
        } else {
            setPermissions([]);
        }
    };


    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/dashboard-superadmin");
            }
        } else if (i === 1) {
            navigate("/role-list");
        } else return;
    };


    return {
        showFilterModal,
        setShowFilterModal,
        handleChange,
        getPermissionsListing,
        permissions,
        values,
        setValues,
        isError,
        loader,
        handleChange,
        handleSubmit,
        handleCheck,
        handleAllSelect,
        selectAll,
        checkAllpermissionsCheckedValue,
        superAdmin,
        breadcrumItem,
        setbreadcrumItem,
        getRoleDetail,
        roleDetail,
        setRoleDetail,
        permissonsArrayList,
        breadcrumData,
        loaction,
        handleBreadcrumDataNaviagtion,
        handleSuperAdminSelect,
    };
};

export default AddRolesFunctions;
