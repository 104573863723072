import React from 'react';
import { Card } from 'react-bootstrap';


const Case = () => {
    return (
        <div className="card_area">
            <Card>
                <Card.Body>
                    <div className="card_details">
                        <h5>Case Detail</h5>
                        <h6>Ebola Hemorrhagic Fever</h6>
                        <div className="btns_area">
                            <ul>
                                <li className="btn_danger">
                                    <a href="#" >Lorem</a>
                                </li>
                                <li className="btn_warning">
                                    <a href="#">Ipsum</a>
                                </li>
                            </ul>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dolor ipsum, hendrerit sit amet neque nonLorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dolor ipsum, hendrerit sit amet neque nonLorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dolor ipsum, hendrerit sit amet neque nonLorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dolor ipsum, hendrerit sit amet neque non</p>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Case;