import { useEffect, useState } from "react";
import patientController from "../../../../apis/Controllers/PatientController/patient.controller";
import { useLocation, useNavigate } from "react-router-dom";
import { Toaster } from "../../../Helper/toaster/Toaster";
import store from "../../../../redux/store";
import { filtersController } from "../../../../apis/Controllers/CommonController/filter.controller";
import permissionsController from "../../../../apis/Controllers/PermissionsController/permissions.contoller";


const PatientListFunctions = () => {
    let storedFilters = store.getState().PatientListFilters.filters;
    const [breadcrumData, setbreadcrumData] = useState(['Dashboard']);
    var breadcrumItem = 'Patient List';
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [keyword, setSearch] = useState(null);
    const [patientListing, setPatientListing] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [wardActivePatient, setWardActivePatient] = useState(0);
    const [totalPages, setTotalPages] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [loader, setLoader] = useState(false);
    const [wardId, setWardID] = useState(null);
    const [checkStoredvalueFromRedux, SetCheckStoredValueFromRedux] = useState(null);
    const [filtersAppliedSign, setFiltersAppliedSign] = useState(false);
    const [checkPermissions, setCheckPermissions] = useState(null);
    const [patientStatus, setPatientStatus] = useState([
        { id: 'admitted', value: 'Admitted' },
        { id: 'mmx', value: 'MMX' },
        { id: 'on_ventilator', value: 'On Ventilator' },
        { id: 'on_tpi', value: 'On TPI' },
        { id: 'mi', value: 'MI' },
        { id: '72hrs', value: '72hrs' },
        { id: 'discharge', value: 'Discharged' },
    ]);

    const loaction = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
        if (count) {
            handlePages(count);
        }
    }, [count]);


    const getModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('user');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckPermissions(data);
        }
        else {
            setCheckPermissions(null);
        }
    };

    useEffect(() => {
        if (storedFilters && checkStoredvalueFromRedux) {
            if (
                (storedFilters &&
                    storedFilters.patientStatus &&
                    storedFilters.patientStatus.length > 0) ||
                (storedFilters &&
                    storedFilters.wards &&
                    storedFilters.wards.length > 0) ||
                (storedFilters && storedFilters.admissionFromDate) ||
                (storedFilters && storedFilters.admissionToDate) ||
                (storedFilters &&
                    storedFilters.hospitals &&
                    storedFilters.hospitals.length > 0 && storedFilters.hospitals)
            ) {
                setFiltersAppliedSign(true);
                getPatientListing("", page);
            } else {
                setFiltersAppliedSign(false);
                getPatientListing("", page);
            }
        }
    }, [storedFilters, checkStoredvalueFromRedux]);


    const handlePages = (count) => {
        let array = []
        const data = parseInt(count);
        const totalPage = Math.ceil(data / itemsPerPage);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i);
        }
        setTotalPages(array);
    };

    const handlePage = (e) => {
        setPage(e);
        getPatientListing('', e);
    };


    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/dashboard-superadmin");
            }
        }
        else {
            return navigate("/dashboard");
        }
    };

    const getPatientListing = async (search, page, filters) => {
        if (!loader) {
            let post = {
                search: search ? search : '',
                page: page ? page : 1,
                hospital_id: filters && filters.hospitals && filters.hospitals.length > 0 ? filters.hospitals : storedFilters && storedFilters.hospitals && storedFilters.hospitals.length > 0 ? storedFilters.hospitals : [],
                patient_status: filters && filters.patientStatus ? filters.patientStatus : storedFilters && storedFilters.patientStatus ? storedFilters.patientStatus : [],
                ward_id: filters && filters.wards ? filters.wards : storedFilters && storedFilters.wards && storedFilters.wards.length > 0 ? storedFilters.wards : [],
                from_date: filters && filters.admissionFromDate ? filters.admissionFromDate : storedFilters && storedFilters.admissionFromDate ? storedFilters.admissionFromDate : '',
                to_date: filters && filters.admissionToDate ? filters.admissionToDate : storedFilters && storedFilters.admissionToDate ? storedFilters.admissionToDate : ''
            }
            setLoader(true)
            let response = await patientController.doctorPatientList(post);
            if (response && response.status) {
                setPatientListing(response.data)
                setCount(response.count);
                setWardActivePatient(response.totalCount);
                setLoader(false)
            }
            else {
                setPatientListing([]);
                setCount(0);
                setWardActivePatient(0);
                setLoader(false)
            }
        }
    };

    //-----------Pazination------->

    const handleBackClick = () => {
        if (page > 1) {
            let newPage = page - 1;
            getPatientListing("", newPage)
            setPage(page - 1);
        }
        else return;
    };

    const handleNextClick = () => {
        if (page >= 1 && page < totalPages.length) {
            let newPage = page + 1;
            getPatientListing("", newPage)
            setPage(page + 1);
        }
        else return;
    };

    const chnagePatientStatus = async (item, status) => {
        if (!loader) {
            let post = {
                patient_status: status ? status : '',
            }
            let ID = item && item.id ? item.id : null;
            setLoader(true)
            let response = await patientController.updatePatientStatus(ID, post);
            if (response && response.status) {
                setLoader(false)
                let array = [...patientListing];
                item.patient_status = status ? status : null;
                setPatientListing(array)
                new Toaster().success(response.message);
                getPatientListing('', page);
            }
            else {
                setLoader(false)
            }

        }
    };

    //--------filters---------->>
    const applyFilters = async (filter) => {
        let filters = {
            patientStatus: filter && filter.patientStatus ? filter.patientStatus : [],
            wards: filter && filter.wards ? filter.wards : [],
            admissionFromDate: filter && filter.admissionFromDate ? filter.admissionFromDate : '',
            admissionToDate: filter && filter.admissionToDate ? filter.admissionToDate : '',
            hospitals: filter && filter.hospitals ? filter.hospitals : []
        };
        if (filter && filter.hospitals && filter.hospitals.length > 0) {
            await filtersController.setPatientListFilter(filters);
            setShowFilterModal(false);
         } else {
            new Toaster().error("Please select at least one hospital");
        }
    };

    const dashboard_hospital = async (item, storedFilters, item1) => {
        let array2 = storedFilters && storedFilters.hospitals && storedFilters.hospitals.length > 0 ? storedFilters.hospitals : [];
        let index = array2.findIndex((id) => parseInt(id) === parseInt(item.id));
        if (index == -1) {
            array2.push(item.id);
        } 
        let wardArr = storedFilters && storedFilters.wards && storedFilters.wards.length > 0 ? storedFilters.wards : [];
        wardArr = [];
        let index1 = wardArr.findIndex((id) => parseInt(id) === parseInt(item1.id));
        if (index1 == -1) {
            wardArr.push(item1.id);
        }
        let filterNew = {
            patientStatus: storedFilters && storedFilters.patientStatus ? storedFilters.patientStatus : [],
            wards: wardArr && wardArr.length > 0 ? wardArr : [],
            admissionFromDate: storedFilters && storedFilters.admissionFromDate ? storedFilters.admissionFromDate : '',
            admissionToDate: storedFilters && storedFilters.admissionToDate ? storedFilters.admissionToDate : '',
            hospitals: array2 && array2.length > 0 ? array2 : [],
        };
        await filtersController.setPatientListFilter(filterNew);
    };

    const handleClearAllFilters = async () => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        await filtersController.clearPatientListFilters(item);
    };

     const setReduxValue = (e) => {          //<------------For confirmation that we have checked the localstorage value's and RESET the REDUX- FILTERS's Values and after it the listing api will hit
        SetCheckStoredValueFromRedux(e);
    };
    
    return {
        setShowFilterModal,
        showFilterModal,
        getPatientListing,
        patientListing,
        navigate,
        loader,
        page,
        handlePages,
        handlePage,
        totalPages,
        handleNextClick,
        handleBackClick,
        wardActivePatient,
        patientStatus,
        setPatientStatus,
        chnagePatientStatus,
        handleBreadcrumDataNaviagtion,
        wardId,
        setWardID,
        applyFilters,
        handleClearAllFilters,
        setReduxValue,
        checkPermissions,
        getModulePermissions,
        filtersAppliedSign,
        dashboard_hospital,
        keyword,
        setSearch,
        breadcrumData,
        breadcrumItem,
        setbreadcrumData,
        loaction
    }
};

export default PatientListFunctions;