import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function superAdminDashboard (id,params) {
    return mainWrapper.get(WebConstant.host + `/superadmin_dashboard/${id}`,params);
};


export const superAdminDashboardServices = {
    superAdminDashboard,
};
