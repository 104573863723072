import {
    faBed,
    faCalendarWeek,
    faHouse,
    faVenus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Dropdown, Image, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import {
    Blood,
    Card1,
    Filter,
    General,
    Heart,
    Icon_Circle,
    Oxygen,
    Patient,
    Sugar,
    Temperature,
    bodyscale,
    siren,
} from "../../../../assets/images";
import * as Constants from "../../../Helper/constants/constants";
import CustomTab from "../../../Helper/customTabs";
import WithoutImageHeader from "../../../Helper/withoutImageHeader";
import TaskModal from "../../Modals/TaskModal/index";
import UpdateVitalsModal from "../../Modals/UpdateVitalsModal";
import Health from "../../Tabs/Health";
import Lab from "../../Tabs/Lab";
import Logs from "../../Tabs/Logs";
import Medicine from "../../Tabs/Medicine";
import Reports from "../../Tabs/Reports";
import Task from "../../Tabs/Task";
import { useNavigate, useParams } from "react-router-dom";
import PatientDetailFunctions from "./functions";
import Loader from "../../../Helper/loader";
import { dateFormat, dateFormat2, renderImage, timeFormat, timeFormat2, timeFormat3 } from "../../../Helper/General";
import PatientProfileModal from "../../Modals/PatientProfileModal";
import PatientDetailTaskListFilterModal from "../../Modals/patientDetailTaskListFilters";
import MedicineUpdate from "../../Tabs/MedicineUpdate";
import LabUpdate from "../../Tabs/LabUpdate";


const PatientDetails = () => {

    const {
        //<-----------------------Permissions
        checkPermissions,
        checkTaskPermissions,
        checkVitalPermissions,
        checkReportPermissions,
        checkMedicinePermissions,
        checkLabPermissions,
        checkLogsPermissions,
        checkPatinetCasePermissions,
        getPatientModulePermissions,
        getTaskModulePermissions,
        getVitalModulePermissions,
        getReportsModulePermissions,
        getMedicineModulePermissions,
        getLabModulePermissions,
        getLogsModulePermissions,
        getPatientCaseModulePermissions,
        //<-------------------------
        setTabs,
        defaultTab,
        breadcrumData,
        breadcrumItem,
        navigate,
        loaction,
        showUpdateVitalModal,
        setShowUpdateVitalModal,
        showLogin,
        setShowLogin,
        currentTab,
        setCurrentTab,
        tabs,
        getPatientDetail,
        patientData,
        loader,
        loader2,
        taskCompletedByAdmin,
        patientReportsList,
        handleTabData,
        handleDeleteReport,
        handleAddReport,
        handleEditReport,
        //<--------------------------Task
        taskList,
        isUserCanCompleteTheTask,
        vitalsList,
        handleTaskBackClick,
        handleTaskNextClick,
        taskListTotalPages,
        reportListTotalPages,
        vitalsListTotalPages,
        values,
        isError,
        handleChange,
        updateHealth,
        page,
        handlePage,
        handleReportsBackClick,
        handleReportsNextClick,
        handleHealthPage,
        handleHealthBackClick,
        handleHealthNextClick,
        handleReportsPage,
        reportsPage,
        vitalsPage,
        profileModal,
        setProfileModal,
        handleBreadcrumDataNaviagtion,
        handleMRButton,
        manageMedicalRecordsTags,
        handleRenderImage,
        showFilterModal,
        setShowFilterModal,
        applyFilters,
        filterSortByValue,
        handleClearAllFilters,
        patientActiveCaseDetail,
        patientStatus,
        setPatientStatus,
        chnagePatientStatus,
        setBreadcrumData,
        //<---------------------medicine tab
        medicineList,
        setMedicineList,
        completeMedicine,
        setCompleteMedicine,
        allmedicineCompletePopup,
        setAllmedicineCompletePopup,
        getMedicineListing,
        handleMedicinePage,
        handleMedicineBackClick,
        handleMedicineNextClick,
        medicinePage,
        medicineTotalPages,
        handleDeleteMedicine,
        deletMedicineDetail,
        setDeletMedicineDetail,
        deletConfirmationModal,
        setDeletConfirmationModal,
        completMedicalConfirmationModal,
        setCompletMedicineConfimationModal,
        completeMedicineDetail,
        setCompleteMedicineDetail,
        handleCompleteMedicine,
        handleCompleteAllMedicine,
        handleAllMedicine,
        selectAllMedicine,
        setSelectAllMedicine,
        medicineComment,
        setMedicineComment,
        medicineDataForCommentApi,
        setMedicineDataForCommentApi,
        handleAddCommentInMedicineTab,
        dateParam,
        setDateParam,
        User,
        checkAllSelecMedicine,
        //<----------------------------Logs Tab
        handleLogsNextClick,
        handleLogsBackClick,
        onChangePage,
        LogsListing,
        logsPage,
        logsCount,
        logsTotalPages,
        logsDateParam,
        setLogsDateParam,
        totalLogsListLength,
        ////////////---------lab tab
        LabListing,
        labPage,
        labTotalPages,
        setLabPage,
        onlabChangePage,
        handleLabBackClick,
        handleLabNextClick,
        deleteLabreport,
        setDeleteLabreport,
        deleteLabReportListing,


        deleteReportsDetail,
        setDeleteReportsDetail,

        labDateParam,
        setLabDateParam,
        totalLabListLength
    } = PatientDetailFunctions();

    let param = useParams();

    useEffect(() => {
        if (param.slug) {
            let id = param ? param.slug : "";
            getPatientDetail(id);
            if (loaction && loaction.state && loaction.state.name1 == "tasklist") {
                setBreadcrumData(loaction && loaction.state && loaction.state.breadcrumData1)
            }
            else if (loaction && loaction.state && loaction.state.pageParams) {                        //<------from patient list
                let params = loaction.state.pageParams
                setBreadcrumData(params)
            }
            else if (loaction && loaction.state && loaction.state.name1 == "ExistingPatientList") {
                let params = loaction.state.pageParams
                setBreadcrumData(params);
            } else {
                setBreadcrumData(breadcrumData)
            }
            let userdata = localStorage.getItem('SET_USER_DATA');
            let User = JSON.parse(userdata);
            if (User && parseInt(User.user_type) === 2) {
                getPatientModulePermissions();
                getPatientCaseModulePermissions();
                getTaskModulePermissions();
                getReportsModulePermissions();
                getLabModulePermissions();
                getVitalModulePermissions();
                // getLogsModulePermissions();
                getMedicineModulePermissions();
            }
            else {
                setTabs(defaultTab);
            }
        } else {
            // navigate(-1);
        }
    }, []);

    return (
        <div className="patient_details_screen">
            <WithoutImageHeader
                header={Constants.PATIENT_DETAILS_HEADER}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBack={() => { navigate(-1); localStorage.removeItem('index'); }}
                handleBreadcrumDataNaviagtion={(i, item) => handleBreadcrumDataNaviagtion(i, item)}
            />
            {!loader && patientData ? (
                <>
                    <Container>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}  >
                                <div className="patient_details">
                                    <div className="main_detail">
                                        <div className="left_area">
                                            <div className="inner_left">
                                                <div className="img_area" >
                                                    <Image
                                                        variant="left"
                                                        src={patientData && patientData.image ? renderImage(patientData.image) : Patient}
                                                        alt="..."
                                                    />
                                                </div>
                                            </div>
                                            <div className="inner_right">
                                                <div className="name" >
                                                    <h3 style={{ textTransform: 'capitalize' }} >
                                                        {patientData.patient_name ? patientData.patient_name : ""}
                                                    </h3>
                                                    <a>
                                                        <div className="img_icon" onClick={() => setProfileModal(true)}>
                                                            <img src={Icon_Circle} alt="..." />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="gender">
                                                    {/* <p>
                                                        <FontAwesomeIcon
                                                            icon={faVenus}
                                                            className="gender_icon"
                                                        />
                                                        {patientData.gender ? patientData.gender : ""}
                                                    </p> */}
                                                    <p style={{ textTransform: 'uppercase' }}>
                                                        <span>
                                                            {patientData.cases && patientData.cases.length > 0 ? patientData.cases[0].ward_title : ""}
                                                        </span>
                                                    </p>
                                                    {
                                                        //<----------------------patient type----------------------
                                                        patientData.cases &&
                                                            patientData.cases
                                                                .length > 0 ? (
                                                            <p className="general">
                                                                <Image
                                                                    src={General} alt="..." className="general_img"
                                                                />
                                                                {patientData.cases[0].patient_type ? patientData.cases[0].patient_type : ""}
                                                            </p>
                                                        ) : null
                                                    }
                                                    {
                                                        //<----------------emergency case----------------------------
                                                        patientData && patientData.cases && patientData.cases.length > 0 &&
                                                            parseInt(patientData.cases[0].emergency_admission) === 1 ? (
                                                            <p className="general emergency">
                                                                <Image src={siren} alt="..." className="general_img" />
                                                                Emergency
                                                            </p>
                                                        ) : null
                                                    }
                                                </div>
                                                <div className="aged">
                                                    {patientData &&
                                                        patientData.age ? (
                                                        <p>
                                                            <FontAwesomeIcon icon={faVenus} className="date_icon" />
                                                            {patientData.gender ? patientData.gender : ""}{patientData.age ? ` (${patientData.age})` : ''}
                                                        </p>
                                                    ) : null}
                                                </div>
                                                <div className="date">
                                                    {patientData &&
                                                        patientData.date_of_admission ? (
                                                        <p>
                                                            <FontAwesomeIcon icon={faCalendarWeek} className="date_icon" /> {dateFormat2(patientData.date_of_admission)}
                                                        </p>
                                                    ) : null}
                                                    {patientData.cases && patientData.cases.length > 0 && patientData.cases[0].bed_number ? (
                                                        <p className="bed_space">
                                                            <FontAwesomeIcon icon={faBed} className="date_icon ps-4" /> Bed #{patientData.cases[0].bed_number}
                                                        </p>
                                                    ) : null}
                                                    {patientData.cases && patientData.cases.length > 0 && patientData.cases[0].room_number ? (
                                                        <p>
                                                            <FontAwesomeIcon icon={faHouse} className="date_icon ps-4" /> Room #{patientData.cases[0].room_number}
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right_area">
                                            {patientData.uh_id ? (
                                                <div className="id_area">
                                                    <p> UHID :{" "}{patientData.uh_id} </p>
                                                </div>
                                            ) : null}

                                            {/* {patientData.patient_status &&
                                                patientData.patient_status ===
                                                "admitted" ? (
                                                <div className="batch">
                                                    <p>Admitted</p>
                                                </div>
                                            ) : patientData.patient_status &&
                                                patientData.patient_status ===
                                                "under_treatment" ? (
                                                <div className="batch">
                                                    <p>Under Treatment</p>
                                                </div>
                                            ) : patientData.patient_status &&
                                                patientData.patient_status ===
                                                "critical" ? (
                                                <div className="batch">
                                                    <p>Critical</p>
                                                </div>
                                            ) : patientData.patient_status &&
                                                patientData.patient_status ===
                                                "discharge" ? (
                                                <div className="batch">
                                                    <p>Discharge</p>
                                                </div>
                                                
                                            ) : null} */}

                                            {
                                                patientData.patient_status === 'discharge' ?
                                                    <div className="batch">
                                                        <p class="discharge">Discharged</p>
                                                    </div>
                                                    :
                                                    User && parseInt(User.user_type) === 2 ?
                                                        // patientData && patientData.patient_status === 'mmx' ?
                                                        //     <div className="batch orange_bg">
                                                        //         <p class="warning">MMX</p>
                                                        //     </div>
                                                        //     : patientData && patientData.patient_status === '72hrs' ?
                                                        //         <div className="batch orange_bg">
                                                        //             <p class="warning">72 hrs</p>
                                                        //         </div>
                                                        //         : patientData && patientData.patient_status === 'on_tpi' ?
                                                        //             <div className="batch orange_bg">
                                                        //                 <p class="warning">On TPI</p>
                                                        //             </div> 
                                                        //             : patientData && patientData.patient_status === 'on_ventilator' ?
                                                        //                 <div className="batch danger">
                                                        //                     <p class="danger">On Ventilator</p>
                                                        //                 </div>
                                                        //                 : patientData && patientData.patient_status === 'mi' ?
                                                        //                     <div className="batch danger">
                                                        //                         <p class="danger">MI</p>
                                                        //                     </div>
                                                        //                     :
                                                        //                     patientData && patientData.patient_status === 'admitted' ?
                                                        //                         <div className="batch success">
                                                        //                             <p class="success">Admitted</p>
                                                        //                         </div>
                                                        //                         :
                                                        //                         patientData && patientData.patient_status === 'discharge' ?
                                                        //                             <div className="batch discharges">
                                                        //                                 <p class="discharge">Discharged</p>
                                                        //                             </div>
                                                        //                             :
                                                        //                             null
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="none" id="dropdown-basic">
                                                                {
                                                                    patientData.patient_status === 'mmx' ?
                                                                        <div className="batch orange_bg">
                                                                            <p class="warning">MMX</p>
                                                                        </div>
                                                                        : patientData.patient_status === '72hrs' ?
                                                                            <div className="batch orange_bg">
                                                                                <p class="warning">72 hrs</p>
                                                                            </div>
                                                                            : patientData.patient_status === 'on_tpi' ?
                                                                                <div className="batch orange_bg">
                                                                                    <p class="warning">On TPI</p>
                                                                                </div> :
                                                                                patientData.patient_status === 'on_ventilator' ?
                                                                                    <div className="batch danger">
                                                                                        <p class="danger">On Ventilator</p>
                                                                                    </div>
                                                                                    :
                                                                                    patientData.patient_status === 'mi' ?
                                                                                        <div className="batch danger">
                                                                                            <p class="danger">MI</p>
                                                                                        </div>
                                                                                        :
                                                                                        patientData.patient_status === 'admitted' ?
                                                                                            <div className="batch success">
                                                                                                <p class="success">Admitted</p>
                                                                                            </div>
                                                                                            :
                                                                                            patientData.patient_status === 'discharge' ?
                                                                                                <div className="batch discharges">
                                                                                                    <p class="discharge">Discharged</p>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                }
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {patientStatus.map((ditem, index) => (
                                                                    patientData.patient_status !== ditem.id ?
                                                                        <Dropdown.Item
                                                                            key={index}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                chnagePatientStatus(patientData.id, ditem.id)
                                                                            }
                                                                            }
                                                                        >
                                                                            {ditem.value}

                                                                        </Dropdown.Item>
                                                                        :
                                                                        null
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        :
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="none" id="dropdown-basic">
                                                                {
                                                                    patientData.patient_status === 'mmx' ?
                                                                        <div className="batch orange_bg">
                                                                            <p class="warning">MMX</p>
                                                                        </div>
                                                                        : patientData.patient_status === '72hrs' ?
                                                                            <div className="batch orange_bg">
                                                                                <p class="warning">72 hrs</p>
                                                                            </div>
                                                                            : patientData.patient_status === 'on_tpi' ?
                                                                                <div className="batch orange_bg">
                                                                                    <p class="warning">On TPI</p>
                                                                                </div> :
                                                                                patientData.patient_status === 'on_ventilator' ?
                                                                                    <div className="batch danger">
                                                                                        <p class="danger">On Ventilator</p>
                                                                                    </div>
                                                                                    :
                                                                                    patientData.patient_status === 'mi' ?
                                                                                        <div className="batch danger">
                                                                                            <p class="danger">MI</p>
                                                                                        </div>
                                                                                        :
                                                                                        patientData.patient_status === 'admitted' ?
                                                                                            <div className="batch success">
                                                                                                <p class="success">Admitted</p>
                                                                                            </div>
                                                                                            :
                                                                                            patientData.patient_status === 'discharge' ?
                                                                                                <div className="batch discharges">
                                                                                                    <p class="discharge">Discharged</p>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                }
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {patientStatus.map((ditem, index) => (
                                                                    patientData.patient_status !== ditem.id ?
                                                                        <Dropdown.Item
                                                                            key={index}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                chnagePatientStatus(patientData.id, ditem.id)
                                                                            }
                                                                            }
                                                                        >
                                                                            {ditem.value}

                                                                        </Dropdown.Item>
                                                                        :
                                                                        null
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>}
                                        </div>
                                    </div>
                                    {patientData &&
                                        patientData.vitals &&
                                        patientData.vitals.length > 0 ? (
                                        <div className="health_area">
                                            <div className="title_area">
                                                <h5>Health Overview</h5>
                                                {patientData.vitals[0].created ? (
                                                    <p >
                                                        Last Update :{" "}{`${dateFormat(patientData.vitals[0].created)}`}{" "} {`${timeFormat2(patientData.vitals[0].created)}`}
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div className="cards_area">
                                                <Row>
                                                    <Col xl={6} lg={6} md={6} sm={6} xs={12} >
                                                        <div className="card_inner_area">
                                                            <div className="sub_area">
                                                                <div className="left_area heart">
                                                                    <div className="img_area">
                                                                        <Image src={Heart} alt="..." />
                                                                    </div>
                                                                </div>
                                                                <div className="right_area">
                                                                    <h5>
                                                                        Heart
                                                                        Rate
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div className="bottom_area">
                                                                <p>
                                                                    <span>
                                                                        {patientData && patientData.vitals && patientData.vitals[0].heart_beat ? patientData.vitals[0].heart_beat : '-'}
                                                                    </span>
                                                                    bpm
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {/* {patientData.vitals[0].blood_pressure_top && patientData.vitals[0].blood_pressure_bottom ? ( */}
                                                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}   >
                                                        <div className="card_inner_area">
                                                            <div className="sub_area">
                                                                <div className="left_area blood">
                                                                    <div className="img_area bloood">
                                                                        <Image src={Blood} alt="..." />
                                                                    </div>
                                                                </div>
                                                                <div className="right_area">
                                                                    <h5>Blood Pressure</h5>
                                                                </div>
                                                            </div>
                                                            <div className="bottom_area">
                                                                <p>
                                                                    <span>
                                                                        {patientData && patientData.vitals && patientData.vitals[0].blood_pressure_top ? patientData.vitals[0].blood_pressure_top : '-'}
                                                                    </span>
                                                                    /
                                                                    <span>
                                                                        {patientData && patientData.vitals && patientData.vitals[0].blood_pressure_bottom ? patientData.vitals[0].blood_pressure_bottom : '-'}
                                                                    </span>{" "}
                                                                    mmhg
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {/* ) : null} */}

                                                    {/* {patientData.vitals[0].oxygen_saturation_top
                                                        && patientData.vitals[0].oxygen_saturation_bottom ? ( */}
                                                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}
                                                    >
                                                        <div className="card_inner_area">
                                                            <div className="sub_area">
                                                                <div className="left_area oxygen">
                                                                    <div className="img_area bloood">
                                                                        <Image
                                                                            src={Oxygen}
                                                                            alt="..."
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="right_area">
                                                                    <h5>
                                                                        Oxygen
                                                                        Saturation
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div className="bottom_area">
                                                                <p><span>{patientData && patientData.vitals && patientData.vitals[0].oxygen_saturation_top ? patientData.vitals[0].oxygen_saturation_top : '-'}{" "}</span>
                                                                    %SpO2
                                                                </p>
                                                                <p>
                                                                    <span className="oxygen">
                                                                        {patientData && patientData.vitals && patientData.vitals[0].oxygen_saturation_bottom ? patientData.vitals[0].oxygen_saturation_bottom : '-'}{" "}
                                                                    </span>
                                                                    PRbpm
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {/* ) : null} */}
                                                    {/* {patientData.vitals[0]
                                                        .blood_suger ? ( */}
                                                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} >
                                                        <div className="card_inner_area">
                                                            <div className="sub_area">
                                                                <div className="left_area sugar">
                                                                    <div className="img_area bloood">
                                                                        <Image
                                                                            src={Sugar}
                                                                            alt="..."
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="right_area">
                                                                    <h5>
                                                                        Blood
                                                                        Sugar
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div className="bottom_area">
                                                                <p> <span>{patientData && patientData.vitals && patientData.vitals[0].blood_suger ? patientData.vitals[0].blood_suger : '-'}</span>mg/dl</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {/* ) : null} */}
                                                    {/* {patientData.vitals[0]
                                                        .weight ? ( */}
                                                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                                        <div className="card_inner_area">
                                                            <div className="sub_area">
                                                                <div className="left_area sugar">
                                                                    <div className="img_area bloood">
                                                                        <Image
                                                                            src={bodyscale}
                                                                            alt="..."
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="right_area">
                                                                    <h5> Weight</h5>
                                                                </div>
                                                            </div>
                                                            <div className="bottom_area">
                                                                <p><span>{patientData && patientData.vitals && patientData.vitals[0].weight ? patientData.vitals[0].weight : '-'}</span>Kg</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {/* ) : null} */}
                                                    {/* {patientData.vitals[0]
                                                        .temperature ? ( */}
                                                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                                        <div className="card_inner_area">
                                                            <div className="sub_area">
                                                                <div className="left_area">
                                                                    <div className="img_area">
                                                                        <Image
                                                                            src={Temperature}
                                                                            alt="..."
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="right_area">
                                                                    <h5>Temperature</h5>
                                                                </div>
                                                            </div>
                                                            <div className="bottom_area">
                                                                <p><span class="circle">{patientData && patientData.vitals && patientData.vitals[0].temperature ? patientData.vitals[0].temperature : '-'}
                                                                </span>
                                                                    Celsius
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {/* ) : null} */}
                                                </Row>
                                            </div>
                                            <div className="update_area">
                                                {patientData.vitals[0].admin_name ? (
                                                    <div className="update_info">
                                                        <p style={{ cursor: 'pointer' }}
                                                            onClick={() => navigate(`/staff-detail/${patientData.created_by}`, { state: { name: patientData.admin_name, fromNavigation: true } })}
                                                        >Last updated by:{" "}<a>{patientData.vitals[0].admin_name}</a></p>
                                                    </div>
                                                ) : null}
                                                {
                                                    User && parseInt(User.user_type) === 1
                                                        && patientData && patientData.patient_status !== 'discharge' ?
                                                        <div className="button_area">
                                                            <Button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => setShowUpdateVitalModal(true)}
                                                            >
                                                                Update Vitals
                                                            </Button>
                                                        </div>
                                                        :
                                                        checkVitalPermissions
                                                            && checkVitalPermissions.permissions
                                                            && checkVitalPermissions.permissions.length > 2
                                                            && parseInt(checkVitalPermissions.permissions[2].value) === 1
                                                            && patientData && patientData.patient_status !== 'discharge' ?
                                                            <div className="button_area">
                                                                <Button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    onClick={() => setShowUpdateVitalModal(true)}
                                                                >
                                                                    Update Vitals
                                                                </Button>
                                                            </div>
                                                            :
                                                            null
                                                }
                                            </div>
                                        </div>
                                    ) :
                                        <div className="health_area">
                                            <div className="update_area center_area">
                                                {
                                                    User && parseInt(User.user_type) === 1 ?
                                                        <div className="button_area">
                                                            <Button
                                                                type="button"
                                                                className="btn btn-primary "
                                                                onClick={() => setShowUpdateVitalModal(true)}
                                                            >
                                                                Update Vitals
                                                            </Button>
                                                        </div>
                                                        :
                                                        checkVitalPermissions
                                                            && checkVitalPermissions.permissions
                                                            && checkVitalPermissions.permissions.length > 2
                                                            && parseInt(checkVitalPermissions.permissions[2].value) === 1
                                                            && patientData && patientData.patient_status !== 'discharge' ?
                                                            <div className="button_area">
                                                                <Button
                                                                    type="button"
                                                                    className="btn btn-primary "
                                                                    onClick={() => setShowUpdateVitalModal(true)}
                                                                >
                                                                    Update Vitals
                                                                </Button>
                                                            </div>
                                                            :
                                                            null
                                                }
                                            </div>
                                        </div>
                                    }


                                    {patientData &&
                                        patientData.cases &&
                                        patientData.cases.length > 0 ?
                                        patientData.cases.map((item, i) => (
                                            <div className="accordin_area" key={i}>
                                                <Accordion defaultActiveKey="0">
                                                    <Accordion.Item eventKey={String(i)}>
                                                        <Accordion.Header>
                                                            Medical Record: #{item.id}
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="case_detail_area">
                                                                <div class="title">
                                                                    <h5>{item.case_title ? item.case_title : ''}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="inner_area">
                                                                <ul>
                                                                    {/* <li className="category">
                                                                            <p onClick={() => setShowUpdateVitalModal(true)}>Lorem</p>

                                                                            {
                                                                                showUpdateVitalModal ? (
                                                                                    <UpdateVitalsModal show={showUpdateVitalModal} close={() => setShowUpdateVitalModal(false)} />
                                                                                ) : null
                                                                            }

                                                                        </li> */}
                                                                    {/* {medicalRecordTags &&
                                                                        medicalRecordTags.length >
                                                                        0
                                                                        ? medicalRecordTags.map(
                                                                            (item, i) => (
                                                                                <li className="category color" key={i}>
                                                                                    <p>{item}</p>
                                                                                </li>
                                                                            )
                                                                        )
                                                                        : null} */}
                                                                    {manageMedicalRecordsTags(item.tag)}
                                                                </ul>

                                                                <div className="description">
                                                                    {item.summary ? (
                                                                        <p>{item.summary}</p>
                                                                    ) : null}
                                                                </div>
                                                                {/* </div> */}
                                                                <div className="btn_active_area">
                                                                    <p style={{ cursor: 'pointer' }}
                                                                        onClick={() => navigate(`/staff-detail/${item.updated_by}`, { state: { name: item.updated_admin_name, fromNavigation: true } })}
                                                                    >Last Updated By:<a>{item.updated_admin_name}{' '}{dateFormat(item.modified)}{" "} {timeFormat2(item.modified)}</a></p>
                                                                    <a className={parseInt(item.case_status) === 1 ? "active" : "inactive"}>{parseInt(item.case_status) === 1 ? "Active" : "Inactive"}</a>
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    {/* <Accordion.Item eventKey="1">
                                            <Accordion.Header>Medical Record: #2</Accordion.Header>
                                            <Accordion.Body>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt laborum.
                                            </Accordion.Body>
                                        </Accordion.Item> */}
                                                </Accordion>
                                            </div>
                                        ))
                                        :
                                        null}
                                    {
                                        User && parseInt(User.user_type) === 1 ?
                                            <div className="btn_area">
                                                <Button
                                                    className="submitBtn"
                                                    type="button"
                                                    onClick={() => handleMRButton()}
                                                >
                                                    {
                                                        patientData &&
                                                            patientData.cases &&
                                                            patientData.cases.length > 0 &&
                                                            parseInt(patientData.cases[0].case_status) === 1 ?
                                                            'Edit MR'
                                                            :
                                                            'Add New MR'
                                                    }
                                                </Button>
                                            </div>
                                            :
                                            checkPatinetCasePermissions
                                                && checkPatinetCasePermissions.permissions
                                                && checkPatinetCasePermissions.permissions.length > 1
                                                && parseInt(checkPatinetCasePermissions.permissions[1].value) === 1 ?
                                                <div className="btn_area">
                                                    <Button
                                                        className="submitBtn"
                                                        type="button"
                                                        onClick={() => handleMRButton()}
                                                    >
                                                        {
                                                            patientData &&
                                                                patientData.cases &&
                                                                patientData.cases.length > 0 &&
                                                                parseInt(patientData.cases[0].case_status) === 1 ?
                                                                'Edit MR'
                                                                :
                                                                'Add New MR'
                                                        }
                                                    </Button>
                                                </div>
                                                :
                                                null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="task_btn_area">
                        <CustomTab
                            tabs={tabs}
                            currentTab={currentTab}
                            setCurrentTab={(e) => (
                                setCurrentTab(e), handleTabData(e)
                            )}
                        />
                    </div>
                    <div className="tab_details">
                        {
                            taskList && taskList.length > 0 ?
                                <div
                                    className={
                                        parseInt(currentTab) === 1
                                            ? "sorting_data"
                                            : "d-none"
                                    }
                                >
                                    <div className="left_area">
                                        <p>Assigned Task</p>
                                    </div>
                                    <div className="right_area">
                                        <div className="sort_button">
                                            <Button
                                                className="btn-primary"
                                                type="submit"
                                                onClick={() => setShowFilterModal(true)}
                                            >
                                                Sort
                                                <Image
                                                    src={Filter}
                                                    alt="..."
                                                    className="image_area"
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                :
                                null

                        }
                        <div className="custom_tab_data">
                            {parseInt(currentTab) === 1 ? (
                                <Task
                                    List={taskList && taskList.length > 0 ? taskList : []}
                                    taskCompletedByAdmin={taskCompletedByAdmin}
                                    navigate={navigate}
                                    totalPages={taskListTotalPages}
                                    page={page}
                                    handlePage={handlePage}
                                    handleBackClick={handleTaskBackClick}
                                    handleNextClick={handleTaskNextClick}
                                    patientCaseDetail={patientActiveCaseDetail ? patientActiveCaseDetail : ''}
                                    patientData={patientData}
                                    checkTaskPermissions={checkTaskPermissions}
                                    User={User}
                                    isUserCanCompleteTheTask={isUserCanCompleteTheTask}
                                />
                            ) : null}
                            {parseInt(currentTab) === 2 ? (
                                <Reports
                                    loader={loader2}
                                    patientReportsList={
                                        patientReportsList &&
                                            patientReportsList.length > 0
                                            ? patientReportsList
                                            : []
                                    }
                                    page={reportsPage}
                                    handlePage={handleReportsPage}
                                    reportListTotalPages={reportListTotalPages}
                                    handleDeleteReport={handleDeleteReport}
                                    handleaddReport={handleAddReport}
                                    handleEditReport={handleEditReport}
                                    handleReportsBackClick={handleReportsBackClick}
                                    handleReportsNextClick={handleReportsNextClick}
                                    handleRenderImage={handleRenderImage}
                                    patientData={patientData}
                                    checkReportPermissions={checkReportPermissions}
                                    User={User}
                                    deleteReportsDetail={deleteReportsDetail}
                                    setDeleteReportsDetail={setDeleteReportsDetail}
                                />
                            ) : null}
                            {parseInt(currentTab) === 3 ?
                                <LabUpdate
                                    LabListing={LabListing}
                                    loader={loader2}
                                    page={labPage}
                                    totalPages={labTotalPages}
                                    handlePage={onlabChangePage}
                                    handleLogsNextClick={handleLabNextClick}
                                    handleLogsBackClick={handleLabBackClick}
                                    patientData={patientData}
                                    deleteLabreport={deleteLabreport}
                                    setDeleteLabreport={setDeleteLabreport}
                                    deleteLabReportListing={deleteLabReportListing}
                                    checkLabPermissions={checkLabPermissions}
                                    labDateParam={labDateParam}
                                    setLabDateParam={setLabDateParam}
                                    totalLabListLength={totalLabListLength}
                                /> : null}
                            {parseInt(currentTab) === 4 ? (
                                <Health
                                    patientHealthData={
                                        vitalsList && vitalsList.length > 0
                                            ? vitalsList
                                            : []
                                    }
                                    page={vitalsPage}
                                    handlePage={handleHealthPage}
                                    totalPages={vitalsListTotalPages}
                                    handleHealthBackClick={handleHealthBackClick}
                                    handleHealthNextClick={handleHealthNextClick}
                                />
                            ) : null}
                            {parseInt(currentTab) === 5 ?
                                <Logs
                                    LogsListing={LogsListing}
                                    loader={loader2}
                                    page={logsPage}
                                    handlePage={onChangePage}
                                    totalPages={logsTotalPages}
                                    handleLogsNextClick={handleLogsNextClick}
                                    handleLogsBackClick={handleLogsBackClick}
                                    logsDateParam={logsDateParam}
                                    setLogsDateParam={setLogsDateParam}
                                    totalLogsListLength={logsCount}
                                    navigate={navigate}
                                    isPatientdetails={true}
                                />
                                :
                                null
                            }

                            {parseInt(currentTab) === 6 ?
                                <MedicineUpdate
                                    medicineList={medicineList && medicineList.length > 0 ? medicineList : []} selectAllMedicinesetSelectAllMedicine
                                    setMedicineList={setMedicineList}
                                    completeMedicine={completeMedicine}
                                    setCompleteMedicine={setCompleteMedicine}
                                    page={medicinePage}
                                    totalPages={medicineTotalPages}
                                    handlePage={handleMedicinePage}
                                    handleMedicineBackClick={handleMedicineBackClick}
                                    handleMedicineNextClick={handleMedicineNextClick}
                                    handleDeleteMedicine={handleDeleteMedicine}
                                    patientCaseDetail={patientActiveCaseDetail ? patientActiveCaseDetail : null}
                                    navigate={navigate}
                                    setDeletMedicineDetail={setDeletMedicineDetail}
                                    deletConfirmationModal={deletConfirmationModal}
                                    setDeletConfirmationModal={setDeletConfirmationModal}
                                    completMedicalConfirmationModal={completMedicalConfirmationModal}
                                    setCompletMedicineConfimationModal={setCompletMedicineConfimationModal}
                                    completeMedicineDetail={completeMedicineDetail}
                                    setCompleteMedicineDetail={setCompleteMedicineDetail}
                                    handleCompleteMedicine={handleCompleteMedicine}
                                    medicineComment={medicineComment}
                                    setMedicineComment={setMedicineComment}
                                    medicineDataForCommentApi={medicineDataForCommentApi}
                                    setMedicineDataForCommentApi={setMedicineDataForCommentApi}
                                    handleAddCommentInMedicineTab={handleAddCommentInMedicineTab}
                                    dateParam={dateParam}
                                    setDateParam={setDateParam}
                                    patientData={patientData}
                                    checkMedicinePermissions={checkMedicinePermissions}
                                    User={User}
                                    handleCompleteAllMedicine={handleCompleteAllMedicine}
                                    handleAllMedicine={handleAllMedicine}
                                    selectAllMedicine={selectAllMedicine}
                                    setSelectAllMedicine={setSelectAllMedicine}
                                    allmedicineCompletePopup={allmedicineCompletePopup}
                                    setAllmedicineCompletePopup={setAllmedicineCompletePopup}
                                    checkAllSelecMedicine={checkAllSelecMedicine}
                                /> : null}
                            {/* <Medicine /> */}
                        </div>
                    </div>
                </>
            )
                :
                (<Loader loader={loader} />)
            }
            {showLogin ?
                (
                    <TaskModal
                        show={showLogin}
                        close={() => setShowLogin(false)}
                    />
                ) : null}
            {profileModal ?
                <PatientProfileModal
                    show={profileModal}
                    patientData={patientData}
                    navigate={navigate}
                    checkPermissions={checkPermissions}
                    close={() => setProfileModal(false)}
                /> : null}
            {showUpdateVitalModal ? (
                <UpdateVitalsModal
                    show={showUpdateVitalModal}
                    patientData={patientData}
                    close={() => setShowUpdateVitalModal(false)}
                    values={values}
                    isError={isError}
                    handleChange={handleChange}
                    updateHealth={() => updateHealth()}
                />
            ) : null}
            {
                showFilterModal ?
                    <PatientDetailTaskListFilterModal
                        show={showFilterModal}
                        applyFilters={applyFilters}
                        filterSortByValue={filterSortByValue}
                        handleClearAllFilters={handleClearAllFilters}
                        close={() => setShowFilterModal(false)}
                    />
                    :
                    null
            }
        </div>
    );
};

export default PatientDetails;
