//------------ REACT IMPORT --------------//
import React, { useRef } from "react";
//--------- BOOTSTRAP ITEMS IMPORT ----------//
import { Col, Form, Image, Row } from "react-bootstrap";
import { LogCalendar, LogsImg, Man } from "../../../../assets/images";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaginationComponent from "../../CommanComponents/Pagination";
import NoDataComponent from "../../CommanComponents/NoDataComponent";
import { _msdate, dateFormat, parseHtmlString, renderImage, timeFormat2 } from "../../../Helper/General";
import moment from "moment";

let lastCreatedAt = null;

const Logs = (props) => {
    const {
        LogsListing,
        loader,
        page,
        handlePage,
        totalPages,
        handleLogsNextClick,
        handleLogsBackClick,
        logsDateParam,
        setLogsDateParam,
        totalLogsListLength,
        navigate,
        isPatientdetails
    } = props

    const inputCal = useRef(null);

    const handleClick = () => {
        if (inputCal.current) {          //---------------date picker----
            inputCal.current.showPicker();
        }
    };



    const updateDate = item => {
        lastCreatedAt = moment(item.created).format('YYYY-MM-DD');
        return null;
    };

    return (
        <>
            <div className="logs_tab_screen">
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="main_log">
                            <div className="title_area">
                                <div className="left_title">
                                    <p>Total Logs : {totalLogsListLength ? totalLogsListLength : 0}</p>
                                </div>
                                <div className="right_title">
                                    <p>{moment(logsDateParam).format('DD-MM-YYYY')}</p>
                                    <div className="icon" style={{ position: 'relative' }}>
                                        <Image
                                            src={LogCalendar}
                                            alt="..."
                                            onClick={() => handleClick()}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <Form.Control
                                            style={{
                                                position: 'absolute',
                                                zIndex: 1,
                                                height: '1px',
                                                width: '295px',
                                                right: '0',
                                                left: 'none',
                                                top: '15px',
                                                visibility: 'hidden'
                                            }}
                                            type="date"
                                            ref={inputCal}
                                            placeholder="date"
                                            value={logsDateParam}
                                            onChange={(e) => {
                                                setLogsDateParam(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                LogsListing && LogsListing.length > 0 ?
                                    LogsListing.map((item, i) => (
                                        <>
                                            <div className="date_area">
                                                {/* <p>12 June, 2023</p> */}
                                                {item.created &&
                                                    (lastCreatedAt === null ||
                                                        _msdate(item.created) != lastCreatedAt) ? (
                                                    <p>
                                                        {dateFormat(item.created)}
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div className="main_area">
                                                <div className="left_area">
                                                    <p>{timeFormat2(item.created)}</p>
                                                    <div className="check_icon">
                                                        <FontAwesomeIcon
                                                            icon={faCircleCheck}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="right_area">
                                                    <div className="inner_left_area">
                                                        <div className="left_img_area">
                                                            {/* <div className="img_area"> */}
                                                            {isPatientdetails ? <Image
                                                                src={item && item.admin_image ? renderImage(item.admin_image) : Man}
                                                                alt="..."
                                                                style={{ cursor: 'pointer' }}
                                                            /> :
                                                                <Image
                                                                    src={item && item.patient_image ? renderImage(item.patient_image) : Man}
                                                                    alt="..."
                                                                    style={{ cursor: 'pointer' }}
                                                                />}
                                                            {/* </div> */}
                                                        </div>
                                                        <div className="right_name_area">
                                                            {isPatientdetails ?
                                                                <p className="label" style={{ cursor: 'pointer' }}
                                                                    onClick={() => navigate(`/staff-detail/${item.created_by}`, { state: { name: item.admin_name, fromNavigation: true } })}
                                                                > {item.admin_name}</p>
                                                                :
                                                                <p className="label"
                                                                    onClick={() => navigate(`/patientDetails/${item.patient_id}`, { state: { pageParams: ['Dashboard', 'Patient List'], fromNavigation: true } })}
                                                                > {item.patient_name}</p>}
                                                            {isPatientdetails ? <h6 style={{ textTransform: 'capitalize' }}><span>{item.admin_role}</span></h6> : <h6 style={{ textTransform: 'capitalize' }}><span>{item.hospital_name}</span></h6>}

                                                        </div>
                                                    </div>
                                                    <div className="inner_right_area">
                                                        <p><span>{item.message}</span></p>
                                                        {/* <p> */}
                                                        {item.description ? <div className="comment" dangerouslySetInnerHTML={{ __html: item.description }}></div> : null}
                                                    </div>
                                                </div>
                                            </div>
                                            {updateDate(item)}
                                        </>
                                    ))
                                    :
                                    <NoDataComponent
                                        message="No Logs Found!"
                                    />
                            }

                        </div>
                    </Col>
                </Row>

            </div>
            {
                LogsListing && LogsListing.length > 0 ?
                    <PaginationComponent
                        page={page}
                        setPage={handlePage}
                        totalPages={totalPages}
                        handleBackClick={handleLogsBackClick}
                        handleNextClick={handleLogsNextClick}
                    />
                    :
                    null
            }
        </>
    )
}

export default Logs;