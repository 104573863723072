import React, { useState } from "react";
import { Button, Col, Container, Dropdown, Image, Row } from "react-bootstrap";
import { Circle_Img, Filter, GirlImg, Man } from "../../../../../assets/images";
import * as Constants from "../../../../Helper/constants/constants";
import CustomTab from "../../../../Helper/customTabs";
import WithoutImageHeader from "../../../../Helper/withoutImageHeader";
import Logs from "../../../Tabs/Logs";
import Task from "../../../Tabs/Task";
import StaffDetailFunctions from "./function";
import { useEffect } from "react";
import Loader from "../../../../Helper/loader";
import { renderImage } from "../../../../Helper/General";
import moment from "moment";
import { useParams } from "react-router-dom";

const StaffDetail = () => {
    var breadcrumItem = "View Staff Detail";
    const [currentTab, setCurrentTab] = useState("1");

    const {
        User,
        checkTaskPermissions,
        checkPermissions,
        getModulePermissions,
        getTaskModulePermissions,
        getLogsModulePermissions,
        checkLogsPermissions,
        tabs,
        setTabs,
        defaultTabs,
        loader,
        setbreadcrumItem,
        breadcrumData,
        loaction,
        navigate,
        staffDetail,
        getStaffDetails,
        handleTabData,
        handleBreadcrumDataNaviagtion,
        LogsListing,
        logsCount,
        logsPage,
        logsTotalPages,
        onChangePage,
        handleLogsBackClick,
        handleLogsNextClick,
        logsDateParam,
        setLogsDateParam,
        handleTaskFilters,
        taskListing,
        sortTitle,
        handleNavigationOnEditIcon,
        checkTaskPermissionInSubAdmin,
        setBreadcrumData
    } = StaffDetailFunctions();

    let param = useParams();
    useEffect(() => {
        if (param.slug) {
            let userdata = localStorage.getItem('SET_USER_DATA');
            let User = JSON.parse(userdata);
            if (User && parseInt(User.user_type) === 2) {
                // getTaskModulePermissions();
                // getLogsModulePermissions();
                checkTaskPermissionInSubAdmin();
                getModulePermissions();
            }
            else {
                setTabs(defaultTabs);
            }
            if (loaction && loaction.state && loaction.state.name === 'fromSubAdminTaskDetail') {
                let data = ["Dashboard", "My Tasks"]
                setBreadcrumData(data)
            }
            else if (loaction && loaction.state && loaction.state.name === 'fromSuperAdminTaskDetail') {
                let data = ["Dashboard", "Task list"]
                setBreadcrumData(data)
            }
            let id = param.slug ? param.slug : "";
            getStaffDetails(id);
        }
        else {
            navigate(-1);
        }
    }, []);


    return (
        <div className="staff_detail_section">
            <WithoutImageHeader
                header={Constants.STAFF_DETAIL}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBack={() => navigate(-1)}
                handleBreadcrumDataNaviagtion={(i, item) =>
                    handleBreadcrumDataNaviagtion(i, item)
                }
                icon={User && parseInt(User.user_type) === 1 && staffDetail && parseInt(staffDetail.user_type) === 2 ? "edit" : checkPermissions
                    && checkPermissions.permissions
                    && checkPermissions.permissions.length > 2
                    && parseInt(checkPermissions.permissions[2].value) === 1 ? "edit" : null
                }
                handleNavigate={handleNavigationOnEditIcon}
            />
            <Container>
                {
                    loader ?
                        <Loader
                            loader={loader}
                        />
                        :
                        staffDetail ?
                            <Row>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className="staff_detail_area">
                                        <div className="content_img_area">
                                            <div className="left_area">
                                                <div className="img_area">
                                                    <Image src={staffDetail && staffDetail.image
                                                        ? renderImage(staffDetail.image)
                                                        : Man} alt="..." />
                                                </div>
                                            </div>
                                            <div className="right_area">
                                                <h5 style={{ textTransform: 'capitalize' }}>
                                                    {staffDetail && staffDetail.name
                                                        ? staffDetail.name
                                                        : null}
                                                </h5>
                                                <div className="text_box" style={{ textTransform: 'uppercase' }}>
                                                    {staffDetail &&
                                                        staffDetail.role_title
                                                        ? staffDetail.role_title
                                                        : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="info_area">
                                            {staffDetail && staffDetail.hospitals && staffDetail.hospitals.length > 0 ? (
                                                <div className="inner_info_area">
                                                    <div className="left_inner_area">
                                                        <p>Hospital Name</p>
                                                    </div>
                                                    {staffDetail.hospitals.map(
                                                        (item, index, arr) => {
                                                            return (
                                                                <div className="right_inner_area">
                                                                    <p style={{ cursor: "pointer" }}
                                                                        onClick={() => navigate(`/hospital-detail/${item && item.id ? item.id : ""}`,
                                                                            {
                                                                                state: {
                                                                                    fromNavigation: true
                                                                                }
                                                                            })}
                                                                    >
                                                                        <u>
                                                                            {item && item.title
                                                                                ? item.title
                                                                                : null}
                                                                            {index !=
                                                                                arr.length - 1
                                                                                ? " , "
                                                                                : null}
                                                                        </u>
                                                                    </p>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            ) : null}

                                            {staffDetail && staffDetail.email ? (
                                                <div className="inner_info_area">
                                                    <div className="left_inner_area">
                                                        <p>Email Address</p>
                                                    </div>
                                                    <div className="right_inner_area">
                                                        <a href={`mailto:${staffDetail.email}`}>
                                                            <p style={{ cursor: "pointer" }}>
                                                                <u>{staffDetail.email}</u>
                                                            </p>
                                                        </a>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {staffDetail && staffDetail.phonenumber ? (
                                                <div className="inner_info_area">
                                                    <div className="left_inner_area">
                                                        <p>Contact Number</p>
                                                    </div>
                                                    <div className="right_inner_area">
                                                        <a href={`tel:${staffDetail.phonenumber}`}>
                                                            <p style={{ cursor: "pointer" }}>
                                                                <u>{staffDetail.phonenumber}</u>
                                                            </p>
                                                        </a>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {staffDetail && staffDetail.emp_id ? (
                                                <div className="inner_info_area">
                                                    <div className="left_inner_area">
                                                        <p>Employee Id</p>
                                                    </div>
                                                    <div className="right_inner_area">
                                                        <p>{staffDetail.emp_id}</p>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {staffDetail && staffDetail.gender_title ? (
                                                <div className="inner_info_area">
                                                    <div className="left_inner_area">
                                                        <p>Gender</p>
                                                    </div>
                                                    <div className="right_inner_area">
                                                        <p>{staffDetail.gender_title}</p>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {staffDetail &&
                                                staffDetail.blood_group_title ? (
                                                <div className="inner_info_area">
                                                    <div className="left_inner_area">
                                                        <p>Blood Group</p>
                                                    </div>
                                                    <div className="right_inner_area">
                                                        <p>
                                                            {staffDetail.blood_group_title}
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {staffDetail && staffDetail.dob ? (
                                                <div className="inner_info_area">
                                                    <div className="left_inner_area">
                                                        <p>D.O.B</p>
                                                    </div>
                                                    <div className="right_inner_area">
                                                        <p>{moment(staffDetail.dob).format('DD-MM-YYYY')}</p>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {staffDetail && staffDetail.joining_date ? (
                                                <div className="inner_info_area">
                                                    <div className="left_inner_area">
                                                        <p>Joining Date</p>
                                                    </div>
                                                    <div className="right_inner_area">
                                                        <p>{moment(staffDetail.joining_date).format('DD-MM-YYYY')}</p>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {staffDetail && staffDetail.department_title ? (
                                                <div className="inner_info_area">
                                                    <div className="left_inner_area">
                                                        <p>Department</p>
                                                    </div>
                                                    <div className="right_inner_area">
                                                        <p>
                                                            <b>{staffDetail.department_title}</b>
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {staffDetail && staffDetail.specialization ? (
                                                <div className="inner_info_area">
                                                    <div className="left_inner_area">
                                                        <p>Specialization</p>
                                                    </div>
                                                    <div className="right_inner_area">
                                                        <p>
                                                            {staffDetail.specialization}
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {staffDetail && staffDetail.state_title ? (
                                                <div className="inner_info_area">
                                                    <div className="left_inner_area">
                                                        <p>State</p>
                                                    </div>
                                                    <div className="right_inner_area">
                                                        <p>{staffDetail.state_title}</p>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {staffDetail && staffDetail.city_title ? (
                                                <div className="inner_info_area">
                                                    <div className="left_inner_area">
                                                        <p>City</p>
                                                    </div>
                                                    <div className="right_inner_area">
                                                        <p>{staffDetail.city_title}</p>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {staffDetail && staffDetail.address ? (
                                                <div className="inner_info_area space">
                                                    <div className="left_inner_area">
                                                        <p>Address</p>
                                                    </div>
                                                    <div className="right_inner_area">
                                                        <p>{staffDetail.address}</p>
                                                    </div>
                                                </div>
                                            ) : null}

                                            <div className="circle_img">
                                                <Image src={Circle_Img} alt="..." />
                                            </div>
                                        </div>
                                        {User && (User.role_title === 'Lab' || User.role_title === 'lab') ? null : <div className="task_btn_area">
                                            <CustomTab
                                                tabs={tabs}
                                                currentTab={currentTab}
                                                setCurrentTab={(e) => (
                                                    setCurrentTab(e), handleTabData(e)
                                                )}
                                            />
                                        </div>}
                                        {User && (User.role_title === 'Lab' || User.role_title === 'lab') ? null : <div className="tab_details">
                                            <div
                                                className={
                                                    parseInt(currentTab) === 1
                                                        ? "sorting_data"
                                                        : "d-none"
                                                }
                                            >
                                                <div className="left_area">
                                                    <p>Assigned Task</p>
                                                </div>

                                                <div className="right_area">
                                                    <div className="sort_button">
                                                        <Dropdown>
                                                            <Dropdown.Toggle
                                                                variant="none"
                                                                id="dropdown-basic"
                                                            >
                                                                <Button
                                                                    className="btn-primary"
                                                                    type="submit"
                                                                >
                                                                    {" "}
                                                                    {sortTitle ? sortTitle : "Sort"}{" "}
                                                                    <Image
                                                                        src={Filter}
                                                                        alt="..."
                                                                        className="image_area"
                                                                    />{" "}
                                                                </Button>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item
                                                                    onClick={() => handleTaskFilters('Asce')}
                                                                >
                                                                    Ascending
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={() => handleTaskFilters('Desc')}
                                                                >
                                                                    Descending
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="custom_tab_data">
                                                {parseInt(currentTab) === 1 ? (
                                                    <Task
                                                        List={taskListing && taskListing.length > 0 ? taskListing : []}
                                                        checkTaskPermissions={checkTaskPermissions}
                                                        User={User}
                                                    />
                                                ) : null}
                                                {parseInt(currentTab) === 2 ? (
                                                    <Logs
                                                        checkLogsPermissions={checkLogsPermissions}
                                                        User={User}
                                                        LogsListing={LogsListing}
                                                        loader={loader}
                                                        page={logsPage}
                                                        handlePage={onChangePage}
                                                        totalPages={logsTotalPages}
                                                        handleLogsNextClick={handleLogsNextClick}
                                                        handleLogsBackClick={handleLogsBackClick}
                                                        logsDateParam={logsDateParam}
                                                        setLogsDateParam={setLogsDateParam}
                                                        totalLogsListLength={logsCount}
                                                        navigate={navigate}
                                                    />
                                                ) : null}
                                            </div>
                                            {/* <NoDataComponent /> */}
                                        </div>}
                                    </div>
                                </Col>
                            </Row>
                            :
                            null
                }
            </Container>
            {loader ? <Loader loader={loader} /> : null}
        </div>
    );
};

export default StaffDetail;
